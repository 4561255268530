import { css } from '@emotion/react'
import ApiFileUploadBox from 'components/ApiFileUploadBox'
import {
  GrayScaleOutline,
  PrimaryButton,
  PrimaryOutlineButton,
} from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DetailsDefault from 'components/DetailsComponents/DetailsComponents'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import UnderlineDropdown from 'components/Dropdown/UnderlineDropdown'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import LiveSearchInput from 'components/LiveSearchInput'
import { LiveSearchDefaultDiv } from 'components/LiveSearchInput/LiveSearchInput.styles'
import { LiveSearchUserInfo } from 'components/LiveSearchInput/LiveSearchInput.types'
import PeriodPicker from 'components/PeriodPicker'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useMatchInput from 'hooks/useMatchInput'
import { uniqBy } from 'lodash-es'
import {
  ISSUE_METHOD_DROPDOWN_LIST,
  IssueMethod,
} from 'pages/Support/Coupon/CouponPublish/ByTarget/ByTarget'
import {
  APIIssuePoint,
  POINT_PUBLISH_INITIAL_VALUE,
  PointIssueMatchInput,
} from 'pages/Support/Point/Publish/PointPublish.types'
import TargetList from 'pages/Support/components/TargetList'
import { TargetUser } from 'pages/Support/support.types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import { ContentBoxWrap, ContentRadiusBox } from 'themes/Shared.styles'
import { typography } from 'themes/styles'
import { DoubleDFile } from 'types/services.type'
import callAxios, { handleError } from 'utils/callAxios'
import highlight from 'utils/highlight'

interface PointPublishProps {}

const PointPublish = (props: PointPublishProps) => {
  const [excelFile, set_excelFile] = useState<DoubleDFile[]>([])
  const pointInputs = useMatchInput<PointIssueMatchInput>(
    POINT_PUBLISH_INITIAL_VALUE
  )
  const { inputs, handleInput } = pointInputs
  const [issueMethod, set_issueMethod] = useState<IssueMethod>('search')
  const trueWhenEmpty = useEmptyCheck(inputs)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // 포인트 생성 api 호출
  const issueNewPoint = async (body: APIIssuePoint) => {
    try {
      await callAxios('order').post('/point', body)
      dispatch(
        setToast({
          message: '포인트가 발행되었습니다.',
          type: 'success',
        })
      )
      navigate('/customer/point')
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <>
      <DetailsDefault
        _css={css`
          .radius-box-gap {
            gap: 12px;
          }
        `}
        _title={'포인트 발급'}
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout _title={'포인트 정보'}>
              <ContentRadiusBox>
                <BoxRowComponent _label="유효 기간 *">
                  <PeriodPicker
                    _css={css`
                      .end-date {
                        .mini-calendar {
                          right: 0px;
                        }
                      }
                    `}
                    _period={{ start: inputs.startAt, end: inputs.endAt }}
                    _emitValue={(value) => {
                      const { start, end } = value
                      handleInput('startAt', start)
                      handleInput('endAt', end)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label="발급 사유 *">
                  <UnderlineInput
                    placeholder="포인트명 입력"
                    value={inputs.note}
                    handleValue={(value) => handleInput('note', value)}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label="포인트 금액 (원) *">
                  <UnderlineNumberInput
                    placeholder="숫자만 입력"
                    value={inputs.amount}
                    handleValue={(value) =>
                      handleInput('amount', Number(value))
                    }
                    suffix="원"
                  />
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout _title={'발급 대상자 검색'}>
              <ContentRadiusBox className="radius-box-gap">
                <BoxRowComponent _label={'발급방법 *'}>
                  <UnderlineDropdown
                    _value={issueMethod}
                    _list={ISSUE_METHOD_DROPDOWN_LIST}
                    _placeholder={'카테고리 선택'}
                    _emitValue={(value) => {
                      set_issueMethod(value)
                    }}
                  />
                </BoxRowComponent>
                {issueMethod === 'search' ? (
                  <BoxRowComponent _label={'발급 대상자 *'}>
                    <LiveSearchInput<LiveSearchUserInfo>
                      _removeAfter
                      _type="user"
                      _url="/users/search"
                      _queryName="name"
                      _placeholder="회원 이름 검색"
                      _emitValue={(value) => {
                        if (value) {
                          const uniqUserList = uniqBy(
                            [...inputs.userList, value],
                            (item) => item.userName
                          )
                          handleInput('userList', uniqUserList)
                        }
                      }}
                      _listParser={(
                        response: DoubleDListWithPaging<LiveSearchUserInfo>
                      ) => {
                        return {
                          _list: response.list,
                          _cursor: disposeCursor(response.paging.cursor),
                        }
                      }}
                      _setTitle={(value) => value.name}
                      _ListContent={(props) => {
                        return (
                          <LiveSearchDefaultDiv
                            onClick={(e) => {
                              props.handleSubmit(props.item)
                            }}
                          >
                            {highlight(props.item.name, props.term)}{' '}
                            {highlight(props.item.phone, props.term)}
                          </LiveSearchDefaultDiv>
                        )
                      }}
                    />
                  </BoxRowComponent>
                ) : (
                  <>
                    <BoxRowComponent
                      _label={'엑셀 업로드 *'}
                      _left
                      _css={css`
                        align-items: flex-start;
                        & > label {
                          padding-top: 13px;
                        }
                      `}
                    >
                      <ApiFileUploadBox
                        _type={'order'}
                        _fileType={'excel'}
                        _url={`/point/xlsx`}
                        _fileList={excelFile}
                        _multiple={false}
                        _emitExcelUserList={(value) => {
                          const targetUserFromExcel: TargetUser[] = value.map(
                            (item: any) => {
                              return {
                                userName: item.userName,
                                name: item.name,
                                phone: item.phone,
                              }
                            }
                          )
                          const uniqUserList = uniqBy(
                            [...inputs.userList, ...targetUserFromExcel],
                            (item) => item.userName
                          )
                          handleInput('userList', uniqUserList)
                        }}
                        _emitValue={(value) => {
                          set_excelFile(value)
                        }}
                        _method={'replace'}
                      />
                    </BoxRowComponent>

                    <BoxRowComponent
                      _label={'양식 다운로드'}
                      _css={css`
                        justify-content: flex-start;
                      `}
                    >
                      <GrayScaleOutline
                        _css={css`
                          ${typography['Body/Small/Bold']};
                          width: 86px;
                          height: 30px;
                          border-radius: 8px;
                        `}
                      >
                        다운로드
                      </GrayScaleOutline>
                    </BoxRowComponent>
                  </>
                )}
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _rightContents={
          <ContentBoxWrap
            _css={css`
              padding-right: 0 !important;
              padding-bottom: 0 !important;
            `}
          >
            <TargetList
              _list={inputs.userList}
              _setList={(newList) => handleInput('userList', newList)}
            />
          </ContentBoxWrap>
        }
        _bottomRightControls={
          <S.Layout.RightBottom>
            <PrimaryOutlineButton
              _css={css`
                width: 160px;
                height: 48px;
              `}
              // disabled={selectedIds.length < 1}
              onClick={() => {
                navigate('/customer/point')
              }}
            >
              취소
            </PrimaryOutlineButton>

            <PrimaryButton
              disabled={trueWhenEmpty}
              _css={css`
                flex: 1;
                height: 48px;
              `}
              onClick={() => {
                // api 발행 요청 성공 시
                const parsedData = parseDataToPointIssueAPI(inputs)
                issueNewPoint(parsedData)
              }}
            >
              포인트 발행
            </PrimaryButton>
          </S.Layout.RightBottom>
        }
      ></DetailsDefault>
    </>
  )
}

const parseDataToPointIssueAPI = (value: PointIssueMatchInput) => {
  const parsedData: APIIssuePoint = {
    startAt: value.startAt?.toISOString() ?? '',
    endAt: value.endAt?.toISOString() ?? '',
    note: value.note,
    amount: value.amount ?? 0,
    userList: value.userList,
  }
  return parsedData
}

export default PointPublish
