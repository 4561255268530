import {
  ImagesCheckboxChecked,
  ImagesCheckboxDisabled,
  ImagesCheckboxRightMore,
  ImagesCheckboxUnChecked,
} from 'assets'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils/helpers'
import S from './CheckBoxRound.styles'
import { CheckBoxRoundProps } from './CheckBoxRound.types'

const CheckBoxRound = ({
  children,
  _active,
  _link,
  _css,
  _mini,
  _disabled,
  onClick,
  className,
  ...props
}: CheckBoxRoundProps) => {
  const navigate = useNavigate()

  return (
    <>
      <S.Wrap
        _css={_css}
        _mini={_mini}
        _active={_active}
        className={cn({ disabled: _disabled }, className)}
        onClick={(e) => {
          e.stopPropagation()
          if (_disabled) return
          if (onClick) onClick(e)
        }}
        {...props}
      >
        <S.Contents>
          <S.ImageBox>
            {_disabled ? (
              <img src={ImagesCheckboxDisabled} alt={'disabledImg'} />
            ) : (
              <>
                {_active && (
                  <img src={ImagesCheckboxChecked} alt={'checkedImg'} />
                )}
                {!_active && (
                  <img src={ImagesCheckboxUnChecked} alt={'unCheckedImg'} />
                )}
              </>
            )}
          </S.ImageBox>
          {children && (
            <S.ChildrenBox className="children-box">{children}</S.ChildrenBox>
          )}
        </S.Contents>
        {_link && (
          <S.More
            onClick={(e) => {
              e.stopPropagation()
              if (typeof _link === 'string') {
                navigate(_link)
              } else {
                _link(e)
              }
            }}
          >
            <img src={ImagesCheckboxRightMore} alt={'RightMore'} />
          </S.More>
        )}
      </S.Wrap>
    </>
  )
}
export default CheckBoxRound
