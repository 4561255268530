import { useMemo } from 'react'

/**
 * 필수값이 비어있는지 체크, 두번째 부터의 값들은 옵셔널일 경우 입력함
 *
 * @template T
 * @param {T} value
 * @param {...Array<keyof T>} optional
 * @return {*}  {boolean}
 */
export const emptyCheck = <T extends object>(
  value?: T,
  ...optional: Array<keyof T>
): boolean => {
  if (!value) return true
  let keysArray: Array<keyof T> = Object.keys(value) as Array<keyof T>
  return keysArray.some((keyName) => {
    if (optional.includes(keyName)) return false
    if (typeof value[keyName] === 'boolean') return false
    if (typeof value[keyName] === 'number') return false
    let checkArray = value[keyName]
    if (Array.isArray(checkArray) && (checkArray as Array<any>).length < 1)
      return true

    if (!value[keyName]) return true
    return false
  })
}

/**
 * 필수값이 비어있는지 체크, 두번째 부터의 값들은 옵셔널일 경우 입력함
 *
 * @template T
 * @param {T} value
 * @param {...Array<keyof T>} optional
 * @return {*}  {boolean}
 */
const useEmptyCheck = <T extends object>(
  value?: T,
  ...optional: Array<keyof T>
): boolean => {
  const trueWhenEmpty = useMemo(() => {
    return emptyCheck(value, ...optional)
  }, [value, optional])
  return trueWhenEmpty
}

export default useEmptyCheck
