import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const UploadImageRowStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  Files: styled.div`
    max-width: calc(100% - 110px);
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    gap: 12px;
    ${addCssProps}
  `,
  File: styled.div`
    position: relative;
    ${typography['Body/Large/Underline bold']}
    color: ${colors['Primary/Default']};
    border-bottom: 1px solid ${colors['Primary/Default']};
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
}

export default UploadImageRowStyle
