import { css } from '@emotion/react'
import Badge from 'components/Badge'
import { PrimaryButton } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import DownloadSpreadSheet from 'components/DownloadSpreadSheet'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import PeriodDropdownFilter from 'components/TableComponent/PeriodDropdownFilter'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  isTimeFilter,
} from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import TableCell from 'components/TableComponent/TableCell'
import T from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import dayjs from 'dayjs'
import useSearchFilter from 'hooks/useSearchFilter'
import {
  APIPointListData,
  POINT_QUERY_DROPDOWN_LIST,
  POINT_STATE,
  POINT_STATE_BADGE_COLOR_SET,
  POINT_STATE_DROPDOWN_LIST,
  PointListItemType,
} from 'pages/Support/Point/PointList/PointList.types'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow, TXT } from 'themes/Shared.styles'
import {
  blobGetXlsx,
  cn,
  isObjectKeyType,
  parserForPeriodQuery,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
} from 'utils/helpers'

import { rebuildSpreadSheetDataForApi } from 'components/DownloadSpreadSheet/SelectFilterForSpreadSheet/SelectFilterForSpreadSheet'
import S from './PointList.styles'
export interface PointListProps {}

const PointListTableConfigs: TableCellConfig<PointListItemType>[] = [
  {
    _type: 'dropdown',
    _id: 'pointState',
    _label: '포인트 상태',
    _data: POINT_STATE_DROPDOWN_LIST,
    _position: 'center',
    _size: 106,
    _value: '',
  },
  {
    _type: 'order',
    _id: 'createdAt',
    _label: '일시',
    _order: 'INITIAL',
    _position: 'left',
    _size: 160,
  },
  {
    _type: 'default',
    _id: 'content',
    _label: '내용',
    _position: 'left',
    _size: 583,
  },
  {
    _type: 'default',
    _id: 'member',
    _label: '회원명',
    _position: 'left',
    _size: 100,
  },
  {
    _type: 'default',
    _id: 'issuer',
    _label: '발급자명',
    _position: 'left',
    _size: 100,
  },
  {
    _type: 'default',
    _id: 'pointAmount',
    _label: '포인트',
    _position: 'right',
    _size: 118,
  },
]

// Parser for API response data to table row data
const parserForPointListTable = (
  response: DoubleDListWithPaging<APIPointListData>
) => {
  const rebuild = response.list.map((row: APIPointListData, index: number) => {
    return {
      id: row.id,
      pointState: POINT_STATE[row.type],
      createdAt: dayjs(row.createdAt).format('YYYY-MM-DD(ddd) HH:mm'),
      content: row.note,
      member: row.name,
      issuer: row.issuerName,
      pointAmount: row.amount,
    } as PointListItemType
  })
  return {
    _list: rebuild,
    _cursor: disposeCursor(response.paging.cursor),
  }
}

export const PointList = (props: PointListProps) => {
  const [selectedId] = useState<string>('')
  const navigate = useNavigate()

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: PERIOD_DROPDOWN_INITIAL_VALUE,
      queryList: POINT_QUERY_DROPDOWN_LIST,
    },
    (
      {
        startAt,
        endAt,
        month,
        year,
        periodType,
        searchType,
        searchValue,
        ...rest
      },
      queryName
    ) => {
      return {
        period: {
          start: startAt ? dayjs(startAt, 'YYYY-MM-DD') : null,
          end: endAt ? dayjs(endAt, 'YYYY-MM-DD') : null,
        },
        monthValue: month ?? '',
        yearValue: year ?? '',
        periodType: isTimeFilter(periodType) ? periodType : 'all',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    ({ queryName = 'search', terms = '', ...rest }) => {
      return removeUndefined({
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        ...parserForPeriodQuery(rest),
      })
    }
  )
  // query -> 백엔드 api url
  const apiURI = useMemo(() => {
    // EDIT:THIS
    return `/point/list?take=10${
      window.location.search ? '&' + searchParams.toString() : ''
    }`
  }, [searchParams])
  return (
    <ContentsBox _type="relative">
      <S.Wrapper>
        {/* TopInfo */}
        {/* TableTitle */}
        <TableTitle
          _left={'포인트 관리'}
          _right={
            <FlexRow gap={16}>
              <DownloadSpreadSheet
                _emitValue={async (value) => {
                  const rebuild = rebuildSpreadSheetDataForApi(value)
                  blobGetXlsx(
                    'order',
                    `/point/list/excel${rebuild.queryString}`,
                    '포인트_목록'
                  )
                }}
                _noPeriod
                _dropdownFilters={[
                  {
                    label: '필터',
                    value: '전체',
                    key: 'status',
                    list: {
                      전체: '전체',
                      발행: '발행',
                      사용: '사용',
                      적립: '적립',
                      기간만료: '기간만료',
                    },
                  },
                ]}
                _tableConfig={['일시', '사유', '회원명', '발급자명', '포인트']}
              />
              <PrimaryButton
                onClick={() => navigate('/customer/point/publish')}
                _css={css`
                  width: 160px;
                  height: 40px;
                `}
              >
                포인트 발급
              </PrimaryButton>
            </FlexRow>
          }
        />
        {/* Filter */}
        <FilterPart<PeriodDropdownValuesType>
          _value={initialValues}
          _placeholder="주문자명 검색"
          _queryNameList={POINT_QUERY_DROPDOWN_LIST}
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _FilterContents={({ _value, ...props }) => {
            return <PeriodDropdownFilter _value={_value} {...props} />
          }}
        />
        {/* Table */}
        <RoundFillBox>
          <TableComponent<PointListItemType>
            _type="order"
            _config={PointListTableConfigs}
            _url={apiURI}
            _parser={parserForPointListTable}
            _ListContent={({ _row, _index, _configs }) => {
              const rowId = _row.id ? String(_row.id) : String(_index)
              const isMinusState =
                _row.pointState === '기간만료' ||
                _row.pointState === '포인트 사용'
              return (
                <T.Row
                  className={cn({
                    active: selectedId === rowId,
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    // set_selectedId(rowId)
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  {_configs.map((item, columnIndex) => {
                    const _id = item._id
                    const _value = _row[item._id]

                    return (
                      <TableCell
                        key={'body' + _index + columnIndex}
                        _size={item._size}
                        _position={item._position}
                      >
                        {_id === 'pointState' &&
                        isObjectKeyType(POINT_STATE_BADGE_COLOR_SET, _value) ? (
                          <>
                            <Badge {...POINT_STATE_BADGE_COLOR_SET[_value]}>
                              {_value}
                            </Badge>
                          </>
                        ) : _id === 'pointAmount' ? (
                          <TXT
                            _textStyle="Body/Small/Bold"
                            _color={
                              isMinusState
                                ? 'Danger/Dark'
                                : 'Grayscale/Black light'
                            }
                          >
                            {`${isMinusState ? '- ' : ''}${thousand(_value)}원`}
                          </TXT>
                        ) : (
                          <div>{_value}</div>
                        )}
                      </TableCell>
                    )
                  })}
                </T.Row>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrapper>
    </ContentsBox>
  )
}
