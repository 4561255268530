import { css } from '@emotion/react'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import UnderlineDropdown from 'components/Dropdown/UnderlineDropdown'
import PeriodPicker from 'components/PeriodPicker'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import { ContentRadiusBox } from 'themes/Shared.styles'
import { CATEGORIES_DROPDOWN_LIST } from '../CouponPublish.types'
import { DiscountByAmountProps } from './DiscountByAmount.types'
const DiscountByAmount = ({
  _css,
  inputs,
  handleInput,
  ...props
}: DiscountByAmountProps) => {
  return (
    <ContentRadiusBox className="radius-box-gap">
      <BoxRowComponent _label="사용 기간 *">
        <PeriodPicker
          _css={css`
            .end-date {
              .mini-calendar {
                right: 0px;
              }
            }
          `}
          _period={{ start: inputs.startAt, end: inputs.endAt }}
          _emitValue={(value) => {
            handleInput('startAt', value.start)
            handleInput('endAt', value.end)
          }}
          _placeholder={{ left: '시작일', right: '종료일' }}
        />
      </BoxRowComponent>
      <BoxRowComponent _label={'쿠폰명 *'}>
        <UnderlineInput
          placeholder="쿠폰 제목 입력"
          value={inputs.name}
          handleValue={(value) => handleInput('name', value)}
        />
      </BoxRowComponent>
      <BoxRowComponent _label={'최소 구매 금액 *'}>
        <UnderlineNumberInput
          placeholder="숫자만 입력"
          value={inputs.minAmount}
          handleValue={(value) => handleInput('minAmount', value)}
          suffix="원"
        />
      </BoxRowComponent>
      <BoxRowComponent _label={'할인금액 (원) *'}>
        <UnderlineNumberInput
          placeholder="숫자만 입력"
          value={inputs.amount}
          handleValue={(value) => handleInput('amount', value)}
          suffix="원"
        />
      </BoxRowComponent>
      <BoxRowComponent _label={'상품 카테고리 *'}>
        <UnderlineDropdown
          _value={inputs.categoryName}
          _list={CATEGORIES_DROPDOWN_LIST}
          _placeholder={'카테고리 선택'}
          _emitValue={(value) => {
            handleInput('categoryName', String(value))
          }}
        />
      </BoxRowComponent>
    </ContentRadiusBox>
  )
}
export default DiscountByAmount
