import { css } from '@emotion/react'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import dayjs from 'dayjs'
import { ContentRadiusBox } from 'themes/Shared.styles'
import { thousand } from 'utils/helpers'
import S from './UserPopupItems.styles'
import {
  CouponPopupContentsProps,
  PointPopupContentsProps,
  RecommendsPopupContentsProps,
} from './UserPopupItems.types'

export const CouponPopupContents = ({
  _css,
  _items,
  ...props
}: CouponPopupContentsProps) => {
  return (
    <S.Wrap _css={_css} {...props}>
      {_items.map((item) => {
        return (
          <ContentRadiusBox key={item.id}>
            <BoxRowComponent _label={'쿠폰명'} _contents={item.name} />
            <BoxRowComponent
              _label={'유효 기간'}
              _contents={`${dayjs(item.validDate.start).format(
                'YYYY-MM-DD'
              )} ~ ${dayjs(item.validDate.end).format('YYYY-MM-DD')}`}
            />
            <BoxRowComponent
              _label={'할인율 / 금액'}
              _contents={`${thousand(item.discount.amount)}${
                item.discount.type === 'percent' ? '%' : '원'
              }`}
            />
            <BoxRowComponent
              _label={'카테고리'}
              _contents={item.categoryName}
            />
            <BoxRowComponent
              _label={'발급일시'}
              _contents={
                item.createdAt
                  ? dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')
                  : null
              }
            />
          </ContentRadiusBox>
        )
      })}
    </S.Wrap>
  )
}
export const PointPopupContents = ({
  _css,
  _earnedPoint,
  _items,
  ...props
}: PointPopupContentsProps) => {
  return (
    <S.Wrap _css={_css} {...props}>
      <ContentRadiusBox
        _css={css`
          margin-bottom: 8px;
        `}
      >
        <BoxRowComponent
          _label={'구매 적립 포인트'}
          _contents={`${thousand(_earnedPoint)}원`}
        />
      </ContentRadiusBox>
      {_items.map((item) => {
        return (
          <ContentRadiusBox key={item.id}>
            <BoxRowComponent _label={'발급 사유'} _contents={item.reason} />
            <BoxRowComponent
              _label={'유효 기간'}
              _contents={`${dayjs(item.validDate.start).format(
                'YYYY-MM-DD'
              )} ~ ${dayjs(item.validDate.end).format('YYYY-MM-DD')}`}
            />
            <BoxRowComponent
              _label={'포인트 금액'}
              _contents={`${thousand(item.amount)}원`}
            />
            <BoxRowComponent
              _label={'발급일시'}
              _contents={
                item.createdAt
                  ? dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')
                  : null
              }
            />
          </ContentRadiusBox>
        )
      })}
    </S.Wrap>
  )
}

export const RecommendsPopupContents = ({
  _css,
  _items,
  ...props
}: RecommendsPopupContentsProps) => {
  return (
    <S.Wrap _css={_css} {...props}>
      {_items.map((item) => {
        return (
          <ContentRadiusBox key={item.id}>
            <BoxRowComponent _label={'회원명'} _contents={item.name} />
            <BoxRowComponent _label={'휴대폰 번호'} _contents={item.phone} />
            <BoxRowComponent
              _label={'일자'}
              _contents={
                item.createdAt
                  ? dayjs(item.createdAt).format('YYYY-MM-DD')
                  : null
              }
            />
          </ContentRadiusBox>
        )
      })}
    </S.Wrap>
  )
}
