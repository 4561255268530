import { ExtraRouteObject } from 'pages/AuthorizedPages'
import { useEffect, useMemo, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

// export type MyPageLabelType = typeof authorizedPages
// export const isMyPageRoute = (
//   value?: string | null
// ): value is keyof MyPageLabelType => {
//   return (
//     value !== null &&
//     value !== undefined &&
//     (Object.keys(myPageLabel) as Array<keyof MyPageLabelType>).some(
//       (item) => item === (value as keyof MyPageLabelType)
//     )
//   )
// }
const useAllMatch = (...urlList: string[]) => {
  const location = useLocation()
  const match = useMemo(() => {
    return urlList.some((url) => matchPath(url, location.pathname))
  }, [urlList, location])
  return match
}
export const useFindMatch = (...routeList: ExtraRouteObject[]) => {
  const location = useLocation()
  const match = useMemo(() => {
    return routeList.find((item) =>
      matchPath({ path: item.path, end: true }, location.pathname)
    )
  }, [routeList, location])
  return match
}

export const workStatusParams = [
  'ordered',
  'waiting',
  'ongoing',
  'completed',
  'cancelled',
] as const
export const workStatusToKorean = {
  ordered: '주문완료',
  waiting: '시공 대기',
  ongoing: '시공중',
  completed: '시공 완료',
  cancelled: '시공 취소',
} as const
export type WorkStatusParams = typeof workStatusParams[number]
export const isWorkStatusParams = (
  value: string | null | undefined
): value is WorkStatusParams => {
  return (
    value !== null &&
    value !== undefined &&
    workStatusParams.includes(value as WorkStatusParams)
  )
}
export const useWorkPathMatch = (...authorizedPages: ExtraRouteObject[]) => {
  const [workStatus, set_workStatus] = useState<WorkStatusParams | null>(null)
  const match = useFindMatch(...authorizedPages)
  useEffect(() => {
    if (match && isWorkStatusParams(match.code)) {
      set_workStatus(match.code)
    } else {
      set_workStatus(null)
    }
  }, [match])
  return workStatus
}
export default useAllMatch
