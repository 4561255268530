import { css } from '@emotion/react'
import DatePicker from 'components/DatePicker'
import Dropdown from 'components/Dropdown'
import RadioButton from 'components/RadioButton'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  TIME_FILTER_DROPDOWN,
  monthOptions,
  yearOption,
} from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import useMatchInput from 'hooks/useMatchInput'
import S from '../DownloadSpreadSheet.styles'

import { FlexColumn, FlexRow } from 'themes/Shared.styles'

interface ByDatesProps {
  _emitValue: (value: PeriodDropdownValuesType | null) => void
}
const ByDates = ({ _emitValue, ...props }: ByDatesProps) => {
  const { inputs, handleInput, handleValues } = useMatchInput(
    PERIOD_DROPDOWN_INITIAL_VALUE
  )

  const handleEmit = (value: PeriodDropdownValuesType) => {
    _emitValue(value)
    return value
  }
  return (
    <>
      <FlexColumn gap={12}>
        <h4>검색 기간</h4>
        <FlexRow
          gap={16}
          justifyContent={'space-between'}
          _css={css`
            & > div {
              flex: 1;
            }
          `}
        >
          {(
            Object.keys(TIME_FILTER_DROPDOWN) as KeyOf<
              typeof TIME_FILTER_DROPDOWN
            >[]
          ).map((item) => {
            return (
              <RadioButton
                key={item}
                _mini
                _active={inputs.periodType === TIME_FILTER_DROPDOWN[item]}
                onClick={() => {
                  let nextValue = {
                    ...PERIOD_DROPDOWN_INITIAL_VALUE,
                    periodType: TIME_FILTER_DROPDOWN[item],
                  }
                  handleValues(nextValue)
                  _emitValue(nextValue)
                }}
              >
                {item}
              </RadioButton>
            )
          })}
        </FlexRow>
      </FlexColumn>
      {inputs.periodType !== 'all' && (
        <FlexColumn gap={12}>
          <h4>기간별 검색</h4>
          <S.Filter>
            {inputs.periodType === 'month' && (
              <Dropdown.Underline
                _list={monthOptions}
                _value={inputs.monthValue}
                _emitValue={(value) => {
                  handleInput('monthValue', value, handleEmit)
                }}
                _css={css`
                  width: 120px;
                  min-width: 120px;
                `}
              />
            )}
            {inputs.periodType === 'year' && (
              <Dropdown.Underline
                _list={yearOption}
                _value={inputs.yearValue}
                _emitValue={(value) => {
                  handleInput('yearValue', value, handleEmit)
                }}
                _css={css`
                  width: 120px;
                  min-width: 120px;
                `}
              />
            )}
            {inputs.periodType === 'period' && (
              <S.PeriodWrap>
                <DatePicker
                  _value={inputs.period.start}
                  _emitValue={(value) => {
                    handleInput(
                      'period',
                      {
                        ...inputs.period,
                        start: value,
                      },
                      handleEmit
                    )
                  }}
                  _comparisonDate={{
                    type: 'after',
                    date: inputs.period.end,
                  }}
                  _css={css`
                    flex: 1;
                  `}
                />
                ~
                <DatePicker
                  _value={inputs.period.end}
                  _emitValue={(value) => {
                    handleInput(
                      'period',
                      {
                        ...inputs.period,
                        end: value,
                      },
                      handleEmit
                    )
                  }}
                  _comparisonDate={{
                    type: 'before',
                    date: inputs.period.start,
                  }}
                  _css={css`
                    flex: 1;
                    .mini-calendar {
                      right: 0;
                    }
                  `}
                />
              </S.PeriodWrap>
            )}
          </S.Filter>
        </FlexColumn>
      )}
    </>
  )
}
export default ByDates
