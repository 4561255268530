import Authorization from 'templates/Authorization'

const Login = () => {
  return (
    <>
      <Authorization _isAdmin={true} />
    </>
  )
}
export default Login
