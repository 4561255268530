import styled from '@emotion/styled/macro'
import { AuthorizationDefault } from 'templates/Authorization/Authorization.styles'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const FindUserComponentStyle = {
  Wrap: styled.div`
    ${AuthorizationDefault}
    ${addCssProps}
  `,
  Contents: styled.div`
    padding: 32px 32px 72px;
  `,

  MiniButton: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 30px;
    ${typography['Body/Caption/Regular']}
    color: ${colors['Grayscale/Gray Default']};
    & > button {
      ${typography['Body/Small/Bold']}
      user-select: none;
      cursor: pointer;
    }
  `,

  CurrentId: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 16px;
    gap: 4px;
    background-color: ${colors['Grayscale/Background Light']};
    span {
      ${typography['Body/Large/Regular']}
      color: ${colors['Grayscale/Gray Dark']};
      &.id {
        ${typography['Body/Large/Bold']}
        color: ${colors['Primary/Default']};
      }
    }
  `,
}
export default FindUserComponentStyle
