import { css } from '@emotion/react'
import { ImagesServiceSort, ImagesServiceSwap } from 'assets'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import { useAppDispatch } from 'hooks/reduxHooks'
import { clone } from 'lodash-es'
import { MutableRefObject, useRef, useState } from 'react'
import { setToast } from 'store/toastSlice'
import {
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { cn, moveArray, objectKeys, thousand } from 'utils/helpers'
import S from './EtcServices.styles'
import { EtcServicesProps } from './EtcServices.types'
type ExtraValuesType = {
  id?: string
  title: string
  price: number | null
}
const initExtraValue = {
  title: '',
  price: null,
}
const EtcServices = ({
  _css,
  inputs,
  handleInput,
  ...props
}: EtcServicesProps) => {
  const dispatch = useAppDispatch()
  const dragStartElement = useRef() as MutableRefObject<HTMLDivElement>
  const dragStartIndex = useRef(0) as MutableRefObject<number>
  const [draggable, set_draggable] = useState(false)
  const [selectedExtra, set_selectedExtra] = useState<
    ExtraValuesType & { index?: number }
  >()
  const handleDragOrderList = (currentIndex: number, nextIndex: number) => {
    if (currentIndex === nextIndex) {
      return
    }
    dragStartIndex.current = nextIndex
    const result = moveArray(inputs.options.option, currentIndex, nextIndex)
    handleInput('options', (prev) => ({ ...prev.options, option: result }))
  }
  const { dropdownsByType } = inputs
  return (
    <>
      {dropdownsByType &&
        objectKeys(dropdownsByType).map((keyName, keyIndex) => {
          const currentValue = dropdownsByType[keyName]
          return (
            <BoxLayout
              _title={`${currentValue?.label}*`}
              key={keyName + keyIndex}
            >
              <ContentRadiusBox
                _css={css`
                  & > div {
                    max-width: 560px;
                  }
                `}
              >
                <BoxRowComponent _label={'제목'}>
                  <UnderlineInput
                    placeholder="내용 입력"
                    value={currentValue?.label}
                    handleValue={(value) => {
                      handleInput('dropdownsByType', (prev) =>
                        prev.dropdownsByType
                          ? {
                              ...prev.dropdownsByType,
                              [keyName]: {
                                ...prev.dropdownsByType[keyName],
                                label: value,
                              },
                            }
                          : prev.dropdownsByType
                      )
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'서브 텍스트'}>
                  <UnderlineInput
                    placeholder="내용 입력"
                    value={currentValue?.subText}
                    handleValue={(value) => {
                      handleInput('dropdownsByType', (prev) =>
                        prev.dropdownsByType
                          ? {
                              ...prev.dropdownsByType,
                              [keyName]: {
                                ...prev.dropdownsByType[keyName],
                                subText: value,
                              },
                            }
                          : prev.dropdownsByType
                      )
                    }}
                  />
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
          )
        })}
      <BoxLayout _title={'추가 옵션*'}>
        <FlexColumn gap={12}>
          <ContentRadiusBox
            _css={css`
              & > div {
                max-width: 560px;
              }
            `}
          >
            <BoxRowComponent _label={'제목'}>
              <UnderlineInput
                placeholder="내용 입력"
                value={inputs.options.title}
                handleValue={(value) => {
                  handleInput('options', (prev) => ({
                    ...prev.options,
                    title: value,
                  }))
                }}
              />
            </BoxRowComponent>
            <BoxRowComponent _label={'서브 텍스트'}>
              <UnderlineInput
                placeholder="내용 입력"
                value={inputs.options.subText}
                handleValue={(value) => {
                  handleInput('options', (prev) => ({
                    ...prev.options,
                    subText: value,
                  }))
                }}
              />
            </BoxRowComponent>
          </ContentRadiusBox>

          <FlexRow
            gap={12}
            alignItems="flex-start"
            _css={css`
              & > div {
                flex: 1 0;
                max-width: calc(50% - 6px);
              }
            `}
          >
            <ContentRadiusBox
              gap={24}
              _css={css`
                height: 488px;
                position: relative;
                padding-bottom: 52px;
              `}
            >
              <BoxRowComponent
                _label={
                  <FlexRow gap={6}>
                    <TXT
                      _color="Grayscale/Gray Default"
                      _textStyle="Menu/Bold"
                    >{`옵션 목록`}</TXT>
                    <TXT
                      _color="Primary/Default"
                      _textStyle="Body/Caption/Bold"
                    >{`총 ${inputs.materials.length}개`}</TXT>
                  </FlexRow>
                }
              >
                <GrayScaleOutline
                  width={84}
                  height={40}
                  onClick={() => {
                    set_selectedExtra(clone(initExtraValue))
                  }}
                >
                  추가
                </GrayScaleOutline>
              </BoxRowComponent>
              {inputs.options.option.length < 1 ? (
                <S.NotFoundItem>옵션 그룹을 추가하세요.</S.NotFoundItem>
              ) : (
                <FlexColumn gap={12} justifyContent="space-between">
                  <FlexColumn
                    gap={12}
                    _css={css`
                      padding: 12px 0;
                    `}
                  >
                    {inputs.options.option.map((extra, extraIndex) => (
                      <S.ExtraRow
                        key={`extra${extra.id}${extraIndex}`}
                        className={cn(
                          {
                            active:
                              selectedExtra?.index === extraIndex && !draggable,
                            draggable,
                          },
                          'draggable-option-item'
                        )}
                        onClick={() => {
                          if (draggable) return
                          set_selectedExtra({ ...extra, index: extraIndex })
                        }}
                        onDrag={(e) => {
                          const eventTarget = e.currentTarget
                          if (dragStartIndex.current === extraIndex) {
                            eventTarget.classList.add('drag-item')
                          }
                        }}
                        onDragLeave={(e) => {
                          const eventTarget = e.currentTarget
                          eventTarget.classList.remove('drag-item')
                        }}
                        onDragEnter={(e) => {
                          e.preventDefault()
                          const eventTarget = e.currentTarget
                          if (
                            eventTarget.classList.contains(
                              'draggable-option-item'
                            )
                          ) {
                            if (e.target !== dragStartElement.current) {
                              handleDragOrderList(
                                dragStartIndex.current,
                                extraIndex
                              )
                            }
                          }
                        }}
                        onDragOver={(e) => {
                          e.preventDefault()
                        }}
                        onDragEnd={(e) => {
                          const eventTarget = e.currentTarget
                          eventTarget.classList.remove('dragging')
                          eventTarget.classList.remove('drag-item')
                        }}
                        onDragStart={(e) => {
                          const currentTarget = e.currentTarget
                          currentTarget.classList.add('dragging')
                          dragStartIndex.current = extraIndex
                          dragStartElement.current =
                            e.currentTarget as HTMLDivElement
                          e.dataTransfer.setData(
                            'text/plain',
                            String(extraIndex)
                          )
                        }}
                        draggable={draggable}
                      >
                        {draggable && (
                          <img src={ImagesServiceSort} alt="sort-able" />
                        )}
                        <div className="box">
                          <div className="title">{extra.title}</div>
                          <div className="option">
                            {thousand(extra.price ?? 0)}원
                          </div>
                        </div>
                      </S.ExtraRow>
                    ))}
                  </FlexColumn>
                </FlexColumn>
              )}
              <S.SwapButtonWrap>
                <S.SwapButton
                  onClick={() => {
                    set_draggable((prev) => !prev)
                  }}
                >
                  순서 변경
                  <img src={ImagesServiceSwap} alt="swap" />
                </S.SwapButton>
              </S.SwapButtonWrap>
            </ContentRadiusBox>
            <ContentRadiusBox
              _css={css`
                height: 488px;
                position: relative;
              `}
              gap={12}
            >
              {selectedExtra ? (
                <S.ExtraDetails>
                  <div>
                    <BoxRowComponent
                      _css={css`
                        & > div {
                          flex: none;
                        }
                      `}
                      _label={
                        <TXT
                          _color="Grayscale/Gray Default"
                          _textStyle="Body/Small/Bold"
                        >
                          옵션 정보
                        </TXT>
                      }
                    >
                      <div></div>
                    </BoxRowComponent>
                    <BoxRowComponent _label={'옵션 이름'}>
                      <UnderlineInput
                        placeholder="내용 입력"
                        value={selectedExtra.title}
                        handleValue={(value) =>
                          set_selectedExtra((prev) =>
                            prev ? { ...prev, title: value } : prev
                          )
                        }
                      />
                    </BoxRowComponent>
                    <BoxRowComponent _label={'금액'}>
                      <UnderlineNumberInput
                        placeholder="내용 입력"
                        suffix="원"
                        value={selectedExtra.price}
                        handleValue={(value) =>
                          set_selectedExtra((prev) =>
                            prev ? { ...prev, price: value } : prev
                          )
                        }
                      />
                    </BoxRowComponent>
                  </div>
                  <S.ExtraButtons>
                    {typeof selectedExtra.index === 'number' ? (
                      <>
                        <GrayScaleFill
                          onClick={() => {
                            if (typeof selectedExtra.index !== 'number') return
                            let modified = clone(selectedExtra)
                            // set_selectedExtra(clone(initExtraValue))
                            handleInput('options', (prev) => {
                              let current = prev.options.option
                              return {
                                ...prev.options,
                                option: current.filter(
                                  (_item, itemIndex) =>
                                    itemIndex !== modified.index
                                ),
                              }
                            })
                          }}
                        >
                          삭제
                        </GrayScaleFill>
                        <GrayScaleOutline
                          disabled={
                            typeof selectedExtra.price !== 'number' ||
                            !selectedExtra.title
                          }
                          onClick={() => {
                            if (typeof selectedExtra.index !== 'number') return
                            let modified = clone(selectedExtra)
                            // set_selectedExtra(clone(initExtraValue))
                            handleInput('options', (prev) => {
                              let current = prev.options.option
                              return {
                                ...prev.options,
                                option: current.map((item, itemIndex) =>
                                  itemIndex === modified.index ? modified : item
                                ),
                              }
                            })
                          }}
                        >
                          수정
                        </GrayScaleOutline>
                      </>
                    ) : (
                      <PrimaryButton
                        disabled={
                          typeof selectedExtra.price !== 'number' ||
                          !selectedExtra.title
                        }
                        onClick={() => {
                          if (
                            inputs.options.option.some(
                              (item) => item.title === selectedExtra.title
                            )
                          ) {
                            dispatch(
                              setToast(['중복된 이름이 있습니다.', 'error'])
                            )
                            return
                          }
                          let addExtra = {
                            ...clone(selectedExtra),
                          }
                          set_selectedExtra(undefined)
                          handleInput('options', (prev) => {
                            let current = prev.options.option
                            return {
                              ...prev.options,
                              option: [...current, addExtra],
                            }
                          })
                        }}
                      >
                        추가하기
                      </PrimaryButton>
                    )}
                  </S.ExtraButtons>
                </S.ExtraDetails>
              ) : (
                <S.NotFoundItem>옵션 그룹을 추가하세요.</S.NotFoundItem>
              )}
            </ContentRadiusBox>
          </FlexRow>
        </FlexColumn>
      </BoxLayout>
    </>
  )
}
export default EtcServices
