import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'

const PeriodPickerStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    ${addCssProps}
  `,
}

export default PeriodPickerStyle
