import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import { AdditionalCss } from 'themes/styles.type'
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 0;
  & > div {
    &.title {
      ${typography['Body/Large/Regular']}
      color: ${colors['Grayscale/Gray Default']};
    }
    &.sub {
      ${typography['Body/Small/Regular']}
      color: ${colors['Grayscale/Gray Light']};
    }
  }
  ${addCssProps}
`
const Ball = styled.div<{ _css?: AdditionalCss; _mini?: boolean }>`
  position: relative;
  ${({ _mini }) =>
    _mini
      ? `
  --radio-ball-size: 24px;
  --radio-ball-active-size: 10px;`
      : `
  --radio-ball-size: 32px;
  --radio-ball-active-size: 12px;`}
  width: var(--radio-ball-size);
  height: var(--radio-ball-size);
  border-radius: calc(var(--radio-ball-size) / 2);
  border-radius: 50%;
  background-color: #fff;
  box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
`
const RadioButtonStyle = {
  Wrap: styled.div<{ _css?: AdditionalCss; _mini?: boolean }>`
    ${typography['Body/Large/Regular']}
    display: flex;
    align-items: flex-start;
    gap: 8px;
    cursor: pointer;
    user-select: none;
    &.disabled {
      opacity: 0.4;
    }
    color: ${colors['Grayscale/Gray Default']};
    &.active {
      color: ${colors['Grayscale/Black']};
      ${Contents} {
        &.title {
          color: ${colors['Grayscale/Black']};
        }
        &.sub {
          color: ${colors['Grayscale/Gray Default']};
        }
      }
      ${Ball} {
        box-shadow: none;
        background-color: ${colors['Primary/Default']};
        &::after {
          content: '';
          width: var(--radio-ball-active-size);
          height: var(--radio-ball-active-size);
          border-radius: calc(var(--radio-ball-active-size) / 2);
          border-radius: 50%;
          background-color: #fff;
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    ${({ _css }) =>
      typeof _css === 'string' ? css(_css) : _css ? _css : css``}
  `,
  BallWrap: styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Ball,
  Contents,
}

export default RadioButtonStyle
