import ContentsBox from 'components/ContentsBox'
import { RoundBoxRow } from 'components/ContentsBox/ContentsBox.styles'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { cn } from 'utils/helpers'

interface DetailsDefaultProps extends DivAttributes {
  _css?: AdditionalCss
  _title: string | JSX.Element
  _outsideTop?: JSX.Element
  _leftTab?: JSX.Element
  _topLeftControls?: JSX.Element
  _leftContents?: JSX.Element
  _rightContents?: JSX.Element
  _topRightHeader?: JSX.Element
  _bottomRightControls?: JSX.Element
}

const DetailsDefault = ({
  _title,
  _outsideTop,
  _topLeftControls,
  _bottomRightControls,
  _leftContents,
  _rightContents,
  _topRightHeader,
  _leftTab,
  _css,
  ...props
}: DetailsDefaultProps) => {
  const { Layout: L } = S
  return (
    <>
      <ContentsBox>
        <L.Wrap _css={_css} {...props}>
          {_outsideTop}
          <RoundBoxRow className="left-box">
            <L.LeftHeader>
              {typeof _title === 'string' && <h1>{_title}</h1>}
              {typeof _title !== 'string' && _title}
              {_topLeftControls}
            </L.LeftHeader>
            {_leftTab && <L.LeftTab>{_leftTab}</L.LeftTab>}
            <L.LeftContents
              id="left-contents"
              className={cn({ hasTab: Boolean(_leftTab) })}
            >
              {_leftContents}
            </L.LeftContents>
          </RoundBoxRow>
          <RoundBoxRow className="right-box">
            {_topRightHeader}
            <L.RightContents id="right-contents">
              {_rightContents}
            </L.RightContents>
            {_bottomRightControls}
          </RoundBoxRow>
        </L.Wrap>
      </ContentsBox>
    </>
  )
}
export default DetailsDefault
