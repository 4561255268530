import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export const USER_TYPE_DROPDOWN_LIST = {
  전체: 'all',
  '정기 회원': 'REGULAR',
  '일반 회원': 'CUSTOMER',
} as const
export const USER_STATE_DROPDOWN_LIST = {
  정상: 'normal',
  휴면: 'dormant',
  탈퇴: 'delete',
} as const

export type UserDropdownKeyType = KeyOf<typeof USER_TYPE_DROPDOWN_LIST>
export type UserStateKeyType = KeyOf<typeof USER_STATE_DROPDOWN_LIST>

export const isUserType = (
  value: any
): value is typeof USER_TYPE_DROPDOWN_LIST[UserDropdownKeyType] => {
  return (
    value !== null &&
    Object.values(USER_TYPE_DROPDOWN_LIST).some((item) => item === value)
  )
}
export const isUserState = (
  value: any
): value is typeof USER_STATE_DROPDOWN_LIST[UserStateKeyType] => {
  return (
    value !== null &&
    Object.values(USER_STATE_DROPDOWN_LIST).some((item) => item === value)
  )
}
export type UserFilterListType = {
  userType: typeof USER_TYPE_DROPDOWN_LIST[UserDropdownKeyType] | 'all'
  userStatus: typeof USER_STATE_DROPDOWN_LIST[UserStateKeyType]
}
export const initialUserFilterValues: UserFilterListType = {
  userType: 'all',
  userStatus: 'normal',
}
export interface UserListProps extends DivAttributes {
  _css?: AdditionalCss
}

export type ApiUserListItem = {
  id: string
  role: string
  name: string
  phone: string
  createdAt: string
}
