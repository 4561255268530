import { AdditionalCss, DivAttributes } from 'themes/styles.type'
export type RecommenderValueType = {
  name: string
  phone: string
  settlementAmount: number | null
}
export const RECOMMENDER_INITIAL_VALUE: RecommenderValueType = {
  name: '',
  phone: '',
  settlementAmount: null,
}
export interface AddRecommenderPopupProps extends DivAttributes {
  _css?: AdditionalCss
  _onClose: () => void
}
