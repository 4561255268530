import { useWorkPathMatch } from 'hooks/useAllMatch'
import LoadingPage from 'layout/LoadingPage'
import { authorizedPages } from 'pages/AuthorizedPages'
import WorkListCommon from './WorkListCommon/WorkListCommon'

const WorkList = () => {
  const workStatus = useWorkPathMatch(...authorizedPages)
  if (!workStatus) return <LoadingPage />
  return (
    <>
      <WorkListCommon _type={workStatus} />
    </>
  )
}
export default WorkList
