import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const ExtraFeesInServiceStyle = {
  Wrap: styled.div`
    padding: 32px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    height: 644px;
    & > div {
      padding: 0 32px;
      flex: 1;
      height: 100%;
      overflow-y: auto;
    }
    ${addCssProps}
  `,
  ServiceItem: styled.div`
    cursor: pointer;
    border-radius: 16px;
    width: 100%;
    border: 1px solid ${colors['Grayscale/Gray Lighter']};
    padding: 16px;
    .title {
      color: ${colors['Grayscale/Black light']};
      ${typography['Title/Bold']}
    }
    .price {
      color: ${colors['Grayscale/Gray Light']};
      ${typography['Body/Caption/Bold']}
    }
    .add {
      ${typography['Body/Large/Regular']}
    }
    .count {
      ${typography['Body/Large/Bold']}
    }
    &.active {
      border-color: ${colors['Primary/Default']};
      background-color: ${colors['Primary/Lighter']};
    }
    &:hover {
      border-color: ${colors['Primary/Default']};
      background-color: ${colors['Primary/Lighter']};
    }
  `,
  AddButton: styled.img`
    margin: 0 auto;
    cursor: pointer;
    transition: 0.15s;
    &:hover {
      opacity: 0.4;
    }
  `,
  Background: styled.div`
    z-index: 9999;
    background-color: ${colors['ETC/Dim']};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  `,
  NoContent: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${typography['Title/Bold']}
    color: ${colors['Grayscale/Gray Default']};
    gap: 12px;
  `,
}

export default ExtraFeesInServiceStyle
