import {
  AbilityItem,
  Key_Of_ADMIN_TYPE,
  PageAuthority,
} from 'types/api/samples/adminWeb'

const INITIAL_ABILITY: AbilityItem[] = [
  { page: 'home', permission: true },
  { page: 'ongoing', permission: true },
  { page: 'completed', permission: true },
  { page: 'reservation', permission: true },
  { page: 'techs-queue', permission: true },
  { page: 'techs-history', permission: true },
  { page: 'partner-queue', permission: true },
  { page: 'partner-history', permission: true },
  { page: 'unpaid', permission: true },
  { page: 'user', permission: true },
  { page: 'partner', permission: true },
  { page: 'building', permission: true },
  { page: 'area', permission: true },
  { page: 'setting', permission: true },
  { page: 'funnel', permission: true },
  { page: 'admin', permission: true },
  { page: 'techs', permission: true },
  { page: 'log', permission: true },
  { page: 'coupon', permission: true },
  { page: 'point', permission: true },
  { page: 'event', permission: true },
  { page: 'notice', permission: true },
]
export const ADMIN_INFO_INITIAL_VALUE: APIAdminCreateData = {
  name: '',
  phone: '',
  userName: '',
  pwd: '',
  role: 'MASTER',
  ability: INITIAL_ABILITY,
  office: '',
  isMaterial: false,
}

export interface AuthSettingOptionProps {
  label: string
  code: PageAuthority | 'all'
  active: boolean
  flexStart?: boolean
}

export type AuthSettingCategory =
  | '홈'
  | '시공 관리'
  | '정산 관리'
  | '미수금 관리'
  | '회원 및 건물관리'
  | '서비스 관리'
  | '시스템 관리'
  | '고객지원'

export type DetailAuthSettingPageData = {
  [key in AuthSettingCategory]: AuthSettingOptionProps[]
}
export const DETAIL_AUTH_SETTING_PAGE_DATA_MATCH_LABEL: {
  [key in PageAuthority]: string
} = {
  home: '홈',
  ongoing: '진행중',
  completed: '시공 완료',
  reservation: '예약 캘린더',
  estimates: '견적서',
  ordered: '주문 완료',
  waiting: '시공 대기',
  cancelled: '시공 취소',
  'techs-queue': '숙련공 \n정산 대기',
  'partner-queue': '파트너 \n정산 대기',
  'techs-history': '숙련공 \n정산 내역',
  'partner-history': '파트너 정산 내역',
  unpaid: '미수금 관리',
  user: '회원',
  partner: '파트너',
  building: '건물',
  area: '서비스 지역',
  setting: '서비스 설정',
  funnel: '주문 유입 경로\n설정',
  admin: '관리자',
  techs: '숙련공',
  log: '작업로그',
  coupon: '쿠폰',
  point: '포인트',
  event: '이벤트',
  notice: '공지사항',
}

export const SETTING_OPTIONS: DetailAuthSettingPageData = {
  홈: [{ label: '홈', code: 'home', active: true }],
  '시공 관리': [
    { label: '전체', code: 'all', active: true },
    { label: '견적서', code: 'estimates', active: true },
    { label: '주문 완료', code: 'ordered', active: true },
    { label: '시공 대기', code: 'waiting', active: true },
    { label: '진행중', code: 'ongoing', active: true },
    { label: '시공 완료', code: 'completed', active: true },
    { label: '시공 취소', code: 'cancelled', active: true },
    { label: '예약 캘린더', code: 'reservation', active: true },
  ],
  '정산 관리': [
    { label: '전체', code: 'all', active: true },
    {
      label: `숙련공 \n정산 대기`,
      code: 'techs-queue',
      active: true,
      flexStart: true,
    },
    {
      label: '파트너 \n정산 대기',
      code: 'partner-queue',
      active: true,
      flexStart: true,
    },
    {
      label: '숙련공 \n정산 내역',
      code: 'techs-history',
      active: true,
      flexStart: true,
    },
    { label: '파트너 정산 내역', code: 'partner-history', active: true },
  ],
  '미수금 관리': [{ label: '미수금 관리', code: 'unpaid', active: true }],
  '회원 및 건물관리': [
    { label: '전체', code: 'all', active: true },
    { label: '회원', code: 'user', active: true },
    { label: '파트너', code: 'partner', active: true },
    { label: '건물', code: 'building', active: true },
  ],
  '서비스 관리': [
    { label: '전체', code: 'all', active: true },
    { label: '서비스 지역', code: 'area', active: true },
    { label: '서비스 설정', code: 'setting', active: true },
    {
      label: '주문 유입 경로\n설정',
      code: 'funnel',
      active: true,
      flexStart: true,
    },
  ],
  '시스템 관리': [
    { label: '전체', code: 'all', active: true },
    { label: '관리자', code: 'admin', active: true },
    { label: '숙련공', code: 'techs', active: true },
    { label: '작업로그', code: 'log', active: true },
  ],
  고객지원: [
    { label: '전체', code: 'all', active: true },
    { label: '쿠폰', code: 'coupon', active: true },
    { label: '포인트', code: 'point', active: true },
    { label: '이벤트', code: 'event', active: true },
    { label: '공지사항', code: 'notice', active: true },
  ],
}

// * API types
export interface APIAdminCreateData {
  name: string
  phone: string
  userName: string
  pwd: string
  role: Key_Of_ADMIN_TYPE
  office: string
  ability: AbilityItem[]
  position?: string | null
  task?: string | null
  isMaterial: boolean
}

export interface APIAdminCreateRes {}
