import styled from '@emotion/styled'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

export const SettingStyles = {
  GroupButton: styled.button`
    width: calc(50% - 6px);
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;
    text-align: left;
    ${typography['Title/Bold']}
    color: ${colors['Grayscale/Black light']};
    box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
    &:hover {
      background-color: ${colors['Grayscale/Background Dark']};
    }
    transition: 0.15s;
    &.active {
      box-shadow: inset 0px 0px 0px 2px ${colors['Primary/Default']};
      background-color: ${colors['Primary/Lighter']};
    }
    &:disabled {
      color: ${colors['Grayscale/Gray Lighter']};
      img {
        opacity: 0.4;
      }
    }
    &.disabled {
      color: ${colors['Grayscale/Gray Lighter']};
      img {
        opacity: 0.4;
      }
    }
    position: relative;
  `,
  Thumbnail: styled.img`
    cursor: pointer;
    width: 100px;
    height: auto;
    &:hover {
      opacity: 0.2;
      border: 2px dashed ${colors['Primary/Light']};
    }
  `,
  UploadImageWrap: styled.label`
    input {
      visibility: hidden;
      display: none;
    }
    img {
      object-fit: cover;
      width: 100px;
      height: 100px;
      border-radius: 8px;
    }
    ${addCssProps}
  `,
  UploadImage: styled.div`
    cursor: pointer;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    border: 2px dashed ${colors['Primary/Light']};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors['Primary/Lighter']};
    svg {
      width: 24px;
      height: 24px;
    }
    &:hover {
      background-color: ${colors['Primary/Light']};
      border-color: ${colors['Primary/Default']};
      svg {
        path {
          fill: ${colors['Primary/Default']};
        }
      }
    }
    ${addCssProps}
  `,
}
