export const ADMIN_TYPE = {
  MASTER: '최고 관리자',
  ADMIN: '일반 관리자',
} as const
export type Key_Of_ADMIN_TYPE = keyof typeof ADMIN_TYPE

export const pageAuthorities = [
  'home',
  'estimates', // 견적서
  'ongoing', // 진행중
  'completed', // 시공 완료
  'ordered', // 주문 완료
  'waiting', // 시공 대기
  'cancelled', // 시공 취소
  'reservation',
  'techs-queue',
  'techs-history',
  'partner-queue',
  'partner-history',
  'unpaid',
  'user',
  'partner',
  'building',
  'area',
  'setting',
  'funnel',
  'admin',
  'techs',
  'log',
  'coupon',
  'point',
  'event',
  'notice',
] as const
export type PageAuthority = typeof pageAuthorities[number]

export interface AbilityItem {
  page: PageAuthority
  permission: boolean
}
