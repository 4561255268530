import { ImagesLoginChecked, ImagesLoginUnCheck } from 'assets'
import { cn } from 'utils/helpers'
import S from './PasswordCheck.styles'
import { PasswordCheckProps } from './PasswordCheck.types'

const PasswordCheck = ({ _active, _css, ...props }: PasswordCheckProps) => {
  return (
    <>
      <S.Wrap className={cn({ active: _active })} _css={_css} {...props}>
        {_active && <img src={ImagesLoginChecked} alt="비밀번호 가리기" />}
        {!_active && <img src={ImagesLoginUnCheck} alt="비밀번호 보기" />}
        <div>비밀번호 보기</div>
      </S.Wrap>
    </>
  )
}
export default PasswordCheck
