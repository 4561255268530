import { css } from '@emotion/react'
import CheckBoxRound from 'components/CheckBoxRound'
import DraggablePopup from 'components/DraggablePopup'
import dayjs from 'dayjs'
import useFetch from 'hooks/useFetch'
import {
  ApiSeeTogetherItemType,
  OtherServicesType,
} from 'pages/Work/Work.types'
import { useEffect, useState } from 'react'
import { FlexColumn, TXT } from 'themes/Shared.styles'
import { cn, thousand } from 'utils/helpers'
import S from './OtherServices.styles'
import { OtherServicesProps } from './OtherServices.types'

const OtherServices = ({
  _css,
  _id,
  _selectedItem,
  _onClose,
  _emitValue,
  ...props
}: OtherServicesProps) => {
  const [response] = useFetch<{ list: ApiSeeTogetherItemType[] }>(
    'task',
    `/order/${_id}/seeTogether?take=99999`
  )
  const [others, set_others] = useState<OtherServicesType[]>([])
  const [selected, set_selected] = useState<string[]>([])
  useEffect(() => {
    if (response) {
      set_others(
        response.list.map((item) => {
          return {
            id: item.id,
            paidAt: item.paidAt ?? '',
            items: item.service.map((data) => ({
              taskId: data.id,
              name: data.serviceName,
              amount: data.servicePrice,
              ...(data.couponDiscount && {
                discount: {
                  type: '쿠폰 적용',
                  amount: data.couponDiscount,
                },
              }),
              paidAt: data.paidAt ?? null,
              serviceDoneAt: data.finishedAt ?? null, // 시공완료일
              cancelledAt: data.cancelledAt ?? null,
            })),
            selected: false,
          }
        })
      )
    }
  }, [response])
  useEffect(() => {
    if (_selectedItem.length) {
      set_selected(_selectedItem.map((item) => item.id))
    }
  }, [_selectedItem])
  const toggleSelected = (value: string) => {
    set_selected((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    )
  }
  const handleSubmit = () => {
    _emitValue(others.filter((item) => selected.includes(item.id)))
    _onClose()
  }
  return (
    <>
      <DraggablePopup
        _title={'시공건 불러오기'}
        _open={!!_id}
        _onClose={_onClose}
        _css={css`
          max-width: 650px;
        `}
        _bottom={{ _onSubmit: handleSubmit }}
      >
        <S.Wrap _css={_css} {...props}>
          {others.map((item) => {
            return (
              <FlexColumn key={item.id} gap={12} alignItems="flex-start">
                <TXT
                  _textStyle="Body/Small/Bold"
                  _color="Grayscale/Gray Default"
                >
                  결제일{' '}
                  {item.paidAt
                    ? dayjs(item.paidAt).format('YYYY-MM-DD HH:mm')
                    : '결제 전입니다.'}
                </TXT>
                <S.SelectAbleContainer>
                  <S.OtherServiceRow
                    className={cn({ active: selected.includes(item.id) })}
                  >
                    {item.items.map((data) => {
                      return (
                        <S.OtherServiceItem key={data.id}>
                          <S.Title>{data.name}</S.Title>
                          <S.Sub>{thousand(data.amount)}원</S.Sub>
                        </S.OtherServiceItem>
                      )
                    })}
                  </S.OtherServiceRow>
                  <CheckBoxRound
                    _active={selected.includes(item.id)}
                    onClick={() => {
                      toggleSelected(item.id)
                    }}
                  />
                </S.SelectAbleContainer>
              </FlexColumn>
            )
          })}
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default OtherServices
