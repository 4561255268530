import S from './ProgressCircle.styles'

export interface ProgressCircleProps {
  _size?: number
  _strokeWidth?: number
  _progress: number
}

const ProgressCircle = ({
  _size = 24,
  _strokeWidth = 3,
  _progress,
  ...props
}: ProgressCircleProps) => {
  return (
    <S.Wrap _strokeWidth={_strokeWidth} _progress={_progress} _size={_size}>
      <svg className="svg_indicator">
        <circle className="svg_indicator__track" />
        <circle className="svg_indicator__indication" />
      </svg>
    </S.Wrap>
  )
}
export default ProgressCircle
