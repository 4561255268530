import { css } from '@emotion/react'
import { IconsDangerCircle, ImagesInputRemove } from 'assets'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import { MutableRefObject, useRef, useState } from 'react'
import { FlexRow, TXT } from 'themes/Shared.styles'
import { distinctDDFiles, HandleEllipsisToolTip, uuid } from 'utils/helpers'
import S from './FileUploadBox.styles'
import { FileUploadBoxProps, FILE_TYPE } from './FileUploadBox.types'

const FileUploadBox = ({
  _css,
  _fileList,
  _emitValue,
  _uploadState,
  _type,
  _multiple,
  _btnText,
  _btnWidth = 86,
  _progress = 100,
  _placeholder = '등록된 파일 없음',
  ...props
}: FileUploadBoxProps) => {
  const [files, set_files] = useState<string>('')
  const uuidRef = useRef('fileUpload-' + uuid()) as MutableRefObject<string>

  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <S.InputWrap>
          {/* <S.Label htmlFor={uuidRef.current}> */}
          <label htmlFor={uuidRef.current}>
            <S.Upload
              _css={css`
                width: ${_btnWidth + 'px'};
              `}
            >
              {_btnText ? _btnText : '업로드'}
            </S.Upload>
          </label>
          <input
            id={uuidRef.current}
            type="file"
            multiple={_multiple}
            value={files}
            accept={FILE_TYPE[_type]}
            onChange={async (e) => {
              set_files(e.target.value)
              const getFiles = e.target.files as FileList
              if (getFiles) {
                let files = await distinctDDFiles(getFiles)
                _emitValue(_multiple ? [..._fileList, ...files] : files)
                setTimeout(() => {
                  set_files('')
                }, 0)
              }
            }}
          />
          {/* </S.Label> */}
        </S.InputWrap>

        {_fileList.length ? (
          <S.Files
            _css={css`
              max-width: calc(100% - ${_btnWidth}px - 24px);
            `}
          >
            {_fileList.map((item) => {
              return (
                <FlexRow
                  _css={css`
                    position: relative;
                    width: 100%;
                  `}
                  key={item.id}
                  gap={10}
                  alignItems="center"
                  onMouseEnter={(e) => HandleEllipsisToolTip('mouseEnter', e)}
                  onMouseLeave={(e) => HandleEllipsisToolTip('mouseLeave', e)}
                >
                  {_progress === 100 ? (
                    <S.File>{item.name}</S.File>
                  ) : (
                    <S.UploadStateBox>
                      <FlexRow gap={12}>
                        {_progress >= 0 ? (
                          <>
                            <ProgressCircle _progress={_progress ?? 0} />
                            업로드중...
                          </>
                        ) : (
                          <>
                            <IconsDangerCircle />
                            업로드 실패
                          </>
                        )}
                      </FlexRow>
                    </S.UploadStateBox>
                  )}

                  {_progress >= 0 && (
                    <img
                      src={ImagesInputRemove}
                      alt="text remove"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        _emitValue([
                          ..._fileList.filter((file) => {
                            return file.id !== item.id
                          }),
                        ])
                      }}
                    />
                  )}
                </FlexRow>
              )
            })}
          </S.Files>
        ) : (
          <TXT
            _textStyle="Body/Large/Bold"
            _color="Grayscale/Gray Light"
            _css={css`
              padding: 10px 0;
            `}
          >
            {_placeholder}
          </TXT>
        )}
      </S.Wrap>
    </>
  )
}
export default FileUploadBox
