import styled from '@emotion/styled/macro'
import { AuthorizationDefault } from 'templates/Authorization/Authorization.styles'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const AgreementsStyle = {
  Wrap: styled.div`
    ${AuthorizationDefault}
    ${addCssProps}
  `,
  Contents: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 32px 32px 40px;
  `,
  List: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
  `,
  Bottom: styled.div`
    padding: 0px 32px 40px;
  `,

  Header: styled.div`
    padding: 32px 32px 34px;
    display: flex;
    align-items: center;
    gap: 16px;
    ${typography['Display/2 Bold']}
    color: ${colors['Grayscale/Black light']};
    user-select: none;
    img {
      cursor: pointer;
    }
  `,
  Details: styled.div`
    height: 469px;
    width: 420px;
    padding: 32px 10px 36px;
  `,
  DetailsContents: styled.div`
    width: 100%;
    height: 100%;
    padding: 0 22px;
    overflow-y: auto;
    ::-webkit-scrollbar-thumb {
      background: rgb(168 179 198 / 100%);
      border-radius: 5px;
      transition: 0.3s;
      border: 0px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
    color: ${colors['Grayscale/Gray Dark']};
    ${typography['Body/Large/Regular']}
    h1 {
      ${typography['Body/Large/Bold']}
    }
    display: flex;
    flex-direction: column;
    gap: 24px;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > ol {
        padding: 0 0 0 1rem;
      }
    }
    ol {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 0;
    }
  `,
}
export default AgreementsStyle
