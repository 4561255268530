import useFetch from './useFetch'

export const workStatsName = {
  total: '전체',
  orderDone: '주문완료',
  ready: '시공대기',
  ing: '시공중',
  finished: '시공완료',
  cancel: '시공취소',
} as const
export const useWorkStats = () => {
  const [response] = useFetch<{
    total: number
    orderDone: number
    ready: number
    ing: number
    cancel: number
    finished: number
  }>('task', `/order/stats`)
  return response
}
