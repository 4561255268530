import SettlementList from 'pages/Settlement/SettlementList'
import { useParams } from 'react-router-dom'
import SettlementForTechsDetails from '../SettlementForTechsDetails/SettlementForTechsDetails'

interface SettlementForTechsHistoryProps {}

const SettlementForTechsHistory = (props: SettlementForTechsHistoryProps) => {
  const { id } = useParams<'id'>()

  return (
    <>
      <>{!id && <SettlementList _type="history" _target="techs" />}</>
      <>{id && <SettlementForTechsDetails _type="history" _id={id} />}</>
    </>
  )
}
export default SettlementForTechsHistory
