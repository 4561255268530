import { css } from '@emotion/react'
import classNames from 'classnames'
import Chips from 'components/Chips'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import LiveSearchInput from 'components/LiveSearchInput'
import { LiveSearchDefaultDiv } from 'components/LiveSearchInput/LiveSearchInput.styles'
import { uniq, uniqBy } from 'lodash-es'
import {
  AvailableSkill,
  DispatchableZone,
} from 'pages/System/Techs/TechsDetails/TechsDetails.types'
import { useParams } from 'react-router-dom'
import { ContentBoxWrap, ContentRadiusBox, Flex } from 'themes/Shared.styles'
import highlight from 'utils/highlight'
import { ModifyTechsSpecsProps } from './ModifyTechsSpecs.types'
const ModifyTechsSpecs = ({
  _css,
  inputs,
  handleInput,
  ...props
}: ModifyTechsSpecsProps) => {
  const { id } = useParams<'id'>()
  return (
    <ContentBoxWrap>
      <BoxLayout _title={'파견 가능 지역'}>
        <ContentRadiusBox>
          <BoxRowComponent _label={'지역'}>
            <LiveSearchInput<DispatchableZone>
              _removeAfter
              _placeholder="지역 검색"
              _type="user"
              _url={`/technician/dispatchableZone/list${
                id ? `?technicianId=${id}` : ''
              }`}
              _queryName="searchValue"
              _emitValue={(value) => {
                if (value) {
                  handleInput(
                    'dispatchAbleAreas',
                    uniqBy([...inputs.dispatchAbleAreas, value], 'id')
                  )
                }
              }}
              _listParser={(response: DispatchableZone[]) => {
                return {
                  _list: response,
                  _cursor: null,
                }
              }}
              _setTitle={(value) => value.name}
              _ListContent={(props) => {
                const checkDisabled = inputs.dispatchAbleAreas
                  .map((item) => item.id)
                  .includes(props.item.id)
                return (
                  <LiveSearchDefaultDiv
                    className={classNames({
                      disabled: checkDisabled,
                    })}
                    onClick={(e) => {
                      props.handleSubmit(props.item)
                    }}
                  >
                    {highlight(props.item.name, props.term)}
                  </LiveSearchDefaultDiv>
                )
              }}
            />
          </BoxRowComponent>
        </ContentRadiusBox>
        <ContentRadiusBox
          _css={css`
            margin-top: 12px;
            height: 256px;
            overflow: auto;
            position: relative;
          `}
        >
          {inputs.dispatchAbleAreas.length === 0 ? (
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              _textStyle="Body/Caption/Bold"
              _color="Grayscale/Gray Light"
              _css={css`
                position: absolute;
                width: 100%;
                /* height: 100%; */
                left: 0;
                top: 30px;
              `}
            >
              파견 가능 지역을 추가하세요.
            </Flex>
          ) : (
            <Flex gap={`16px 12px`} flexWrap="wrap">
              {inputs.dispatchAbleAreas.map((item, itemIndex) => {
                return (
                  <Chips
                    key={itemIndex}
                    onClick={() => {
                      handleInput('dispatchAbleAreas', (prev) =>
                        prev.dispatchAbleAreas.filter((ft) => ft !== item)
                      )
                    }}
                    _type={'border'}
                  >
                    {item.name}
                  </Chips>
                )
              })}
            </Flex>
          )}
        </ContentRadiusBox>
      </BoxLayout>
      <BoxLayout _title={'시공 가능 업무'}>
        <ContentRadiusBox>
          <BoxRowComponent _label={'시공 가능 업무'}>
            <LiveSearchInput<AvailableSkill>
              _removeAfter
              _placeholder="업무 검색"
              _type="user"
              _url={`/technician/availableSkill/list${
                id ? `?technicianId=${id}` : ''
              }`}
              _queryName="searchValue"
              _emitValue={(value) => {
                const extractedName = value?.name
                if (extractedName) {
                  handleInput('skills', uniq([...inputs.skills, extractedName]))
                }
              }}
              _listParser={(response: AvailableSkill[]) => {
                return {
                  _list: response,
                  _cursor: null,
                }
              }}
              _setTitle={(value) => value.name}
              _ListContent={(props) => {
                return (
                  <LiveSearchDefaultDiv
                    className={classNames({
                      disabled:
                        inputs.skills.includes(props.item.name) ||
                        props.item.isRegistered,
                    })}
                    onClick={(e) => {
                      props.handleSubmit(props.item)
                    }}
                  >
                    {highlight(props.item.name, props.term)}
                  </LiveSearchDefaultDiv>
                )
              }}
            />
          </BoxRowComponent>
        </ContentRadiusBox>
        <ContentRadiusBox
          _css={css`
            margin-top: 12px;
            height: 256px;
            overflow: auto;
            position: relative;
          `}
        >
          {inputs.skills.length === 0 ? (
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              _textStyle="Body/Caption/Bold"
              _color="Grayscale/Gray Light"
              _css={css`
                position: absolute;
                width: 100%;
                left: 0;
                top: 30px;
              `}
            >
              시공 가능 업무를 추가하세요.
            </Flex>
          ) : (
            <Flex gap={`16px 12px`} flexWrap="wrap">
              {inputs.skills.map((item, nameIndex) => {
                return (
                  <Chips
                    key={nameIndex}
                    onClick={() => {
                      handleInput('skills', (prev) =>
                        prev.skills.filter((ft) => ft !== item)
                      )
                    }}
                    _type={'border'}
                  >
                    {item}
                  </Chips>
                )
              })}
            </Flex>
          )}
        </ContentRadiusBox>
      </BoxLayout>
    </ContentBoxWrap>
  )
}
export default ModifyTechsSpecs
