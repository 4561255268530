import { css } from '@emotion/react'
import AssignMeasurement from 'components/AssignMeasurement'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash-es'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import { FlexColumn } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import ManagerPart from '../../ManagerPart'
import AssignTeamPopup from '../AssignTeamPopup'
import { AssignedSchedulesType } from '../AssignTeamPopup/AssignTeamPopup.types'
import { AssignMeasurementTechsProps } from './AssignMeasurementTechs.types'

const AssignMeasurementTechs = ({
  _css,
  _readOnly,
  handleModal,
  inputs,
  handleInput,
  ...props
}: AssignMeasurementTechsProps) => {
  const { id } = useParams<'id'>()

  const [openMeasurementTeam, set_openMeasurementTeam] = useState<{
    open: boolean
    data?: AssignedSchedulesType
  }>({
    open: false,
  })

  const dispatch = useDispatch()
  const handleSubmit = async (value: AssignedSchedulesType) => {
    try {
      await callAxios('task').put(`/schedule`, {
        type: '실측',
        taskId: id,
        memo: value.remarks,
        schedule: value.daySchedules.map((day) => ({
          ymd: dayjs(day.currentDay).format('YYYYMMDD'),
          startTime: day.times.start.replace(':', ''),
          endTime: day.times.end.replace(':', ''),
          workerList: uniqBy(day.techs, (value) => value.id).map(
            (item) => item.id
          ),
        })),
      })
      handleInput('measurementTeam', value)
      dispatch(setToast(['실측이 배정되었습니다.', 'success']))
      set_openMeasurementTeam({ open: false })
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      {openMeasurementTeam.open && (
        <AssignTeamPopup
          _data={openMeasurementTeam.data}
          _open={openMeasurementTeam.open}
          _onClose={() => {
            set_openMeasurementTeam({ open: false })
          }}
          _emitValue={handleSubmit}
          _type="실측"
        />
      )}
      <FlexColumn
        gap={24}
        _css={css`
          & > div {
            width: 100%;
          }
        `}
      >
        <ManagerPart
          _readOnly={_readOnly}
          _managerTeam={inputs.measurementTeam}
          _label={{
            teams: '실측자',
            time: '실측 일자',
            info: '실측팀 정보',
          }}
          _handleOpenSelectPopup={(value) => {
            set_openMeasurementTeam(value)
          }}
        />
        <BoxLayout _title={'실측 정보'}>
          <AssignMeasurement
            taskId={id}
            inputs={inputs}
            handleInput={handleInput}
            handleModal={handleModal}
          />
        </BoxLayout>
      </FlexColumn>
    </>
  )
}
export default AssignMeasurementTechs
