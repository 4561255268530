import { css } from '@emotion/react'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import PeriodDropdownFilter from 'components/TableComponent/PeriodDropdownFilter'
import useFetch from 'hooks/useFetch'
import {
  PeriodDataConditionProps,
  PeriodStatsType,
  StaffCondition,
  TodayCondition,
  TodayStatsType,
  UserCondition,
  UserStatsType,
  todayStatsLabel,
  userCountDataLabel,
} from 'pages/Main/Main.types'
import { ContentPart } from 'pages/Main/components/ContentPart/ContentPart'
import { MessagePopUp } from 'pages/Main/components/MessagePopUp/MessagePopUp'
import {
  PeriodDataBox,
  PeriodLabelProps,
} from 'pages/Main/components/PeriodDataBox/PeriodDataBox'
import { PeriodRatioBox } from 'pages/Main/components/PeriodRatioBox/PeriodRatioBox'
import { UserBox, UserBoxProps } from 'pages/Main/components/UserBox/UserBox'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, FlexColumn, TXT } from 'themes/Shared.styles'
import { colors } from 'themes/styles'
import callAxios, { handleError } from 'utils/callAxios'
import { objectKeys, parserForPeriodQuery, thousand } from 'utils/helpers'
import { parseObjectToQueryString } from 'utils/parsers'
import M from './Main.styles'

const numberState = (num: number) => {
  return num > 0 ? 'increase' : num === 0 ? 'flat' : 'decrease'
}
const Main = () => {
  const [todayCondition, set_todayCondition] = useState<TodayCondition>()
  const [userCondition, set_userCondition] = useState<UserCondition>()
  const [staffCondition, set_staffCondition] = useState<UserCondition>()
  const [periodDataCondition, set_periodDataCondition] =
    useState<PeriodDataConditionProps>()
  // const [response, error] = useFetch<TestType>(`/post/`)
  const [response] = useFetch<
    {
      userName: string
    }[]
  >('user', `/admin/requested-pwchange`)
  const [requestChange, set_requestChange] = useState<{ userName: string }[]>(
    []
  )
  const [todayStatsResponse] = useFetch<TodayStatsType>(
    'task',
    `/stats/order/today`
  )
  const [userStatsResponse] = useFetch<UserStatsType>(
    'user',
    `/stats/user/today`
  )
  useEffect(() => {
    if (todayStatsResponse) {
      set_todayCondition(
        objectKeys(todayStatsLabel).reduce((prev, curr) => {
          prev[todayStatsLabel[curr].label] = todayStatsResponse[curr]
          return prev
        }, {} as TodayCondition)
      )
    }
  }, [todayStatsResponse])
  useEffect(() => {
    if (userStatsResponse) {
      set_staffCondition(
        objectKeys(userCountDataLabel.worker).reduce((prev, curr) => {
          prev[userCountDataLabel.worker[curr]] = {
            _growth: {
              state: numberState(userStatsResponse.change[curr]),
              value: userStatsResponse.change[curr],
            },
            _total: userStatsResponse.today[curr],
          }
          return prev
        }, {} as UserCondition)
      )
      set_userCondition({
        '총 가입자': {
          _growth: {
            state: numberState(userStatsResponse.changeTotal),
            value: userStatsResponse.changeTotal,
          },
          _total: userStatsResponse.todayTotal,
        },
        ...objectKeys(userCountDataLabel.user).reduce((prev, curr) => {
          prev[userCountDataLabel.user[curr]] = {
            _growth: {
              state: numberState(userStatsResponse.change[curr]),
              value: userStatsResponse.change[curr],
            },
            _total: userStatsResponse.today[curr],
          }
          return prev
        }, {} as UserCondition),
      })
    }
  }, [userStatsResponse])
  useEffect(() => {
    if (response) {
      set_requestChange(response)
    }
  }, [response])
  // 오늘 현황 데이터 obj -> <div>컴포넌트
  const getTodayCondition = useCallback((conditionObj?: TodayCondition) => {
    if (!conditionObj) return
    return Object.entries(conditionObj).map((item) => {
      const key = item[0]
      const value =
        key === '결제 금액' ? thousand(item[1]) + '원' : item[1] + '건'
      return (
        <div className="box" key={'today-' + item[0]}>
          <div>{value}</div>
          <div className="sub">{key}</div>
        </div>
      )
    })
  }, [])
  // 가입자 현황 데이터 obj -> UserBox 컴포넌트
  const getUserCondition = (conditionObj?: UserCondition | StaffCondition) => {
    if (!conditionObj) return
    return Object.entries(conditionObj).map((item) => {
      const key = item[0]
      const val = item[1] as UserBoxProps
      return (
        <UserBox
          key={'user-' + key}
          _label={key}
          _growth={val._growth}
          _total={val._total}
        />
      )
    })
  }

  const getPeriodDataCondition = (
    conditionObj?: PeriodDataConditionProps['data']
  ) => {
    if (!conditionObj) return
    return Object.entries(conditionObj).map((item, index) => {
      const key = item[0] as PeriodLabelProps
      const val = item[1]
      return <PeriodDataBox key={key + index} _label={key} _value={val} />
    })
  }
  const [currentPeriod, set_currentPeriod] = useState<{
    startAt: string
    endAt: string
  } | null>(null)

  const handleGetStats = async () => {
    try {
      if (!currentPeriod) return
      const res = await callAxios('user').get<DoubleDResponse<PeriodStatsType>>(
        `/stats/search${parseObjectToQueryString(currentPeriod)}`
      )
      if (res.data.data) {
        const currentStats = res.data.data
        set_periodDataCondition({
          data: {
            '결제 금액': currentStats.order.paymentAmount,
            '주문 건수': currentStats.order.ordersCounts,
            '취소 건수': currentStats.order.cancellationsCounts,
            '미수금 건수': currentStats.order.receivablesCounts,
            '총 가입자': currentStats.user.total,
            '일반 회원': currentStats.user.U1,
            '정기 회원': currentStats.user.U2,
            '파트너 회원': currentStats.user.U3,
            관리자: currentStats.user.A1,
            숙련공: currentStats.user.T1,
            실측공: currentStats.user.T2,
            자재지원팀: currentStats.user.A2,
          },
          ratio: currentStats.rate,
        })
      }
    } catch (error) {
      handleError(error)
    }
  }
  const navigate = useNavigate()
  return (
    <>
      <Modal
        onClose={() => set_requestChange([])}
        open={requestChange.length > 0}
      >
        <MiniPopup
          _title={'비밀번호 변경 요청'}
          _buttonHandler={
            <>
              <GrayScaleFill
                onClick={() => {
                  set_requestChange([])
                }}
              >
                취소
              </GrayScaleFill>
              <PrimaryButton
                onClick={() => {
                  navigate('/system/admin')
                }}
              >
                이동
              </PrimaryButton>
            </>
          }
        >
          <div>
            비밀번호 변경 요청이 있습니다.
            <br />
            요청한 관리자 상세화면으로 이동할까요?
          </div>
          <FlexColumn
            gap={8}
            _css={css`
              margin-top: 8px;
              & > div {
                background-color: ${colors['Grayscale/Background Light']};
                border-radius: 20px;
                padding: 6px;
                width: 100%;
              }
            `}
          >
            {requestChange.map((name) => {
              return (
                <TXT
                  _textStyle="Title/Bold"
                  _color="Grayscale/Gray Dark"
                  key={name.userName}
                >
                  {name.userName}
                </TXT>
              )
            })}
          </FlexColumn>
        </MiniPopup>
      </Modal>
      <ContentsBox
        _type="relative"
        _css={css`
          margin: 0px auto;
          padding: 76px 0px;
        `}
      >
        <M.MainWrap>
          <MessagePopUp />
          <ContentPart
            _title={'오늘 현황'}
            _css={css`
              .content {
                background-color: ${colors['Primary/Default']};
              }
            `}
          >
            <M.TodayWrapper>{getTodayCondition(todayCondition)}</M.TodayWrapper>
          </ContentPart>
          <div className="flex-row">
            <ContentPart _title={'가입자 현황'}>
              <M.UserWrapper>{getUserCondition(userCondition)}</M.UserWrapper>
            </ContentPart>
            <ContentPart _title={'내부 직원 현황'}>
              <M.UserWrapper>{getUserCondition(staffCondition)}</M.UserWrapper>
            </ContentPart>
          </div>
          <ContentPart
            _title={'기간별 현황'}
            _css={css`
              .content {
                min-height: 478px;
                padding: 0px 32px 0px;
                display: flex;
                flex-direction: column;
              }
            `}
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              _css={css`
                padding: 16px 0;
              `}
            >
              <PeriodDropdownFilter
                _removeAll
                _emitValue={(value) => {
                  const nextData = parserForPeriodQuery(value)
                  set_currentPeriod({
                    startAt: nextData.startAt,
                    endAt: nextData.endAt,
                  })
                }}
              />
              <GrayScaleOutline
                _mini
                padding={`8px 26px`}
                onClick={handleGetStats}
              >
                검색
              </GrayScaleOutline>
            </Flex>
            <M.PeriodWrapper>
              {periodDataCondition ? (
                <>
                  <div className="left">
                    <div className="flex-row">
                      {getPeriodDataCondition(periodDataCondition.data)}
                    </div>
                  </div>
                  <div className="right">
                    <PeriodRatioBox data={periodDataCondition.ratio} />
                  </div>
                </>
              ) : (
                <div className="no-period">조회 기간을 입력해주세요</div>
              )}
            </M.PeriodWrapper>
          </ContentPart>
        </M.MainWrap>
      </ContentsBox>
    </>
  )
}
export default Main
