import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import { AuthorizationDefault } from '../Authorization.styles'
const InitPasswordStyle = {
  Wrap: styled.div`
    ${AuthorizationDefault}
    ${addCssProps}
  `,
  Contents: styled.div`
    padding: 0 32px 72px;
    p {
      ${typography['Title/Regular']}
      color: ${colors['Grayscale/Gray Dark']};
      margin-bottom: 24px;
    }
  `,
}
export default InitPasswordStyle
