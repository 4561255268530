import { css } from '@emotion/react'
import { ImagesGlobalHeaderBackButton } from 'assets'
import { PrimaryButton } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound'
import { useAppDispatch } from 'hooks/reduxHooks'
import { isEqual, sortBy } from 'lodash-es'
import { useMemo, useState } from 'react'
import { setAgreements } from 'store/signSlice'
import SA from 'templates/Authorization/Authorization.styles'
import LogoPart from 'templates/Authorization/LogoPart'
import { Divider } from 'themes/Shared.styles'
import { typography } from 'themes/styles'
import S from './AgreementsComponent.styles'
import { AgreementsComponentProps } from './AgreementsComponent.types'

const AGREEMENTS_LIST = [
  '서비스 이용약관 동의',
  '개인정보 수집 및 동의',
  '위치정보 이용 약관 동의',
  '(선택) 마케팅 활용 및 광고성 정보 수신동의',
] as const

type AgreementsType = typeof AGREEMENTS_LIST[number]

const agreementsConfig = [
  { label: '서비스 이용약관 동의', required: true },
  { label: '개인정보 수집 및 동의', required: true },
  { label: '위치정보 이용 약관 동의', required: true },
  { label: '(선택) 마케팅 활용 및 광고성 정보 수신동의', required: false },
] as { label: AgreementsType; required: boolean }[]

const AgreementsComponent = ({
  inputs,
  handleInput,
  _css,
  ...props
}: AgreementsComponentProps) => {
  const dispatch = useAppDispatch()
  const [popupAgreement, set_popupAgreement] = useState<AgreementsType | null>(
    null
  )
  const [currentAgreements, set_currentAgreements] = useState<AgreementsType[]>(
    []
  )
  const disabledButton = useMemo(() => {
    const requiredList = agreementsConfig
      .filter((item) => item.required)
      .map((item) => item.label)

    return requiredList.some((item) => !currentAgreements.includes(item))
  }, [currentAgreements])
  const agreeAll = useMemo(() => {
    return isEqual(
      sortBy([...AGREEMENTS_LIST], (item) => item),
      sortBy(currentAgreements, (item) => item)
    )
  }, [currentAgreements])
  const handleListToggle = (value: AgreementsType) => {
    set_currentAgreements((prev) =>
      prev.some((item) => item === value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    )
  }
  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <LogoPart />
        {popupAgreement && (
          <SA.Center>
            <S.Header>
              <img
                src={ImagesGlobalHeaderBackButton}
                alt={'backward'}
                onClick={() => set_popupAgreement(null)}
              />
              서비스 이용약관 동의
            </S.Header>
            <S.Details>
              <S.DetailsContents>
                <div>
                  <h1>제1조(목적)</h1>
                  <ol>
                    <li>
                      이 약관은 이용자가 주식회사 위드와이티(이하 “회사”)가
                      운영하는 서비스(이하 “서비스” 또는 “수수다”, “수수다
                      앱”)를 통해 제공하는 인터넷 전자상거래 관련 서비스(이하
                      “서비스”)와 관련하여 회사와 이용자의 권리, 의무, 책임
                      사항을 규정함을 목적으로 합니다.
                    </li>
                    <li>
                      본 약관은 유무선 전화, 유무선 PC통신, 태블릿 PC,
                      스마트폰(아이폰, 안드로이드폰 등) 애플리케이션 및 모바일
                      웹 등을 이용하는 전자상거래 등에 대해서도 그 성질에 반하지
                      않는 한 준용 됩니다.
                    </li>
                  </ol>
                </div>
                <div>
                  <h1>제2조(정의)</h1>
                  <ol>
                    <li>
                      회사가 운영하는 모바일 앱은 다음과 같습니다.
                      <ol>
                        <li>신청 또는 변경시 허위내용의 등록</li>
                        <li>신청 또는 변경시 허위내용의 등록</li>
                        <li>신청 또는 변경시 허위내용의 등록</li>
                        <li>신청 또는 변경시 허위내용의 등록</li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </S.DetailsContents>
            </S.Details>
          </SA.Center>
        )}
        {!popupAgreement && (
          <SA.Center>
            <SA.Header>약관동의</SA.Header>
            <S.Contents>
              <CheckBoxRound
                _active={agreeAll}
                _css={css`
                  ${typography['Body/Large/Bold']}
                `}
                onClick={() => {
                  agreeAll
                    ? set_currentAgreements([])
                    : set_currentAgreements([...AGREEMENTS_LIST])
                }}
              >
                모두 동의할게요
              </CheckBoxRound>
              <Divider />
              <S.List>
                {agreementsConfig.map((item) => {
                  return (
                    <CheckBoxRound
                      key={item.label}
                      _css={css`
                        ${typography['Body/Large/Bold']}
                      `}
                      _active={currentAgreements.includes(item.label)}
                      _link={(e) => {
                        set_popupAgreement(item.label)
                      }}
                      onClick={() => {
                        handleListToggle(item.label)
                      }}
                    >
                      {item.label}
                    </CheckBoxRound>
                  )
                })}
              </S.List>
            </S.Contents>
            <S.Bottom>
              <PrimaryButton
                disabled={disabledButton}
                _css={css`
                  width: 100%;
                  padding: 10px 20px;
                `}
                onClick={() => {
                  let optionAgreements = agreementsConfig
                    .filter((item) => !item.required)
                    .map((item) => item.label)
                    .filter((item) => currentAgreements.includes(item))
                  dispatch(setAgreements(optionAgreements))
                }}
              >
                동의 후 회원 가입
              </PrimaryButton>
            </S.Bottom>
          </SA.Center>
        )}
        <SA.Dim />
      </S.Wrap>
    </>
  )
}
export default AgreementsComponent
