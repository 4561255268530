import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const SettlementForTechsDetailsStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  CalendarHeader: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 20px;
    h1 {
      ${typography['Headline/Bold']}
      color: ${colors['Grayscale/Black']};
      white-space: nowrap;
    }
    img {
      width: 14px;
      height: 14px;
    }
  `,
}

export default SettlementForTechsDetailsStyle
