import { workStatusToKorean } from 'hooks/useAllMatch'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export type ServiceGroupDropdownListType = {
  zoneGroupId: string | number
  month: string
}
export const reservationCalendarDropdownInitial: ServiceGroupDropdownListType =
  {
    zoneGroupId: '',
    month: '',
  }
export interface CalendarProps extends DivAttributes {
  _css?: AdditionalCss
}

export type ApiDayScheduleListItem = {
  day: string
  schedule: (0 | 1 | 2)[]
}

export type ApiDaySelectedScheduleItem = {
  startTime: string
  endTime: string
  schedules: {
    taskId: string
    type: '실측' | '시공' | '자재'
    status: typeof workStatusToKorean[keyof typeof workStatusToKorean]
    categoryName: string
    nameList: string[]
    unpaid: boolean
  }[]
}
