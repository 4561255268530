import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import { SettingStyles } from '../Setting.styles'
const categoryWidth = 1206
const gap = 12
const num = 5
const SelectCategoryStyle = {
  Wrap: styled.div`
    padding-top: 68px;
    width: ${categoryWidth}px;
    display: flex;
    align-items: flex-start;
    gap: ${gap}px;
    justify-content: space-between;
    height: 100%;
    & > div {
      height: 100%;
      background-color: #fff;
      border: 1px solid ${colors['Grayscale/Gray Lighter']};
      padding: 24px 32px;
    }
    ${addCssProps}
  `,
  NotFoundList: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${typography['Body/Large/Bold']}
    color: ${colors['Grayscale/Gray Light']};
  `,
  ButtonBox: styled.div`
    width: ${(categoryWidth - gap * (num - 1)) / 5}px;
    padding: 24px 32px;
    height: 96px;
    position: relative;
    & > button {
      width: 100%;
      height: 100%;
    }
  `,
  CategoryHeader: styled.div`
    padding: 12px 0;
    ${typography['Title/Bold']}
    color: ${colors['Primary/Default']};
  `,
  CategoryBody: styled.div`
    width: 100%;
    height: calc(100% - 52px);
    overflow-y: auto;
  `,
  CategoryWrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    & > button {
      width: 100%;
    }
  `,
  GroupButton: SettingStyles.GroupButton,
}

export default SelectCategoryStyle
