import { css } from '@emotion/react'
import ApiFileUploadBox from 'components/ApiFileUploadBox'
import { GrayScaleOutline } from 'components/Buttons'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import UnderlineDropdown from 'components/Dropdown/UnderlineDropdown'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import LiveSearchInput from 'components/LiveSearchInput'
import { LiveSearchDefaultDiv } from 'components/LiveSearchInput/LiveSearchInput.styles'
import { LiveSearchUserInfo } from 'components/LiveSearchInput/LiveSearchInput.types'
import { uniqBy } from 'lodash-es'
import { TargetUser } from 'pages/Support/support.types'
import { useEffect, useState } from 'react'
import { ContentRadiusBox } from 'themes/Shared.styles'
import { typography } from 'themes/styles'
import { DoubleDFile } from 'types/services.type'
import highlight from 'utils/highlight'
import { ByTargetProps } from './ByTarget.types'

export const ISSUE_METHOD_DROPDOWN_LIST = {
  '회원 이름 검색': 'search',
  '엑셀 업로드': 'excel',
} as const
export type IssueMethod = ValueOf<typeof ISSUE_METHOD_DROPDOWN_LIST>
const ByTarget = ({
  _css,
  inputs,
  _couponId,
  handleInput,
  ...props
}: ByTargetProps) => {
  const [issueMethod, set_issueMethod] = useState<IssueMethod>('search')
  const [excelFile, set_excelFile] = useState<DoubleDFile[]>([])
  // issueMethod 외부로 emit하기 위함
  useEffect(() => {
    handleInput('issueMethod', issueMethod)
  }, [issueMethod, handleInput])

  return (
    <ContentRadiusBox className="radius-box-gap">
      <BoxRowComponent _label={'발급방법 *'}>
        <UnderlineDropdown
          _value={issueMethod}
          _list={ISSUE_METHOD_DROPDOWN_LIST}
          _placeholder={'카테고리 선택'}
          _disabled={!!_couponId}
          _emitValue={(value) => {
            set_issueMethod(value)
          }}
        />
      </BoxRowComponent>
      {issueMethod === 'search' ? (
        <BoxRowComponent _label={'발급 대상자 *'}>
          <LiveSearchInput<LiveSearchUserInfo>
            _removeAfter
            _type="user"
            _url="/users/search"
            _queryName="name"
            _placeholder="회원 이름 검색"
            _emitValue={(value) => {
              if (value) {
                const uniqUserList = uniqBy(
                  [...inputs.userList, value],
                  (item) => item.userName
                )
                handleInput('userList', uniqUserList)
              }
            }}
            _listParser={(
              response: DoubleDListWithPaging<LiveSearchUserInfo>
            ) => {
              return {
                _list: response.list,
                _cursor: disposeCursor(response.paging.cursor),
              }
            }}
            _setTitle={(value) => value.name}
            _ListContent={(props) => {
              return (
                <LiveSearchDefaultDiv
                  onClick={(e) => {
                    props.handleSubmit(props.item)
                  }}
                >
                  {highlight(props.item.name, props.term)}{' '}
                  {highlight(props.item.phone, props.term)}
                </LiveSearchDefaultDiv>
              )
            }}
          />
        </BoxRowComponent>
      ) : (
        <>
          <BoxRowComponent
            _label={'엑셀 업로드 *'}
            _left
            _css={css`
              align-items: flex-start;
              & > label {
                padding-top: 13px;
              }
            `}
          >
            <ApiFileUploadBox
              _type={'order'}
              _fileType={'excel'}
              _url={`/coupon/xlsx`}
              _fileList={excelFile}
              _multiple={false}
              _emitExcelUserList={(value) => {
                const targetUserFromExcel: TargetUser[] = value.map(
                  (item: any) => {
                    return {
                      userName: item.userName,
                      name: item.name,
                      phone: item.phone,
                    }
                  }
                )
                const uniqUserList = uniqBy(
                  [...inputs.userList, ...targetUserFromExcel],
                  (item) => item.userName
                )
                handleInput('userList', uniqUserList)
              }}
              _emitValue={(value) => {
                set_excelFile(value)
              }}
              _method={'replace'}
            />
          </BoxRowComponent>
          <BoxRowComponent
            _label={'양식 다운로드'}
            _css={css`
              justify-content: flex-start;
            `}
          >
            <GrayScaleOutline
              _css={css`
                ${typography['Body/Small/Bold']};
                width: 86px;
                height: 30px;
                border-radius: 8px;
              `}
            >
              다운로드
            </GrayScaleOutline>
          </BoxRowComponent>
        </>
      )}
    </ContentRadiusBox>
  )
}
export default ByTarget
