import { css } from '@emotion/react'
import Badge from 'components/Badge'
import { PrimaryButton } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import Dropdown from 'components/Dropdown'
import LinkClip from 'components/LinkClip/LinkClip'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import { isPeriod } from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import TableCell from 'components/TableComponent/TableCell'
import T from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import useSearchFilter from 'hooks/useSearchFilter'
import {
  APIEventListData,
  EVENT_BADGE_COLOR,
  EVENT_QUERY_DROPDOWN_LIST,
  EVENT_TYPE_DROPDOWN_LIST,
  EventFilterListType,
  EventListItem,
  initialEventFilterValues,
  isEventType,
} from 'pages/Support/Event/EventList/EventList.types'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow, TXT } from 'themes/Shared.styles'
import {
  cn,
  isBetweenPeriod,
  isObjectKeyType,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
} from 'utils/helpers'

import useFetch from 'hooks/useFetch'
import S from './EventList.styles'

interface EventListProps {}
const EventList = (props: EventListProps) => {
  const [selectedId, set_selectedId] = useState<string>('')
  const [totalEventsCount, set_totalEventsCount] = useState<number>()
  const navigate = useNavigate()

  const { initialValues, searchParams, handleSetLocation } = useSearchFilter(
    {
      filterList: initialEventFilterValues,
      queryList: EVENT_QUERY_DROPDOWN_LIST,
    },
    ({ searchType, searchValue, status, ...reset }) => {
      return {
        status: isEventType(status) ? status : 'ALL',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    ({ queryName, terms = '', status, ...rest }) => {
      return removeUndefined({
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        status: status === 'ALL' ? '' : status,
        ...rest,
      })
    }
  )

  // ** Parser for API response data to table row data
  const parserForEventListTable = (
    response: DoubleDListWithPaging<APIEventListData>
  ) => {
    const rebuild = response.list.map(
      (row: APIEventListData, index: number) => {
        return {
          id: String(row.id),
          state: row.finishAt
            ? '종료'
            : isBetweenPeriod(dayjs(), row.startAt, row.endAt),
          title: row.title,
          period: {
            start: dayjs(row.startAt).startOf('day'),
            end: dayjs(row.endAt).endOf('day'),
          },
          writer: row.issuer,
          link: row.link,
        } as EventListItem
      }
    )
    // 전체 이벤트 개수 set
    set_totalEventsCount(response.paging.totalRow)
    return {
      _list: rebuild,
      _cursor: disposeCursor(response.paging.cursor),
    }
  }

  // query -> 백엔드 api url
  const apiURI = useMemo(() => {
    const uri = `/board/event/list?take=10${
      searchParams.toString() ? '&' + searchParams.toString() : ''
    }`
    return decodeURI(uri)
  }, [searchParams])

  const [stats] = useFetch<{
    total: number
    process: number
    end: number
    expected: number
  }>('user', `/board/event/stats`)
  return (
    <ContentsBox _type="relative">
      <S.Wrapper>
        {/* TopInfo */}
        <TopInfo>
          <TopInfoRow
            _label={'전체'}
            _contents={`${thousand(stats?.total)}건`}
          />
          <TopInfoRow
            _label={'진행중'}
            _contents={`${thousand(stats?.process)}건`}
          />
          <TopInfoRow
            _label={'예정'}
            _contents={`${thousand(stats?.expected)}건`}
          />
          <TopInfoRow _label={'종료'} _contents={`${thousand(stats?.end)}건`} />
        </TopInfo>
        {/* TableTitle */}
        <TableTitle
          _left={
            <FlexRow gap={0}>
              <h1>이벤트</h1>
              <TXT _color="Primary/Default">
                <h1> {totalEventsCount}</h1>
              </TXT>
            </FlexRow>
          }
          _right={
            <PrimaryButton
              onClick={() => navigate('/customer/event/publish')}
              _css={css`
                width: 160px;
                height: 40px;
              `}
            >
              글쓰기
            </PrimaryButton>
          }
        />
        {/* Filter */}
        <FilterPart<EventFilterListType>
          _value={initialValues}
          _placeholder="주문자명 검색"
          _queryNameList={EVENT_QUERY_DROPDOWN_LIST}
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _FilterContents={({ _value, _emitValue, ...props }) => {
            return (
              <Dropdown.Underline
                _css={css`
                  width: 120px;
                `}
                _value={_value?.status ? _value.status : 'ALL'}
                _list={EVENT_TYPE_DROPDOWN_LIST}
                _emitValue={(value) => {
                  if (_emitValue && _value)
                    _emitValue({
                      ..._value,
                      status: isEventType(value) ? value : 'ALL',
                    })
                }}
              />
            )
          }}
        />
        {/* Table */}
        <RoundFillBox>
          <TableComponent<EventListItem>
            _type="user"
            _url={apiURI}
            _config={EventListTableConfigs}
            _parser={parserForEventListTable}
            _ListContent={({
              _row,
              _index,
            }: {
              _row: EventListItem
              _index: number
              _totalCount: number
              _configs: TableCellConfig<EventListItem>[]
            }) => {
              const rowId = _row.id ? String(_row.id) : String(_index)
              return (
                <T.Row
                  className={cn({
                    active: selectedId === rowId,
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    set_selectedId(rowId)
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation()
                    navigate(`/customer/event/${rowId}`)
                  }}
                >
                  {EventListTableConfigs.map((item, columnIndex) => {
                    const _id = item._id
                    const _value = _row[item._id]

                    return (
                      <TableCell
                        key={'body' + _index + columnIndex}
                        _position={item._position}
                        _size={item._size}
                      >
                        {_id === 'state' && typeof _value === 'string' && (
                          <Badge
                            {...(isObjectKeyType(EVENT_BADGE_COLOR, _value) &&
                              EVENT_BADGE_COLOR[_value])}
                            _css={css`
                              width: 52px;
                              height: 24px;
                            `}
                          >
                            {_value}
                          </Badge>
                        )}
                        {_id === 'title' && typeof _value === 'string' && (
                          <div>{_value}</div>
                        )}
                        {_id === 'period' && isPeriod(_value) && (
                          <div>{`${_value.start?.format(
                            'YYYY-MM-DD(ddd)'
                          )} ~ ${_value.end?.format('YYYY-MM-DD(ddd)')}`}</div>
                        )}
                        {_id === 'writer' && typeof _value === 'string' && (
                          <div>{_value}</div>
                        )}
                        {_id === 'link' && typeof _value === 'string' && (
                          <FlexRow gap={0} justifyContent="center">
                            <LinkClip _to={_value} />
                          </FlexRow>
                        )}
                      </TableCell>
                    )
                  })}
                </T.Row>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrapper>
    </ContentsBox>
  )
}

export default EventList

// table initial config
const EventListTableConfigs: TableCellConfig<EventListItem>[] = [
  {
    _type: 'dropdown',
    _id: 'state',
    _data: {
      전체: '',
      진행중: '진행중',
      예정: '예정',
      종료: '종료',
    },
    _label: '현황',
    _position: 'center',
    _size: 106,
    _value: '',
  },
  {
    _type: 'default',
    _id: 'title',
    _label: '제목',
    _position: 'left',
    _size: 640,
  },
  {
    _type: 'default',
    _id: 'period',
    _label: '기간',
    _position: 'left',
    _size: 240,
  },
  {
    _type: 'default',
    _id: 'writer',
    _label: '작성자',
    _position: 'right',
    _size: 90,
  },
  {
    _type: 'default',
    _id: 'link',
    _label: '외부 링크',
    _position: 'center',
    _size: 84,
  },
]
