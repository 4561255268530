import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const ToggleSwitchStyle = {
  Wrap: styled.div`
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    gap: 8px;
    ${typography['Body/Large/Regular']}
    color: ${colors['Grayscale/Gray Default']};
    &.active {
      color: ${colors['Grayscale/Black']};
    }
    &.disabled {
      opacity: 0.4;
    }
    ${addCssProps}
  `,
  Switch: styled.div`
    width: 56px;
    height: 32px;
    border-radius: 16px;
    position: relative;
    transition: 0.15s ease-in-out;
    background-color: ${colors['Grayscale/Gray Lighter']};
    * {
      transition: 0.15s ease-in-out;
    }
    .edge-round {
      transform: translateX(4px);
      background-color: #fff;
      position: absolute;
      top: 4px;
      left: 0px;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      border-radius: 50%;
    }
    &.small {
      width: 44px;
      height: 24px;
      border-radius: 50px;
      .edge-round {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        border-radius: 50%;
      }
    }
    &.active {
      background-color: ${colors['Primary/Default']};
      .edge-round {
        transform: translateX(28px);
      }
      &.small {
        .edge-round {
          transform: translateX(24px);
        }
      }
    }
  `,
}

export default ToggleSwitchStyle
