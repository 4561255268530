import { forwardRef, isValidElement } from 'react'
import S from './TableTitle.styles'
import { TableHeaderProps } from './TableTitle.types'

const TableHeader = forwardRef<HTMLDivElement, TableHeaderProps>(
  ({ _css, _left, _right, ...props }, ref) => {
    return (
      <>
        <S.Wrap ref={ref} _css={_css} {...props}>
          <S.Left>
            {typeof _left === 'string' && <h1>{_left}</h1>}
            {isValidElement(_left) && _left}
          </S.Left>
          <S.Right>{_right}</S.Right>
        </S.Wrap>
      </>
    )
  }
)
export default TableHeader
