import {
  ImagesTableComponentSortAscending,
  ImagesTableComponentSortDefault,
  ImagesTableComponentSortDescending,
} from 'assets'
import S from './OrderIcon.styles'
import { OrderIconProps } from './OrderIcon.types'
const OrderIcon = ({ _css, _order = 'INITIAL', ...props }: OrderIconProps) => {
  return (
    <>
      {_order === 'ASC' && (
        <S.OrderImage
          src={ImagesTableComponentSortAscending}
          alt="ASC"
          _css={_css}
          {...props}
        />
      )}
      {_order === 'DESC' && (
        <S.OrderImage
          src={ImagesTableComponentSortDescending}
          alt="DESC"
          _css={_css}
          {...props}
        />
      )}
      {_order === 'INITIAL' && (
        <S.OrderImage
          src={ImagesTableComponentSortDefault}
          alt="INITIAL"
          _css={_css}
          {...props}
        />
      )}
    </>
  )
}
export default OrderIcon
