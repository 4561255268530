import { css } from '@emotion/react'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import DetailsDefault from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import ToList from 'components/DetailsComponents/ToList'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import { AccountInfo } from 'pages/System/Admin/AdminDetails/components/AccountInfo/AccountInfo'
import { AdminInfo } from 'pages/System/Admin/AdminDetails/components/AdminInfo/AdminInfo'
import { AdminPopUp } from 'pages/System/Admin/AdminPopUp/AdminPopUp'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { setToast } from 'store/toastSlice'
import { ContentBoxWrap } from 'themes/Shared.styles'
import { ADMIN_TYPE } from 'types/api/samples/adminWeb'
import callAxios, { handleError } from 'utils/callAxios'
import { parseToFormattedStr } from 'utils/parsers'
import M from './AdminDetails.styles'
import { APIAdminDetailData, AdminDetailsProps } from './AdminDetails.types'

const AdminDetails = ({ _css, _id, ...props }: AdminDetailsProps) => {
  const rightContentsRef = useRef() as MutableRefObject<HTMLDivElement>
  const { Layout: L } = S
  const [isPopUpOpen, set_isPopUpOpen] = useState<boolean>(false)
  const [isBanPopUpOpen, set_isBanPopUpOpen] = useState<boolean>(false)
  const [isBan, set_isBan] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [response, , callRefresh] = useFetch<APIAdminDetailData>(
    'user',
    `/admin/${_id}`,
    {
      disabled: !_id,
    }
  )

  const handleBan = async (id: string) => {
    try {
      const res = await callAxios('user').delete<DoubleDResponse<string>>(
        `/admin/${id}`
      )
      if (res.data.success) {
        set_isBan(true)
        set_isBanPopUpOpen(false)
        dispatch(setToast({ message: '삭제되었습니다.', type: 'error' }))
      }
    } catch (e) {
      handleError(e)
      dispatch(setToast({ message: '삭제 오류', type: 'error' }))
      set_isBan(false)
    }
  }

  // 관리자 삭제 상태일 경우
  useEffect(() => {
    if (!!response?.deletedAt) set_isBan(true)
  }, [response])
  return (
    <>
      <Modal open={isBanPopUpOpen} onClose={() => set_isBanPopUpOpen(false)}>
        <MiniPopup
          _title="관리자 권한 삭제"
          _buttonHandler={
            <>
              <GrayScaleFill onClick={() => set_isBanPopUpOpen(false)}>
                아니요
              </GrayScaleFill>
              <PrimaryButton
                onClick={() => {
                  handleBan(_id)
                }}
              >
                삭제하기
              </PrimaryButton>
            </>
          }
        >
          {`권한 삭제하게 되면 해당 계정은 탈퇴 처리되며, 
          수수다 관리자에 로그인 하실 수 없습니다.`}
        </MiniPopup>
      </Modal>
      <AdminPopUp
        _toastMsg="관리자가 수정되었습니다."
        _title={'관리자 수정'}
        _isOpen={isPopUpOpen}
        _handleIsOpen={(state) => set_isPopUpOpen(state)}
        _detailInfo={response}
        _reload={callRefresh}
      />
      <DetailsDefault
        _title={`관리자 상세`}
        _topLeftControls={
          <L.ButtonRow>
            {!isBan && (
              <GrayScaleFill
                _mini
                _css={css`
                  padding: 5px 12px;
                `}
                onClick={() => {
                  set_isBanPopUpOpen(true)
                }}
              >
                이용 정지
              </GrayScaleFill>
            )}
            <ToList _to="/system/admin" />
          </L.ButtonRow>
        }
        _leftContents={
          <ContentBoxWrap>
            <>
              <BoxLayout _title={`관리자 정보`}>
                <AdminInfo
                  _name={response?.name ?? ''}
                  _phone={parseToFormattedStr(response?.phone ?? '', 'phone')}
                  _team={response?.office ?? ''}
                  _position={response?.position ?? ''}
                  _part={response?.task ?? ''}
                />
              </BoxLayout>
            </>
          </ContentBoxWrap>
        }
        _rightContents={
          <ContentBoxWrap ref={rightContentsRef}>
            <BoxLayout _title={'계정 정보'}>
              <AccountInfo
                _id={response?.userName ?? ''}
                _password={response?.pwd ?? ''}
                _authority={response?.role ? ADMIN_TYPE[response?.role] : ''}
                _registerDate={
                  response?.createdAt ? dayjs(response?.createdAt) : null
                }
                _recentLogin={
                  response?.loginAt ? dayjs(response?.loginAt) : null
                }
              />
            </BoxLayout>
          </ContentBoxWrap>
        }
        _bottomRightControls={
          <M.RightBottomBtnWrapper>
            {!isBan && (
              <GrayScaleOutline
                onClick={() => {
                  set_isPopUpOpen(true)
                }}
                _css={css`
                  width: 100%;
                  height: 100%;
                `}
              >
                수정
              </GrayScaleOutline>
            )}
          </M.RightBottomBtnWrapper>
        }
      />
    </>
  )
}
export default AdminDetails
