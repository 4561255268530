import S from 'components/DetailsComponents/DetailsComponents.styles'
import { forwardRef, isValidElement } from 'react'
import { ContentRadiusBox } from 'themes/Shared.styles'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { cn } from 'utils/helpers'
import highlight from 'utils/highlight'

interface BoxLayoutProps extends DivAttributes {
  _title: string | JSX.Element
  _titleSuffix?: string | JSX.Element
  _css?: AdditionalCss
  _wrapContents?: boolean
  _checkAble?: boolean
}
const BoxLayout = forwardRef<HTMLDivElement, BoxLayoutProps>(
  (
    {
      children,
      _title,
      _titleSuffix,
      _css,
      _wrapContents,
      _checkAble,
      className,
      ...props
    },
    refs
  ) => {
    return (
      <S.Box
        _css={_css}
        ref={refs}
        className={cn({ checkAble: _checkAble }, className)}
        {...props}
      >
        <S.Header className="box-header">
          <S.HeaderWrap>
            {typeof _title === 'string' && (
              <S.Title>{highlight(_title, '*', 'asterisk')}</S.Title>
            )}
            {isValidElement(_title) && _title}
            {_titleSuffix}
          </S.HeaderWrap>
          <S.CheckAble className="checkEmpty" />
        </S.Header>
        {_wrapContents && <ContentRadiusBox>{children}</ContentRadiusBox>}
        {!_wrapContents && children}
      </S.Box>
    )
  }
)
export default BoxLayout
