import { OrderTypes } from './TableComponent.types'

export const toggleOrder = (order: OrderTypes): OrderTypes => {
  if (order === 'INITIAL') return 'ASC'
  if (order === 'ASC') return 'DESC'
  if (order === 'DESC') return 'INITIAL'
  return 'INITIAL'
}

export function isEllipsisActive(e: any) {
  if (e.children.length > 0) {
    let childArray = [] as boolean[]
    for (let child of e.children) {
      childArray.push(child.offsetWidth < child.scrollWidth)
    }
    return childArray.find((bool) => bool) ? true : false
  } else if (e.offsetWidth && e.scrollWidth) {
    return e.offsetWidth < e.scrollWidth
  } else {
    return false
  }
}
