import { css } from '@emotion/react'
import { PrimaryButton } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import TableCell from 'components/TableComponent/TableCell'
import { TableRow } from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import dayjs from 'dayjs'
import useSearchFilter from 'hooks/useSearchFilter'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow } from 'themes/Shared.styles'
import { cn, removeUndefined } from 'utils/helpers'
import { addLocalGroup } from '../components/AddServiceArea/AddServiceArea.helpers'
import EditServiceArea from '../components/EditServiceArea/EditServiceArea'
import S from './AreaList.styles'
import { ApiZoneList, AreaListProps } from './AreaList.types'
// EDIT:THIS
type ResponseKeyName = {
  id: number
  groupName: string
  areas: string
  modifiedAt: string
}

// EDIT:THIS
const TablePageTemplateTableConfigs: TableCellConfig<ResponseKeyName>[] = [
  {
    _type: 'default',
    _id: 'groupName',
    _label: '그룹 이름',
    _size: 200,
    _position: 'left',
  },
  {
    _type: 'default',
    _id: 'areas',
    _label: '포함 지역',
    _size: 'auto',
    _position: 'left',
  },
  {
    _type: 'default',
    _id: 'modifiedAt',
    _label: '최근 수정일',
    _size: 160,
    _position: 'left',
  },
]

const parserForZone = (zone: SiGuDong) => {
  return `${zone.si} ${zone.gu} ${zone.dong}`
}
const responseParser = (response: ApiZoneList): ResponseKeyName[] => {
  const rebuild = response.list.map((item, index: number) => {
    return {
      id: item.id,
      groupName: item.name,
      areas: item.pivotZone.map((zone) => parserForZone(zone.zone)).join(', '),
      modifiedAt: dayjs(item.createdAt).format('YYYY-MM-DD HH:mm'),
    } as ResponseKeyName
  })
  return rebuild
}
const AREA_QUERY_DROPDOWN_LIST = { 그룹이름: 'name', 지역: 'zone' }
const AreaList = ({ _css, ...props }: AreaListProps) => {
  const navigate = useNavigate()

  const headerRef = useRef() as MutableRefObject<HTMLDivElement>

  const [tableHeaderOffset, set_tableHeaderOffset] = useState<number>(0)
  const [headerOffset, set_headerOffset] = useState<number>(0)
  const [selectedId, set_selectedId] = useState<string>('')
  const [openAddServiceArea, set_openAddServiceArea] = useState<boolean>(false)

  useEffect(() => {
    if (headerRef.current) {
      const headerSize = headerRef.current.getBoundingClientRect().height
      set_headerOffset(0)
      set_tableHeaderOffset(headerSize)
    }
  }, [])

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: {},
      queryList: AREA_QUERY_DROPDOWN_LIST,
    },
    // query object -> filterList 상태값으로 파싱하세요.
    ({ searchType, searchValue, ...rest }) => {
      return {
        terms: searchValue ? String(searchValue) : '',
        queryName: searchType ? String(searchType) : '',
      }
    },
    // filterList => query object로 파싱하세요. 주소 search query로 갑니다.
    ({ queryName, terms = '', ...rest }) => {
      return removeUndefined({
        searchType: terms ? queryName : '',
        searchValue: terms,
      })
    }
  )
  const apiURI = useMemo(() => {
    return `/zone-group?take=10${
      window.location.search ? '&' + searchParams.toString() : ''
    }`
  }, [searchParams])
  const [updateList, set_updateList] = useState<number>(dayjs().valueOf())
  return (
    <>
      {openAddServiceArea && (
        <EditServiceArea
          _emitValue={async (value) => {
            await addLocalGroup(value)
            set_updateList(dayjs().valueOf())
          }}
          _open={openAddServiceArea}
          _onClose={() => {
            set_openAddServiceArea(false)
          }}
        />
      )}
      <ContentsBox _type="relative" _css={css``}>
        {/* ContentsBox에서 스타일 상속 받음 */}
        <S.Wrap _css={_css} {...props}>
          {/* EDIT:THIS */}
          <TableTitle
            ref={headerRef}
            _css={css`
              top: ${headerOffset}px;
              padding-top: 108px;
            `}
            _left={`서비스 지역 관리`}
            _right={
              <FlexRow gap={16}>
                <PrimaryButton
                  _css={css`
                    width: 160px;
                    height: 40px;
                  `}
                  onClick={() => {
                    set_openAddServiceArea(true)
                  }}
                >
                  지역 그룹 추가
                </PrimaryButton>
              </FlexRow>
            }
          />
          {/* EDIT:THIS */}
          <FilterPart
            _value={initialValues}
            _queryNameList={AREA_QUERY_DROPDOWN_LIST}
            _emitValue={(value) => {
              handleSetLocation(value)
            }}
            _FilterContents={() => {
              return <div></div>
            }}
          />
          {/* EDIT:THIS */}
          <RoundFillBox>
            <TableComponent<ResponseKeyName>
              key={updateList}
              _type="user"
              _url={apiURI}
              _topOffset={tableHeaderOffset}
              _config={TablePageTemplateTableConfigs}
              _parser={(response: ApiZoneList) => {
                const rebuild = responseParser(response)
                return {
                  _list: rebuild,
                  _cursor: disposeCursor(response.paging.cursor),
                }
              }}
              _ListContent={({ _row, _index }) => {
                const rowId = _row.id ? String(_row.id) : String(_index)
                return (
                  <TableRow
                    className={cn({
                      active: selectedId === rowId,
                    })}
                    onClick={(e) => {
                      e.stopPropagation()
                      set_selectedId(rowId)
                    }}
                    onDoubleClick={(e) => {
                      e.stopPropagation()
                      // EDIT:THIS !
                      navigate(`/services/area/${rowId}`)
                    }}
                  >
                    {TablePageTemplateTableConfigs.map((item, columnIndex) => {
                      // EDIT:THIS
                      const _id = item._id as keyof ResponseKeyName
                      const _value = _row[_id]
                      return (
                        <TableCell
                          key={'body' + _index + columnIndex}
                          _position={item._position}
                          _size={item._size}
                        >
                          <div>{_value}</div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              }}
            />
          </RoundFillBox>
        </S.Wrap>
      </ContentsBox>
    </>
  )
}
export default AreaList
