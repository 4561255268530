import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import { Dayjs } from 'dayjs'
import { ContentRadiusBox } from 'themes/Shared.styles'
export interface AccountInfoProps {
  _id: string
  _password: string
  _authority: string
  _registerDate: Dayjs | null
  _recentLogin: Dayjs | null
}

export const AccountInfo = ({
  _authority,
  _id,
  _password,
  _recentLogin,
  _registerDate,
  ...props
}: AccountInfoProps) => {
  return (
    <>
      <ContentRadiusBox>
        <BoxRowComponent _label={'아이디'} _contents={_id} />
        <BoxRowComponent _label={'비밀번호'} _contents={_password} />
        <BoxRowComponent _label={'권한'} _contents={_authority} />
        <BoxRowComponent
          _label={'관리자 등록일'}
          _contents={_registerDate && _registerDate.format('YYYY-MM-DD HH:mm')}
        />
        <BoxRowComponent
          _label={'최근 로그인'}
          _contents={_recentLogin && _recentLogin.format('YYYY-MM-DD HH:mm')}
        />
      </ContentRadiusBox>
    </>
  )
}
