import { BADGE_COLOR } from 'components/Badge/Badge'

export const COUPON_DISCOUNT_TYPE = {
  '금액 할인 쿠폰': '1',
  '정률 할인 쿠폰': '2',
} as const
export type CouponDiscountTypeKey = KeyOf<typeof COUPON_DISCOUNT_TYPE>

export const COUPON_DISCOUNT_TYPE_BADGE_COLOR = {
  '금액 할인 쿠폰': BADGE_COLOR.PrimaryLight,
  '정률 할인 쿠폰': BADGE_COLOR.PrimaryLight,
} as const

export const COUPON_TARGET_TYPE = {
  회원지정: '회원지정',
  코드발급: '코드발급',
} as const
export type CouponTargetTypeKey = KeyOf<typeof COUPON_TARGET_TYPE>

export const COUPON_TARGET_TYPE_BADGE_COLOR = {
  회원지정: BADGE_COLOR.PrimaryFill,
  코드발급: BADGE_COLOR.SuccessFill,
} as const

// API

export type CouponTarget = 'specify' | 'code'
