import { css } from '@emotion/react'
import { IconsChipsCancel } from 'assets'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
  PrimaryOutlineButton,
} from 'components/Buttons'
import DatePicker from 'components/DatePicker'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DraggablePopup from 'components/DraggablePopup'
import Dropdown from 'components/Dropdown'
import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import TextArea from 'components/Textarea'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ASTERISK,
  ContentRadiusBox,
  Flex,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import CalendarInPopup from '../../CalendarInPopup'
import SelectTeamPopup from '../../SelectTeamPopup'
import {
  TechsListItemTypeForSelect,
  parserForTechsListItemTypeForSelect,
} from '../WorkerAssignmentTab.types'

import S from './AssignTeamPopup.styles'
import {
  AssignTeamPopupProps,
  DaySchedulesType,
  INITIAL_DAY_SCHEDULE,
  tableConfigsForMaterialTechs,
  tableConfigsForMeasurementOrServiceTechs,
} from './AssignTeamPopup.types'

const pad = (value: number) => {
  const _value = String(value)
  return _value.length === 1 ? `0${_value}` : `${_value}`
}
const TIMES = Array.from(Array(24).keys()).reduce((prev, curr) => {
  prev[`${pad(curr)}:00`] = `${pad(curr)}:00`
  prev[`${pad(curr)}:30`] = `${pad(curr)}:30`
  return prev
}, {} as DropdownListType)

const labelText = {
  실측: {
    title: '실측 일정 선택',
    day: '실측 일자',
    time: '실측 시간',
    team: '실측팀 선택',
  },
  자재: {
    title: '자재 배달 일정 선택',
    day: '자재 배달 일자',
    time: '자재 배달 시간',
    team: '자재팀 선택',
  },
  시공: {
    title: '시공 일정 선택',
    day: '시공 일자',
    time: '시공 시간',
    team: '시공팀 선택',
  },
} as const
const AssignTeamPopup = ({
  _css,
  _open,
  _id,
  _data,
  _type,
  _onClose,
  _emitValue,
  ...props
}: AssignTeamPopupProps) => {
  const [openSelectTechs, set_openSelectTechs] = useState<{
    index: number
    data: DaySchedulesType
  } | null>(null)
  const [postData, set_postData] = useState<{
    daySchedules: DaySchedulesType[]
    remarks: string
  }>({
    daySchedules: [INITIAL_DAY_SCHEDULE],
    remarks: '',
  })
  const { daySchedules, remarks } = postData
  const handleDaySchedules = useCallback((value: DaySchedulesType[]) => {
    set_postData((prev) => ({ ...prev, daySchedules: value }))
  }, [])
  const handleRemarks = useCallback((value: string) => {
    set_postData((prev) => ({ ...prev, remarks: value }))
  }, [])
  useEffect(() => {
    if (_data) set_postData(_data)
  }, [_data])

  const apiUrl = useMemo(() => {
    return _type === '자재'
      ? `/worker/material/list?take=10&yyyymmdd=${dayjs(
          openSelectTechs?.data.currentDay
        ).format('YYYYMMDD')}`
      : `/worker/technician/list?take=10&type=${_type}&yyyymmdd=${dayjs(
          openSelectTechs?.data.currentDay
        ).format('YYYYMMDD')}`
  }, [_type, openSelectTechs])

  const NotValid = useMemo(() => {
    if (postData) {
      const checkAll = postData.daySchedules.every(
        (item) =>
          item.currentDay &&
          item.times.start &&
          item.times.end &&
          item.techs.length > 0
      )
      return !checkAll
    }
    return true
  }, [postData])
  if (!_open) {
    return <></>
  }
  return (
    <>
      {openSelectTechs && (
        <SelectTeamPopup<TechsListItemTypeForSelect>
          _api={apiUrl}
          _tableConfig={
            _type === '자재'
              ? tableConfigsForMaterialTechs
              : tableConfigsForMeasurementOrServiceTechs
          }
          // _title={labelText[_type].team}
          _title={'담당자 추가'}
          _emitValue={(value) => {
            set_openSelectTechs(null)
            handleDaySchedules(
              daySchedules.map((schedule, scheduleIndex) =>
                scheduleIndex === openSelectTechs.index
                  ? { ...schedule, techs: uniqBy(value, (item) => item.id) }
                  : schedule
              )
            )
          }}
          _currentTechs={openSelectTechs.data.techs}
          _onClose={() => {
            set_openSelectTechs(null)
          }}
          _q="searchValue"
          _responseParser={(response) =>
            parserForTechsListItemTypeForSelect(response, _type)
          }
        />
      )}
      <DraggablePopup
        _title={labelText[_type].title}
        _open={_open}
        _onClose={_onClose}
        _css={css`
          min-width: 1154px;
          width: 1154px;
        `}
        _bottom={
          <>
            <GrayScaleOutline onClick={_onClose}>취소</GrayScaleOutline>
            <PrimaryButton
              onClick={() => {
                _emitValue(postData)
              }}
              disabled={NotValid}
            >
              확인
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap {...props}>
          <CalendarInPopup _team={_type} />
          <>
            <S.RightWrap>
              <S.PopupInputLayout>
                <S.Header>
                  일정 선택
                  <ASTERISK />
                </S.Header>
                {daySchedules.map((schedule, scheduleIndex) => {
                  return (
                    <FlexColumn gap={12} key={'schedule' + scheduleIndex}>
                      <ContentRadiusBox gap={16}>
                        <BoxRowComponent _label={labelText[_type].day}>
                          <DatePicker
                            _css={css`
                              .mini-calendar {
                                right: 0px;
                              }
                            `}
                            _value={
                              schedule.currentDay
                                ? dayjs(schedule.currentDay)
                                : null
                            }
                            _emitValue={(value) => {
                              handleDaySchedules(
                                daySchedules.map((item, itemIndex) =>
                                  itemIndex === scheduleIndex
                                    ? {
                                        ...item,
                                        currentDay: value.format('YYYY-MM-DD'),
                                      }
                                    : item
                                )
                              )
                            }}
                          />
                        </BoxRowComponent>
                        <BoxRowComponent
                          _label={labelText[_type].time}
                          _css={css`
                            & > label {
                              width: 86px;
                              min-width: 86px;
                            }
                          `}
                        >
                          <FlexRow gap={16}>
                            <Dropdown.Underline
                              _css={css`
                                flex: 1;
                              `}
                              _list={TIMES}
                              _value={schedule.times.start}
                              _emitValue={(value) => {
                                handleDaySchedules(
                                  daySchedules.map((item, itemIndex) =>
                                    itemIndex === scheduleIndex
                                      ? {
                                          ...item,
                                          times: {
                                            ...item.times,
                                            start: String(value),
                                          },
                                        }
                                      : item
                                  )
                                )
                              }}
                            />
                            <TXT
                              _textStyle="Body/Large/Bold"
                              _color="Grayscale/Gray Default"
                            >
                              ~
                            </TXT>
                            <Dropdown.Underline
                              _css={css`
                                flex: 1;
                              `}
                              _list={TIMES}
                              _value={schedule.times.end}
                              _emitValue={(value) => {
                                handleDaySchedules(
                                  daySchedules.map((item, itemIndex) =>
                                    itemIndex === scheduleIndex
                                      ? {
                                          ...item,
                                          times: {
                                            ...item.times,
                                            end: String(value),
                                          },
                                        }
                                      : item
                                  )
                                )
                              }}
                            />
                          </FlexRow>
                        </BoxRowComponent>
                      </ContentRadiusBox>
                      {schedule.techs.length > 0 && (
                        <ContentRadiusBox>
                          <Flex gap={`6px 24px`} flexWrap={`wrap`}>
                            {schedule.techs.map((techs, techsIndex) => {
                              return (
                                <S.Chips
                                  key={techs.id + scheduleIndex}
                                  onClick={() => {
                                    handleDaySchedules(
                                      daySchedules.map((item, itemIndex) => {
                                        return itemIndex === scheduleIndex
                                          ? {
                                              ...item,
                                              techs: item.techs.filter(
                                                (ft, ftIndex) =>
                                                  ftIndex !== techsIndex
                                              ),
                                            }
                                          : item
                                      })
                                    )
                                  }}
                                >
                                  {techs.name}
                                  <S.SvgWrap>
                                    <IconsChipsCancel />
                                  </S.SvgWrap>
                                </S.Chips>
                              )
                            })}
                          </Flex>
                        </ContentRadiusBox>
                      )}
                      <S.Buttons>
                        <PrimaryOutlineButton
                          _mini
                          disabled={!schedule.currentDay}
                          onClick={() => {
                            set_openSelectTechs({
                              data: schedule,
                              index: scheduleIndex,
                            })
                          }}
                        >
                          {!schedule.currentDay
                            ? '날짜를 선택하세요.'
                            : '담당자 추가'}
                        </PrimaryOutlineButton>
                        <GrayScaleFill
                          _mini
                          disabled={postData.daySchedules.length < 2}
                          onClick={() => {
                            handleDaySchedules(
                              daySchedules.filter(
                                (ft, ftIndex) => ftIndex !== scheduleIndex
                              )
                            )
                          }}
                        >
                          일정 삭제
                        </GrayScaleFill>
                      </S.Buttons>
                    </FlexColumn>
                  )
                })}
              </S.PopupInputLayout>
              <PrimaryButton
                _css={css`
                  margin: 23px auto 31px;
                  width: 100%;
                  height: 30px;
                  gap: 4px;
                `}
                _mini
                onClick={() => {
                  handleDaySchedules([
                    ...daySchedules,
                    { ...INITIAL_DAY_SCHEDULE },
                  ])
                }}
              >
                일정 추가<span>+</span>
              </PrimaryButton>
              <S.PopupInputLayout>
                <S.Header>특이 사항</S.Header>
                <TextArea
                  placeholder="특이사항 입력"
                  _css={css`
                    min-height: 180px;
                  `}
                  value={remarks}
                  onChange={(e) => handleRemarks(e.target.value)}
                ></TextArea>
              </S.PopupInputLayout>
            </S.RightWrap>
          </>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default AssignTeamPopup
