import { css } from '@emotion/react'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import UnderlineInput from 'components/UnderlineInput'
import { useAppDispatch } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import { isEqual, uniq, uniqBy } from 'lodash-es'
import AlertRow from 'pages/Work/components/AlertRow'
import { useEffect, useMemo, useState } from 'react'
import { setToast } from 'store/toastSlice'
import { FlexRow } from 'themes/Shared.styles'
import { colors } from 'themes/styles'
import callAxios, { handleError } from 'utils/callAxios'
import { cn, updateAndDeleteBehind } from 'utils/helpers'
import S from './Funnel.styles'
import { ApiFunnels, FunnelProps } from './Funnel.types'

type NewCategoryType = [string | null, string | null, string | null]

const Funnel = ({ _css, ...props }: FunnelProps) => {
  const dispatch = useAppDispatch()
  const [openDeleteFunnel, set_openDeleteFunnel] = useState<number | null>(null)
  const [apiFunnels, set_apiFunnels] = useState<ApiFunnels | null>(null)
  const [initialFunnels, set_initialFunnels] = useState<ApiFunnels | null>(null)
  const [selectedCategory, set_selectedCategory] = useState<string[]>([])
  const [categoryInput, set_categoryInput] = useState<[string, string, string]>(
    ['', '', '']
  )
  const [newCategoryInput, set_newCategoryInput] = useState<NewCategoryType>([
    null,
    null,
    null,
  ])
  const [response] = useFetch<ApiFunnels>('order', '/order-funnels')
  useEffect(() => {
    if (response) {
      set_apiFunnels(response)
      set_initialFunnels(response)
      set_selectedCategory([])
    }
    return () => {
      set_selectedCategory([])
    }
  }, [response])

  const isEqualsData = useMemo(() => {
    return isEqual(apiFunnels, initialFunnels)
  }, [apiFunnels, initialFunnels])

  const noDataInChildren = useMemo(() => {
    if (!apiFunnels) return true
    return apiFunnels.some(
      (ancestor) =>
        ancestor.value.length === 0 ||
        ancestor.value.some(
          (parent) =>
            parent.value.length === 0 ||
            parent.value.some((child) => child.length === 0)
        )
    )
  }, [apiFunnels])

  const categoryName = (indexNumber: number) => {
    switch (indexNumber) {
      case 0:
        return '대분류'
      case 1:
        return '중분류'
      case 2:
        return '소분류'
      default:
        return ''
    }
  }

  const categoryList = useMemo(() => {
    if (!apiFunnels) return [[], [], []]
    const findSecond = apiFunnels
      .find((item) => item.key === selectedCategory[0])
      ?.value.map((item) => item.key)
    const findThird = apiFunnels
      .find((item) => item.key === selectedCategory[0])
      ?.value.find((item) => item.key === selectedCategory[1])?.value
    const secondCategory = findSecond ? findSecond : []
    const thirdCategory = findThird ? findThird : []
    return [apiFunnels.map((item) => item.key), secondCategory, thirdCategory]
  }, [apiFunnels, selectedCategory])

  const handleCategoryInput = (
    index: number,
    type: 'add' | 'update' | 'delete' = 'add'
  ) => {
    const nextValue = categoryInput[index]
    if (!nextValue) return
    if (index === 0) {
      set_apiFunnels((prev) => {
        if (!prev) return prev
        return type === 'add'
          ? uniqBy([...prev, { key: nextValue, value: [] }], (data) => data.key)
          : type === 'update'
          ? prev.map((item) =>
              item.key === selectedCategory[index]
                ? { ...item, key: nextValue }
                : item
            )
          : type === 'delete'
          ? prev.filter((item) => item.key !== selectedCategory[index])
          : prev
      })
      if (type === 'delete') {
        set_selectedCategory([])
      } else {
        set_selectedCategory((prev) => [nextValue])
      }
    }
    if (index === 1 && selectedCategory[0]) {
      set_apiFunnels((prev) => {
        if (!prev) return prev
        return prev.map((ft) =>
          ft.key === selectedCategory[0]
            ? {
                ...ft,
                value:
                  type === 'add'
                    ? uniqBy(
                        [...ft.value, { key: nextValue, value: [] }],
                        (data) => data.key
                      )
                    : type === 'update'
                    ? ft.value.map((item) =>
                        item.key === selectedCategory[index]
                          ? { ...item, key: nextValue }
                          : item
                      )
                    : type === 'delete'
                    ? ft.value.filter(
                        (item) => item.key !== selectedCategory[index]
                      )
                    : ft.value,
              }
            : ft
        )
      })
      if (type === 'delete') {
        set_selectedCategory((prev) => [prev[0]])
      } else {
        set_selectedCategory((prev) => [prev[0], nextValue])
      }
    }
    if (index === 2 && selectedCategory[0] && selectedCategory[1]) {
      set_apiFunnels((prev) => {
        if (!prev) return prev
        return prev.map((ft) =>
          ft.key === selectedCategory[0]
            ? {
                ...ft,
                value: ft.value.map((fd) =>
                  fd.key === selectedCategory[1]
                    ? {
                        ...fd,
                        value:
                          type === 'add'
                            ? uniq([...fd.value, nextValue])
                            : type === 'update'
                            ? fd.value.map((val) =>
                                val === selectedCategory[2] ? nextValue : val
                              )
                            : type === 'delete'
                            ? fd.value.filter(
                                (val) => val !== selectedCategory[2]
                              )
                            : fd.value,
                      }
                    : fd
                ),
              }
            : ft
        )
      })
      if (type === 'delete') {
        set_selectedCategory((prev) => [prev[0], prev[1]])
      } else {
        set_selectedCategory((prev) => [prev[0], prev[1], nextValue])
      }
    }
    if (type === 'add') {
      set_newCategoryInput(
        (prev) =>
          prev.map((item, itemIndex) =>
            itemIndex === index ? null : item
          ) as NewCategoryType
      )
    }
  }
  const handleFunnelsUpdate = async () => {
    if (!apiFunnels) return
    try {
      await callAxios('order').put('/order-funnels', { value: apiFunnels })
      set_initialFunnels([...apiFunnels])
      dispatch(setToast(['저장되었습니다.', 'success']))
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      <Modal
        open={typeof openDeleteFunnel === 'number'}
        onClose={() => set_openDeleteFunnel(null)}
      >
        <MiniPopup
          _title="유입경로 삭제"
          _buttonHandler={
            <>
              <GrayScaleFill onClick={() => set_openDeleteFunnel(null)}>
                취소
              </GrayScaleFill>
              <PrimaryButton
                onClick={() => {
                  const categoryIndex = openDeleteFunnel
                  if (categoryIndex === null) return
                  handleCategoryInput(categoryIndex, 'delete')
                  set_openDeleteFunnel(null)
                }}
              >
                삭제
              </PrimaryButton>
            </>
          }
        >
          해당 유입경로를 삭제시겠습니까?
          <br />
          하위 경로와 함께 삭제됩니다
        </MiniPopup>
      </Modal>
      <ContentsBox
        _type="relative"
        _css={css`
          margin: 0px auto;
          padding: 64px 0px;
          & > div {
            max-width: 1270px;
            background-color: ${colors['White/White off']};
            padding: 0;
            border: 1px solid ${colors['Grayscale/Gray Lighter']};
            border-radius: 16px;
          }
        `}
      >
        <S.Wrap _css={_css} {...props}>
          <S.Header>
            <h1>주문 유입 경로 설정</h1>
            <GrayScaleFill
              disabled={isEqualsData || noDataInChildren}
              onClick={handleFunnelsUpdate}
            >
              저장
            </GrayScaleFill>
          </S.Header>
          <S.Content>
            <AlertRow>
              {`대분류>중분류>소분류까지 경로를 모두 입력해 주세요. 대분류>중분류만 추가 시 저장이 불가합니다.`}
            </AlertRow>
            {apiFunnels && (
              <>
                {categoryList.map((currentCategoryList, categoryIndex) => {
                  return (
                    <FlexRow
                      key={'category' + categoryIndex}
                      gap={12}
                      justifyContent="space-between"
                      className="box-row"
                    >
                      <div>
                        <S.BoxLeftHeader>
                          <S.ClassifyHeader>
                            <h2>{categoryName(categoryIndex)}</h2>
                            <div className="count">
                              총 {currentCategoryList.length}개
                            </div>
                          </S.ClassifyHeader>
                          <GrayScaleOutline
                            disabled={
                              categoryIndex > 0 &&
                              !selectedCategory[categoryIndex - 1]
                            }
                            onClick={() => {
                              set_newCategoryInput((prev) => {
                                return prev.map((_, itemIndex) =>
                                  itemIndex === categoryIndex ? '' : null
                                ) as [string, string, string]
                              })
                              set_selectedCategory((prev) => {
                                return updateAndDeleteBehind(
                                  prev,
                                  categoryIndex,
                                  ''
                                )
                              })
                              set_categoryInput((prev) => {
                                return prev.map((item, itemIndex) =>
                                  itemIndex === categoryIndex ? '' : item
                                ) as [string, string, string]
                              })
                            }}
                          >
                            추가
                          </GrayScaleOutline>
                        </S.BoxLeftHeader>
                        <S.BoxLeftContent>
                          {currentCategoryList.length > 0 ? (
                            <>
                              {currentCategoryList.map((keyName) => {
                                return (
                                  <button
                                    key={'keyName' + keyName + categoryIndex}
                                    className={cn({
                                      active:
                                        selectedCategory[categoryIndex] ===
                                        keyName,
                                    })}
                                    onClick={() => {
                                      set_newCategoryInput((prev) => {
                                        return prev.map(
                                          () => null
                                        ) as NewCategoryType
                                      })
                                      set_selectedCategory((prev) => {
                                        return updateAndDeleteBehind(
                                          prev,
                                          categoryIndex,
                                          keyName
                                        )
                                      })
                                      set_categoryInput((prev) => {
                                        return prev.map((item, itemIndex) =>
                                          itemIndex === categoryIndex
                                            ? keyName
                                            : item
                                        ) as [string, string, string]
                                      })
                                    }}
                                  >
                                    {keyName}
                                  </button>
                                )
                              })}
                            </>
                          ) : (
                            <S.NotFountList>
                              {categoryIndex - 1 < 0 ? (
                                '대분류를 추가하세요'
                              ) : (
                                <>
                                  {categoryName(categoryIndex - 1)}를
                                  선택하세요.
                                </>
                              )}
                            </S.NotFountList>
                          )}
                        </S.BoxLeftContent>
                      </div>
                      <div>
                        {newCategoryInput[categoryIndex] !== null ? (
                          <>
                            <BoxRowComponent
                              _label={`${categoryName(categoryIndex)} *`}
                            >
                              <UnderlineInput
                                value={categoryInput[categoryIndex]}
                                handleValue={(value) => {
                                  set_categoryInput((prev) => {
                                    return prev.map((item, itemIndex) =>
                                      itemIndex === categoryIndex ? value : item
                                    ) as [string, string, string]
                                  })
                                }}
                              />
                            </BoxRowComponent>
                            <S.BoxRightButtons>
                              <GrayScaleOutline
                                disabled={!categoryInput[categoryIndex]}
                                onClick={() => {
                                  handleCategoryInput(categoryIndex)
                                }}
                              >
                                추가하기
                              </GrayScaleOutline>
                            </S.BoxRightButtons>
                          </>
                        ) : selectedCategory[categoryIndex] ? (
                          <>
                            <BoxRowComponent
                              _label={`${categoryName(categoryIndex)} *`}
                            >
                              <UnderlineInput
                                value={categoryInput[categoryIndex]}
                                handleValue={(value) => {
                                  set_categoryInput((prev) => {
                                    return prev.map((item, itemIndex) =>
                                      itemIndex === categoryIndex ? value : item
                                    ) as [string, string, string]
                                  })
                                }}
                              />
                            </BoxRowComponent>
                            <S.BoxRightButtons>
                              <GrayScaleFill
                                disabled={!selectedCategory[categoryIndex]}
                                onClick={() =>
                                  set_openDeleteFunnel(categoryIndex)
                                }
                              >
                                삭제
                              </GrayScaleFill>
                              <GrayScaleOutline
                                disabled={!categoryInput[categoryIndex]}
                                onClick={() =>
                                  handleCategoryInput(categoryIndex, 'update')
                                }
                              >
                                수정
                              </GrayScaleOutline>
                            </S.BoxRightButtons>
                          </>
                        ) : (
                          <>
                            <S.NotFountList>
                              {`${categoryName(categoryIndex)}`}를 추가,
                              선택하세요.
                            </S.NotFountList>
                          </>
                        )}
                      </div>
                    </FlexRow>
                  )
                })}
              </>
            )}
          </S.Content>
        </S.Wrap>
      </ContentsBox>
    </>
  )
}
export default Funnel
