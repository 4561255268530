import { Copy } from 'components/DetailsComponents'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ContentRadiusBox } from 'themes/Shared.styles'
import { ByCodeProps } from './ByCode.types'

const ByCode = ({ _css, inputs, handleInput, ...props }: ByCodeProps) => {
  const { id: userId } = useParams<'id'>()
  const [code, set_code] = useState<string>('')

  // code generate
  useEffect(() => {
    const newCode = generateCode()
    set_code(newCode)
    handleInput('code', newCode)
  }, [handleInput])
  return (
    <ContentRadiusBox className="radius-box-gap">
      <BoxRowComponent _label={'쿠폰 코드'}>
        <Copy _text={code}></Copy>
      </BoxRowComponent>
      <BoxRowComponent _label={'쿠폰 발급 개수 *'}>
        <UnderlineNumberInput
          readOnly={!!userId}
          placeholder="발급 개수 입력"
          value={inputs.issuedCount}
          handleValue={(value) => {
            handleInput('issuedCount', value)
          }}
          suffix="개"
        />
      </BoxRowComponent>
    </ContentRadiusBox>
  )
}
const generateCode = () => {
  const first4 = localStorage.getItem('dd-tks')?.slice(0, 4).toUpperCase()
  const second6 = dayjs().format('YYMMDD')
  const last5 = Math.random().toString().slice(5, 10)
  return first4 + second6 + last5
}
export default ByCode
