import { css } from '@emotion/react'
import { device_uuid } from 'API_URL'
import { GrayScaleText, PrimaryButton } from 'components/Buttons'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import UnderlineInput from 'components/UnderlineInput'
import { useAppDispatch } from 'hooks/reduxHooks'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useMatchInput from 'hooks/useMatchInput'
import { MutableRefObject, useMemo, useRef, useState } from 'react'
import { TokenType, setAuth } from 'store/authSlice'
import { setStep } from 'store/signSlice'
import { FlexColumn, TXT } from 'themes/Shared.styles'
import callAxios, {
  extractData,
  handleError,
  setTokenToLocalStorage,
} from 'utils/callAxios'
import SA from '../Authorization.styles'
import LogoPart from '../LogoPart'
import S from './LoginComponent.styles'
import { LoginComponentProps, LoginValue } from './LoginComponent.types'
import PasswordCheck from './PasswordCheck'

const LOGIN_DATA_INITIAL_VALUE: LoginValue = {
  username: process.env.NODE_ENV === 'development' ? 'devadmin' : '',
  password: process.env.NODE_ENV === 'development' ? 'testpwd1234!@' : '',
}

const LoginComponent = ({ _isAdmin, ...props }: LoginComponentProps) => {
  const dispatch = useAppDispatch()
  const { inputs, handleInput } = useMatchInput(LOGIN_DATA_INITIAL_VALUE)
  const trueWhenEmpty = useEmptyCheck(inputs)
  const usernameRef = useRef() as MutableRefObject<HTMLDivElement>
  const passwordRef = useRef() as MutableRefObject<HTMLDivElement>
  const [usernameError, set_usernameError] = useState<string>('')
  const [passwordError, set_passwordError] = useState<string>('')
  const [passwordType, set_passwordType] = useState<'password' | 'text'>(
    'password'
  )
  const togglePasswordType = () => {
    set_passwordType((type) => (type === 'password' ? 'text' : 'password'))
  }
  const canNotSubmit = useMemo(() => {
    return (
      trueWhenEmpty ||
      inputs.username.length < 6 ||
      inputs.password.length < 8 ||
      !!usernameError ||
      !!passwordError
    )
  }, [
    inputs.password.length,
    inputs.username.length,
    passwordError,
    trueWhenEmpty,
    usernameError,
  ])
  const handleSubmit = async () => {
    // TODO :: api
    if (canNotSubmit) return

    try {
      const axiosRes = await callAxios('user').post<ApiAuthData>(
        '/auth/admin/login',
        {
          userName: inputs.username,
          pwd: inputs.password,
        },
        {
          headers: {
            skipToken: true,
            deviceId: device_uuid,
          },
        }
      )
      if (axiosRes) {
        const res = extractData<TokenType>(axiosRes)
        const token: TokenType = res
        await setTokenToLocalStorage(token)
        dispatch(setAuth({ status: 'authorized', token, isSuperAdmin: false }))
      }
    } catch (error) {
      handleError(error)
    }
  }
  const handleJoinStep = () => {
    dispatch(setStep('cert'))
  }
  const [openFindPasswordModal, set_openFindPasswordModal] =
    useState<boolean>(false)
  // const closeFindPasswordModal = () => {
  //   set_openFindPasswordModal(false)
  // }
  // const handleFindPassword = async () => {
  //   set_openFindPasswordModal(false)
  //   dispatch(setStep('password'))
  // }
  const [openDormantModal, set_openDormantModal] = useState<boolean>(false)
  const closeDormantModal = () => {
    set_openDormantModal(false)
  }
  const handleDormant = async () => {
    closeDormantModal()
  }
  const [idForChangePassword, set_idForChangePassword] = useState<string>('')
  const handleRequestPasswordChange = async () => {
    try {
      const postData = {
        userName: idForChangePassword,
      }
      await callAxios('user').post(`/auth/admin/pwd/request-restore`, postData)
      window.location.reload()
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      {/* <Modal open={openFindPasswordModal} onClose={closeFindPasswordModal}>
        <MiniPopup
          _title={`비밀번호 초기화`}
          _buttonHandler={
            <>
              <GrayScaleFill onClick={closeFindPasswordModal}>
                취소
              </GrayScaleFill>
              <PrimaryButton onClick={handleFindPassword}>
                요청하기
              </PrimaryButton>
            </>
          }
        >{`비밀번호가 생각나지 않으실때,
      초기화 요청 후 다시 로그인 해주세요.`}</MiniPopup>
      </Modal> */}
      <Modal open={openDormantModal} onClose={closeDormantModal}>
        <MiniPopup
          _title={`휴면계정 안내`}
          _buttonHandler={handleDormant}
          _buttonText="해제하기"
        >{`오랫동안 접속하지 않아 휴면 전환되었습니다.
        회원님의 휴면상태를 해제할까요?`}</MiniPopup>
      </Modal>
      <S.Wrap>
        <LogoPart />
        <SA.Center>
          <>
            {openFindPasswordModal ? (
              <>
                <S.PasswordContents>
                  <div>
                    <FlexColumn gap={32}>
                      <TXT
                        _textStyle="Display/2 Bold"
                        _color="Grayscale/Black light"
                      >
                        비밀번호 변경 요청
                      </TXT>
                      <div>
                        관리자에게 비밀번호 변경 요청을 합니다.
                        <br />
                        아이디를 입력해 주세요.
                      </div>
                    </FlexColumn>
                    <UnderlineInput
                      _css={css`
                        margin-top: 24px;
                      `}
                      value={idForChangePassword}
                      handleValue={(value) => {
                        set_idForChangePassword(value)
                      }}
                      placeholder="아이디"
                    />
                  </div>
                  <PrimaryButton
                    disabled={!idForChangePassword}
                    onClick={() => {
                      handleRequestPasswordChange()
                    }}
                    width={`100%`}
                    padding={`12px 20px`}
                  >
                    변경 요청
                  </PrimaryButton>
                </S.PasswordContents>
              </>
            ) : (
              <>
                <SA.Header>로그인</SA.Header>
                <S.Contents>
                  <UnderlineInput
                    ref={usernameRef}
                    label="아이디"
                    autoComplete="username"
                    value={inputs['username']}
                    handleValue={(value) => {
                      set_usernameError('')
                      handleInput('username', value)
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        passwordRef.current?.querySelector('input')?.focus()
                      }
                    }}
                    maxLength={20}
                    error={usernameError}
                  />
                  <UnderlineInput
                    _css={css`
                      margin-top: 24px;
                    `}
                    autoComplete="password"
                    ref={passwordRef}
                    type={passwordType}
                    label="비밀번호"
                    value={inputs['password']}
                    handleValue={(value) => {
                      set_passwordError('')
                      handleInput('password', value)
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        handleSubmit()
                      }
                    }}
                    error={passwordError}
                  />
                  <PasswordCheck
                    _active={passwordType === 'text'}
                    _css={css`
                      margin-top: 8px;
                      justify-content: flex-end;
                    `}
                    onClick={togglePasswordType}
                  />
                </S.Contents>
                <S.Bottom>
                  {_isAdmin && (
                    <div>
                      <GrayScaleText
                        onClick={() => {
                          set_openFindPasswordModal(true)
                        }}
                        _mini
                        _css={css`
                          margin: 0 auto;
                          padding: 5px 12px;
                        `}
                      >
                        비밀번호가 생각나지 않으시나요?
                      </GrayScaleText>
                    </div>
                  )}
                  {!_isAdmin && (
                    <div>
                      <GrayScaleText
                        onClick={handleJoinStep}
                        _mini
                        _css={css`
                          margin: 0 auto;
                          padding: 5px 12px;
                        `}
                      >
                        회원가입 또는 계정 찾기
                      </GrayScaleText>
                    </div>
                  )}
                  <PrimaryButton
                    _css={css`
                      width: 100%;
                      padding: 10px 20px;
                    `}
                    disabled={canNotSubmit}
                    onClick={handleSubmit}
                  >
                    로그인
                  </PrimaryButton>
                </S.Bottom>
              </>
            )}
          </>
        </SA.Center>
        <SA.Dim />
      </S.Wrap>
    </>
  )
}
export default LoginComponent
