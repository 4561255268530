import { BADGE_COLOR } from 'components/Badge/Badge'
import dayjs from 'dayjs'
import { MatchInputProps } from 'hooks/useMatchInput'
import {
  HandleOrderItemsType,
  OrderDetailsValuesType,
  OrderItemType,
  OrderSelectIdsType,
  OrderStateType,
  ServiceDetailsValuesType,
} from 'pages/Work/Work.types'

import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export const ORDER_STATE_COLOR = {
  취소요청: BADGE_COLOR.Danger,
  '취소 완료': BADGE_COLOR.Gray,
} as const
export type OrderStateKey = keyof typeof ORDER_STATE_COLOR
export const isOrderState = (value: any): value is OrderStateKey => {
  return (Object.keys(ORDER_STATE_COLOR) as Array<OrderStateKey>).some(
    (keyName) => keyName === value
  )
}

export const initOrderItem: OrderItemType = {
  id: 0,
  status: null,
  orderAt: dayjs().format('YYYY-MM-DD'),
  name: '',
  amount: 0,
}
export interface OrderInfoTabProps
  extends DivAttributes,
    MatchInputProps<ServiceDetailsValuesType> {
  _type: OrderStateType
  _id: string
  _css?: AdditionalCss
  _cancelPaymentState: boolean
  _cancelServiceState: boolean
  _orderItem: OrderDetailsValuesType
  _selected: OrderSelectIdsType
  _emitValue: (value: OrderSelectIdsType) => void
  _handleOrderItems?: HandleOrderItemsType
}
