import { css } from '@emotion/react'
import { ImagesDetailsProfile } from 'assets'
import Badge from 'components/Badge'
import { BADGE_COLOR } from 'components/Badge/Badge'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import dayjs from 'dayjs'
import { TECHS_TYPES_BADGE_COLOR_SET } from 'pages/System/Techs/TechsList/TechsList.types'
import {
  ContentBoxWrap,
  ContentProfileBox,
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
} from 'themes/Shared.styles'
import { PWD_NOT_CHANGED } from 'types/share'
import { thousand } from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import { TechsDetailsReadOnlyProps } from './TechsDetailsReadOnly.types'
const TechsDetailsReadOnly = ({
  inputs,
  _css,
  ...props
}: TechsDetailsReadOnlyProps) => {
  return (
    <>
      <ContentBoxWrap>
        <ContentProfileBox>
          <div className="wrap">
            <FlexColumn gap={12} alignItems="center">
              <div
                data-profile
                style={{
                  backgroundImage: `url(${
                    inputs.profileImage
                      ? inputs.profileImage
                      : ImagesDetailsProfile
                  })`,
                }}
              />
              <div>
                <div className="name">{inputs.name}</div>
                <div className="phone">
                  {parseToFormattedStr(inputs.phone, 'phone')}
                </div>
                <FlexRow gap={4} className="badges" justifyContent={'center'}>
                  {inputs.badges.inActive && (
                    <Badge {...BADGE_COLOR.Gray}>비활성화</Badge>
                  )}
                  {inputs.badges.techsType && (
                    <Badge
                      {...TECHS_TYPES_BADGE_COLOR_SET[inputs.badges.techsType]}
                    >
                      {inputs.badges.techsType}
                    </Badge>
                  )}
                  {inputs.measureAble && (
                    <Badge {...BADGE_COLOR.AccentLight}>실측가능</Badge>
                  )}
                </FlexRow>
              </div>
            </FlexColumn>
          </div>
        </ContentProfileBox>
        <BoxLayout _title={'회원 정보'}>
          <FlexColumn
            gap={12}
            width="100%"
            _css={css`
              & > div {
                width: 100%;
              }
            `}
          >
            <ContentRadiusBox>
              <BoxRowComponent _label={'아이디'} _contents={inputs.userId} />
              <BoxRowComponent
                _label={'비밀번호'}
                _contents={
                  inputs.password === PWD_NOT_CHANGED
                    ? '********'
                    : inputs.password
                }
              />
              <BoxRowComponent
                _label={'가입일'}
                _contents={
                  inputs.createdAt
                    ? dayjs(inputs.createdAt).format('YYYY-MM-DD')
                    : null
                }
              />
              <BoxRowComponent
                _label={'주민번호'}
                _contents={parseToFormattedStr(
                  inputs.residentNumber,
                  'residentNumber'
                )}
              />
              <BoxRowComponent
                _label={'계좌 정보'}
                _contents={inputs.accountNumber}
              />
            </ContentRadiusBox>
            <ContentRadiusBox>
              <BoxRowComponent
                _label={'총 시공 횟수'}
                _contents={
                  inputs.serviceCount ? `${inputs.serviceCount}회` : null
                }
              />
              <BoxRowComponent
                _label={'최근 시공일'}
                _contents={
                  inputs.recentServiceDate
                    ? dayjs(inputs.recentServiceDate).format('YYYY-MM-DD')
                    : null
                }
              />
            </ContentRadiusBox>
            {inputs.badges.techsType !== '프리랜서' && (
              <ContentRadiusBox>
                <BoxRowComponent
                  _label={'기본 급여'}
                  _contents={
                    inputs.salary ? `${thousand(inputs.salary)}원` : null
                  }
                />
                <BoxRowComponent
                  _label={'기본 정산 비율'}
                  _contents={
                    inputs.settlementRatio ? `${inputs.settlementRatio}%` : null
                  }
                />
              </ContentRadiusBox>
            )}
          </FlexColumn>
        </BoxLayout>
      </ContentBoxWrap>
    </>
  )
}
export default TechsDetailsReadOnly
