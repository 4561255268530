import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  GrayScaleFill,
  GrayScaleOutline,
  GrayScaleText,
  PrimaryButton,
  PrimaryOutlineButton,
} from 'components/Buttons'
import DetailsDefault, { ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import {
  ApiGalleryType,
  ApiImagesWithGalleryItem,
} from 'components/Gallery/Gallery.types'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import ModifySettlementRatio from 'components/ModifySettlementRatio'
import RowTab from 'components/RowTab'
import Textarea from 'components/Textarea'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { workStatusToKorean } from 'hooks/useAllMatch'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import LoadingPage from 'layout/LoadingPage'
import { clone, orderBy } from 'lodash-es'
import {
  ApiSettlementForTechs,
  ApiWorkDetails,
  HandleOrderItemsType,
  OrderSelectIdsType,
  SERVICE_DETAILS_INITIAL_VALUE,
  parserForServiceDetailsValues,
} from 'pages/Work/Work.types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { initialGalleryData, setCurrentGallery } from 'store/gallerySlice'
import { setRefresh } from 'store/refreshSlice'
import { setToast } from 'store/toastSlice'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  Flex,
  FlexColumn,
  FlexRow,
} from 'themes/Shared.styles'
import { oneProduct, testProduct } from 'themes/sample'
import { colors, typography } from 'themes/styles'
import callAxios, { handleError } from 'utils/callAxios'
import {
  cn,
  getFileNameFromUrl,
  getKeyNameByValue,
  isObjectValueType,
  objectKeys,
  thousand,
} from 'utils/helpers'
import CancelServiceButtons from '../CancelServiceButtons'
import CancelServiceModal from '../CancelServiceModal'
import CancelWork from '../CancelWork'
import { CancelPostData } from '../CancelWork/CancelWork.types'
import CanceledPaymentInfo from '../CanceledPaymentInfo'
import ExtraFeesInService from '../ExtraFeesInService'
import { TaskAdditionalItem } from '../ExtraFeesInService/ExtraFeesInService.types'
import OrderInfoTab from '../OrderInfoTab'
import PaymentInfo from '../PaymentInfo'
import RequestDetails from '../RequestDetails'
import WorkerAssignmentTab from '../WorkerAssignmentTab'
import { WorkDetailsProps } from './WorkDetails.types'
const BoxTextarea = styled(Textarea)`
  border: none;
  min-height: 120px;
  padding: 13px 0px;
`
const initSelected: OrderSelectIdsType = {
  selectIds: [],
  rejectIds: [],
  deleteIds: [],
}
const tabs = ['주문 정보', '숙련공 배정', '결제 정보'] as const

const WorkDetails = ({
  _css,
  _id,
  _type = 'ongoing',
  ...props
}: WorkDetailsProps) => {
  const { Layout: L } = S
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [response, , callRefresh] = useFetch<ApiGalleryType>(
    'task',
    `/gallery/task/${_id}`
  )
  const [details, isError] = useFetch<ApiWorkDetails>(
    'task',
    `/order/${_id}/admin`,
    {
      disabled: !_id,
    }
  )
  const serviceDetailsData = useMatchInput(SERVICE_DETAILS_INITIAL_VALUE)
  const [currentTab, set_currentTab] = useState<typeof tabs[number]>(tabs[0])
  const [searchParams, set_searchParams] = useSearchParams()
  const [selected, set_selected] = useState<OrderSelectIdsType>(
    clone(initSelected)
  )
  const [cancelPaymentState, set_cancelPaymentState] = useState<boolean>(false)
  const [cancelServiceState, set_cancelServiceState] = useState<boolean>(false)
  const [openCancelWork, set_openCancelWork] = useState<boolean>(false)
  const [openSettlement, set_openSettlement] = useState<{ open: boolean }>({
    open: false,
  })
  const [openModal, set_openModal] = useState<boolean>(false)

  const { handleInput, fetchInit, inputs } = serviceDetailsData

  const [settlements] = useFetch<{ list: ApiSettlementForTechs[] }>(
    'task',
    `/settlement/technician/log/list?taskId=${_id}&take=99999`,
    {
      disabled: !_id || inputs.serviceState !== '시공완료',
    }
  )
  useEffect(() => {
    if (settlements) {
      fetchInit((prev) => ({
        ...prev,
        settlementRatios: orderBy(settlements.list, (value) => value.id).map(
          (item) => ({
            id: item.id,
            name: item.technicianName,
            company: item.isFreeLancer ? '프리랜서' : '수수다',
            serviceTime: Number(item.workHour),
            dutyState: item.overWorkHour ? true : false,
            ratio: item.settlementRatio,
            amount: item.settlementWages,
            originalTaskAmount: item.originalTaskWages,
          })
        ),
      }))
    }
  }, [settlements, fetchInit])
  const parseGroupImages = useCallback((value: ApiImagesWithGalleryItem[]) => {
    const nextData = value.map((item) => ({
      id: item.id,
      title: item.title,
      list: {
        '360 사진': item.image360
          ? [
              {
                id: getFileNameFromUrl(item.image360),
                name: getFileNameFromUrl(item.image360),
                url: item.image360,
              },
            ]
          : [],
        사진: item.image.map((data) => ({
          id: getFileNameFromUrl(data),
          name: getFileNameFromUrl(data),
          url: data,
        })),
      },
    }))
    return objectKeys(nextData).length > 0 ? nextData : null
  }, [])
  const workRoute = useMemo(() => {
    return getKeyNameByValue(workStatusToKorean, inputs.serviceState)
  }, [inputs.serviceState])
  useEffect(() => {
    if (response) {
      const 실측사진 = parseGroupImages(response.measurement)
      const 시공전 = parseGroupImages(response.beforeConstruction)
      const 시공후 = parseGroupImages(response.afterConstruction)
      dispatch(
        setCurrentGallery({
          id: response.taskId,
          requestId: response.serviceRequestImage.id,
          data: {
            ...initialGalleryData.data,
            '요청 사진':
              response.serviceRequestImage.serviceRequestImageUrl.map(
                (item) => ({
                  id: getFileNameFromUrl(item),
                  name: getFileNameFromUrl(item),
                  url: item,
                })
              ),
            '실측 사진': 실측사진,
            '시공전 사진': 시공전,
            '시공후 사진': 시공후,
          },
        })
      )
    }
  }, [dispatch, response, parseGroupImages])
  useEffect(() => {
    if (details) {
      fetchInit((prev) => parserForServiceDetailsValues(details, _type, prev))
    }
  }, [dispatch, details, fetchInit, _type])
  useEffect(() => {
    const currentTabQuery = searchParams.get('tabs')
    set_cancelServiceState(false)
    set_cancelPaymentState(false)
    if (isObjectValueType(tabs, currentTabQuery)) {
      set_currentTab(currentTabQuery)
    } else {
      set_currentTab(tabs[0])
    }
  }, [searchParams])

  const handleOrderItems: HandleOrderItemsType = useCallback(
    (values) => {
      handleInput('orderItems', (prev) => {
        if (typeof values === 'function') {
          return values(prev.orderItems)
        } else {
          return values
        }
      })
    },
    [handleInput]
  )

  const handleTab = useCallback(
    (value: typeof tabs[number]) => {
      set_currentTab(value)
      set_cancelServiceState(false)
      set_cancelPaymentState(false)
      if (value === currentTab) return
      searchParams.delete('assign')
      searchParams.set('tabs', value)
      set_searchParams(searchParams)
      const leftContentScrollDom = document.querySelector(
        '#left-contents > div'
      )
      if (leftContentScrollDom) {
        leftContentScrollDom.scrollTo(0, 0)
      }
    },
    [currentTab, searchParams, set_searchParams]
  )

  const handleSelected = useCallback((value: OrderSelectIdsType) => {
    set_selected(value)
  }, [])

  const handleCancelState = useCallback(
    (type: 'service' | 'payment') => {
      if (type === 'payment') {
        set_cancelPaymentState((prev) => !prev)
        set_cancelServiceState(false)
      } else {
        set_cancelServiceState((prev) => !prev)
        set_cancelPaymentState(false)
      }
      set_currentTab(tabs[0])
      handleSelected(clone(initSelected))
    },
    [handleSelected]
  )
  const [postData, set_postData] = useState<CancelPostData | null>(null)
  const handleDeleteOrCancelOrder = async () => {
    try {
      if (cancelPaymentState) {
        let nextPostData: CancelPostData = {
          cancelDispatch: false,
          tasks: selected.deleteIds.map((item) => {
            return {
              id: String(item),
              reject: selected.rejectIds.includes(item),
              cancelAmount: null,
              cancelTaskPrice: null,
            }
          }),
        }
        if (postData) {
          nextPostData = postData
        }
        await callAxios('task').post(
          `/order/${inputs.orderId}/cancel`,
          nextPostData
        )
        set_postData(null)
        set_cancelServiceState(false)
        set_cancelPaymentState(false)
        handleSelected(clone(initSelected))
        dispatch(setRefresh(dayjs().valueOf()))
        callRefresh()
      } else if (cancelServiceState) {
        const patchData = {
          ids: selected.deleteIds,
        }
        await callAxios('task').patch(`/task/cancel`, patchData)
        dispatch(setRefresh(dayjs().valueOf()))
        navigate(`/work/cancelled`)
      }
    } catch (error) {
      handleError(error)
    }
  }
  // 다음 상태로 변경 API Call
  const handleChangeOrderStatus = useCallback(async () => {
    try {
      await callAxios('task').patch(`/task/${_id}/status `)
      dispatch(setRefresh(dayjs().valueOf()))
      dispatch(setToast({ message: '상태가 변경되었습니다.', type: 'success' }))
      switch (inputs.serviceState) {
        case '주문완료':
          navigate(`/work/ordered`)
          break
        case '시공대기':
          navigate(`/work/waiting`)
          break
        case '시공중':
          navigate(`/work/ongoing`)
          break
        default:
          return
      }
    } catch (e) {
      handleError(e)
    }
  }, [inputs.serviceState, _id, navigate, dispatch])

  useEffect(() => {
    handleOrderItems({
      main: oneProduct,
      additional: testProduct,
    })
  }, [handleOrderItems])

  const handleOpenSettlementRatio = () => {
    set_openSettlement({ open: true })
  }
  const [openExtraFees, set_openExtraFees] = useState<
    TaskAdditionalItem[] | null
  >(null)

  const handleSubmitExtraFee = async (value: TaskAdditionalItem[]) => {
    try {
      const postData = value.map((item) => {
        return {
          taskId: item.taskId,
          additionalCost: item.list.map((data) => {
            return {
              taskName: data.taskName,
              price: data.price,
              taskPrice: data.taskPrice ?? 0,
              messageToUser: data.messageToUser,
              note: data.note,
              isPaid: data.isPaid ? true : false,
            }
          }),
        }
      })
      await callAxios('task').post(`/additional-payment`, postData)
      set_openExtraFees(null)
      dispatch(setRefresh(dayjs().valueOf()))
      navigate(`/unpaid`)
    } catch (error) {
      handleError(error)
    }
  }
  const handleUnpaidFee = async () => {
    try {
      const patchData = {
        paidCost: currentUnpaid.price,
        note: currentUnpaid.reason,
      }
      const res = await callAxios('task').patch<DoubleDResponse<number>>(
        `/unpaid/${_id}`,
        patchData
      )
      if (res.data.data === 0) {
        set_openModal(true)
      } else if (typeof res.data.data === 'number') {
        handleInput('paymentInfo', (prev) => {
          if (!prev.paymentInfo) return prev.paymentInfo
          if (!prev.paymentInfo.paidCost) return prev.paymentInfo
          return {
            ...prev.paymentInfo,
            unpaidCost: res.data.data,
            paidCost: {
              ...prev.paymentInfo.paidCost,
              price: currentUnpaid.price ?? 0,
              note: currentUnpaid.reason ?? '',
            },
          }
        })
        set_unpaidControl(false)
      }
    } catch (error) {
      handleError(error)
    }
  }
  const [unpaidControl, set_unpaidControl] = useState<boolean>(false)
  const [currentUnpaid, set_currentUnpaid] = useState<{
    price: number | null
    reason: string
    unpaid: number | null
  }>({
    price: null,
    reason: '',
    unpaid: null,
  })
  const [prevUnpaid, set_prevUnpaid] = useState<{
    price: number | null
    reason: string
    unpaid: number | null
  }>({
    price: null,
    reason: '',
    unpaid: null,
  })
  useEffect(() => {
    set_openCancelWork(false)
  }, [currentTab])
  if (!details) return <LoadingPage />

  if (isError) return <Navigate to={`/404?from=${window.location.pathname}`} />
  return (
    <>
      {openModal && (
        <Modal
          open={openModal}
          onClose={() => {
            set_openModal(false)
            dispatch(setRefresh(dayjs().valueOf()))
            navigate(`/work/${workRoute}`)
          }}
        >
          <MiniPopup
            _title={'수금 완료'}
            _buttonHandler={
              <>
                <PrimaryButton
                  onClick={() => {
                    set_openModal(false)
                    set_unpaidControl(false)
                    dispatch(setRefresh(dayjs().valueOf()))
                    navigate(`/work/${workRoute}`)
                  }}
                >
                  확인
                </PrimaryButton>
              </>
            }
          >
            미수금 금액을 모두 수금했습니다.
          </MiniPopup>
        </Modal>
      )}
      {openExtraFees && (
        <ExtraFeesInService
          _list={openExtraFees}
          _orderId={inputs.orderId}
          _status={inputs.serviceState}
          _isUnpaid={_type === 'unpaid'}
          _onClose={() => set_openExtraFees(null)}
          _emitValue={handleSubmitExtraFee}
        />
      )}
      {openCancelWork && inputs.paymentInfo && (
        <CancelWork
          _orderId={inputs.orderId}
          _selected={selected}
          _paymentInfo={inputs.paymentInfo}
          _onClose={() => {
            set_openCancelWork(false)
          }}
          _emitValue={(value) => {
            set_postData(value)
            set_openCancelWork(false)
          }}
        />
      )}
      <CancelServiceModal
        _type={cancelPaymentState ? 'payment' : 'service'}
        _open={selected.deleteIds.length > 0}
        _onClose={() =>
          handleSelected({ ...selected, deleteIds: [], rejectIds: [] })
        }
        _onSubmit={handleDeleteOrCancelOrder}
      />
      {openSettlement.open && (
        <ModifySettlementRatio
          _emitValue={(value) => {
            handleInput('settlementRatios', value)
          }}
          _onClose={() => {
            set_openSettlement({ open: false })
          }}
          _items={inputs.settlementRatios}
        />
      )}
      <DetailsDefault
        key={_id}
        _title={'주문 내역'}
        _topLeftControls={
          <L.ButtonRow>
            {/* status change */}
            {!(
              inputs.serviceState === '시공완료' ||
              inputs.serviceState === '시공취소'
            ) && (
              <GrayScaleOutline
                _mini
                _css={css`
                  padding: 5px 12px;
                `}
                onClick={() => {
                  handleChangeOrderStatus()
                }}
              >
                다음 상태로 변경
              </GrayScaleOutline>
            )}
            {/* Unpaid일떄 */}
            <GrayScaleOutline
              _mini
              _css={css`
                padding: 5px 12px;
              `}
              disabled={cancelPaymentState}
              onClick={() => handleCancelState('payment')}
            >
              결제 취소
            </GrayScaleOutline>
            <GrayScaleFill
              _mini
              _css={css`
                padding: 5px 12px;
              `}
              disabled={cancelServiceState}
              onClick={() => handleCancelState('service')}
            >
              시공 취소
            </GrayScaleFill>
            <ToList _to={_type === 'unpaid' ? `/unpaid` : `/work/${_type}`} />
            <GrayScaleText
              _mini
              _css={css`
                padding: 5px 12px;
              `}
            >
              출력
            </GrayScaleText>
          </L.ButtonRow>
        }
        _leftTab={
          <RowTab _tabs={tabs} _tab={currentTab} _emitValue={handleTab} />
        }
        _leftContents={
          <ContentBoxWrap>
            {currentTab === '주문 정보' && (
              <>
                <OrderInfoTab
                  _type={_type}
                  _id={_id}
                  _cancelPaymentState={cancelPaymentState}
                  _cancelServiceState={cancelServiceState}
                  _orderItem={inputs.orderItems}
                  _selected={selected}
                  _emitValue={(value) => {
                    handleSelected(value)
                    set_openCancelWork(false)
                  }}
                  _handleOrderItems={handleOrderItems}
                  {...serviceDetailsData}
                />
                {cancelPaymentState && (
                  <Flex
                    _css={css`
                      padding: 7px 0;
                    `}
                  >
                    <GrayScaleOutline
                      width={'100%'}
                      padding={`5px`}
                      onClick={() => {
                        set_openCancelWork(true)
                      }}
                      disabled={selected.selectIds.length === 0}
                    >
                      환불 금액 수정
                    </GrayScaleOutline>
                  </Flex>
                )}
              </>
            )}
            {currentTab === '숙련공 배정' && (
              <>
                {inputs.orderItems.main.paymentStatus === '결제 대기' ? (
                  <Flex
                    _css={css`
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: ${colors['Grayscale/Gray Light']};
                      ${typography['Body/Large/Bold']}
                    `}
                  >
                    결제 완료 후 숙련공 배정이 가능합니다.
                  </Flex>
                ) : (
                  <>
                    <WorkerAssignmentTab
                      _type={_type}
                      {...serviceDetailsData}
                    />
                  </>
                )}
              </>
            )}
            {currentTab === '결제 정보' && (
              <>
                {inputs.orderItems.main.paymentStatus !== '결제 대기' && (
                  <BoxLayout _title={'추가금 내역'}>
                    <ContentRadiusBox>
                      <BoxRowComponent
                        _label={'추가금'}
                        _contents={
                          <PrimaryOutlineButton
                            _css={css`
                              padding: 5px 0;
                              width: 100%;
                            `}
                            onClick={() => {
                              set_openExtraFees(inputs.taskAdditionalCost)
                            }}
                          >
                            {_type === 'unpaid' ? '조회' : '입력 또는 조회'}
                          </PrimaryOutlineButton>
                        }
                      />
                    </ContentRadiusBox>
                  </BoxLayout>
                )}
                <BoxLayout _title={'결제 정보'}>
                  <PaymentInfo inputs={inputs} />
                </BoxLayout>
                <CanceledPaymentInfo inputs={inputs} />
                {inputs.unpaid && inputs.paymentInfo?.paidCost && (
                  <BoxLayout _title={'수금 정보'}>
                    <FlexColumn gap={12}>
                      {inputs.paymentInfo?.paidCost && (
                        <ContentRadiusBox>
                          <BoxRowComponent _label={'금액'}>
                            {unpaidControl ? (
                              <UnderlineNumberInput
                                placeholder="금액 입력"
                                value={currentUnpaid.price}
                                suffix="원"
                                isAllowed={(values) => {
                                  const { floatValue } = values
                                  if (!inputs.paymentInfo) return true
                                  if (!floatValue) return true
                                  if (!currentUnpaid.unpaid) return true
                                  return (
                                    floatValue >= 0 &&
                                    floatValue <= inputs.paymentInfo.totalPrice
                                  )
                                }}
                                handleValue={(value) => {
                                  set_currentUnpaid((prev) => {
                                    return {
                                      ...prev,
                                      price: value,
                                    }
                                  })
                                }}
                              />
                            ) : (
                              <>
                                {thousand(inputs.paymentInfo.paidCost.price)}원
                              </>
                            )}
                          </BoxRowComponent>
                          <BoxRowComponent
                            _label={'사유 입력'}
                            className={cn({ 'flex-start': unpaidControl })}
                          >
                            {unpaidControl ? (
                              <BoxTextarea
                                value={currentUnpaid.reason}
                                placeholder="입력"
                                onChange={(e) =>
                                  set_currentUnpaid((prev) => ({
                                    ...prev,
                                    reason: e.target.value,
                                  }))
                                }
                              />
                            ) : (
                              <>{inputs.paymentInfo.paidCost.note}</>
                            )}
                          </BoxRowComponent>
                          <BoxRowComponent _label={'수금 금액'}>
                            {unpaidControl ? (
                              <FlexRow gap={16}>
                                <GrayScaleOutline
                                  _css={css`
                                    padding: 5px 0;
                                    flex: 1;
                                  `}
                                  disabled={
                                    prevUnpaid.price === currentUnpaid.price &&
                                    prevUnpaid.reason === currentUnpaid.reason
                                  }
                                  onClick={() => {
                                    handleUnpaidFee()
                                  }}
                                >
                                  확인
                                </GrayScaleOutline>
                                <GrayScaleFill
                                  _css={css`
                                    padding: 5px 0;
                                    width: 97px;
                                  `}
                                  onClick={() => {
                                    set_unpaidControl(false)
                                  }}
                                >
                                  취소
                                </GrayScaleFill>
                              </FlexRow>
                            ) : (
                              <GrayScaleOutline
                                _css={css`
                                  padding: 5px 0;
                                  width: 100%;
                                `}
                                onClick={() => {
                                  set_unpaidControl(true)
                                  if (inputs.paymentInfo?.paidCost) {
                                    set_currentUnpaid({
                                      price:
                                        inputs.paymentInfo?.paidCost?.price ??
                                        null,
                                      reason:
                                        inputs.paymentInfo?.paidCost?.note ??
                                        '',
                                      unpaid:
                                        inputs.paymentInfo?.unpaidCost ?? null,
                                    })
                                    set_prevUnpaid({
                                      price:
                                        inputs.paymentInfo?.paidCost?.price ??
                                        null,
                                      reason:
                                        inputs.paymentInfo?.paidCost?.note ??
                                        '',
                                      unpaid:
                                        inputs.paymentInfo?.unpaidCost ?? null,
                                    })
                                  }
                                }}
                              >
                                수정
                              </GrayScaleOutline>
                            )}
                          </BoxRowComponent>
                        </ContentRadiusBox>
                      )}
                      {inputs.paymentInfo?.unpaidCost && (
                        <ContentRadiusBox>
                          <BoxRowComponent
                            _label={<S.TotalLabel>미수금액</S.TotalLabel>}
                          >
                            <S.TotalPrice>
                              {thousand(inputs.paymentInfo?.unpaidCost)}원
                            </S.TotalPrice>
                          </BoxRowComponent>
                        </ContentRadiusBox>
                      )}
                    </FlexColumn>
                  </BoxLayout>
                )}
              </>
            )}
          </ContentBoxWrap>
        }
        _rightContents={
          <RequestDetails
            _type={_type}
            _serviceRowList={inputs.serviceInfo}
            handleOpenSettlementRatio={handleOpenSettlementRatio}
            {...serviceDetailsData}
          />
        }
        _bottomRightControls={
          <>
            {(cancelPaymentState || cancelServiceState) && (
              <CancelServiceButtons
                _handleSelected={handleSelected}
                _onClose={() =>
                  handleCancelState(cancelPaymentState ? 'payment' : 'service')
                }
                _selected={selected}
              />
            )}
            {/* {_type === 'completed' && !inputs.fixedCommissionRate && (
              <L.RightBottom>
                <PrimaryButton
                  _css={css`
                    flex: 1;
                    height: 48px;
                  `}
                  onClick={() => {
                    handleInput('fixedCommissionRate', true)
                    dispatch(
                      setToast({
                        message: '수수료가 확정되었습니다.',
                        type: 'success',
                      })
                    )
                  }}
                >
                  수수료 확정
                </PrimaryButton>
              </L.RightBottom>
            )} */}
          </>
        }
      />
    </>
  )
}
export default WorkDetails
