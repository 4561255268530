import styled from '@emotion/styled/macro'
import { notFoundMiniTable } from 'themes/Shared.styles'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const EditBuildingStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  MiniTableWrap: styled.div`
    border: 1px solid #dadfe8;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    flex: 1;
    overflow-y: auto;
    text-align: left;
  `,
  MiniTable: styled.table`
    width: 100%;
    position: relative;
    border-collapse: collapse;
    thead {
      position: sticky;
      top: 0px;
      border-radius: 16px 16px 0px 0px;
      background-color: ${colors['Grayscale/Background Dark']};
    }
    th {
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Dark']};
      & > div {
        padding: 14px 12px;
      }
    }
    td {
      ${typography['Body/Small/Regular']}
      color: ${colors['Grayscale/Black light']};
      border-top: 1px solid ${colors['Grayscale/Gray Lighter']};
      height: 44px;
      & > div {
        padding: 12px 12px;
      }
    }
    th,
    td {
      &:first-of-type {
        & > div {
          margin-left: 20px;
        }
      }
      &:last-of-type {
        & > div {
          margin-right: 20px;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background-color: ${colors['Grayscale/Background Light']};
        }
        &:last-of-type {
          border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
        }
      }
    }
    ${notFoundMiniTable}
  `,
}

export default EditBuildingStyle
