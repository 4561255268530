import { BADGE_COLOR } from 'components/Badge/Badge'
import { dropDownValueProps } from 'components/TableComponent/DropdownFilter/DropdownFilter.types'
import { PeriodType } from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'

export type EventType = '포인트 발행' | '포인트 사용'

export type EVENT_FILTER_DROPDOWN_LISTProps = {
  customer: dropDownValueProps<typeof EVENT_FILTER_DROPDOWN_LIST>
}

export const EVENT_FILTER_DROPDOWN_LIST = {
  전체: 'all',
  '회원 지정': 'user-select',
  '코드 발급': 'code-issue',
} as const

export const EVENT_QUERY_DROPDOWN_LIST = {
  제목: 'title',
  작성자명: 'issuer',
} as const

export const EVENT_TYPE_DROPDOWN_LIST = {
  전체: 'ALL',
  진행중: 'process',
  예정: 'expected',
  종료: 'end',
} as const
export type EventKeyType = KeyOf<typeof EVENT_TYPE_DROPDOWN_LIST>
export type EventValueType = ValueOf<typeof EVENT_TYPE_DROPDOWN_LIST>
export const EVENT_BADGE_COLOR = {
  예정: BADGE_COLOR.SuccessLight,
  진행중: BADGE_COLOR.PrimaryLight,
  종료: BADGE_COLOR.Gray,
} as const
export type EventListItem = {
  id: string
  state: EventKeyType
  title: string
  period: PeriodType
  writer: string
  link: string
}
export const isEventType = (
  value: any
): value is typeof EVENT_TYPE_DROPDOWN_LIST[EventKeyType] => {
  return (
    value !== null &&
    Object.values(EVENT_TYPE_DROPDOWN_LIST).some((item) => item === value)
  )
}
export type EventFilterListType = {
  status: typeof EVENT_TYPE_DROPDOWN_LIST[EventKeyType]
}
export const initialEventFilterValues: EventFilterListType = {
  status: 'ALL',
}

// ** API type
export interface APIEventListData {
  id: number
  status: '진행중' | '예정' | '종료'
  title: string
  startAt: string
  endAt: string
  finishAt?: string
  issuer: string
  link: string
}
