import { css } from '@emotion/react'
import { IconsClip } from 'assets'
import Badge from 'components/Badge'
import { BADGE_COLOR } from 'components/Badge/Badge'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import DetailsDefault, { ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DC from 'components/DetailsComponents/DetailsComponents.styles'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { useControlQuillUrls } from 'hooks/useControlQuillUrls'
import useFetch from 'hooks/useFetch'
import LoadingPage from 'layout/LoadingPage'
import { APINoticeDetailData } from 'pages/Support/Notice/NoticeDetails/NoticeDetails.types'
import {
  NOTICE_BADGE_COLOR_SET,
  NOTICE_TYPE_DROPDOWN_LIST,
} from 'pages/Support/Notice/NoticeList/NoticeList.types'
import { getNoticeState } from 'pages/Support/Notice/NoticePublish/NoticePublish'
import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import { ContentBoxWrap, ContentRadiusBox, FlexRow } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import { getKeyByValue } from 'utils/helpers'
import S from './NoticeDetails.styles'

interface NoticeDetailsProps {
  _id: string
}

const NoticeDetails = ({ _id, ...props }: NoticeDetailsProps) => {
  const { Layout: L } = DC
  const [response, isError] = useFetch<APINoticeDetailData>(
    'user',
    `/board/notice/${_id}`,
    {
      disabled: !_id,
    }
  )
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [modalState, set_modalState] = useState<'delete' | null>(null)

  const { handleDeleteAllSharedImages } = useControlQuillUrls(
    response?.content ?? ''
  )
  // api : 이벤트 종료
  const handleDelete = async (id: string) => {
    try {
      await handleDeleteAllSharedImages()
      await callAxios('user').delete(`/board/notice/${id}`)
      set_modalState(null)
      dispatch(setToast({ message: '삭제되었습니다.', type: 'error' }))
      navigate('/customer/notice')
    } catch (e) {
      handleError(e)
    }
  }

  if (!response) return <LoadingPage />
  if (isError) return <Navigate to={`/404?from=${window.location.pathname}`} />
  return (
    <>
      <Modal open={!!modalState} onClose={() => set_modalState(null)}>
        {modalState === 'delete' && (
          <MiniPopup
            _title="공지 삭제"
            _buttonHandler={
              <>
                <GrayScaleFill onClick={() => set_modalState(null)}>
                  아니요
                </GrayScaleFill>
                <PrimaryButton
                  onClick={() => {
                    handleDelete(_id)
                  }}
                >
                  삭제하기
                </PrimaryButton>
              </>
            }
          >
            {`해당 공지를 삭제하시겠습니까?`}
          </MiniPopup>
        )}
      </Modal>
      <DetailsDefault
        _title={'공지사항 상세'}
        _topLeftControls={
          <L.ButtonRow>
            <GrayScaleFill
              padding={'5px 12px'}
              _mini
              onClick={() => {
                set_modalState('delete')
              }}
            >
              삭제
            </GrayScaleFill>
            <ToList _to="/customer/notice" />
          </L.ButtonRow>
        }
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout _title={'설정'}>
              <ContentRadiusBox
                _css={css`
                  gap: 12px;
                  .no-minHeight {
                    min-height: auto;
                  }
                `}
              >
                <BoxRowComponent
                  className="no-minHeight"
                  _label={'알림 제목'}
                  _css={css`
                    align-items: baseline;
                    & > span {
                      text-align: left;
                    }
                  `}
                  _contents={<span>{response?.notiTitle ?? '-'}</span>}
                />
                <BoxRowComponent
                  className="no-minHeight"
                  _label={'알림 내용'}
                  _css={css`
                    align-items: baseline;
                    & > span {
                      text-align: left;
                    }
                  `}
                  _contents={<span>{response?.notiContent ?? '-'}</span>}
                />
                <BoxRowComponent
                  className="no-minHeight"
                  _label={'공지 대상'}
                  _contents={
                    <div>
                      {getKeyByValue(
                        NOTICE_TYPE_DROPDOWN_LIST,
                        response?.targetUserType
                      )}
                    </div>
                  }
                />
                <BoxRowComponent
                  className="no-minHeight"
                  _label={'옵션'}
                  _contents={
                    !response.link && !response.isImportant ? undefined : (
                      <div>
                        {getNoticeState(response.isImportant, !!response.link)}
                      </div>
                    )
                  }
                />
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _topRightHeader={
          <S.RightTopHeaderWrap>
            <FlexRow gap={8}>
              <Badge
                _css={css`
                  width: 52px;
                  height: 24px;
                `}
                {...NOTICE_BADGE_COLOR_SET[
                  response?.targetUserType === 'normal'
                    ? '일반회원'
                    : response?.targetUserType === 'regular'
                    ? '정기회원'
                    : '전체'
                ]}
              >
                {response?.targetUserType === 'normal'
                  ? '일반회원'
                  : response?.targetUserType === 'regular'
                  ? '정기회원'
                  : '전체'}
              </Badge>
            </FlexRow>
            <div className="header-title">
              <FlexRow gap={8}>
                {response?.isImportant && (
                  <Badge
                    _css={css`
                      width: 33px;
                      height: 24px;
                    `}
                    {...BADGE_COLOR['Danger']}
                  >
                    중요
                  </Badge>
                )}
                {response?.title}
              </FlexRow>
            </div>
            <FlexRow gap={8} className="header-info-wrap">
              <div>
                {dayjs(response?.createdAt).format('YYYY-MM-DD(ddd) HH:mm')}
              </div>
              <div className="vertical-bar" />
              <div>{response?.issuer}</div>
            </FlexRow>
          </S.RightTopHeaderWrap>
        }
        _rightContents={
          response?.link ? (
            <FlexRow gap={20} justifyContent="center">
              <IconsClip />
              <S.LinkText href={'https://www.link.com/'} target="_blank">
                {response?.link}
              </S.LinkText>
            </FlexRow>
          ) : (
            <S.RightContentsWrap>
              <div className="ql-container ql-snow">
                <div
                  className="text-box ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: response?.content ?? '',
                  }}
                ></div>
              </div>
            </S.RightContentsWrap>
          )
        }
        _bottomRightControls={
          <S.RightBottom>
            <GrayScaleOutline
              _css={css`
                width: 100%;
                height: 48px;
              `}
              onClick={() => navigate(`/customer/notice/publish/${_id}`)}
            >
              수정
            </GrayScaleOutline>
          </S.RightBottom>
        }
      ></DetailsDefault>
    </>
  )
}
export default NoticeDetails
