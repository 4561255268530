import { FlexColumn, FlexRow, TXT } from 'themes/Shared.styles'

export type nameAndPhoneProps = {
  name: string
  phone: string
}
export const isNameAndPhone = (value: any): value is nameAndPhoneProps => {
  return 'name' in value && 'phone' in value
}
export interface TwoInfoOneCellProps {
  _topData: string
  _bottomData: string
  _icon?: JSX.Element
}
const TwoInfoOneCell = ({
  _bottomData,
  _topData,
  _icon,
  ...props
}: TwoInfoOneCellProps) => {
  return (
    <FlexRow gap={4} className="inbox">
      {_icon}
      <FlexColumn gap={2}>
        <TXT _color="Grayscale/Black light" _textStyle="Body/Small/Regular">
          {_topData}
        </TXT>
        <TXT _textStyle="Body/Small/Regular" _color="Grayscale/Gray Default">
          {_bottomData}
        </TXT>
      </FlexColumn>
    </FlexRow>
  )
}
export default TwoInfoOneCell
