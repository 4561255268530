import { css } from '@emotion/react'
import { GrayScaleOutline, PrimaryButton } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound'
import DraggablePopup from 'components/DraggablePopup'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import TableCell from 'components/TableComponent/TableCell'
import { TableRow } from 'components/TableComponent/TableComponent.styles'
import { useEffect, useState } from 'react'
import { Flex } from 'themes/Shared.styles'
import SearchTechBox from '../SearchTechBox'

import S from './SelectTeamPopup.styles'
import { SelectTeamPopupProps, TechsDefaultType } from './SelectTeamPopup.types'

const SelectTeamPopup = <T extends TechsDefaultType>({
  _css,
  _api,
  _title,
  _q = 'search',
  _currentTechs,
  _emitValue,
  _onClose,
  _tableConfig,
  _responseParser: responseParser,
  ...props
}: SelectTeamPopupProps<T>) => {
  const [term, set_term] = useState<string>('')
  const [selectedTechs, set_selectedTechs] = useState<TechsDefaultType[]>([])
  const toggleSelectedTechs = (value: TechsDefaultType) => {
    if (selectedTechs.some((prev) => prev.id === value.id)) {
      set_selectedTechs((prev) => prev.filter((item) => item.id !== value.id))
    } else {
      set_selectedTechs((prev) => [...prev, value])
    }
  }
  useEffect(() => {
    if (_currentTechs) set_selectedTechs(_currentTechs)
  }, [_currentTechs])
  return (
    <>
      <DraggablePopup
        _title={_title}
        _open={true}
        _css={css`
          min-width: 1154px;
          width: 1154px;
          z-index: 9999;
        `}
        _onClose={_onClose}
        _bottom={
          <>
            <GrayScaleOutline onClick={_onClose}>취소</GrayScaleOutline>
            <PrimaryButton
              disabled={selectedTechs.length < 1}
              onClick={() => {
                _emitValue(selectedTechs)
              }}
            >
              완료
            </PrimaryButton>
          </>
        }
      >
        <S.TableWrap>
          <Flex justifyContent={'space-between'} gap={24}>
            <div></div>
            {/* <S.TechsWrap>
              {selectedTechs.map((tech) => {
                return (
                  <Chips
                    _type="border"
                    key={'techs' + tech.id}
                    onClick={() => {
                      toggleSelectedTechs(tech)
                    }}
                  >
                    {tech.name}
                  </Chips>
                )
              })}
            </S.TechsWrap> */}
            <div>
              <SearchTechBox
                _term={term}
                _emitValue={(value) => set_term(value)}
                _refresh={() => set_term('')}
              />
            </div>
          </Flex>
          <RoundFillBox>
            <TableComponent<T>
              _useVirtuoso={true}
              _css={css`
                padding-bottom: 0px;
                .table-header {
                  background-color: #fff;
                }
                .table-list {
                  min-height: calc(100vh - 526px);
                  & > div {
                    min-height: calc(100vh - 526px);
                  }
                }
              `}
              _type="user"
              _url={`${_api}${term ? `&${_q}=${term}` : ''}`}
              _config={_tableConfig}
              _parser={responseParser}
              _ListContent={({ _row, _index }) => {
                const row = _row
                return (
                  <TableRow
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleSelectedTechs(row)
                    }}
                  >
                    {_tableConfig.map((item, columnIndex) => {
                      const row = _row
                      const _id = item._id as keyof T
                      const _value = row[_id]
                      return (
                        <TableCell
                          key={'body' + _index + columnIndex}
                          _position={item._position}
                          _size={item._size}
                          _css={css`
                            ${_id === 'selected'
                              ? css`
                                  padding: 0px;
                                `
                              : ''}
                          `}
                        >
                          {_id === 'selected' ? (
                            <CheckBoxRound
                              _active={selectedTechs.some(
                                (prev) => prev.id === _value
                              )}
                              onClick={(e) => {
                                e.stopPropagation()
                                toggleSelectedTechs(row)
                              }}
                            />
                          ) : (
                            <div>
                              {typeof _value === 'string' ? _value : ''}
                            </div>
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              }}
            />
          </RoundFillBox>
        </S.TableWrap>
      </DraggablePopup>
    </>
  )
}
export default SelectTeamPopup
