import { MaterialsInformationForRecordsType } from 'pages/Work/Work.types'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
export const EXTRA_FEE_INITIAL_VALUE: MaterialsInformationForRecordsType = {
  id: null,
  name: '',
  count: 1,
}
export interface MaterialFeeProps extends DivAttributes {
  _css?: AdditionalCss
  _open: boolean
  _onClose: () => void
  _emitValue: (value: MaterialsInformationForRecordsType) => void
  _id?: number | null
  _data?: MaterialsInformationForRecordsType
}
