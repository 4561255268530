import SettlementList from 'pages/Settlement/SettlementList'
import { useParams } from 'react-router-dom'
import SettlementForTechsDetails from '../SettlementForTechsDetails/SettlementForTechsDetails'

interface SettlementForTechsQueueProps {}

const SettlementForTechsQueue = (props: SettlementForTechsQueueProps) => {
  const { id } = useParams<'id'>()

  return (
    <>
      <>{!id && <SettlementList _type="queue" _target="techs" />}</>
      <>{id && <SettlementForTechsDetails _type="queue" _id={id} />}</>
    </>
  )
}
export default SettlementForTechsQueue
