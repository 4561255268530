import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import { ApiFunnels } from 'pages/Services/Funnel/Funnel.types'
import { useEffect, useState } from 'react'
import useFetch from './useFetch'

export const useGetFunnels = <T extends object>(
  tableConfigs?: TableCellConfig<T>[]
) => {
  const [apiFunnels] = useFetch<ApiFunnels>(`order`, '/order-funnels')
  const [funnels, set_funnels] = useState<ApiFunnels>()
  const [flattenFunnels, set_flattenFunnels] = useState<string[]>([])
  const [currentConfigs, set_currentConfigs] = useState<TableCellConfig<T>[]>(
    tableConfigs ? tableConfigs : []
  )
  useEffect(() => {
    if (apiFunnels) {
      set_funnels(apiFunnels)
      const flatter = apiFunnels
        .map((mainCategory) =>
          mainCategory.value
            .map((middleCategory) =>
              middleCategory.value.map((subdivision) => subdivision).flat()
            )
            .flat()
        )
        .flat()
      set_flattenFunnels(flatter)
      if (tableConfigs) {
        let rebuildConfigs = [
          ...tableConfigs.map((item) => {
            if (item._id === 'funnel' && item._type === 'dropdown') {
              item._data = {
                전체: '',
                ...flatter.reduce((prev, curr) => {
                  prev[curr] = curr
                  return prev
                }, {} as { [key in string]: string }),
              }
            }
            return item
          }),
        ]
        set_currentConfigs(rebuildConfigs)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiFunnels])
  return {
    funnels,
    flattenFunnels,
    currentConfigs,
  }
}
