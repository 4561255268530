import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import { AdditionalCss } from 'themes/styles.type'

const CheckBoxRoundStyle = {
  Wrap: styled.div<{
    _css?: AdditionalCss
    _mini?: boolean
    _active?: boolean
  }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    padding: 8px 0;
    &.disabled {
      opacity: 0.4;
    }
    ${typography['Body/Large/Regular']}
    ${({ _active }) =>
      _active
        ? css`
            color: ${colors['Grayscale/Gray Dark']};
          `
        : css`
            color: ${colors['Grayscale/Gray Default']};
          `}
    ${({ _mini }) =>
      _mini
        ? css`
            img {
              width: 24px;
              height: 24px;
              min-height: 24px;
            }
          `
        : css`
            img {
              width: 32px;
              height: 32px;
              min-height: 32px;
            }
          `}
    ${addCssProps}
  `,
  ImageBox: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  `,
  ChildrenBox: styled.div`
    flex: 1;
  `,
  Contents: styled.div`
    flex: 1;
    display: flex;
    user-select: none;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    & > img {
      width: 32px;
      height: 32px;
      min-height: 32px;
    }
  `,
  More: styled.div`
    width: 32px;
    min-width: 32px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    user-select: none;
    cursor: pointer;
  `,
}
export default CheckBoxRoundStyle
