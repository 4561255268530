import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import { AdditionalCss } from 'themes/styles.type'

const rotate360 = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(-360deg);
}
`
const BodyCell = styled.div(
  (props: {
    _size: number | 'auto'
    _css?: AdditionalCss
    _position?: 'left' | 'center' | 'right'
  }) => css`
    position: relative;
    padding: 0 12px;
    width: ${typeof props._size === 'number' ? props._size + 'px' : 'auto'};
    min-width: ${typeof props._size === 'number' ? props._size + 'px' : 'auto'};
    max-width: ${typeof props._size === 'number' ? props._size + 'px' : 'auto'};
    ${props._size === 'auto' ? `flex:1 1 auto;` : ''}

    &:first-of-type {
      margin-left: 20px;
    }
    &:last-of-type {
      margin-right: 20px;
    }
    ${props._position
      ? css`
          text-align: ${props._position};
          justify-content: ${props._position === 'center'
            ? 'center'
            : props._position === 'right'
            ? 'flex-end'
            : 'flex-start'};
        `
      : css`
          &:last-of-type {
            text-align: right;
          }
        `}
    ${typography['Body/Small/Regular']}
    color: ${colors['Grayscale/Black light']};
    & > div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &.auto {
      height: 20px;
      & > div {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% - 24px);
      }
    }

    ${props._css ? props._css : ''}
  `
)
export const TableRow = styled.div`
  height: 64px;
  border-top: 1px solid ${colors['Grayscale/Gray Lighter']};
  &:last-child {
    border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  &:hover:not(.disabled, .active) {
    background-color: ${colors['Grayscale/Background Dark']};
  }
  &.active {
    background-color: ${colors['Primary/Default']};
    ${BodyCell} {
      color: #fff;
      .inbox {
        * {
          color: #fff;
        }
      }
    }
  }
  &.disabled {
    ${BodyCell} {
      color: ${colors['Grayscale/Gray Light']};
      .inbox {
        * {
          color: ${colors['Grayscale/Gray Light']};
        }
      }
    }
  }
`
const TableComponentStyle = {
  Wrap: styled.div`
    width: 100%;
    height: auto;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    ${addCssProps}
  `,
  Header: styled.div(
    (props: { _topOffset?: number }) => css`
      position: sticky;
      z-index: 2;
      top: ${props._topOffset ? `${props._topOffset}px` : '0px'};
      background-color: ${colors['Grayscale/Background Light']};
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 12px 12px 0 0;
        border: 1px solid ${colors['Grayscale/Gray Lighter']};
        border-bottom: none;
        height: 48px;
        background-color: ${colors['Grayscale/Background Dark']};
        z-index: 2;
        & > div {
          flex: 1;
          text-align: left;
        }
      }
    `
  ),
  HeadDummy: styled.div`
    contain: '';
    position: relative;
    display: block;
    width: 20px;
    height: 100%;
  `,
  HeadCell: styled.div(
    (props: {
      _size?: number | 'auto'
      _position?: 'left' | 'center' | 'right'
      _isDropdown?: boolean
    }) => css`
      position: relative;
      width: ${typeof props._size === 'number' ? props._size + 'px' : 'auto'};
      min-width: ${typeof props._size === 'number'
        ? props._size + 'px'
        : 'auto'};
      max-width: ${typeof props._size === 'number'
        ? props._size + 'px'
        : 'auto'};
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      ${props._isDropdown
        ? ''
        : css`
            padding: 0 12px;
          `}
      &:first-of-type {
        margin-left: 20px;
      }
      &:last-of-type {
        margin-right: 20px;
      }
      ${props._position
        ? css`
            text-align: ${props._position};
            justify-content: ${props._position === 'center'
              ? 'center'
              : props._position === 'right'
              ? 'flex-end'
              : 'flex-start'};
          `
        : ''}
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Dark']};
    `
  ),

  List: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0px;
    border: 1px solid ${colors['Grayscale/Gray Lighter']};
    margin-bottom: 10px;
    border-top: none;
    min-height: 640px;
    background-color: #fff;
  `,
  EmptyDefault: styled.div`
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${typography['Body/Small/Regular']}
    color: ${colors['Grayscale/Gray Light']};
  `,

  BodyCell: BodyCell,
  Row: TableRow,
  More: styled.button`
    ${typography['Body/Caption/Bold']}
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dadfe8;
    border-radius: 0 0 12px 12px;
    background-color: ${colors['Grayscale/Background Dark']};
    cursor: pointer;
    user-select: none;
    span {
      color: ${colors['Grayscale/Gray Default']};
    }
    img {
      animation: ${rotate360} 1s infinite linear;
    }
    &:hover {
      background-color: ${colors['Grayscale/Gray Lighter']};
    }
    &:disabled {
      cursor: default;
      span {
        opacity: 0.4;
      }
    }
  `,

  OrderBox: styled.div`
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 4px;
    img {
      width: 16px;
      height: 16px;
    }
  `,
}
export default TableComponentStyle
