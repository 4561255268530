import { MutableRefObject, useEffect, useRef, useState } from 'react'
import ReactQuill, { QuillOptions } from 'react-quill'
import callAxios from 'utils/callAxios'
import S from './QuillEditorComponent.styles'
import { QuillEditorComponentProps } from './QuillEditorComponent.types'

export const QUILL_DEFAULT_OPTIONS: QuillOptions = {
  modules: {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'], // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link', 'image', 'video'],
        ['clean'], // remove formatting button
      ],
    },
  },
  placeholder: '내용을 입력하세요.',
  theme: 'snow',
}
const QuillEditorComponent = ({
  _css,
  _quillHTML,
  _emitLength,
  _emitValue,
  _getEditor,
  _options,
  ...props
}: QuillEditorComponentProps) => {
  const quillRef = useRef() as MutableRefObject<ReactQuill | null>
  const fireFirstRef = useRef() as MutableRefObject<boolean>

  const quillImageUpload = async () => {
    const input = document.createElement('input')
    input.onchange = async (e: Event) => {
      if (!quillRef.current) return
      const eventTarget = e.target as HTMLInputElement
      if (!eventTarget || !eventTarget.files) return
      const getFile = eventTarget.files[0]
      const formData = new FormData()
      if (getFile) {
        formData.append('file', getFile)
        const result = await callAxios('user').put<
          DoubleDResponse<{
            image: string
            imageUrl: string
          }>
        >('/board/notice/image', formData)
        if (result.data.data) {
          const fileData = {
            fileName: result.data.data.image,
            url: result.data.data.imageUrl,
          }
          const editor = quillRef.current.getEditor()
          const range = editor.getSelection()!
          editor.insertEmbed(range.index, 'image', fileData.url, 'user')
        }
      }
    }
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
  }

  const [currentOptions, set_currentOptions] = useState<QuillOptions>({
    ...QUILL_DEFAULT_OPTIONS,
    modules: {
      ...QUILL_DEFAULT_OPTIONS.modules,
      toolbar: {
        ...QUILL_DEFAULT_OPTIONS.modules?.toolbar,
        handlers: {
          ...QUILL_DEFAULT_OPTIONS.modules?.toolbar?.handlers,
          image: quillImageUpload,
        },
      },
    },
  })

  useEffect(() => {
    if (!fireFirstRef.current && _options) {
      fireFirstRef.current = true
      set_currentOptions({
        ..._options,
        modules: {
          ..._options.modules,
          toolbar: {
            ..._options.modules?.toolbar,
            handlers: {
              ..._options.modules?.toolbar?.handlers,
              image: quillImageUpload,
            },
          },
        },
      })
    }
  }, [_options])
  useEffect(() => {
    if (quillRef.current && _getEditor) {
      _getEditor(quillRef.current)
    }
    if (quillRef.current && _emitLength) {
      _emitLength(quillRef.current.getEditor().getLength())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quillRef])
  return (
    <>
      <S.Wrap className="super-ql" _css={_css} {...props}>
        <ReactQuill
          ref={(el) => {
            quillRef.current = el
          }}
          theme={currentOptions.theme}
          placeholder={currentOptions.placeholder}
          value={_quillHTML}
          onChange={_emitValue}
          modules={currentOptions.modules}
          readOnly={currentOptions.readOnly}
        />
      </S.Wrap>
    </>
  )
}
export default QuillEditorComponent
