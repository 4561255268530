import { useParams } from 'react-router-dom'
import TechsDetails from './TechsDetails'
import TechsList from './TechsList'

const Techs = () => {
  const { id } = useParams<'id'>()
  return (
    <>
      {id && <TechsDetails _id={id} />}
      {!id && <TechsList />}
    </>
  )
}
export default Techs
