import { useWorkPathMatch } from 'hooks/useAllMatch'
import LoadingPage from 'layout/LoadingPage'
import { authorizedPages } from 'pages/AuthorizedPages'
import { useParams } from 'react-router-dom'
import WorkDetails from './components/WorkDetails/WorkDetails'

interface WorkDetailsPageProps {}
const WorkDetailsPage = ({ ...props }: WorkDetailsPageProps) => {
  const workStatus = useWorkPathMatch(...authorizedPages)
  const { id } = useParams<'id'>()
  if (!workStatus || !id) return <LoadingPage />
  return (
    <>
      <WorkDetails _type={workStatus} _id={id} />
    </>
  )
}
export default WorkDetailsPage
