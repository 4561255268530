import { BADGE_COLOR } from 'components/Badge/Badge'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'

// 소속 dropdown
export const TECHS_TYPES_DROPDOWN_LIST = {
  전체: '',
  수수다: '수수다',
  프리랜서: '프리랜서',
} as const
export type TechsTypesDropdownKeyType = keyof typeof TECHS_TYPES_DROPDOWN_LIST
export const isTechsType = (
  value: any
): value is typeof TECHS_TYPES_DROPDOWN_LIST[TechsTypesDropdownKeyType] => {
  return (
    value !== null &&
    Object.values(TECHS_TYPES_DROPDOWN_LIST).some((item) => item === value)
  )
}
// 소속 뱃지
export const TECHS_TYPES_BADGE_COLOR_SET = {
  수수다: BADGE_COLOR.PrimaryLight,
  프리랜서: BADGE_COLOR.SuccessLight,
  비활성화: BADGE_COLOR.Gray,
} as const
export type TechsBadgeColorKeyType = keyof typeof TECHS_TYPES_BADGE_COLOR_SET
export const isTechBadgeType = (
  value: any
): value is TechsBadgeColorKeyType => {
  return Object.keys(TECHS_TYPES_BADGE_COLOR_SET).some((item) => item === value)
}
// 실측 여부 dropdown
export const HAS_MEASUREMENT_DROPDOWN_LIST = {
  전체: '',
  가능: '가능',
  불가: '불가',
} as const
// query dropdown
export const TECHS_QUERY_DROPDOWN_LIST = {
  전화번호: 'phone',
  숙련공명: 'name',
  '파견 가능 지역': 'zone',
} as const

export interface TechsListProps extends DivAttributes {
  _css?: AdditionalCss
}

// ** API type
export interface APITechListData {
  id: string
  userTechnicianId: string
  name: string
  phone: string
  measuring: boolean
  createdAt: string
  progressingTaskCount: number
  lastWorkDate: string | null
  dispatchableZone: { name: string }[]
  isFreelancer: boolean
  disabledAt: string | null
}
