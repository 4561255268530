import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const ServiceDropdownStyle = {
  Wrap: styled.div`
    width: 100%;
    height: 100%;
    padding: 6px 4px;
    &.active,
    &.selected {
      background-color: ${colors['Primary/Default']};
    }
    &.disabled {
      background-color: ${colors['Grayscale/Gray Lighter']};
    }
    ${addCssProps}
  `,
  Label: styled.div`
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    width: 100%;
    height: 100%;
    gap: 4px;
    .hide {
      display: none;
    }
    & > label {
      cursor: pointer;
      ${typography['Body/Small/Regular']}
      color: ${colors['Grayscale/Black light']};
      white-space: nowrap;
    }
    img {
      width: 16px;
      height: 16px;
    }
    &.placeholder {
      color: ${colors['Grayscale/Gray Light']};
    }
    &.active,
    &.selected {
      & > label {
        color: #fff;
      }
    }
  `,

  Item: styled.div`
    text-align: center;
    cursor: pointer;
    user-select: none;
    padding: 10px 20px;
    ${typography['Body/Small/Bold']}
    color: ${colors['Grayscale/Gray Default']};
    &[data-active='active'] {
      background-color: ${colors['Primary/Default']};
      color: ${colors['White/White off']};
    }
    &:hover,
    &:focus {
      background-color: ${colors['Grayscale/Background Dark']};
      color: ${colors['Grayscale/Black light']};
      outline: none;
    }
  `,
  List: styled.div`
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 9;
    padding: 8px 0;
    box-shadow: 0px 10px 40px rgba(99, 99, 99, 0.3);
    border-radius: 0 0 12px 12px;
    min-width: 113px;
    & > div {
      max-height: 252px;
      width: 100%;
      overflow-y: auto;
    }
  `,
}
const TableDropdownStyle = {
  Wrap: styled.div`
    width: 100%;
    height: 100%;
    ${addCssProps}
  `,
  Label: styled.div`
    padding: 0 12px;
    display: flex;
    align-items: center;

    ${(props: { _position?: 'left' | 'center' | 'right' }) =>
      props._position
        ? css`
            justify-content: ${props._position === 'center'
              ? 'center'
              : props._position === 'right'
              ? 'flex-end'
              : 'flex-start'};
          `
        : ''}
    cursor: pointer;
    user-select: none;
    width: 100%;
    height: 100%;
    gap: 4px;
    .hide {
      display: none;
    }
    & > label {
      cursor: pointer;
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Dark']};
      white-space: nowrap;
    }
    img {
      width: 16px;
      height: 16px;
    }
    &.placeholder {
      color: ${colors['Grayscale/Gray Dark']};
    }
    &.active,
    &.selected {
      background-color: ${colors['Primary/Default']};
      & > label {
        color: #fff;
      }
    }
  `,

  Item: styled.div`
    text-align: center;
    cursor: pointer;
    user-select: none;
    padding: 10px 20px;
    ${typography['Body/Small/Bold']}
    color: ${colors['Grayscale/Gray Default']};
    &[data-active='active'] {
      background-color: ${colors['Primary/Default']};
      color: ${colors['White/White off']};
    }
    &:hover,
    &:focus {
      background-color: ${colors['Grayscale/Background Dark']};
      color: ${colors['Grayscale/Black light']};
      outline: none;
    }
  `,
  List: styled.div`
    position: absolute;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 9;
    padding: 8px 0;
    box-shadow: 0px 10px 40px rgba(99, 99, 99, 0.3);
    border-radius: 0 0 12px 12px;
    min-width: 113px;
    & > div {
      max-height: 252px;
      width: 100%;
      overflow-y: auto;
    }
  `,
}
const CellDropdownStyle = {
  TableDropdownStyle,
  ServiceDropdownStyle,
}
export default CellDropdownStyle
