import { css } from '@emotion/react'
import { PrimaryButton } from 'components/Buttons'
import DatePicker from 'components/DatePicker'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DraggablePopup from 'components/DraggablePopup'
import UnderlineInput from 'components/UnderlineInput'
import { Dayjs } from 'dayjs'
import { useState } from 'react'
import { ContentRadiusBox, ContentRow, Flex } from 'themes/Shared.styles'
import S from './AddHoliday.styles'
import { AddHolidayProps } from './AddHoliday.types'

const AddHoliday = ({
  _css,
  _onClose,
  _emitValue,
  ...props
}: AddHolidayProps) => {
  const [currentDate, set_currentDate] = useState<Dayjs | null>(null)
  const [currentReason, set_currentReason] = useState<string>('')
  return (
    <>
      <DraggablePopup
        className="draggable"
        _title={'휴무일 추가'}
        _open={true}
        _onClose={_onClose}
        _css={css`
          .drag-modal-contents.no-bottom {
            overflow-y: initial;
          }
        `}
      >
        <S.Wrap _css={_css} {...props}>
          <ContentRadiusBox gap={12}>
            <BoxRowComponent _label={'날짜 *'}>
              <DatePicker
                _value={currentDate}
                _emitValue={(value) => set_currentDate(value)}
                placeholder="날짜 입력"
              />
            </BoxRowComponent>
            <BoxRowComponent _label={'사유'}>
              <UnderlineInput
                value={currentReason}
                handleValue={(value) => set_currentReason(value)}
                placeholder="사유 입력"
              />
            </BoxRowComponent>
            <ContentRow
              _css={css`
                margin-top: 4px;
              `}
            >
              <div></div>
              <Flex justifyContent={'flex-end'}>
                <PrimaryButton
                  padding={'8px 46px'}
                  disabled={!currentDate}
                  onClick={() => {
                    if (!currentDate) return
                    _emitValue(currentDate, currentReason)
                  }}
                >
                  추가
                </PrimaryButton>
              </Flex>
            </ContentRow>
          </ContentRadiusBox>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default AddHoliday
