import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

export const OrderGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
const OrderGroup = styled.div`
  box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${colors['White/White off']};
  transition: 0.3s;
  &.not-found {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 34px 16px;
    ${typography['Body/Caption/Bold']}
    color: ${colors['Grayscale/Gray Default']};
    & > div {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 6px;
      img {
        width: 40px;
        height: 40px;
        margin: 0 auto;
      }
    }
  }
  ${addCssProps}
`
const OrderItemStyle = {
  OrderGroup,
  RightWrap: styled.div`
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    color: ${colors['Grayscale/Gray Default']};
    overflow: hidden;
    font-size: 0px;
    div {
      cursor: pointer;
    }
  `,
  Wrap: styled.div`
    overflow: hidden;
    &.checkAble {
      gap: 16px;
      user-select: none;
      &:not(.cancel) {
      }
    }
    &.cancel,
    &.rejected {
      & *:not(.badge > div) {
        color: ${colors['Grayscale/Gray Light']};
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div:first-of-type {
      flex: 1;
    }
    .visible {
      ${typography['Body/Small/Bold']}
      width: 32px;
    }
    .hide {
      width: 0px;
    }
    &.selected {
      ${OrderGroup} {
        background-color: ${colors['Primary/Lighter']};
        box-shadow: inset 0px 0px 0px 2px ${colors['Primary/Default']};
      }
    }
    &.clickAble:not(.checkAble) {
      ${OrderGroup} {
        cursor: pointer;
      }
    }
    &.disabled {
      ${OrderGroup} {
        & *:not(.badge) {
          color: ${colors['Grayscale/Gray Light']};
        }
        box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
        background-color: ${colors['Grayscale/Background Dark']};
      }
    }
    ${addCssProps}
  `,

  Description: styled.div`
    gap: 2px;
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
  Top: styled.div`
    height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    .order-date {
      flex: 1;
      ${typography['Body/Caption/Regular']}
      color: ${colors['Grayscale/Gray Dark']};
      display: flex;
      align-items: center;
      gap: 4px;
      .bold {
        ${typography['Body/Caption/Bold']}
      }
    }
  `,
  LeftSide: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  Thumbnail: styled.div`
    width: 68px;
    height: 68px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${({ url }: { url: string }) => `url(${url})`};
  `,
  Details: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  `,
  Discount: styled.div`
    ${typography['Body/Caption/Bold']}
    color: ${colors['Danger/Dark']};
    display: flex;
    align-items: center;
    gap: 4px;
  `,
  Title: styled.div`
    ${typography['Title/Bold']}
    color: ${colors['Grayscale/Black light']};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  Price: styled.div`
    ${typography['Body/Caption/Bold']}
    color: ${colors['Grayscale/Gray Light']};
  `,
  Count: styled.div`
    ${typography['Body/Caption/Bold']}
    color: ${colors['Grayscale/Gray Default']};
  `,
  Sub: styled.div`
    ${typography['Title/Bold']}
    color: ${colors['Grayscale/Black']};
    display: flex;
    align-items: center;
    gap: 4px;
  `,
}

export default OrderItemStyle
