import { getHouseSize, objectKeys } from 'utils/helpers'
import { ExtraFeeValuesType } from '../Work.types'
import {
  EstimatesDetailsValuesType,
  MaterialPriceType,
} from './AddEstimates/AddEstimates.types'

export const parserForEstimatesToPostData = (
  inputs: EstimatesDetailsValuesType,
  otherData: {
    optionPriceValues: {
      [x: string]: number | null
    }
    selectedMaterial: MaterialPriceType | null
    extraFees: ExtraFeeValuesType[]
    serviceQuantity: number | null
  }
) => {
  const { optionPriceValues, selectedMaterial, extraFees, serviceQuantity } =
    otherData
  return {
    ordererInfo: {
      userId: inputs.userId ? inputs.userId : undefined,
      name: inputs.name,
      phone: inputs.phone,
      userType: inputs.customerType,
    },
    quantity: serviceQuantity ? serviceQuantity : 1,
    addressInfo: {
      bcode: inputs.bcode,
      fullLegalAddress: inputs.address,
      landLotAddress: inputs.addressOld,
      details: inputs.addressDetails,
      type: inputs.residentType,
      exclusiveArea: inputs.buildingSize,
      susudaArea: getHouseSize(inputs.buildingSize, '평').susudaSize,
      thirdFloor: inputs.floors === 2,
      isExistElevator: inputs.elevator === 1,
    },
    orderFunnel: inputs.funnels.join(','),
    serviceRequests: inputs.serviceItem.serviceRequests
      ? inputs.serviceItem.serviceRequests
      : undefined,
    serviceRequestsImage: inputs.requestImages.map((item) => item.name),
    etcRequests: inputs.requests ? inputs.requests : undefined,
    // 요청  이미지 업로드 프로퍼티 추가
    serviceProductId: inputs.serviceItem.serviceCategoryId
      ? inputs.serviceItem.serviceCategoryId
      : undefined,
    priceTagId: inputs.serviceItem.priceTagId
      ? inputs.serviceItem.priceTagId
      : undefined,
    reservationInfo: {
      desiredAt: inputs.desiredDate ? inputs.desiredDate : undefined,
      scheduledAt: inputs.scheduledDate ? inputs.scheduledDate : undefined,
      parking: inputs.freeParking ? inputs.freeParking : undefined,
      parkingInfo: inputs.parkingMethod ? inputs.parkingMethod : undefined,
      isLive: inputs.liveIn === 1,
      frontPwd: inputs.hasPassword === 2 ? inputs.frontPwd : undefined,
      doorPwd: inputs.hasPassword === 2 ? inputs.doorPwd : undefined,
      communication: inputs.communicationMethod
        ? inputs.communicationMethod
        : undefined,
    },
    paymentInfo: {
      paidAt: inputs.orderDate ? inputs.orderDate : undefined,
      name: inputs.buyerName ? inputs.buyerName : undefined,
      dispatchPrice: inputs.dispatchFee ? inputs.dispatchFee : undefined,
      taskPrice: inputs.serviceFee ? inputs.serviceFee : undefined,
      serviceMaterialPrice: inputs.serviceMaterialPrice
        ? inputs.serviceMaterialPrice
        : undefined,
      liftingPrice: inputs.liftingPrice ? inputs.liftingPrice : undefined,
      paidCost: inputs.prePaidFee ? inputs.prePaidFee : undefined,
      paidCostReason: inputs.prePaidReason ? inputs.prePaidReason : undefined,
      communication: inputs.communicationMethod
        ? inputs.communicationMethod
        : undefined,
      additionalCost: extraFees.map((item) => ({
        taskName: item.taskName,
        taskPrice: item.taskPrice ?? 0,
        price: item.price,
        note: item.note,
        // messageToUser: item.requests, 신규작성은 유저 특정 x
      })),
    },
    serviceOption: objectKeys(optionPriceValues)
      .map((keyName) =>
        optionPriceValues[keyName] !== 0
          ? {
              name: keyName,
              price: optionPriceValues[keyName],
            }
          : null
      )
      .filter((ii) => ii !== null),
    ...(selectedMaterial && {
      material: {
        id: selectedMaterial.id,
        name: selectedMaterial.name,
        unitPrice: selectedMaterial.price,
        quantity: selectedMaterial.count ?? 1,
      },
    }),
  }
}
