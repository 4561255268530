import { css } from '@emotion/react'
import { signUpURL } from 'API_URL'
import { PrimaryButton } from 'components/Buttons'
import UnderlineInput from 'components/UnderlineInput'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useMatchInput from 'hooks/useMatchInput'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { setAuth, TokenType } from 'store/authSlice'
import { cleanSign, selectSign } from 'store/signSlice'
import callAxios, { extractData, handleError } from 'utils/callAxios'
import {
  decryptString,
  passwordInputRegex,
  passwordRegex,
  usernameRegex,
} from '../Authorization.helpers'
import SA from '../Authorization.styles'
import PasswordCheck from '../LoginComponent/PasswordCheck'
import LogoPart from '../LogoPart'
import AgreementsComponent from './AgreementsComponent'
import S from './JoinComponent.styles'
import { JoinComponentProps, JoinValue } from './JoinComponent.types'

const initialValue: JoinValue = {
  username: '',
  password: '',
  optionAgreements: null,
  imp_uid: '',
}
// test url
// http://localhost:3000/?step=join&imp_uid=U2FsdGVkX19v7%2FF%2FeQ%2FPv7Dc1xTqJWbg98a8aUvFNYBrU1BwyNGGIoh%2BhBRyayIi
const JoinComponent = ({ _css, ...props }: JoinComponentProps) => {
  const { imp_uid, optionAgreements } = useAppSelector(selectSign)
  const dispatch = useAppDispatch()
  const joinInputs = useMatchInput<JoinValue>(initialValue)
  const { inputs, handleInput } = joinInputs
  const trueWhenEmpty = useEmptyCheck(inputs, 'optionAgreements')
  const usernameRef = useRef() as MutableRefObject<HTMLDivElement>
  const passwordRef = useRef() as MutableRefObject<HTMLDivElement>
  const [usernameError, set_usernameError] = useState<string>('')
  const [passwordError, set_passwordError] = useState<string>('')
  const [passwordType, set_passwordType] = useState<'password' | 'text'>(
    'password'
  )
  const canNotSubmit = useMemo(() => {
    return (
      trueWhenEmpty ||
      !usernameRegex.test(inputs.username) ||
      !passwordRegex.test(inputs.password)
    )
  }, [inputs.password, inputs.username, trueWhenEmpty])
  const togglePasswordType = () => {
    set_passwordType((type) => (type === 'password' ? 'text' : 'password'))
  }
  const handleSubmit = async () => {
    // TODO :: change for production
    try {
      const axiosRes = await callAxios('dev').post(signUpURL, inputs, {
        headers: {
          skipToken: true,
        },
      })
      const res = extractData<TokenType>(axiosRes)
      if (canNotSubmit) return
      dispatch(
        setAuth({ status: 'authorized', token: res, isSuperAdmin: false })
      )
      dispatch(cleanSign())
    } catch (error) {
      handleError(error)
    }
  }

  useEffect(() => {
    const disposeUid = (_e: string) => {
      const decrypted = decryptString(_e)
      if (decrypted) {
        handleInput('imp_uid', decrypted)
      } else {
        window.location.href = '/'
      }
    }
    if (imp_uid) {
      disposeUid(imp_uid)
    } else {
      window.location.href = '/'
    }
  }, [handleInput, imp_uid])

  useEffect(() => {
    handleInput('optionAgreements', optionAgreements)
  }, [handleInput, optionAgreements])
  return (
    <>
      {!optionAgreements && <AgreementsComponent {...joinInputs} />}
      {optionAgreements && (
        <S.Wrap _css={_css} {...props}>
          <LogoPart />
          <SA.Center>
            <SA.Header>회원가입</SA.Header>
            <S.Contents>
              <UnderlineInput
                ref={usernameRef}
                label="아이디"
                value={inputs['username']}
                handleValue={(value) => {
                  if (value) {
                    if (value.length >= 6) {
                      if (usernameRegex.test(value)) {
                        set_usernameError('')
                      } else {
                        set_usernameError('형식에 맞게 다시 입력해 주세요.')
                      }
                    } else {
                      set_usernameError('6자리 이상 입력해주세요.')
                    }
                  } else {
                    set_usernameError('')
                  }
                  handleInput('username', value)
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    passwordRef.current?.querySelector('input')?.focus()
                  }
                }}
                maxLength={20}
                error={usernameError}
                guide={'6~20자의 영문 소문자, 숫자'}
              />
              <UnderlineInput
                _css={css`
                  margin-top: 24px;
                `}
                ref={passwordRef}
                type={passwordType}
                label="비밀번호"
                value={inputs['password']}
                handleValue={(value) => {
                  if (value) {
                    if (!passwordInputRegex.test(value)) return
                    if (value.length >= 8) {
                      if (passwordRegex.test(value)) {
                        set_passwordError('')
                      } else {
                        set_passwordError('조합된 비밀번호를 입력해 주세요.')
                      }
                    } else {
                      set_passwordError('8자리 이상 입력해주세요.')
                    }
                  } else {
                    set_passwordError('')
                  }
                  handleInput('password', value)
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    handleSubmit()
                  }
                }}
                error={passwordError}
                guide={'8자 이상, 영문 + 숫자 + 특수문자 조합'}
                _el={
                  <PasswordCheck
                    _active={passwordType === 'text'}
                    _css={css`
                      margin-top: 8px;
                      justify-content: flex-end;
                      position: absolute;
                      bottom: -6px;
                      right: 0;
                    `}
                    onClick={togglePasswordType}
                  />
                }
              />
            </S.Contents>
            <S.Bottom>
              <PrimaryButton
                _css={css`
                  width: 100%;
                  padding: 10px 20px;
                `}
                disabled={canNotSubmit}
                onClick={handleSubmit}
              >
                가입완료
              </PrimaryButton>
            </S.Bottom>
          </SA.Center>
          <SA.Dim />
        </S.Wrap>
      )}
    </>
  )
}
export default JoinComponent
