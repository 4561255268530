import { ServiceBadgeStatusType, ServiceStates } from 'components/Badge/Badge'
import dayjs from 'dayjs'
import { WorkStatusParams } from 'hooks/useAllMatch'
import { GalleryStatesType, initialGalleryData } from 'store/gallerySlice'
import {
  COMMUNICATION_METHODS_DROPDOWN_LIST,
  CUSTOMER_TYPE_DROPDOWN_LIST,
  DoubleDFile,
  ELEVATOR_DROPDOWN_LIST,
  FLOORS_DROPDOWN_LIST,
  FREE_PARKING_DROPDOWN_LIST,
  HAS_PASSWORD_DROPDOWN_LIST,
  LIVE_IN_DROPDOWN_LIST,
  RESIDENT_TYPE_DROPDOWN_LIST,
} from 'types/services.type'
import { DropdownValueOf, UserType } from 'types/share'
import { getFileNameFromUrl, thousand } from 'utils/helpers'
import {
  TaskAdditionalCost,
  TaskAdditionalItem,
} from './components/ExtraFeesInService/ExtraFeesInService.types'
import { initOrderItem } from './components/OrderInfoTab/OrderInfoTab.types'

import { AssignedSchedulesType } from './components/WorkerAssignmentTab/AssignTeamPopup/AssignTeamPopup.types'

export type HandleOrderItemsType = (
  values:
    | OrderDetailsValuesType
    | ((values: OrderDetailsValuesType) => OrderDetailsValuesType)
) => void
export type PaymentStatusType =
  | '결제 대기'
  | '결제 완료'
  | '결제 요청'
  | '추가 결제 대기'
  | '결제 취소 요청'
  | '결제 취소'
  | '취소 불가'

export type OrderSelectIdsType = {
  selectIds: (string | number)[]
  rejectIds: (string | number)[]
  deleteIds: (string | number)[]
}
/**
 * 실측 정보 with 사진
 */
export type MeasurementValuesType = {
  id: number | null
  title: string
  size: string
  사진: DoubleDFile[]
  '360도 사진': DoubleDFile[]
  etc: string
}
export type ConstructionValuesType = {
  id: number | null
  title: string
  사진: DoubleDFile[]
  '360도 사진': DoubleDFile[]
}
/**
 * 추가금 실측, 시공
 */
export type ExtraFeeValuesType = TaskAdditionalCost
/**
 * 추가금 자재
 */
export type MaterialsInformationForRecordsType = {
  id: number | null
  name: string
  count: number
}
export type PaymentInfoType = {
  buyerName: string
  totalPrice: number
  servicePrice: number
  couponDiscount: number
  usePoint: number
  payMethod: 'billing' | 'kakaopay' | 'naverpay' | 'card' | 'vbank' | null
  paidAt: string
  dispatchPrice: number
  taskAdditional: { taskName: string; price: number }[]
  paidCost?: { id: number; price: number; note: string | null }
  unpaidCost?: number
  vbankInfo: {
    holder: string
    vbankName: string
    vbankNum: string
    vbankCode: string
    vbankExpiredAt: string
  } | null
  cardInfo: {
    cardName: string
    cardQuota: number
    cardNumber: string
    applyNum: string
    buyerName: string
  } | null
  cancelAmount: number
  cancelPoint: number
  cancelMoney: number
  cancelledAt: string | null
  cancelCouponDiscount: number
  cancelDispatchPrice: number

  merchantUid: string | null
  applyNum: string | null
  savePoint: number
  cancelSavePoint: number
}
export type PaymentInfoValuesType = {
  paymentInfo: PaymentInfoType | null
}
/**
 * 주문 내 모든 팀 일정 정보
 */
export type AllTeamsInfoValuesType = MeasurementTeamInfoValuesType &
  MaterialTeamInfoValuesType &
  ServiceTeamInfoValuesType
/**
 * 실측팀 일정 정보
 */
export type MeasurementTeamInfoValuesType = {
  measurementInfo: MeasurementValuesType[]
  measurementTeam: AssignedSchedulesType
}
/**
 * 자재팀 일정 정보
 */
export type MaterialTeamInfoValuesType = {
  materialTeam: AssignedSchedulesType
}
/**
 * 시공팀 일정 정보
 */
export type ServiceTeamInfoValuesType = {
  serviceTeam: AssignedSchedulesType
  afterConstruction: ConstructionValuesType[]
  beforeConstruction: ConstructionValuesType[]
}

/**
 * 공통 갤러리
 */
export type GalleryCommonValueType = {
  gallery: GalleryStatesType
}
/**
 * 주문 서비스 카드 아이템
 */
export interface OrderItemType {
  id?: number
  taskId?: string
  name: string
  amount?: number
  orderAt?: string
  status?: ServiceBadgeStatusType
  paidAt?: string | null // 결제일
  serviceAt?: string | null // 시공 시작일
  serviceDoneAt?: string | null // 시공완료일
  cancelledAt?: string | null // 취소일
  noCancellation?: boolean
  discount?: {
    type: string
    amount: number
  }
  cancelRequest?: boolean
  paymentStatus?: PaymentStatusType
}
/**
 * 함께 하는 서비스
 */
export type OtherServicesType = {
  id: string
  paidAt: string
  items: OrderItemType[]
  selected?: boolean
}
/**
 * 주문 서비스 항목들
 */
export type OrderDetailsValuesType = {
  main: OrderItemType
  additional?: OrderItemType[]
  loadService?: OtherServicesType[]
}
/**
 * 주문 서비스
 */
export type OrderItemsValueType = {
  orderItems: OrderDetailsValuesType
}
/**
 * 주문 정보
 */
export type OrderInfoValuesType = {
  orderDate: string
  payMethod: string
  orderNumber: string
}
/**
 * 회원 정보
 */
export type CustomerInfoValuesType = {
  name: string
  phone: string
  customerType: DropdownValueOf<typeof CUSTOMER_TYPE_DROPDOWN_LIST> | null
  funnels: string[]
}
/**
 * 주소지 정보
 */
export type AddressInfoValuesType = {
  bcode: number | null
  address: string
  addressOld: string
  addressDetails: string
  residentType: DropdownValueOf<typeof RESIDENT_TYPE_DROPDOWN_LIST> | null
  buildingSize: number | null
  floors: DropdownValueOf<typeof FLOORS_DROPDOWN_LIST> | null
  elevator: DropdownValueOf<typeof ELEVATOR_DROPDOWN_LIST> | null
}
/**
 * 방문시 참고 사항
 */
export type EnteringInfoValuesType = {
  desiredDate: string
  scheduledDate: string
  freeParking: DropdownValueOf<typeof FREE_PARKING_DROPDOWN_LIST> | null
  parkingMethod: string
  liveIn: DropdownValueOf<typeof LIVE_IN_DROPDOWN_LIST> | null
  hasPassword: DropdownValueOf<typeof HAS_PASSWORD_DROPDOWN_LIST> | null
  frontPwd: string
  doorPwd: string
  communicationMethod: DropdownValueOf<
    typeof COMMUNICATION_METHODS_DROPDOWN_LIST
  > | null
}

export type ServiceInfoValuesType = {
  // 시공 정보
  serviceInfo: ServiceRowListType
}
export type SettlementRatios = {
  id: number
  name: string
  company: string
  serviceTime: number
  dutyState: boolean
  ratio: number
  amount: number | null
  originalTaskAmount: number
}
/**
 * 정산 내역
 */
export type SettlementInfoValuesType = {
  settlementRatios: SettlementRatios[]
}

/**
 * 주문 상태
 */
export type OrderStateType = WorkStatusParams | 'unpaid'

/**
 * 주문 상세 변수
 */
export type ServiceDetailsValuesType = OrderInfoValuesType &
  CustomerInfoValuesType &
  AddressInfoValuesType &
  EnteringInfoValuesType &
  ServiceInfoValuesType &
  SettlementInfoValuesType &
  OrderItemsValueType &
  GalleryCommonValueType &
  AllTeamsInfoValuesType &
  PaymentInfoValuesType & {
    orderId: string
    // 서비스 상태
    serviceState: ServiceStates | null
    fixedCommissionRate?: boolean
    // 만족도 조사
    survey: string
    // 서비스 추가금
    taskAdditionalCost: TaskAdditionalItem[]
    taskId: string
    materials: MaterialsInformationForRecordsType[]
    hasSettlement: boolean
    // 미수금 이냐
    unpaid?: boolean
    seeTogetherCount: number

    paymentStatus: PaymentStatusType | null
  }

export const SERVICE_DETAILS_INITIAL_VALUE: ServiceDetailsValuesType = {
  paymentStatus: null,
  seeTogetherCount: 0,
  orderId: '',
  gallery: initialGalleryData,
  hasSettlement: false,
  taskId: '',
  taskAdditionalCost: [],
  // 결제 정보
  paymentInfo: null,

  // 주문 정보
  orderDate: '',
  payMethod: '',
  orderNumber: '',

  // 회원 정보
  name: '',
  phone: '',
  customerType: 'REGULAR',
  funnels: [],

  // 주문 서비스
  orderItems: {
    main: initOrderItem,
  },

  // 주소지 정보
  bcode: null,
  address: '',
  addressOld: '',
  addressDetails: '',
  residentType: null,
  buildingSize: null,
  floors: null,
  elevator: null,

  // 시공 정보
  serviceInfo: {
    title: '',
    list: [],
    requests: '',
  },

  // 방문시 참고 사항
  desiredDate: '',
  scheduledDate: '',
  freeParking: null,
  parkingMethod: '',
  liveIn: null,
  hasPassword: null,
  frontPwd: '',
  doorPwd: '',
  communicationMethod: null,

  // 팀 일정
  measurementInfo: [],
  measurementTeam: {
    daySchedules: [],
    remarks: '',
  },
  materialTeam: {
    daySchedules: [],
    remarks: '',
  },
  materials: [],
  serviceTeam: {
    daySchedules: [],
    remarks: '',
  },
  afterConstruction: [],
  beforeConstruction: [],

  serviceState: null,

  settlementRatios: [],

  survey: '',
}
export type ApiSettlementForTechs = {
  id: number
  technicianName: string
  isFreeLancer: boolean
  workHour: string
  settlementRatio: number
  settlementWages: number
  originalTaskWages: number
  overWorkHour?: boolean
}
export type ServiceRowListType = {
  title: string
  list: { name: string; value: string }[]
  requests: string
}

export type TogetherServiceItemType = {
  cancelledAt: string | null
  couponDiscount: number
  finishedAt: string | null
  id: string
  paidAt: string | null
  serviceCode: string
  serviceName: string
  servicePrice: number
  status: ServiceBadgeStatusType
  taskAdditionalCost: TaskAdditionalCost[] | null
  unpaid: boolean
}
export type ApiSeeTogetherItemType = {
  cancelledAt: string | null
  cursor: number
  id: string
  paidAt: string | null
  service: TogetherServiceItemType[]
}
export type ApiServiceItemType = {
  id: number
  householdsId: string | null
  taskName: string
  title: string
  size: string
  imageUrl: string[]
  image360Url: string | null
  note: string
  updatedAt: string | null
  createdAt: string
}
export type ApiWorkDetails = {
  id: string
  orderId: string
  paid: boolean
  status: ServiceBadgeStatusType
  orderNo: string
  review: string | null
  seeTogetherCount: number
  addressInfo: {
    fullLegalAddress: string
    landLotAddress: string
    detailAddress: string
    addressType: string
    exclusiveArea: number
    susudaArea: number
    thirdFloor: boolean
    isExistElevator: boolean
    locationName: string
    bcode: string
  }
  service: {
    paymentStatus: PaymentStatusType
    settlement: boolean // 정산 완료 여부
    status: ServiceBadgeStatusType
    serviceName: string
    noCancellation: boolean
    serviceInfo: {
      title: string
      option: string | number
      price: number | null
    }[]
    serviceOption: {
      name: string
      price: number
    }[]
    serviceAt: string | null
    paidAt: string | null
    cancelledAt: string | null
    finishedAt: string | null // 시공 완료일
    couponDiscount: number // 쿠폰 없으면 0
    serviceCode: string
    serviceCategory: {
      name: string
      title: string
    }[]
    servicePrice: number
    taskAdditionalCost: TaskAdditionalCost[] | null
    material: {
      name: string
      unitPrice: number
      quantity: number
    } | null
    unpaid: boolean
  }
  togetherPaid: {
    // 함께 주문한 서비스
    paymentStatus: PaymentStatusType
    unpaid: boolean
    status: ServiceBadgeStatusType
    paidAt: string | null
    serviceAt: string | null
    cancelledAt: string | null
    finishedAt: string | null
    id: string // task id
    serviceName: string
    couponDiscount: number
    servicePrice: number
    noCancellation: boolean
    taskAdditionalCost: TaskAdditionalCost[] | null
  }[]
  seeTogether: {
    // 백에서 저장 안함
    // 시공 같이보기
    id: string
    serviceName: string
    finishedAt: string | null
    couponDiscount: number
    servicePrice: number
  }[]
  orderFunnel: string
  orderInfo: {
    id: null | number
    name: string
    phone: string
    userType: UserType
  }
  reservationInfo: {
    desireAt: string
    parking: string
    parkingInfo: string | null
    householdsType: string
    isLive: boolean
    doorPwd: string
    frontPwd: string
    communication: string
  }
  taskRequirements: {
    // 요청 사진, 사항
    id: number
    serviceRequests: string | null
    serviceRequestsImage: string[]
    serviceRequestsImageUrl: string[]
    etcRequests: string
  }
  taskAdditionalCost: TaskAdditionalCost[] | null
  measurement: ApiServiceItemType[]
  afterConstruction: (Omit<ApiServiceItemType, 'size' | 'note'> & {
    isBefore: boolean
  })[]
  beforeConstruction: (Omit<ApiServiceItemType, 'size' | 'note'> & {
    isBefore: boolean
  })[]
  paymentInfo: PaymentInfoType
  measurementSchedule: {
    note: string | null
    schedules: {
      technician: {
        technicianId: string
        technicianName: string
        technicianPhone: string
      }[]
      taskYMD: number
      taskStartHMS: string // 작업 시작시간
      taskEndHMS: string // 작업 종료시간
      workStartHMS: string
      workEndHMS: string
    }[]
  }
  technicianTaskSchedule: {
    note: string | null
    schedules: {
      technician: {
        technicianId: string
        technicianName: string
        technicianPhone: string
      }[]
      taskYMD: number
      taskStartHMS: string // 작업 시작시간
      taskEndHMS: string // 작업 종료시간
      workStartHMS: string
      workEndHMS: string
    }[]
  }
  adminTaskSchedule: {
    note: string
    schedules: {
      admin: {
        adminId: string
        adminName: string
      }[]
      taskYMD: number
      taskStartHMS: string
      taskEndHMS: string
    }[]
  }
  taskMaterials: { id: number; name: string; price: number; count: number }[] // 자재 내역
}

export const getServiceList = (response: ApiWorkDetails) => {
  const subCategories = response.service.serviceCategory
    .slice(1, response.service.serviceCategory.length)
    .map((item) => ({
      name: item.title,
      value: item.name,
    }))
  const tags = response.service.serviceInfo.map((item) => ({
    name: item.title,
    value: String(item.option),
  }))
  const options = response.service.serviceOption.map((item) => ({
    name: item.name,
    value: thousand(item.price) + '원',
  }))
  // const materials = response.material
  //   ? [
  //       {
  //         name: '자재',
  //         value: `${response.material.name} (${thousand(
  //           response.material.quantity
  //         )})`,
  //       },
  //     ]
  //   : []
  return [
    ...subCategories,
    ...tags,
    ...options,
    // ...materials,
  ]
}

export const getServiceRowList = (
  response: ApiWorkDetails
): ServiceRowListType => {
  return {
    title: response.service.serviceCategory[0].name,
    list: getServiceList(response),
    requests: response.taskRequirements.serviceRequests ?? '',
  }
}
// 추가금 관련
export const parserForServiceDetailsValues = (
  response: ApiWorkDetails,
  _type: OrderStateType,
  prev: ServiceDetailsValuesType
): ServiceDetailsValuesType => {
  const mainAdditionalCost: TaskAdditionalItem = {
    taskId: response.id,
    serviceName: response.service.serviceName,
    price: response.service.servicePrice,
    list: response.service.taskAdditionalCost
      ? response.service.taskAdditionalCost.map((item) => item)
      : [],
  }
  const otherAdditionalCost: TaskAdditionalItem[] = response.togetherPaid.map(
    (data) => {
      return {
        taskId: data.id,
        serviceName: data.serviceName,
        price: data.servicePrice,
        list: data.taskAdditionalCost
          ? data.taskAdditionalCost.map((item) => item)
          : [],
      }
    }
  )
  const taskAdditionalCost = [mainAdditionalCost, ...otherAdditionalCost]
  return {
    ...prev,
    paymentStatus: response.service.paymentStatus,
    seeTogetherCount: response.seeTogetherCount,
    unpaid: response.service.unpaid,
    orderId: response.orderId,
    hasSettlement: response.service.settlement,
    materials: response.taskMaterials.map((item) => {
      return {
        id: item.id,
        name: item.name,
        count: item.count,
      }
    }),
    taskId: response.id,
    taskAdditionalCost,
    paymentInfo: response.paymentInfo,
    orderDate: response.paymentInfo.paidAt
      ? dayjs(response.paymentInfo.paidAt).toISOString()
      : '',
    payMethod: response.paymentInfo.payMethod
      ? response.paymentInfo.payMethod
      : '-',
    orderNumber: response.orderNo,

    // 회원 정보
    name: response.orderInfo.name,
    phone: response.orderInfo.phone,
    customerType: response.orderInfo.userType,
    funnels: response.orderFunnel.includes('>')
      ? response.orderFunnel.split('>')
      : response.orderFunnel.split(','),

    // 주문 서비스
    orderItems: {
      // main: oneProduct,
      main: {
        // id: number
        paymentStatus: response.service.paymentStatus,
        status: response.service.status?.replace(
          /\s/g,
          ''
        ) as ServiceBadgeStatusType,
        taskId: response.id,
        name: response.service.serviceName,
        amount: response.paymentInfo.servicePrice,
        ...(response.service.couponDiscount && {
          discount: {
            type: '쿠폰 적용',
            amount: response.service.couponDiscount,
          },
        }),
        noCancellation: response.service.noCancellation,
        serviceAt: response.service.serviceAt,
        paidAt: response.service.paidAt ?? null,
        serviceDoneAt: response.service.finishedAt ?? null, // 시공완료일
        cancelledAt: response.service.cancelledAt ?? null,
        cancelRequest: response.service.status === '취소요청',
      },
      additional: response.togetherPaid.map((item) => {
        return {
          // id: number
          paymentStatus: item.paymentStatus,
          noCancellation: item.noCancellation,
          status: item.status?.replace(/\s/g, '') as ServiceBadgeStatusType,
          taskId: item.id,
          name: item.serviceName,
          amount: item.servicePrice,
          ...(item.couponDiscount && {
            discount: {
              type: '쿠폰 적용',
              amount: item.couponDiscount,
            },
          }),
          paidAt: item.paidAt ?? null,
          serviceDoneAt: item.finishedAt ?? null, // 시공완료일
          cancelledAt: item.cancelledAt ?? null,
          cancelRequest: item.status === '취소요청',
          serviceAt: item.serviceAt,
        }
      }),
    },

    // 주소지 정보
    address: response.addressInfo.fullLegalAddress,
    addressOld: response.addressInfo.landLotAddress,
    addressDetails: response.addressInfo.detailAddress,
    residentType: response.addressInfo.addressType as DropdownValueOf<
      typeof RESIDENT_TYPE_DROPDOWN_LIST
    >,
    buildingSize: response.addressInfo.exclusiveArea,
    floors: response.addressInfo.thirdFloor ? 2 : 1,
    elevator: response.addressInfo.isExistElevator ? 1 : 2,

    // 시공 정보
    serviceInfo: getServiceRowList(response),

    // 방문시 참고 사항

    scheduledDate: response.reservationInfo.desireAt,
    freeParking: response.reservationInfo.parking as '가능' | '불가능',
    parkingMethod: response.reservationInfo.parkingInfo ?? '',
    liveIn: response.reservationInfo.isLive ? 1 : 2,
    hasPassword: response.reservationInfo.frontPwd ? 2 : 1,
    frontPwd: response.reservationInfo.frontPwd,
    doorPwd: response.reservationInfo.doorPwd,
    communicationMethod: response.reservationInfo
      .communication as DropdownValueOf<
      typeof COMMUNICATION_METHODS_DROPDOWN_LIST
    >,

    serviceState: response.status
      ? (response.status?.replace(/\s/, '') as ServiceStates)
      : null,
    survey: response.review ?? '',
    beforeConstruction: response.beforeConstruction.map((item) => ({
      id: item.id,
      title: item.title,
      사진: item.imageUrl.map((url) => ({
        id: getFileNameFromUrl(url),
        name: getFileNameFromUrl(url),
        url: url,
      })),
      '360도 사진': item.image360Url
        ? [
            {
              id: getFileNameFromUrl(item.image360Url),
              name: getFileNameFromUrl(item.image360Url),
              url: item.image360Url,
            },
          ]
        : [],
    })),
    afterConstruction: response.afterConstruction.map((item) => ({
      id: item.id,
      title: item.title,
      사진: item.imageUrl.map((url) => ({
        id: getFileNameFromUrl(url),
        name: getFileNameFromUrl(url),
        url: url,
      })),
      '360도 사진': item.image360Url
        ? [
            {
              id: getFileNameFromUrl(item.image360Url),
              name: getFileNameFromUrl(item.image360Url),
              url: item.image360Url,
            },
          ]
        : [],
    })),
    measurementInfo: response.measurement.map((item) => ({
      id: item.id,
      title: item.title,
      size: item.size,
      사진: item.imageUrl.map((url) => ({
        id: getFileNameFromUrl(url),
        name: getFileNameFromUrl(url),
        url: url,
      })),
      '360도 사진': item.image360Url
        ? [
            {
              id: getFileNameFromUrl(item.image360Url),
              name: getFileNameFromUrl(item.image360Url),
              url: item.image360Url,
            },
          ]
        : [],
      etc: item.note,
    })),
    measurementTeam: {
      daySchedules: response.measurementSchedule
        ? response.measurementSchedule.schedules.map((item) => {
            return {
              currentDay: dayjs(String(item.taskYMD), 'YYYYMMDD').format(
                'YYYY-MM-DD'
              ),
              times: {
                start: dayjs(item.taskStartHMS).format('HH:mm'),
                end: dayjs(item.taskEndHMS).format('HH:mm'),
              },
              techs: item.technician.map((item) => ({
                id: item.technicianId,
                name: item.technicianName,
              })),
            }
          })
        : [],
      remarks: response.measurementSchedule?.note ?? '',
    },
    serviceTeam: {
      daySchedules: response.technicianTaskSchedule
        ? response.technicianTaskSchedule.schedules.map((item) => {
            return {
              currentDay: dayjs(String(item.taskYMD), 'YYYYMMDD').format(
                'YYYY-MM-DD'
              ),
              times: {
                start: dayjs(item.taskStartHMS).format('HH:mm'),
                end: dayjs(item.taskEndHMS).format('HH:mm'),
              },
              techs: item.technician.map((item) => ({
                id: item.technicianId,
                name: item.technicianName,
              })),
            }
          })
        : [],
      remarks: response.technicianTaskSchedule?.note ?? '',
    },
    materialTeam: {
      daySchedules: response.adminTaskSchedule
        ? response.adminTaskSchedule.schedules.map((item) => {
            return {
              currentDay: dayjs(String(item.taskYMD), 'YYYYMMDD').format(
                'YYYY-MM-DD'
              ),
              times: {
                start: dayjs(item.taskStartHMS).format('HH:mm'),
                end: dayjs(item.taskEndHMS).format('HH:mm'),
              },
              techs: item.admin.map((item) => ({
                id: item.adminId,
                name: item.adminName,
              })),
            }
          })
        : [],
      remarks: response.adminTaskSchedule?.note ?? '',
    },
  }
}

export type ApiServiceListItem = {
  id: string
  cursor: number
  paymentStatus:
    | '결제 대기'
    | '결제 완료'
    | '결제 취소 요청'
    | '결제 취소'
    | '취소 불가'
  customerType: 'CUSTOMER' | 'REGULAR'
  orderFunnel: string
  createAt: string
  issuer: string
  customerPhone: string
  customerName: string
  serviceName: string
}
