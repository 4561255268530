import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import C from 'components/ContentsBox/ContentsBox.styles'
import { AdditionalCss } from 'themes/styles.type'
const RoundFillBoxStyle = {
  Wrap: styled.div(
    (props: { _css?: AdditionalCss; _offsetTop: number; _vh?: boolean }) => {
      return css`
        width: 100%;
        height: calc(
          ${props._vh ? '100vh' : '100%'} - ${props._offsetTop}px +
            ${C.AbsolutePadding}px
        );
        ${props._css ? props._css : ''}
      `
    }
  ),
}
export default RoundFillBoxStyle
