import { css } from '@emotion/react'
import { BadgeComponent } from 'components/Badge/Badge'
import { PrimaryButton } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import DownloadSpreadSheet from 'components/DownloadSpreadSheet'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import PeriodDropdownFilter from 'components/TableComponent/PeriodDropdownFilter'

import { rebuildSpreadSheetDataForApi } from 'components/DownloadSpreadSheet/SelectFilterForSpreadSheet/SelectFilterForSpreadSheet'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  isTimeFilter,
} from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import TableCell from 'components/TableComponent/TableCell'
import { TableRow } from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import useFetch from 'hooks/useFetch'
import useSearchFilter from 'hooks/useSearchFilter'
import { ApiFunnels } from 'pages/Services/Funnel/Funnel.types'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow, TXT } from 'themes/Shared.styles'
import {
  blobGetXlsx,
  cn,
  parserForPeriodQuery,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
  valueCheck,
  parserForSortQuery,
} from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import S from './EstimatesList.styles'
import { ApiEstimatesItem, EstimatesListProps } from './EstimatesList.types'

const CUSTOMER_TYPE_DROPDOWN_LIST = {
  전체: '',
  '일반 회원': '일반 회원',
  '정기 회원': '정기 회원',
} as const

const ESTIMATES_DROPDOWN_LIST = {
  전체: '',
  '작성 대기': '작성대기',
  '전송 완료': '전송완료',
} as const

// EDIT:THIS
type ResponseKeyName = {
  id: number
  estimatesState: '작성대기' | '전송완료'
  customerType: string
  funnel: string
  createdAt: string
  replyAt: string
  managerName: string
  buyerName: string
  phone: string
  serviceName: string
}

// EDIT:THIS
const defaultListTableConfigs: TableCellConfig<ResponseKeyName>[] = [
  {
    _type: 'dropdown',
    _id: 'estimatesState',
    _label: '작성상태',
    _size: 106,
    _position: 'left',
    _data: ESTIMATES_DROPDOWN_LIST,
    _value: '',
  },
  {
    _type: 'dropdown',
    _id: 'customerType',
    _label: '회원 분류',
    _size: 106,
    _position: 'left',
    _data: CUSTOMER_TYPE_DROPDOWN_LIST,
    _value: '',
  },
  {
    _type: 'dropdown',
    _id: 'funnel',
    _label: '주문 경로',
    _size: 106,
    _position: 'left',
    _data: {},
    _value: '',
  },
  {
    _type: 'order',
    _id: 'createdAt',
    _label: '요청 일시',
    _size: 160,
    _order: 'INITIAL',
  },
  {
    _type: 'order',
    _id: 'replyAt',
    _label: '작성 일시',
    _size: 160,
    _order: 'INITIAL',
  },
  {
    _type: 'default',
    _id: 'managerName',
    _label: '담당자명',
    _size: 84,
  },
  {
    _type: 'default',
    _id: 'buyerName',
    _label: '주문자명',
    _size: 84,
  },
  {
    _type: 'default',
    _id: 'phone',
    _label: '전화번호',
    _size: 120,
  },
  {
    _type: 'default',
    _id: 'serviceName',
    _label: '서비스명',
    _size: 'auto',
    _position: 'left',
  },
]
const ESTIMATES_QUERY_DROPDOWN_LIST = {
  전화번호: '전화번호',
  담당자명: '담당자명',
  주문자명: '주문자명',
  서비스명: '서비스명',
}
// EDIT:THIS
const responseParser = (response: ApiEstimatesItem[]): ResponseKeyName[] => {
  const rebuild = response.map((item: ApiEstimatesItem, index: number) => {
    return {
      id: item.id,
      estimatesState: item.status,
      customerType: item.userType,
      funnel: item.orderFunnel,
      createdAt: valueCheck(item.createdAt, (value) =>
        dayjs(value).format('YYYY-MM-DD(dd) HH:mm')
      ),

      replyAt: valueCheck(item.replyAt, (value) =>
        dayjs(value).format('YYYY-MM-DD(dd) HH:mm')
      ),
      phone: parseToFormattedStr(item.customerPhone, 'phone'),
      serviceName: item.servicePath,
      buyerName: item.customerName,
      managerName: valueCheck(item.issuer),
    } as ResponseKeyName
  })
  return rebuild
}

const EstimatesList = ({ _css, ...props }: EstimatesListProps) => {
  const navigate = useNavigate()
  const [apiFunnels] = useFetch<ApiFunnels>(`order`, '/order-funnels')

  const topInfoRef = useRef() as MutableRefObject<HTMLDivElement>
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [tableHeaderOffset, set_tableHeaderOffset] = useState<number>(0)
  const [headerOffset, set_headerOffset] = useState<number>(0)
  const [selectedId, set_selectedId] = useState<string>('')
  const [estimatesTableConfigs, set_estimatesTableConfigs] = useState<
    TableCellConfig<ResponseKeyName>[]
  >(defaultListTableConfigs)
  const [stats] = useFetch<{ total: number; before: number; after: number }>(
    'order',
    `/estimates/stats`
  )
  useEffect(() => {
    if (apiFunnels) {
      const flatter = apiFunnels
        .map((mainCategory) =>
          mainCategory.value
            .map((middleCategory) =>
              middleCategory.value.map((subdivision) => subdivision).flat()
            )
            .flat()
        )
        .flat()

      let rebuildConfigs = [
        ...defaultListTableConfigs.map((item) => {
          if (item._id === 'funnel' && item._type === 'dropdown') {
            item._data = {
              전체: '',
              ...flatter.reduce((prev, curr) => {
                prev[curr] = curr
                return prev
              }, {} as { [key in string]: string }),
            }
          }
          return item
        }),
      ]
      set_estimatesTableConfigs(rebuildConfigs)
    }
  }, [apiFunnels])
  useEffect(() => {
    if (topInfoRef.current && headerRef.current) {
      const headerSize =
        topInfoRef.current.getBoundingClientRect().height +
        headerRef.current.getBoundingClientRect().height
      set_headerOffset(topInfoRef.current.getBoundingClientRect().height)
      set_tableHeaderOffset(headerSize)
    }
  }, [])

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: PERIOD_DROPDOWN_INITIAL_VALUE,
      queryList: ESTIMATES_QUERY_DROPDOWN_LIST,
    },
    // query object -> filterList
    (
      {
        startAt,
        endAt,
        month,
        year,
        periodType,
        searchType,
        searchValue,
        sortType,
        sortValue,
        ...rest
      },
      queryName
    ) => {
      return {
        period: {
          start: startAt ? dayjs(startAt, 'YYYY-MM-DD') : null,
          end: endAt ? dayjs(endAt, 'YYYY-MM-DD') : null,
        },
        monthValue: month ?? '',
        yearValue: year ?? '',
        sortType: sortType ?? '',
        sortValue: sortValue ?? '',
        periodType: isTimeFilter(periodType) ? periodType : 'all',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    // filterList => query object
    ({ queryName = 'search', terms = '', ...rest }) => {
      return removeUndefined({
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        ...parserForPeriodQuery(rest),
        ...parserForSortQuery(rest),
      })
    }
  )
  const apiURI = useMemo(() => {
    // EDIT:THIS
    return `/estimates/list?take=10${
      window.location.search ? '&' + searchParams.toString() : ''
    }`
  }, [searchParams])
  return (
    <ContentsBox _type="relative" _css={css``}>
      {/* ContentsBox에서 스타일 상속 받음 */}
      <S.Wrap _css={_css} {...props}>
        {/* EDIT:THIS */}
        <TopInfo ref={topInfoRef}>
          <TopInfoRow
            _label={`장바구니수`}
            _contents={`${thousand(stats?.total)}건`}
          />
          <TopInfoRow
            _label={`견적요청`}
            _contents={`${thousand(stats?.before)}건`}
          />
          <TopInfoRow
            _label={`전송완료`}
            _contents={`${thousand(stats?.after)}건`}
          />
        </TopInfo>
        {/* EDIT:THIS */}
        <TableTitle
          ref={headerRef}
          _css={css`
            top: ${headerOffset}px;
          `}
          _left={
            <FlexRow
              gap={20}
              _css={css`
                & > div {
                  white-space: nowrap;
                }
              `}
            >
              <TXT _textStyle="Display/3 Bold" _color="Grayscale/Gray Dark">
                견적서
              </TXT>
              <FlexRow gap={7} alignItems="baseline">
                <TXT _color="Grayscale/Gray Light" _textStyle="Body/Small/Bold">
                  미작업 목록
                </TXT>
                <TXT _color="Primary/Dark" _textStyle="Headline/Bold">
                  {stats?.before}건
                </TXT>
              </FlexRow>
            </FlexRow>
          }
          _right={
            <FlexRow gap={16}>
              <DownloadSpreadSheet
                _tableConfig={[
                  '주문상태',
                  '회원분류',
                  '주문경로',
                  '요청일시',
                  '작성일시',
                  '담당자명',
                  '주문자명',
                  '전화번호',
                  '서비스명',
                ]}
                _emitValue={async (value) => {
                  const rebuild = rebuildSpreadSheetDataForApi(value)
                  blobGetXlsx(
                    'order',
                    `/estimates/excel${rebuild.queryString}`,
                    '견적서_목록'
                  )
                }}
              />
              <PrimaryButton
                _css={css`
                  width: 160px;
                  height: 40px;
                `}
                onClick={() => {
                  navigate(`/work/estimates/new`)
                }}
              >
                견적서 작성
              </PrimaryButton>
            </FlexRow>
          }
        />
        {/* EDIT:THIS */}
        <FilterPart<PeriodDropdownValuesType>
          _value={initialValues}
          _queryNameList={ESTIMATES_QUERY_DROPDOWN_LIST}
          _queryNameFormat={{ 전화번호: 'phone' }}
          _placeholder="주문번호 또는 주문자명, 상품명 검색"
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _FilterContents={({ _value, ...props }) => {
            return <PeriodDropdownFilter _value={_value} {...props} />
          }}
        />
        {/* EDIT:THIS */}
        <RoundFillBox>
          <TableComponent<ResponseKeyName>
            _value={initialValues}
            _type="order"
            _url={apiURI}
            _topOffset={tableHeaderOffset}
            _config={estimatesTableConfigs}
            _parser={(response: DoubleDListWithPaging<ApiEstimatesItem>) => {
              const rebuild = responseParser(response.list)
              return {
                _list: rebuild,
                _cursor: disposeCursor(response.paging.cursor, 'cursor'),
              }
            }}
            _emitOrder={(value) => {
              handleSetLocation(value)
            }}
            _ListContent={({ _row, _index, _configs }) => {
              const rowId = _row.id ? String(_row.id) : String(_index)
              return (
                <TableRow
                  className={cn({
                    active: selectedId === rowId,
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    set_selectedId(rowId)
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation()
                    navigate(`/work/estimates/${rowId}`)
                  }}
                >
                  {_configs.map((item, columnIndex) => {
                    // EDIT:THIS
                    const _id = item._id as keyof ResponseKeyName
                    const _value = _row[_id]
                    return (
                      <TableCell
                        key={'body' + _index + columnIndex}
                        _position={item._position}
                        _size={item._size}
                      >
                        {_id === 'estimatesState' ? (
                          <BadgeComponent
                            _dot={_value === '작성대기'}
                            _value={_value as '작성대기' | '전송완료'}
                          />
                        ) : (
                          <div>{_value}</div>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrap>
    </ContentsBox>
  )
}
export default EstimatesList
