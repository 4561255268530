import { css } from '@emotion/react'
import { FlexColumn, FlexRow, TXT } from 'themes/Shared.styles'
import { PopupInputLayoutProps } from './PopupInputLayout.types'

const PopupInputLayout = ({
  _css,
  _gap,
  _required,
  _title,
  children,
  ...props
}: PopupInputLayoutProps) => {
  return (
    <>
      <FlexColumn
        gap={_gap ?? 12}
        _css={css`
          & > *:not(.in-popup-title) {
            width: 100%;
          }
          ${_css ? _css : ''}
        `}
        {...props}
      >
        <TXT
          className="in-popup-title"
          _textStyle="Body/Small/Bold"
          _color="Grayscale/Gray Default"
        >
          <FlexRow gap={4}>
            {_title}
            {_required && <TXT _color="Accent/Dark">*</TXT>}
          </FlexRow>
        </TXT>
        {children}
      </FlexColumn>
    </>
  )
}
export default PopupInputLayout
