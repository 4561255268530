import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export const PARTNER_QUERY_DROPDOWN_LIST = {
  전화번호: 'phone',
  회원명: 'name',
} as const

export type ApiPartnerItem = {
  id: string
  name: string
  phone: string
  createdAt: string
  referralFee: number // 소개 수수료 한도
  settlementFee: number // 정산 수수료 %
}
export interface PartnerListProps extends DivAttributes {
  _css?: AdditionalCss
}
