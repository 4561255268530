import dayjs from 'dayjs'
import RouteComponent from 'pages/RouteComponent'
import { Provider } from 'react-redux'
import { store } from 'store'
require('dayjs/locale/ko')
dayjs.locale('ko')
function App() {
  return (
    <Provider store={store}>
      <RouteComponent />
    </Provider>
  )
}

export default App
