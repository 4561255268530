import { ImagesDetailsEmptyImage } from 'assets'
import S from './NotFoundItem.styles'
import { NotFoundItemProps } from './NotFoundItem.types'

const NotFoundItem = ({
  _css,
  children,
  _useImage,
  ...props
}: NotFoundItemProps) => {
  return (
    <S.Wrap _css={_css} {...props}>
      <S.Box className="not-found">
        <div>
          {_useImage && <img src={ImagesDetailsEmptyImage} alt="empty" />}
          <div>{children}</div>
        </div>
      </S.Box>
    </S.Wrap>
  )
}

export default NotFoundItem
