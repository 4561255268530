import { css } from '@emotion/react'
import { IconsRefreshFilter, ImagesInputSearchIcon } from 'assets'
import { GrayScaleOutline } from 'components/Buttons'
import UnderlineInput from 'components/UnderlineInput'
import { useState } from 'react'
import S from './SearchTechBox.styles'
import { SearchTechBoxProps } from './SearchTechBox.types'

const SearchTechBox = ({
  _css,
  _term,
  _emitValue,
  _placeholder,
  _refresh,
  ...props
}: SearchTechBoxProps) => {
  const [term, set_term] = useState<string>('')
  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <S.SubmitWrap>
          <UnderlineInput
            value={term}
            _css={css`
              width: 339px;
            `}
            handleValue={(value) => set_term(value)}
            placeholder={_placeholder ?? '담당자명 검색'}
            after={<img src={ImagesInputSearchIcon} alt="search" />}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault()
                _emitValue(term)
              }
            }}
          />
          <GrayScaleOutline
            _css={css`
              width: 80px;
              height: 40px;
            `}
            onClick={() => {
              _emitValue(term)
            }}
          >
            검색
          </GrayScaleOutline>
          <GrayScaleOutline
            _icon={true}
            _css={css`
              margin-left: 8px;
            `}
            disabled={!_term}
            onClick={() => {
              set_term('')
              if (_refresh) _refresh()
            }}
          >
            <IconsRefreshFilter width={24} height={24} />
          </GrayScaleOutline>
        </S.SubmitWrap>
      </S.Wrap>
    </>
  )
}
export default SearchTechBox
