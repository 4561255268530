import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const PeriodDropdownFilterStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  FilterWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    ${addCssProps}
  `,
  PeriodWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    ${typography['Body/Large/Bold']}
    color: ${colors['Grayscale/Gray Default']};
  `,
}

export default PeriodDropdownFilterStyle
