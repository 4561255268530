import { css } from '@emotion/react'
import Badge from 'components/Badge'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import { Copy, ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DetailsDefault from 'components/DetailsComponents/DetailsComponents'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import NotFoundItem from 'components/NotFoundItem/NotFoundItem'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import LoadingPage from 'layout/LoadingPage'
import {
  COUPON_DISCOUNT_TYPE_BADGE_COLOR,
  COUPON_TARGET_TYPE_BADGE_COLOR,
} from 'pages/Support/Coupon/Coupon.types'
import {
  APICouponDetailData,
  APICouponUsageHistory,
} from 'pages/Support/Coupon/CouponDetails/CouponDetails.types'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
} from 'themes/Shared.styles'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import callAxios, { extractData, handleError } from 'utils/callAxios'
import { isBetweenPeriod, thousand } from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'

interface CouponDetailsProps extends DivAttributes {
  _css?: AdditionalCss
  _id: string
}

const CouponDetails = ({ _id, _css, ...props }: CouponDetailsProps) => {
  const [isDeletePopUpOpen, set_isDeletePopUpOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [response, isError] = useFetch<APICouponDetailData>(
    'order',
    `/coupon/${_id}`,
    {
      disabled: !_id,
    }
  )
  const [list, set_list] = useState<APICouponUsageHistory[]>([])
  const [cursor, set_cursor] = useState<string | null>(null)
  const observerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [isEditable, set_isEditable] = useState<boolean>(false)

  // api : delete coupon
  const handleDelete = async (id: string) => {
    try {
      await callAxios('order').delete(`/coupon/${id}`)
      dispatch(setToast({ message: '삭제되었습니다.', type: 'error' }))
      navigate('/customer/coupon')
    } catch (e) {
      handleError(e)
    }
    set_isDeletePopUpOpen(false)
  }

  const COUPON_HISTORY_API_URL = useMemo(() => {
    return `/coupon/${_id}/history?take=10&type=used`
  }, [_id])

  // 수정,삭제 가능 여부 체크
  useEffect(() => {
    if (response) {
      const notStarted =
        isBetweenPeriod(dayjs(), response?.startAt, response?.endAt) === '예정'
      set_isEditable(notStarted)
    }
  }, [response])
  // api : 초기 쿠폰 사용 정보 불러오기
  useEffect(() => {
    if (!response) return
    const controller = new AbortController()
    const signal = controller.signal
    fetchCouponUsageHistory(COUPON_HISTORY_API_URL, signal, (extractedData) => {
      set_list(extractedData.list)
      set_cursor(disposeCursor(extractedData.paging.cursor))
    })
    return () => {
      controller.abort()
    }
  }, [response, COUPON_HISTORY_API_URL])
  // api : 쿠폰 사용 정보 더 불러오기(무한스크롤)
  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (observerRef.current) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              fetchCouponUsageHistory(
                COUPON_HISTORY_API_URL + `&cursor=${cursor}`,
                signal,
                (extractedData) => {
                  set_list((prev) => prev.concat(extractedData.list))
                  set_cursor(disposeCursor(extractedData.paging.cursor))
                }
              )
            }
          })
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 1.0,
        }
      )
      observer.observe(observerRef.current)
      return () => {
        observer.disconnect()
        controller.abort()
      }
    }
  }, [observerRef, cursor, COUPON_HISTORY_API_URL])

  if (!response) return <LoadingPage />

  if (isError) return <Navigate to={`/404?from=${window.location.pathname}`} />
  return (
    <>
      <Modal
        open={isDeletePopUpOpen}
        onClose={() => set_isDeletePopUpOpen(false)}
      >
        <MiniPopup
          _title="쿠폰 삭제"
          _buttonHandler={
            <>
              <GrayScaleFill onClick={() => set_isDeletePopUpOpen(false)}>
                취소
              </GrayScaleFill>
              <PrimaryButton
                onClick={() => {
                  handleDelete(_id)
                }}
              >
                삭제하기
              </PrimaryButton>
            </>
          }
        >
          {`쿠폰을 이미 발급받은 회원이 있을 수 있습니다.
해당 쿠폰을 삭제하시겠습니까?`}
        </MiniPopup>
      </Modal>
      <DetailsDefault
        _title={'쿠폰 상세'}
        _topLeftControls={
          <FlexRow gap={16} width="auto">
            {isEditable && (
              <GrayScaleFill
                _mini
                _css={css`
                  padding: 5px 12px;
                `}
                onClick={() => {
                  set_isDeletePopUpOpen(true)
                }}
              >
                삭제
              </GrayScaleFill>
            )}
            <ToList _to="/customer/coupon" />
          </FlexRow>
        }
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout
              _title={'쿠폰 정보'}
              _titleSuffix={
                <FlexRow gap={8} width="auto">
                  <Badge
                    {...COUPON_DISCOUNT_TYPE_BADGE_COLOR[
                      response?.isPercent ? '정률 할인 쿠폰' : '금액 할인 쿠폰'
                    ]}
                  >
                    {response?.isPercent ? '정률 할인 쿠폰' : '금액 할인 쿠폰'}
                  </Badge>
                  <Badge
                    {...COUPON_TARGET_TYPE_BADGE_COLOR[
                      response?.target === 'code' ? '코드발급' : '회원지정'
                    ]}
                  >
                    {response?.target === 'code' ? '코드발급' : '회원지정'}
                  </Badge>
                </FlexRow>
              }
            >
              <ContentRadiusBox>
                <BoxRowComponent
                  _label={'유효 기간'}
                  _contents={
                    <div>{`${dayjs(response?.startAt).format(
                      'YYYY-MM-DD'
                    )} ~ ${dayjs(response?.endAt).format('YYYY-MM-DD')}`}</div>
                  }
                />
                <BoxRowComponent
                  _label={'쿠폰명'}
                  _contents={<div>{response?.name}</div>}
                />
                {response?.code && (
                  <BoxRowComponent
                    _label={'쿠폰 코드'}
                    _contents={<Copy _text={response.code} />}
                  />
                )}
                <BoxRowComponent
                  _label={'최소 구매 금액'}
                  _contents={<div>{thousand(response?.minAmount) + '원'}</div>}
                />
                <BoxRowComponent
                  _label={'할인율 / 금액'}
                  _contents={
                    <div>
                      {response?.isPercent
                        ? response.amount + '%'
                        : thousand(response?.amount) + '원'}
                    </div>
                  }
                />
                {response?.isPercent && (
                  <BoxRowComponent
                    _label={'최대 할인 금액'}
                    _contents={<div>{thousand(response.maxAmount) + '원'}</div>}
                  />
                )}
                <BoxRowComponent
                  _label={'카테고리'}
                  _contents={<div>{response?.categoryName}</div>}
                />
                {response?.code && (
                  <BoxRowComponent
                    _label={'사용 / 발급 개수'}
                    _contents={
                      <div>{`${response?.useCount} / ${response?.issuedCount}`}</div>
                    }
                  />
                )}
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout _title={'발급자 정보'}>
              <ContentRadiusBox>
                <BoxRowComponent
                  _label={'발급자'}
                  _contents={response?.issuerName}
                />
                <BoxRowComponent
                  _label={'휴대폰 번호'}
                  _contents={parseToFormattedStr(
                    response?.issuerPhone ?? '',
                    'phone'
                  )}
                />
                <BoxRowComponent
                  _label={'발급일시'}
                  _contents={dayjs(response?.createdAt).format(
                    'YYYY-MM-DD HH:mm'
                  )}
                />
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _rightContents={
          <BoxLayout _title={'사용 정보'}>
            <FlexColumn gap={12}>
              {list.length < 1 && (
                <NotFoundItem _css={`width: 100%;`}>
                  사용 내역이 없습니다.
                </NotFoundItem>
              )}
              {list.map((item) => (
                <ContentRadiusBox
                  key={'coupon usage history : ' + item.id}
                  _css={css`
                    width: 100%;
                  `}
                >
                  <BoxRowComponent _label={'회원명'} _contents={item.name} />
                  <BoxRowComponent
                    _label={'휴대폰 번호'}
                    _contents={parseToFormattedStr(item.phone, 'phone')}
                  />
                  <BoxRowComponent _label={'아이디'} _contents={item.id} />
                  <BoxRowComponent
                    _label={'사용 일시'}
                    _contents={dayjs(item.useAt).format('YYYY-MM-DD HH:mm')}
                  />
                  <BoxRowComponent
                    _label={'쿠폰 사용 상품'}
                    _contents={item.usedIn}
                  />
                </ContentRadiusBox>
              ))}
              {cursor && <div ref={observerRef}></div>}
            </FlexColumn>
          </BoxLayout>
        }
        _bottomRightControls={
          <S.Layout.RightBottom>
            {isEditable && (
              <GrayScaleOutline
                onClick={() => navigate(`/customer/coupon/edit/${_id}`)}
                _css={css`
                  flex: 1;
                  height: 48px;
                `}
              >
                수정
              </GrayScaleOutline>
            )}
          </S.Layout.RightBottom>
        }
      ></DetailsDefault>
    </>
  )
}
// api : 쿠폰 사용 정보 불러오기
export const fetchCouponUsageHistory = async (
  url: string,
  signal: AbortSignal,
  callback: (
    extractedData: DoubleDListWithPaging<APICouponUsageHistory>
  ) => void
) => {
  try {
    const res = await callAxios('order').get(url, { signal })
    const extractedData =
      extractData<DoubleDListWithPaging<APICouponUsageHistory>>(res)
    callback(extractedData)
  } catch (error) {
    handleError(error)
  }
}
export default CouponDetails
