import { authorizedPages } from 'pages/AuthorizedPages'
import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'
import { PageAuthority } from 'types/api/samples/adminWeb'

const usePageAuthority = (...allowedCode: Array<PageAuthority>) => {
  const location = useLocation()

  const allowed = useMemo(() => {
    function checkAllowed() {
      if (!authorizedPages) return false
      // 코드의 라우트와 페이지권한이 있는 라우트가 일치하면 true, 코드 없는 페이지면 true, code있지만 권한 없으면 false
      if (matchPath({ path: '/', end: true }, location.pathname)) {
        // home이면 권한 체크
        return allowedCode.includes('home')
      }
      const freePages = authorizedPages.filter((item) => !item.code) // code가 없어서 권한 필요없는 페이지
      const needAuthPages = authorizedPages.filter((item) => item.code) // code가 포함된 권한이 필요한 페이지
      if (freePages.some((item) => matchPath(item.path, location.pathname))) {
        // freePages는 걍 true
        return true
      }
      return needAuthPages.some((item) => {
        // needAuthPages는 권한을 체크합니다.
        return item.code // item.code가 undefined인지를 체크합니다.
          ? matchPath(item.path, location.pathname) &&
              allowedCode.includes(item.code) // 현재 라우트가 일치하고, 그 아이템의 allowedCode에 권한이 있는게 맞는지 체크합니다.
          : false // 권한 없으면 밴
      })
    }
    return checkAllowed()
  }, [allowedCode, location.pathname])

  return allowed
}

export default usePageAuthority
