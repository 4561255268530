import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const Label = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
  cursor: pointer;
  user-select: none;
  & > label {
    ${typography['Body/Large/Bold']}
    color: ${colors['Grayscale/Black light']};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.placeholder {
    & > label {
      color: ${colors['Grayscale/Gray Lighter']};
    }
  }
  &.active {
    border-bottom: 1px solid ${colors['Primary/Default']};
  }
  &.disabled {
    opacity: 0.5;
  }
`
const Item = styled.div`
  &.before-item {
    min-width: auto;
    white-space: nowrap;
  }
`

const UnderlineDropdownStyle = {
  Label,
  Wrap: styled.div`
    position: relative;
    &.active {
      filter: drop-shadow(0px 2px 3px rgba(17, 17, 17, 0.12));
      z-index: 9;
    }
    &:focus {
      outline: none;
      filter: drop-shadow(0px 2px 3px rgba(17, 17, 17, 0.12));
      z-index: 999;
      ${Label} {
        border-bottom: 1px solid ${colors['Primary/Default']};
      }
    }
    ${addCssProps}
  `,
  Item,
  ListWrap: styled.div`
    position: absolute;
    min-width: 100%;
    padding-bottom: 32px;
    z-index: 9;
  `,
  List: styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 9;
    min-width: 100%;
    padding: 8px 0;
    text-align: left;
    ${Item} {
      cursor: pointer;
      user-select: none;
      padding: 10px 20px;
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Default']};
      &[data-active='active'] {
        color: ${colors['Grayscale/Black light']};
      }
      &:hover,
      &:focus {
        background-color: ${colors['Grayscale/Background Dark']};
        color: ${colors['Grayscale/Gray Default']};
        outline: none;
      }
    }
    & > div {
      max-height: 252px;
      width: 100%;
      overflow-y: auto;
    }
  `,
}

export default UnderlineDropdownStyle
