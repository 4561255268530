import { css } from '@emotion/react'
import { forwardRef } from 'react'
import S from './TopInfo.styles'
import { TopInfoProps } from './TopInfo.types'

const TopInfo = forwardRef<HTMLDivElement, TopInfoProps>(
  ({ _css, children, ...props }, ref) => {
    return (
      <>
        <S.Wrap
          ref={ref}
          _css={css`
            z-index: 5;
            ${_css && _css}
          `}
          {...props}
        >
          <div>{children}</div>
        </S.Wrap>
      </>
    )
  }
)
export default TopInfo
