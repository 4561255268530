import { css } from '@emotion/react'
import { GrayScaleOutline, PrimaryOutlineButton } from 'components/Buttons'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import dayjs from 'dayjs'
import { clone, uniqBy } from 'lodash-es'
import { useMemo } from 'react'
import { ContentRadiusBox, Flex } from 'themes/Shared.styles'
import { TechsDefaultType } from '../SelectTeamPopup/SelectTeamPopup.types'
import S from './ManagerPart.styles'
import { ManagerPartProps } from './ManagerPart.types'

const ManagerPart = <T extends TechsDefaultType>({
  _css,
  _readOnly,
  _managerTeam,
  _label,
  _handleOpenSelectPopup,
  ...props
}: ManagerPartProps<T>) => {
  const techsAll = useMemo(() => {
    return _managerTeam
      ? uniqBy(
          _managerTeam.daySchedules.map((team) => team.techs).flat(),
          (item) => item.id
        )
      : []
  }, [_managerTeam])
  return (
    <>
      {_managerTeam && _managerTeam?.daySchedules.length > 0 ? (
        <div>
          <ContentRadiusBox>
            <BoxRowComponent
              _css={css`
                align-items: flex-start;
                & > label {
                  margin-top: 5px;
                }
              `}
              _label={_label.teams}
              _contents={
                <Flex gap={8} justifyContent="flex-end" flexWrap="wrap">
                  {techsAll.map((tech) => {
                    return (
                      <S.WorkerName key={'techName' + tech.id}>
                        {tech.name}
                      </S.WorkerName>
                    )
                  })}
                </Flex>
              }
            />
            {_managerTeam.daySchedules.map((schedule, index) => {
              return (
                <BoxRowComponent
                  _label={index === 0 ? _label.time : ''}
                  key={'schedule-list' + index}
                >
                  <div>{`${dayjs(schedule.currentDay).format(
                    'YYYY-MM-DD(dd)'
                  )} ${schedule.times.start}~${schedule.times.end}`}</div>
                </BoxRowComponent>
              )
            })}
            <BoxRowComponent
              _label={_label.info}
              _contents={
                <GrayScaleOutline
                  _css={css`
                    padding: 5px 0;
                    width: 100%;
                  `}
                  onClick={() => {
                    let cloned = clone(_managerTeam)
                    if (cloned) {
                      cloned = {
                        ...cloned,
                        daySchedules: cloned.daySchedules.map((item) => ({
                          ...item,
                          techs: uniqBy(item.techs, (data) => data.id),
                        })),
                      }
                    }
                    _handleOpenSelectPopup({ open: true, data: cloned })
                  }}
                >
                  수정
                </GrayScaleOutline>
              }
            />
          </ContentRadiusBox>
          {_managerTeam.remarks && (
            <ContentRadiusBox className="question">
              <BoxRowComponent
                _label={'특이 사항'}
                _contents={_managerTeam.remarks}
              />
            </ContentRadiusBox>
          )}
        </div>
      ) : (
        <>
          {_readOnly ? (
            <></>
          ) : (
            <ContentRadiusBox>
              <BoxRowComponent
                _label={_label.info}
                _contents={
                  <PrimaryOutlineButton
                    _css={css`
                      padding: 5px 0;
                      width: 100%;
                    `}
                    onClick={() => {
                      _handleOpenSelectPopup({ open: true })
                    }}
                  >
                    입력
                  </PrimaryOutlineButton>
                }
              />
            </ContentRadiusBox>
          )}
        </>
      )}
    </>
  )
}
export default ManagerPart
