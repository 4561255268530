import { css } from '@emotion/react'
import AssignServiceInfo from 'components/AssignServiceInfo'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash-es'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setRefresh } from 'store/refreshSlice'
import { setToast } from 'store/toastSlice'
import { FlexColumn } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import ManagerPart from '../../ManagerPart'
import AssignTeamPopup from '../AssignTeamPopup'
import { AssignedSchedulesType } from '../AssignTeamPopup/AssignTeamPopup.types'
import { AssignServiceTechsProps } from './AssignServiceTechs.types'

const AssignServiceTechs = ({
  _css,
  _readOnly,
  handleModal,
  inputs,
  handleInput,
  ...props
}: AssignServiceTechsProps) => {
  const { id } = useParams<'id'>()

  const [openServiceTeam, set_openServiceTeam] = useState<{
    open: boolean
    id?: string
    data?: AssignedSchedulesType
  }>({
    open: false,
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleSubmit = async (value: AssignedSchedulesType) => {
    try {
      await callAxios('task').put(`/schedule`, {
        type: '시공',
        taskId: id,
        memo: value.remarks,
        schedule: value.daySchedules.map((day) => ({
          ymd: dayjs(day.currentDay).format('YYYYMMDD'),
          startTime: day.times.start.replace(':', ''),
          endTime: day.times.end.replace(':', ''),
          workerList: uniqBy(day.techs, (value) => value.id).map(
            (item) => item.id
          ),
        })),
      })
      dispatch(setToast(['시공팀이 배정되었습니다.', 'success']))
      if (
        inputs.serviceTeam.daySchedules.length === 0 &&
        inputs.serviceState === '주문완료'
      ) {
        handleInput('serviceState', '시공대기')
        dispatch(setRefresh(dayjs().valueOf()))
        navigate(`/work/waiting/${id}`)
      }
      handleInput('serviceTeam', value)
      set_openServiceTeam({ open: false })
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      {openServiceTeam.open && (
        <AssignTeamPopup
          _data={openServiceTeam.data}
          _open={openServiceTeam.open}
          _onClose={() => {
            set_openServiceTeam({ open: false })
          }}
          _emitValue={handleSubmit}
          _type="시공"
        />
      )}
      <FlexColumn
        gap={24}
        _css={css`
          & > div {
            width: 100%;
          }
        `}
      >
        <ManagerPart
          _readOnly={_readOnly}
          _managerTeam={inputs.serviceTeam}
          _label={{
            teams: '시공자',
            time: '시공 일자',
            info: '시공팀 정보',
          }}
          _handleOpenSelectPopup={(value) => {
            set_openServiceTeam(value)
          }}
        />
        <BoxLayout _title={'시공 전'}>
          <AssignServiceInfo
            _type={'beforeConstruction'}
            taskId={id}
            inputs={inputs}
            handleInput={handleInput}
            handleModal={handleModal}
          />
        </BoxLayout>

        <BoxLayout _title={'시공 후'}>
          <AssignServiceInfo
            _type={'afterConstruction'}
            taskId={id}
            inputs={inputs}
            handleInput={handleInput}
            handleModal={handleModal}
          />
        </BoxLayout>
      </FlexColumn>
    </>
  )
}
export default AssignServiceTechs
