import { Dayjs } from 'dayjs'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { GeneratedType } from 'utils/getCalendar'

export const CALENDAR_SCHEDULE_DOT_COLOR = {
  실측: 'Accent/Default',
  시공: 'Danger/Dark',
  자재: 'Success/Default',
} as const

export type DotColorsKeyType = keyof typeof CALENDAR_SCHEDULE_DOT_COLOR

export interface CalendarInReservationProps extends DivAttributes {
  _css?: AdditionalCss
  _value?: Dayjs
  _emitValue: (value: Dayjs) => void
  _DayContents?: (props: { day: GeneratedType }) => JSX.Element
  _currentMonth: Dayjs
  _prefix?: JSX.Element
}
