import { css } from '@emotion/react'
import { ImagesInputSearchIcon } from 'assets'
import UnderlineInput from 'components/UnderlineInput'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import callAxios, { extractData, handleError } from 'utils/callAxios'
import { cn } from 'utils/helpers'
import S from './LiveSearchInput.styles'
import { LiveSearchInputProps } from './LiveSearchInput.types'

const LiveSearchInput = <T,>({
  _url,
  _css,
  _ListContent: ListContent,
  _setTitle,
  _emitValue,
  _emitList,
  _listParser,
  _defaultValue,
  _removeAfter,
  _placeholder,
  _suffix: SuffixContent,
  _height = 196,
  _type = 'dev',
  _queryName = 'search',
  _disabled,
  _readOnly,
  ...props
}: LiveSearchInputProps<T>) => {
  const [term, set_term] = useState<string>('')
  const timeoutRef = useRef() as MutableRefObject<NodeJS.Timeout>
  const [liveSearchList, set_liveSearchList] = useState<T[] | null>(null)
  const [cursor, set_cursor] = useState<string | null>(null)
  useEffect(() => {
    set_term(_defaultValue ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_defaultValue])
  const fetchSearch = async (search: string, next?: boolean) => {
    try {
      const axiosRes = await callAxios(_type).get(
        _url +
          `${_url.includes('?') ? '&' : '?'}${_queryName}=${search}${
            cursor ? cursor : ''
          }`
      )
      const res = extractData(axiosRes)
      const { _list, _cursor } = _listParser(res)
      set_liveSearchList((prev) => (next && prev ? [...prev, ..._list] : _list))
      set_cursor(_cursor)
      if (_emitList) _emitList(_list)
    } catch (error) {
      handleError(error)
    }
  }
  const handleSearch = (value: string) => {
    set_term(value)
    set_cursor(null)
    set_liveSearchList(null)
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(async () => {
      if (_emitValue) _emitValue(null)
      if (value.length > 1) {
        await fetchSearch(value)
      }
    }, 500)
  }
  const handleSetResult = (item: T) => {
    if (_emitValue) _emitValue(item)
    if (_removeAfter) {
      set_term('')
    } else {
      set_term(_setTitle(item))
    }
    set_liveSearchList(null)
  }
  const clearList = () => {
    set_term('')
    set_liveSearchList(null)
  }
  const fireNext = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    let scrollingElement = e.currentTarget
    if (!cursor) return
    if (
      scrollingElement.scrollHeight ===
      scrollingElement.clientHeight + scrollingElement.scrollTop
    ) {
      fetchSearch(term, true)
    }
  }
  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <UnderlineInput
          disabled={_disabled}
          readOnly={_readOnly}
          _css={css`
            ${_disabled ? `opacity: 0.5;` : ''}
          `}
          value={term}
          handleValue={handleSearch}
          placeholder={_placeholder}
          after={<img src={ImagesInputSearchIcon} alt="search" />}
        />
        {liveSearchList && (
          <S.List className={cn({ 'has-list': liveSearchList.length > 0 })}>
            {liveSearchList.length > 0 && (
              <>
                <div className="list-inner">
                  <Virtuoso
                    onScroll={fireNext}
                    className="list-row"
                    style={{ height: `${_height}px` }}
                    totalCount={liveSearchList.length}
                    itemContent={(index) => {
                      const row = liveSearchList[index]
                      return (
                        <ListContent
                          key={
                            (row as any).id
                              ? (row as any).id
                              : 'liveSearch' + index
                          }
                          handleSubmit={handleSetResult}
                          clearList={clearList}
                          item={row}
                          term={term}
                        />
                      )
                    }}
                  />
                  {SuffixContent ? (
                    <SuffixContent
                      handleSubmit={handleSetResult}
                      clearList={clearList}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
            {liveSearchList.length === 0 && (
              <div className="not-found">검색결과가 없습니다.</div>
            )}
          </S.List>
        )}
      </S.Wrap>
    </>
  )
}
export default LiveSearchInput
