import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const ServiceDropdownStyle = {
  Wrap: styled.div`
    width: 100%;
    height: 100%;
    padding: 6px 4px;
    &.active,
    &.selected {
      background-color: ${colors['Primary/Default']};
      input {
        background-color: ${colors['Primary/Default']};
        color: #fff;
      }
    }
    input {
      ${typography['Body/Small/Regular']}
      color: ${colors['Grayscale/Black light']};
      height: 100%;
      padding: 0;
      flex: 1;
    }
    &.disabled {
      background-color: ${colors['Grayscale/Gray Lighter']};
    }
    ${addCssProps}
  `,
  Label: styled.div`
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    width: 100%;
    height: 100%;
    gap: 4px;
    .hide {
      display: none;
    }
    img {
      width: 16px;
      height: 16px;
    }
    &.placeholder {
      color: ${colors['Grayscale/Gray Light']};
    }
    &.active,
    &.selected {
      & > label {
        color: #fff;
      }
    }
  `,

  Item: styled.div`
    text-align: left;
    cursor: pointer;
    user-select: none;
    padding: 4px;
    width: 100%;
    ${typography['Body/Small/Regular']}
    color: ${colors['Grayscale/Gray Default']};
    &[data-active='active'] {
      color: ${colors['Grayscale/Black light']};
    }
    &:hover,
    &:focus {
      background-color: ${colors['Grayscale/Background Light']};
      outline: none;
    }
    &.option {
      border-top: 1px solid ${colors['Grayscale/Gray Lighter']};
      margin-top: 4px;
    }
  `,
  List: styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    transform: translateY(100%);
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 10px 40px rgba(99, 99, 99, 0.3);
    gap: 4px;
    z-index: 9;
    padding: 0;
    min-width: 113px;
    & > div {
      max-height: 252px;
      width: 100%;
      overflow-y: auto;
    }
  `,
}

export default ServiceDropdownStyle
