import { css } from '@emotion/react'
import { ImagesServiceButton } from 'assets'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DraggablePopup from 'components/DraggablePopup'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import { useAppDispatch } from 'hooks/reduxHooks'
import { workStatusToKorean } from 'hooks/useAllMatch'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ContentRadiusBox,
  Flex,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { colors } from 'themes/styles'
import callAxios, { handleError } from 'utils/callAxios'
import { cn, getKeyNameByValue, thousand } from 'utils/helpers'
import ExtraFee from '../ExtraFee/ExtraFee'
import S from './ExtraFeesInService.styles'
import {
  ExtraFeesInServiceProps,
  TaskAdditionalCost,
  TaskAdditionalItem,
} from './ExtraFeesInService.types'

const ExtraFeesInService = ({
  _css,
  _list,
  _orderId,
  _status,
  _onClose,
  _isUnpaid,
  _emitValue,
  ...props
}: ExtraFeesInServiceProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [currentExtraFees, set_currentExtraFees] = useState<
    TaskAdditionalItem[]
  >([])
  const workRoute = useMemo(() => {
    return getKeyNameByValue(workStatusToKorean, _status)
  }, [_status])
  useEffect(() => {
    set_currentExtraFees(_list)
  }, [_list])
  const totalAmount = useMemo(() => {
    return currentExtraFees.reduce(
      (pr, cu) => {
        const serviceTotal = cu.list.reduce(
          (prev, curr) => {
            if (curr.isPaid && curr.price) {
              prev.completed += curr.price
            }
            if (!curr.isPaid && curr.price) {
              prev.new += curr.price
            }
            return prev
          },
          { completed: 0, new: 0 } as {
            completed: number
            new: number
          }
        )
        pr.completed += serviceTotal.completed
        pr.new += serviceTotal.new
        return pr
      },
      { completed: 0, new: 0 } as {
        completed: number
        new: number
      }
    )
  }, [currentExtraFees])
  const [selectedIndex, set_selectedIndex] = useState<number>(0)
  const selectedList = useMemo(() => {
    const selectedItem = currentExtraFees[selectedIndex]
    return selectedItem ? selectedItem.list : []
  }, [currentExtraFees, selectedIndex])
  const [openExtraFee, set_openExtraFee] = useState<{
    open: boolean
    data?: {
      index: number
      data: TaskAdditionalCost
      readonly?: boolean
    }
  }>({ open: false })
  const [openModal, set_openModal] = useState<{
    title: string
    content: string
    txt?: [string, string]
    onSubmit?: () => void
  } | null>(null)
  const handleRemoveAdditional = async () => {
    if (!_orderId) return
    try {
      await callAxios('order').delete(`/payment/additional`, {
        data: {
          orderId: _orderId,
        },
      })
      set_openModal(null)
      _onClose()
      if (workRoute) {
        navigate(`/work/${workRoute}`)
      } else {
        navigate(`/unpaid`)
      }
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      {openModal && (
        <Modal open={openModal !== null} onClose={() => set_openModal(null)}>
          <MiniPopup
            _title={openModal.title}
            _buttonHandler={
              openModal.onSubmit ? (
                <>
                  <GrayScaleFill
                    onClick={() => {
                      set_openModal(null)
                    }}
                  >
                    {openModal.txt?.[0] ?? '아니오'}
                  </GrayScaleFill>
                  <PrimaryButton onClick={openModal.onSubmit}>
                    {openModal.txt?.[1] ?? '삭제'}
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    set_openModal(null)
                  }}
                >
                  {openModal.txt?.[0] ?? '확인'}
                </PrimaryButton>
              )
            }
          >
            {openModal.content}
          </MiniPopup>
        </Modal>
      )}
      {openExtraFee.open && (
        <>
          <ExtraFee
            _data={openExtraFee.data}
            _open={openExtraFee.open}
            _onClose={() => set_openExtraFee((prev) => ({ open: false }))}
            _emitValue={(value, index) => {
              set_currentExtraFees((prev) =>
                prev.map((item, itemIndex) =>
                  itemIndex === selectedIndex
                    ? {
                        ...item,
                        list: index
                          ? item.list.map((data, dataIndex) =>
                              dataIndex === index ? value : data
                            )
                          : [...item.list, value],
                      }
                    : item
                )
              )
              set_openExtraFee((prev) => ({ open: false }))
            }}
          />
          <S.Background
            onClick={(e) => {
              e.stopPropagation()
              set_openExtraFee((prev) => ({ open: false }))
            }}
          />
        </>
      )}
      <DraggablePopup
        _open={true}
        _onClose={_onClose}
        _title={'추가금 요청'}
        _css={css`
          min-width: 1154px;
        `}
        _bottom={
          <Flex
            justifyContent={'space-between'}
            _css={css`
              width: 100%;
            `}
          >
            <FlexRow gap={16}>
              <TXT _textStyle="Body/Large/Bold" _color="Grayscale/Black light">
                결제 완료된 추가금: {thousand(totalAmount.completed)}원
              </TXT>
              <TXT _textStyle="Body/Large/Bold" _color="Primary/Default">
                신규 추가금: {thousand(totalAmount.new)}원
              </TXT>
            </FlexRow>
            <FlexRow gap={16} justifyContent="flex-end">
              {_isUnpaid && (
                <GrayScaleOutline
                  width={200}
                  padding={`12px 20px`}
                  onClick={() => {
                    set_openModal({
                      title: '미결제 추가금 요청 삭제',
                      content: `결제되지 않은 추가금 요청 건을\n모두 삭제하시겠습니까?`,
                      txt: ['취소', '요청 삭제'],
                      onSubmit: () => {
                        handleRemoveAdditional()
                      },
                    })
                  }}
                >
                  미결제 추가금 요청 삭제
                </GrayScaleOutline>
              )}
              <GrayScaleFill
                width={148}
                padding={`12px 20px`}
                onClick={_onClose}
              >
                취소
              </GrayScaleFill>
              <PrimaryButton
                width={200}
                padding={`12px 20px`}
                onClick={() => {
                  set_openModal({
                    title: '추가금 요청',
                    content: `추가금이 발생되면 재결재를 위해 회원에게 새로운\n견적서를 보내고, 기존 결제 내역은 취소 됩니다.\n추가금 요청서를 전송하시겠습니까?`,
                    txt: ['취소', '전송'],
                    onSubmit: () => {
                      _emitValue(currentExtraFees)
                      set_openModal(null)
                    },
                  })
                }}
              >
                완료
              </PrimaryButton>
            </FlexRow>
          </Flex>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <FlexColumn gap={16}>
            {currentExtraFees.map((item, itemIndex) => {
              return (
                <S.ServiceItem
                  key={'big' + itemIndex}
                  className={cn({ active: selectedIndex === itemIndex })}
                  onClick={() => {
                    set_selectedIndex(itemIndex)
                  }}
                >
                  <Flex
                    justifyContent={'space-between'}
                    _css={css`
                      width: 100%;
                    `}
                  >
                    <FlexColumn gap={2}>
                      <div className="title">{item.serviceName}</div>
                      <div className="price">{thousand(item.price)}원</div>
                    </FlexColumn>
                    <FlexRow gap={4} justifyContent={'flex-end'}>
                      <div className="add">추가금</div>
                      <div className="count">
                        {thousand(item.list.length)}건
                      </div>
                    </FlexRow>
                  </Flex>
                </S.ServiceItem>
              )
            })}
          </FlexColumn>
          {selectedList.length > 0 ? (
            <FlexColumn gap={12}>
              {selectedList.map((item, itemIndex) => {
                return (
                  <Fragment key={'extraFee' + itemIndex}>
                    <ContentRadiusBox
                      _css={css`
                        &.isPaid {
                          cursor: pointer;
                          &:hover {
                            border-color: ${colors['Primary/Default']};
                            background-color: ${colors['Primary/Lighter']};
                          }
                        }
                      `}
                      className={cn({ isPaid: item.isPaid || _isUnpaid })}
                      {...((item.isPaid || _isUnpaid) && {
                        onClick: () => {
                          set_openExtraFee(() => ({
                            open: true,
                            data: {
                              index: itemIndex,
                              data: item,
                              readonly: true,
                            },
                          }))
                        },
                      })}
                    >
                      <BoxRowComponent
                        _label={'추가 발생 작업'}
                        _contents={item.taskName}
                      />
                      <BoxRowComponent
                        _label={'총 추가 금액'}
                        _contents={thousand(item.price ?? 0) + '원'}
                      />
                      <BoxRowComponent
                        _label={'공임비 추가 금액'}
                        _contents={thousand(item.taskPrice ?? 0) + '원'}
                      />
                      {!item.isPaid && !_isUnpaid ? (
                        <BoxRowComponent
                          _label={'추가금 정보'}
                          _contents={
                            <FlexRow gap={16}>
                              <GrayScaleOutline
                                _css={css`
                                  padding: 5px 0;
                                  flex: 1;
                                `}
                                onClick={() => {
                                  set_openExtraFee(() => ({
                                    open: true,
                                    data: {
                                      index: itemIndex,
                                      data: item,
                                    },
                                  }))
                                }}
                              >
                                수정
                              </GrayScaleOutline>
                              <GrayScaleFill
                                _css={css`
                                  padding: 5px 0;
                                  width: 97px;
                                `}
                                onClick={() => {
                                  set_openModal({
                                    title: '추가금 삭제',
                                    content: `추가금을 삭제 하시겠습니까?`,
                                    txt: ['취소', '삭제'],
                                    onSubmit: () => {
                                      set_currentExtraFees((prev) =>
                                        prev.map((dt, dtIndex) =>
                                          dtIndex === selectedIndex
                                            ? {
                                                ...dt,
                                                list: dt.list.filter(
                                                  (data, dataIndex) =>
                                                    dataIndex !== itemIndex
                                                ),
                                              }
                                            : dt
                                        )
                                      )

                                      dispatch(
                                        setToast(['삭제 되었습니다.', 'error'])
                                      )
                                      set_openModal(null)
                                    },
                                  })
                                }}
                              >
                                삭제
                              </GrayScaleFill>
                            </FlexRow>
                          }
                        />
                      ) : (
                        <BoxRowComponent _label={'추가금 정보'}>
                          {item.isPaid ? '결제 완료' : '결제 대기'}
                        </BoxRowComponent>
                      )}
                    </ContentRadiusBox>
                  </Fragment>
                )
              })}
              {!_isUnpaid && (
                <S.AddButton
                  src={ImagesServiceButton}
                  alt="add"
                  onClick={() => {
                    set_openExtraFee(() => ({
                      open: true,
                    }))
                  }}
                />
              )}
            </FlexColumn>
          ) : (
            <S.NoContent>
              <div>추가금 항목을 추가하세요.</div>
              <S.AddButton
                src={ImagesServiceButton}
                alt="add"
                onClick={() => {
                  set_openExtraFee(() => ({
                    open: true,
                  }))
                }}
              />
            </S.NoContent>
          )}
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default ExtraFeesInService
