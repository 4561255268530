import { css } from '@emotion/react'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { cleanError, selectError } from 'store/errorSlice'

const GlobalErrorPopup = () => {
  const globalError = useAppSelector(selectError)
  const dispatch = useAppDispatch()
  return (
    <>
      <Modal
        _target="#global"
        _css={css`
          z-index: 999999;
        `}
        open={globalError !== null}
        onClose={() => dispatch(cleanError())}
      >
        <MiniPopup _title={globalError?.title ?? `알림`}>
          {globalError?.errorMessage}
        </MiniPopup>
      </Modal>
    </>
  )
}
export default GlobalErrorPopup
