import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import {
  CALENDAR_SCHEDULE_DOT_COLOR,
  DotColorsKeyType,
} from '../../CalendarInReservation/CalendarInReservation.types'

const SchedulesInPopupStyle = {
  Wrap: styled.div`
    position: fixed;
    width: 264px;
    height: 470px;
    z-index: 10101;
    ${addCssProps}
  `,
  Background: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10100;
    background-color: transparent;
  `,
  RightHeader: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${typography['Body/Small/Bold']}
    color: ${colors['Grayscale/Black light']};
    padding: 16px 24px 12px;
  `,
  SchedulesOuter: styled.div`
    padding: 0 8px;
    height: calc(100% - 48px - 16px);
  `,
  None: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    ${typography['Body/Caption/Bold']}
    color: ${colors['Grayscale/Gray Light']};
  `,
  SchedulesWrap: styled.div`
    padding: 0 16px 12px;
    height: 100%;
    overflow: auto;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  SchedulesGroup: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  AllDayWrap: styled.div`
    position: sticky;
    top: 0px;
    height: 24px;
    padding: 3px 0 15px;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    gap: 8px;
    ${typography['Body/Caption/Bold']}
    color: ${colors['Primary/Default']};
    background-color: #fff;
  `,
  Line: styled.div`
    flex: 1 0;
    height: 1px;
    background-color: ${colors['Grayscale/Gray Light']};
  `,
  ScheduleItem: styled.div(
    ({ _bgColor, ...props }: { _bgColor: DotColorsKeyType }) =>
      css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        .title {
          display: flex;
          align-items: center;
          gap: 12px;
          ${typography['Body/Caption/Bold']}
          color: ${colors['Grayscale/Gray Dark']};
          position: relative;
          &::before {
            content: '';
            width: 4px;
            height: 18px;
            border-radius: 8px;
            background-color: ${colors[CALENDAR_SCHEDULE_DOT_COLOR[_bgColor]]};
          }
        }
        .techs {
          padding-left: 16px;
          width: 100%;
          display: grid;
          gap: 10px 4px;
          grid-auto-flow: row;
          grid-template-columns: repeat(4, 1fr);
          justify-content: space-between;
          & > div {
            ${typography['Body/Caption/Regular']}
            color: ${colors['Grayscale/Gray Dark']};
            padding: 3px 6px;
            border-radius: 20px;
            white-space: nowrap;
            box-shadow: inset 0px 0px 0px 1px
              ${colors['Grayscale/Gray Default']};
          }
        }
      `
  ),
}

export default SchedulesInPopupStyle
