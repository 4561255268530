import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'

const MainStyle = {
  MainWrap: styled.div`
    & {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
    & > .flex-row {
      display: flex;
      gap: 24px;
    }
  `,
  TodayWrapper: styled.div`
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 179px;
    }
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      ${typography['Headline/Bold']};
      color: ${colors['White/White off']};
      .sub {
        ${typography['Body/Caption/Bold']};
      }
    }
  `,
  UserWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 24px 75px;
  `,
  PeriodWrapper: styled.div`
    & {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      gap: 56px;
    }
    .left {
      & {
        flex: 1;
        padding: 40px 0px 24px;
      }
      & > .flex-row {
        height: 100%;
        display: flex;
        align-content: space-between;
        gap: 15px;
        flex-wrap: wrap;
      }
    }
    .right {
      width: 396px;
      padding-bottom: 14px;
    }
    .no-period {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${colors['Grayscale/Gray Light']};
    }
  `,
}
export default MainStyle
