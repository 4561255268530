import styled from '@emotion/styled/macro'
import { ImagesGalleryImageFail } from 'assets'
import LoadingPage from 'layout/LoadingPage'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { colors, typography } from 'themes/styles'
import * as THREE from 'three'
import { cn, getCreatedAtFromFileName, getFileNameFromUrl } from 'utils/helpers'

const PanoramaWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  &.isError {
    display: flex;
    align-items: center;
    justify-content: center;
    canvas {
      display: none;
    }
    img {
      width: 300px;
      height: auto;
    }
  }
  position: relative;
  .createdAt {
    position: absolute;
    bottom: 8px;
    right: 8px;
    ${typography['Body/Small/Bold']}
    color: ${colors['White/White off']};
    background-color: ${colors['Grayscale/Black']};
    padding: 2px 8px;
  }
`
interface PanoramaProps {
  _imageURL: string
}
const Panorama = ({ _imageURL, ...props }: PanoramaProps) => {
  const domRef = useRef() as MutableRefObject<HTMLDivElement>
  const [pending, set_pending] = useState<boolean>(false)
  const configRef = useRef({
    onPointerDownPointerX: 0,
    onPointerDownPointerY: 0,
    onPointerDownLon: 0,
    onPointerDownLat: 0,
    lon: 0,
    lat: 0,
    phi: 0,
    theta: 0,
  }) as MutableRefObject<{
    renderer?: THREE.WebGLRenderer
    scene?: THREE.Scene
    camera?: THREE.PerspectiveCamera
    onPointerDownPointerX: number
    onPointerDownPointerY: number
    onPointerDownLon: number
    onPointerDownLat: number
    lon: number
    lat: number
    phi: number
    theta: number
  }>

  const [src, set_src] = useState<string>('')
  const [isError, set_isError] = useState<boolean>(false)
  useEffect(() => {
    if (_imageURL) {
      const image = new Image()
      image.onload = () => {
        set_isError(false)
        set_src(_imageURL)
      }
      image.onerror = () => {
        set_isError(true)
        set_src('')
      }
      image.src = _imageURL
    }
  }, [_imageURL])
  useEffect(() => {
    let {
      renderer,
      scene,
      camera,
      onPointerDownPointerX,
      onPointerDownPointerY,
      onPointerDownLon,
      onPointerDownLat,
      lon,
      lat,
      phi,
      theta,
    } = configRef.current
    function onWindowResized() {
      if (!domRef.current) return
      if (!renderer) return
      if (!camera) return
      const domRect = domRef.current.getBoundingClientRect()
      renderer.setSize(domRect.width, domRect.height)

      camera.aspect = domRect.width / domRect.height
      camera.updateProjectionMatrix()
    }
    function onDocumentMouseWheel(event: WheelEvent) {
      if (!camera) return
      const fov = camera.fov + event.deltaY * 0.05

      camera.fov = THREE.MathUtils.clamp(fov, 10, 75)

      camera.updateProjectionMatrix()
    }
    function onPointerDown(event: PointerEvent) {
      event.preventDefault()

      onPointerDownPointerX = event.clientX
      onPointerDownPointerY = event.clientY

      onPointerDownLon = lon
      onPointerDownLat = lat

      domRef.current.addEventListener('pointermove', onPointerMove, false)
      domRef.current.addEventListener('pointerup', onPointerUp, false)
      domRef.current.addEventListener('pointerleave', onPointerUp, false)
    }

    function onPointerMove(event: PointerEvent) {
      lon = (event.clientX - onPointerDownPointerX) * 0.1 + onPointerDownLon
      lat = (event.clientY - onPointerDownPointerY) * 0.1 + onPointerDownLat
    }

    function onPointerUp() {
      domRef.current.removeEventListener('pointermove', onPointerMove, false)
      domRef.current.removeEventListener('pointerup', onPointerUp, false)
      domRef.current.removeEventListener('pointerleave', onPointerUp, false)
    }
    function init(texture: THREE.Texture) {
      if (!domRef.current) return
      const domRect = domRef.current.getBoundingClientRect()
      if (renderer) renderer.clear()
      renderer = new THREE.WebGLRenderer({ antialias: true })
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(domRect.width, domRect.height)
      renderer.outputEncoding = THREE.sRGBEncoding
      domRef.current.appendChild(renderer.domElement)

      scene = new THREE.Scene()
      scene.background = texture

      camera = new THREE.PerspectiveCamera(
        75,
        domRect.width / domRect.height,
        1,
        1000
      )

      domRef.current.addEventListener('pointerdown', onPointerDown, false)
      domRef.current.addEventListener('wheel', onDocumentMouseWheel, {
        passive: false,
      })

      window.addEventListener('resize', onWindowResized, false)
    }

    function render() {
      if (!camera) return
      if (!renderer) return
      if (!scene) return
      lat = Math.max(-85, Math.min(85, lat))
      phi = THREE.MathUtils.degToRad(90 - lat)
      theta = THREE.MathUtils.degToRad(lon)

      camera.position.x = 100 * Math.sin(phi) * Math.cos(theta)
      camera.position.y = 100 * Math.cos(-1 * phi)
      camera.position.z = 100 * Math.sin(phi) * Math.sin(theta)

      camera.lookAt(scene.position)
      renderer.render(scene, camera)
    }
    function animate() {
      requestAnimationFrame(animate)
      render()
    }
    function init360(url: string) {
      const beforeCanvas = domRef.current.querySelector('canvas')
      if (beforeCanvas) {
        onPointerUp()
        domRef.current.removeEventListener('pointerdown', onPointerDown, false)
        domRef.current.removeEventListener('wheel', onDocumentMouseWheel)
        beforeCanvas.remove()
      }
      const textureLoader = new THREE.TextureLoader()
      textureLoader.load(url, function (texture) {
        set_pending(false)
        texture.encoding = THREE.sRGBEncoding
        texture.mapping = THREE.EquirectangularReflectionMapping

        init(texture)
        animate()
      })
    }
    if (src) {
      set_pending(true)
      if (renderer) {
        renderer.clear()
        renderer.dispose()
        renderer.forceContextLoss()
        renderer = undefined
      }
      init360(src)
    }
    return () => {
      if (!renderer) return
      renderer.clear()
      renderer.dispose()
      renderer.forceContextLoss()
      renderer = undefined
    }
  }, [src])
  const fileName = useMemo(() => {
    return getFileNameFromUrl(_imageURL)
  }, [_imageURL])
  const createdAt = useMemo(() => {
    return getCreatedAtFromFileName(fileName)
  }, [fileName])
  return (
    <>
      <PanoramaWrap ref={domRef} className={cn({ isError })}>
        {!isError && createdAt && <div className="createdAt">{createdAt}</div>}
        {pending && <LoadingPage position="absolute" />}
        {isError && <img src={ImagesGalleryImageFail} alt="error" />}
      </PanoramaWrap>
    </>
  )
}
export default Panorama
