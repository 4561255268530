import { ImagesTableComponentCSVFile } from 'assets'
import { CSVDownloadButton } from 'components/Buttons/Buttons.styles'

import { useState } from 'react'
import S from './DownloadSpreadSheet.styles'
import { DownloadSpreadSheetProps } from './DownloadSpreadSheet.types'
import SelectFilterForSpreadSheet from './SelectFilterForSpreadSheet'

const DownloadSpreadSheet = <T extends object>({
  _css,
  ...props
}: DownloadSpreadSheetProps<T>) => {
  const [open, set_open] = useState<boolean>(false)
  return (
    <>
      {open && (
        <SelectFilterForSpreadSheet
          _open={open}
          _closeModal={(value) => set_open(value)}
          {...props}
        />
      )}
      <S.Wrap _css={_css} {...props}>
        <CSVDownloadButton onClick={() => set_open(true)}>
          <img src={ImagesTableComponentCSVFile} alt={'excel'} />
        </CSVDownloadButton>
      </S.Wrap>
    </>
  )
}
export default DownloadSpreadSheet
