import { css } from '@emotion/react'
import ApiFileUploadBox from 'components/ApiFileUploadBox'
import { GrayScaleOutline, PrimaryButton } from 'components/Buttons'
import DetailsComponents, { ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import NotFoundItem from 'components/NotFoundItem'
import RadioButton from 'components/RadioButton'
import TextArea from 'components/Textarea'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineFormatInput, {
  getFormats,
} from 'components/UnderlineInput/UnderlineFormatInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import { useAppDispatch } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  FlexColumn,
} from 'themes/Shared.styles'
import { colors } from 'themes/styles'
import callAxios, { handleError } from 'utils/callAxios'
import { ApiPartnerDetails, PARTNER_INFO_INITIAL_VALUE } from '../Partner.types'
import { EditPartnerProps } from './EditPartner.types'

const EditPartner = ({ _css, ...props }: EditPartnerProps) => {
  const { Layout: L } = S
  const { id } = useParams()
  const { inputs, handleInput, fetchInit } = useMatchInput(
    PARTNER_INFO_INITIAL_VALUE
  )
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [response] = useFetch<ApiPartnerDetails>('user', `/partner/${id}`, {
    disabled: !id,
  })
  useEffect(() => {
    if (response) {
      fetchInit({
        ...PARTNER_INFO_INITIAL_VALUE,
        id: response.id,
        name: response.name,
        phone: response.phone,
        gender: response.gender,
        residentNumber: response.personalNo,
        accountNumber: response.accountNo,
        email: response.email,
        chargeLimit: response.referralFee,
        settlementCharge: response.settlementFee,
        contractFiles: response.contracts
          ? response.contracts.map((item) => ({
              id: item.fileName,
              name: item.fileName,
              url: item.contractsUrl,
            }))
          : [],
        remarks: response.note,

        buildingInfo: true,
      })
    }
  }, [fetchInit, response])

  const handleSubmit = async () => {
    try {
      let method = (id ? 'patch' : 'post') as 'post' | 'patch'
      const postData = {
        name: inputs.name,
        phone: inputs.phone,
        gender: inputs.gender,
        personalNo: inputs.residentNumber,
        accountNo: inputs.accountNumber,
        email: inputs.email,
        referralFee: inputs.chargeLimit,
        settlementFee: inputs.settlementCharge,
        contracts: inputs.contractFiles.map((item) => item.name),
        note: inputs.remarks,
      }
      await callAxios('user')[method](`/partner${id ? `/${id}` : ''}`, {
        ...postData,
      })
      let toastMessage = id ? '수정되었습니다.' : '등록되었습니다.'
      let backwardTo = id ? `/member/partner/${id}` : `/member/partner`
      dispatch(setToast([toastMessage, 'success']))
      navigate(backwardTo)
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      <DetailsComponents
        _title={id ? '파트너 수정' : '파트너 등록'}
        _topLeftControls={
          <L.ButtonRow>
            <ToList _to="/member/partner" />
          </L.ButtonRow>
        }
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout
              _title={'기본 정보'}
              _css={css`
                div > label {
                  min-width: 100px;
                }
              `}
            >
              <FlexColumn gap={12}>
                <ContentRadiusBox gap={12}>
                  <BoxRowComponent _label={'이름'}>
                    <UnderlineInput
                      placeholder="이름 입력"
                      value={inputs.name}
                      handleValue={(value) => handleInput('name', value)}
                    />
                  </BoxRowComponent>
                  <BoxRowComponent _label={'전화번호 *'}>
                    <UnderlineFormatInput
                      format={getFormats('phone', inputs.phone)}
                      placeholder="‘-’ 제외 숫자만 입력"
                      value={inputs.phone}
                      handleValue={(value) => handleInput('phone', value)}
                    />
                  </BoxRowComponent>
                  <BoxRowComponent _label={'성별'}>
                    <RadioButton
                      _mini
                      _active={inputs.gender === 'M'}
                      onClick={() => {
                        handleInput('gender', 'M')
                      }}
                    >
                      남
                    </RadioButton>
                    <RadioButton
                      _mini
                      _active={inputs.gender === 'F'}
                      onClick={() => {
                        handleInput('gender', 'F')
                      }}
                    >
                      여
                    </RadioButton>
                  </BoxRowComponent>
                  <BoxRowComponent _label={'주민등록번호'}>
                    <UnderlineFormatInput
                      format={getFormats('residentNumber')}
                      placeholder="‘-’ 제외 숫자만 입력"
                      value={inputs.residentNumber}
                      handleValue={(value) =>
                        handleInput('residentNumber', value)
                      }
                    />
                  </BoxRowComponent>
                  <BoxRowComponent _label={'계좌번호'}>
                    <UnderlineInput
                      placeholder="(예) 국민 1234567890"
                      value={inputs.accountNumber}
                      handleValue={(value) =>
                        handleInput('accountNumber', value)
                      }
                    />
                  </BoxRowComponent>
                  <BoxRowComponent _label={'이메일'}>
                    <UnderlineInput
                      placeholder="이메일 입력(세금계산서 발행)"
                      value={inputs.email}
                      handleValue={(value) => handleInput('email', value)}
                    />
                  </BoxRowComponent>
                </ContentRadiusBox>
                <ContentRadiusBox gap={12}>
                  <BoxRowComponent _label={'소개 수수료 한도 *'}>
                    <UnderlineNumberInput
                      placeholder="한도 입력"
                      value={inputs.chargeLimit}
                      handleValue={(value) => handleInput('chargeLimit', value)}
                    />
                  </BoxRowComponent>
                  <BoxRowComponent _label={'정산수수료(%) *'}>
                    <UnderlineNumberInput
                      placeholder="정산수수료 입력"
                      value={inputs.settlementCharge}
                      handleValue={(value) =>
                        handleInput('settlementCharge', value)
                      }
                    />
                  </BoxRowComponent>
                </ContentRadiusBox>
              </FlexColumn>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _rightContents={
          <ContentBoxWrap>
            <BoxLayout _title={'기타 정보'}>
              <ContentRadiusBox>
                <BoxRowComponent _label={'계약서'} className="flex-start">
                  <ApiFileUploadBox
                    _type={'user'}
                    _fileType={'all'}
                    _maxLength={5}
                    _url={`/partner/contracts/file`}
                    _fileList={inputs.contractFiles}
                    _multiple={true}
                    _emitValue={(value) => handleInput('contractFiles', value)}
                    _method={'append'}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'비고*'} className="flex-start">
                  <TextArea
                    placeholder="1000자 이하"
                    _css={css`
                      border: none;
                      border-radius: 0px;
                      padding: 10px 0;
                      min-height: 120px;
                    `}
                    value={inputs.remarks}
                    onChange={(e) => handleInput('remarks', e.target.value)}
                  />
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout _title={'건물 정보'}>
              <NotFoundItem
                _css={css`
                  .not-found {
                    color: ${colors['Grayscale/Gray Light']};
                  }
                `}
              >
                건물 관리에서 등록, 수정이 가능합니다
              </NotFoundItem>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _bottomRightControls={
          <L.RightBottom>
            <GrayScaleOutline
              width={160}
              height={48}
              padding={`12px 0`}
              onClick={() => {
                navigate(`/member/partner${id ? `/${id}` : ''}`)
              }}
            >
              취소
            </GrayScaleOutline>
            <PrimaryButton
              _css={css`
                flex: 1;
              `}
              height={48}
              padding={`12px 0`}
              onClick={handleSubmit}
            >
              {id ? '완료' : '등록'}
            </PrimaryButton>
          </L.RightBottom>
        }
      ></DetailsComponents>
    </>
  )
}
export default EditPartner
