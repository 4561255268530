import { css } from '@emotion/react'
import { ImagesServiceCancel } from 'assets'
import { GrayScaleOutline } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import PeriodPicker from 'components/PeriodPicker'
import { PickerPeriod } from 'components/PeriodPicker/PeriodPicker.types'
import { useState } from 'react'
import {
  ContentRadiusBox,
  ContentRow,
  FlexColumn,
  FlexRow,
} from 'themes/Shared.styles'
import { HolidaysType } from '../../ServiceManagement/ServiceManagement.types'
import { LimitReservationProps } from './LimitReservation.types'

const LimitReservation = ({
  _css,
  inputs,
  handleInput,
  ...props
}: LimitReservationProps) => {
  const [period, set_period] = useState<PickerPeriod>({
    start: null,
    end: null,
  })
  return (
    <>
      <BoxLayout _title={'예약 제한'}>
        <FlexColumn gap={12}>
          <ContentRadiusBox>
            <BoxRowComponent _label={'예약 제한일'}>
              <FlexRow gap={16}>
                {[
                  { name: '매주 일요일', value: 'sunday' },
                  { name: '매주 토요일', value: 'saturday' },
                  { name: '공휴일', value: 'holiday' },
                ].map((day) => {
                  const dayValue = day.value as HolidaysType
                  return (
                    <CheckBoxRound
                      _mini
                      key={'holiday' + dayValue}
                      _active={inputs.reservationLimit.holidays.includes(
                        dayValue
                      )}
                      onClick={() => {
                        handleInput('reservationLimit', (prev) => {
                          const currentHolidays = prev.reservationLimit.holidays
                          if (currentHolidays.includes(dayValue)) {
                            return {
                              ...prev.reservationLimit,
                              holidays: currentHolidays.filter(
                                (ft) => ft !== dayValue
                              ),
                            }
                          } else {
                            return {
                              ...prev.reservationLimit,
                              holidays: [...currentHolidays, dayValue],
                            }
                          }
                        })
                      }}
                    >
                      {day.name}
                    </CheckBoxRound>
                  )
                })}
              </FlexRow>
            </BoxRowComponent>
          </ContentRadiusBox>
          <ContentRadiusBox gap={24}>
            <ContentRow>
              <FlexRow gap={24}>
                <PeriodPicker
                  _period={period}
                  _emitValue={(value) => {
                    set_period(value)
                  }}
                />
                <GrayScaleOutline
                  width={'84px'}
                  height={'40px'}
                  onClick={() => {
                    const currentPeriod = period
                    handleInput('reservationLimit', (prev) => {
                      return {
                        ...prev.reservationLimit,
                        dates: [...prev.reservationLimit.dates, currentPeriod],
                      }
                    })
                    set_period({ start: null, end: null })
                  }}
                >
                  추가
                </GrayScaleOutline>
              </FlexRow>
            </ContentRow>

            {inputs.reservationLimit.dates.length > 0 && (
              <div>
                {inputs.reservationLimit.dates.map((date, dateIndex) => {
                  return (
                    <BoxRowComponent
                      key={'limit-date-' + (date.id ? date.id : dateIndex)}
                      _label={`예약 제한일 ${dateIndex + 1}`}
                      _css={css`
                        max-width: 504px;
                        img {
                          cursor: pointer;
                        }
                      `}
                    >
                      <FlexRow gap={16}>
                        <div>
                          {date.start?.format('YYYY-MM-DD')} ~{' '}
                          {date.end?.format('YYYY-MM-DD')}
                        </div>
                      </FlexRow>
                      <img
                        src={ImagesServiceCancel}
                        alt="cancel"
                        onClick={() => {
                          handleInput('reservationLimit', (prev) => {
                            let currentPeriods = prev.reservationLimit.dates
                            currentPeriods = currentPeriods.filter(
                              (_, index) => index !== dateIndex
                            )
                            return {
                              ...prev.reservationLimit,
                              dates: currentPeriods,
                            }
                          })
                        }}
                      />
                    </BoxRowComponent>
                  )
                })}
              </div>
            )}
          </ContentRadiusBox>
        </FlexColumn>
      </BoxLayout>
    </>
  )
}
export default LimitReservation
