import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const ServiceManagementStyle = {
  Wrap: styled.div`
    background-color: ${colors['Grayscale/Background Light']};
    width: 100%;
    min-height: 100vh;
    color: ${colors['Grayscale/Gray Dark']};
    padding: 64px 0;
    & > div {
      max-width: 1270px;
      margin: 0 auto;
      min-width: 1270px;
      width: 100%;
      height: 100%;
      padding: 0;
    }
    ${addCssProps}
  `,
  ContentsWrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  TopButtons: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    & > button {
      height: 40px;
      &:first-of-type {
        width: 160px;
      }
      &:last-of-type {
        width: 160px;
      }
    }
  `,
  Content: styled.div`
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px 32px;
    border: 1px solid ${colors['Grayscale/Gray Lighter']};
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  BreadCrumbs: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    .group {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .category-chain {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    span {
      ${typography['Body/Caption/Bold']}
      color: ${colors['Primary/Default']};

      &.label {
        ${typography['Menu/Bold']}
        color: ${colors['Grayscale/Gray Default']};
      }
    }
  `,
  PriceTable: styled.div`
    input {
      border: none;
      ${typography['Body/Small/Regular']}
      color: ${colors['Grayscale/Black light']};

      &:focus {
        outline: none;
      }
    }
    border: 1px solid ${colors['Grayscale/Gray Lighter']};
    width: calc(100% - 56px);
    .row {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0;
      width: 100%;
      &.header {
        height: 40px;
      }
      &.content {
        height: 33px;
      }
      & > div,
      & > input {
        display: flex;
        align-items: center;
        flex: 1 0;
        height: 100%;
      }
      & > input {
        padding: 6px 4px;
      }
      & > div {
        position: relative;
      }
      &.header {
        & > div {
          border-left: 1px solid ${colors['Grayscale/Gray Lighter']};
          &:first-of-type {
            border-left: none;
          }
          padding: 10px 4px;
          height: 100%;
          ${typography['Body/Small/Bold']}
          color: ${colors['Grayscale/Gray Default']};
          background-color: ${colors['Grayscale/Background Light']};
          &.dropdown-header {
            color: ${colors['Primary/Default']};
            background-color: ${colors['Primary/Lighter']};
          }
        }
      }
      &.content {
        & > div,
        & > input {
          border-top: 1px solid ${colors['Grayscale/Gray Lighter']};
          border-left: 1px solid ${colors['Grayscale/Gray Lighter']};
        }
        & > div {
          &:first-of-type {
            border-left: none;
          }
        }
        & > button.delete {
          position: absolute;
          width: 24px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          right: -16px;
          transform: translateX(100%);
        }
      }
    }
  `,
}

export default ServiceManagementStyle
