import { isValidElement } from 'react'
import S from '../TopInfo.styles'
import { TopInfoRowProps } from './TopInfoRow.types'

const TopInfoRow = ({ _css, _label, _contents, ...props }: TopInfoRowProps) => {
  return (
    <S.Column _css={_css} {...props}>
      <>
        {typeof _label === 'string' && <label>{_label}</label>}
        {isValidElement(_label) && _label}
        {typeof _contents === 'string' && <div>{_contents}</div>}
        {isValidElement(_contents) && _contents}
        {!_contents && <div>-</div>}
      </>
    </S.Column>
  )
}
export default TopInfoRow
