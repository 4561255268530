import { css } from '@emotion/react'
import { IconsMessageBtn } from 'assets'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound'
import DraggablePopup from 'components/DraggablePopup'
import Textarea from 'components/Textarea'
import { useAppDispatch } from 'hooks/reduxHooks'
import { ContentPart } from 'pages/Main/components/ContentPart/ContentPart'
import AlertRow from 'pages/Work/components/AlertRow/AlertRow'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { setToast } from 'store/toastSlice'
import { FlexRow, SvgWrap, TXT } from 'themes/Shared.styles'
import { colors, typography } from 'themes/styles'
import callAxios, { handleError } from 'utils/callAxios'
import S from './MessagePopUp.styles'

export interface MessagePopUpProps {}

function lengthInUtf8Bytes(str: string) {
  // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
  var m = encodeURIComponent(str).match(/%[89ABab]/g)
  return str.length + (m ? m.length : 0)
}

type ReceiversProps = '일반회원' | '정기회원' | '숙련공' | '자재지원팀'

const receiverForApi = {
  일반회원: 'CUSTOMER',
  정기회원: 'REGULAR',
  숙련공: 'TECH',
  자재지원팀: 'ADMIN',
} as const

export const MessagePopUp = (props: MessagePopUpProps) => {
  const [isOnPopUp, set_isOnPopUp] = useState<boolean>(false)
  const [isHovered, set_isHovered] = useState<boolean>(false)
  const [receivers, set_receivers] = useState<ReceiversProps[]>([])
  const [textAreaVal, set_textAreaVal] = useState<string>('')

  const dispatch = useAppDispatch()
  // 받는 사람 선택 여부 확인
  const isReceiverContain = useCallback(
    (target: ReceiversProps) => {
      return receivers.includes(target)
    },
    [receivers]
  )
  // 받는사람 check handler
  const handleReceiver = useCallback(
    (target: ReceiversProps) => {
      if (isReceiverContain(target)) {
        const filteredArr = receivers.filter((item) => item !== target)
        set_receivers(filteredArr)
        return
      }
      set_receivers((prev) => [...prev, target])
      return
    },
    [receivers, isReceiverContain]
  )
  // 버튼 활성화 조건 체크
  const isValid = useCallback(
    (receiver: ReceiversProps[], textAreaVal: string) => {
      if (receiver.length < 1 || textAreaVal.length < 1) return false
      return true
    },
    []
  )
  // 취소
  const handleCancel = () => {
    set_isOnPopUp(false)
  }
  // 전송
  const handleSubmit = async () => {
    try {
      const postData = {
        to: receivers.map((item) => receiverForApi[item]),
        content: textAreaVal,
      }
      await callAxios('user').post(`/sms`, postData)
      dispatch(
        setToast({
          message: '문자메세지가 전송되었습니다.',
          type: 'success',
        })
      )
      set_isOnPopUp(false)
    } catch (error) {
      handleError(error)
    }
  }
  // 팝업 토글 시 값 초기화
  useEffect(() => {
    set_receivers([])
    set_textAreaVal('')
  }, [isOnPopUp])
  const bytesLength = useMemo(() => {
    return lengthInUtf8Bytes(textAreaVal)
  }, [textAreaVal])

  return (
    <>
      <DraggablePopup
        _title={'문자메세지 전송'}
        _open={isOnPopUp}
        _onClose={handleCancel}
        _css={css`
          .drag-modal-bottom .btn-wrapper {
            display: flex;
            justify-content: flex-end;
            gap: 16px;
            width: 100%;
            height: 48px;
          }
        `}
        _bottom={
          <>
            <GrayScaleFill onClick={handleCancel}>취소</GrayScaleFill>
            <PrimaryButton
              disabled={!isValid(receivers, textAreaVal)}
              onClick={handleSubmit}
            >
              확인
            </PrimaryButton>
          </>
        }
      >
        <S.ContentWrapper>
          <div className="padding-wrapper">
            <ContentPart
              _css={css`
                margin-bottom: 32px;
                .title {
                  ${typography['Body/Small/Bold']};
                  color: ${colors['Grayscale/Gray Default']};
                  margin-bottom: 12px;
                  span {
                    color: ${colors['Accent/Dark']};
                  }
                }
                .content {
                  border: none;
                }
              `}
              _title={
                <div className="title">
                  받는사람 (다중선택 가능) <span>*</span>
                </div>
              }
            >
              <div className="checkbox-wrapper">
                <div className="flex-row">
                  <CheckBoxRound
                    _mini
                    _active={isReceiverContain('일반회원')}
                    onClick={() => {
                      handleReceiver('일반회원')
                    }}
                  >
                    일반회원
                  </CheckBoxRound>
                  <CheckBoxRound
                    _mini
                    _active={isReceiverContain('정기회원')}
                    onClick={() => {
                      handleReceiver('정기회원')
                    }}
                  >
                    정기회원
                  </CheckBoxRound>
                </div>
                <div className="flex-row">
                  <CheckBoxRound
                    _mini
                    _active={isReceiverContain('숙련공')}
                    onClick={() => {
                      handleReceiver('숙련공')
                    }}
                  >
                    숙련공
                  </CheckBoxRound>
                  <CheckBoxRound
                    _mini
                    _active={isReceiverContain('자재지원팀')}
                    onClick={() => {
                      handleReceiver('자재지원팀')
                    }}
                  >
                    자재지원팀
                  </CheckBoxRound>
                </div>
              </div>
            </ContentPart>
            <ContentPart
              _css={css`
                .title {
                  ${typography['Body/Small/Bold']};
                  color: ${colors['Grayscale/Gray Default']};
                  span {
                    color: ${colors['Accent/Dark']};
                  }
                }
                .content {
                  border: none;
                }
              `}
              _title={
                <FlexRow gap={8} justifyContent={'space-between'}>
                  <div className="title">
                    내용 <span>*</span>
                  </div>
                  <TXT
                    _textStyle="Body/Small/Regular"
                    _color="Grayscale/Gray Default"
                  >
                    {bytesLength} / bytes
                  </TXT>
                </FlexRow>
              }
            >
              <Textarea
                className="textarea"
                placeholder="메세지를 입력하세요"
                value={textAreaVal}
                onChange={(e) => {
                  if (lengthInUtf8Bytes(e.target.value) <= 2000) {
                    set_textAreaVal(e.target.value)
                  }
                }}
              />
            </ContentPart>
            <AlertRow
              className="flex-start"
              _css={css`
                margin-top: 32px;
              `}
            >
              문자 내용이 80byte를 초과할 경우, MMS로 전환되어 전송됩니다. (추가
              금액 발생할 수 있음)
              <br />
              2000byte 초과 시, 문자 전송이 불가능합니다.
            </AlertRow>
          </div>
        </S.ContentWrapper>
      </DraggablePopup>
      <S.MessageBtn>
        <S.BtnWrapper
          onMouseEnter={(e) => {
            set_isHovered(true)
          }}
          onMouseLeave={(e) => {
            set_isHovered(false)
          }}
          onClick={(e) => {
            set_isOnPopUp(true)
          }}
        >
          {isHovered && <S.BubbleWrapper className="bubble"></S.BubbleWrapper>}
          <SvgWrap _size={64} _svgSize={{ width: 64, height: 64 }}>
            <IconsMessageBtn />
          </SvgWrap>
        </S.BtnWrapper>
      </S.MessageBtn>
    </>
  )
}
