import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const ServicePriceTableStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  PriceTable: styled.div`
    input {
      border: none;

      &:focus {
        outline: none;
      }
    }
    border: 1px solid ${colors['Grayscale/Gray Lighter']};
    width: 100%;
    &.etc {
      width: calc(100% - 56px);
    }
    .row {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0;
      width: 100%;
      &.header {
        height: 40px;
      }
      &.content {
        height: 33px;
      }
      & > div,
      & > input {
        display: flex;
        align-items: center;
        flex: 1 0;
        height: 100%;
        ${typography['Body/Small/Regular']}
        color: ${colors['Grayscale/Black light']};
      }
      & > input {
        padding: 6px 4px;
      }
      & > div {
        position: relative;
        &.row-content {
          padding: 6px 4px;
        }
      }
      &.header {
        & > div {
          border-left: 1px solid ${colors['Grayscale/Gray Lighter']};
          &:first-of-type {
            border-left: none;
          }
          padding: 10px 4px;
          height: 100%;
          ${typography['Body/Small/Bold']}
          color: ${colors['Grayscale/Gray Default']};
          background-color: ${colors['Grayscale/Background Light']};
          &.dropdown-header {
            color: ${colors['Primary/Default']};
            background-color: ${colors['Primary/Lighter']};
          }
        }
      }
      &.content {
        & > div,
        & > input {
          border-top: 1px solid ${colors['Grayscale/Gray Lighter']};
          border-left: 1px solid ${colors['Grayscale/Gray Lighter']};
        }
        & > div {
          &:first-of-type {
            border-left: none;
          }
        }
        & > button.delete {
          position: absolute;
          width: 24px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          right: -16px;
          transform: translateX(100%);
        }
      }
    }
  `,
}

export default ServicePriceTableStyle
