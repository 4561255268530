import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'

const EventPublishStyles = {
  NotificationBox: styled.div`
    & {
      background-color: ${colors['Grayscale/Background Light']};
      color: ${colors['Grayscale/Gray Dark']};
      width: 100%;
      ${typography['Body/Caption/Regular']};
      border-radius: 12px;
      padding: 10px 12px;
    }
    ul {
      padding-inline-start: 23px;
      margin-block-start: 4px;
      margin-block-end: 0;
    }
  `,
}

export default EventPublishStyles
