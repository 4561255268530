import { keyframes } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { ImagesGlobalLoading } from 'assets'
import { DivAttributes } from 'themes/styles.type'

const rotate360 = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(-360deg);
}`

const LoadingPageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1199;
  img {
    animation: ${rotate360} 1s infinite linear;
  }
`

interface LoadingPageProps extends DivAttributes {
  position?: 'fixed' | 'absolute'
}
const LoadingPage = ({ position = 'fixed', ...props }: LoadingPageProps) => {
  return (
    <>
      <LoadingPageWrap style={{ position }} {...props}>
        <img src={ImagesGlobalLoading} alt="Loading" />
      </LoadingPageWrap>
    </>
  )
}
export default LoadingPage
