import { css } from '@emotion/react'
import { ImagesInputSearchIcon } from 'assets'
import ApiFileUploadBox from 'components/ApiFileUploadBox'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import NotFoundItem from 'components/NotFoundItem'
import ToggleSwitch from 'components/ToggleSwitch'
import UnderlineInput from 'components/UnderlineInput'
import { clone } from 'lodash-es'
import { ContentBoxWrap, ContentRadiusBox } from 'themes/Shared.styles'
import { colors } from 'themes/styles'
import { defaultSubscribedInfo } from '../../User.types'
import { ModifyUserExtraInfoProps } from './ModifyUserExtraInfo.types'

const ModifyUserExtraInfo = ({
  _css,
  inputs,
  handlePostalCode,
  handleInput,
  ...props
}: ModifyUserExtraInfoProps) => {
  return (
    <ContentBoxWrap>
      <BoxLayout
        _title={'정기회원 전환'}
        _titleSuffix={
          <ToggleSwitch
            _mini={true}
            _active={inputs.userType === 'REGULAR'}
            onClick={() => {
              handleInput('userType', (prev) =>
                prev.userType === 'REGULAR' ? 'CUSTOMER' : 'REGULAR'
              )
            }}
          />
        }
      >
        {inputs.userType === 'REGULAR' && (
          <ContentRadiusBox gap={12}>
            <BoxRowComponent _label={'현금 영수증 발행'}>
              <UnderlineInput
                placeholder="내용 입력"
                value={inputs.subscribedCustomer?.cashReceiptCode ?? ''}
                handleValue={(value) => {
                  handleInput('subscribedCustomer', (prev) => {
                    let nextValue = {
                      ...defaultSubscribedInfo,
                      ...prev.subscribedCustomer,
                    }
                    return { ...nextValue, cashReceiptCode: value }
                  })
                }}
              />
            </BoxRowComponent>
            <BoxRowComponent _label={'주소'}>
              <UnderlineInput
                value={inputs.subscribedCustomer?.address}
                noRemoveButton
                handleValue={(value) => {}}
                after={
                  <img
                    src={ImagesInputSearchIcon}
                    alt="search"
                    onClick={() => {
                      handlePostalCode(true)
                    }}
                  />
                }
                placeholder="주소 검색"
                onFocus={() => {
                  handlePostalCode(true)
                }}
                onClick={() => {
                  handlePostalCode(true)
                }}
              />
            </BoxRowComponent>
            <BoxRowComponent _label={''}>
              <UnderlineInput
                value={inputs.subscribedCustomer?.addressDetails}
                handleValue={(value) =>
                  handleInput('subscribedCustomer', (prev) => {
                    let nextValue = {
                      ...defaultSubscribedInfo,
                      ...prev.subscribedCustomer,
                    }
                    return { ...nextValue, addressDetails: value }
                  })
                }
                placeholder="상세주소 입력"
              />
            </BoxRowComponent>
            <BoxRowComponent _label={'계약서'} className="flex-start">
              <ApiFileUploadBox
                _type="user"
                _url="/users/contracts/file"
                _fileType={'all'}
                _fileList={inputs.subscribedCustomer?.contractFiles ?? []}
                _multiple={true}
                _maxLength={5}
                _emitValue={(value) =>
                  handleInput('subscribedCustomer', (prev) => {
                    let cloned = clone(prev.subscribedCustomer)
                    if (!cloned) return cloned
                    cloned = { ...cloned, contractFiles: value }
                    return cloned
                  })
                }
                _optionValue={{
                  userId: inputs.id,
                }}
                _method={'append'}
              />
            </BoxRowComponent>
          </ContentRadiusBox>
        )}
      </BoxLayout>
      {inputs.userType === 'REGULAR' && (
        <BoxLayout _title={'건물 정보'}>
          <NotFoundItem
            _css={css`
              .not-found {
                color: ${colors['Grayscale/Gray Light']};
              }
            `}
          >
            건물 관리에서 등록, 수정이 가능합니다
          </NotFoundItem>
        </BoxLayout>
      )}
    </ContentBoxWrap>
  )
}
export default ModifyUserExtraInfo
