import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import { AddressInfoValuesType } from 'pages/Work/Work.types'

import { ContentRadiusBox } from 'themes/Shared.styles'
import {
  ELEVATOR_DROPDOWN_LIST,
  FLOORS_DROPDOWN_LIST,
  RESIDENT_TYPE_DROPDOWN_LIST,
} from 'types/services.type'
import { getHouseSize, getKeyNameByValue } from 'utils/helpers'

interface AddressInfoProps<T extends AddressInfoValuesType> {
  inputs: Pick<T, keyof AddressInfoValuesType>
}
const AddressInfo = <T extends AddressInfoValuesType>({
  inputs,
  ...props
}: AddressInfoProps<T>) => {
  return (
    <>
      <ContentRadiusBox>
        <BoxRowComponent _label={`도로명 주소`} _contents={inputs.address} />
        <BoxRowComponent _label={`지번 주소`} _contents={inputs.addressOld} />
        <BoxRowComponent
          _label={`상세 주소`}
          _contents={inputs.addressDetails}
        />
        <BoxRowComponent
          _label={`거주지 유형`}
          _contents={getKeyNameByValue(
            RESIDENT_TYPE_DROPDOWN_LIST,
            inputs.residentType
          )}
        />
        <BoxRowComponent
          _label={`건물 면적`}
          _contents={getHouseSize(inputs.buildingSize, '평').toString()}
        />
        <BoxRowComponent
          _label={`층수`}
          _contents={getKeyNameByValue(FLOORS_DROPDOWN_LIST, inputs.floors)}
        />
        <BoxRowComponent
          _label={`엘리베이터`}
          _contents={getKeyNameByValue(ELEVATOR_DROPDOWN_LIST, inputs.elevator)}
        />
      </ContentRadiusBox>
    </>
  )
}
export default AddressInfo
