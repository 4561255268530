import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const WagesForFreelancersStyle = {
  Wrap: styled.div`
    padding: 32px;
    h1 {
      ${typography['Headline/Bold']}
      color: ${colors['Grayscale/Black']};
      white-space: nowrap;
    }
    ${addCssProps}
  `,
}

export default WagesForFreelancersStyle
