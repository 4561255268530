import { IconsChipsCancel } from 'assets'
import { cn } from 'utils/helpers'
import S from './Chips.styles'
import { ChipsProps } from './Chips.types'

const Chips = ({
  _css,
  _color,
  _type,
  className,
  onClick,
  children,
  ...props
}: ChipsProps) => {
  return (
    <>
      <S.Chip
        _css={_css}
        _color={_color}
        className={cn(_type, className)}
        onClick={onClick}
        {...props}
      >
        <div>{children}</div>
        {onClick && <IconsChipsCancel />}
      </S.Chip>
    </>
  )
}
export default Chips
