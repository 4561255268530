import { css } from '@emotion/react'
import { ImagesDetailsTrashWhite } from 'assets'
import { DangerButton } from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import NotFoundItem from 'components/NotFoundItem'
import { Virtuoso } from 'react-virtuoso'
import { ContentRadiusBox, FlexRow } from 'themes/Shared.styles'
import { parseToFormattedStr } from 'utils/parsers'
import { TargetListProps } from './TargetList.types'
const TargetList = ({ _css, _list, _setList, ...props }: TargetListProps) => {
  return (
    <BoxLayout
      _title={'발급 대상자'}
      _css={css`
        height: 100%;
        & > div:last-of-type {
          height: calc(100% - 52px);
        }
      `}
    >
      {_list.length > 0 ? (
        <Virtuoso
          style={{ height: `calc(100% - 52px)`, width: '100%' }}
          totalCount={_list.length}
          itemContent={(index) => {
            const item = _list[index]
            return (
              <FlexRow
                key={'list' + item.userName}
                gap={16}
                _css={css`
                  width: 100%;
                  height: 100%;
                  margin-bottom: 12px;
                  padding-right: 10px;
                  flex: 1;
                `}
              >
                <ContentRadiusBox
                  _css={css`
                    width: 100%;
                    flex: 1;
                  `}
                >
                  <BoxRowComponent _label={'회원명'} _contents={item.name} />
                  <BoxRowComponent
                    _label={'휴대폰 번호'}
                    _contents={parseToFormattedStr(item.phone, 'phone')}
                  />
                  <BoxRowComponent
                    _label={'아이디'}
                    _contents={item.userName}
                  />
                </ContentRadiusBox>
                <DangerButton
                  _icon
                  _css={css`
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                  `}
                  onClick={() => {
                    const newUserList = _list.filter(
                      (cur) => cur.userName !== item.userName
                    )
                    _setList(newUserList)
                  }}
                >
                  <img src={ImagesDetailsTrashWhite} alt="삭제" />
                </DangerButton>
              </FlexRow>
            )
          }}
        />
      ) : (
        <NotFoundItem
          _css={css`
            align-items: flex-start;
          `}
        >
          발급 대상자를 추가해 주세요.
        </NotFoundItem>
      )}
    </BoxLayout>
  )
}
export default TargetList
