import styled from '@emotion/styled/macro'
import { RoundBox } from 'components/ContentsBox/ContentsBox.styles'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const ContentPartStyle = {
  Wrap: styled.div`
    width: 100%;
    ${addCssProps}
  `,
  Title: styled.div`
    ${typography['Headline/Bold']};
    ${colors['Grayscale/Black light']};
    margin-bottom: 16px;
  `,
  Content: styled(RoundBox)`
    width: 100%;
  `,
}
export default ContentPartStyle
