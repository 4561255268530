import {
  ImagesDropdownCellChevronDown,
  ImagesDropdownCellChevronUp,
} from 'assets'
import useOutsideEvent from 'hooks/useOutsideEvent'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { cn, uuid } from 'utils/helpers'
import { dropdownListOnkeyDown } from '../Dropdown.helpers'
import S from './ServiceDropdown.styles'
import { ServiceDropdownProps } from './ServiceDropdown.types'

const ServiceDropdown = ({
  _css,
  _value,
  _list,
  _emitValue,
  className,
  _placeholder = '선택',
  _type = 'th',
  _disabled,
  _changeList,
  _appendList,
  _inputProps,
  _dataIndex,
  ...props
}: ServiceDropdownProps) => {
  const listRef = useRef() as MutableRefObject<HTMLDivElement>
  const wrapRef = useRef() as MutableRefObject<HTMLDivElement>
  const [label, set_label] = useState<string | number | null>(null)

  const [open, set_open] = useState<string[]>()

  useEffect(() => {
    if (_value) {
      set_label(_value)
    } else {
      set_label(null)
    }
  }, [_list, _value])

  const uuidRef = useRef('cell_' + uuid()) as MutableRefObject<string>

  useOutsideEvent(`#${uuidRef.current}`, () => {
    set_open(undefined)
  })

  const handleChange = (newLabel: string) => {
    let changed = [..._list]
    if (_changeList) {
      if (newLabel) {
        if (_value) {
          changed = changed.map((item) => {
            return item === _value ? newLabel : item
          })
        } else {
          changed = [...changed, newLabel]
        }
      } else {
        changed = changed.filter((item) => item !== _value)
      }
      _changeList(changed, {
        prev: _value ?? '',
        next: newLabel,
      })
    }
  }
  return (
    <>
      <S.Wrap
        ref={wrapRef}
        id={uuidRef.current}
        _css={_css}
        className={cn({ active: open, disabled: _disabled }, className)}
        {...props}
      >
        <S.Label
          className={cn(
            {
              placeholder: !label,
              active: open,
              // selected: !!label,
            },
            'label-box'
          )}
        >
          <input
            value={label ?? ''}
            onFocus={() => set_open(undefined)}
            onChange={(e) => {
              set_open(undefined)
              set_label(e.target.value)
            }}
            onBlur={(e) => {
              const newLabel = e.currentTarget.value.trim()
              handleChange(newLabel)
            }}
            onKeyDown={(e) => {
              const newLabel = e.currentTarget.value.trim()
              if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                handleChange(newLabel)
              }
            }}
            placeholder={_placeholder}
            data-index={_dataIndex}
            {..._inputProps}
          />
          <img
            src={ImagesDropdownCellChevronDown}
            alt="closed"
            className={cn({ hide: open })}
            onClick={(e) => {
              set_open((prev) => (prev ? undefined : _list))
            }}
          />
          <img
            src={ImagesDropdownCellChevronUp}
            alt="opened"
            className={cn({ hide: !open })}
            onClick={(e) => {
              set_open((prev) => (prev ? undefined : _list))
            }}
          />
        </S.Label>
        {open && (
          <S.List>
            <div ref={listRef}>
              {open.map((keyName) => {
                return (
                  <S.Item
                    tabIndex={0}
                    key={keyName}
                    className="dropdown-item"
                    data-active={_value === keyName ? 'active' : 'inactive'}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (_emitValue) _emitValue(keyName)
                      set_open(undefined)
                      if (wrapRef.current) {
                        wrapRef.current.focus()
                      }
                    }}
                    onKeyDown={(e) => {
                      dropdownListOnkeyDown(e, listRef.current, (e) => {
                        if (e.code === 'Enter' && open) {
                          if (_emitValue) _emitValue(keyName)
                          set_open(undefined)
                          if (wrapRef.current) {
                            wrapRef.current.focus()
                          }
                        }
                      })
                    }}
                  >
                    {keyName}
                  </S.Item>
                )
              })}
              <S.Item
                className="option"
                onClick={(e) => {
                  e.stopPropagation()
                  set_open(undefined)
                  if (_appendList) _appendList()
                }}
              >
                옵션 추가
              </S.Item>
            </div>
          </S.List>
        )}
      </S.Wrap>
    </>
  )
}
export default ServiceDropdown
