import { getDoms } from 'utils/helpers'

export const dropdownWrapperOnkeyDown = (
  e: React.KeyboardEvent<HTMLDivElement>,
  El: HTMLDivElement,
  callback?: (e: React.KeyboardEvent<HTMLDivElement>) => void
) => {
  e.stopPropagation()
  if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
    e.preventDefault()
    if (El) {
      const childrenElements = El.querySelectorAll('.dropdown-item')
      if (childrenElements.length > 0) {
        getDoms(childrenElements)
        const Items = getDoms(childrenElements)
        if (e.code === 'ArrowDown') {
          Items[0].focus()
        }
        if (e.code === 'ArrowUp') {
          Items[childrenElements.length - 1].focus()
        }
      }
    }
  }
  if (callback) callback(e)
}

export const dropdownListOnkeyDown = (
  e: React.KeyboardEvent<HTMLDivElement>,
  El: HTMLDivElement,
  callback?: (e: React.KeyboardEvent<HTMLDivElement>) => void
) => {
  e.stopPropagation()
  if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
    e.preventDefault()
    if (El) {
      const childrenElements = El.querySelectorAll('.dropdown-item')
      if (childrenElements.length > 0) {
        const Items = getDoms(childrenElements)
        const currentIndex = Items.indexOf(
          document.activeElement! as HTMLDivElement
        )
        if (e.code === 'ArrowDown') {
          let nextIndex = currentIndex + 1
          if (nextIndex > Items.length - 1) {
            nextIndex = 0
          }
          Items[nextIndex].focus()
        }
        if (e.code === 'ArrowUp') {
          let prevIndex = currentIndex - 1
          if (prevIndex < 0) {
            prevIndex = Items.length - 1
          }
          Items[prevIndex].focus()
        }
      }
    }
  }
  if (callback) callback(e)
}
