import { BADGE_COLOR } from 'components/Badge/Badge'

export const UNPAID_ORDER_STATE_DROPDOWN_LIST = {
  주문완료: '주문완료',
  시공대기: '시공대기',
  시공중: '시공중',
  취소요청: '취소요청',
  시공완료: '시공완료',
  시공취소: '시공취소',
  정산완료: '정산완료',
} as const
export type OrderStatesType = KeyOf<typeof UNPAID_ORDER_STATE_DROPDOWN_LIST>

export const UNPAID_USER_TYPE_DROPDOWN_LIST = {
  일반회원: '일반회원',
  정기회원: '정기회원',
} as const
export type UserType = KeyOf<typeof UNPAID_USER_TYPE_DROPDOWN_LIST>

export const UNPAID_ORDER_PATH_DROPDOWN_LIST = {
  블로그: '1',
  당근: '2',
  숨고: '3',
  지인: '4',
  앱주문: '5',
}
export type OrderPathKeyType = KeyOf<typeof UNPAID_ORDER_PATH_DROPDOWN_LIST>

export const UNPAID_BADGE_COLOR_SET = {
  주문완료: BADGE_COLOR['AccentLight'],
  시공대기: BADGE_COLOR['PrimaryLight'],
  시공중: BADGE_COLOR['SuccessLight'],
  취소요청: BADGE_COLOR['Danger'],
  시공완료: BADGE_COLOR['PrimaryFill'],
  시공취소: BADGE_COLOR['Gray'],
  정산완료: BADGE_COLOR['DangerFill'],
} as const
export const UNPAID_QUERY_DROPDOWN_LIST = {
  전화번호: '전화번호',
  주문자명: '주문자명',
  담당자명: '담당자명',
  서비스명: '서비스명',
} as const

export const NOTICE_FILTER_DROPDOWN_LIST = {
  전체: 'all',
  '회원 지정': 'user-select',
  '코드 발급': 'code-issue',
} as const

export type ApiUnpaid = {
  id: string
  cursor: number
  status:
    | '주문완료'
    | '시공 대기'
    | '시공중'
    | '시공 취소'
    | '시공 완료'
    | '취소요청'
  customerType: 'CUSTOMER' | 'REGULAR'
  orderFunnel: string
  createAt: string
  issuer: string
  customerPhone: string
  customerName: string
  serviceName: string
}
