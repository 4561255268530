import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const MiniPopupStyle = {
  Wrap: styled.div`
    padding: 40px 24px 24px;
    width: 312px;
    min-height: 200px;
    max-width: 312px;
    ${addCssProps}
  `,
  ModalPosition: styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  `,
  Contents: styled.div``,
  Bottom: styled.div`
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    & > button {
      flex: 1;
      padding: 12px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
  Title: styled.div`
    ${typography['Headline/Bold']}
    color: ${colors['Grayscale/Black']};
    text-align: center;
  `,
  Body: styled.div`
    margin-top: 12px;
    ${typography['Body/Small/Regular']}
    color: ${colors['Grayscale/Gray Default']};
    text-align: center;
    white-space: pre-line;
  `,
}
export default MiniPopupStyle
