import Badge, { BADGE_COLOR } from 'components/Badge/Badge'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import dayjs from 'dayjs'
import { PaymentInfoValuesType } from 'pages/Work/Work.types'
import { useMemo } from 'react'
import { ContentRadiusBox, Divider, FlexRow, TXT } from 'themes/Shared.styles'
import { payMethodString, thousand } from 'utils/helpers'

interface PaymentInfoProps<T extends PaymentInfoValuesType> {
  inputs: Pick<T, keyof PaymentInfoValuesType>
}
const PaymentInfo = <T extends PaymentInfoValuesType>({
  inputs,
  ...props
}: PaymentInfoProps<T>) => {
  const vbankInfo = useMemo(() => {
    return inputs.paymentInfo?.vbankInfo
  }, [inputs.paymentInfo])
  const cardInfo = useMemo(() => {
    return inputs.paymentInfo?.cardInfo
  }, [inputs.paymentInfo])
  if (!inputs.paymentInfo) return <></>
  return (
    <>
      <ContentRadiusBox>
        {!vbankInfo && !cardInfo && inputs.paymentInfo.payMethod === null && (
          <>
            <BoxRowComponent
              _label={`결제일`}
              _contents={
                inputs.paymentInfo.paidAt
                  ? dayjs(inputs.paymentInfo.paidAt).format('YYYY-MM-DD HH:mm')
                  : ''
              }
            />
            <BoxRowComponent
              _label={`결제자 명`}
              _contents={inputs.paymentInfo.buyerName}
            />
            <Divider />
          </>
        )}
        {vbankInfo && (
          <>
            {!inputs.paymentInfo.paidAt && (
              <BoxRowComponent
                _label={`입금 기한`}
                _contents={
                  vbankInfo.vbankExpiredAt
                    ? dayjs(vbankInfo.vbankExpiredAt).format('YYYY-MM-DD HH:mm')
                    : ''
                }
              />
            )}
            <BoxRowComponent
              _label={`결제 코드`}
              _contents={inputs.paymentInfo.merchantUid}
            />
            <BoxRowComponent
              _label={`가상 계좌 은행`}
              _contents={vbankInfo.vbankName}
            />
            <BoxRowComponent
              _label={`가상 계좌 번호`}
              _contents={vbankInfo.vbankNum}
            />
            <BoxRowComponent
              _label={`가상 계좌 예금주`}
              _contents={vbankInfo.holder}
            />
            <Divider />
          </>
        )}
        {cardInfo && (
          <>
            <BoxRowComponent
              _label={`결제 방식`}
              _contents={`${payMethodString(
                inputs.paymentInfo?.payMethod ?? null
              )}${
                cardInfo.cardQuota === 0
                  ? '(일시불)'
                  : `(할부 ${cardInfo.cardQuota}개월`
              }`}
            />
            <BoxRowComponent
              _label={`결제 코드`}
              _contents={inputs.paymentInfo.merchantUid}
            />
            <Divider />
            <BoxRowComponent
              _label={`승인 카드`}
              _contents={cardInfo.cardName}
            />
            <BoxRowComponent
              _label={`카드 명의자`}
              _contents={cardInfo.buyerName}
            />
            <BoxRowComponent
              _label={`승인 번호`}
              _contents={cardInfo.applyNum}
            />
            <Divider />
          </>
        )}
        <BoxRowComponent
          _label={`상품 금액`}
          _contents={`${thousand(inputs.paymentInfo.servicePrice)}원`}
        />
        <BoxRowComponent
          _label={`출장비`}
          _emptyCheck={inputs.paymentInfo.dispatchPrice !== 0}
          _emptyType="hide"
          _contents={`${thousand(inputs.paymentInfo.dispatchPrice)}원`}
        />
        {inputs.paymentInfo.taskAdditional.map((item, itemIndex) => {
          return (
            <BoxRowComponent
              key={'taskAdditional_' + itemIndex}
              _label={item.taskName}
              _emptyCheck={item.price !== 0}
              _emptyType="hide"
              _contents={`${thousand(item.price)}원`}
            />
          )
        })}
        <BoxRowComponent
          _label={`쿠폰 할인`}
          _emptyCheck={inputs.paymentInfo.couponDiscount !== 0}
          _emptyType="hide"
          _contents={`- ${thousand(inputs.paymentInfo.couponDiscount)}원`}
        />
        <BoxRowComponent
          _label={`포인트 할인`}
          _emptyCheck={inputs.paymentInfo.usePoint !== 0}
          _emptyType="hide"
          _contents={`- ${thousand(inputs.paymentInfo.usePoint)}원`}
        />
        <Divider />
        <BoxRowComponent
          _label={<S.TotalLabel>주문 금액</S.TotalLabel>}
          _contents={
            <S.TotalPrice>
              {thousand(inputs.paymentInfo.totalPrice)}원
            </S.TotalPrice>
          }
        />
        <FlexRow gap={4} justifyContent={'space-between'}>
          <S.PointBadgeBox>
            <Badge {...BADGE_COLOR['PrimaryLight']}>계산식</Badge>
            <TXT
              _color="Primary/Default"
              _textStyle="Body/Caption/Regular"
            >{`{(상품금액 - 쿠폰,포인트 할인) * 1.1(VAT)} + 출장비`}</TXT>
          </S.PointBadgeBox>
          <S.PointBadgeBox>
            <S.PointBadge>적립예정</S.PointBadge>
            <div>
              {thousand(inputs.paymentInfo.savePoint)}
              {'원 '}
              (0.1%)
            </div>
          </S.PointBadgeBox>
        </FlexRow>
      </ContentRadiusBox>
    </>
  )
}
export default PaymentInfo
