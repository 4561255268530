import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '.'

export const AUTH_STEP_PROCESS = [
  'login',
  'join',
  'cert',
  'find',
  'password',
] as const
export type StepType = typeof AUTH_STEP_PROCESS[number]
export const isStepType = (value: string | null): value is StepType => {
  return (
    value !== undefined &&
    value !== null &&
    AUTH_STEP_PROCESS.some((item) => item === value)
  )
}

export interface SignConfigValue {
  username: string
  optionAgreements: string[] | null
  imp_uid: string
}

interface signState {
  userInfo: SignConfigValue
  step: StepType
}
const initialState: signState = {
  userInfo: {
    username: '',
    optionAgreements: null,
    imp_uid: '',
  },
  step: 'login',
}

export const signSlice = createSlice({
  name: 'sign',
  initialState,
  reducers: {
    setSign: (state, action: { payload: SignConfigValue }) => {
      state.userInfo = action.payload
    },
    setUid: (state, action: { payload: string }) => {
      state.userInfo.imp_uid = action.payload
    },
    setAgreements: (state, action: { payload: string[] }) => {
      state.userInfo.optionAgreements = action.payload
    },
    setUsername: (state, action: { payload: string }) => {
      state.userInfo.username = action.payload
    },
    setStep: (state, action: { payload: StepType }) => {
      state.step = action.payload
    },
    cleanSign: (state) => {
      state.userInfo = {
        username: '',
        optionAgreements: null,
        imp_uid: '',
      }
      state.step = 'login'
    },
  },
})
export const {
  setSign,
  setUid,
  setUsername,
  setAgreements,
  setStep,
  cleanSign,
} = signSlice.actions

export const selectSign = (state: RootState) => state.sign.userInfo
export const selectStep = (state: RootState) => state.sign.step

export default signSlice.reducer
