import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const DownloadSpreadSheetStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  Filter: styled.div`
    width: 100%;
    & > div {
      width: 100%;
    }
  `,
  Contents: styled.div`
    width: 650px;
    padding: 32px 32px 0;
    h4 {
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Default']};
      text-align: left;
    }
  `,
  FieldsColumn: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    & > div {
      min-width: 139px;
      text-align: left;
    }
  `,
  PeriodWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    ${typography['Body/Large/Bold']}
    color: ${colors['Grayscale/Gray Default']};
  `,
}

export default DownloadSpreadSheetStyle
