import { useCallback, useEffect, useState } from 'react'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'

export const useBlock = (when: boolean = true) => {
  const blocker = useBlocker(when)
  const [showPrompt, setShowPrompt] = useState<boolean>(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false)

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false)
  }, [])

  useEffect(() => {
    if (when)
      window.onbeforeunload = (event) => {
        event.preventDefault()
        return (event.returnValue = `변경사항이 저장되지 않을 수 있습니다!`)
      }

    return () => {
      window.onbeforeunload = null
      setConfirmedNavigation(false)
      setShowPrompt(false)
    }
  }, [when])
  const confirmNavigation = useCallback((callback?: () => void) => {
    setShowPrompt(false)
    setConfirmedNavigation(true)
    if (callback) {
      setTimeout(() => {
        callback()
      }, 0)
    }
  }, [])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setShowPrompt(true)
      if (confirmedNavigation) {
        blocker.proceed()
      }
    }
  }, [blocker, confirmedNavigation])
  return { showPrompt, confirmNavigation, cancelNavigation }
}
