import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { TechsDefaultType } from '../../SelectTeamPopup/SelectTeamPopup.types'
import { TechsListItemTypeForSelect } from '../WorkerAssignmentTab.types'

export type DaySchedulesType = {
  id?: number | null
  currentDay: string
  times: {
    start: string
    end: string
  }
  techs: TechsDefaultType[]
}
export const INITIAL_DAY_SCHEDULE = {
  currentDay: '',
  times: {
    start: '',
    end: '',
  },
  techs: [],
}
export type AssignedSchedulesType = {
  daySchedules: DaySchedulesType[]
  remarks: string
}

export type TechsType = '실측' | '자재' | '시공'

export const tableConfigsForMeasurementOrServiceTechs: TableCellConfig<TechsListItemTypeForSelect>[] =
  [
    {
      _type: 'default',
      _id: 'selected',
      _label: '',
      _size: 32,
    },
    {
      _type: 'default',
      _id: 'name',
      _label: '담당자명',
      _size: 80,
    },
    {
      _type: 'default',
      _id: 'company',
      _label: '소속',
      _size: 98,
    },
    {
      _type: 'default',
      _id: 'workable',
      _label: '근무 여부',
      _size: 128,
    },
    {
      _type: 'default',
      _id: 'todayWork',
      _label: '일정',
      _size: 74,
    },
    {
      _type: 'default',
      _id: 'duty',
      _label: '실측 가능 업무',
      _size: 'auto',
    },
    {
      _type: 'default',
      _id: 'dispatchArea',
      _label: '파견 가능 지역',
      _size: 264,
      _position: 'left',
    },
  ]

export const tableConfigsForMaterialTechs: TableCellConfig<TechsListItemTypeForSelect>[] =
  [
    {
      _type: 'default',
      _id: 'selected',
      _label: '',
      _size: 32,
    },
    {
      _type: 'default',
      _id: 'name',
      _label: '담당자명',
      _size: 'auto',
    },
    {
      _type: 'default',
      _id: 'company',
      _label: '소속',
      _size: 'auto',
    },
    {
      _type: 'default',
      _id: 'todayWork',
      _label: '일정',
      _size: 'auto',
      _position: 'left',
    },
  ]
export interface AssignTeamPopupProps extends DivAttributes {
  _css?: AdditionalCss
  _open: boolean
  _onClose: () => void
  _id?: string
  _data?: AssignedSchedulesType
  _emitValue: (value: AssignedSchedulesType) => void
  _type: TechsType
}
