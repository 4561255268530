import { useParams } from 'react-router-dom'
import AreaDetails from './AreaDetails'
import AreaList from './AreaList'

const Area = () => {
  const { id } = useParams<'id'>()
  return (
    <>
      {id && <AreaDetails _id={id} />}
      {!id && <AreaList />}
    </>
  )
}
export default Area
