import { DropdownListType } from 'components/Dropdown/Dropdown.types'

export type DoubleDFile = {
  id: string
  name: string
  url: string
  file?: File
}
export const FREE_PARKING_DROPDOWN_LIST = {
  가능: '가능',
  불가능: '불가능',
} as const
export const ROOM_SIZE_DROPDOWN_LIST = Array.from(Array(60 / 5).keys()).reduce(
  (prev, curr) => {
    let num = curr * 5
    let plusFive = num + 5
    prev[`${num + 1} ~ ${plusFive}평`] = curr + 1
    return prev
  },
  {} as DropdownListType
)
export const REQUIRED_DROPDOWN_LIST = {
  필요: 1,
  필요없음: 2,
} as const
export const LIVE_IN_DROPDOWN_LIST = {
  거주중: 1,
  공실: 2,
} as const
export const HAS_PASSWORD_DROPDOWN_LIST = {
  모름: 1,
  '알고 있음': 2,
} as const
export const COMMUNICATION_METHODS_DROPDOWN_LIST = {
  '오시면 전화 주세요.': '오시면 전화 주세요.',
  '호출 및 초인종을 눌러주세요.': '호출 및 초인종을 눌러주세요.',
} as const
export const SAMPLE_CATEGORY_LIST = {
  천장: 1,
  청소: 2,
  도배: 3,
  타일: 4,
} as const
export const FUNNELS_DROPDOWN_LIST = {
  '숨고 주문': 1,
  '전화 주문': 2,
  '기타 주문': 3,
} as const

export const CUSTOMER_TYPE_DROPDOWN_LIST = {
  '일반 회원': 'CUSTOMER',
  '정기 회원': 'REGULAR',
} as const
export const RESIDENT_TYPE_DROPDOWN_LIST = {
  아파트: '아파트',
  오피스텔: '오피스텔',
  빌라: '빌라',
  주택: '주택',
} as const

export const FLOORS_DROPDOWN_LIST = {
  '1~2층': 1,
  '3층 이상': 2,
} as const

export const ELEVATOR_DROPDOWN_LIST = {
  있음: 1,
  없음: 2,
} as const

export const ROOM_TYPE_DROPDOWN_LIST = {
  원룸: '원룸',
  투룸: '투룸',
  미니투룸: '미니투룸',
  쓰리룸: '쓰리룸',
} as const

export const IS_LIVING_DROPDOWN_LIST = {
  공실: 1,
  거주중: 2,
} as const
