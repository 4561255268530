import { css } from '@emotion/react'
import { ImagesDetailsChevronUp } from 'assets'
import { PrimaryOutlineButton } from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'

import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'

import { OrderSelectIdsType, OtherServicesType } from 'pages/Work/Work.types'

import { workStatusToKorean } from 'hooks/useAllMatch'
import { Fragment, MutableRefObject, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ContentRadiusBox, FlexColumn } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import { getKeyNameByValue, handleCollapseToChild } from 'utils/helpers'
import OrderInfoWithCustomerInfo from '../OrderInfoWithCustomerInfo'
import OrderItem from '../OrderItem'
import { OrderGroupWrapper } from '../OrderItem/OrderItem.styles'
import OtherServices from '../OtherServices'
import { OrderInfoTabProps } from './OrderInfoTab.types'

const OrderInfoTab = ({
  _id,
  _type,
  _css,
  _cancelPaymentState,
  _cancelServiceState,
  _orderItem,
  _selected: selected,
  _emitValue,
  _handleOrderItems,
  inputs,
  ...props
}: OrderInfoTabProps) => {
  const cancelState = useMemo(() => {
    return !!_cancelPaymentState || !!_cancelServiceState
  }, [_cancelPaymentState, _cancelServiceState])
  const navigate = useNavigate()
  const { main, additional, loadService } = _orderItem
  const [openLoadService, set_openLoadService] = useState<string>('')
  const collapsedTargetRef = useRef() as MutableRefObject<HTMLDivElement>
  const toggleSelect = (
    type: keyof OrderSelectIdsType,
    value: string | number
  ) => {
    const toggled = selected[type].includes(value)
      ? selected[type].filter((item) => item !== value)
      : [...selected[type], value]
    _emitValue({
      ...selected,
      [type]: toggled,
      ...(type === 'rejectIds' && {
        selectIds: selected.selectIds.filter((item) => item !== value),
      }),
    })
  }
  const handleEditWithOtherServices = async (value: OtherServicesType[]) => {
    try {
      const putData = {
        orderIds: value.map((item) => item.id),
      }
      await callAxios('task').put(
        `/order/${inputs.orderId}/seeTogether`,
        putData
      )
      if (_handleOrderItems) {
        _handleOrderItems({ ..._orderItem, loadService: value })
        setTimeout(() => {
          const scrollingElement =
            document.querySelector('#left-contents')?.children[0]
          if (scrollingElement)
            scrollingElement.scrollTo(0, scrollingElement.scrollHeight)
        }, 0)
      }
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      {!cancelState && <OrderInfoWithCustomerInfo inputs={inputs} />}
      {openLoadService && (
        <OtherServices
          _id={openLoadService}
          _selectedItem={loadService ?? []}
          _emitValue={handleEditWithOtherServices}
          _onClose={() => {
            set_openLoadService('')
          }}
        />
      )}
      <>
        <BoxLayout _title={`주문 서비스`}>
          <OrderGroupWrapper>
            <OrderItem
              _item={main}
              _noCancellation={main.noCancellation}
              _forPayment={_cancelPaymentState}
              key={main.id}
              _checkAble={cancelState}
              _selected={selected}
              onCheck={(type) => {
                if (main.taskId) toggleSelect(type, main.taskId)
              }}
            />
          </OrderGroupWrapper>
        </BoxLayout>
        {additional && additional.length > 0 && (
          <BoxLayout
            _title={`함께 주문한 서비스`}
            _checkAble={cancelState}
            _titleSuffix={
              <img
                src={ImagesDetailsChevronUp}
                alt="collapsed"
                onClick={(e) => {
                  handleCollapseToChild(e, collapsedTargetRef.current)
                }}
              />
            }
          >
            <S.CollapseWrap ref={collapsedTargetRef}>
              {additional.map((item, itemIndex) => {
                return (
                  <OrderItem
                    _item={item}
                    _noCancellation={item.noCancellation}
                    _forPayment={_cancelPaymentState}
                    key={'additional' + item.id + itemIndex}
                    _checkAble={cancelState}
                    _selected={selected}
                    onCheck={(type) => {
                      if (item.taskId) toggleSelect(type, item.taskId)
                    }}
                    onClick={() => {
                      if (cancelState || !item.taskId || !item.status) return
                      navigate(
                        _type === 'unpaid'
                          ? `/unpaid/${item.taskId}`
                          : `/work/${getKeyNameByValue(
                              workStatusToKorean,
                              item.status
                            )}/${item.taskId}`
                      )
                    }}
                  />
                )
              })}
            </S.CollapseWrap>
          </BoxLayout>
        )}
      </>
      {!cancelState && _handleOrderItems && (
        <BoxLayout _title={`서비스 같이보기`}>
          <ContentRadiusBox>
            <BoxRowComponent
              _label={'주문 같이보기'}
              _contents={
                <PrimaryOutlineButton
                  _css={css`
                    padding: 5px 0;
                    flex: 1;
                  `}
                  disabled={inputs.seeTogetherCount === 0}
                  onClick={async () => {
                    set_openLoadService(inputs.orderId)
                  }}
                >
                  주문자 결제건 불러오기
                </PrimaryOutlineButton>
              }
            />
          </ContentRadiusBox>
          {loadService && loadService.length > 0 && (
            <FlexColumn
              gap={12}
              _css={css`
                margin-top: 12px;
                & > div {
                  width: 100%;
                }
              `}
            >
              {loadService.map((loadData, loadDataIndex) => {
                return (
                  <Fragment key={loadData.id + loadDataIndex}>
                    {loadData.items.map((item) => {
                      return <OrderItem _item={item} key={item.id} />
                    })}
                  </Fragment>
                )
              })}
            </FlexColumn>
          )}
        </BoxLayout>
      )}
    </>
  )
}
export default OrderInfoTab
