import { css } from '@emotion/react'
import { GrayScaleOutline } from 'components/Buttons'
import { useNavigate } from 'react-router-dom'

interface ToListProps {
  _to: string
}
const ToList = ({ _to, ...props }: ToListProps) => {
  const navigate = useNavigate()
  return (
    <>
      <GrayScaleOutline
        _mini
        _css={css`
          padding: 5px 12px;
        `}
        onClick={() => {
          navigate(_to)
        }}
      >
        목록
      </GrayScaleOutline>
    </>
  )
}
export default ToList
