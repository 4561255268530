export const disposeTargetCursor = <T>(
  arr: T[],
  cursorName: string,
  target: keyof T
): string => {
  return arr.length > 0 ? `&${cursorName}=${arr[arr.length - 1][target]}` : ''
}

export const disposeCursor = (cursor: DoubleDCursor, query = 'cursor') => {
  return cursor ? `${query}=${cursor}` : null
}
export const handleCursor = (
  cursorName: string,
  value: string | number | null
) => {
  return value != null ? `&${cursorName}=${value}` : ''
}
