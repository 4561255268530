import { css } from '@emotion/react'
import { ImagesServiceChevronRight } from 'assets'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { colors } from 'themes/styles'
import { thousand } from 'utils/helpers'
import { UserDetailsExtraInfoReadOnlyProps } from './UserDetailsExtraInfoReadOnly.types'

const UserDetailsExtraInfoReadOnly = ({
  _css,
  inputs,
  handleModal,
  ...props
}: UserDetailsExtraInfoReadOnlyProps) => {
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  return (
    <>
      <ContentBoxWrap>
        {inputs.subscribedCustomer && inputs.userType === 'REGULAR' && (
          <BoxLayout _title={'건물정보'}>
            <ContentRadiusBox>
              <BoxRowComponent _label={'건물'}>
                <FlexRow
                  gap={8}
                  justifyContent="flex-end"
                  onClick={() => {
                    navigate(
                      `/member/building?searchType=회원명&searchValue=${inputs.name}`
                    )
                  }}
                >
                  <TXT _textStyle="Body/Large/Bold" _color="Primary/Default">
                    정보 보기
                  </TXT>
                  <img src={ImagesServiceChevronRight} alt="right" />
                </FlexRow>
              </BoxRowComponent>
            </ContentRadiusBox>
          </BoxLayout>
        )}
        <BoxLayout _title={'기타정보'}>
          <ContentRadiusBox>
            <BoxRowComponent
              _label={'보유 쿠폰'}
              _css={css`
                img {
                  cursor: pointer;
                }
              `}
            >
              {inputs.etc.couponCount ? (
                <FlexRow gap={8} justifyContent="flex-end">
                  {thousand(inputs.etc.couponCount ?? 0)}개
                  <img
                    src={ImagesServiceChevronRight}
                    alt="right"
                    onClick={() => {
                      if (!id) return
                      handleModal({
                        _id: id,
                        _title: '보유 쿠폰',
                        _sub: `${thousand(inputs.etc.couponCount ?? 0)}장`,
                        _type: 'coupon',
                      })
                    }}
                  />
                </FlexRow>
              ) : null}
            </BoxRowComponent>
            <BoxRowComponent
              _label={'보유 포인트'}
              _css={css`
                img {
                  cursor: pointer;
                }
              `}
            >
              {inputs.etc.pointAmount ? (
                <FlexRow
                  gap={8}
                  justifyContent="flex-end"
                  onClick={() => {
                    if (!id) return
                    handleModal({
                      _id: id,
                      _title: '보유 포인트',
                      _sub: `${thousand(inputs.etc.pointAmount ?? 0)}원`,
                      _type: 'point',
                      _data: inputs.etc.moneyAmount,
                    })
                  }}
                >
                  {thousand(inputs.etc.pointAmount ?? 0)}원
                  <img src={ImagesServiceChevronRight} alt="right" />
                </FlexRow>
              ) : null}
            </BoxRowComponent>
            <BoxRowComponent
              _label={'추천인'}
              _css={css`
                img {
                  cursor: pointer;
                }
              `}
            >
              {inputs.etc.referer ? (
                <FlexRow
                  gap={8}
                  justifyContent="flex-end"
                  onClick={() => {
                    if (!id) return
                    handleModal({
                      _id: id,
                      _title: '추천인',
                      _sub: `${thousand(inputs.etc.referer ?? 0)}명`,
                      _type: 'referer',
                    })
                  }}
                >
                  {thousand(inputs.etc.referer ?? 0)}명
                  <img src={ImagesServiceChevronRight} alt="right" />
                </FlexRow>
              ) : null}
            </BoxRowComponent>

            {inputs.subscribedCustomer && inputs.userType === 'REGULAR' && (
              <BoxRowComponent
                _label={'계약서'}
                _css={css`
                  align-items: flex-start;
                  & > label {
                    padding-top: 10px;
                  }
                `}
              >
                {inputs.subscribedCustomer.contractFiles.length ? (
                  <FlexColumn
                    gap={12}
                    alignItems="flex-end"
                    _css={css`
                      padding-top: 7px;
                      text-align: right;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      & > div {
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                      & > div a {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: ${colors['Primary/Default']};
                        text-decoration: underline;
                        white-space: nowrap;
                      }
                    `}
                  >
                    {inputs.subscribedCustomer.contractFiles.map((item) => {
                      return (
                        <div key={item.id}>
                          <Link to={item.url}>{item.name}</Link>
                        </div>
                      )
                    })}
                  </FlexColumn>
                ) : (
                  <>-</>
                )}
              </BoxRowComponent>
            )}
          </ContentRadiusBox>
          {inputs.etc.remarks && (
            <ContentRadiusBox
              className="question"
              _css={css`
                margin-top: 12px !important;
              `}
            >
              <BoxRowComponent _label={'비고'} _contents={inputs.etc.remarks} />
            </ContentRadiusBox>
          )}
        </BoxLayout>
      </ContentBoxWrap>
    </>
  )
}
export default UserDetailsExtraInfoReadOnly
