import NoticeDetails from 'pages/Support/Notice/NoticeDetails/NoticeDetails'
import { NoticeList } from 'pages/Support/Notice/NoticeList/NoticeList'
import { useParams } from 'react-router-dom'

export interface NoticeProps {}

const Notice = (props: NoticeProps) => {
  const { id } = useParams<'id'>()

  return (
    <>
      {id && <NoticeDetails _id={id} />}
      {!id && <NoticeList />}
    </>
  )
}
export default Notice
