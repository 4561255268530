import { css } from '@emotion/react'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import DraggablePopup from 'components/DraggablePopup'
import PopupInputLayout from 'components/PopupInputLayout'
import Textarea from 'components/Textarea'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useMatchInput from 'hooks/useMatchInput'
import { useEffect } from 'react'
import { colors } from 'themes/styles'
import S from './ExtraFee.styles'
import { EXTRA_FEE_INITIAL_VALUE, ExtraFeeProps } from './ExtraFee.types'

const ExtraFee = ({
  _css,
  _open,
  _noUserRequest,
  _data,
  _onClose,
  _emitValue,
  ...props
}: ExtraFeeProps) => {
  const { inputs, handleInput, handleValues } = useMatchInput(
    EXTRA_FEE_INITIAL_VALUE
  )
  const trueWhenEmpty = useEmptyCheck(inputs, 'note', 'messageToUser', 'id')
  useEffect(() => {
    if (_data) handleValues(_data.data)
  }, [_data, handleValues])
  if (!_open) {
    return <></>
  }
  return (
    <>
      <DraggablePopup
        _title={'추가금'}
        _open={true}
        _onClose={_onClose}
        _css={css`
          z-index: 10000;
        `}
        _bottom={
          <>
            {_data?.readonly ? (
              <PrimaryButton onClick={_onClose}>확인</PrimaryButton>
            ) : (
              <>
                <GrayScaleFill onClick={_onClose}>취소</GrayScaleFill>
                <PrimaryButton
                  disabled={trueWhenEmpty}
                  onClick={() => {
                    _emitValue({ ...inputs }, _data?.index)
                  }}
                >
                  확인
                </PrimaryButton>
              </>
            )}
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <PopupInputLayout _title="추가금 발생 작업" _required={true} _gap={4}>
            <UnderlineInput
              placeholder="내용 입력"
              readOnly={_data?.readonly}
              value={inputs.taskName ?? ''}
              handleValue={(value) => handleInput('taskName', value)}
              _css={css`
                input:read-only {
                  border-bottom: none;
                }
              `}
            />
          </PopupInputLayout>
          <PopupInputLayout _title="총 추가 금액" _required={true} _gap={4}>
            <UnderlineNumberInput
              placeholder="추가 금액 입력"
              readOnly={_data?.readonly}
              value={inputs.price}
              suffix={'원'}
              handleValue={(value) => {
                handleInput('price', value ?? null)
                handleInput('taskPrice', null)
              }}
              _css={css`
                input:read-only {
                  border-bottom: none;
                }
              `}
            />
          </PopupInputLayout>
          <PopupInputLayout _title="공임비 추가 금액" _required={true} _gap={4}>
            <UnderlineNumberInput
              placeholder={
                !inputs.price
                  ? '총 추가금액을 먼저 입력해주세요.'
                  : '추가 금액 중 공임비 금액 입력'
              }
              readOnly={_data?.readonly || !inputs.price}
              isAllowed={(values) => {
                if (!values.floatValue) return true
                return (
                  values.floatValue >= 0 &&
                  values.floatValue <= (inputs.price ?? 0)
                )
              }}
              value={inputs.taskPrice}
              suffix={'원'}
              handleValue={(value) => handleInput('taskPrice', value ?? null)}
              _css={css`
                input:read-only {
                  border-bottom: none;
                }
              `}
            />
          </PopupInputLayout>
          {!_noUserRequest && (
            <PopupInputLayout _title="회원 전달 내용">
              <Textarea
                readOnly={_data?.readonly}
                minHeight={'120px'}
                placeholder="회원에게 전달할 내용을 입력하세요."
                value={inputs.messageToUser ?? ''}
                onChange={(e) => handleInput('messageToUser', e.target.value)}
                _css={css`
                  &:read-only {
                    border: none;
                    background-color: ${colors['Grayscale/Background Light']};
                  }
                `}
              />
            </PopupInputLayout>
          )}
          <PopupInputLayout _title="상세 내용">
            <Textarea
              readOnly={_data?.readonly}
              minHeight={'120px'}
              placeholder="상세 내용을 입력해주세요."
              value={inputs.note ?? ''}
              onChange={(e) => handleInput('note', e.target.value)}
              _css={css`
                &:read-only {
                  border: none;
                  background-color: ${colors['Grayscale/Background Light']};
                }
              `}
            />
          </PopupInputLayout>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default ExtraFee
