import { css } from '@emotion/react'
import CheckBoxRound from 'components/CheckBoxRound'
import ContentsBox from 'components/ContentsBox'
import { RoundBox } from 'components/ContentsBox/ContentsBox.styles'
import Dropdown from 'components/Dropdown'
import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import RoundFillBox from 'components/RoundFillBox'
import FilterPart from 'components/TableComponent/FilterPart'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs, { Dayjs } from 'dayjs'
import { workStatusToKorean } from 'hooks/useAllMatch'
import useFetch from 'hooks/useFetch'
import useSearchFilter from 'hooks/useSearchFilter'
import { ApiZoneGroupItem } from 'pages/Services/Setting/Setting.types'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow } from 'themes/Shared.styles'
import {
  cn,
  getKeyByValue,
  objectKeys,
  removeUndefined,
  thousand,
} from 'utils/helpers'
import CalendarInReservation from '../components/CalendarInReservation'
import { DotColorsKeyType } from '../components/CalendarInReservation/CalendarInReservation.types'
import S from './Calendar.styles'
import {
  ApiDayScheduleListItem,
  ApiDaySelectedScheduleItem,
  CalendarProps,
  ServiceGroupDropdownListType,
  reservationCalendarDropdownInitial,
} from './Calendar.types'

const parseType = {
  0: '실측',
  1: '시공',
  2: '자재',
} as const
const Calendar = ({ _css, ...props }: CalendarProps) => {
  const navigate = useNavigate()
  const topInfoRef = useRef() as MutableRefObject<HTMLDivElement>
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [selected, set_selected] = useState<Dayjs>(dayjs())
  const [filtered, set_filtered] = useState<{
    실측: boolean
    시공: boolean
    자재: boolean
  }>({ 실측: true, 시공: true, 자재: true })
  const allFiltered = useMemo(() => {
    return !Object.values(filtered).some((bool) => !bool)
  }, [filtered])

  const [groupList] = useFetch<DoubleDListWithPaging<ApiZoneGroupItem>>(
    'user',
    `/zone-group?take=9999`
  )
  const [serviceGroupDropdown, set_serviceGroupDropdown] =
    useState<DropdownListType | null>(null)

  useEffect(() => {
    if (groupList?.list) {
      set_serviceGroupDropdown({
        전체: 'all',
        ...groupList.list.reduce((prev, curr) => {
          prev[curr.name] = String(curr.id)
          return prev
        }, {} as DropdownListType),
      } as DropdownListType)
    }
  }, [groupList])

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: reservationCalendarDropdownInitial,
    },
    // query object -> filterList
    ({ zoneGroupId, name, month, ...rest }) => {
      return {
        month: month ? String(month) : dayjs().format('YYYY-MM'),
        zoneGroupId: zoneGroupId ? zoneGroupId : 'all',
        terms: name ? String(name) : '',
      }
    },
    // filterList => query object
    ({ queryName = 'name', terms = '', zoneGroupId, month, ...rest }) => {
      return removeUndefined({
        month: month ? month : dayjs().format('YYYY-MM'),
        zoneGroupId: zoneGroupId === 'all' ? '' : zoneGroupId,
        name: terms,
      })
    }
  )

  const currentMonth = useMemo(() => {
    const { month } = initialValues
    return month ? dayjs(month, 'YYYY-MM') : dayjs()
  }, [initialValues])

  const [stats] = useFetch<{
    total: number
    construction: number
    measurement: number
    material: number
  }>(
    'task',
    `/schedule/stats?year=${currentMonth.format(
      'YYYY'
    )}&months=${currentMonth.format('MM')}`
  )
  const [response] = useFetch<ApiDayScheduleListItem[]>(
    'task',
    `/schedule/month?yyyymm=${currentMonth.format('YYYYMM')}&team=${objectKeys(
      filtered
    )
      .filter((item) => filtered[item])
      .join(',')}${initialValues.terms ? `&name=${initialValues.terms}` : ''}${
      searchParams.get('zoneGroupId')
        ? `&zoneGroupId=${searchParams.get('zoneGroupId')}`
        : ''
    }`
  )

  const [dayResponse] = useFetch<ApiDaySelectedScheduleItem[]>(
    'task',
    `/schedule/day?yyyymmdd=${selected.format('YYYYMMDD')}&team=${objectKeys(
      filtered
    )
      .filter((item) => filtered[item])
      .join(',')}${initialValues.terms ? `&name=${initialValues.terms}` : ''}`,
    {
      disabled: !selected,
    }
  )
  const currentSchedules = useMemo(() => {
    if (!dayResponse) return []
    return dayResponse.map((item) => {
      item.startTime =
        item.startTime.slice(0, 2) + ':' + item.startTime.slice(2, 4)
      item.endTime = item.endTime.slice(0, 2) + ':' + item.endTime.slice(2, 4)
      return item
    })
  }, [dayResponse])
  const scheduleDots = useMemo(() => {
    if (!response) return {} as { [key in string]: DotColorsKeyType[] }
    return response.reduce((prev, curr) => {
      prev[curr.day] = curr.schedule.map((num) => parseType[num])
      return prev
    }, {} as { [key in string]: DotColorsKeyType[] })
  }, [response])

  return (
    <>
      <ContentsBox
        _type="relative"
        _css={css`
          padding-bottom: 44px;
        `}
      >
        {/* ContentsBox에서 스타일 상속 받음 */}
        <S.Wrap _css={_css} {...props}>
          {/* EDIT:THIS */}
          <TopInfo ref={topInfoRef}>
            <TopInfoRow
              _label={`전체`}
              _contents={`${thousand(stats?.total)}건`}
            />
            <TopInfoRow
              _label={`실측`}
              _contents={`${thousand(stats?.measurement)}건`}
            />
            <TopInfoRow
              _label={`자재`}
              _contents={`${thousand(stats?.material)}건`}
            />
            <TopInfoRow
              _label={`시공`}
              _contents={`${thousand(stats?.construction)}건`}
            />
          </TopInfo>
          {/* EDIT:THIS */}
          <TableTitle ref={headerRef} _left={`예약 캘린더`} />
          {/* EDIT:THIS */}
          <FilterPart<ServiceGroupDropdownListType>
            _value={initialValues}
            _placeholder="담당자명 검색"
            _emitValue={handleSetLocation}
            _FilterContents={({ _value, _emitValue, ...props }) => {
              return (
                <div>
                  {serviceGroupDropdown && (
                    <Dropdown.Underline
                      _autoWidth
                      _value={_value?.zoneGroupId}
                      _list={serviceGroupDropdown}
                      _emitValue={(value) => {
                        if (_emitValue)
                          _emitValue({
                            month: _value?.month ?? dayjs().format('YYYY-MM'),
                            zoneGroupId: value,
                          })
                      }}
                    />
                  )}
                </div>
              )
            }}
          />
          <RoundFillBox>
            <FlexRow gap={24} alignItems="flex-start">
              <RoundBox
                _css={css`
                  flex: 1;
                `}
              >
                <CalendarInReservation
                  _currentMonth={currentMonth}
                  _value={selected}
                  _emitValue={(value) => {
                    set_selected(value)
                  }}
                  _prefix={
                    <FlexRow
                      gap={24}
                      _css={css`
                        & > div {
                          white-space: nowrap;
                          padding: 0px;
                          img {
                            width: 32px;
                            height: 32px;
                          }
                        }
                      `}
                    >
                      <CheckBoxRound
                        _active={allFiltered}
                        onClick={() => {
                          set_filtered(() => {
                            return { 실측: true, 시공: true, 자재: true }
                          })
                        }}
                      >
                        전체
                      </CheckBoxRound>
                      {(
                        Object.keys(filtered) as Array<keyof typeof filtered>
                      ).map((filterKeyName) => (
                        <CheckBoxRound
                          key={filterKeyName}
                          _active={filtered[filterKeyName]}
                          onClick={() => {
                            set_filtered((prev) => ({
                              ...prev,
                              [filterKeyName]: !prev[filterKeyName],
                            }))
                          }}
                        >
                          {filterKeyName}
                        </CheckBoxRound>
                      ))}
                    </FlexRow>
                  }
                  _DayContents={({ day }) => {
                    const reservedDots =
                      scheduleDots[day.current.format('DD')] ?? null
                    if (!reservedDots) return <></>
                    return (
                      <S.DayDescription>
                        <S.DotWrap>
                          {reservedDots.slice(0, 36).map((_col, _index) => {
                            return (
                              <S.Dot
                                key={day.current.format('YYYY-MM-DD') + _index}
                                _bgColor={_col}
                              />
                            )
                          })}
                        </S.DotWrap>
                        {reservedDots.length > 36 && (
                          <S.MoreCount>
                            +
                            {reservedDots.slice(36, reservedDots.length).length}
                          </S.MoreCount>
                        )}
                      </S.DayDescription>
                    )
                  }}
                />
              </RoundBox>
              <RoundBox
                _css={css`
                  width: 280px;
                  height: 746px;
                  flex-shrink: 0;
                  position: relative;
                `}
              >
                <S.RightHeader>{selected.format('YYYY-MM-DD')}</S.RightHeader>
                <S.SchedulesOuter
                  className={cn({
                    none: currentSchedules && currentSchedules.length < 1,
                  })}
                >
                  <S.SchedulesWrap>
                    {currentSchedules && currentSchedules.length > 0 ? (
                      <>
                        {currentSchedules.map((schedule, index) => {
                          return (
                            <S.SchedulesGroup key={'schedule' + index}>
                              <S.AllDayWrap>
                                <S.Line />
                                <div>
                                  {schedule.startTime} ~ {schedule.endTime}
                                </div>
                                <S.Line />
                              </S.AllDayWrap>
                              {schedule.schedules.map((work, workIndex) => {
                                return (
                                  <S.ScheduleItem
                                    key={'work' + workIndex}
                                    _bgColor={work.type}
                                    onClick={() => {
                                      navigate(
                                        work.unpaid
                                          ? `/unpaid/${work.taskId}`
                                          : `/work/${getKeyByValue(
                                              workStatusToKorean,
                                              work.status
                                            )}/${work.taskId}`
                                      )
                                    }}
                                  >
                                    <div className="title">
                                      {work.categoryName} - {work.type}
                                    </div>
                                    {work.nameList.length > 0 && (
                                      <div className="techs">
                                        {work.nameList.map(
                                          (techName, techsIndex) => {
                                            return (
                                              <div
                                                key={'techName' + techsIndex}
                                              >
                                                {techName}
                                              </div>
                                            )
                                          }
                                        )}
                                      </div>
                                    )}
                                  </S.ScheduleItem>
                                )
                              })}
                            </S.SchedulesGroup>
                          )
                        })}
                      </>
                    ) : (
                      <S.None>오늘 일정이 없습니다.</S.None>
                    )}
                  </S.SchedulesWrap>
                </S.SchedulesOuter>
              </RoundBox>
            </FlexRow>
          </RoundFillBox>
        </S.Wrap>
      </ContentsBox>
    </>
  )
}
export default Calendar
