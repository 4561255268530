import { useAppSelector } from 'hooks/reduxHooks'
import { selectSign, selectStep } from 'store/signSlice'
import FindUserComponent from 'templates/Authorization/FindUserComponent'
import JoinComponent from 'templates/Authorization/JoinComponent'
import LoginComponent from 'templates/Authorization/LoginComponent'
import { AuthorizationProps } from './Authorization.types'
import Certification from './Certification'
import InitPassword from './InitPassword'

const Authorization = ({ _isAdmin, ...props }: AuthorizationProps) => {
  const { imp_uid, username } = useAppSelector(selectSign)
  const currentStep = useAppSelector(selectStep)

  return (
    <>
      {currentStep === 'login' && <LoginComponent _isAdmin={_isAdmin} />}
      {currentStep === 'cert' && <Certification />}
      {currentStep === 'join' && imp_uid && <JoinComponent />}
      {currentStep === 'find' && username && imp_uid && <FindUserComponent />}
      {currentStep === 'password' && _isAdmin && <InitPassword />}
    </>
  )
}
export default Authorization
