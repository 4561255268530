import { cn } from 'utils/helpers'
import S from './ToggleSwitch.styles'
import { ToggleSwitchProps } from './ToggleSwitch.types'

const ToggleSwitch = ({
  _css,
  _active,
  _mini,
  _disabled,
  className,
  onClick,
  children,
  ...props
}: ToggleSwitchProps) => {
  return (
    <>
      <S.Wrap
        _css={_css}
        className={cn({ disabled: _disabled, active: _active }, className)}
        onClick={(e) => {
          e.stopPropagation()
          if (onClick && !_disabled) onClick(e)
        }}
        {...props}
      >
        <S.Switch className={cn({ active: _active, small: _mini })}>
          <div className="edge-round"></div>
        </S.Switch>
        {children}
      </S.Wrap>
    </>
  )
}
export default ToggleSwitch
