import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import dayjs, { Dayjs } from 'dayjs'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export interface PeriodDropdownFilterProps extends DivAttributes {
  _css?: AdditionalCss
}

export const PERIOD_DROPDOWN_INITIAL_VALUE: PeriodDropdownValuesType = {
  period: {
    start: null,
    end: null,
  },
  monthValue: '',
  yearValue: '',
  periodType: 'all',
  sortType: '',
  sortValue: '',
}
export const months = Array.from(Array(12).keys()).map((i) => i)
export const monthOptions = months.reduce((prev, curr) => {
  prev[curr + 1 + '월'] = curr + 1
  return prev
}, {} as DropdownListType)
export const years = Array.from(Array(101).keys()).map(
  (i) => Number(dayjs().format('YYYY')) + i
)
export const yearOption = years.reduce((prev, curr) => {
  prev[curr] = curr
  return prev
}, {} as DropdownListType)

export const TIME_FILTER_DROPDOWN = {
  전체: 'all',
  연도별: 'year',
  월별: 'month',
  기간별: 'period',
} as const
export const TIME_FILTER_DROPDOWN_WITHOUT_ALL = {
  연도별: 'year',
  월별: 'month',
  기간별: 'period',
} as const

export type TimeFilterValueType = ValueOf<typeof TIME_FILTER_DROPDOWN>
export const isTimeFilter = (
  value: any
): value is typeof TIME_FILTER_DROPDOWN[keyof typeof TIME_FILTER_DROPDOWN] => {
  return (Object.values(TIME_FILTER_DROPDOWN) as string[]).some(
    (v) => v === value
  )
}

export interface PeriodType {
  start: Dayjs | null
  end: Dayjs | null
}
export interface PeriodDropdownValuesType {
  period: PeriodType
  monthValue: string | number
  yearValue: string | number
  periodType: TimeFilterValueType | ''
  sortType?: string | number
  sortValue?: string | number
}
export const isPeriod = (value: any): value is PeriodType => {
  return 'start' in value && 'end' in value
}
