import { css } from '@emotion/react'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FlexColumn } from 'themes/Shared.styles'
import { cn, isObjectValueType } from 'utils/helpers'
import AssignMaterials from './AssignMaterials'
import AssignMeasurementTechs from './AssignMeasurementTechs'
import AssignServiceTechs from './AssignServiceTechs'
import W from './WorkerAssignmentTab.styles'
import { WorkerAssignmentTabProps } from './WorkerAssignmentTab.types'

const tabs = ['실측', '자재', '시공'] as const
const WorkerAssignmentTab = ({
  _css,
  _type = 'ongoing',
  inputs,
  handleInput,
  ...props
}: WorkerAssignmentTabProps) => {
  const [searchParams, set_searchParams] = useSearchParams()
  const [currentTab, set_currentTab] = useState<typeof tabs[number]>()
  useEffect(() => {
    const currentTabQuery = searchParams.get('assign')
    if (isObjectValueType(tabs, currentTabQuery)) {
      set_currentTab(currentTabQuery)
    } else {
      set_currentTab(tabs[0])
    }
  }, [searchParams])

  const [openModal, set_openModal] = useState<{
    title: string
    content: string
    btnTitle?: string
    btnSubTitle?: string
    onSubmit: () => void
  } | null>(null)

  return (
    <>
      {openModal && (
        <Modal open={openModal !== null} onClose={() => set_openModal(null)}>
          <MiniPopup
            _title={openModal.title}
            _buttonHandler={
              <>
                <GrayScaleFill
                  onClick={() => {
                    set_openModal(null)
                  }}
                >
                  {openModal.btnSubTitle ?? '아니요'}
                </GrayScaleFill>
                <PrimaryButton onClick={openModal.onSubmit}>
                  {openModal.btnTitle ?? '삭제'}
                </PrimaryButton>
              </>
            }
          >
            {openModal.content}
          </MiniPopup>
        </Modal>
      )}
      <W.Wrap _css={_css} {...props}>
        <FlexColumn
          gap={24}
          _css={css`
            & > div {
              width: 100%;
            }
          `}
        >
          <W.PartsTab>
            {tabs.map((tab) => {
              return (
                <div
                  key={tab}
                  className={cn({ active: tab === currentTab })}
                  onClick={() => {
                    searchParams.set('assign', tab)
                    set_searchParams(searchParams)
                    const leftContentScrollDom = document.querySelector(
                      '#left-contents > div'
                    )
                    if (leftContentScrollDom) {
                      leftContentScrollDom.scrollTo(0, 0)
                    }
                  }}
                >
                  {tab}
                </div>
              )
            })}
          </W.PartsTab>
          <div>
            {currentTab === '실측' && (
              <AssignMeasurementTechs
                {...{
                  _readOnly: inputs.serviceState === '시공완료',
                  handleModal: (value) => {
                    set_openModal(value)
                  },
                  inputs,
                  handleInput,
                }}
              />
            )}
            {currentTab === '자재' && (
              <AssignMaterials
                {...{
                  _readOnly: inputs.serviceState === '시공완료',
                  handleModal: (value) => {
                    set_openModal(value)
                  },
                  inputs,
                  handleInput,
                }}
              />
            )}
            {currentTab === '시공' && (
              <AssignServiceTechs
                {...{
                  _readOnly: inputs.serviceState === '시공완료',
                  handleModal: (value) => {
                    set_openModal(value)
                  },
                  inputs,
                  handleInput,
                }}
              />
            )}
          </div>
        </FlexColumn>
      </W.Wrap>
    </>
  )
}
export default WorkerAssignmentTab
