import { SerializedStyles } from '@emotion/react'
import React from 'react'
import S from './ContentPart.styles'
export interface ContentPartProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  _title: string | JSX.Element
  _css?: SerializedStyles
}

export const ContentPart = ({
  _title,
  _css,
  children,
  ...props
}: ContentPartProps) => {
  return (
    <S.Wrap _css={_css}>
      <S.Title className="title">{_title}</S.Title>
      <S.Content className="content">{children}</S.Content>
    </S.Wrap>
  )
}
