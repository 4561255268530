import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const AssignTeamPopupStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    padding: 32px;
    ${addCssProps}
  `,
  RightWrap: styled.div`
    flex: 1;
  `,
  PopupInputLayout: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  Header: styled.div`
    ${typography['Body/Small/Bold']}
    color: ${colors['Grayscale/Gray Default']};
    display: flex;
    align-items: center;
  `,
  Chips: styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 2px;
    ${typography['Body/Large/Bold']}
    color: ${colors['Primary/Default']};
    svg {
      width: 16px;
      height: 16px;
    }
  `,
  SvgWrap: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  `,
  Buttons: styled.div`
    padding: 7px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    & > button {
      height: 30px;
      &:first-of-type {
        flex: 1;
      }
      &:last-of-type {
        width: 120px;
      }
    }
  `,
}

export default AssignTeamPopupStyle
