import { css } from '@emotion/react'
import { ImagesDetailsChevronRightBlack, ImagesDetailsProfile } from 'assets'
import Badge from 'components/Badge'
import { BADGE_COLOR } from 'components/Badge/Badge'
import DetailsComponents, { ToList } from 'components/DetailsComponents'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import dayjs from 'dayjs'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import LoadingPage from 'layout/LoadingPage'
import { TECHS_TYPES_BADGE_COLOR_SET } from 'pages/System/Techs/TechsList/TechsList.types'
import { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import {
  ContentBoxWrap,
  ContentProfileBox,
  ContentRadiusBox,
  Divider,
  Flex,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { objectKeys, thousand } from 'utils/helpers'
import SettlementServiceHistoryTable from '../components/SettlementServiceHistoryTable'
import ST from './SettlementForTechsDetails.styles'
import {
  FreelancerSettlementDetailsType,
  SettlementForTechsDetailsProps,
  SusudaSettlementDetailsType,
  TECHS_SETTLEMENT_INFO_INITIAL_VALUE,
  freelancerCalcInfoName,
  parserForSettlementTechsDetails,
  susudaCalcInfoName,
  wagesInfoTypeName,
} from './SettlementForTechsDetails.types'

const SettlementForTechsDetails = ({
  _css,
  _type,
  _id,
  ...props
}: SettlementForTechsDetailsProps) => {
  const { Layout: L } = S
  const [response, isError] = useFetch<
    FreelancerSettlementDetailsType | SusudaSettlementDetailsType
  >('task', `/settlement/technician/${_id}`, {
    disabled: !_id,
  })
  const navigate = useNavigate()
  const { inputs, fetchInit } = useMatchInput(
    TECHS_SETTLEMENT_INFO_INITIAL_VALUE
  )
  useEffect(() => {
    if (response) {
      fetchInit(parserForSettlementTechsDetails(response))
    }
  }, [fetchInit, response])
  const { freelancerCalcInfo, susudaCalcInfo, wagesInfo } = inputs

  if (!response) return <LoadingPage />

  if (isError) return <Navigate to={`/404?from=${window.location.pathname}`} />
  return (
    <>
      <DetailsComponents
        _title={`숙련공 정산 ${_type === 'history' ? '내역' : '대기'} 상세`}
        _topLeftControls={
          <L.ButtonRow>
            <ToList _to={`/settlement/techs/${_type}`} />
          </L.ButtonRow>
        }
        _leftContents={
          <ContentBoxWrap>
            <ContentProfileBox>
              <div className="wrap">
                <FlexColumn gap={12} alignItems="center">
                  <div
                    data-profile
                    style={{
                      backgroundImage: `url(${
                        inputs.profileImage
                          ? inputs.profileImage
                          : ImagesDetailsProfile
                      })`,
                    }}
                  />
                  <div>
                    <div
                      className="name with-link"
                      onClick={() => {
                        navigate(`/system/techs/${inputs.userId}`)
                      }}
                    >
                      {inputs.name}
                      <img src={ImagesDetailsChevronRightBlack} alt="right" />
                    </div>
                    <div className="phone">{inputs.phone}</div>
                    <FlexRow
                      gap={4}
                      className="badges"
                      justifyContent={'center'}
                    >
                      {inputs.badges.inActive && (
                        <Badge {...BADGE_COLOR.Gray}>비활성화</Badge>
                      )}
                      {inputs.badges.techsType && (
                        <Badge
                          {...TECHS_TYPES_BADGE_COLOR_SET[
                            inputs.badges.techsType
                          ]}
                        >
                          {inputs.badges.techsType}
                        </Badge>
                      )}
                      {inputs.badges.measureAble && (
                        <Badge {...BADGE_COLOR.AccentLight}>실측가능</Badge>
                      )}
                    </FlexRow>
                  </div>
                </FlexColumn>
              </div>
            </ContentProfileBox>
            <Flex
              flexDirection="column"
              justifyContent={'center'}
              alignItems={'center'}
              _css={css`
                margin-top: 16px;
              `}
              gap={18}
            >
              {_type === 'queue' && (
                <>
                  <TXT _textStyle="Headline/Bold" _color="Grayscale/Black">
                    지급 정보
                  </TXT>
                </>
              )}
              {_type === 'history' && (
                <ST.CalendarHeader>
                  <FlexRow gap={12}>
                    {/* <GrayScaleOutline
                      _css={css`
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                      `}
                      onClick={() => {
                        set_currentMonth((prev) => prev.subtract(1, 'month'))
                      }}
                    >
                      <img src={ImagesDetailsChevronLeftSmall} alt="prev" />
                    </GrayScaleOutline> */}
                    <h1>{inputs.yearMonth}</h1>
                    {/* <GrayScaleOutline
                      _css={css`
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                      `}
                      disabled={dayjs().diff(currentMonth, 'month') <= 0}
                      onClick={() => {
                        set_currentMonth((prev) => prev.add(1, 'month'))
                      }}
                    >
                      <img src={ImagesDetailsChevronRightSmall} alt="next" />
                    </GrayScaleOutline> */}
                  </FlexRow>
                </ST.CalendarHeader>
              )}
              <FlexColumn gap={12}>
                <ContentRadiusBox>
                  <BoxRowComponent _label={'계좌 정보'}>
                    {inputs.wagesInfo?.accountNo}
                  </BoxRowComponent>
                  <BoxRowComponent _label={'시공 완료일'}>
                    {inputs.wagesInfo?.taskFinishedAt
                      ? dayjs(inputs.wagesInfo?.taskFinishedAt).format(
                          'YYYY-MM-DD'
                        )
                      : '-'}
                  </BoxRowComponent>
                </ContentRadiusBox>
                {freelancerCalcInfo && (
                  <>
                    {wagesInfo && (
                      <ContentRadiusBox>
                        {objectKeys(wagesInfo)
                          .filter(
                            (ii) =>
                              ii !== 'taskFinishedAt' && ii !== 'accountNo'
                          )
                          .map((freeWage) => {
                            return (
                              <BoxRowComponent
                                key={'free' + freeWage}
                                _label={wagesInfoTypeName[freeWage].name}
                              >
                                {typeof wagesInfo[freeWage] === 'number'
                                  ? thousand(wagesInfo[freeWage] ?? 0)
                                  : wagesInfo[freeWage]}
                                {wagesInfoTypeName[freeWage].unit}
                              </BoxRowComponent>
                            )
                          })}
                      </ContentRadiusBox>
                    )}
                    <ContentRadiusBox>
                      {objectKeys(freelancerCalcInfo)
                        .filter(
                          (ii) =>
                            !!freelancerCalcInfo[ii] && ii !== 'totalWages'
                        )
                        .map((free) => {
                          return (
                            <BoxRowComponent
                              key={'free' + free}
                              _label={freelancerCalcInfoName[free]}
                            >
                              {thousand(freelancerCalcInfo[free])}원
                            </BoxRowComponent>
                          )
                        })}
                      <Divider />
                      <BoxRowComponent
                        _label={
                          <S.TotalLabel>
                            {_type === 'queue' ? '지급 대기 총액' : '지급 총액'}{' '}
                          </S.TotalLabel>
                        }
                      >
                        <S.TotalPrice>
                          {thousand(freelancerCalcInfo.totalWages)}원
                        </S.TotalPrice>
                      </BoxRowComponent>
                      {_type === 'history' && inputs.finishedAt && (
                        <Flex justifyContent={'right'}>
                          <Badge {...BADGE_COLOR['Gray']}>
                            {dayjs(inputs.finishedAt).format('YYYY-MM-DD')}{' '}
                            정산완료
                          </Badge>
                        </Flex>
                      )}
                    </ContentRadiusBox>
                  </>
                )}
                {susudaCalcInfo && (
                  <>
                    <ContentRadiusBox
                      _css={css`
                        margin-bottom: 6px;
                      `}
                    >
                      <BoxRowComponent _label={'기본 급여'}>
                        {thousand(inputs.wagesInfo?.basicWages)}원
                      </BoxRowComponent>
                      <BoxRowComponent _label={'기본 정산 비율'}>
                        {inputs.wagesInfo?.settlementRatio}%
                      </BoxRowComponent>
                    </ContentRadiusBox>
                    <ContentRadiusBox>
                      {objectKeys(susudaCalcInfo)
                        .filter(
                          (ii) => !!susudaCalcInfo[ii] && ii !== 'totalWages'
                        )
                        .map((free) => {
                          return (
                            <BoxRowComponent
                              key={'free' + free}
                              _label={susudaCalcInfoName[free]}
                            >
                              {thousand(susudaCalcInfo[free])}원
                            </BoxRowComponent>
                          )
                        })}
                      <Divider />
                      <BoxRowComponent
                        _label={
                          <S.TotalLabel>
                            {_type === 'queue' ? '지급 대기 총액' : '지급 총액'}{' '}
                          </S.TotalLabel>
                        }
                      >
                        <S.TotalPrice>
                          {thousand(susudaCalcInfo.totalWages)}원
                        </S.TotalPrice>
                      </BoxRowComponent>
                      {_type === 'history' && inputs.finishedAt && (
                        <Flex justifyContent={'right'}>
                          <Badge {...BADGE_COLOR['Gray']}>
                            {dayjs(inputs.finishedAt).format('YYYY-MM-DD')}{' '}
                            정산완료
                          </Badge>
                        </Flex>
                      )}
                    </ContentRadiusBox>
                  </>
                )}
              </FlexColumn>
            </Flex>
          </ContentBoxWrap>
        }
        _rightContents={
          <SettlementServiceHistoryTable
            _type={_type}
            _settlementList={inputs.settlementList}
            _workInfo={inputs.workInfo}
          />
        }
      />
    </>
  )
}
export default SettlementForTechsDetails
