import { API_URL_KEYS } from 'API_URL'
import classNames from 'classnames'
import { PeriodDropdownValuesType } from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import { isEllipsisActive } from 'components/TableComponent/TableComponent.helpers'
import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { clone } from 'lodash-es'
import { chain } from 'mathjs'
import { DoubleDFile } from 'types/services.type'
import { v4 } from 'uuid'
import callAxios, { handleError } from './callAxios'
dayjs.extend(isBetween)

/** classNames 호출
 *
 * cn({ value: boolean }, string) => string */
export const cn = classNames

/** uuid 생성
 *
 * () => string
 */
export const uuid = v4

/**
 * 클립보드 복사
 *
 * @param text
 * @returns
 */
export async function copyToClipboard(text: string): Promise<string> {
  if (window.navigator?.clipboard && window.isSecureContext) {
    return new Promise((res, rej) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          res(text)
        })
        .catch((err) => {
          rej('error')
        })
    })
  } else {
    let El = document.createElement('textarea')
    El.style.position = 'absolute'
    El.style.opacity = '0'
    document.body.appendChild(El)
    El.value = text
    El.focus()
    El.select()
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res(text) : rej('error')
      El.remove()
    })
  }
}

/**
 *
 *
 * @template T
 * @param {T} obj
 * @param {T[keyof T]} value
 * @return {*}  {(keyof T | null)}
 */
export const getObjectLabel = <T extends object>(
  obj: T,
  value: T[keyof T]
): Extract<keyof T, string | number> | null => {
  const keyName = (Object.keys(obj) as Array<keyof T>).find(
    (keyName) => obj[keyName] === value
  )
  if (typeof keyName === 'string' || typeof keyName === 'number') {
    return keyName as Extract<keyof T, string | number>
  } else {
    return null
  }
}
/**
 *
 *
 * @param {NodeListOf<Element>} el
 * @return {*}  {HTMLDivElement[]}
 */
export const getDoms = (
  el: NodeListOf<Element> | HTMLCollection
): HTMLDivElement[] => {
  let els: HTMLDivElement[] = []
  for (let i: number = 0; i <= el.length - 1; i++) {
    els.push(el[i] as HTMLDivElement)
  }
  return els
}

/** 천단위 쉼표 */
export const thousand = (num?: number | string, decimalCount?: number) => {
  if (!num || !Number(num)) num = 0
  if (typeof num === 'string') num = Number(num)
  if (decimalCount !== undefined) num = Number(num.toFixed(decimalCount))
  return new Intl.NumberFormat('ko-KR').format(num)
}

/**
 * input 숫자만 받아야 할 경우
 * @param value 새로 들어온 Input value
 * @param preValue 직전의 Input value
 */
const numberRegex = /^[0-9]+$/
export const onInputNumber = (value: string, preValue: string) => {
  if (value.match(numberRegex) || value === '') return value
  return preValue
}

/**
 * 부드럽게 스크롤
 * @param y 이동할 offsetTop
 * @param el HTMLElement
 * @param param2 { duration: number, offset: number }
 * @returns
 */
export const smoothScrollTo = (
  y: number,
  el: HTMLElement | Window = window,
  { duration = 500, offset = 0 }: { duration?: number; offset?: number } = {}
): Promise<number> => {
  const isWindow = (value: any): value is Window => {
    return typeof value.scrollY === 'number'
  }
  const easeOutCubic = (t: number) => --t * t * t + 1
  const startY = isWindow(el) ? el.scrollY : el.scrollTop
  const difference = y - startY
  const startTime = performance.now()

  if (y === startY + offset) {
    return Promise.resolve(y)
  }

  return new Promise((resolve) => {
    const step = () => {
      const progress = (performance.now() - startTime) / duration
      const amount = easeOutCubic(progress)
      el.scrollTo({ top: startY + amount * difference - offset })
      if (progress < 0.99) {
        window.requestAnimationFrame(step)
      } else {
        resolve(y)
      }
    }
    step()
  })
}

export const updateObjectArray = <T extends { id: string | number }>(
  prev: T[],
  updatedItem: Partial<T>,
  identifier: keyof T = 'id'
) => {
  if (updatedItem) {
    if (
      Object.keys(updatedItem).length === 1 &&
      updatedItem[identifier] !== undefined
    ) {
      return prev.filter((item) => {
        return updatedItem && item[identifier] !== updatedItem[identifier]
      })
    }
    return prev.map((item) => {
      return updatedItem && item[identifier] === updatedItem[identifier]
        ? { ...item, ...updatedItem }
        : item
    })
  }
  return prev
}

/**
 * object value type 체크
 * @param obj
 * @param value
 * @returns
 */
export const isObjectValueType = <T extends object>(
  obj: T,
  value: any
): value is T[keyof T] => {
  return (Object.values(obj) as string[]).some((v) => v === value)
}

/**
 * object key type 체크
 * @param obj
 * @param value
 * @returns
 */
export const isObjectKeyType = <T extends object>(
  obj: T,
  value: any
): value is keyof T => {
  return (Object.keys(obj) as string[]).some((v) => v === value)
}
/**
 * object key-value 뒤집기
 * @param item
 * @returns
 */
export const flipObject = <
  T extends { [key in string | number | symbol]: string | number | symbol }
>(
  item: T
): {
  [K in keyof T as T[K]]: K
} => {
  return Object.keys(item).reduce(
    (prev, curr) => {
      const keysValue = item[curr] as keyof typeof prev
      prev[keysValue] = curr as typeof prev[keyof typeof prev]
      return prev
    },
    {} as {
      [K in keyof T as T[K]]: K
    }
  )
}
/**
 * ellipsis hover 시 나오는 tooltip 핸들러
 * @param eventType : mouseEnter | mouseLeave
 * @param e : event
 */
export const HandleEllipsisToolTip = (
  eventType: 'mouseEnter' | 'mouseLeave',
  e: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  if (eventType === 'mouseEnter') {
    if (
      isEllipsisActive(e.currentTarget) &&
      typeof e.currentTarget.innerText === 'string'
    ) {
      const tooltipElement = document.createElement('span')
      tooltipElement.innerText = e.currentTarget.innerText

      tooltipElement.classList.add('ellipsis-tooltips')
      tooltipElement.onclick = (e) => {
        e.stopPropagation()
      }
      tooltipElement.ondblclick = (e) => {
        e.stopPropagation()
      }
      if (!e.currentTarget.querySelector('.ellipsis-tooltips')) {
        e.currentTarget.appendChild(tooltipElement)
      }
    }
  }
  if (eventType === 'mouseLeave') {
    let tooltipElement = e.currentTarget.querySelector('.ellipsis-tooltips')
    if (tooltipElement) {
      tooltipElement.remove()
    }
  }
}
/**
 * File to Blob string
 * @param value
 * @returns
 */
export const getFileToBlob = (value: File): Promise<string> => {
  return new Promise((res, rej) => {
    let reader = new FileReader()
    reader.onload = () => {
      res(reader.result as string)
    }
    reader.readAsDataURL(value)
  })
}
/**
 * File[] to string[]
 * @param files
 * @returns
 */
export const filesToBlobs = async (files: File[]): Promise<string[]> => {
  return await Promise.all(
    files.map((file) => {
      return getFileToBlob(file)
    })
  )
}
/**
 * dataUrl to Blob
 * @param files
 * @returns
 * @description
 */
export const dataUrlToBlob = (dataUrl: string): Blob => {
  const [metadata, base64Data] = dataUrl.split(',')
  const mimeType = metadata.split(';')[0].split(':')[1]
  const decodedData = window.atob(base64Data)
  const buffer = new Uint8Array(decodedData.length)
  for (let i = 0; i < decodedData.length; ++i) {
    buffer[i] = decodedData.charCodeAt(i)
  }
  return new Blob([buffer], { type: mimeType })
}

/**
 * File to DoubleDFile with Blob url
 * @param value
 * @returns
 */
export const getFileToDoubleDFile = (value: File): Promise<DoubleDFile> => {
  return new Promise((res, rej) => {
    let reader = new FileReader()
    reader.onload = () => {
      res({
        id: uuid(),
        name: value.name,
        url: reader.result as string,
        file: value,
      })
    }
    reader.readAsDataURL(value)
  })
}
/**
 * File[] to DoubleDFile[]
 * @param files
 * @returns
 */
export const filesToDDFiles = async (files: File[]): Promise<DoubleDFile[]> => {
  return await Promise.all(
    files.map((file) => {
      return getFileToDoubleDFile(file)
    })
  )
}
/**
 * FileList to DD_File[]
 * @param list
 * @returns
 */
export async function distinctDDFiles(list: FileList): Promise<DoubleDFile[]> {
  const result = []
  for (var i = 0; i < list.length; i++) {
    var value = list[i]
    if (result.indexOf(value) === -1) result.push(value)
  }
  return await filesToDDFiles(result)
}
/**
 * FileList to File[]
 * @param list
 * @returns
 */
export function distinctFiles(list: FileList): File[] {
  const result = []
  for (var i = 0; i < list.length; i++) {
    var value = list[i]
    if (result.indexOf(value) === -1) result.push(value)
  }
  return result
}

export const handleNumber = (value: string | number | undefined | null) => {
  if (typeof value === 'number') return value
  if (value && !isNaN(Number(value))) return Number(value)
  return 0
}

/**
 * value의 첫번째 키값
 * @param rest
 * @returns
 */
export const getQueryNameInRest = <K extends QueryObject>(rest: K) => {
  return Object.keys(rest).length ? Object.keys(rest)[0] : ''
}
export const parserForSearchWithTypeAndValue = (
  searchType: string | number | undefined,
  searchValue: string | number | undefined
) => {
  return {
    terms: searchValue ? String(searchValue) : '',
    queryName: searchType ? String(searchType) : '',
  }
}
export const parserForSearchWithTermsAndQueryName = (
  terms: string,
  queryName: string
) => {
  return {
    searchType: terms ? queryName : '',
    searchValue: terms,
  }
}
export const parserForSortQuery = (rest: PeriodDropdownValuesType) => {
  if (rest.sortValue === 'INITIAL') {
    return {}
  }
  return { sortType: rest.sortType, sortValue: rest.sortValue }
}
export const parserForPeriodQuery = (rest: PeriodDropdownValuesType) => {
  const { period, monthValue, yearValue, periodType } = rest
  let periods: { startAt: string; endAt: string } = { startAt: '', endAt: '' }
  if (periodType === 'year' && yearValue) {
    periods = {
      startAt: dayjs(`${yearValue}-01-01`).startOf('year').toISOString(),
      endAt: dayjs(`${yearValue}-12-31 23:59:59`).endOf('year').toISOString(),
    }
  } else if (periodType === 'month' && monthValue) {
    periods = {
      startAt: dayjs(`${dayjs().format('YYYY')}-${monthValue}-01`)
        .startOf('month')
        .toISOString(),
      endAt: dayjs(`${dayjs().format('YYYY')}-${monthValue}-01`)
        .endOf('month')
        .toISOString(),
    }
  } else if (periodType === 'period' && period) {
    periods = {
      startAt: period.start?.startOf('day').toISOString() ?? '',
      endAt: period.end?.endOf('day').toISOString() ?? '',
    }
  }
  return {
    ...periods,
    periodType: periodType === 'all' ? '' : periodType,
    month: monthValue ?? 0,
    year: yearValue ?? 0,
  }
}
/**
 * value가 undefined 인걸 뺸다.
 * @param value
 * @returns
 */
export const removeUndefined = <T extends object>(
  value: T,
  removeValue?: string
): Partial<T> => {
  const keyArray = Object.keys(value) as Array<keyof T>
  return keyArray.reduce((prev, curr) => {
    if (value[curr] === removeValue) return prev
    if (value[curr] !== undefined && value[curr] !== '')
      prev[curr] = value[curr]
    return prev
  }, {} as Partial<T>)
}
/**
 * 해당 위치에 값을 변경(추가) 뒤의 값은 날림
 * @param array
 * @param index
 * @param elementsArray
 * @returns
 */
export const updateAndDeleteBehind = <T>(
  array: T[],
  index: number,
  ...elementsArray: T[]
) => {
  let newArray = clone(array)
  newArray.splice(index, 0, ...elementsArray)
  return newArray.slice(0, index + 1)
}

/**
 * 해당 위치에 값을 추가
 * @param array
 * @param index
 * @param elementsArray
 * @returns
 */
export const appendAt = <T>(array: T[], index: number, item: T) => {
  let newArray = clone(array)
  return [
    ...newArray.slice(0, index),
    item,
    ...newArray.slice(index, array.length),
  ]
}
/**
 * 해당 위치에 값을 변경
 * @param array
 * @param index
 * @param elementsArray
 * @returns
 */
export const replaceAt = <T>(array: T[], index: number, item: T) => {
  let newArray = clone(array)
  return [
    ...newArray.slice(0, index - 1),
    item,
    ...newArray.slice(index + 1, array.length),
  ]
}
/**
 * 값으로 키 리턴
 */
export const getKeyNameByValue = <T extends object>(
  obj: T,
  value: number | string | null
): keyof T | null => {
  if (value === null) return null
  return (
    (Object.keys(obj).find(
      (keyName) => obj[keyName as keyof T] === value
    ) as keyof T) ?? null
  )
}
/**
 * 배열 위치 변경
 * @param array
 * @param from
 * @param to
 * @param on
 * @returns
 */
export const moveArray = <T>(array: T[], from: number, to: number, on = 1) => {
  let cloning = clone(array)
  cloning.splice(to, 0, ...cloning.splice(from, on))
  return cloning
}

export const getTechsName = <T extends { name: string }>(value: T[]) => {
  if (value.length === 1) {
    return value[0].name
  } else if (value.length > 1) {
    return `${value[0].name} 외 ${value.length - 1}명`
  }
  return ''
}

export const roomSizeMeterToSquareCalculator = (value: number) => {
  return Number(chain(value).divide(3.3058).round(0))
}
export const roomSizeSquareToMeterCalculator = (value: number) => {
  return Number(chain(value).multiply(3.3058).round(3))
}
export const getHouseSize = (
  value: number | null | undefined,
  type: '평' | '제곱'
) => {
  value = value ?? 0
  const square =
    type === '평'
      ? Number(chain(value).round(0))
      : roomSizeMeterToSquareCalculator(value)
  const meter =
    type === '제곱'
      ? Number(chain(value).round(3))
      : roomSizeSquareToMeterCalculator(value)
  const susudaSize = Number(chain(square).divide(0.75).round(0))
  return {
    square,
    meter,
    susudaSize,
    toString: () => {
      return value ? `${square}평 (${meter}㎡) / ${susudaSize}평` : ''
    },
  }
}

export const stringArrayToDropdownListObject = <T extends string>(
  value: readonly T[]
): { [key in T]: T } => {
  return value.reduce((prev, curr) => {
    prev[curr] = curr
    return prev
  }, {} as { [key in T]: T })
}

/**
 * 목록 펼치기 타겟만
 * @param e
 * @param el
 */
export const handleCollapseTarget = (
  e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  el: HTMLDivElement
) => {
  const bounding = el.getBoundingClientRect()
  if (el.classList.contains('collapsed')) {
    e.currentTarget.classList.remove('collapsed')
    el.style.height = ''
    el.classList.remove('collapsed')
  } else {
    e.currentTarget.classList.add('collapsed')
    el.style.height = bounding.height + 'px'
    setTimeout(() => {
      el.classList.add('collapsed')
      el.style.height = ''
    }, 100)
  }
}
/**
 * 목록 펼치기 자식 타겟만
 * @param e
 * @param el
 */
export const handleCollapseToChild = (
  e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  el: HTMLDivElement
) => {
  const els = el as HTMLDivElement
  if (e.currentTarget.classList.contains('collapsed')) {
    e.currentTarget.classList.remove('collapsed')
    els.style.height = ''
    els.classList.remove('collapsed')
  } else {
    e.currentTarget.classList.add('collapsed')
    const bounding = els.getBoundingClientRect()
    els.style.height = bounding.height + 'px'
    els.classList.add('collapsed')
    els.style.height = ''
  }
}
/**
 * 목록 펼치기 자식들 전부
 * @param e
 * @param el
 */
export const handleCollapseToChildren = (
  e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  el: HTMLDivElement
) => {
  const els = getDoms(el.children)
  if (e.currentTarget.classList.contains('collapsed')) {
    e.currentTarget.classList.remove('collapsed')

    els.forEach((element) => {
      element.style.height = ''
      el.classList.remove('collapsed')
      element.classList.remove('collapsed')
    })
  } else {
    e.currentTarget.classList.add('collapsed')

    els.forEach((element) => {
      el.classList.add('collapsed')
      const bounding = element.getBoundingClientRect()
      element.style.height = bounding.height + 'px'
    })
    setTimeout(() => {
      els.forEach((element) => {
        element.classList.add('collapsed')
        element.style.height = ''
      })
    }, 100)
  }
}

/**
 * 해당 value에 매칭되는 key return
 * @param object : key는 string, value는 number | string 인 객체
 * @param value : value는 number | string
 * @returns
 */
export const getKeyByValue = (
  object: { [key in string]: string | number },
  value: string | number | undefined | null
) => {
  return Object.keys(object).find((key) => object[key] === value)
}

/**
 * 스크롤시 가로 스크롤 동작, onWheel에 적용함
 * @param target
 * @param deltaY
 */
export const onHorizonWheel = (target: HTMLElement, deltaY: number) => {
  const scrollableWidth = target.scrollWidth - target.clientWidth
  const nextLeftX = target.scrollLeft + deltaY
  const left =
    nextLeftX > scrollableWidth
      ? scrollableWidth
      : nextLeftX < 0
      ? 0
      : nextLeftX
  target.scroll({
    left: left,
    top: 0,
    behavior: 'smooth',
  })
}

/**
 * target 날짜 진행중,종료,예정 체크
 * @param target
 * @param start
 * @param end
 * @returns
 */
export const isBetweenPeriod = (
  target: string | Dayjs | null | undefined,
  start: string | Dayjs | null | undefined,
  end: string | Dayjs | null | undefined
) => {
  if (!target || !start || !end) return null
  if (
    !dayjs.isDayjs(target) ||
    !dayjs.isDayjs(dayjs(start)) ||
    !dayjs.isDayjs(dayjs(end))
  )
    return null
  if (dayjs(target).isBetween(start, end, 'day', '[]')) return '진행중'
  if (dayjs(target).isAfter(end)) return '종료'
  if (dayjs(target).isBefore(start)) return '예정'
  return null
}

export const smoothLeftTo = (
  x: number,
  el: HTMLElement | Window = window,
  { duration = 500, offset = 0 }: { duration?: number; offset?: number } = {}
): Promise<number> => {
  const isWindow = (value: any): value is Window => {
    return typeof value.scrollY === 'number'
  }
  const easeOutCubic = (t: number) => --t * t * t + 1
  const startX = isWindow(el) ? el.scrollX : el.scrollLeft
  const difference = x - startX
  const startTime = performance.now()

  if (x === startX + offset) {
    return Promise.resolve(x)
  }

  return new Promise((resolve) => {
    const step = () => {
      const progress = (performance.now() - startTime) / duration
      const amount = easeOutCubic(progress)
      el.scrollTo({ left: startX + amount * difference - offset })
      if (progress < 0.99) {
        window.requestAnimationFrame(step)
      } else {
        resolve(x)
      }
    }
    step()
  })
}

/**
 * object keys 를 타입에 맞게 실행
 *
 * @template T
 * @param {T} value
 * @return {*}  {(keyof T)[]}
 */
export const objectKeys = <T extends object, K extends keyof T>(
  value: T
): K[] => {
  return Object.keys(value) as K[]
}

export const checkPwdRegex = (
  value: string,
  option: 'password' | 'phone' | 'id'
) => {
  const regex =
    option === 'password'
      ? /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+=<>,./?;:‘“|~`{}[\]-])[a-zA-Z0-9!@#$%^&*()_+=<>,./?;:‘“|~`{}[\]-]{8,}$/
      : option === 'phone'
      ? /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/
      : /^[a-z0-9]{6,20}$/

  return regex.test(value)
}

export const valueCheck = <T extends string | number | null | undefined>(
  value: T,
  formatter?: (value: T) => T
) => {
  const nextValue = formatter ? formatter(value) : value
  if (typeof value === 'number') {
    return nextValue
  }
  if (!value) return '-'
  return nextValue
}

export const getFileNameFromUrl = (url: string) => {
  if (!url) return ''
  if (typeof url !== 'string') return ''
  let name = url.split('/')[url.split('/').length - 1]
  name = name.split('?')[0]
  name = decodeURIComponent(name)
  return name
}
export const getCreatedAtFromFileName = (fileName: string) => {
  if (!fileName) return ''
  if (!fileName.includes('Z.')) return ''
  if (!fileName.includes('_')) return ''
  if (!fileName.includes('T')) return ''
  return fileName.split('_')[fileName.split('_').length - 1].split('T')[0]
}

export const payMethodString = (value: string | null) => {
  if (typeof value === 'string' && value.includes('pay')) {
    return '간편결제'
  } else {
    return '일반결제'
  }
}
/**
 * xlsx 파일 다운 로드 (토큰이 필요해서)
 *
 * @param {API_URL_KEYS} type
 * @param {string} url
 * @param {string} fileName
 */
export const blobGetXlsx = async (
  type: API_URL_KEYS,
  url: string,
  fileName: string
) => {
  try {
    const blobResponse = await callAxios(type).get(url, {
      responseType: 'blob',
    })

    const href = URL.createObjectURL(blobResponse.data)
    const link = document.createElement('a')
    link.href = href
    link.setAttribute(
      'download',
      `${fileName}_${dayjs().format('YYYY_MM_DD_HHmmss')}.xlsx`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  } catch (error) {
    handleError(error)
  }
}
