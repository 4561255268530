import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import classNames from 'classnames'
import CheckBoxRound from 'components/CheckBoxRound'
import { MatchInputProps } from 'hooks/useMatchInput'
import { clone } from 'lodash-es'
import {
  AuthSettingCategory,
  DetailAuthSettingPageData,
} from 'pages/System/Admin/AdminPopUp/AdminPopUp.types'
import { PopUpContentPart } from 'pages/System/Admin/AdminPopUp/ContentInput/ContentInput'
import { useCallback } from 'react'
import { FlexColumn, FlexRow } from 'themes/Shared.styles'
import { PageAuthority } from 'types/api/samples/adminWeb'
import { objectKeys } from 'utils/helpers'
import S from './AdminPopUp.styles'

const AuthorityCheckBoxRound = styled(CheckBoxRound)`
  width: 140px;
  .children-box {
    white-space: pre-wrap;
  }
`

interface AuthoritySetFormProps
  extends MatchInputProps<DetailAuthSettingPageData> {}

const AuthoritySetForm = ({
  inputs,
  handleInput,
  fetchInit,
  ...props
}: AuthoritySetFormProps) => {
  const isAllSelected = (category: AuthSettingCategory) => {
    return inputs[category].every((item) => item.code === 'all' || item.active)
  }

  const toggleAll = (category: AuthSettingCategory) => {
    const allActiveOptions = inputs[category].map((item) => ({
      ...item,
      active: !isAllSelected(category),
    }))
    handleInput(category, allActiveOptions)
  }
  const toggleOne = useCallback(
    (value: PageAuthority, category: AuthSettingCategory) => {
      handleInput(category, (prev) => {
        let temp = clone([...prev[category]])
        return temp.map((item) =>
          item.code === value ? { ...item, active: !item.active } : item
        )
      })
    },
    [handleInput]
  )

  return (
    <S.ContentWrapper>
      <FlexColumn
        gap={32}
        className="padding-wrapper"
        _css={css`
          * .flex-row {
            width: 590px;
            flex-wrap: wrap;
          }
        `}
      >
        {objectKeys(inputs).map((category) => {
          return (
            <PopUpContentPart _title={category} key={category}>
              <FlexRow
                gap={10}
                className="flex-row"
                alignItems="flex-start"
                justifyContent="space-between"
                _css={css`
                  & > div.flex-start > div {
                    align-items: flex-start;
                  }
                `}
              >
                {inputs[category].map((item) => {
                  const { code, label, active, flexStart } = item
                  return (
                    <AuthorityCheckBoxRound
                      key={category + item.code}
                      className={classNames({ 'flex-start': flexStart })}
                      _mini
                      _active={
                        code === 'all' ? isAllSelected(category) : active
                      }
                      onClick={() => {
                        if (code === 'all') {
                          toggleAll(category)
                          return
                        }
                        toggleOne(code, category)
                      }}
                    >
                      {label}
                    </AuthorityCheckBoxRound>
                  )
                })}
              </FlexRow>
            </PopUpContentPart>
          )
        })}
      </FlexColumn>
    </S.ContentWrapper>
  )
}
export default AuthoritySetForm
