import { css } from '@emotion/react'
import { PrimaryButton } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import DownloadSpreadSheet from 'components/DownloadSpreadSheet'
import { rebuildSpreadSheetDataForApi } from 'components/DownloadSpreadSheet/SelectFilterForSpreadSheet/SelectFilterForSpreadSheet'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import PeriodDropdownFilter from 'components/TableComponent/PeriodDropdownFilter'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  isTimeFilter,
} from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import TableCell from 'components/TableComponent/TableCell'
import { TableRow } from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import useFetch from 'hooks/useFetch'
import useSearchFilter from 'hooks/useSearchFilter'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow } from 'themes/Shared.styles'
import {
  blobGetXlsx,
  cn,
  parserForPeriodQuery,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
} from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import S from './PartnerList.styles'
import {
  ApiPartnerItem,
  PARTNER_QUERY_DROPDOWN_LIST,
  PartnerListProps,
} from './PartnerList.types'

// EDIT:THIS
type ResponseKeyName = {
  id: string
  type: string
  name: string
  phone: string
  createdAt: string
}

// EDIT:THIS
const PartnerListTableConfigs: TableCellConfig<ResponseKeyName>[] = [
  {
    _type: 'default',
    _id: 'name',
    _size: 120,
    _label: '파트너명',
  },
  {
    _type: 'default',
    _id: 'phone',
    _size: 'auto',
    _label: '전화번호',
  },
  {
    _type: 'order',
    _id: 'createdAt',
    _size: 160,
    _label: '가입일',
    _order: 'INITIAL',
    _position: 'left',
  },
]

// EDIT:THIS
const responseParser = (
  response: DoubleDListWithPaging<ApiPartnerItem>
): ResponseKeyName[] => {
  const rebuild = response.list.map((item: ApiPartnerItem, index: number) => {
    return {
      id: String(item.id),
      name: String(item.name),
      phone: String(item.phone),
      createdAt: String(item.createdAt),
    } as ResponseKeyName
  })
  return rebuild
}

const PartnerList = ({ _css, ...props }: PartnerListProps) => {
  const navigate = useNavigate()
  const topInfoRef = useRef() as MutableRefObject<HTMLDivElement>
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [tableHeaderOffset, set_tableHeaderOffset] = useState<number>(0)
  const [headerOffset, set_headerOffset] = useState<number>(0)

  const [stats] = useFetch<{ total: number }>('user', `/partner/stats`)
  useEffect(() => {
    if (topInfoRef.current && headerRef.current) {
      const headerSize =
        topInfoRef.current.getBoundingClientRect().height +
        headerRef.current.getBoundingClientRect().height
      set_headerOffset(topInfoRef.current.getBoundingClientRect().height)
      set_tableHeaderOffset(headerSize)
    }
  }, [])

  const [selectedId, set_selectedId] = useState<string>('')

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: PERIOD_DROPDOWN_INITIAL_VALUE,
      queryList: PARTNER_QUERY_DROPDOWN_LIST,
    },
    // query object -> filterList
    (
      {
        startAt,
        endAt,
        month,
        year,
        periodType,
        searchType,
        searchValue,
        ...rest
      },
      queryName
    ) => {
      return {
        period: {
          start: startAt ? dayjs(startAt, 'YYYY-MM-DD') : null,
          end: endAt ? dayjs(endAt, 'YYYY-MM-DD') : null,
        },
        monthValue: month ?? '',
        yearValue: year ?? '',
        periodType: isTimeFilter(periodType) ? periodType : 'all',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    // filterList => query object
    ({ queryName = 'search', terms = '', ...rest }) => {
      return removeUndefined({
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        ...parserForPeriodQuery(rest),
      })
    }
  )

  const apiURI = useMemo(() => {
    // EDIT:THIS
    return `/partner/list?take=10${
      window.location.search ? '&' + searchParams.toString() : ''
    }`
  }, [searchParams])

  return (
    <ContentsBox _type="relative" _css={css``}>
      {/* ContentsBox에서 스타일 상속 받음 */}
      <S.Wrap _css={_css} {...props}>
        {/* EDIT:THIS */}
        <TopInfo ref={topInfoRef}>
          <TopInfoRow
            _label={`전체`}
            _contents={`${thousand(stats?.total)}명`}
          />
        </TopInfo>
        {/* EDIT:THIS */}
        <TableTitle
          ref={headerRef}
          _css={css`
            top: ${headerOffset}px;
          `}
          _left={`파트너 리스트`}
          _right={
            <FlexRow gap={16}>
              <DownloadSpreadSheet
                _noPeriod
                _tableConfig={['가입일', '파트너명', '전화번호', '회원상태']}
                _emitValue={async (value) => {
                  const rebuild = rebuildSpreadSheetDataForApi(value)
                  blobGetXlsx(
                    'user',
                    `/partner/excel${rebuild.queryString}`,
                    '파트너_목록'
                  )
                }}
              />
              <PrimaryButton
                _css={css`
                  width: 160px;
                  height: 40px;
                `}
                onClick={() => {
                  navigate(`/member/partner/new`)
                }}
              >
                파트너 등록
              </PrimaryButton>
            </FlexRow>
          }
        />
        {/* EDIT:THIS */}
        <FilterPart<PeriodDropdownValuesType>
          _value={initialValues}
          _placeholder="주문번호 또는 주문자명, 상품명 검색"
          _queryNameList={PARTNER_QUERY_DROPDOWN_LIST}
          _queryNameFormat={{ phone: 'phone' }}
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _FilterContents={({ _value, ...props }) => {
            return <PeriodDropdownFilter _value={_value} {...props} />
          }}
        />
        {/* EDIT:THIS */}
        <RoundFillBox>
          <TableComponent<ResponseKeyName>
            _type="user"
            _url={apiURI}
            _topOffset={tableHeaderOffset}
            _config={PartnerListTableConfigs}
            _parser={(response: DoubleDListWithPaging<ApiPartnerItem>) => {
              const rebuild = responseParser(response)
              return {
                _list: rebuild,
                _cursor: disposeCursor(response.paging.cursor, 'cursor'),
              }
            }}
            _EmptyContent={`파트너가 없습니다`}
            _ListContent={({ _row, _index }) => {
              const rowId = _row.id ? String(_row.id) : String(_index)
              return (
                <TableRow
                  className={cn({
                    active: selectedId === rowId,
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    set_selectedId(rowId)
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation()
                    // EDIT:THIS !
                    navigate(`/member/partner/${rowId}`)
                  }}
                >
                  {PartnerListTableConfigs.map((item, columnIndex) => {
                    // EDIT:THIS
                    const _id = item._id as keyof ResponseKeyName
                    const _value = _row[_id]
                    return (
                      <TableCell
                        key={'body' + _index + columnIndex}
                        _position={item._position}
                        _size={item._size}
                      >
                        {_id === 'createdAt' ? (
                          <div>
                            {dayjs(_value).format('YYYY-MM-DD(dd) HH:mm')}
                          </div>
                        ) : _id === 'phone' ? (
                          <div>{parseToFormattedStr(_value, 'phone')}</div>
                        ) : (
                          <div>{_value}</div>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrap>
    </ContentsBox>
  )
}
export default PartnerList
