import { PrimaryButton } from 'components/Buttons'
import { cn } from 'utils/helpers'
import S from './MiniPopup.styles'
import { isJSXComponent, MiniPopupProps } from './MiniPopup.types'

const MiniPopup = ({
  _css,
  _buttonHandler,
  _title,
  children,
  className,
  _buttonText,
  onClose,
  ...props
}: MiniPopupProps) => {
  return (
    <>
      <S.Wrap className={cn('modal-wrap', className)} _css={_css} {...props}>
        <S.Contents className="modal-contents">
          <S.Title className="modal-title">{_title}</S.Title>
          <S.Body>{children}</S.Body>
        </S.Contents>
        <S.Bottom className="modal-bottom">
          {_buttonHandler && isJSXComponent(_buttonHandler) ? (
            _buttonHandler
          ) : (
            <>
              <PrimaryButton
                onClick={(e) => {
                  e.stopPropagation()
                  if (_buttonHandler) {
                    _buttonHandler()
                  } else {
                    onClose!()
                  }
                }}
              >
                {_buttonText ?? '확인'}
              </PrimaryButton>
            </>
          )}
        </S.Bottom>
      </S.Wrap>
    </>
  )
}
export default MiniPopup
