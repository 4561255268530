import { css } from '@emotion/react'
import { GraphRatioType } from 'pages/Main/Main.types'
import { DonutChart } from 'pages/Main/components/DonutChart/DonutChart'
import { useEffect } from 'react'
import { FlexRow, TXT } from 'themes/Shared.styles'
import { colors } from 'themes/styles'
import { onHorizonWheel } from 'utils/helpers'
import S from './PeriodRatioBox.styles'

const DATA_COLOR = {
  기타: colors['Grayscale/Gray Lighter'],
  바닥: colors['Accent/Default'],
  타일: colors['Warning/Default'],
  '도배 + 청소': colors['Accent/Dark'],
  도배: colors['Success/Default'],
  청소: colors['Primary/Default'],
} as const
export interface PeriodRatioBoxProps {
  data: GraphRatioType | null
}
const isMainCategoryName = (
  value: string
): value is keyof typeof DATA_COLOR => {
  return value !== undefined && Object.keys(DATA_COLOR).includes(value)
}
const getPercent = (value: number, total: number) => {
  if (!value) return 0
  return (value / total) * 100
}
export const PeriodRatioBox = ({ data, ...props }: PeriodRatioBoxProps) => {
  // 가로 스크롤 시 세로 스크롤 막기
  useEffect(() => {
    function onwheel(e: WheelEvent) {
      const eventTarget = e.target as HTMLElement
      const currentTarget = eventTarget.closest('.overflow') as HTMLElement
      if (currentTarget) {
        e.preventDefault()
        onHorizonWheel(currentTarget, e.deltaY)
      }
    }
    window.addEventListener('wheel', onwheel, { passive: false })
    return () => {
      window.removeEventListener('wheel', onwheel)
    }
  }, [])
  if (!data) return <></>
  return (
    <S.Wrapper>
      <div className="title">서비스 비율</div>
      <S.GraphWrapper>
        <DonutChart
          _data={data.serviceCounts.map((item) => ({
            name: item.name,
            value: getPercent(item.count, data.total),
          }))}
          _colors={data.serviceCounts.map((item) =>
            isMainCategoryName(item.name) && DATA_COLOR[item.name]
              ? DATA_COLOR[item.name]
              : '#cccccc'
          )}
        />
        <div className="list">
          {data.serviceCounts.map((item, index) => (
            <div className="row" key={item.name}>
              <FlexRow gap={10}>
                <S.ListDot
                  _css={css`
                    background-color: ${isMainCategoryName(item.name) &&
                    DATA_COLOR[item.name]
                      ? DATA_COLOR[item.name]
                      : '#cccccc'};
                  `}
                />
                {item.name}
              </FlexRow>
              <span>{getPercent(item.count, data.total) + '%'}</span>
            </div>
          ))}
        </div>
      </S.GraphWrapper>
      <S.RoundBoxWrapper>
        <div className="title">기타 서비스 비율</div>
        <div
          className="overflow"
          // onWheel={(e) => {
          //   onHorizonWheel(e)
          // }}
        >
          <div className="flex-row">
            {data.etcCounts.map((item) => (
              <div className="box" key={item.name + item.count}>
                {item.name}
                <span>{item.count + '건'}</span>
              </div>
            ))}
            {data.etcCounts.length < 1 && (
              <TXT
                _textStyle="Headline/Bold"
                _color="Grayscale/Gray Lighter"
                _css={css`
                  position: relative;
                  top: 8px;
                `}
              >
                등록된 기타 서비스가 없습니다.
              </TXT>
            )}
          </div>
        </div>
      </S.RoundBoxWrapper>
    </S.Wrapper>
  )
}
