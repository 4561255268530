import { css } from '@emotion/react'
import { ImagesDetailsArrowRightCircle } from 'assets'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import DraggablePopup from 'components/DraggablePopup'
import { useGallery } from 'components/Gallery/Gallery.hooks'
import PhotoUploadBox from 'components/PhotoUploadBox'
import PopupInputLayout from 'components/PopupInputLayout'
import UnderlineInput from 'components/UnderlineInput'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useMatchInput from 'hooks/useMatchInput'
import { useEffect } from 'react'
import { FlexRow, TXT } from 'themes/Shared.styles'
import S from './ConstructionInfo.styles'
import {
  ConstructionInfoProps,
  CONSTRUCTION_INITIAL_VALUE,
} from './ConstructionInfo.types'

const ConstructionInfo = ({
  _css,
  _open,
  _id,
  _galleryId,
  _item,
  _type,
  _onClose,
  _emitValue,
  ...props
}: ConstructionInfoProps) => {
  const { inputs, handleInput, handleValues } = useMatchInput(
    CONSTRUCTION_INITIAL_VALUE
  )
  const trueWhenEmpty = useEmptyCheck(inputs, '360도 사진', 'id', '사진')
  useEffect(() => {
    if (_item) {
      handleValues(_item)
    }
  }, [_id, handleValues, _item])
  const { openGallery, currentGallery } = useGallery((updated) => {
    const updatedWith360 = _item
      ? updated.data[_type]?.find((data) => data.id === _item.id)?.list[
          '360 사진'
        ] ?? []
      : []
    const updatedWithSite = _item
      ? updated.data[_type]?.find((data) => data.id === _item.id)?.list[
          '사진'
        ] ?? []
      : []
    handleInput('360도 사진', updatedWith360)
    handleInput('사진', updatedWithSite)
  })
  const with360 = _item
    ? currentGallery.data[_type]?.find((data) => data.id === _item.id)?.list[
        '360 사진'
      ] ?? []
    : []
  const withSite = _item
    ? currentGallery.data[_type]?.find((data) => data.id === _item.id)?.list[
        '사진'
      ] ?? []
    : []
  return (
    <>
      <DraggablePopup
        _title={
          (_type === '시공전 사진' ? '시공 전 ' : '시공 후 ') +
          (_id ? ` 정보` : ` 정보 입력`)
        }
        _open={true}
        _onClose={_onClose}
        _bottom={
          <>
            <GrayScaleFill onClick={_onClose}>취소</GrayScaleFill>
            <PrimaryButton
              disabled={trueWhenEmpty}
              onClick={() => {
                _emitValue({ ...inputs, id: inputs.id })
              }}
            >
              확인
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <PopupInputLayout _title="제목" _required={true} _gap={4}>
            <UnderlineInput
              value={inputs.title}
              handleValue={(value) => handleInput('title', value)}
            />
          </PopupInputLayout>
          <PopupInputLayout _title="사진">
            <FlexRow
              gap={8}
              onClick={() => {
                if (!_item || !(withSite.length > 0)) return
                if (!inputs.id) return
                openGallery({
                  ...currentGallery,
                  target: [_type, inputs.id, '사진'],
                })
              }}
            >
              {(!_item || !(withSite.length > 0)) && (
                <PhotoUploadBox
                  _type={'task'}
                  _url={'/construction/image'}
                  _optionValue={{
                    ..._galleryId,
                    isImage360: false,
                    isBefore: _type === '시공전 사진',
                  }}
                  _emitValue={(value) => {
                    handleInput('사진', value)
                  }}
                />
              )}
              <TXT
                _css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                `}
                _color={
                  inputs['사진'].length > 0
                    ? 'Grayscale/Black light'
                    : 'Grayscale/Gray Light'
                }
                _textStyle="Body/Large/Bold"
              >
                {_item && withSite.length > 0 ? (
                  <>
                    {`등록된 사진 ${withSite.length}개`}
                    <img src={ImagesDetailsArrowRightCircle} alt="" />
                  </>
                ) : inputs['사진'].length ? (
                  `등록된 사진 ${inputs['사진'].length}개`
                ) : (
                  '등록된 사진 없음'
                )}
              </TXT>
            </FlexRow>
          </PopupInputLayout>
          <PopupInputLayout _title="360도 사진">
            <FlexRow
              gap={8}
              onClick={() => {
                if (!_item || !(with360.length > 0)) return
                if (!inputs.id) return
                openGallery({
                  ...currentGallery,
                  target: [_type, inputs.id, '360 사진'],
                })
              }}
            >
              {(!_item || !(with360.length > 0)) && (
                <PhotoUploadBox
                  _type={'task'}
                  _url={'/construction/image'}
                  _optionValue={{
                    ..._galleryId,
                    isImage360: true,
                    isBefore: _type === '시공전 사진',
                  }}
                  multiple={false}
                  _emitValue={(value) => {
                    handleInput('360도 사진', [value[0]])
                  }}
                />
              )}
              <TXT
                _css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                `}
                _color={
                  inputs['360도 사진'].length > 0
                    ? 'Grayscale/Black light'
                    : 'Grayscale/Gray Light'
                }
                _textStyle="Body/Large/Bold"
              >
                {_item && with360.length > 0 ? (
                  <>
                    {`등록된 사진 ${with360.length}개`}
                    <img src={ImagesDetailsArrowRightCircle} alt="" />
                  </>
                ) : inputs['360도 사진'].length ? (
                  `등록된 사진 ${inputs['360도 사진'].length}개`
                ) : (
                  '등록된 사진 없음'
                )}
              </TXT>
            </FlexRow>
          </PopupInputLayout>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default ConstructionInfo
