import {
  ImagesSideBarBuilding,
  ImagesSideBarBuildingActive,
  ImagesSideBarCalendar,
  ImagesSideBarCalendarActive,
  ImagesSideBarCategory,
  ImagesSideBarCategoryActive,
  ImagesSideBarChevronDown,
  ImagesSideBarChevronUp,
  ImagesSideBarEstimate,
  ImagesSideBarEstimateActive,
  ImagesSideBarGraph,
  ImagesSideBarGraphActive,
  ImagesSideBarHome,
  ImagesSideBarHomeActive,
  ImagesSideBarSupport,
  ImagesSideBarSupportActive,
  ImagesSideBarWallet,
  ImagesSideBarWalletActive,
} from 'assets'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export interface SideBarProps extends DivAttributes {
  _css?: AdditionalCss
}
export type BothIconType = {
  default: string
  active: string
}
export const chevronIcon: BothIconType = {
  default: ImagesSideBarChevronDown,
  active: ImagesSideBarChevronUp,
}
export type NoChildNavType = {
  label: string
  to: string
  icon: BothIconType
}
export type HasChildNavType = {
  label: string
  icon: BothIconType
  parent: string
  children: {
    label: string
    to: string
    count: number
  }[]
}
export const isNoChildNav = (value: object): value is NoChildNavType => {
  return 'to' in value && 'label' in value && 'icon' in value
}
export type NavListType = (NoChildNavType | HasChildNavType)[]
export const NavList: NavListType = [
  {
    label: '홈',
    to: '/',
    icon: {
      default: ImagesSideBarHome,
      active: ImagesSideBarHomeActive,
    },
  },
  {
    label: '시공 관리',
    icon: {
      default: ImagesSideBarCalendar,
      active: ImagesSideBarCalendarActive,
    },
    parent: '/work',
    children: [
      {
        label: '견적서',
        to: '/work/estimates',
        count: 0,
      },
      {
        label: '주문 완료',
        to: '/work/ordered',
        count: 0,
      },
      {
        label: '시공 대기',
        to: '/work/waiting',
        count: 0,
      },
      {
        label: '진행중',
        to: '/work/ongoing',
        count: 0,
      },
      {
        label: '시공 완료',
        to: '/work/completed',
        count: 0,
      },
      {
        label: '시공 취소',
        to: '/work/cancelled',
        count: 0,
      },
      {
        label: '예약 캘린더',
        to: '/work/calendar',
        count: 0,
      },
    ],
  },
  {
    label: '정산 관리',
    icon: {
      default: ImagesSideBarEstimate,
      active: ImagesSideBarEstimateActive,
    },
    parent: '/settlement',
    children: [
      {
        label: '숙련공 정산 대기',
        to: '/settlement/techs/queue',
        count: 0,
      },
      {
        label: '파트너 정산 대기',
        to: '/settlement/partner/queue',
        count: 0,
      },
      {
        label: '숙련공 정산 내역',
        to: '/settlement/techs/history',
        count: 0,
      },
      {
        label: '파트너 정산 내역',
        to: '/settlement/partner/history',
        count: 0,
      },
    ],
  },
  {
    label: '미수금 관리',
    to: '/unpaid',
    icon: {
      default: ImagesSideBarWallet,
      active: ImagesSideBarWalletActive,
    },
  },
  {
    label: '회원 및 건물 관리',
    icon: {
      default: ImagesSideBarBuilding,
      active: ImagesSideBarBuildingActive,
    },
    parent: '/member',
    children: [
      {
        label: '회원',
        to: '/member/user',
        count: 0,
      },
      {
        label: '파트너',
        to: '/member/partner',
        count: 0,
      },
      {
        label: '건물',
        to: '/member/building',
        count: 0,
      },
    ],
  },
  {
    label: '서비스 관리',
    icon: {
      default: ImagesSideBarGraph,
      active: ImagesSideBarGraphActive,
    },
    parent: '/services',
    children: [
      {
        label: '서비스 지역',
        to: '/services/area',
        count: 0,
      },
      {
        label: '서비스 설정',
        to: '/services/setting',
        count: 0,
      },
      {
        label: '주문 유입 경로 설정',
        to: '/services/funnel',
        count: 0,
      },
    ],
  },
  {
    label: '시스템 관리',
    icon: {
      default: ImagesSideBarCategory,
      active: ImagesSideBarCategoryActive,
    },
    parent: '/system',
    children: [
      {
        label: '관리자',
        to: '/system/admin',
        count: 0,
      },
      {
        label: '숙련공',
        to: '/system/techs',
        count: 0,
      },
      {
        label: '작업로그',
        to: '/system/log',
        count: 0,
      },
    ],
  },

  {
    label: '고객지원',
    icon: {
      default: ImagesSideBarSupport,
      active: ImagesSideBarSupportActive,
    },
    parent: '/customer',
    children: [
      {
        label: '쿠폰',
        to: '/customer/coupon',
        count: 0,
      },
      {
        label: '포인트',
        to: '/customer/point',
        count: 0,
      },
      {
        label: '이벤트',
        to: '/customer/event',
        count: 0,
      },
      {
        label: '공지사항',
        to: '/customer/notice',
        count: 0,
      },
    ],
  },
]
