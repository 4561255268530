import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'

const DonutChartStyle = {
  Wrapper: styled.div`
    width: 172px;
    height: 172px;
    position: relative;
    .no-data {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      ${typography['Body/Caption/Bold']}
      color: ${colors['Grayscale/Gray Light']};
    }
    path:focus {
      outline: none;
    }
  `,
}
export default DonutChartStyle
