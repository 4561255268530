import { useParams } from 'react-router-dom'
import EstimatesDetails from './EstimatesDetails'
import EstimatesList from './EstimatesList'

const Estimates = () => {
  const { id } = useParams<'id'>()
  return (
    <>
      {id && <EstimatesDetails _id={id} />}
      {!id && <EstimatesList />}
    </>
  )
}
export default Estimates
