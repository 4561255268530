import Badge from 'components/Badge'
import { SERVICE_BADGE_COLOR } from 'components/Badge/Badge'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import CustomerInfo from '../CustomerInfo'
import OrderInfo from '../OrderInfo/OrderInfo'
import { OrderInfoWithCustomerInfoProps } from './OrderInfoWithCustomerInfo.types'

const OrderInfoWithCustomerInfo = ({
  _css,
  inputs,
  ...props
}: OrderInfoWithCustomerInfoProps) => {
  return (
    <>
      <BoxLayout
        _title={`주문 정보`}
        _titleSuffix={
          <>
            {inputs.serviceState !== null && (
              <Badge {...SERVICE_BADGE_COLOR[inputs.serviceState]}>
                {inputs.serviceState}
              </Badge>
            )}
          </>
        }
      >
        <OrderInfo inputs={inputs} />
      </BoxLayout>
      <BoxLayout _title={`회원 정보`}>
        <CustomerInfo inputs={inputs} />
      </BoxLayout>
    </>
  )
}
export default OrderInfoWithCustomerInfo
