import { UnpaidList } from 'pages/Unpaid/UnpaidList/UnpaidList'
import WorkDetails from 'pages/Work/components/WorkDetails'
import { useParams } from 'react-router-dom'

export interface UnpaidProps {}

const Unpaid = (props: UnpaidProps) => {
  const { id } = useParams<'id'>()

  return (
    <>
      {id && <WorkDetails _id={id} _type="unpaid" />}
      {!id && <UnpaidList />}
    </>
  )
}
export default Unpaid
