import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { Images404_error } from 'assets'
import { PrimaryButton } from 'components/Buttons'
import { FlexColumn, TXT } from 'themes/Shared.styles'
import { colors, typography } from 'themes/styles'

const NotFoundPageWrap = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors['Primary/Default']};
  ${typography['Headline/Bold']}
  text-align: center;
  background-color: #fff;
`

interface NotFoundPageProps {}
const NotFoundPage = ({ ...props }: NotFoundPageProps) => {
  return (
    <>
      <NotFoundPageWrap>
        <FlexColumn gap={60} alignItems="center">
          <FlexColumn gap={24} alignItems="center">
            <img src={Images404_error} alt="404 error" />
            <FlexColumn gap={12} alignItems="center">
              <TXT _textStyle="Display/1 Bold" _color="Grayscale/Gray Default">
                죄송합니다. 현재 찾을 수 없는 페이지를 요청하셨습니다.
              </TXT>
              <TXT _textStyle="Title/Regular" _color="Grayscale/Gray Default">
                존재하지 않는 주소를 입력하셨거나,
                <br />
                요청하신 페이지의 서비스 기간이 만료되어 더이상 제공되지
                않습니다.
              </TXT>
            </FlexColumn>
          </FlexColumn>
          <PrimaryButton
            _css={css`
              width: 160px;
              height: 48px;
            `}
            onClick={() => {
              window.location.href = '/'
            }}
          >
            메인으로
          </PrimaryButton>
        </FlexColumn>
      </NotFoundPageWrap>
    </>
  )
}
export default NotFoundPage
