import { useParams } from 'react-router-dom'
import BuildingDetails from './BuildingDetails'
import BuildingList from './BuildingList'

const Building = () => {
  const { id } = useParams<'id'>()
  return (
    <>
      {id && <BuildingDetails _id={id} />}
      {!id && <BuildingList />}
    </>
  )
}
export default Building
