import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setToast } from 'store/toastSlice'
import callAxios, { handleError } from 'utils/callAxios'
import { SendSettlementPopupProps } from './SendSettlementPopup.types'

const SendSettlementPopup = ({
  _css,
  _open,
  _onClose,
  _refresh,
  ...props
}: SendSettlementPopupProps) => {
  const dispatch = useAppDispatch()
  const handleSettlement = async () => {
    try {
      if (_open?._target === 'techs') {
        await callAxios('task').patch(`/settlement/technician/confirm-all`)
      }
      if (_open?._target === 'partner') {
        await callAxios('task').patch(`/settlement/partner/confirm-all`)
      }
      _refresh()
      dispatch(setToast(['정산되었습니다.', 'success']))
      _onClose()
    } catch (error) {
      handleError(error)
    }
  }
  if (!_open?._target) {
    return <></>
  }
  return (
    <>
      <Modal open={!!_open} onClose={_onClose}>
        <MiniPopup
          _title="송금 하셨나요?"
          _buttonHandler={
            <>
              <GrayScaleFill onClick={_onClose}>아니요</GrayScaleFill>
              <PrimaryButton onClick={handleSettlement}>
                일괄 정산확정
              </PrimaryButton>
            </>
          }
        >
          {_open?._target === 'techs' && (
            <>
              송금 후 눌러주세요.
              <br />
              매월 1번만 정산 확정 가능합니다.
              <br />
              정산 확정시 숙련공앱으로 정산내역을 보내줍니다.
            </>
          )}
          {_open?._target === 'partner' && (
            <>
              송금 후 눌러주세요. 매월 1일부터 말일까지 한 번만 정산 확정
              가능합니다. 정산 확정시 파트너 이메일로 정산내역을 전송합니다.
            </>
          )}
        </MiniPopup>
      </Modal>
    </>
  )
}
export default SendSettlementPopup
