import { css } from '@emotion/react'
import { GrayScaleOutline } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import DownloadSpreadSheet from 'components/DownloadSpreadSheet'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import PeriodDropdownFilter from 'components/TableComponent/PeriodDropdownFilter'

import { BadgeComponent } from 'components/Badge/Badge'
import { rebuildSpreadSheetDataForApi } from 'components/DownloadSpreadSheet/SelectFilterForSpreadSheet/SelectFilterForSpreadSheet'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  isTimeFilter,
} from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import TableCell from 'components/TableComponent/TableCell'
import T from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import useFetch from 'hooks/useFetch'
import useSearchFilter from 'hooks/useSearchFilter'
import { clone } from 'lodash-es'
import SendSettlementPopup from 'pages/Settlement/components/SendSettlementPopup'
import {
  SETTLEMENT_TARGET,
  SETTLEMENT_TYPE,
} from 'pages/Settlement/index.types'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow, TXT } from 'themes/Shared.styles'
import {
  blobGetXlsx,
  cn,
  parserForPeriodQuery,
  removeUndefined,
  thousand,
} from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import S from './SettlementList.styles'
import {
  ApiPartnerSettlementListItem,
  ApiTechsSettlementListItem,
  SettlementListProps,
} from './SettlementList.types'

const TECHS_TYPE_DROPDOWN_LIST = {
  전체: '',
  수수다: '수수다',
  프리랜서: '프리랜서',
} as const

const isTechsType = (
  value: string | number
): value is '프리랜서' | '수수다' => {
  return (
    typeof value === 'string' && (value === '프리랜서' || value === '수수다')
  )
}
type TechsOrPartnerSettlementListItemType = {
  id: number
  techsType: '프리랜서' | '수수다'
  completeDate: string
  recentSettlementDate: string
  name: string
  phone: string

  settlementAmount: string

  accountInfo: string
}

const parserForPartnersSettlementList = (
  response: DoubleDListWithPaging<ApiPartnerSettlementListItem>
) => {
  const rebuild = response.list.map(
    (item: ApiPartnerSettlementListItem, index: number) => {
      return {
        id: item.id,
        recentSettlementDate: item.settlementAt
          ? dayjs(item.settlementAt).format('YYYY-MM-DD HH:mm')
          : '-',
        name: item.partnerName,
        phone: parseToFormattedStr(item.partnerPhone, 'phone'),
        settlementAmount: thousand(item.totalWages) + '원',
      } as TechsOrPartnerSettlementListItemType
    }
  )
  return {
    _list: rebuild,
    _cursor: disposeCursor(response.paging.cursor, 'cursor'),
  }
}
const parserForTechsSettlementList = (
  response: DoubleDListWithPaging<ApiTechsSettlementListItem>
) => {
  const rebuild = response.list.map(
    (item: ApiTechsSettlementListItem, index: number) => {
      return {
        id: item.id,
        techsType: item.isFreelancer ? '프리랜서' : '수수다',
        completeDate: item.taskFinishedAt
          ? dayjs(item.taskFinishedAt).format('YYYY-MM-DD HH:mm')
          : '-',
        recentSettlementDate: item.settlementAt
          ? dayjs(item.settlementAt).format('YYYY-MM-DD HH:mm')
          : '-',
        name: item.technicianName,
        phone: parseToFormattedStr(item.technicianPhone, 'phone'),
        settlementAmount: thousand(item.totalWages) + '원',
      } as TechsOrPartnerSettlementListItemType
    }
  )
  return {
    _list: rebuild,
    _cursor: disposeCursor(response.paging.cursor, 'cursor'),
  }
}

const partnerDefaultTableConfigs: TableCellConfig<TechsOrPartnerSettlementListItemType>[] =
  [
    {
      _type: 'order',
      _id: 'recentSettlementDate',
      _label: '최근 정산일',
      _order: 'INITIAL',
      _size: 160,
      _isNumber: true,
    },
    {
      _type: 'default',
      _id: 'name',
      _label: '이름',
      _size: 130,
    },
    {
      _type: 'default',
      _id: 'phone',
      _label: '전화번호',
      _size: 'auto',
      _position: 'left',
    },
    {
      _type: 'empty',
      _id: 'accountInfo',
      _label: '계좌 정보',
    },
  ]
const techsDefaultTableConfigs: TableCellConfig<TechsOrPartnerSettlementListItemType>[] =
  [
    {
      _type: 'dropdown',
      _id: 'techsType',
      _label: '소속',
      _size: 106,
      _position: 'center',
      _data: TECHS_TYPE_DROPDOWN_LIST,
    },
    {
      _type: 'order',
      _id: 'completeDate',
      _label: '시공 완료일',
      _order: 'INITIAL',
      _size: 160,
    },
    {
      _type: 'order',
      _id: 'recentSettlementDate',
      _label: '최근 정산일',
      _order: 'INITIAL',
      _size: 160,
      _isNumber: true,
    },
    {
      _type: 'default',
      _id: 'name',
      _label: '이름',
      _size: 120,
    },
    {
      _type: 'default',
      _id: 'phone',
      _label: '전화번호',
      _size: 'auto',
      _position: 'left',
    },
  ]

export const SETTLEMENT_QUERY_DROPDOWN_LIST = {
  전화번호: 'phone',
  이름: 'name',
} as const

const SettlementList = ({
  _css,
  _type,
  _target,
  ...props
}: SettlementListProps) => {
  const [stats] = useFetch<{
    total: number
    free?: number
    head?: number
    totalWages?: number
  }>(
    'task',
    `/settlement/${_target === 'partner' ? 'partner' : 'technician'}/stats${
      _type === 'history' ? '/done' : ''
    }`
  )
  const defaultSettlementTableConfigs = useMemo(() => {
    return {
      techs: {
        configs: techsDefaultTableConfigs,
        parser: parserForTechsSettlementList,
        api: `/settlement/technician/list?status=${
          _type === 'queue' ? '정산 대기' : '정산 완료'
        }`,
      },
      partner: {
        configs: partnerDefaultTableConfigs,
        parser: parserForPartnersSettlementList,
        api: `/settlement/partner/list?status=${
          _type === 'queue' ? '정산 대기' : '정산 완료'
        }`,
      },
    }
  }, [_type])

  const navigate = useNavigate()
  const topInfoRef = useRef() as MutableRefObject<HTMLDivElement>
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [tableHeaderOffset, set_tableHeaderOffset] = useState<number>(0)
  const [headerOffset, set_headerOffset] = useState<number>(0)
  const settlementForTechsListTableConfigs = useMemo(() => {
    let cloned = clone(defaultSettlementTableConfigs[_target].configs)
    if (_type === 'queue') {
      cloned = [
        ...cloned,
        {
          _type: 'default',
          _id: 'settlementAmount',
          _label: '정산 대기 금액',
          _size: 'auto',
          _position: 'right',
        },
      ]
    }
    return cloned
  }, [defaultSettlementTableConfigs, _target, _type])
  useEffect(() => {
    if (topInfoRef.current && headerRef.current) {
      const headerSize =
        topInfoRef.current.getBoundingClientRect().height +
        headerRef.current.getBoundingClientRect().height
      set_headerOffset(topInfoRef.current.getBoundingClientRect().height)
      set_tableHeaderOffset(headerSize)
    }
  }, [])

  const [selectedId, set_selectedId] = useState<string>('')

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: PERIOD_DROPDOWN_INITIAL_VALUE,
      queryList: SETTLEMENT_QUERY_DROPDOWN_LIST,
    },
    ({ startAt, endAt, month, year, periodType, ...rest }, queryName) => {
      return {
        period: {
          start: startAt ? dayjs(startAt, 'YYYY-MM-DD') : null,
          end: endAt ? dayjs(endAt, 'YYYY-MM-DD') : null,
        },
        monthValue: month ?? '',
        yearValue: year ?? '',
        periodType: isTimeFilter(periodType) ? periodType : 'all',
        terms: queryName && rest[queryName] ? String(rest[queryName]) : '',
        queryName: queryName ?? '',
      }
    },
    // filterList => query object로 파싱하세요. 주소 search query로 갑니다.
    ({ queryName = 'phone', terms = '', ...rest }) => {
      return removeUndefined({
        [queryName]: terms,
        ...parserForPeriodQuery(rest),
      })
    }
  )
  const apiURI = useMemo(() => {
    return `${defaultSettlementTableConfigs[_target].api}${
      window.location.search ? '&' + searchParams.toString() : ''
    }`
  }, [defaultSettlementTableConfigs, _target, searchParams])
  const [openSettlementPopup, set_openSettlementPopup] = useState<{
    _type: typeof SETTLEMENT_TYPE[number]
    _target: typeof SETTLEMENT_TARGET[number]
  } | null>(null)
  const [refresh, set_refresh] = useState<number>(0)
  const handleListRefresh = () => {
    set_refresh(Date.now())
  }
  return (
    <>
      {openSettlementPopup && (
        <>
          <SendSettlementPopup
            _refresh={handleListRefresh}
            _open={openSettlementPopup}
            _onClose={() => set_openSettlementPopup(null)}
          />
        </>
      )}
      <ContentsBox _type="relative" _css={css``}>
        {/* ContentsBox에서 스타일 상속 받음 */}
        <S.Wrap _css={_css} {...props}>
          {/* 상단 Fixed 정보 */}
          <TopInfo ref={topInfoRef}>
            <>
              {_target === 'techs' && (
                <>
                  <TopInfoRow
                    _label={`전체`}
                    _contents={`${thousand(stats?.total)}명`}
                  />
                  {_target === 'techs' && (
                    <>
                      <TopInfoRow
                        _label={`수수다 본사`}
                        _contents={`${thousand(stats?.head)}명`}
                      />
                      <TopInfoRow
                        _label={`프리랜서`}
                        _contents={`${thousand(stats?.free)}명`}
                      />
                    </>
                  )}
                  {_type === 'queue' && (
                    <TopInfoRow
                      _label={`정산 대기 금액`}
                      _contents={`${thousand(stats?.totalWages)}뭔`}
                    />
                  )}
                </>
              )}
              {_target === 'partner' && (
                <>
                  <TopInfoRow
                    _label={`전체`}
                    _contents={`${thousand(stats?.total)}명`}
                  />
                  {_type === 'queue' && (
                    <TopInfoRow
                      _label={`정산 대기 금액`}
                      _contents={`${thousand(stats?.totalWages)}뭔`}
                    />
                  )}
                </>
              )}
            </>
          </TopInfo>
          {/* 테이블 타이틀 */}
          <TableTitle
            ref={headerRef}
            _css={css`
              top: ${headerOffset}px;
            `}
            _left={
              <FlexRow
                gap={20}
                _css={css`
                  & > div {
                    white-space: nowrap;
                  }
                `}
              >
                <TXT _textStyle="Display/3 Bold" _color="Grayscale/Gray Dark">
                  {_target === 'partner' ? '파트너' : '숙련공'} 정산{' '}
                  {_type === 'history' ? '내역' : '대기'}
                </TXT>
              </FlexRow>
            }
            _right={
              <FlexRow gap={16}>
                <DownloadSpreadSheet
                  {...(_target === 'techs' && {
                    _dropdownFilters: [
                      {
                        label: '소속',
                        value: '전체',
                        key: 'type',
                        list: { ...TECHS_TYPE_DROPDOWN_LIST, 전체: '전체' },
                      },
                    ],
                  })}
                  {...(_type === 'queue' && { _noPeriod: true })}
                  _tableConfig={
                    _target === 'techs'
                      ? _type === 'queue'
                        ? [
                            '시공 완료일',
                            '최근 정산일',
                            '이름',
                            '전화번호',
                            '정산 대기 금액',
                            '계좌 정보',
                          ]
                        : ['시공 완료일', '최근 정산일', '이름', '전화번호']
                      : _type === 'queue'
                      ? [
                          '최근 정산일',
                          '파트너명',
                          '전화번호',
                          '정산 대기 금액',
                          '계좌 정보',
                        ]
                      : ['최근 정산일', '파트너명', '전화번호']
                  }
                  _emitValue={(value) => {
                    const rebuild = rebuildSpreadSheetDataForApi(value)
                    blobGetXlsx(
                      'task',
                      `/settlement/${
                        _target === 'techs' ? 'technician' : 'partner'
                      }/${_type === 'queue' ? 'wait' : 'done'}/excel${
                        rebuild.queryString
                      }`,
                      `${_target === 'techs' ? '숙련공_' : '파트너_'}/${
                        _type === 'queue' ? '정산_대기' : '정산_내역'
                      }`
                    )
                  }}
                />
                {_type === 'queue' && (
                  <GrayScaleOutline
                    _css={css`
                      width: 160px;
                      height: 40px;
                    `}
                    onClick={() => {
                      set_openSettlementPopup({ _type, _target })
                    }}
                  >
                    일괄 정산확정
                  </GrayScaleOutline>
                )}
              </FlexRow>
            }
          />
          {/* 테이블 필터 */}
          <FilterPart<PeriodDropdownValuesType>
            _value={initialValues}
            _placeholder="주문자명 검색"
            _queryNameList={SETTLEMENT_QUERY_DROPDOWN_LIST}
            _queryNameFormat={{ phone: 'phone' }}
            _emitValue={(value) => {
              handleSetLocation(value)
            }}
            _FilterContents={({ _value, ...props }) => {
              if (_type === 'queue') {
                return <div />
              }
              return <PeriodDropdownFilter _value={_value} {...props} />
            }}
          />
          {/* 테이블 */}
          <RoundFillBox>
            <TableComponent<TechsOrPartnerSettlementListItemType>
              key={refresh}
              _type="task"
              _url={apiURI}
              _topOffset={tableHeaderOffset}
              _config={settlementForTechsListTableConfigs}
              _parser={defaultSettlementTableConfigs[_target].parser}
              _ListContent={({ _row, _index, _configs }) => {
                const rowId = _row.id ? String(_row.id) : String(_index)
                return (
                  <T.Row
                    className={cn({
                      active: selectedId === rowId,
                    })}
                    onClick={(e) => {
                      e.stopPropagation()
                      set_selectedId(rowId)
                    }}
                    onDoubleClick={(e) => {
                      e.stopPropagation()
                      navigate(`/settlement/${_target}/${_type}/${rowId}`)
                    }}
                  >
                    {_configs
                      .filter((item) => item._type !== 'empty')
                      .map((item, columnIndex) => {
                        const _id = item._id
                        const _value = _row[_id]
                        return (
                          <TableCell
                            key={'body' + _index + columnIndex}
                            _position={item._position}
                            _size={item._size}
                          >
                            {_id === 'techsType' && isTechsType(_value) ? (
                              <BadgeComponent _value={_value} />
                            ) : (
                              <div>{_value}</div>
                            )}
                          </TableCell>
                        )
                      })}
                  </T.Row>
                )
              }}
            />
          </RoundFillBox>
        </S.Wrap>
      </ContentsBox>
    </>
  )
}
export default SettlementList
