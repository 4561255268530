import { css } from '@emotion/react'
import { ImagesServicePlus } from 'assets'
import { GrayScaleOutline } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import { RoundBoxRow } from 'components/ContentsBox/ContentsBox.styles'
import S from 'components/DetailsComponents/DetailsComponents.styles'

import RowTab from 'components/RowTab'
import useFetch from 'hooks/useFetch'
import { useEffect, useState } from 'react'
import { ContentBoxWrap, Flex, FlexColumn } from 'themes/Shared.styles'
import { cn } from 'utils/helpers'
import SS from './SelectService.styles'

import {
  ApiCategoryList,
  ApiZoneGroupItem,
  parseForMainService,
  SelectedServiceItemType,
  SERVICE_CUSTOMER_TYPE,
  SERVICE_KIND_TABS,
} from '../Setting.types'
import { SelectServiceProps } from './SelectService.types'
const SelectService = ({ _css, _emitValue, ...props }: SelectServiceProps) => {
  const [currentTab, set_currentTab] =
    useState<typeof SERVICE_KIND_TABS[number]>('메인 서비스 설정')
  const [currentServiceType, set_currentServiceType] =
    useState<typeof SERVICE_CUSTOMER_TYPE[number]>('일반')
  const { Layout: L } = S
  const [response] = useFetch<DoubleDListWithPaging<ApiZoneGroupItem>>(
    'user',
    `/zone-group?take=9999`
  )
  const [selectedItem, set_selectedItem] = useState<SelectedServiceItemType>({
    groupItem: null,
    serviceItem: null,
  })
  const [groups, set_groups] = useState<ApiZoneGroupItem[]>([])

  useEffect(() => {
    if (response) {
      set_groups(response.list)
      if (response.list.length) {
        set_selectedItem((prev) => ({
          ...prev,
          groupItem: response.list[0],
        }))
      }
    }
  }, [response])

  const [mainCategoryResponse] = useFetch<ApiCategoryList[]>(
    'order',
    `/category/list?zoneGroupId=${selectedItem?.groupItem?.id}&type=${
      currentTab === '메인 서비스 설정' ? 'main' : 'sub'
    }&userType=${currentServiceType === '일반' ? 'CUSTOMER' : 'REGULAR'}`,
    {
      disabled:
        selectedItem === null ||
        typeof selectedItem?.groupItem?.id !== 'number',
    }
  )
  return (
    <>
      <ContentsBox>
        <FlexColumn gap={24}>
          <RowTab
            _css={css`
              & > div {
                white-space: nowrap;
                width: 196px;
              }
            `}
            _tabs={SERVICE_KIND_TABS}
            _tab={currentTab}
            _emitValue={(tab) => {
              set_currentTab(tab)
              set_selectedItem((prev) => ({
                ...prev,
                serviceItem: null,
              }))
              set_currentServiceType('일반')
            }}
          />
          <L.Wrap
            _css={css`
              & > div {
                width: 100%;
              }
            `}
            {...props}
          >
            <RoundBoxRow>
              <L.LeftHeader>
                <h1>지역 그룹 선택</h1>
              </L.LeftHeader>
              <L.LeftContents id="left-contents">
                <ContentBoxWrap>
                  <Flex flexWrap="wrap" gap={'12px 12px'}>
                    {groups.map((group) => {
                      return (
                        <SS.GroupButton
                          className={cn({
                            active: selectedItem.groupItem?.id === group.id,
                          })}
                          onClick={() => {
                            set_selectedItem((prev) => ({
                              ...prev,
                              groupItem: group,
                              serviceItem: null,
                            }))
                            set_currentServiceType('일반')
                          }}
                          key={group.id + 'group'}
                        >
                          {group.name}
                        </SS.GroupButton>
                      )
                    })}
                  </Flex>
                </ContentBoxWrap>
              </L.LeftContents>
            </RoundBoxRow>
            <RoundBoxRow>
              <L.LeftHeader>
                <h1>서비스 선택</h1>
                <RowTab
                  _type="badge"
                  className="flex-end"
                  _tabs={SERVICE_CUSTOMER_TYPE}
                  _tab={currentServiceType}
                  _emitValue={(tab) => {
                    set_currentServiceType(tab)
                    set_selectedItem((prev) => ({
                      ...prev,
                      serviceItem: null,
                    }))
                  }}
                />
              </L.LeftHeader>
              <L.RightContents id="right-contents">
                <ContentBoxWrap>
                  <Flex flexWrap="wrap" gap={'12px 12px'}>
                    {currentTab === '메인 서비스 설정' && mainCategoryResponse && (
                      <>
                        {mainCategoryResponse.map((serviceItem) => {
                          return (
                            <SS.ServiceButton
                              key={serviceItem.id}
                              className={cn({
                                active:
                                  selectedItem.serviceItem?.serviceId ===
                                    serviceItem.id &&
                                  currentServiceType ===
                                    selectedItem.serviceItem?.type &&
                                  currentTab === selectedItem.serviceItem?.kind,
                              })}
                              onClick={() => {
                                set_selectedItem((prev) => ({
                                  ...prev,
                                  serviceItem: {
                                    ...prev.serviceItem,
                                    serviceName: serviceItem.name,
                                    kind: currentTab,
                                    type: currentServiceType,
                                    serviceId: serviceItem.id,
                                    serviceType: parseForMainService(
                                      serviceItem.name
                                    ),
                                  },
                                }))
                              }}
                            >
                              {serviceItem.name}
                            </SS.ServiceButton>
                          )
                        })}
                      </>
                    )}
                    {currentTab === '기타서비스 설정' && mainCategoryResponse && (
                      <>
                        {mainCategoryResponse.map((serviceItem) => {
                          return (
                            <SS.ServiceButton
                              key={serviceItem.id}
                              className={cn('etc', {
                                disabled: !serviceItem.isActive,
                                active:
                                  selectedItem.serviceItem?.serviceId ===
                                    serviceItem.id &&
                                  currentServiceType ===
                                    selectedItem.serviceItem?.type &&
                                  currentTab === selectedItem.serviceItem?.kind,
                              })}
                              onClick={() => {
                                set_selectedItem((prev) => ({
                                  ...prev,
                                  serviceItem: {
                                    ...prev.serviceItem,
                                    serviceName: serviceItem.name,
                                    kind: currentTab,
                                    type: currentServiceType,
                                    serviceId: serviceItem.id,
                                    serviceType: 'etc',
                                  },
                                }))
                              }}
                            >
                              {!serviceItem.isActive && (
                                <SS.DisabledBadge>비활성</SS.DisabledBadge>
                              )}
                              {serviceItem.iconUrl && (
                                <div
                                  className="img"
                                  style={{
                                    backgroundImage: `url(${serviceItem.iconUrl})`,
                                  }}
                                ></div>
                              )}
                              <div>{serviceItem.name}</div>
                            </SS.ServiceButton>
                          )
                        })}
                        <SS.ServiceButton
                          className="etc add"
                          onClick={() => {
                            let { groupItem, serviceItem } = selectedItem
                            if (groupItem === null) return
                            serviceItem = {
                              serviceName: '',
                              serviceType: 'etc',
                              kind: currentTab,
                              type: currentServiceType,
                            }
                            set_selectedItem((prev) => ({
                              ...prev,
                              serviceItem,
                            }))
                            _emitValue({
                              groupItem,
                              serviceItem,
                            })
                          }}
                        >
                          <img src={ImagesServicePlus} alt="추가" />
                          <div>추가</div>
                        </SS.ServiceButton>
                      </>
                    )}
                  </Flex>
                </ContentBoxWrap>
              </L.RightContents>

              <L.RightBottom>
                <GrayScaleOutline
                  disabled={
                    !selectedItem.groupItem || !selectedItem.serviceItem
                  }
                  width={'100%'}
                  padding={`12px 0px`}
                  onClick={() => {
                    const { groupItem, serviceItem } = selectedItem
                    if (groupItem === null) return
                    if (serviceItem === null) return
                    _emitValue({
                      groupItem,
                      serviceItem,
                    })
                  }}
                >
                  {currentTab === '메인 서비스 설정' ? '다음' : '조회'}
                </GrayScaleOutline>
              </L.RightBottom>
            </RoundBoxRow>
          </L.Wrap>
        </FlexColumn>
      </ContentsBox>
    </>
  )
}
export default SelectService
