import { css } from '@emotion/react'
import Badge from 'components/Badge'
import { GrayScaleOutline, PrimaryButton } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import DownloadSpreadSheet from 'components/DownloadSpreadSheet'
import { rebuildSpreadSheetDataForApi } from 'components/DownloadSpreadSheet/SelectFilterForSpreadSheet/SelectFilterForSpreadSheet'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import TableCell from 'components/TableComponent/TableCell'
import { TableRow } from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TwoInfoOneCell, {
  isNameAndPhone,
  nameAndPhoneProps,
} from 'components/TableComponent/TwoInfoOneCell/TwoInfoOneCell'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import useFetch from 'hooks/useFetch'
import useSearchFilter from 'hooks/useSearchFilter'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow, TXT } from 'themes/Shared.styles'
import {
  blobGetXlsx,
  cn,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
} from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import S from './TechsList.styles'
import {
  APITechListData,
  HAS_MEASUREMENT_DROPDOWN_LIST,
  TECHS_QUERY_DROPDOWN_LIST,
  TECHS_TYPES_BADGE_COLOR_SET,
  TECHS_TYPES_DROPDOWN_LIST,
  TechsListProps,
  TechsTypesDropdownKeyType,
  isTechBadgeType,
} from './TechsList.types'
import WagesForFreelancers from './WagesForFreelancers'

type TechListItemType = {
  id: string
  techsType: TechsTypesDropdownKeyType
  nameAndPhone: nameAndPhoneProps
  hasMeasurement: string
  restReservation: number
  recentWorkDate: string
  dispatchAbleArea: string
  disabled?: boolean
}

// EDIT:THIS
const TablePageTemplateTableConfigs: TableCellConfig<TechListItemType>[] = [
  {
    _type: 'dropdown',
    _id: 'techsType',
    _label: '소속',
    _size: 106,
    _data: TECHS_TYPES_DROPDOWN_LIST,
    _value: '',
    _position: 'center',
  },
  {
    _type: 'default',
    _id: 'nameAndPhone',
    _label: '이름 / 휴대폰 번호',
    _size: 200,
  },
  {
    _type: 'dropdown',
    _id: 'hasMeasurement',
    _label: '실측 여부',
    _size: 120,
    _data: HAS_MEASUREMENT_DROPDOWN_LIST,
    _value: '',
  },
  {
    _type: 'order',
    _id: 'restReservation',
    _label: '남은 예약',
    _order: 'INITIAL',
    _size: 150,
  },
  {
    _type: 'default',
    _id: 'recentWorkDate',
    _label: '최근 작업일',
    _size: 140,
  },
  {
    _type: 'default',
    _id: 'dispatchAbleArea',
    _label: '파견 가능 지역',
    _size: 'auto',
    _position: 'left',
  },
]

// ** Parser for API response data to table row data
const parserForTechsListTable = (
  response: DoubleDListWithPaging<APITechListData>
) => {
  const rebuild = response.list.map((row: APITechListData, index: number) => {
    const {
      id,
      name,
      phone,
      measuring,
      progressingTaskCount,
      lastWorkDate,
      dispatchableZone,
      isFreelancer,
      disabledAt,
    } = row
    return {
      id: id,
      techsType: isFreelancer ? '프리랜서' : '수수다',
      nameAndPhone: {
        name: name,
        phone: parseToFormattedStr(phone, 'phone'),
      },
      hasMeasurement: measuring ? '가능' : '불가',
      restReservation: progressingTaskCount,
      recentWorkDate:
        lastWorkDate && lastWorkDate !== '-'
          ? dayjs(lastWorkDate).format('YYYY-MM-DD')
          : '-',
      dispatchAbleArea: dispatchableZone.map((item) => item.name).join(', '),
      disabled: dayjs(disabledAt).isSame(dayjs(), 'date'),
    } as TechListItemType
  })
  return {
    _list: rebuild,
    _cursor: disposeCursor(response.paging.cursor),
  }
}

const TechsList = ({ _css, ...props }: TechsListProps) => {
  const navigate = useNavigate()
  const topInfoRef = useRef() as MutableRefObject<HTMLDivElement>
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [tableHeaderOffset, set_tableHeaderOffset] = useState<number>(0)
  const [headerOffset, set_headerOffset] = useState<number>(0)
  const [openWagesForFreelancer, set_openWagesForFreelancer] =
    useState<boolean>(false)
  const [selectedId, set_selectedId] = useState<string>('')

  const [stats] = useFetch<{
    total: number
    free: number
    head: number
    measuring: number
  }>('user', `/technician/stats`)
  useEffect(() => {
    if (topInfoRef.current && headerRef.current) {
      const headerSize =
        topInfoRef.current.getBoundingClientRect().height +
        headerRef.current.getBoundingClientRect().height
      set_headerOffset(topInfoRef.current.getBoundingClientRect().height)
      set_tableHeaderOffset(headerSize)
    }
  }, [])

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    { filterList: {}, queryList: TECHS_QUERY_DROPDOWN_LIST },
    ({ searchType, searchValue, ...reset }) => {
      return {
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    ({ queryName, terms = '', ...rest }) => {
      return removeUndefined({
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        ...rest,
      })
    }
  )
  // query -> 백엔드 api url
  const apiURI = useMemo(() => {
    const uri = `/technician/list?take=10${
      searchParams.toString() ? '&' + searchParams.toString() : ''
    }`
    return decodeURI(uri)
  }, [searchParams])

  return (
    <>
      {openWagesForFreelancer && (
        <WagesForFreelancers
          _open={openWagesForFreelancer}
          _onClose={() => {
            set_openWagesForFreelancer(false)
          }}
        />
      )}
      <ContentsBox _type="relative" _css={css``}>
        {/* ContentsBox에서 스타일 상속 받음 */}
        <S.Wrap _css={_css} {...props}>
          {/* EDIT:THIS */}
          <TopInfo ref={topInfoRef}>
            <TopInfoRow
              _label={`전체 숙련공`}
              _contents={`${thousand(stats?.total)}명`}
            />
            <TopInfoRow
              _label={`수수다 본사`}
              _contents={`${thousand(stats?.head)}명`}
            />
            <TopInfoRow
              _label={`프리랜서`}
              _contents={`${thousand(stats?.free)}명`}
            />
            <TopInfoRow
              _label={`실측가능`}
              _contents={`${thousand(stats?.measuring)}명`}
            />
          </TopInfo>
          {/* EDIT:THIS */}
          <TableTitle
            ref={headerRef}
            _css={css`
              top: ${headerOffset}px;
            `}
            _left={`숙련공 관리`}
            _right={
              <FlexRow gap={16}>
                <DownloadSpreadSheet
                  _noPeriod
                  _dropdownFilters={[
                    {
                      label: '필터',
                      value: '전체',
                      key: 'type',
                      list: {
                        전체: '전체',
                        수수다: '수수다',
                        프리랜서: '프리랜서',
                        비활성화: '비활성화',
                      },
                    },
                  ]}
                  _tableConfig={[
                    '소속',
                    '회원명',
                    '전화번호',
                    '실측 여부',
                    '파견 가능 지역',
                  ]}
                  _emitValue={async (value) => {
                    const rebuild = rebuildSpreadSheetDataForApi(value)
                    blobGetXlsx(
                      'user',
                      `/technician/list/excel${rebuild.queryString}`,
                      '숙련공_목록'
                    )
                  }}
                />
                <GrayScaleOutline
                  width={'160px'}
                  height={'40px'}
                  onClick={() => {
                    set_openWagesForFreelancer(true)
                  }}
                >
                  보존 공임 일괄 조정
                </GrayScaleOutline>
                <PrimaryButton
                  width={'160px'}
                  height={'40px'}
                  onClick={() => {
                    navigate(`/system/techs/new`)
                  }}
                >
                  숙련공 추가
                </PrimaryButton>
              </FlexRow>
            }
          />
          {/* EDIT:THIS */}
          <FilterPart<{}>
            _value={initialValues}
            _queryNameList={TECHS_QUERY_DROPDOWN_LIST}
            _queryNameFormat={{ phone: 'phone' }}
            _placeholder="이름 검색"
            _emitValue={(value) => {
              handleSetLocation(value)
            }}
            _FilterContents={({ _value, _emitValue, ...props }) => {
              return <div></div>
            }}
          />
          {/* EDIT:THIS */}
          <RoundFillBox>
            <TableComponent<TechListItemType>
              _type="user"
              _url={apiURI}
              _topOffset={tableHeaderOffset}
              _config={TablePageTemplateTableConfigs}
              _parser={parserForTechsListTable}
              _ListContent={({ _row, _index }) => {
                const rowId = _row.id ? String(_row.id) : String(_index)
                return (
                  <TableRow
                    className={cn({
                      active: selectedId === rowId,
                      disabled: _row['disabled'],
                    })}
                    onClick={(e) => {
                      e.stopPropagation()
                      set_selectedId(rowId)
                    }}
                    onDoubleClick={(e) => {
                      e.stopPropagation()
                      // EDIT:THIS !
                      navigate(`/system/techs/${rowId}`)
                    }}
                  >
                    {TablePageTemplateTableConfigs.map((item, columnIndex) => {
                      // EDIT:THIS
                      const _id = item._id
                      const _value = _row[_id]
                      return (
                        <TableCell
                          key={'body' + _index + columnIndex}
                          _position={item._position}
                          _size={item._size}
                        >
                          {_id === 'techsType' && isTechBadgeType(_value) ? (
                            <>
                              <Badge
                                {...TECHS_TYPES_BADGE_COLOR_SET[
                                  _row['disabled'] ? '비활성화' : _value
                                ]}
                              >
                                {_row['disabled'] ? '비활성화' : _value}
                              </Badge>
                            </>
                          ) : _id === 'nameAndPhone' &&
                            isNameAndPhone(_value) ? (
                            <TwoInfoOneCell
                              _topData={_value.name}
                              _bottomData={_value.phone}
                            />
                          ) : _id === 'restReservation' ? (
                            <div>{_value?.toString()}</div>
                          ) : (
                            <>
                              {typeof _value === 'string' && (
                                <TXT
                                  _css={css`
                                    text-overflow: ellipsis;
                                  `}
                                >
                                  {_value}
                                </TXT>
                              )}
                            </>
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              }}
            />
          </RoundFillBox>
        </S.Wrap>
      </ContentsBox>
    </>
  )
}
export default TechsList
