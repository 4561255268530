import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import dayjs from 'dayjs'
import { PaymentInfoValuesType } from 'pages/Work/Work.types'
import { useMemo } from 'react'
import { ContentRadiusBox, Divider } from 'themes/Shared.styles'
import { DivAttributes } from 'themes/styles.type'
import { thousand } from 'utils/helpers'

interface CanceledPaymentInfoProps<T extends PaymentInfoValuesType>
  extends DivAttributes {
  inputs: Pick<T, keyof PaymentInfoValuesType>
}
const CanceledPaymentInfo = <T extends PaymentInfoValuesType>({
  inputs,
  ...props
}: CanceledPaymentInfoProps<T>) => {
  const vbankInfo = useMemo(() => {
    return inputs.paymentInfo?.vbankInfo
  }, [inputs.paymentInfo])

  if (!inputs.paymentInfo) return <></>

  if (!inputs.paymentInfo.cancelledAt) return <></>
  return (
    <>
      <BoxLayout _title={'결제 취소 정보'}>
        <ContentRadiusBox {...props}>
          <BoxRowComponent
            _label={`결제 취소일`}
            _contents={
              inputs.paymentInfo.cancelledAt
                ? dayjs(inputs.paymentInfo.cancelledAt).format(
                    'YYYY-MM-DD HH:mm'
                  )
                : ''
            }
          />
          <BoxRowComponent
            _label={`결제 코드`}
            _contents={inputs.paymentInfo.merchantUid}
          />
          <BoxRowComponent
            _label={`승인 번호`}
            _emptyCheck={!!inputs.paymentInfo.applyNum}
            _emptyType="hide"
            _contents={inputs.paymentInfo.applyNum}
          />
          <Divider />
          {vbankInfo && (
            <>
              <BoxRowComponent
                _label={`은행`}
                _contents={vbankInfo.vbankName}
              />
              <BoxRowComponent
                _label={`계좌 번호`}
                _contents={vbankInfo.vbankNum}
              />
              <BoxRowComponent _label={`예금주`} _contents={vbankInfo.holder} />
              <Divider />
            </>
          )}
          <BoxRowComponent
            _label={`상품 금액`}
            _contents={`${thousand(inputs.paymentInfo.cancelMoney)}원`}
            className="cancel"
          />
          <BoxRowComponent
            _label={`출장비`}
            _emptyCheck={inputs.paymentInfo.cancelDispatchPrice !== 0}
            _emptyType="hide"
            className="cancel"
            _contents={`${thousand(inputs.paymentInfo.cancelDispatchPrice)}원`}
          />
          <BoxRowComponent
            _label={`쿠폰 할인`}
            _emptyCheck={inputs.paymentInfo.cancelCouponDiscount !== 0}
            _emptyType="hide"
            className="cancel"
            _contents={`- ${thousand(
              inputs.paymentInfo.cancelCouponDiscount
            )}원`}
          />
          <BoxRowComponent
            _label={`포인트 할인`}
            _emptyCheck={inputs.paymentInfo.cancelPoint !== 0}
            _emptyType="hide"
            className="cancel"
            _contents={`- ${thousand(inputs.paymentInfo.cancelPoint)}원`}
          />
          <Divider />
          <BoxRowComponent
            _label={<S.TotalLabel>취소 금액</S.TotalLabel>}
            _contents={
              <S.TotalPrice>
                {thousand(inputs.paymentInfo.cancelAmount)}원
              </S.TotalPrice>
            }
            className="cancel"
          />
          <S.PointBadgeBox>
            <S.PointBadge className="cancel">적립취소</S.PointBadge>
            <div>
              {thousand(inputs.paymentInfo.cancelPoint)}
              {'원 '}
              (0.1%)
            </div>
          </S.PointBadgeBox>
        </ContentRadiusBox>
      </BoxLayout>
    </>
  )
}
export default CanceledPaymentInfo
