import { DoubleDFile } from 'types/services.type'
import { GenderType } from 'types/share'

export type ApiPartnerDetails = {
  id: string
  name: string
  phone: string
  gender: 'F' | 'M'
  personalNo: string
  accountNo: string
  email: string
  referralFee: number
  settlementFee: number
  contracts?: {
    id: string
    fileName: string
    contractsUrl: string
  }[]
  createdAt: string
  note: string
}
export type PartnerInfoValuesType = {
  id: string
  profileImage: string

  name: string
  phone: string
  gender: GenderType
  residentNumber: string
  accountNumber: string
  email: string

  chargeLimit: number | null
  settlementCharge: number | null

  contractFiles: DoubleDFile[]
  remarks: string

  buildingInfo: boolean
}
export const PARTNER_INFO_INITIAL_VALUE: PartnerInfoValuesType = {
  id: '',
  profileImage: '',

  name: '',
  phone: '',
  gender: 'M',
  residentNumber: '',
  accountNumber: '',
  email: '',

  chargeLimit: null,
  settlementCharge: null,

  contractFiles: [],
  remarks: '',

  buildingInfo: false,
}
