import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { isObjectValueType } from 'utils/helpers'
export const BUILDING_DROPDOWN_LIST = {
  전체: 'all',
  '지역 그룹': '지역 그룹',
  건물유형: '건물유형',
} as const
export type BuildingKeyType = KeyOf<typeof BUILDING_DROPDOWN_LIST>
export const isBuildingValueType = (
  value: any
): value is typeof BUILDING_DROPDOWN_LIST[BuildingKeyType] => {
  return isObjectValueType(BUILDING_DROPDOWN_LIST, value)
}
export type BuildingFilterListType = {
  types: typeof BUILDING_DROPDOWN_LIST[BuildingKeyType]
}
export const initialBuildingFilterValues: BuildingFilterListType = {
  types: 'all',
}
export const BUILDING_QUERY_DROPDOWN_LIST = {
  건물명: '건물명',
  '도로명 주소': '도로명 주소',
  회원명: '회원명',
  파트너명: '파트너명',
}
export interface BuildingListProps extends DivAttributes {
  _css?: AdditionalCss
}

export type ApiBuildingItem = {
  id: string
  cursor: number
  zoneGroup: { name: string }
  type: string
  name: string
  fullLegalAddress: string
  user: { name: string }
  partner: { name: string }
  createdAt: string
}
