import { createSlice } from '@reduxjs/toolkit'
import { isArray } from 'lodash-es'
import { DoubleDFile } from 'types/services.type'
import { RootState } from '.'

const GALLERY_TITLES = [
  // '문의 사진',
  '요청 사진',
  '실측 사진',
  '시공전 사진',
  '시공후 사진',
] as const

export type GalleryCategoryTitleType = typeof GALLERY_TITLES[number]
export type PhotoWithPanoramaType = {
  '360 사진': DoubleDFile[]
  사진: DoubleDFile[]
  // id?: number
}
export type GalleryChildrenType = {
  id: number | string
  title: string
  list: PhotoWithPanoramaType
}
export const isWith360Type = (
  value: DoubleDFile[] | GalleryChildrenType | PhotoWithPanoramaType | null
): value is PhotoWithPanoramaType => {
  return value !== null && '360 사진' in value && '사진' in value
}
export const isDoubleDArray = (
  value: DoubleDFile[] | GalleryChildrenType[] | null
): value is DoubleDFile[] => {
  return (
    value !== null &&
    value !== undefined &&
    isArray(value) &&
    value.length > 0 &&
    'url' in value[0]
  )
}
export const isGalleryChildrenListType = (
  value: DoubleDFile[] | GalleryChildrenType[] | null
): value is GalleryChildrenType[] => {
  return (
    value !== null &&
    value !== undefined &&
    isArray(value) &&
    value.length > 0 &&
    'title' in value[0]
  )
}

export type CurrentGalleryTarget =
  | [keyof GalleryItemType]
  | [keyof GalleryItemType, keyof PhotoWithPanoramaType]
  | [keyof GalleryItemType, number | string, keyof PhotoWithPanoramaType]

export interface GalleryItemType {
  '요청 사진': DoubleDFile[] | null
  '실측 사진': GalleryChildrenType[] | null
  '시공전 사진': GalleryChildrenType[] | null
  '시공후 사진': GalleryChildrenType[] | null
}
export interface GalleryStatesType {
  data: GalleryItemType
  target?: CurrentGalleryTarget | null
  id?: string | number // 서비스 아이디, 있으면 수정 삭제 가능, 없으면 readOnly
  requestId?: number // 요청 사진 아이디
}
interface galleryState {
  item: GalleryStatesType | null
  result: GalleryStatesType | null
  currentGallery: GalleryStatesType
}
export const initialGalleryData: GalleryStatesType = {
  data: {
    // '문의 사진': null,
    '요청 사진': null,
    '실측 사진': null,
    '시공전 사진': null,
    '시공후 사진': null,
  },
}
const initialState: galleryState = {
  item: null,
  result: null,
  currentGallery: { ...initialGalleryData },
}

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setCurrentGallery: (state, action: { payload: GalleryStatesType }) => {
      state.currentGallery = action.payload
    },
    updateCurrentGalleryAndClean: (
      state,
      action: { payload: GalleryStatesType }
    ) => {
      state.currentGallery = action.payload
      state.item = null
      state.result = null
    },
    setGallery: (state, action: { payload: GalleryStatesType }) => {
      state.item = action.payload
    },
    saveToResultAndClose: (state, action: { payload: GalleryStatesType }) => {
      state.item = null
      state.result = action.payload
    },
    cleanGallery: (state) => {
      state.item = null
      state.result = null
    },
  },
})
export const {
  setGallery,
  cleanGallery,
  saveToResultAndClose,
  setCurrentGallery,
  updateCurrentGalleryAndClean,
} = gallerySlice.actions

export const getCurrentGallery = (state: RootState) =>
  state.gallery.currentGallery
export const selectGallery = (state: RootState) => state.gallery.item
export const updatedGallery = (state: RootState) => state.gallery.result

export default gallerySlice.reducer
