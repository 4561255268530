import { difference, isEqual, uniq } from 'lodash-es'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import callAxios from 'utils/callAxios'
import { getFileNameFromUrl } from 'utils/helpers'

export const useControlQuillUrls = (value: string | string[]) => {
  const [currentAllUrls, set_currentAllUrls] = useState<string[]>([])
  const prevRef = useRef() as MutableRefObject<string | string[]>

  const currentImageUrlsInContent = useMemo(() => {
    if (isEqual(prevRef.current, value)) {
      return null
    }
    prevRef.current = value
    if (!value) return []
    if (typeof value === 'string') {
      const imageTags = value.match(/<img [^>]*src="([^"]+)"[^>]*>/g)
      return imageTags
        ? imageTags.map((x) => x.replace(/.*src="([^"]*)".*/, '$1'))
        : []
    } else {
      if (value.length < 1) return []
      const imageTags = value.join('').match(/<img [^>]*src="([^"]+)"[^>]*>/g)
      return imageTags
        ? imageTags.map((x) => x.replace(/.*src="([^"]*)".*/, '$1'))
        : []
    }
  }, [value])

  useEffect(() => {
    if (currentImageUrlsInContent) {
      set_currentAllUrls((prev) => {
        const nextData = uniq([...prev, ...currentImageUrlsInContent])
        if (isEqual(nextData, prev)) return prev
        return uniq([...prev, ...currentImageUrlsInContent])
      })
    }
  }, [currentImageUrlsInContent])

  const deletedUrl = useMemo(() => {
    return difference(currentAllUrls, currentImageUrlsInContent ?? [])
  }, [currentImageUrlsInContent, currentAllUrls])

  const handleDeleteSharedImages = useCallback(async () => {
    if (deletedUrl) {
      await Promise.all(
        deletedUrl.map(async (url) => {
          return await callAxios('user').delete(
            `/board/notice/image/${getFileNameFromUrl(url)}`
          )
        })
      )
    }
  }, [deletedUrl])
  const handleDeleteAllSharedImages = useCallback(async () => {
    if (currentImageUrlsInContent) {
      await Promise.all(
        currentImageUrlsInContent.map(async (url) => {
          return await callAxios('user').delete(
            `/board/notice/image/${getFileNameFromUrl(url)}`
          )
        })
      )
    }
  }, [currentImageUrlsInContent])

  return {
    currentAllUrls,
    currentImageUrlsInContent,
    deletedUrl,
    handleDeleteSharedImages,
    handleDeleteAllSharedImages,
  }
}
