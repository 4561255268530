import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'
import { AuthorizationDefault } from '../Authorization.styles'

const JoinComponentStyle = {
  Wrap: styled.div`
    ${AuthorizationDefault}
    ${addCssProps}
  `,
  Contents: styled.div`
    padding: 32px 32px 72px;
  `,
  Bottom: styled.div`
    padding: 0px 32px 40px;
  `,
  BoxBottom: styled.div`
    padding: 0px 32px 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
}
export default JoinComponentStyle
