import { css } from '@emotion/react'
import { ImagesDetailsArrowRightCircle, ImagesInputRemove } from 'assets'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import PhotoUploadBox from 'components/PhotoUploadBox'
import { useAppDispatch } from 'hooks/reduxHooks'
import { cloneDeep } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  CurrentGalleryTarget,
  GalleryItemType,
  isDoubleDArray,
  isGalleryChildrenListType,
  setCurrentGallery,
  setGallery,
} from 'store/gallerySlice'
import { FlexRow, TXT } from 'themes/Shared.styles'
import { HandleEllipsisToolTip } from 'utils/helpers'
import UploadImageRowStyle from './UploadImageRow.styles'
import { UploadImageRowProps } from './UploadImageRow.types'

function blobNewWindow(data: string) {
  var image = new Image()
  image.src = data

  var w = window.open('')
  if (w) w.document.write(image.outerHTML)
}
const UploadImageRow = ({
  parent,
  child,
  currentGallery,
  _type,
  _url,
  _optionValue,
  _readOnly,
  _disabledGallery,
  _emitValue,
  ...props
}: UploadImageRowProps) => {
  const dispatch = useAppDispatch()
  const { data } = currentGallery
  const parentCategory = currentGallery.data[parent]

  const getImages = useCallback(
    (
      imageItems: GalleryItemType | null,
      target: CurrentGalleryTarget | null
    ) => {
      if (!imageItems) return []
      if (!target) return []
      let galleryItem = imageItems[target[0]]
      if (isDoubleDArray(galleryItem) && target.length === 1) {
        return galleryItem
      } else if (
        isGalleryChildrenListType(galleryItem) &&
        target.length === 3
      ) {
        const middleTarget = target[1]
        const lastTarget = target[2]
        return (
          galleryItem.find((item) => item.id === middleTarget)?.list?.[
            lastTarget
          ] ?? []
        )
      }
      return []
    },
    []
  )

  const currentImages = useMemo(() => {
    return getImages(data, child ? [parent, child] : [parent])
  }, [parent, child, data, getImages])

  return (
    <>
      {parentCategory !== null && currentImages.length > 0 ? (
        <>
          <BoxRowComponent
            _label={child ?? parent}
            _css={
              _disabledGallery
                ? css`
                    align-items: flex-start;
                    & > label {
                      padding: 12px 0;
                    }
                  `
                : ''
            }
            _contents={
              <>
                {_disabledGallery ? (
                  <>
                    <FlexRow gap={24} alignItems="flex-start">
                      <PhotoUploadBox
                        _type={_type}
                        _url={_url}
                        _optionValue={_optionValue}
                        _emitValue={(value) => {
                          if (_emitValue) _emitValue(value)
                          let cloned = cloneDeep({ ...currentGallery })
                          let withSite = cloned.data[parent]
                          withSite = value
                          dispatch(
                            setCurrentGallery({
                              ...cloned,
                              data: {
                                ...cloned.data,
                                [parent]: withSite,
                              },
                            })
                          )
                        }}
                      />
                      <UploadImageRowStyle.Files
                        _css={css`
                          max-width: calc(100% - 84px - 24px);
                        `}
                      >
                        {currentImages.map((imageUrl) => {
                          return (
                            <FlexRow
                              _css={css`
                                position: relative;
                                width: 100%;
                              `}
                              key={imageUrl.name}
                              gap={10}
                              alignItems="center"
                              onMouseEnter={(e) =>
                                HandleEllipsisToolTip('mouseEnter', e)
                              }
                              onMouseLeave={(e) =>
                                HandleEllipsisToolTip('mouseLeave', e)
                              }
                            >
                              <UploadImageRowStyle.File>
                                <Link
                                  to={imageUrl.url}
                                  download={imageUrl.name}
                                  target="_blank"
                                  {...(!imageUrl.url.includes('http') && {
                                    onClick: (e) => {
                                      e.preventDefault()
                                      blobNewWindow(imageUrl.url)
                                    },
                                  })}
                                >
                                  {imageUrl.name}
                                </Link>
                              </UploadImageRowStyle.File>
                              <img
                                src={ImagesInputRemove}
                                alt="text remove"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (!data['요청 사진']) return
                                  dispatch(
                                    setCurrentGallery({
                                      ...currentGallery,
                                      data: {
                                        ...currentGallery['data'],
                                        '요청 사진': [
                                          ...data['요청 사진'].filter(
                                            (galleryUrl) => {
                                              return galleryUrl !== imageUrl
                                            }
                                          ),
                                        ],
                                      },
                                    })
                                  )
                                }}
                              />
                            </FlexRow>
                          )
                        })}
                      </UploadImageRowStyle.Files>
                    </FlexRow>
                  </>
                ) : (
                  <FlexRow
                    gap={8}
                    _css={css`
                      cursor: pointer;
                    `}
                    width="100%"
                    justifyContent="flex-end"
                    _color={'Grayscale/Black light'}
                    _textStyle="Body/Large/Bold"
                    onClick={() => {
                      dispatch(
                        setGallery({
                          ...currentGallery,
                          target: child ? [parent, child] : [parent],
                        })
                      )
                    }}
                  >
                    {currentImages.length}개
                    <img src={ImagesDetailsArrowRightCircle} alt="" />
                  </FlexRow>
                )}
              </>
            }
          />
        </>
      ) : (
        <>
          <BoxRowComponent
            _label={child ?? parent}
            _contents={
              <>
                <FlexRow gap={24}>
                  {!_readOnly && (
                    <PhotoUploadBox
                      _type={_type}
                      _url={_url}
                      _optionValue={_optionValue}
                      _emitValue={(value) => {
                        if (_emitValue) _emitValue(value)
                        let cloned = cloneDeep({ ...currentGallery })
                        let withSite = cloned.data[parent]
                        withSite = value
                        dispatch(
                          setCurrentGallery({
                            ...cloned,
                            data: {
                              ...cloned.data,
                              [parent]: withSite,
                            },
                          })
                        )
                      }}
                    />
                  )}
                  <TXT
                    _color={'Grayscale/Gray Light'}
                    _textStyle="Body/Large/Bold"
                  >
                    등록된 사진 없음
                  </TXT>
                </FlexRow>
              </>
            }
          />
        </>
      )}
    </>
  )
}
export default UploadImageRow
