import styled from '@emotion/styled/macro'
import { typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const TableHeaderStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-color: #f6f6f6;
    padding-top: 60px;
    padding-bottom: 24px;
    position: sticky;
    top: 0px;
    z-index: 4;
    ${addCssProps}
  `,
  Left: styled.div`
    h1 {
      ${typography['Display/3 Bold']}
    }
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  Right: styled.div``,
}

export default TableHeaderStyle
