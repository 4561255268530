import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
const navStyle = css`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  transition: 0.2s;
  .left {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${typography['Body/Small/Bold']}

  &:hover {
    background-color: ${colors['White/White 10%']};
  }
  border-radius: 12px;
  &.active {
    background-color: ${colors['White/White off']};
    color: ${colors['Primary/Default']};
  }
`
const SideBarStyle = {
  Wrap: styled.div`
    position: fixed;
    border-radius: 0 20px 20px 0;
    top: 0;
    left: 0;
    z-index: 2100;
    width: 322px;
    height: 100%;
    background-color: ${colors['Primary/Default']};
    color: ${colors['White/White off']};
    padding: 0;
    @media print {
      display: none;
    }
    .logo-part {
      padding: 0;
    }
    a.logo {
      width: 100%;
      padding: 32px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 78px;
        height: auto;
      }
    }
    a.home {
      ${navStyle}
    }
    a.no-child {
      ${navStyle}
    }
    ${addCssProps}
  `,
  Logo: styled(Link)``,
  NavMenu: styled.div`
    height: calc(100% - 192px - 96px);
    overflow-y: auto;
    overflow-y: overlay;
    overscroll-behavior-y: contain;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 24px;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
    .nav-parent {
      user-select: none;
      ${navStyle}
      cursor: pointer;
      .chevron {
        &.open {
          transform: rotate(180deg);
        }
      }
      &.active {
        .chevron {
          transform: rotate(180deg);
          &.open {
            transform: rotate(0deg);
          }
        }
      }
    }
  `,
  ChildList: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    transition: 0.15s;
    overflow: hidden;
    a.child {
      ${typography['Body/Small/Regular']}
      user-select: none;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      border-radius: 12px;
      justify-content: space-between;
      &:hover {
        transition: 0.3s;
        background-color: ${colors['White/White 10%']};
      }
      .left {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .mark {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &::before {
          transition: 0.3s;
          content: '';
          display: block;
          position: relative;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: #fff;
        }
      }
      &.active {
        transition: 0.3s;
        background-color: ${colors['White/White 10%']};
        .mark {
          &::before {
            width: 4px;
            height: 20px;
            border-radius: 1px;
            background-color: #fff;
          }
        }
      }
      &.active {
        ${typography['Body/Small/Bold']}
      }
    }
  `,
  ChildMark: styled.div``,
  NavGroup: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  ChildCount: styled.div`
    height: 16px;
    width: 16px;
    border-radius: 6px;
    ${typography['Menu/Bold']}
    color: ${colors['Primary/Default']};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors['White/White off']};
  `,
  Bottom: styled.div`
    border-radius: 0 0 20px 0;
    background-color: ${colors['Primary/Default']};
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 32px 24px;
    .name {
      ${typography['Title/Bold']}
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .duty {
      padding: 4px 0 24px;
      ${typography['Body/Small/Regular']}
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }
  `,
  MainButton: styled.button`
    background-color: ${colors['White/White off']};
    ${typography['Body/Small/Regular']}
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors['Grayscale/Gray Dark']};
    gap: 8px;
    height: 52px;
    flex: 1;
    border-radius: 12px;
    &:hover,
    &:active {
      background-color: ${colors['Grayscale/Background Dark']};
    }
  `,
}

export default SideBarStyle
