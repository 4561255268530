import { cn } from 'utils/helpers'
import S from './RadioButton.styles'
import { RadioButtonProps, RadioDescriptionProps } from './RadioButton.types'

export const RadioDescription = ({
  _css,
  _sub,
  _title,
  ...props
}: RadioDescriptionProps) => {
  return (
    <S.Contents _css={_css} {...props}>
      <div className="title">{_title}</div>
      <div className="sub">{_sub}</div>
    </S.Contents>
  )
}

const RadioButton = ({
  _css,
  children,
  _active,
  _disabled,
  _mini,
  className,
  ...props
}: RadioButtonProps) => {
  return (
    <>
      <S.Wrap
        className={cn({ active: _active, disabled: _disabled }, className)}
        _mini={_mini}
        _css={_css}
        {...props}
      >
        <S.BallWrap>
          <S.Ball _mini={_mini} />
        </S.BallWrap>
        {typeof children === 'string' && <S.Contents>{children}</S.Contents>}
        {typeof children !== 'string' && children}
      </S.Wrap>
    </>
  )
}
export default RadioButton
