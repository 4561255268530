import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'

const ExtraFeeStyle = {
  Wrap: styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    ${addCssProps}
  `,
}

export default ExtraFeeStyle
