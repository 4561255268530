import { css } from '@emotion/react'
import { ImagesInputSearchIcon } from 'assets'
import { GrayScaleOutline, PrimaryButton } from 'components/Buttons'
import DetailsComponents, { ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import Dropdown from 'components/Dropdown'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import LiveSearchInput from 'components/LiveSearchInput'
import { LiveSearchDefaultDiv } from 'components/LiveSearchInput/LiveSearchInput.styles'
import { LiveSearchUserInfo } from 'components/LiveSearchInput/LiveSearchInput.types'
import PostalCode from 'components/PostalCode'
import { PostcodeType } from 'components/PostalCode/PostalCode'
import { TD, TH } from 'components/TableComponent/TableComponent'
import TextArea from 'components/Textarea'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineFormatInput, {
  getFormats,
} from 'components/UnderlineInput/UnderlineFormatInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import { useAppDispatch } from 'hooks/reduxHooks'
import useMatchInput from 'hooks/useMatchInput'
import { clone } from 'lodash-es'
import { ApiPartnerItem } from 'pages/Member/Partner/PartnerList/PartnerList.types'
import { MutableRefObject, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
} from 'themes/Shared.styles'
import {
  ELEVATOR_DROPDOWN_LIST,
  FREE_PARKING_DROPDOWN_LIST,
  RESIDENT_TYPE_DROPDOWN_LIST,
} from 'types/services.type'
import callAxios, { extractData, handleError } from 'utils/callAxios'
import { cn, getHouseSize, objectKeys } from 'utils/helpers'
import highlight from 'utils/highlight'
import { parseToFormattedStr } from 'utils/parsers'
import {
  BUILDING_INFO_INITIAL_VALUE,
  HouseHold,
  PostBuildingDetails,
} from '../Building.types'
import B from './EditBuilding.styles'
import { EditBuildingProps } from './EditBuilding.types'
import EditHouseholdPopup from './EditHouseholdPopup'

// 빌딩 수정은 팝없으로 대체되어 실제 역할은 등록만 가능하다.
const EditBuilding = ({ _css, ...props }: EditBuildingProps) => {
  const { Layout: L } = S
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const buildingData = useMatchInput(BUILDING_INFO_INITIAL_VALUE)

  const { inputs, handleInput, handleValues } = buildingData
  const [openPopupForGetPostalCode, set_openPopupForGetPostalCode] =
    useState<boolean>(false)
  const addressDetailsRef = useRef() as MutableRefObject<HTMLDivElement>
  const handleGetAddress = (value: PostcodeType) => {
    let fullAddress = value.address
    let extraAddress = ''

    if (value.addressType === 'R') {
      if (value.bname !== '') {
        extraAddress += value.bname
      }
      if (value.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${value.buildingName}` : value.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }
    handleValues((prev) => ({
      ...prev,
      address: fullAddress,
      addressOld: value.jibunAddress,
      bcode: value.bcode,
    }))
    if (addressDetailsRef.current) {
      addressDetailsRef.current.querySelector('input')?.focus()
    }
    set_openPopupForGetPostalCode(false)
  }
  const handlePostalCode = (value: boolean) => {
    set_openPopupForGetPostalCode(value)
  }
  const [openEditHouseHold, set_openEditHouseHold] = useState<{
    open: boolean
    id?: string | null
    index?: number
    data?: HouseHold
  }>({
    open: false,
  })
  const handleHouseHold = async (
    value: HouseHold,
    type: 'remove' | 'update' | 'add',
    index?: number
  ) => {
    if (typeof index === 'number' && type === 'remove') {
      handleInput('houseInfo', (prev) => {
        let cloned = clone(prev.houseInfo)
        cloned = cloned.filter((_item, itemIndex) => itemIndex !== index)
        return cloned
      })
      dispatch(
        setToast({
          message: '삭제 되었습니다.',
          type: 'error',
        })
      )
    }
    if (typeof index === 'number' && type === 'update') {
      handleInput('houseInfo', (prev) => {
        let cloned = clone(prev.houseInfo)
        cloned = cloned.map((item, itemIndex) =>
          itemIndex === index ? { ...item, ...value } : item
        )
        return cloned
      })
      dispatch(
        setToast({
          message: '수정되었습니다.',
          type: 'success',
        })
      )
    }
    if (type === 'add') {
      handleInput('houseInfo', (prev) => {
        let cloned = clone(prev.houseInfo)
        value = { ...value }
        cloned = [...cloned, value]
        return cloned
      })
      dispatch(
        setToast({
          message: '건물이 저장되었습니다.',
          type: 'success',
        })
      )
    }
    set_openEditHouseHold({ open: false })
  }
  const handleSubmit = async () => {
    if (!inputs.userInfo?.id) return
    if (!inputs.residentType) return
    if (!inputs.freeParking) return
    try {
      const postData: PostBuildingDetails = {
        type: inputs.residentType,
        name: inputs.buildingName,
        userId: inputs.userInfo?.id,
        businessNo: inputs.businessNo,
        bcode: inputs.bcode,
        fullLegalAddress: inputs.address,
        landLotAddress: inputs.addressOld,
        details: inputs.addressDetails,
        highestFloor: inputs.floorCount ?? 1,
        partnerId: inputs.partnerInfo?.id ?? null,
        isExistElevator: inputs.elevator === 1,
        parking: inputs.freeParking,
        parkingInfo: inputs.parkingMethod,
        frontPwd: inputs.frontPwd,
        note: inputs.remarks,
        referralWages: inputs.partnerInfo?.referralFee ?? 0,
        households: inputs.houseInfo.map((house) => {
          return {
            ...(house.id && { id: house.id }),
            name: house.name,
            type: house.type ? house.type : '원룸',
            isLive: house.living === 1,
            // {{{{{TODO}}}}}: string. iso8601 date
            susudaArea: house.roomSize
              ? getHouseSize(house.roomSize, '평').susudaSize
              : 0,
            exclusiveArea: house.roomSize ?? 0,
            floor: house.floorCount ?? 0,
          }
        }),
      }
      await callAxios('user').post('/building', postData)
      let toastMessage = '등록되었습니다.'
      let backwardTo = `/member/building`
      dispatch(setToast([toastMessage, 'success']))
      navigate(backwardTo)
    } catch (error) {
      handleError(error)
    }
  }
  const [partnerCheck, set_partnerCheck] = useState<'exist' | 'none' | null>(
    null
  )
  const findPartnerByUserId = async (value: LiveSearchUserInfo | null) => {
    if (!value) return
    try {
      const res = await callAxios('user').get(`/partner/regular/${value.id}`)
      const response = extractData<{
        id: string
        name: string
        phone: string
        gender: string
        referralFee: number
      }>(res)
      if (objectKeys(response).length > 0) {
        handleInput('partnerInfo', {
          id: response.id,
          name: response.name,
          phone: response.phone,
          referralFee: null,
          referralFeeLimit: response.referralFee,
        })
        set_partnerCheck('exist')
      } else {
        handleInput('partnerInfo', {
          name: '',
          phone: '',
          referralFee: null,
          referralFeeLimit: null,
        })
        set_partnerCheck('none')
      }
      let nextValue = value
        ? {
            id: value.id,
            name: value.name ?? '',
            phone: value.phone ?? '',
          }
        : null
      handleInput('userInfo', nextValue)
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      {openEditHouseHold.open && (
        <EditHouseholdPopup
          _open={openEditHouseHold.open}
          _id={openEditHouseHold.id}
          _data={openEditHouseHold.data}
          _index={openEditHouseHold.index}
          _onClose={() => {
            set_openEditHouseHold({ open: false })
          }}
          _emitValue={handleHouseHold}
        />
      )}
      {openPopupForGetPostalCode && (
        <PostalCode
          onClose={() => handlePostalCode(false)}
          onComplete={(value) => {
            handleGetAddress(value)
          }}
        />
      )}
      <DetailsComponents
        _title={'건물 등록'}
        _topLeftControls={
          <L.ButtonRow>
            <ToList _to="/member/building" />
          </L.ButtonRow>
        }
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout _title={'기본 정보'}>
              <ContentRadiusBox gap={12}>
                <BoxRowComponent _label={'건물 유형 *'}>
                  <Dropdown.Underline
                    _list={RESIDENT_TYPE_DROPDOWN_LIST}
                    _value={inputs.residentType}
                    _placeholder={'선택'}
                    _emitValue={(value) => {
                      handleInput('residentType', value)
                    }}
                  />
                </BoxRowComponent>

                <BoxRowComponent _label={'건물명 *'}>
                  <UnderlineInput
                    value={inputs.buildingName}
                    placeholder="건물명 입력"
                    handleValue={(value) => {
                      handleInput('buildingName', value)
                    }}
                  />
                </BoxRowComponent>

                <BoxRowComponent _label={'주소*'} className="flex-start">
                  <FlexColumn
                    gap={12}
                    width="100%"
                    _css={css`
                      & > div {
                        width: 100%;
                      }
                    `}
                  >
                    <UnderlineInput
                      value={inputs.address}
                      noRemoveButton
                      handleValue={(value) => {}}
                      after={
                        <img
                          src={ImagesInputSearchIcon}
                          alt="search"
                          onClick={() => {
                            handlePostalCode(true)
                          }}
                        />
                      }
                      placeholder="도로명 검색"
                      onFocus={() => {
                        handlePostalCode(true)
                      }}
                      onClick={() => {
                        handlePostalCode(true)
                      }}
                    />
                    <UnderlineInput
                      _css={css`
                        opacity: 0.5;
                      `}
                      readOnly
                      placeholder="지번"
                      value={inputs.addressOld}
                      handleValue={(value) => {}}
                    />
                    <UnderlineInput
                      ref={addressDetailsRef}
                      placeholder="상세주소 입력"
                      value={inputs.addressDetails}
                      handleValue={(value) => {
                        handleInput('addressDetails', value)
                      }}
                    />
                  </FlexColumn>
                </BoxRowComponent>

                <BoxRowComponent _label={'회원명 *'}>
                  <LiveSearchInput<LiveSearchUserInfo>
                    _removeAfter={false}
                    _type="user"
                    _url="/users/search?type=regular"
                    _placeholder="회원 이름 검색"
                    _queryName="name"
                    _defaultValue={inputs.userInfo?.name ?? ''}
                    _emitValue={findPartnerByUserId}
                    _listParser={(
                      response: DoubleDListWithPaging<LiveSearchUserInfo>
                    ) => {
                      return {
                        _list: response.list,
                        _cursor: disposeCursor(
                          response.paging.cursor,
                          'cursor'
                        ),
                      }
                    }}
                    _setTitle={(value) => value.name}
                    _ListContent={(props) => {
                      return (
                        <LiveSearchDefaultDiv
                          _css={css`
                            padding: 8px 12px;
                          `}
                          onClick={(e) => {
                            props.handleSubmit(props.item)
                          }}
                        >
                          {highlight(props.item.name, props.term)}{' '}
                          {highlight(
                            parseToFormattedStr(props.item.phone, 'phone'),
                            props.term
                          )}
                        </LiveSearchDefaultDiv>
                      )
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'전화번호 *'}>
                  <UnderlineFormatInput
                    format={getFormats('phone', inputs.userInfo?.phone)}
                    readOnly
                    placeholder="회원 선택시 자동 입력"
                    value={inputs.userInfo?.phone}
                    handleValue={(value) => {
                      handleInput('userInfo', (prev) => {
                        if (prev.userInfo) {
                          return { ...prev.userInfo, phone: value }
                        } else {
                          return { name: '', phone: value }
                        }
                      })
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'법인사업자 번호'}>
                  <UnderlineInput
                    value={inputs.businessNo}
                    placeholder="내용 입력"
                    handleValue={(value) => {
                      handleInput('businessNo', value)
                    }}
                  />
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout _title={'파트너 정보'}>
              <ContentRadiusBox gap={12}>
                <BoxRowComponent _label={'파트너명'}>
                  <LiveSearchInput<ApiPartnerItem>
                    _removeAfter={false}
                    _type="user"
                    _disabled={!partnerCheck}
                    _readOnly={partnerCheck === 'exist'}
                    _url="/users/search?take=10&type=partner"
                    _defaultValue={inputs.partnerInfo?.name}
                    _placeholder="검색"
                    _queryName="name"
                    _emitValue={(value) => {
                      let nextValue = value
                        ? {
                            id: value.id,
                            name: value.name ?? '',
                            phone: value.phone ?? '',
                            referralFee: null,
                            referralFeeLimit: value.referralFee,
                          }
                        : null
                      handleInput('partnerInfo', nextValue)
                    }}
                    _listParser={(
                      response: DoubleDListWithPaging<ApiPartnerItem>
                    ) => {
                      return {
                        _list: response.list,
                        _cursor: disposeCursor(
                          response.paging.cursor,
                          'cursor'
                        ),
                      }
                    }}
                    _setTitle={(value) => value.name}
                    _ListContent={(props) => {
                      return (
                        <LiveSearchDefaultDiv
                          onClick={(e) => {
                            props.handleSubmit(props.item)
                          }}
                        >
                          {highlight(props.item.name, props.term)}{' '}
                          {highlight(
                            parseToFormattedStr(props.item.phone, 'phone'),
                            props.term
                          )}
                        </LiveSearchDefaultDiv>
                      )
                    }}
                  />
                </BoxRowComponent>
                {partnerCheck && (
                  <>
                    <BoxRowComponent _label={'전화번호'}>
                      <UnderlineFormatInput
                        format={getFormats('phone', inputs.partnerInfo?.phone)}
                        readOnly
                        placeholder="파트너 선택시 자동 입력"
                        value={inputs.partnerInfo?.phone}
                        handleValue={(value) => {}}
                      />
                    </BoxRowComponent>
                    <BoxRowComponent _label={'소개 수수료'}>
                      <UnderlineNumberInput
                        readOnly={partnerCheck === 'exist'}
                        value={inputs.partnerInfo?.referralFee}
                        isAllowed={(values) => {
                          if (!values.floatValue) return true
                          return (
                            values.floatValue >= 0 &&
                            values.floatValue <=
                              (inputs.partnerInfo?.referralFeeLimit ?? 0)
                          )
                        }}
                        suffix="원"
                        placeholder={
                          partnerCheck === 'exist'
                            ? '이미 소개된 정기회원입니다.'
                            : '소개 수수료 입력'
                        }
                        handleValue={(value) => {
                          handleInput('partnerInfo', (prev) => {
                            if (!prev.partnerInfo) return prev.partnerInfo
                            return {
                              ...prev.partnerInfo,
                              referralFee: value,
                            }
                          })
                        }}
                      />
                    </BoxRowComponent>
                    <BoxRowComponent _label={'소개 수수료 한도'}>
                      <UnderlineNumberInput
                        readOnly
                        suffix="원"
                        placeholder="파트너 선택시 자동 입력"
                        value={inputs.partnerInfo?.referralFeeLimit}
                        handleValue={(value) => {}}
                      />
                    </BoxRowComponent>
                  </>
                )}
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout _title={'기타 정보'}>
              <ContentRadiusBox gap={12}>
                <BoxRowComponent _label={'총 층수 *'}>
                  <UnderlineNumberInput
                    placeholder="숫자만 입력"
                    value={inputs.floorCount}
                    handleValue={(value) => {
                      handleInput('floorCount', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'엘리베이터 *'}>
                  <Dropdown.Underline
                    _list={ELEVATOR_DROPDOWN_LIST}
                    _value={inputs.elevator}
                    _emitValue={(value) => {
                      handleInput('elevator', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'무료주차 *'}>
                  <Dropdown.Underline
                    _list={FREE_PARKING_DROPDOWN_LIST}
                    _value={inputs.freeParking}
                    _emitValue={(value) => {
                      handleInput('freeParking', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'주차방법'} className="flex-start">
                  <TextArea
                    placeholder="1000자 이하"
                    _css={css`
                      border: none;
                      border-radius: 0px;
                      padding: 10px 0;
                      min-height: 120px;
                    `}
                    value={inputs.parkingMethod}
                    onChange={(e) =>
                      handleInput('parkingMethod', e.target.value)
                    }
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'현관 비밀번호 *'}>
                  <UnderlineInput
                    placeholder="내용 입력"
                    value={inputs.frontPwd}
                    handleValue={(value) => {
                      handleInput('frontPwd', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'비고'} className="flex-start">
                  <TextArea
                    placeholder="1000자 이하"
                    _css={css`
                      border: none;
                      border-radius: 0px;
                      padding: 10px 0;
                      min-height: 120px;
                    `}
                    value={inputs.remarks}
                    onChange={(e) => handleInput('remarks', e.target.value)}
                  />
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _rightContents={
          <ContentBoxWrap>
            <BoxLayout
              _title={'세대 정보'}
              _css={css`
                display: flex;
                flex-direction: column;
                gap: 10px;
                height: 100%;
                & > div {
                  width: 100%;
                  &:last-of-type {
                    flex: 1;
                    height: 100%;
                  }
                }
              `}
              _titleSuffix={
                <FlexRow gap={8} width={'auto'}>
                  <L.TotalCount>
                    총 세대수{' '}
                    <span className="count">{inputs.houseInfo.length}</span>
                  </L.TotalCount>
                  <PrimaryButton
                    _mini
                    padding={`5px 12px`}
                    onClick={() => {
                      set_openEditHouseHold({ open: true })
                    }}
                  >
                    세대 추가
                  </PrimaryButton>
                </FlexRow>
              }
            >
              <B.MiniTableWrap>
                <B.MiniTable
                  className={cn({
                    'not-found': inputs.houseInfo.length === 0,
                  })}
                >
                  <thead>
                    <tr>
                      <TH>세대명</TH>
                      <TH>거주형태</TH>
                      <TH>거주여부</TH>
                    </tr>
                  </thead>
                  <tbody>
                    {inputs.houseInfo.length > 0 ? (
                      <>
                        {inputs.houseInfo.map((item, houseIndex) => {
                          return (
                            <tr
                              key={'house_list' + houseIndex}
                              onClick={() => {
                                set_openEditHouseHold({
                                  open: true,
                                  id: item.id,
                                  index: houseIndex,
                                  ...(!item.id && {
                                    data: item,
                                  }),
                                })
                              }}
                            >
                              <TD>{item.name}</TD>
                              <TD>{item.type}</TD>
                              <TD>
                                {item.living === 1
                                  ? '공실'
                                  : item.living === 2
                                  ? '거주중'
                                  : '-'}
                              </TD>
                            </tr>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={4}>
                            <div>추가된 세대가 없습니다</div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </B.MiniTable>
              </B.MiniTableWrap>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _bottomRightControls={
          <L.RightBottom>
            <GrayScaleOutline
              width={160}
              height={48}
              padding={`12px 0`}
              onClick={() => {
                navigate(`/member/building`)
              }}
            >
              취소
            </GrayScaleOutline>
            <PrimaryButton
              _css={css`
                flex: 1;
              `}
              height={48}
              padding={`12px 0`}
              onClick={handleSubmit}
            >
              등록
            </PrimaryButton>
          </L.RightBottom>
        }
      />
    </>
  )
}
export default EditBuilding
