import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { getFileNameFromUrl, valueCheck } from 'utils/helpers'
import { EstimatesDetailsValuesForUserType } from '../AddEstimates/AddEstimates.types'

export interface EstimatesDetailsProps extends DivAttributes {
  _css?: AdditionalCss
  _id: string
}

export const ESTIMATES_INITIAL_VALUE: EstimatesDetailsValuesForUserType = {
  extraFees: [],
  zoneGroupId: null,
  name: '',
  phone: '',
  customerType: null,
  funnels: [],
  serviceState: '작성대기',

  // 주문 금액
  orderDate: '',
  buyerName: '',
  dispatchFee: null,
  serviceFee: null,
  serviceMaterialPrice: null,
  liftingPrice: null,
  totalPrice: null,

  // 수금 정보
  prePaidFee: null,
  prePaidReason: '',

  bcode: null,
  address: '',
  addressOld: '',
  addressDetails: '',
  residentType: null,
  buildingSize: null,
  floors: null,
  elevator: null,

  serviceItem: {
    serviceCategoryName: '',
    serviceCategoryId: null,
    serviceName: '',
    serviceRequests: '',
    priceTagId: null,
  },

  desiredDate: '',
  scheduledDate: '',

  requests: '',
  requestImages: [],
  paymentInfoResult: null,
}
export type ApiEstimatesDetails = {
  status: '작성대기' | '전송완료'
  createdAt: string
  orderFunnel: string
  additionalCost: {
    messageToUser: string
    note: string
    price: number
    taskName: string
    taskPrice: number
  }[]
  orderInfo: {
    name: string
    phone: string
    userType: 'REGULAR' | 'CUSTOMER'
  }
  material: {
    id: number
    imageUrl: string
    name: string
    price: number
    quantity: number
  } | null
  addressInfo: {
    fullLegalAddress: string
    landLotAddress: string
    details: string
    type: '아파트' | '빌라' | '오피스텔' | '주택'
    exclusiveArea: number
    susudaArea: number
    thirdFloor: boolean
    isExistElevator: boolean
    zoneGroupId: number
  }
  servicePath: string
  serviceInfo: {
    category: {
      id: number
      name: string
      isActive: boolean
    }
    quantity: {
      name: string
      quantity: number
    }
    subCategory: {
      id: number
      name: string
      isEstimates: boolean
      parentId: number
      path: string
      order: number
    }[]
    tag: {
      name: string
      priceTagColumn: 'h1' | 'h2' | 'h3' | 'h4'
      value: string
    }[]
    serviceOption: {
      name: string
      price: number
    }[]
  }
  serviceProductId: number
  serviceRequests: string
  etcRequests: string
  serviceRequestsImageUrl: string[]
  isMain: boolean
  paymentInfo: {
    totalPrice: number
    taskPrice: number
    serviceMaterialPrice: number
    liftingPrice: number
    additionalCost: {
      messageToUser: string
      note: string
      price: number
      taskName: string
    }[]
  }
}
export const parserForEstimatesDetails = (
  response: ApiEstimatesDetails
): EstimatesDetailsValuesForUserType => {
  return {
    ...ESTIMATES_INITIAL_VALUE,
    paymentInfoResult: response.paymentInfo,

    extraFees: response.additionalCost,
    serviceItem: {
      ...ESTIMATES_INITIAL_VALUE.serviceItem,
      serviceCategoryName: response.serviceInfo.category.name,
      serviceCategoryId: response.serviceProductId,
      serviceName: response.servicePath,
      serviceRequests: response.serviceRequests,
    },
    requests: response.etcRequests,
    requestImages: response.serviceRequestsImageUrl.map((item) => ({
      id: getFileNameFromUrl(item),
      name: getFileNameFromUrl(item),
      url: item,
    })),
    serviceState: response.status,
    zoneGroupId: response.addressInfo.zoneGroupId,
    // 주문 정보
    orderDate: response.createdAt,

    serviceFee: response.paymentInfo.taskPrice,
    liftingPrice: response.paymentInfo.liftingPrice,
    serviceMaterialPrice: response.paymentInfo.serviceMaterialPrice,
    totalPrice: response.paymentInfo.totalPrice,
    // 추가금

    // 회원 정보
    name: response.orderInfo?.name ?? '',
    phone: response.orderInfo?.phone ?? '',
    customerType: response.orderInfo?.userType,
    funnels: response.orderFunnel.split(','),

    // 주문 서비스

    // 주소지 정보
    address: valueCheck(response.addressInfo.fullLegalAddress),
    addressOld: valueCheck(response.addressInfo.landLotAddress),
    addressDetails: valueCheck(response.addressInfo.details),
    residentType: response.addressInfo.type,
    buildingSize: response.addressInfo.exclusiveArea,
    floors: response.addressInfo.thirdFloor ? 2 : 1,
    elevator: response.addressInfo.isExistElevator ? 1 : 2,
  }
}
