import { SETTLEMENT_TYPE } from 'pages/Settlement/index.types'
import { TechsTypesDropdownKeyType } from 'pages/System/Techs/TechsList/TechsList.types'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { parseToFormattedStr } from 'utils/parsers'

export interface SettlementForTechsDetailsProps extends DivAttributes {
  _css?: AdditionalCss
  _id: string
  _type: typeof SETTLEMENT_TYPE[number]
}
export type TechsSettlementValuesType = {
  profileImage: string
  name: string
  phone: string
  badges: {
    inActive?: boolean
    techsType: Exclude<TechsTypesDropdownKeyType, '전체'> | null
    measureAble?: boolean
  }
  userId: string

  accountNumber: string
  completeServiceDate: string

  salary: number
  settlementRatio: number

  defaultFee: number
  serviceFees: number
  wagesInfo: WagesInfoType | null
  freelancerCalcInfo: FreeLancerCalcInfo | null
  susudaCalcInfo: SusudaCalcInfo | null

  settlementList: ApiSettlementList[]

  workInfo: {
    activityDay: number
    dispatchCount: number
  }
  yearMonth: string
  finishedAt: string | null
}
export const TECHS_SETTLEMENT_INFO_INITIAL_VALUE: TechsSettlementValuesType = {
  wagesInfo: null,
  freelancerCalcInfo: null,
  susudaCalcInfo: null,
  profileImage: '',
  name: '',
  phone: '',
  badges: {
    inActive: false,
    techsType: null,
    measureAble: true,
  },
  userId: '',

  accountNumber: '',
  completeServiceDate: '',

  salary: 0,
  settlementRatio: 0,

  defaultFee: 0,
  serviceFees: 0,

  settlementList: [],
  workInfo: {
    activityDay: 0,
    dispatchCount: 0,
  },
  yearMonth: '',
  finishedAt: null,
}

export interface ApiSettlementList {
  taskId: string
  isMeasurement: boolean
  serviceName: string
  customerName: string
  settlementWages: number
  taskFinishedAt: string
  unpaid: boolean
}
export interface WagesInfoType {
  accountNo: string // 계좌 정보
  taskFinishedAt?: string | null // 시공 완료일
  basicWages?: number // 기본 급여
  settlementRatio: number // 정산 비율
  lowestWorkDay?: number // 최저 활동 일수
  basicTaskCount?: number // 하루 기본 시공 수
  subtractWages?: number // 차감액
  specialWages?: number // 특별 공임료
  guaranteeWorkDay?: number // 보존 공임 일수
}
export const wagesInfoTypeName = {
  accountNo: {
    name: '계좌 정보',
    unit: '',
  },
  taskFinishedAt: {
    name: '시공 완료일',
    unit: '',
  },
  basicWages: {
    name: '기본 급여',
    unit: '원',
  },
  settlementRatio: {
    name: '정산 비율',
    unit: '%',
  },
  lowestWorkDay: {
    name: '최저 활동 일수',
    unit: '일',
  },
  basicTaskCount: {
    name: '하루 기본 시공 수',
    unit: '건',
  },
  subtractWages: {
    name: '차감액',
    unit: '원',
  },
  specialWages: {
    name: '특별 공임료',
    unit: '원',
  },
  guaranteeWorkDay: {
    name: '보존 공임 일수',
    unit: '일',
  },
} as const
export type FreeLancerCalcInfo = {
  taskWages?: number // 활동 공임료
  guaranteeWages?: number // 보존 공임료
  subtractWages?: number // 차감액
  specialWages?: number // 특별 공임료
  lackOfDay?: number // 부족한 날
  totalWages?: number // 지급 총액
  incomeTax?: number // 소득세
  provinceIncomeTax?: number // 지방 소득세
}
export const freelancerCalcInfoName = {
  taskWages: '활동 공임료',
  guaranteeWages: '보존 공임료',
  subtractWages: '차감액',
  specialWages: '특별 공임료',
  lackOfDay: '부족한 날',
  totalWages: '지급 총액',
  incomeTax: '소득세',
  provinceIncomeTax: '지방 소득세',
}
export type SusudaCalcInfo = {
  basicWages?: number // 기본 급여
  taskWages?: number // 활동 공임료
  totalWages?: number // 지급 총액
}
export const susudaCalcInfoName = {
  basicWages: '기본 급여',
  taskWages: '활동 공임료',
  totalWages: '지급 총액',
}
export interface ApiTechsQueueType {
  technicianId: string
  year: number
  month: number
  technicianName: string
  technicianPhone: string
  profileUrl: string | null
  isFreelancer: boolean
  measuring: boolean
  wagesInfo: WagesInfoType
  calcInfo: SusudaCalcInfo | FreeLancerCalcInfo
  settlementList: ApiSettlementList[]
  workInfo: {
    activityDay: number
    dispatchCount: number
  }
  finishedAt: string | null
}

export interface SusudaSettlementDetailsType extends ApiTechsQueueType {
  isFreelancer: false
  calcInfo: SusudaCalcInfo
}
export interface FreelancerSettlementDetailsType extends ApiTechsQueueType {
  isFreelancer: true
  calcInfo: FreeLancerCalcInfo
}
export const isFreelancerSettlementDetailsType = (
  value: ApiTechsQueueType
): value is FreelancerSettlementDetailsType => {
  return value.isFreelancer
}
export const isSusudaSettlementDetailsType = (
  value: ApiTechsQueueType
): value is SusudaSettlementDetailsType => {
  return !value.isFreelancer
}
export const parserForSettlementTechsDetails = (
  response: FreelancerSettlementDetailsType | SusudaSettlementDetailsType
): TechsSettlementValuesType => {
  let common: TechsSettlementValuesType = {
    ...TECHS_SETTLEMENT_INFO_INITIAL_VALUE,
    workInfo: response.workInfo,
    profileImage: response.profileUrl ?? '',
    name: response.technicianName,
    phone: parseToFormattedStr(response.technicianPhone, 'phone'),
    badges: {
      inActive: false,
      techsType: response.isFreelancer ? '프리랜서' : '수수다',
      measureAble: response.measuring,
    },
    userId: response.technicianId,

    accountNumber: response.wagesInfo.accountNo,
    completeServiceDate: response.wagesInfo.taskFinishedAt ?? '',
    wagesInfo: response.wagesInfo,

    settlementList: response.settlementList,
    yearMonth: response.year + '년 ' + response.month + '월',
    finishedAt: response.finishedAt,
  }
  if (isFreelancerSettlementDetailsType(response)) {
    return {
      ...common,
      freelancerCalcInfo: response.calcInfo,
    }
  } else {
    return {
      ...common,
      susudaCalcInfo: response.calcInfo,
    }
  }
}
