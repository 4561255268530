import dayjs from 'dayjs'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { TechsTypesDropdownKeyType } from '../TechsList/TechsList.types'

export type TechsProfileValuesType = {
  // 필수 값
  name: string
  userId: string
  password: string
  phone: string
  residentNumber: string
  accountNumber: string
  measureAble: boolean

  salary: number | null
  settlementRatio: number | null
  dispatchAbleAreas: DispatchableZone[]

  badges: {
    inActive?: boolean
    techsType: Exclude<TechsTypesDropdownKeyType, '전체'>
  }
  createdAt: string
  profileImage: string
  recentServiceDate: string
  serviceCount: number
  skills: string[]

  usernameError?: string
  passwordError?: string
}
export const TECHS_PROFILE_INITIAL_VALUE: TechsProfileValuesType = {
  profileImage: '',
  name: '',
  phone: '',
  measureAble: false,

  badges: {
    inActive: false,
    techsType: '수수다',
  },

  userId: '',
  password: '',
  createdAt: '',
  residentNumber: '',
  accountNumber: '',

  serviceCount: 0,
  recentServiceDate: '',

  salary: null,
  settlementRatio: null,

  dispatchAbleAreas: [],
  skills: [],
}
export const parserForProfileValues = (value: any): TechsProfileValuesType => {
  return {
    ...TECHS_PROFILE_INITIAL_VALUE,
    name: '김숙련',
    phone: '010-1234-1234',
    residentNumber: `880604-1234567`,
    measureAble: false,
    badges: {
      inActive: false,
      techsType: '수수다',
    },
    userId: 'kim102031032',
    password: 'password1234@',
    createdAt: dayjs().format('YYYY-MM-DD'),
  }
}
export interface TechsDetailsProps extends DivAttributes {
  _css?: AdditionalCss
  _id: string
}

// * API
export interface APIgetTechsDetail {
  id: string
  userTechnicianId: string
  name: string
  userName: string
  profileImageUrl: string
  phone: string
  createdAt: string
  personalNo: string
  isFreelancer: boolean
  disabledAt: string | null
  accountInfo: string
  totalTaskCount: number
  measuring: boolean
  basicWages: number
  settlementRatio: number
  dispatchableZone: DispatchableZone[]
  availableSkill: string[]
  lastTaskDate: string | null
}
export interface DispatchableZone {
  id: number
  name: string
}

export interface AvailableSkill {
  name: string
  isRegistered: boolean
}
