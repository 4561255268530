import { ImagesGalleryImageFail, ImagesGalleryImageFailSmall } from 'assets'
import LoadingPage from 'layout/LoadingPage'
import { useEffect, useMemo, useState } from 'react'
import { cn, getCreatedAtFromFileName, getFileNameFromUrl } from 'utils/helpers'
import S from './ImageWithLoading.styles'
import { ImageWithLoadingProps } from './ImageWithLoading.types'

const ImageWithLoading = ({
  _css,
  _backgroundSize,
  className,
  _imageURL,
  children,
  _type = 'thumbnail',
  ...props
}: ImageWithLoadingProps) => {
  const [src, set_src] = useState<string>('')
  const [isError, set_isError] = useState<boolean>(false)
  useEffect(() => {
    if (_imageURL) {
      const image = new Image()
      image.onload = () => {
        set_isError(false)
        set_src(_imageURL)
      }
      image.onerror = () => {
        set_isError(true)
        set_src(
          _type === 'thumbnail'
            ? ImagesGalleryImageFailSmall
            : ImagesGalleryImageFail
        )
      }
      image.src = _imageURL
    }
  }, [_imageURL, _type])
  const fileName = useMemo(() => {
    return getFileNameFromUrl(_imageURL)
  }, [_imageURL])
  const createdAt = useMemo(() => {
    return getCreatedAtFromFileName(fileName)
  }, [fileName])
  return (
    <>
      <S.Wrap
        _css={_css}
        data-filename={fileName}
        data-created={createdAt}
        className={cn(
          { isError: isError && _type === 'original' },
          _backgroundSize,
          className
        )}
        {...(src && {
          style: {
            backgroundImage: `url(${src})`,
          },
        })}
        {...props}
      >
        {children}
        {!isError && createdAt && _type === 'original' && (
          <div className="createdAt">{createdAt}</div>
        )}
        {!src && <LoadingPage position="absolute" data-filename={fileName} />}
      </S.Wrap>
    </>
  )
}
export default ImageWithLoading
