import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const AbsoluteContentsStyle = {
  TabWrap: styled.div``,
  TotalCount: styled.div`
    ${typography['Body/Caption/Regular']}
    color: ${colors['Grayscale/Gray Dark']};
    .count {
      ${typography['Body/Caption/Bold']}
      color: ${colors['Primary/Default']};
    }
  `,
  Wrap: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    height: 100%;
    & > div {
      position: relative;
      flex: 1;
      width: 100%;
      height: 100%;
      /* padding: 0 5px; */
    }
    ${addCssProps}
  `,
  ButtonRow: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  LeftHeader: styled.div`
    height: 75px;
    min-height: 75px;
    width: 100%;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      ${typography['Display/1 Bold']}
      color: ${colors['Grayscale/Black light']};
    }
    border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
  `,
  LeftTab: styled.div`
    padding: 24px 32px;
  `,
  LeftContents: styled.div`
    width: 100%;
    flex: 1;
    overflow: hidden;
    padding-right: 11px;
    & > div {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 24px 11px 24px 32px;
      @media print {
        height: auto;
        overflow-y: initial;
      }
    }
    &.hasTab {
      & > div {
        padding-top: 0px;
      }
    }
  `,
  RightContents: styled.div`
    width: 100%;
    flex: 1;
    overflow: hidden;
    padding-right: 11px;
    & > div {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 24px 11px 24px 32px;
      @media print {
        height: auto;
        overflow-y: initial;
      }
    }
    transition: 0.3s;
  `,
  RightBottom: styled.div`
    height: 96px;
    min-height: 96px;
    width: 100%;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    transition: 0.3s;
    overflow: hidden;
    ${({ hide }: { hide?: boolean }) =>
      hide
        ? css`
            height: 0px;
            min-height: 0px;
          `
        : ''}
  `,
}

const DetailsComponentsStyle = {
  Layout: AbsoluteContentsStyle,
  DetailsComponentsWrap: styled.div`
    ${addCssProps}
  `,
  BadgeRow: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  Box: styled.div`
    &.checkAble {
      .checkEmpty {
        width: 32px;
      }
      .box-header {
        gap: 16px;
      }
    }
    ${addCssProps}
  `,
  Title: styled.div`
    color: ${colors['Primary/Default']};
    ${typography['Title/Bold']}
    display: flex;
    align-items: center;
    gap: 10px;
    & > span {
      ${typography['Body/Large/Bold']}
      color: ${colors['Grayscale/Gray Default']};
    }
    .asterisk {
      ${typography['Body/Small/Bold']}
      color: ${colors['Accent/Dark']};
      margin-left: -6px;
    }
  `,
  Header: styled.div`
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    img {
      cursor: pointer;
      transition: 0.5s;
      &.collapsed {
        transform: rotate3d(1, 0, 0, 180deg);
      }
    }
  `,
  HeaderWrap: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  `,
  CheckAble: styled.div`
    width: 0px;
    height: 32px;
  `,
  CollapseWrap: styled.div`
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    gap: 12px;
    &.collapsed {
      gap: 0px;
      height: 0px;
    }
  `,
  WithQuestionBox: styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    ${addCssProps}
  `,
  CopyText: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    gap: 8px;
    img {
      cursor: pointer;
    }
    ${addCssProps}
  `,
  TotalLabel: styled.label`
    ${typography['Title/Bold']}
    color: ${colors['Grayscale/Gray Default']};
  `,
  CancelColor: styled.span`
    color: ${colors['Danger/Default']};
  `,
  TotalPrice: styled.div`
    ${typography['Display/1 Bold']}
    color: ${colors['Primary/Default']};
    &.cancel {
      color: ${colors['Danger/Default']};
    }
  `,
  PointBadgeBox: styled.div`
    ${typography['Body/Caption/Bold']}
    color: ${colors['Grayscale/Gray Default']};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
  `,
  PointBadge: styled.div`
    color: ${colors['Accent/Dark']};
    background-color: ${colors['Accent/Lighter']};
    padding: 3px 6px;
    border-radius: 20px;
    &.cancel {
      color: ${colors['Danger/Dark']};
      background-color: ${colors['Danger/Light']};
    }
  `,
  MiniTableWrap: styled.div`
    border: 1px solid #dadfe8;
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
  `,
  ProfileBox: styled.div`
    .wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    div[data-profile] {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 120px;
      height: 120px;
      border-radius: 60px;
      border-radius: 50%;
    }
    text-align: center;
    .name {
      ${typography['Display/1 Bold']}
      color: ${colors['Grayscale/Black light']};
    }
    .phone {
      ${typography['Body/Small/Regular']}
      color: ${colors['Grayscale/Gray Default']};
    }
    .badges {
      margin-top: 8px;
    }
  `,
  MiniTable: styled.table`
    width: 100%;
    border-collapse: collapse;
    thead {
      border-radius: 16px 16px 0px 0px;
      background-color: ${colors['Grayscale/Background Dark']};
    }
    th {
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Dark']};
      & > div {
        padding: 14px 12px;
      }
    }
    td {
      ${typography['Body/Small/Regular']}
      color: ${colors['Grayscale/Black light']};
      border-top: 1px solid ${colors['Grayscale/Gray Lighter']};
      & > div {
        padding: 22px 12px;
      }
    }
    th,
    td {
      &:first-of-type {
        & > div {
          margin-left: 20px;
        }
      }
      &:last-of-type {
        & > div {
          margin-right: 20px;
        }
        text-align: right;
      }
    }
  `,
}
export default DetailsComponentsStyle
