import styled from '@emotion/styled'
import { ImagesGlobalLoading } from 'assets'
import CellDropdown from 'components/Dropdown/CellDropdown'
import OrderIcon from 'components/OrderIcon'
import { clone, isEqual, orderBy } from 'lodash-es'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'
import { addCssProps } from 'themes/styles.helper'
import { AdditionalCss } from 'themes/styles.type'
import callAxios, { extractData, handleError } from 'utils/callAxios'
import { cn } from 'utils/helpers'

import { toggleOrder } from './TableComponent.helpers'
import S from './TableComponent.styles'
import {
  TableCellConfig,
  TableCellDropdown,
  TableCellOrder,
  TableComponentProps,
} from './TableComponent.types'

const TableCell = styled.td`
  ${addCssProps}
`
export const TH = ({ children, ...props }: { children?: React.ReactNode }) => (
  <th {...props}>
    <div>{children}</div>
  </th>
)
interface TDProps
  extends React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  > {
  children?: React.ReactNode
  _css?: AdditionalCss
}
export const TD = ({ children, _css, ...props }: TDProps) => (
  <TableCell _css={_css} {...props}>
    <div>{children}</div>
  </TableCell>
)
const isDropdownArrayType = <T extends object>(
  value: any
): value is TableCellDropdown<T>[] => {
  return (
    value !== undefined &&
    value.length > 0 &&
    !value.some((item: any) => item._type !== 'dropdown')
  )
}
const isOrderType = <T extends object>(
  value: any
): value is TableCellOrder<T> => {
  return value !== undefined && value._type === 'order' && '_order' in value
}
const TableComponent = <T extends object>({
  _type,
  _url,
  _css,
  _config,
  _topOffset,
  _ListContent: ListContent,
  _EmptyContent: EmptyContent,
  className,
  _apiConfig,
  _parser,
  _emitConfig,
  _useVirtuoso,
  _emitOrder,
  _value,
  ...props
}: TableComponentProps<T>) => {
  const [searchParams] = useSearchParams()
  const [pending, set_pending] = useState<boolean>(false)
  const [config, set_config] = useState<TableCellConfig<T>[]>()
  const [list, set_list] = useState<T[] | null>(null)
  const [cursor, set_cursor] = useState<string | null>(null)
  const selectedFilterConfig = useMemo(() => {
    return config?.filter((item) => item._type === 'dropdown' && item._value)
  }, [config])
  const orderConfig = useMemo(() => {
    return config?.find(
      (item) => item._type === 'order' && item._order !== 'INITIAL'
    )
  }, [config])

  const filteredList = useMemo(() => {
    let result: T[] = []
    // let unfiltered: T[] = []
    if (list) {
      result = clone(list)
      if (result && isDropdownArrayType<T>(selectedFilterConfig)) {
        const partialObj = selectedFilterConfig.reduce((prev, curr) => {
          prev[curr._id] = curr._value as T[keyof T]
          return prev
        }, {} as Partial<T>)

        result = result.filter((value) => {
          const forCompare = { ...value, ...partialObj }

          return isEqual(value, forCompare)
        })

        // unfiltered = clone(list).filter((value) => {
        //   const forCompare = { ...value, ...partialObj }
        //   return !isEqual(value, forCompare)
        // })
      }
      if (result && isOrderType<T>(orderConfig) && !_emitOrder) {
        result = orderBy(
          result,
          (item) =>
            orderConfig._isNumber
              ? Number(item[orderConfig._id])
              : item[orderConfig._id],
          orderConfig._order.toLowerCase() as 'asc' | 'desc'
        )
      }
      return result
    } else {
      return null
    }
  }, [_emitOrder, list, orderConfig, selectedFilterConfig])

  // params를 받아와서 table config 업데이트
  useEffect(() => {
    let configsWithValue = _config.map((item) => {
      if (item._type === 'dropdown')
        item._value = searchParams.get(item._id as string) ?? ''
      if (item._type === 'order') {
        if (item._id === searchParams.get('sortType')) {
          item._order = searchParams.get('sortValue') as
            | 'ASC'
            | 'DESC'
            | 'INITIAL'
        } else {
          item._order = 'INITIAL'
        }
      }
      return item
    })
    set_config(configsWithValue)
    if (_emitConfig) _emitConfig(configsWithValue)
  }, [_config, _emitConfig, searchParams])

  // const handleReplace = useCallback(
  //   (nextConfig?: TableCellConfig<T>[]) => {
  //     if (!nextConfig) return
  //     const dropdownQuery = nextConfig.reduce((prev, curr) => {
  //       let targetId = curr._id as string
  //       if (curr._type === 'dropdown') {
  //         prev[targetId] = curr._value ? String(curr._value) : ''
  //       }
  //       return prev
  //     }, {} as { [key in string]: string })

  //     const orderQuery = nextConfig.reduce((prev, curr) => {
  //       let targetId = curr._id as string
  //       if (curr._type === 'order') {
  //         prev[targetId] = curr._order
  //       }
  //       return prev
  //     }, {} as { [key in string]: string })
  //     const currentQuery = parseQueryStringToObject(searchParams.toString())
  //     const replaceQuery = parseObjectToQueryString(
  //       removeInitialOrder({
  //         ...currentQuery,
  //         ...dropdownQuery,
  //         ...orderQuery,
  //       }),
  //       true
  //     )
  //     let nextQuery = [replaceQuery].filter((ii) => !!ii).join('&')
  //     navigate(window.location.pathname + `?${nextQuery}`, { replace: true })
  //     return nextConfig
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [searchParams]
  // )

  const addRow = useCallback(async () => {
    try {
      if (!cursor) {
        return
      }
      const apiConfig = _apiConfig ?? {}
      const axiosRes = await callAxios(_type).get<T>(
        _url + `${_url.includes('?') ? '&' : '?'}${cursor}`,
        {
          ...apiConfig,
        }
      )
      const res = extractData(axiosRes)
      const { _list, _cursor } = _parser(res)
      set_list((prev) => (prev ? [...prev, ..._list] : prev))
      set_cursor(_cursor)
    } catch (error) {
      handleError(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_url, cursor, _type])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const fetchRow = async (url: string) => {
      try {
        const apiConfig = _apiConfig ?? {}
        const axiosRes = await callAxios(_type).get(url, {
          ...apiConfig,
          signal,
        })
        const res = extractData<T>(axiosRes)
        const { _list, _cursor } = _parser(res)
        set_list(_list)

        set_cursor(_cursor)
      } catch (error) {
        handleError(error)
      }
    }
    if (_url) {
      set_list((prev) => (prev ? (prev.length ? [] : prev) : prev))
      fetchRow(_url)
    } else {
      set_list([])
    }
    return () => {
      set_list([])
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_url])

  if (!config) return <></>
  return (
    <>
      <S.Wrap
        _css={_css}
        className={cn({ more: cursor }, className)}
        {...props}
      >
        <S.Header className="table-header" _topOffset={_topOffset}>
          <div>
            {config
              .filter((item) => item._type !== 'empty')
              .map((item, configIndex) => {
                return (
                  <S.HeadCell
                    key={'header' + configIndex}
                    _size={item._size}
                    _position={item._position}
                    _isDropdown={item._type === 'dropdown'}
                  >
                    {item._type === 'dropdown' && (
                      <>
                        <CellDropdown
                          _value={item._value}
                          _position={item._position}
                          _list={item._data}
                          _placeholder={item._label}
                          _emitValue={(value) => {
                            if (!config) return config
                            let nextConfig = config.map((p) =>
                              p._id === item._id ? { ...p, _value: value } : p
                            )
                            set_config(nextConfig)
                            if (_emitConfig) _emitConfig(nextConfig)
                            // handleReplace(nextConfig)
                          }}
                        />
                      </>
                    )}
                    {item._type === 'order' && (
                      <>
                        <S.OrderBox
                          onClick={(e) => {
                            e.stopPropagation()
                            if (!config) return config
                            let nextConfig = config.map((p) => {
                              if (p._id === item._id) {
                                const _order = toggleOrder(item._order)
                                if (_emitOrder) {
                                  _emitOrder({
                                    ..._value,
                                    sortValue: _order,
                                    sortType: item._id,
                                  })
                                }
                                return {
                                  ...p,
                                  _order,
                                }
                              } else if (p._type === 'order') {
                                return {
                                  ...p,
                                  _order: 'INITIAL' as
                                    | 'ASC'
                                    | 'DESC'
                                    | 'INITIAL',
                                }
                              } else {
                                return p
                              }
                            })
                            set_config(nextConfig)
                            if (_emitConfig) _emitConfig(nextConfig)
                            // handleReplace(nextConfig)
                          }}
                        >
                          <div>{item._label}</div>
                          <OrderIcon _order={item._order} />
                        </S.OrderBox>
                      </>
                    )}
                    {item._type === 'default' && <>{item._label}</>}
                  </S.HeadCell>
                )
              })}
          </div>
        </S.Header>
        <S.List className={cn({ more: cursor }, 'table-list')}>
          {filteredList === null && <></>}
          {filteredList !== null && filteredList.length > 0 && (
            <>
              {_useVirtuoso && (
                <Virtuoso
                  totalCount={filteredList.length}
                  itemContent={(index) => {
                    const row = filteredList[index]
                    return (
                      <ListContent
                        data-id={(row as any).id ? (row as any).id : index}
                        _configs={config}
                        _row={row}
                        _index={index}
                        _totalCount={filteredList.length}
                      />
                    )
                  }}
                />
              )}
              {!_useVirtuoso && (
                <>
                  {filteredList.map((row, rowIndex) => {
                    return (
                      <Fragment
                        key={(row as any).id ? (row as any).id : rowIndex}
                      >
                        <ListContent
                          data-id={(row as any).id ? (row as any).id : rowIndex}
                          _configs={config}
                          _row={row}
                          _index={rowIndex}
                          _totalCount={filteredList.length}
                        />
                      </Fragment>
                    )
                  })}
                </>
              )}
            </>
          )}
          {filteredList !== null && filteredList.length < 1 && (
            <>
              {EmptyContent ? (
                <>
                  {typeof EmptyContent === 'string' ? (
                    <S.EmptyDefault>{EmptyContent}</S.EmptyDefault>
                  ) : (
                    EmptyContent
                  )}
                </>
              ) : (
                <S.EmptyDefault>내역이 없습니다.</S.EmptyDefault>
              )}
            </>
          )}
        </S.List>
        <S.More
          onClick={async () => {
            if (pending) return
            set_pending(true)
            await addRow()
            set_pending(false)
          }}
          disabled={pending || !cursor}
        >
          {pending && <img src={ImagesGlobalLoading} alt="loading" />}
          {!pending && (
            <span>
              {selectedFilterConfig && selectedFilterConfig.length > 0 ? (
                <>
                  ({filteredList?.length ?? 0}/{list?.length ?? 0}) 다음 페이지
                  필터링 항목 추가
                </>
              ) : (
                <>10건 더 보기</>
              )}
            </span>
          )}
        </S.More>
      </S.Wrap>
    </>
  )
}
export default TableComponent
