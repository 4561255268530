import { isValidElement, useMemo } from 'react'
import { ContentLabel, ContentRow } from 'themes/Shared.styles'
import { thousand } from 'utils/helpers'
import highlight from 'utils/highlight'
import { BoxRowComponentProps } from './DetailsComponents.types'

const Comp = ({
  _css,
  _label,
  _contents,
  _emptyType = 'dash',
  _emptyCheck,
  children,
  _left,
  _suffix,
  ...props
}: BoxRowComponentProps) => {
  return (
    <ContentRow _css={_css} left={_left} {...props}>
      <>
        {typeof _label === 'string' && (
          <ContentLabel>{highlight(_label, '*', 'asterisk')}</ContentLabel>
        )}
        {isValidElement(_label) && _label}
        {typeof _contents === 'string' && (
          <>{_contents ? <div>{_contents}</div> : <></>}</>
        )}
        {typeof _contents === 'number' && (
          <div>
            {thousand(_contents)}
            {_suffix}
          </div>
        )}
        {isValidElement(_contents) && _contents}
        {typeof _contents !== 'number' && !_contents && !children && (
          <div>-</div>
        )}
        {children}
      </>
    </ContentRow>
  )
}
const BoxRowComponent = ({
  _css,
  _label,
  _contents,
  _emptyType = 'dash',
  _emptyCheck,
  children,
  _left,
  _suffix,
  ...props
}: BoxRowComponentProps) => {
  const emptyCheck = useMemo(() => {
    const check = typeof _emptyCheck === 'boolean' ? _emptyCheck : true
    return (
      (typeof _contents === 'number' ||
        typeof _contents === 'string' ||
        isValidElement(_contents)) &&
      check
    )
  }, [_emptyCheck, _contents])
  if (_emptyType === 'hide') {
    return (
      <>
        {emptyCheck && (
          <Comp
            {...{
              _css,
              _label,
              _contents,
              _emptyType,
              _emptyCheck,
              children,
              _left,
              _suffix,
              ...props,
            }}
          />
        )}
      </>
    )
  } else {
    return (
      <>
        <Comp
          {...{
            _css,
            _label,
            _contents,
            _emptyType,
            _emptyCheck,
            children,
            _left,
            _suffix,
            ...props,
          }}
        />
      </>
    )
  }
}
export default BoxRowComponent
