import { useMemo } from 'react'
import { Cell, Pie, PieChart } from 'recharts'
import S from './DonutChart.styles'

export interface DonutChartProps {
  _data: { name: string; value: number }[]
  _colors: string[]
}

export const DonutChart = ({ _colors, _data, ...props }: DonutChartProps) => {
  const NoneOfData = useMemo(() => {
    const dataTotal = _data.reduce((prev, curr) => {
      prev += curr.value
      return prev
    }, 0)
    return dataTotal < 1
  }, [_data])
  return (
    <S.Wrapper>
      {NoneOfData && (
        <div className="no-data">
          집계할 데이터가
          <br />
          없습니다.
        </div>
      )}
      <PieChart
        className="piechart"
        width={172}
        height={172}
        onMouseEnter={() => {}}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <Pie
          data={NoneOfData ? [{ name: '', value: 100 }] : _data}
          cx={172 / 2}
          cy={172 / 2}
          fill="#8884d8"
          stroke=""
          dataKey="value"
          innerRadius={(172 / 2 - 10) * 0.65}
          outerRadius={172 / 2 - 10}
          paddingAngle={0}
          startAngle={90}
          endAngle={450}
        >
          {_data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={NoneOfData ? `#EBEDF1` : _colors[index % _colors.length]}
            />
          ))}
        </Pie>
      </PieChart>
    </S.Wrapper>
  )
}
