import { PeriodType } from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import {
  CouponTarget,
  COUPON_TARGET_TYPE,
} from 'pages/Support/Coupon/Coupon.types'

export const COUPON_TARGET_TYPE_DROPDOWN_LIST = {
  전체: '',
  ...COUPON_TARGET_TYPE,
} as const
export type CouponTargetTypeKey = KeyOf<typeof COUPON_TARGET_TYPE_DROPDOWN_LIST>

export const COUPON_QUERY_DROPDOWN_LIST = {
  쿠폰명: 'name',
  발급자명: 'issuer',
  쿠폰코드: 'code',
} as const

export const isCouponType = (
  value: any
): value is typeof COUPON_TARGET_TYPE_DROPDOWN_LIST[CouponTargetTypeKey] => {
  return (
    value !== null &&
    Object.values(COUPON_TARGET_TYPE_DROPDOWN_LIST).some(
      (item) => item === value
    )
  )
}

export type CouponListItemType = {
  id: string
  type: CouponTargetTypeKey
  dateOrder: Number
  validDate: PeriodType
  couponName: string
  code: string
  issuerName: string
  discount: string
  count: CouponCount
  isPercent: boolean
}
interface CouponCount {
  used: number
  total: number
}
export const isCouponCount = (value: any): value is CouponCount => {
  return 'used' in value && 'total' in value
}

// API
export interface APICouponListRow {
  id: number
  target: CouponTarget
  startAt: string
  endAt: string
  name: string
  code: string
  issuer: string
  issuerName: string
  isPercent: boolean
  amount: number
  issuedCount: number
  useCount: number
  createdAt: string
}
