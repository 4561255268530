import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '.'

interface ConfigStateType {
  refresh: boolean
}
interface configState {
  options: ConfigStateType
}
const initialState: configState = {
  options: {
    refresh: false,
  },
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action: { payload: Partial<ConfigStateType> }) => {
      state.options = { ...state.options, ...action.payload }
    },
    restoreRefresh: (state) => {
      state.options = { ...state.options, refresh: false }
    },
    cleanConfig: (state) => {
      state.options = initialState.options
    },
  },
})
export const { setConfig, cleanConfig, restoreRefresh } = configSlice.actions

export const selectConfig = (state: RootState) => state.config.options
export const selectRefresh = (state: RootState) => state.config.options.refresh

export default configSlice.reducer
