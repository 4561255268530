import { SETTLEMENT_TYPE } from 'pages/Settlement/index.types'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export type PartnerSettlementInfoValuesType = {
  profileImage: string

  name: string
  phone: string
  gender: '남' | '여'
  residentNumber: string
  accountNumber: string
  email: string

  userId: string

  apiResponse: ApiPartnerSettlementDetails | null
}
export const PARTNER_SETTLEMENT_INFO_INITIAL_VALUE: ApiPartnerSettlementDetails =
  {
    id: null,
    partnerId: '',
    partnerName: '',
    partnerPhone: '',
    year: null,
    month: null,
    accountNo: '',
    partnerEmail: '',
    referralFee: 0,
    settlementFee: 0,
    calcInfo: {
      referralWages: 0,
      taskWages: 0,
      totalWages: 0,
    },
    referralInfo: [],
    taskInfo: [],
    finishedAt: null,
  }
export interface SettlementForPartnerDetailsProps extends DivAttributes {
  _css?: AdditionalCss
  _id: string
  _type: typeof SETTLEMENT_TYPE[number]
}

export type ApiPartnerSettlementDetails = {
  id: number | null
  partnerId: string
  partnerName: string
  partnerPhone: string
  year: number | null
  month: number | null
  accountNo: string
  partnerEmail: string | null
  referralFee: number
  settlementFee: number
  calcInfo: {
    referralWages: number
    taskWages: number
    totalWages: number
  }
  referralInfo: {
    userName: string
    userPhone: string
    referralWages: number
    createdAt: string
  }[]
  taskInfo: {
    buildingName: string
    serviceName: string
    serviceCount: number
    settlementWages: number
    taskFinishedAt: string
  }[]
  finishedAt: string | null
}
