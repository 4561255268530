import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { ImagesBgLogin } from 'assets'
import { colors, shadows, typography } from 'themes/styles'

export const ChangePasswordWrap = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AuthorizationDefault = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors['Grayscale/Black light']};
  background-size: cover;
  background-position: center;
  background-image: url(${ImagesBgLogin});
  @media screen and (max-height: 626px) {
    position: absolute;
    min-height: 626px;
    overflow-y: auto;
  }
  @media screen and (max-width: 500px) {
    position: absolute;
    min-width: 500px;
  }
`
const AuthorizationStyle = {
  Default: AuthorizationDefault,
  Header: styled.div`
    padding: 32px;
    ${typography['Display/2 Bold']}
  `,

  Center: styled.div`
    background-color: ${colors['White/White off']};
    ${shadows['popup2']};
    border-radius: 20px;
    padding: 0;
    position: relative;
    z-index: 2;
    min-width: 420px;
  `,

  Bottom: styled.div`
    padding: 0px 32px 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  Dim: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    /* background-color: ${colors['ETC/Dim']}; */
  `,
}
export default AuthorizationStyle
