import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { colors, typography } from 'themes/styles'
import { AdditionalCss, ColorKey, DivAttributes } from 'themes/styles.type'
import { isObjectKeyType } from 'utils/helpers'
import { BadgeProps } from './Badge.types'
export const BADGE_COLOR = {
  PrimaryFill: {
    _textColor: 'Primary/Lighter',
    _bgColor: 'Primary/Default',
  },
  PrimaryLight: {
    _textColor: 'Primary/Default',
    _bgColor: 'Primary/Lighter',
  },
  Gray: {
    _textColor: 'Grayscale/Gray Default',
    _bgColor: 'Grayscale/Gray Lighter',
  },
  AccentFill: {
    _textColor: 'Accent/Light',
    _bgColor: 'Accent/Default',
  },
  AccentLight: {
    _textColor: 'Accent/Dark',
    _bgColor: 'Accent/Lighter',
  },
  Danger: {
    _textColor: 'Danger/Dark',
    _bgColor: 'Danger/Light',
  },
  DangerFill: {
    _textColor: 'Danger/Light',
    _bgColor: 'Danger/Dark',
  },
  SuccessFill: {
    _textColor: 'Success/Light',
    _bgColor: 'Success/Default',
  },
  SuccessLight: {
    _textColor: 'Success/Default',
    _bgColor: 'Success/Light',
  },
} as const
export type BadgeColorSet = { _textColor: ColorKey; _bgColor: ColorKey }

export const 미수금_정산완료 = BADGE_COLOR.DangerFill
export type ServiceBadgeStatusType =
  | '작성대기'
  | '전송완료'
  | '주문완료'
  | '시공대기'
  | '시공중'
  | '취소요청'
  | '시공취소'
  | '시공완료'
  | '정산완료'
  | '결제취소'
  | '취소불가'
  | '결제완료'
  | '결제대기'
  | null
export const SERVICE_BADGE_COLOR = {
  // 주문서 작성
  작성대기: BADGE_COLOR.PrimaryLight,
  전송완료: BADGE_COLOR.PrimaryFill,

  주문완료: BADGE_COLOR.AccentLight,
  시공대기: BADGE_COLOR.PrimaryLight,
  시공중: BADGE_COLOR.SuccessLight,
  시공취소: BADGE_COLOR.Gray,
  시공완료: BADGE_COLOR.PrimaryFill,

  정산완료: BADGE_COLOR.DangerFill,

  결제완료: BADGE_COLOR.PrimaryLight,
  결제대기: BADGE_COLOR.AccentLight,
  결제취소: BADGE_COLOR.Gray,
  취소요청: BADGE_COLOR.Danger,
  취소불가: BADGE_COLOR.AccentFill,

  전체: BADGE_COLOR.PrimaryLight,
  일반회원: BADGE_COLOR.PrimaryLight,
  정기회원: BADGE_COLOR.SuccessLight,
  파트너: BADGE_COLOR.AccentLight,
  숙련공: BADGE_COLOR.Gray,
  중요: BADGE_COLOR.Danger,

  수수다: BADGE_COLOR.PrimaryLight,
  프리랜서: BADGE_COLOR.SuccessLight,
  신규가입: BADGE_COLOR.Danger,
  비활성화: BADGE_COLOR.Gray,

  고객지원팀: BADGE_COLOR.PrimaryLight,
  현장지원팀: BADGE_COLOR.Danger,
  자재지원팀: BADGE_COLOR.SuccessLight,

  '금액 할인쿠폰': BADGE_COLOR.PrimaryLight,
  '정률 할인쿠폰': BADGE_COLOR.PrimaryLight,
  '회원 지정': BADGE_COLOR.PrimaryLight,
  '코드 발급': BADGE_COLOR.SuccessFill,

  진행중: BADGE_COLOR.PrimaryLight,
  종료: BADGE_COLOR.Gray,
  예정: BADGE_COLOR.SuccessLight,

  '포인트 발행': BADGE_COLOR.PrimaryLight,
  '포인트 사용': BADGE_COLOR.PrimaryFill,
  기간만료: BADGE_COLOR.Gray,
  '포인트 적립': BADGE_COLOR.AccentLight,

  휴면: BADGE_COLOR.Danger,
  탈퇴: BADGE_COLOR.Gray,
} as const
export type ServiceStates = keyof typeof SERVICE_BADGE_COLOR

export type BadgeColorKeys<K extends ServiceStates> = keyof Pick<
  typeof SERVICE_BADGE_COLOR,
  K
>

interface BadgeComponentProps extends DivAttributes {
  _value: string
  _css?: AdditionalCss
  _dot?: boolean
}

export const BadgeComponent = ({
  _value,
  _css,
  _dot,
  ...props
}: BadgeComponentProps) => {
  if (isObjectKeyType(SERVICE_BADGE_COLOR, _value)) {
    return (
      <Badge {...SERVICE_BADGE_COLOR[_value]} _css={_css} {...props}>
        {_dot && <div className="dot" />}
        {_value}
      </Badge>
    )
  }
  return <></>
}

const Badge = styled.div(
  ({ _bgColor, _css, _textColor, ...props }: BadgeProps) => css`
    ${typography['Body/Caption/Bold']}
    padding: 2px 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    overflow: initial !important;
    ${_bgColor
      ? css`
          background-color: ${colors[_bgColor]};
        `
      : ''}
    ${_textColor
      ? css`
          color: ${colors[_textColor]} !important;
        `
      : ''}
      position: relative;
    .dot {
      position: absolute;
      top: -4px;
      right: -4px;
      width: 4px;
      height: 4px;
      background-color: ${colors['Danger/Dark']};
      border-radius: 2px;
    }
    ${_css ? _css : ''}
  `
)
export default Badge
