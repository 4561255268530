import { css } from '@emotion/react'
import { IconsServiceImage, ImagesServiceSort, ImagesServiceSwap } from 'assets'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import QuillEditorComponent from 'components/QuillEditorComponent'
import { QUILL_DEFAULT_OPTIONS } from 'components/QuillEditorComponent/QuillEditorComponent'
import TextArea from 'components/Textarea'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import useEmptyCheck from 'hooks/useEmptyCheck'
import { clone } from 'lodash-es'
import { MutableRefObject, useRef, useState } from 'react'
import {
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { cn, getFileToBlob, moveArray, thousand, uuid } from 'utils/helpers'
import { callFileUpload } from 'utils/services'
import {
  MandatoryProductInfoProps,
  MaterialValuesType,
  initialMaterialValue,
} from '../../ServiceManagement/ServiceManagement.types'
import { SettingStyles } from '../../Setting.styles'
import S from './ServiceMaterials.styles'
import { ServiceMaterialsProps } from './ServiceMaterials.types'

const ServiceMaterials = ({
  _css,
  inputs,
  _currentValue,
  handleInput,
  ...props
}: ServiceMaterialsProps) => {
  const uuidRef = useRef('fileUpload-' + uuid()) as MutableRefObject<string>
  const dragStartElement = useRef() as MutableRefObject<HTMLDivElement>
  const dragStartIndex = useRef(0) as MutableRefObject<number>
  const [draggable, set_draggable] = useState(false)
  const handleDragOrderList = (currentIndex: number, nextIndex: number) => {
    if (currentIndex === nextIndex) {
      return
    }
    dragStartIndex.current = nextIndex
    const result = moveArray(inputs.materials, currentIndex, nextIndex)
    handleInput('materials', result)
  }
  const [selectedMaterial, set_selectedMaterial] =
    useState<MaterialValuesType>()
  const trueWhenEmptyMaterial = useEmptyCheck(
    selectedMaterial,
    'thumbnail',
    'mandatoryProductInfo'
  )
  const trueWhenEmptyThumbnail = useEmptyCheck(
    selectedMaterial?.thumbnail,
    'id'
  )
  const trueWhenEmptyMandatory = useEmptyCheck(
    selectedMaterial?.mandatoryProductInfo
  )
  const [files, set_files] = useState<string>('')
  const handleMandatory = (
    target: keyof MandatoryProductInfoProps,
    value: string
  ) => {
    set_selectedMaterial((prev) =>
      prev
        ? {
            ...prev,
            mandatoryProductInfo: {
              ...prev.mandatoryProductInfo,
              [target]: value,
            },
          }
        : prev
    )
  }

  const handleMaterialImageUpload = async (...files: File[]) => {
    const currentFile = files[0]
    if (!currentFile) return
    const fileId = await callFileUpload(
      currentFile,
      'order',
      '/service-product/material'
    )
    if (fileId) {
      const blob = await getFileToBlob(currentFile)
      set_selectedMaterial((prev) =>
        prev
          ? {
              ...prev,
              thumbnail: {
                id: fileId,
                url: blob,
              },
            }
          : prev
      )
    }
  }
  const [quillLength, set_quillLength] = useState<number>(1)
  return (
    <>
      <BoxLayout _title={'자재'}>
        <FlexRow
          gap={12}
          alignItems="flex-start"
          _css={css`
            & > div {
              flex: 1 0;
              max-width: calc(50% - 6px);
            }
          `}
        >
          <ContentRadiusBox
            gap={24}
            _css={css`
              min-height: 488px;
              position: relative;
              padding-bottom: 52px;
            `}
          >
            <BoxRowComponent
              _label={
                <FlexRow gap={6}>
                  <TXT
                    _color="Grayscale/Gray Default"
                    _textStyle="Menu/Bold"
                  >{`자재 목록`}</TXT>
                  <TXT
                    _color="Primary/Default"
                    _textStyle="Body/Caption/Bold"
                  >{`총 ${inputs.materials.length}개`}</TXT>
                </FlexRow>
              }
            >
              <GrayScaleOutline
                width={84}
                height={40}
                onClick={() => {
                  set_selectedMaterial(clone(initialMaterialValue))
                }}
              >
                추가
              </GrayScaleOutline>
            </BoxRowComponent>
            {inputs.materials.length > 0 ? (
              <FlexColumn gap={12} justifyContent="space-between">
                <FlexColumn
                  gap={12}
                  _css={css`
                    padding: 12px 0;
                  `}
                >
                  {inputs.materials.map((material, materialIndex) => (
                    <S.MaterialRow
                      key={material.id}
                      className={cn(
                        {
                          active:
                            selectedMaterial?.id === material.id && !draggable,
                          draggable,
                        },
                        'draggable-option-item'
                      )}
                      onClick={() => {
                        if (draggable) return
                        set_selectedMaterial({ ...material })
                      }}
                      onDrag={(e) => {
                        const eventTarget = e.currentTarget
                        if (dragStartIndex.current === materialIndex) {
                          eventTarget.classList.add('drag-item')
                        }
                      }}
                      onDragLeave={(e) => {
                        const eventTarget = e.currentTarget
                        eventTarget.classList.remove('drag-item')
                      }}
                      onDragEnter={(e) => {
                        e.preventDefault()
                        const eventTarget = e.currentTarget
                        if (
                          eventTarget.classList.contains(
                            'draggable-option-item'
                          )
                        ) {
                          if (e.target !== dragStartElement.current) {
                            handleDragOrderList(
                              dragStartIndex.current,
                              materialIndex
                            )
                          }
                        }
                      }}
                      onDragOver={(e) => {
                        e.preventDefault()
                      }}
                      onDragEnd={(e) => {
                        const eventTarget = e.currentTarget
                        eventTarget.classList.remove('dragging')
                        eventTarget.classList.remove('drag-item')
                      }}
                      onDragStart={(e) => {
                        const currentTarget = e.currentTarget
                        currentTarget.classList.add('dragging')
                        dragStartIndex.current = materialIndex
                        dragStartElement.current =
                          e.currentTarget as HTMLDivElement
                        e.dataTransfer.setData(
                          'text/plain',
                          String(materialIndex)
                        )
                      }}
                      draggable={draggable}
                    >
                      {draggable && (
                        <img src={ImagesServiceSort} alt="sort-able" />
                      )}
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url(${material.thumbnail.url})`,
                        }}
                      ></div>
                      <div className="box">
                        <div className="title">{material.title}</div>
                        <div className="option">
                          {thousand(material.price ?? 0)}원
                        </div>
                      </div>
                    </S.MaterialRow>
                  ))}
                </FlexColumn>
              </FlexColumn>
            ) : (
              <S.NotFoundItem>자재를 추가하세요.</S.NotFoundItem>
            )}

            <S.SwapButtonWrap>
              <S.SwapButton
                onClick={() => {
                  set_draggable((prev) => !prev)
                }}
              >
                순서 변경
                <img src={ImagesServiceSwap} alt="swap" />
              </S.SwapButton>
            </S.SwapButtonWrap>
          </ContentRadiusBox>
          <ContentRadiusBox
            _css={css`
              min-height: 488px;
            `}
          >
            {selectedMaterial ? (
              <S.MaterialDetails>
                <SettingStyles.UploadImageWrap
                  _css={css`
                    margin-bottom: 24px;
                  `}
                >
                  {selectedMaterial.thumbnail.url ? (
                    <S.Thumbnail
                      src={selectedMaterial.thumbnail.url}
                      alt="current-material"
                      width={100}
                      height={100}
                    />
                  ) : (
                    <SettingStyles.UploadImage>
                      <IconsServiceImage />
                    </SettingStyles.UploadImage>
                  )}
                  <input
                    id={uuidRef.current}
                    type="file"
                    multiple={false}
                    value={files}
                    accept={'image/x-png, image/png, image/gif, image/jpeg'}
                    onChange={async (e) => {
                      set_files(e.target.value)
                      const getFiles = e.target.files as FileList
                      if (getFiles) {
                        await handleMaterialImageUpload(...getFiles)
                        setTimeout(() => {
                          set_files('')
                        }, 0)
                      }
                    }}
                  />
                </SettingStyles.UploadImageWrap>
                <div className="title-box">
                  <div className="label">기본 정보</div>
                  <ContentRadiusBox gap={12}>
                    <BoxRowComponent _label={'자재명 *'}>
                      <UnderlineInput
                        placeholder="자재명 입력"
                        value={selectedMaterial.title}
                        handleValue={(value) => {
                          set_selectedMaterial((prev) =>
                            prev ? { ...prev, title: value } : prev
                          )
                        }}
                      />
                    </BoxRowComponent>
                    <BoxRowComponent _label={'수량 입력 *'}>
                      <UnderlineNumberInput
                        placeholder="최대 수량 입력"
                        value={selectedMaterial.count}
                        handleValue={(value) => {
                          set_selectedMaterial((prev) =>
                            prev ? { ...prev, count: value } : prev
                          )
                        }}
                      />
                    </BoxRowComponent>
                    <BoxRowComponent _label={'가격 *'}>
                      <UnderlineNumberInput
                        placeholder="가격 입력"
                        value={selectedMaterial.price}
                        handleValue={(value) => {
                          set_selectedMaterial((prev) =>
                            prev ? { ...prev, price: value } : prev
                          )
                        }}
                        suffix={'원'}
                      />
                    </BoxRowComponent>
                  </ContentRadiusBox>
                </div>
                <div>
                  <QuillEditorComponent
                    _css={css`
                      padding-top: 16px;
                      padding-bottom: 24px;
                    `}
                    _emitLength={(length) => {
                      set_quillLength(length)
                    }}
                    _options={{
                      ...QUILL_DEFAULT_OPTIONS,
                      placeholder: '상세 내용 입력',
                    }}
                    _quillHTML={selectedMaterial.quillHTML ?? ''}
                    _emitValue={(value, _d, _s, editor) => {
                      set_quillLength(editor.getLength())
                      set_selectedMaterial((prev) =>
                        prev ? { ...prev, quillHTML: value } : prev
                      )
                    }}
                  />
                </div>
                <div className="title-box">
                  <div className="label">상세정보</div>
                  <ContentRadiusBox gap={12}>
                    {(
                      Object.keys(
                        selectedMaterial.mandatoryProductInfo
                      ) as Array<keyof MandatoryProductInfoProps>
                    ).map((keyName) => {
                      return (
                        <BoxRowComponent
                          _label={keyName + ' *'}
                          key={keyName}
                          className="flex-start"
                        >
                          {keyName === '인증' ||
                          keyName === '교환/반품 제한' ||
                          keyName === '판매자 정보' ? (
                            <>
                              <TextArea
                                placeholder={`${keyName} 입력`}
                                _css={css`
                                  border: none;
                                  border-radius: 0px;
                                  padding: 10px 0;
                                `}
                                value={
                                  selectedMaterial.mandatoryProductInfo[keyName]
                                }
                                onChange={(e) => {
                                  handleMandatory(keyName, e.target.value)
                                }}
                              ></TextArea>
                            </>
                          ) : (
                            <>
                              <UnderlineInput
                                placeholder={`${keyName} 입력`}
                                value={
                                  selectedMaterial.mandatoryProductInfo[keyName]
                                }
                                handleValue={(value) => {
                                  handleMandatory(keyName, value)
                                }}
                              />
                            </>
                          )}
                        </BoxRowComponent>
                      )
                    })}
                  </ContentRadiusBox>
                </div>
                <S.MaterialButtons>
                  {selectedMaterial.id ? (
                    <>
                      <GrayScaleFill
                        onClick={() => {
                          if (!selectedMaterial.id) return
                          let modified = clone(selectedMaterial)
                          set_selectedMaterial(clone(initialMaterialValue))
                          handleInput('materials', (prev) => {
                            let current = prev.materials
                            return current.filter(
                              (material) => material.id !== modified.id
                            )
                          })
                        }}
                      >
                        삭제
                      </GrayScaleFill>
                      <GrayScaleOutline
                        disabled={
                          trueWhenEmptyMaterial ||
                          trueWhenEmptyThumbnail ||
                          trueWhenEmptyMandatory ||
                          quillLength <= 1
                        }
                        onClick={() => {
                          if (!selectedMaterial.id) return
                          let modified = clone(selectedMaterial)
                          set_selectedMaterial(clone(initialMaterialValue))
                          handleInput('materials', (prev) => {
                            let current = prev.materials
                            return current.map((material) =>
                              material.id === modified.id ? modified : material
                            )
                          })
                        }}
                      >
                        수정
                      </GrayScaleOutline>
                    </>
                  ) : (
                    <>
                      <PrimaryButton
                        disabled={
                          trueWhenEmptyMaterial ||
                          trueWhenEmptyThumbnail ||
                          trueWhenEmptyMandatory ||
                          quillLength <= 1
                        }
                        onClick={() => {
                          let addMaterial = {
                            ...clone(selectedMaterial),
                            id: uuid(),
                          }
                          set_selectedMaterial(clone(initialMaterialValue))
                          handleInput('materials', (prev) => {
                            let current = prev.materials
                            return [...current, addMaterial]
                          })
                        }}
                      >
                        자재 추가
                      </PrimaryButton>
                    </>
                  )}
                </S.MaterialButtons>
              </S.MaterialDetails>
            ) : (
              <S.NotFoundItem>자재를 추가하세요.</S.NotFoundItem>
            )}
          </ContentRadiusBox>
        </FlexRow>
      </BoxLayout>
    </>
  )
}
export default ServiceMaterials
