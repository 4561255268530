import { unstable_batchedUpdates } from 'react-dom'
import { store } from 'store'
import { setToast } from 'store/toastSlice'
import callAxios, { handleError } from 'utils/callAxios'
import { ApiZoneResponse } from './EditServiceArea.types'

export const parseZoneArrayGroupBy = (
  zone: ApiZoneResponse[],
  type: 'si' | 'gu'
) => {
  return zone.reduce((prev, curr) => {
    prev[curr[type]] = prev[curr[type]] || []
    prev[curr[type]].push(curr)
    return prev
  }, {} as { [key in string]: ApiZoneResponse[] })
}

export const addLocalGroup = async (postData: {
  name: string
  zoneBcodes: string[]
}) => {
  try {
    await callAxios('user').post('/zone-group', postData)
    unstable_batchedUpdates(() => {
      store.dispatch(setToast(['서비스 지역이 추가되었습니다.', 'success']))
    })
  } catch (error) {
    handleError(error)
  }
}
