import { PointList } from 'pages/Support/Point/PointList/PointList'
import { useParams } from 'react-router-dom'

export interface PointProps {}

const Point = (props: PointProps) => {
  const { id } = useParams<'id'>()

  return (
    <>
      {/* {id && <PointDetails _id={id} />} */}
      {!id && <PointList />}
    </>
  )
}
export default Point
