import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { Images500_error } from 'assets'
import { PrimaryButton } from 'components/Buttons'
import { useSearchParams } from 'react-router-dom'
import { FlexColumn, TXT } from 'themes/Shared.styles'
import { colors, typography } from 'themes/styles'

const ServerErrorPageWrap = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors['Primary/Default']};
  ${typography['Headline/Bold']}
  text-align: center;
  background-color: #fff;
`

interface ServerErrorPageProps {}
const ServerErrorPage = ({ ...props }: ServerErrorPageProps) => {
  const [searchParams] = useSearchParams()

  return (
    <>
      <ServerErrorPageWrap>
        <FlexColumn gap={60} alignItems="center">
          <FlexColumn gap={24} alignItems="center">
            <img src={Images500_error} alt="500 error" />
            <FlexColumn gap={12} alignItems="center">
              <TXT _textStyle="Display/1 Bold" _color="Grayscale/Gray Default">
                죄송합니다. 서버가 응답하지 않습니다.
              </TXT>
              <TXT _textStyle="Title/Regular" _color="Grayscale/Gray Default">
                내부 서버 오류로 페이지를 표시할 수 없습니다.
                <br />
                새로고침을 누르시거나 잠시 후 다시 시도해보세요.
              </TXT>
            </FlexColumn>
          </FlexColumn>
          <PrimaryButton
            _css={css`
              width: 160px;
              height: 48px;
            `}
            onClick={() => {
              if (searchParams.get('from')) {
                window.location.href = searchParams.get('from')!
              }
            }}
          >
            새로고침
          </PrimaryButton>
        </FlexColumn>
      </ServerErrorPageWrap>
    </>
  )
}
export default ServerErrorPage
