import { css } from '@emotion/react'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DraggablePopup from 'components/DraggablePopup'
import Dropdown from 'components/Dropdown'
import TextArea from 'components/Textarea'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import useMatchInput from 'hooks/useMatchInput'
import AlertRow from 'pages/Work/components/AlertRow'
import { useMemo } from 'react'
import { ContentRadiusBox, FlexColumn } from 'themes/Shared.styles'
import {
  ELEVATOR_DROPDOWN_LIST,
  FREE_PARKING_DROPDOWN_LIST,
} from 'types/services.type'
import S from './EditBuildingPopup.styles'
import { EditBuildingPopupProps } from './EditBuildingPopup.types'

const EditBuildingPopup = ({
  _css,
  _onClose,
  _data,
  _emitValue,
  ...props
}: EditBuildingPopupProps) => {
  const { inputs, handleInput } = useMatchInput(_data)
  const handleSubmit = () => {
    _emitValue(inputs)
  }
  const trueWhenEmpty = useMemo(() => {
    if (!inputs.buildingName) return true
    if (!inputs.floorCount) return true
    if (!inputs.elevator) return true
    if (!inputs.freeParking) return true
    if (!inputs.frontPwd) return true
    return false
  }, [inputs])
  return (
    <>
      <DraggablePopup
        _title={'건물 수정'}
        _onClose={_onClose}
        _open={true}
        _bottom={
          <>
            <GrayScaleFill onClick={_onClose}>취소</GrayScaleFill>
            <PrimaryButton disabled={trueWhenEmpty} onClick={handleSubmit}>
              저장
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <AlertRow className="flex-start">
            건물 정보 수정은 아래 정보들만 수정이 가능합니다.
            <br />
            이외 정보 수정을 원하시면 건물 삭제 후 재등록하시기 바랍니다.
          </AlertRow>
          <FlexColumn
            gap={32}
            width={'100%'}
            _css={css`
              & > div {
                width: 100%;
              }
            `}
          >
            <BoxLayout _title={'기본 정보'}>
              <ContentRadiusBox gap={12}>
                <BoxRowComponent _label={'건물명 *'}>
                  <UnderlineInput
                    value={inputs.buildingName}
                    placeholder="건물명 입력"
                    handleValue={(value) => {
                      handleInput('buildingName', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'법인사업자 번호'}>
                  <UnderlineInput
                    value={inputs.businessNo}
                    placeholder="내용 입력"
                    handleValue={(value) => {
                      handleInput('businessNo', value)
                    }}
                  />
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout _title={'기타 정보'}>
              <ContentRadiusBox gap={12}>
                <BoxRowComponent _label={'총 층수 *'}>
                  <UnderlineNumberInput
                    placeholder="숫자만 입력"
                    value={inputs.floorCount}
                    handleValue={(value) => {
                      handleInput('floorCount', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'엘리베이터 *'}>
                  <Dropdown.Underline
                    _list={ELEVATOR_DROPDOWN_LIST}
                    _value={inputs.elevator}
                    _emitValue={(value) => {
                      handleInput('elevator', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'무료주차 *'}>
                  <Dropdown.Underline
                    _list={FREE_PARKING_DROPDOWN_LIST}
                    _value={inputs.freeParking}
                    _emitValue={(value) => {
                      handleInput('freeParking', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'주차방법'} className="flex-start">
                  <TextArea
                    placeholder="1000자 이하"
                    _css={css`
                      border: none;
                      border-radius: 0px;
                      padding: 10px 0;
                      min-height: 120px;
                    `}
                    value={inputs.parkingMethod}
                    onChange={(e) =>
                      handleInput('parkingMethod', e.target.value)
                    }
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'현관 비밀번호 *'}>
                  <UnderlineInput
                    placeholder="내용 입력"
                    value={inputs.frontPwd}
                    handleValue={(value) => {
                      handleInput('frontPwd', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'비고'} className="flex-start">
                  <TextArea
                    placeholder="1000자 이하"
                    _css={css`
                      border: none;
                      border-radius: 0px;
                      padding: 10px 0;
                      min-height: 120px;
                    `}
                    value={inputs.remarks}
                    onChange={(e) => handleInput('remarks', e.target.value)}
                  />
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
          </FlexColumn>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default EditBuildingPopup
