import { css } from '@emotion/react'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryOutlineButton,
} from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash-es'
import { MaterialsInformationForRecordsType } from 'pages/Work/Work.types'
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import { ContentRadiusBox, FlexColumn, FlexRow } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import { thousand } from 'utils/helpers'
import ManagerPart from '../../ManagerPart'
import MaterialFee from '../../MaterialFee'
import AssignTeamPopup from '../AssignTeamPopup'
import { AssignedSchedulesType } from '../AssignTeamPopup/AssignTeamPopup.types'
import { AssignMaterialsProps } from './AssignMaterials.types'

const AssignMaterials = ({
  _css,
  _readOnly,
  handleModal,
  inputs,
  handleInput,
  ...props
}: AssignMaterialsProps) => {
  const { id } = useParams<'id'>()
  const dispatch = useDispatch()
  const [openMaterialTeam, set_openMaterialTeam] = useState<{
    open: boolean
    id?: string
    data?: AssignedSchedulesType
  }>({
    open: false,
  })
  const [openMaterialFee, set_openMaterialFee] = useState<{
    open: boolean
    id?: number | null
    data?: MaterialsInformationForRecordsType
  }>({
    open: false,
  })

  const handleSubmit = async (value: AssignedSchedulesType) => {
    try {
      await callAxios('task').put(`/schedule`, {
        type: '자재',
        taskId: id,
        memo: value.remarks,
        schedule: value.daySchedules.map((day) => ({
          ymd: dayjs(day.currentDay).format('YYYYMMDD'),
          startTime: day.times.start.replace(':', ''),
          endTime: day.times.end.replace(':', ''),
          workerList: uniqBy(day.techs, (value) => value.id).map(
            (item) => item.id
          ),
        })),
      })
      handleInput('materialTeam', value)
      dispatch(setToast(['자재팀이 배정되었습니다.', 'success']))
      set_openMaterialTeam({ open: false })
    } catch (error) {
      handleError(error)
    }
  }
  const handleMaterialUpdate = async (
    value: MaterialsInformationForRecordsType
  ) => {
    try {
      let putData = {
        name: value.name,
        count: value.count,
      } as {
        id?: number | null
        name: string
        count: number
      }
      if (value.id) {
        putData.id = value.id
      }
      const res = await callAxios('task').put<
        DoubleDResponse<MaterialsInformationForRecordsType>
      >(`/task/${id}/material`, putData)
      if (res.data.data) {
        const prevExtra = [...inputs.materials]
        let nextValue = res.data.data
        const nextExtra = openMaterialFee.id
          ? prevExtra.map((item) =>
              item.id === nextValue.id ? { ...item, ...nextValue } : item
            )
          : [...prevExtra, nextValue]
        handleInput('materials', nextExtra)
      }
      set_openMaterialFee((prev) => ({ open: false }))
    } catch (error) {
      handleError(error)
    }
  }
  const handleDeleteMaterial = async (
    value: MaterialsInformationForRecordsType
  ) => {
    if (!value.id) return
    try {
      await callAxios('task').delete(`/task/${value.id}/material`)
      handleInput('materials', (prev) =>
        prev.materials.filter((item) => item.id !== value.id)
      )
      dispatch(
        setToast({
          message: '삭제 되었습니다.',
          type: 'error',
        })
      )
      handleModal(null)
    } catch (error) {}
  }
  return (
    <>
      {openMaterialFee.open && (
        <MaterialFee
          _id={openMaterialFee.id}
          _data={openMaterialFee.data}
          _open={openMaterialFee.open}
          _onClose={() => set_openMaterialFee((prev) => ({ open: false }))}
          _emitValue={handleMaterialUpdate}
        />
      )}
      {openMaterialTeam.open && (
        <AssignTeamPopup
          _data={openMaterialTeam.data}
          _open={openMaterialTeam.open}
          _onClose={() => {
            set_openMaterialTeam({ open: false })
          }}
          _emitValue={handleSubmit}
          _type="자재"
        />
      )}
      <FlexColumn
        gap={24}
        _css={css`
          & > div {
            width: 100%;
          }
        `}
      >
        <ManagerPart
          _readOnly={_readOnly}
          _managerTeam={inputs.materialTeam}
          _label={{
            teams: '자재관리팀',
            time: '자재 배달 일자',
            info: '자재팀 정보',
          }}
          _handleOpenSelectPopup={(value) => {
            set_openMaterialTeam(value)
          }}
        />
        <div>
          <BoxLayout _title={'자재 내역'}>
            <ContentRadiusBox>
              <BoxRowComponent
                _label={'자재'}
                _contents={
                  <PrimaryOutlineButton
                    _css={css`
                      padding: 5px 0;
                      width: 100%;
                    `}
                    onClick={() => {
                      set_openMaterialFee({ open: true })
                    }}
                  >
                    입력
                  </PrimaryOutlineButton>
                }
              />
            </ContentRadiusBox>
          </BoxLayout>
          {inputs.materials.length > 0 && (
            <div>
              {inputs.materials.map((material, materialIndex) => {
                return (
                  <Fragment key={'material' + materialIndex}>
                    <ContentRadiusBox
                      _css={css`
                        margin-top: 12px;
                      `}
                    >
                      <BoxRowComponent
                        _label={'자재 이름'}
                        _contents={material.name}
                      />
                      <BoxRowComponent
                        _label={'개수'}
                        _contents={thousand(material.count) + '개'}
                      />
                      <BoxRowComponent
                        _label={'자재 정보'}
                        _contents={
                          <FlexRow gap={16}>
                            <GrayScaleOutline
                              _css={css`
                                padding: 5px 0;
                                flex: 1;
                              `}
                              onClick={() => {
                                set_openMaterialFee({
                                  open: true,
                                  id: material.id,
                                  data: material,
                                })
                              }}
                            >
                              수정
                            </GrayScaleOutline>
                            <GrayScaleFill
                              _css={css`
                                padding: 5px 0;
                                width: 97px;
                              `}
                              onClick={() => {
                                handleModal({
                                  title: '자재 삭제',
                                  content: '자재를 삭제 하시겠습니까?',
                                  onSubmit: () => {
                                    handleDeleteMaterial(material)
                                  },
                                })
                              }}
                            >
                              삭제
                            </GrayScaleFill>
                          </FlexRow>
                        }
                      />
                    </ContentRadiusBox>
                  </Fragment>
                )
              })}
            </div>
          )}
        </div>
      </FlexColumn>
    </>
  )
}
export default AssignMaterials
