import { useCallback } from 'react'
import { thousand } from 'utils/helpers'
import S from './PeriodDataBox.styles'

export type PeriodLabelProps =
  | '결제 금액'
  | '주문 건수'
  | '취소 건수'
  | '미수금 건수'
  | '총 가입자'
  | '일반 회원'
  | '정기 회원'
  | '파트너 회원'
  | '관리자'
  | '숙련공'
  | '실측공'
  | '자재지원팀'

// export interface PeriodLabelProps {
//   '결제 금액': number
//   '주문 건수': number
//   '미 결제건': number
//   '1:1 문의': number
//   '취소 건수': number
//   '총 가입자': number
//   '일반 회원': number
//   '정기 회원': number
//   '파트너 회원': number
// }
export interface PeriodDataBoxProps {
  _label: PeriodLabelProps
  _value: number
}

export const PeriodDataBox = ({
  _label,
  _value,
  ...props
}: PeriodDataBoxProps) => {
  const getUnit = useCallback((target: PeriodLabelProps) => {
    if (target === '결제 금액') return '원'
    if (
      target === '주문 건수' ||
      target === '미수금 건수' ||
      target === '취소 건수'
    )
      return '건'
    return '명'
  }, [])

  return (
    <S.Wrapper>
      <S.Label>{_label}</S.Label>
      <S.GrayRoundWrapper>
        {thousand(_value)}
        <span>{getUnit(_label)}</span>
      </S.GrayRoundWrapper>
    </S.Wrapper>
  )
}
