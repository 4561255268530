import { css } from '@emotion/react'
import {
  ImagesMiniCalendarChevronLeft,
  ImagesMiniCalendarChevronRight,
} from 'assets'
import classNames from 'classnames'
import { GrayScaleOutline } from 'components/Buttons'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FlexRow } from 'themes/Shared.styles'
import generateCalendar, { GeneratedType } from 'utils/getCalendar'
import { cn } from 'utils/helpers'
import { parseQueryStringToObject } from 'utils/parsers'
import S from './CalendarInReservation.styles'
import { CalendarInReservationProps } from './CalendarInReservation.types'

const CalendarInReservation = ({
  _css,
  _value: selected,
  _emitValue,
  _DayContents: DayContents,
  _currentMonth: currentMonth,
  _prefix,
  ...props
}: CalendarInReservationProps) => {
  const [currentCalendar, set_currentCalendar] = useState<GeneratedType[][]>()
  const [searchParams, set_searchParams] = useSearchParams()
  useEffect(() => {
    const calendar = generateCalendar(currentMonth, selected)
    set_currentCalendar(calendar)
  }, [currentMonth, selected])
  const handleMonth = (type: 'add' | 'subtract') => {
    const { month } = parseQueryStringToObject(searchParams.toString())
    searchParams.set(
      'month',
      month
        ? dayjs(month, 'YYYY-MM')[type](1, 'month').format('YYYY-MM')
        : dayjs()[type](1, 'month').format('YYYY-MM')
    )
    set_searchParams(searchParams.toString())
  }
  return (
    <>
      <S.CalendarWrap className="popup-calendar" _css={_css} {...props}>
        <S.CalendarHeader>
          <FlexRow gap={8}>
            <h1>{currentMonth.format('YYYY년 MM월')}</h1>
            <FlexRow gap={6}>
              <GrayScaleOutline
                _css={css`
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                `}
                onClick={() => {
                  handleMonth('subtract')
                }}
              >
                <img src={ImagesMiniCalendarChevronLeft} alt="prev" />
              </GrayScaleOutline>
              <GrayScaleOutline
                _css={css`
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                `}
                onClick={() => {
                  handleMonth('add')
                }}
              >
                <img src={ImagesMiniCalendarChevronRight} alt="next" />
              </GrayScaleOutline>
            </FlexRow>
          </FlexRow>
          <FlexRow gap={48} justifyContent="flex-end">
            {_prefix}
            <GrayScaleOutline
              _mini
              _css={css`
                padding: 5px 12px;
                white-space: nowrap;
              `}
              onClick={() => {
                searchParams.set('month', dayjs().format('YYYY-MM'))
                _emitValue(dayjs())
              }}
            >
              오늘
            </GrayScaleOutline>
          </FlexRow>
        </S.CalendarHeader>
        <S.CalendarDays className={cn('calendar-body')}>
          <S.WeeksRow className="week">
            <S.Weeks>일</S.Weeks>
            <S.Weeks>월</S.Weeks>
            <S.Weeks>화</S.Weeks>
            <S.Weeks>수</S.Weeks>
            <S.Weeks>목</S.Weeks>
            <S.Weeks>금</S.Weeks>
            <S.Weeks>토</S.Weeks>
          </S.WeeksRow>
          {currentCalendar &&
            currentCalendar.map((week, index) => (
              <S.CalendarRow key={'week' + index}>
                {week.map((day, _index) => {
                  const {
                    isGrayed,
                    isSelected,
                    isToday,
                    endBetween,
                    startBetween,
                  } = day
                  return (
                    <S.DayColumn
                      key={'day' + _index}
                      className={classNames(
                        {
                          isGrayed,
                          isSelected,
                          isToday,
                          endBetween,
                          startBetween,
                        },
                        'day-column'
                      )}
                      onClick={() => {
                        _emitValue(day.current)
                      }}
                    >
                      <S.DayWrap className="day-wrap">
                        <S.DayHeader>
                          <S.DayPart
                            className="day-part"
                            data-date={day.current.format('YYYY-MM-DD')}
                          >
                            {day.current.format('D')}
                          </S.DayPart>
                        </S.DayHeader>
                        {DayContents ? <DayContents day={day} /> : <></>}
                      </S.DayWrap>
                    </S.DayColumn>
                  )
                })}
              </S.CalendarRow>
            ))}
        </S.CalendarDays>
      </S.CalendarWrap>
    </>
  )
}
export default CalendarInReservation
