import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const TechsNameInDayPartStyle = {
  Techs: styled.div`
    padding: 0 6px;
    ${typography['Menu/Bold']}
    color: ${colors['Grayscale/Black light']};
    ${addCssProps}
  `,
  MoreCount: styled.div`
    ${typography['Menu/Regular']}
    color: ${colors['Grayscale/Gray Default']};
    text-align: right;
    position: absolute;
    bottom: 11px;
    right: 6px;
  `,
}

export default TechsNameInDayPartStyle
