import React, {
  createContext,
  MutableRefObject,
  useEffect,
  useReducer,
  useRef
} from 'react'
import ReactDOM from 'react-dom'
import S from './Modal.styles'
import { ModalProps } from './Modal.types'

// src/index.scss 에 모달 css 포함
// public/index.html 에 <div id="modal"></div> 포함

// context api는 필요시 수정해서 사용
export type ModalValuesType = {
  open: boolean
}
export type ModalActions = {
  dispatch?: React.Dispatch<{
    type: any
    payload: ModalValuesType
  }>
}
const initialModalValues: ModalValuesType = {
  open: false,
}
export const ModalContext = createContext<ModalValuesType & ModalActions>(
  initialModalValues
)
export const modalReducer = (
  _state: ModalValuesType,
  action: { type: any; payload: ModalValuesType }
): ModalValuesType => {
  switch (action.type) {
    case 'OPEN':
      return {
        ..._state,
        open: true,
      }
    case 'CLOSE':
      return {
        ...initialModalValues,
      }
    default:
      throw new Error('잘못된 요청')
  }
}
export const ModalProvider = (props: ModalProps) => {
  const [state, dispatch] = useReducer(modalReducer, initialModalValues)

  return (
    <ModalContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </ModalContext.Provider>
  )
}

const Modal = ({
  open,
  onClose,
  children,
  _target = '#modal',
  ...props
}: ModalProps) => {
  const wrapRef = useRef() as MutableRefObject<HTMLDivElement>
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClose } as { onClose: () => void })
    }
    return child
  })
  useEffect(() => {
    const body = document.body
    if (_target === '#global') {
      if (open) {
        body.classList.add('modal')
      } else {
        body.classList.remove('modal')
      }
    }
    return () => {
      body.classList.remove('modal')
    }
  }, [_target, open])
  return ReactDOM.createPortal(
    <>
      {open && (
        <S.Container {...props}>
          <S.Wrap tabIndex={0} ref={wrapRef} className="modal-wrap">
            {childrenWithProps}
          </S.Wrap>
          <S.Background
            className="modal-background"
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
          />
        </S.Container>
      )}
    </>,
    document.querySelector(_target)!
  )
}
export default Modal
