import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const EtcServicesStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  NotFoundItem: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    ${typography['Body/Large/Bold']}
    color: ${colors['Grayscale/Gray Light']};
  `,
  ExtraRow: styled.div`
    width: 100%;
    box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
    border-radius: 16px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    background-color: transparent;
    &.dragging {
      opacity: 0.4;
      * {
        opacity: 0.4;
      }
    }
    .img {
      width: 40px;
      height: 40px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .box {
      flex: 1 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16;
      .title {
        flex: 1;
        ${typography['Title/Bold']}
        color: ${colors['Grayscale/Black light']};
      }
      .option {
        ${typography['Body/Caption/Regular']}
        color: ${colors['Grayscale/Gray Default']};
      }
    }
    &:hover:not(.draggable) {
      background-color: ${colors['Grayscale/Background Dark']};
    }
    &.active {
      background-color: ${colors['Primary/Lighter']};
      box-shadow: inset 0px 0px 0px 2px ${colors['Primary/Default']};
    }
    &:disabled {
      cursor: default;
      * {
        color: ${colors['Grayscale/Gray Lighter']};
      }
    }
  `,
  ExtraDetails: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    justify-content: space-between;
    .title-box {
      .label {
        padding: 10px 16px;
        ${typography['Body/Small/Bold']}
        color: ${colors['Grayscale/Gray Default']};
      }
      display: flex;
      flex-direction: column;
      gap: 0px;
    }
  `,

  ExtraButtons: styled.div`
    padding: 8px 0;
    display: flex;
    align-items: center;
    gap: 16px;
    & > button {
      padding: 12px 20px;
      &:first-of-type {
        width: 160px;
      }
      &:last-of-type {
        flex: 1 0;
      }
    }
  `,
  SwapButtonWrap: styled.div`
    position: absolute;
    bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 64px);
    right: 32px;
  `,
  SwapButton: styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 2px 12px;
    ${typography['Body/Small/Bold']}
    color: ${colors['Grayscale/Gray Dark']};
    border-radius: 6px;
    &:hover {
      background-color: ${colors['Grayscale/Background Dark']};
    }
  `,
}

export default EtcServicesStyle
