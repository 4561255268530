import { css } from '@emotion/react'
import { ImagesDetailsArrowRightCircle } from 'assets'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryOutlineButton,
} from 'components/Buttons'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import { useGallery } from 'components/Gallery/Gallery.hooks'
import useFetch from 'hooks/useFetch'
import { clone } from 'lodash-es'
import {
  ApiServiceItemType,
  MeasurementValuesType,
} from 'pages/Work/Work.types'
import MeasurementInfo from 'pages/Work/components/MeasurementInfo'
import { useEffect, useState } from 'react'
import {
  GalleryStatesType,
  initialGalleryData,
  setCurrentGallery,
} from 'store/gallerySlice'
import { setToast } from 'store/toastSlice'
import { ContentRadiusBox, FlexColumn, FlexRow } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import { getFileNameFromUrl } from 'utils/helpers'
import { AssignMeasurementProps } from './AssignMeasurement.types'

const updateGallery = (
  value: MeasurementValuesType[],
  target: GalleryStatesType
): GalleryStatesType => {
  return {
    ...target,
    data: {
      ...target.data,
      '실측 사진': value
        .filter((item) => item.id !== null)
        .map((item) => {
          return {
            id: item.id!,
            title: item.title,
            list: {
              '360 사진': item['360도 사진'],
              사진: item.사진,
            },
          }
        }),
    },
  }
}
const AssignMeasurement = <
  T extends { measurementInfo: MeasurementValuesType[] }
>({
  householdsId,
  taskId,
  _css,
  inputs,
  handleInput,
  handleModal,
  ...props
}: AssignMeasurementProps<T>) => {
  const [openMeasurementInfo, set_openMeasurementInfo] = useState<{
    open: boolean
    id?: number | string
    taskId?: string
    item?: MeasurementValuesType
  }>({ open: false })
  const [response, , refresh] = useFetch<{ list: ApiServiceItemType[] }>(
    'task',
    `/measurement/list?householdsId=${householdsId}&take=99999`,
    {
      disabled: !householdsId,
    }
  )
  const { dispatch, openGallery, currentGallery } = useGallery((update) => {
    if (householdsId) {
      refresh()
    }
  })
  useEffect(() => {
    if (response) {
      // 건물 상세에서 .. 인데 일단 옮겨갈 수도?
      const data = response.list
      const newMeasurementInfo: MeasurementValuesType[] = data.map(
        (itemNext) => {
          const nextItem = {
            id: itemNext.id,
            title: itemNext.title,
            size: itemNext.size,
            etc: itemNext.note,
            사진: itemNext.imageUrl.map((url) => ({
              id: getFileNameFromUrl(url),
              name: getFileNameFromUrl(url),
              url: url,
            })),
            '360도 사진': itemNext.image360Url
              ? [
                  {
                    id: getFileNameFromUrl(itemNext.image360Url),
                    name: getFileNameFromUrl(itemNext.image360Url),
                    url: itemNext.image360Url,
                  },
                ]
              : [],
          }
          return nextItem
        }
      )
      const nextData = data.map((item) => ({
        id: item.id,
        title: item.title,
        list: {
          '360 사진': item.image360Url
            ? [
                {
                  id: getFileNameFromUrl(item.image360Url),
                  name: getFileNameFromUrl(item.image360Url),
                  url: item.image360Url,
                },
              ]
            : [],
          사진: item.imageUrl.map((data) => ({
            id: getFileNameFromUrl(data),
            name: getFileNameFromUrl(data),
            url: data,
          })),
        },
      }))
      dispatch(
        setCurrentGallery({
          id: householdsId,
          data: {
            ...initialGalleryData.data,
            '실측 사진': nextData,
          },
        })
      )
      handleInput('measurementInfo', newMeasurementInfo)
    }
  }, [response, handleInput, householdsId, dispatch])
  const handleAddMeasurement = async (value: MeasurementValuesType) => {
    try {
      const postData = {
        title: value.title,
        taskId: taskId,
        householdsId: householdsId,
        size: value.size,
        image: value.사진.map((item) => item.name),
        image360:
          value['360도 사진']?.length > 0
            ? value['360도 사진'].map((item) => item.name)[0]
            : undefined,
        note: value.etc,
        ...(typeof openMeasurementInfo.id === 'number' && {
          id: openMeasurementInfo.id,
        }),
      }
      const res = await callAxios('task')[
        typeof openMeasurementInfo.id === 'number' ? 'put' : 'post'
      ]<DoubleDResponse<ApiServiceItemType>>(`/measurement`, postData)
      if (res.data.data) {
        const measurementNext = res.data.data
        const nextItem = {
          id: measurementNext.id,
          title: measurementNext.title,
          size: measurementNext.size,
          사진: measurementNext.imageUrl.map((url) => ({
            id: getFileNameFromUrl(url),
            name: getFileNameFromUrl(url),
            url: url,
          })),
          '360도 사진': measurementNext.image360Url
            ? [
                {
                  id: getFileNameFromUrl(measurementNext.image360Url),
                  name: getFileNameFromUrl(measurementNext.image360Url),
                  url: measurementNext.image360Url,
                },
              ]
            : [],
          etc: measurementNext.note,
        }
        const nextData =
          typeof openMeasurementInfo.id === 'number'
            ? inputs.measurementInfo.map((item) =>
                item.id === openMeasurementInfo.id ? nextItem : item
              )
            : [...inputs.measurementInfo, nextItem]
        handleInput('measurementInfo', nextData)
        set_openMeasurementInfo({ open: false })
        const update = updateGallery(nextData, currentGallery)
        const forUpdate = { ...currentGallery, ...update }
        dispatch(
          setCurrentGallery({ ...forUpdate, id: taskId ?? householdsId })
        )
      }
    } catch (error) {
      handleError(error)
    }
  }
  const handleDelete = async (id: string | number) => {
    try {
      await callAxios('task').delete(`/measurement/${id}`)
      const currentMeasurementList = currentGallery.data['실측 사진']
      const nextGallery = {
        ...currentGallery,
        data: {
          ...currentGallery.data,
          '실측 사진': currentMeasurementList
            ? currentMeasurementList.filter((item) => item.id !== id)
            : null,
        },
      }
      dispatch(
        setCurrentGallery({ ...nextGallery, id: taskId ?? householdsId })
      )
      handleInput('measurementInfo', (prev) => {
        let cloned = clone(prev.measurementInfo)
        cloned = cloned.filter((ft) => id !== ft.id)
        return cloned
      })
      handleModal(null)
      dispatch(
        setToast({
          message: '삭제 되었습니다.',
          type: 'error',
        })
      )
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      {openMeasurementInfo.open && (
        <MeasurementInfo
          _galleryId={{
            householdsId: householdsId,
            taskId: taskId,
          }}
          _id={openMeasurementInfo.id}
          _open={openMeasurementInfo.open}
          _onClose={() => set_openMeasurementInfo({ open: false })}
          _item={openMeasurementInfo.item}
          _emitValue={handleAddMeasurement}
        />
      )}
      <FlexColumn
        gap={12}
        _css={css`
          & > div {
            width: 100%;
          }
        `}
      >
        <ContentRadiusBox>
          <BoxRowComponent
            _label={'실측 정보'}
            _contents={
              <PrimaryOutlineButton
                _css={css`
                  padding: 5px 0;
                  width: 100%;
                `}
                onClick={() => {
                  set_openMeasurementInfo({ open: true, taskId: taskId })
                }}
              >
                입력
              </PrimaryOutlineButton>
            }
          />
        </ContentRadiusBox>
        {inputs.measurementInfo?.map((item) => {
          const with360 = householdsId
            ? item.사진
            : currentGallery.data['실측 사진']?.find(
                (data) => data.id === item.id
              )?.list['360 사진'] ?? []
          const withSite = householdsId
            ? item['360도 사진']
            : currentGallery.data['실측 사진']?.find(
                (data) => data.id === item.id
              )?.list['사진'] ?? []
          return (
            <div key={item.id}>
              <ContentRadiusBox>
                <BoxRowComponent _label={'실측 장소'} _contents={item.title} />
                <BoxRowComponent _label={'사이즈'} _contents={item.size} />
                <BoxRowComponent
                  _label={'사진'}
                  _emptyType={'hide'}
                  _emptyCheck={withSite.length > 0}
                  _contents={
                    <FlexRow
                      gap={8}
                      justifyContent="flex-end"
                      onClick={() => {
                        if (!item.id) return
                        openGallery({
                          ...currentGallery,
                          target: ['실측 사진', item.id, '사진'],
                        })
                      }}
                      _css={`cursor:pointer;`}
                    >
                      <div>
                        {withSite.length
                          ? withSite.length + '개'
                          : '이미지 없음'}
                      </div>
                      <img src={ImagesDetailsArrowRightCircle} alt="" />
                    </FlexRow>
                  }
                />
                <BoxRowComponent
                  _label={'360도 사진'}
                  _emptyType={'hide'}
                  _emptyCheck={with360.length > 0}
                  _contents={
                    <FlexRow
                      gap={8}
                      justifyContent="flex-end"
                      onClick={() => {
                        if (!item.id) return
                        openGallery({
                          ...currentGallery,
                          target: ['실측 사진', item.id, '360 사진'],
                        })
                      }}
                      _css={`cursor:pointer;`}
                    >
                      <div>
                        {with360.length ? with360.length + '개' : '이미지 없음'}
                      </div>
                      <img src={ImagesDetailsArrowRightCircle} alt="" />
                    </FlexRow>
                  }
                />
                <BoxRowComponent
                  _label={'실측 정보'}
                  _contents={
                    <FlexRow gap={16}>
                      <GrayScaleOutline
                        _css={css`
                          padding: 5px 0;
                          flex: 1;
                        `}
                        onClick={() => {
                          if (!item.id) return
                          set_openMeasurementInfo({
                            open: true,
                            id: item.id,
                            taskId: taskId,
                            item,
                          })
                        }}
                      >
                        수정
                      </GrayScaleOutline>
                      <GrayScaleFill
                        _css={css`
                          padding: 5px 0;
                          width: 97px;
                        `}
                        onClick={() => {
                          if (!item.id) return
                          handleModal({
                            title: '실측정보 삭제',
                            content: '실측 정보를 삭제하시겠습니까?',
                            onSubmit: () => {
                              if (!item.id) return
                              handleDelete(item.id)
                            },
                          })
                        }}
                      >
                        삭제
                      </GrayScaleFill>
                    </FlexRow>
                  }
                />
              </ContentRadiusBox>
              {item.etc && (
                <ContentRadiusBox className="question">
                  <BoxRowComponent _label={'특이사항'} _contents={item.etc} />
                </ContentRadiusBox>
              )}
            </div>
          )
        })}
      </FlexColumn>
    </>
  )
}
export default AssignMeasurement
