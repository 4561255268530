import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const AreaItemColorStyle = css`
  ${typography['Body/Small/Bold']}
  color: ${colors['Grayscale/Gray Default']};
  &.active {
    color: ${colors['Primary/Dark']};
    background-color: ${colors['Primary/Lighter']};
    svg {
      path {
        fill: ${colors['Primary/Dark']};
      }
    }
  }
  &:disabled {
    background-color: ${colors['Grayscale/Background Light']};
    opacity: 0.4;
  }
`
const EditServiceAreaStyle = {
  Wrap: styled.div`
    padding: 32px;
    ${addCssProps}
  `,
  LiveSearchButtonBox: styled.div`
    border-top: 1px solid ${colors['Grayscale/Gray Lighter']};
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    & > button:first-of-type {
      padding: 5px 12px;
      height: 30px;
    }
    & > button:last-of-type {
      width: 100px;
      height: 30px;
    }
  `,
  BigChips: styled.button`
    cursor: pointer;
    border-radius: 50px;
    width: 130px;
    height: 40px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${AreaItemColorStyle};
    box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
    &.active {
      box-shadow: inset 0px 0px 0px 1px ${colors['Primary/Default']};
    }
    & > div:first-of-type {
      flex: 1;
      text-align: left;
    }
  `,
  Parents: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
    ${AreaItemColorStyle};
  `,
  AreaGroupContainer: styled.div`
    border-radius: 16px;
    height: 300px;
    display: flex;
    border: 1px solid ${colors['Grayscale/Gray Lighter']};
    align-items: flex-start;
    gap: 0px;
    overflow: hidden;
    .streets-wrap {
      padding: 8px;
      height: 100%;
      overflow-y: auto;
    }
    .streets {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .parents {
      border-right: 1px solid ${colors['Grayscale/Gray Lighter']};
      display: flex;
      flex-direction: column;
      gap: 0px;
      height: 100%;
      overflow-y: auto;
      & > button {
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        &:first-of-type {
          padding: 14px 16px 10px;
        }
        &:last-of-type {
          padding: 10px 16px 14px;
        }
      }
    }
    & > div:first-of-type {
      width: 130px;
    }
    & > div:nth-of-type(2) {
      width: 130px;
    }
    & > div:last-of-type {
      flex: 1 0;
    }
  `,
}

export default EditServiceAreaStyle
