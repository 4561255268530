import { Navigate, useLocation } from 'react-router-dom'

const ReDirect = () => {
  const location = useLocation()
  const redirect = location.state?.redirect || '/'
  return (
    <>
      <Navigate to={redirect} replace />
    </>
  )
}
export default ReDirect
