import styled from '@emotion/styled'
import { colors, typography } from 'themes/styles'

const UserBoxStyle = {
  Wrapper: styled.div`
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .label {
      margin-bottom: 25px;
      ${typography['Body/Small/Bold']};
      color: ${colors['Grayscale/Gray Default']};
    }
    .arrow {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      ${typography['Body/Caption/Bold']};
      .increase {
        color: ${colors['Success/Default']};
      }
      .decrease {
        color: ${colors['Danger/Dark']};
      }
    }
    .number {
      ${typography['Headline/Bold']};
      color: ${colors['Grayscale/Black light']};
      span {
        margin-left: 2px;
        ${typography['Body/Caption/Bold']};
        color: ${colors['Grayscale/Gray Default']};
      }
    }
  `,
}

export default UserBoxStyle
