import { css } from '@emotion/react'
import { ImagesMemberToggle } from 'assets'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import DraggablePopup from 'components/DraggablePopup'
import Dropdown from 'components/Dropdown'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import { clone } from 'lodash-es'
import { useEffect, useState } from 'react'
import {
  ASTERISK,
  ContentLabel,
  Flex,
  FlexColumn,
  FlexRow,
} from 'themes/Shared.styles'
import {
  IS_LIVING_DROPDOWN_LIST,
  ROOM_TYPE_DROPDOWN_LIST,
} from 'types/services.type'
import { cn, getHouseSize } from 'utils/helpers'
import { HOUSEHOLD_INITIAL_VALUE } from '../../Building.types'
import S from './EditHouseholdPopup.styles'
import {
  ApiHouseHold,
  EditHouseholdPopupProps,
} from './EditHouseholdPopup.types'

const EditHouseholdPopup = ({
  _css,
  _open,
  _id,
  _data,
  _index,
  _onClose,
  _emitValue,
  ...props
}: EditHouseholdPopupProps) => {
  const [response] = useFetch<ApiHouseHold>('user', `/households/${_id}`, {
    disabled: !_id,
  })
  const { inputs, handleInput, fetchInit } = useMatchInput(
    HOUSEHOLD_INITIAL_VALUE
  )
  const trueWhenEmpty = useEmptyCheck(
    inputs,
    'id',
    'recentServiceDate',
    'address',
    'addressDetails',
    'addressOld',
    'bcode',
    'elevator'
  )
  useEffect(() => {
    if (_data) {
      fetchInit(_data)
    } else if (response) {
      fetchInit({
        id: response.id,
        name: response.name,
        type: response.type as '원룸' | '투룸' | '미니투룸' | '쓰리룸',
        living: response.isLive ? 1 : 2,
        recentServiceDate: '',
        roomSize: response.exclusiveArea,
        bcode: '',
        address: '',
        addressOld: '',
        addressDetails: '',
        floorCount: response.floor,
        elevator: 1,
      })
    }
  }, [response, _data, fetchInit])
  const [roomUnit, set_roomUnit] = useState<'평' | '제곱'>('평')
  const handleRoomUnit = (unit: '평' | '제곱', value: number) => {
    const prevUnit = roomUnit
    if (unit === '제곱') {
      handleInput('roomSize', getHouseSize(value, prevUnit).meter)
    } else if (unit === '평') {
      handleInput('roomSize', getHouseSize(value, prevUnit).square)
    }
    set_roomUnit(unit)
  }
  const handleSubmit = async () => {
    let postData = clone(inputs)
    if (postData.roomSize && roomUnit === '제곱') {
      postData = {
        ...postData,
        roomSize: getHouseSize(postData.roomSize, '제곱').square,
      }
    }
    if ((_id || _data) && typeof _index === 'number') {
      _emitValue(postData, 'update', _index)
    } else if (_id || _data) {
      _emitValue(postData, 'update')
    } else {
      _emitValue(postData, 'add')
    }
  }
  const [openModal, set_openModal] = useState<{
    title: string
    content: string
    btnTitle?: string
    btnSubTitle?: string
    onSubmit: () => void
  } | null>(null)
  const handleModal = (
    value: {
      title: string
      content: string
      onSubmit: () => void
    } | null
  ) => {
    set_openModal(value)
  }
  return (
    <>
      {openModal && (
        <Modal open={openModal !== null} onClose={() => set_openModal(null)}>
          <MiniPopup
            _title={openModal.title}
            _buttonHandler={
              <>
                <GrayScaleFill
                  onClick={() => {
                    set_openModal(null)
                  }}
                >
                  {openModal.btnSubTitle ?? '아니요'}
                </GrayScaleFill>
                <PrimaryButton onClick={openModal.onSubmit}>
                  {openModal.btnTitle ?? '삭제하기'}
                </PrimaryButton>
              </>
            }
          >
            {openModal.content}
          </MiniPopup>
        </Modal>
      )}
      <DraggablePopup
        _title={_id || _data ? '세대 수정' : '세대 추가'}
        _onClose={_onClose}
        _open={_open}
        _css={css`
          max-width: 650px;
        `}
        _bottom={
          <>
            {(_id || _data) && (
              <GrayScaleFill
                onClick={() => {
                  handleModal({
                    title: '세대 삭제',
                    content: '해당 세대를 삭제하시겠습니까?',
                    onSubmit: () => {
                      handleModal(null)
                      _emitValue(inputs, 'remove', _index)
                    },
                  })
                }}
              >
                삭제
              </GrayScaleFill>
            )}
            <PrimaryButton disabled={trueWhenEmpty} onClick={handleSubmit}>
              저장
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <Flex
            gap={`32px 24px`}
            flexWrap="wrap"
            _css={css`
              & > div {
                width: calc(50% - 12px);
                & > div {
                  width: 100%;
                }
              }
            `}
          >
            <FlexColumn gap={12}>
              <ContentLabel>
                세대명
                <ASTERISK />
              </ContentLabel>
              <UnderlineInput
                placeholder="내용 입력"
                value={inputs.name}
                handleValue={(value) => handleInput('name', value)}
              />
            </FlexColumn>
            <FlexColumn gap={12}>
              <ContentLabel>
                거주 형태
                <ASTERISK />
              </ContentLabel>
              <Dropdown.Underline
                _list={ROOM_TYPE_DROPDOWN_LIST}
                _value={inputs.type}
                _emitValue={(value) => handleInput('type', value)}
              />
            </FlexColumn>
            <FlexColumn gap={12}>
              <FlexRow gap={8} justifyContent="space-between">
                <ContentLabel>
                  전용 면적
                  <ASTERISK />
                </ContentLabel>
                <S.RoomUnitToggleButton
                  onClick={() => {
                    handleRoomUnit(
                      roomUnit === '제곱' ? '평' : '제곱',
                      inputs.roomSize ?? 0
                    )
                  }}
                >
                  <div className={cn({ active: roomUnit === '평' })}>평수</div>
                  <img src={ImagesMemberToggle} alt="toggle-unit" />
                  <div className={cn({ active: roomUnit === '제곱' })}>㎡</div>
                </S.RoomUnitToggleButton>
              </FlexRow>
              <UnderlineNumberInput
                placeholder="숫자 입력"
                decimalScale={roomUnit === '평' ? 0 : 3}
                suffix={roomUnit === '제곱' ? '㎡' : '평'}
                value={inputs.roomSize}
                handleValue={(value) => handleInput('roomSize', value)}
              />
            </FlexColumn>
            <FlexColumn gap={12}>
              <ContentLabel style={{ height: '28px', alignItems: 'center' }}>
                수수다 면적
                <ASTERISK />
              </ContentLabel>
              <UnderlineNumberInput
                placeholder="숫자 입력"
                readOnly
                suffix={'평'}
                value={getHouseSize(inputs.roomSize ?? 0, roomUnit).susudaSize}
                handleValue={(value) => {}}
              />
            </FlexColumn>
            <FlexColumn gap={12}>
              <ContentLabel>
                층수
                <ASTERISK />
              </ContentLabel>
              <UnderlineNumberInput
                placeholder="숫자 입력"
                suffix={'층'}
                value={inputs.floorCount}
                handleValue={(value) => {
                  handleInput('floorCount', value)
                }}
              />
            </FlexColumn>
            <FlexColumn gap={12}>
              <ContentLabel>
                거주 여부
                <ASTERISK />
              </ContentLabel>
              <Dropdown.Underline
                _list={IS_LIVING_DROPDOWN_LIST}
                _value={inputs.living}
                _emitValue={(value) => handleInput('living', value)}
              />
            </FlexColumn>
          </Flex>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default EditHouseholdPopup
