import { ServiceStates } from 'components/Badge/Badge'
import { MeasurementValuesType } from 'pages/Work/Work.types'
import { GalleryStatesType, initialGalleryData } from 'store/gallerySlice'
import {
  ELEVATOR_DROPDOWN_LIST,
  FREE_PARKING_DROPDOWN_LIST,
  IS_LIVING_DROPDOWN_LIST,
  RESIDENT_TYPE_DROPDOWN_LIST,
  ROOM_TYPE_DROPDOWN_LIST,
} from 'types/services.type'

export type ApiHouseServiceInfoItems = {
  taskId: string
  serviceName: string
  technicianName: string
  orderAt: string
  finishedAt: string | null
  status: ServiceStates
  unpaid: boolean
}

export interface HouseDefault {
  address: string
  addressOld: string
  addressDetails: string
  bcode: string
  floorCount: number | null
  elevator: ValueOf<typeof ELEVATOR_DROPDOWN_LIST> | null
}
export interface HouseHold extends HouseDefault {
  id?: string | null
  name: string
  type: ValueOf<typeof ROOM_TYPE_DROPDOWN_LIST> | null
  living: ValueOf<typeof IS_LIVING_DROPDOWN_LIST> | null
  recentServiceDate: string | null
  roomSize: number | null
}
export const HOUSEHOLD_INITIAL_VALUE: HouseHold = {
  name: '',
  type: null,
  living: null,
  recentServiceDate: null,
  roomSize: null,
  bcode: '',
  address: '',
  addressOld: '',
  addressDetails: '',
  floorCount: null,
  elevator: null,
}
export interface BuildingInfoValuesType extends HouseDefault {
  gallery: GalleryStatesType
  residentType: ValueOf<typeof RESIDENT_TYPE_DROPDOWN_LIST> | null
  buildingName: string

  userInfo: {
    id?: string
    name: string
    phone: string
  } | null
  businessNo: string
  businessPhone: string
  groupName: string

  partnerInfo: {
    id?: string
    name: string
    phone: string
    referralFee: number | null
    referralFeeLimit: number | null
  } | null

  freeParking: ValueOf<typeof FREE_PARKING_DROPDOWN_LIST> | null
  frontPwd: string
  doorPwd: string
  parkingMethod: string

  remarks: string

  houseInfo: HouseHold[]

  measurementInfo: MeasurementValuesType[]

  serviceInfo: ApiHouseServiceInfoItems[]

  totalHouseCount: number
  totalServiceCount: number
}
export const BUILDING_INFO_INITIAL_VALUE: BuildingInfoValuesType = {
  gallery: initialGalleryData,
  residentType: null,
  businessNo: '',
  buildingName: '',
  address: '',
  addressOld: '',
  addressDetails: '',
  bcode: '',
  userInfo: null,
  businessPhone: '',
  groupName: '',

  partnerInfo: null,

  floorCount: null,
  elevator: null,
  freeParking: null,
  frontPwd: '',
  doorPwd: '',
  parkingMethod: '',

  remarks: '',

  houseInfo: [],

  measurementInfo: [],

  serviceInfo: [],

  totalHouseCount: 0,
  totalServiceCount: 0,
}
type ApiHouseHolds = {
  id?: string
  name: string
  type: string
  isLive: boolean
  // {{{{{TODO}}}}}: string. iso8601 date
  susudaArea: number
  exclusiveArea: number
  floor: number
}
export type ApiBuildingDetails = {
  id: string
  type: string
  name: string
  fullLegalAddress: string
  landLotAddress: string
  bcode: string
  businessNo: string | null
  user: {
    id: string
    name: string
    phone: string
  } | null
  partner: {
    id: string
    name: string
    phone: string
    referralWages: number // 수수료 한도
  } | null
  zoneGroup: {
    name: string
  } | null
  highestFloor: number
  isExistElevator: boolean
  parking: boolean
  parkingInfo: string
  frontPwd: string
  doorPwd: string
  note: string
  households: ApiHouseHolds[]
}

export type PostBuildingDetails = {
  type: string
  name: string
  userId: string
  businessNo: string
  bcode: string
  fullLegalAddress: string
  landLotAddress: string
  details: string
  highestFloor: number
  partnerId?: string | null
  isExistElevator: boolean
  parking: '가능' | '불가능' | '모름'
  parkingInfo: string
  referralWages: number
  frontPwd: string
  note: string
  households: ApiHouseHolds[]
}
