import {
  ImagesCheckboxChecked,
  ImagesCheckboxDisabled,
  ImagesCheckboxUnChecked,
} from 'assets'
import { cn } from 'utils/helpers'
import S from './CheckBoxForDropdown.styles'
import { CheckBoxForDropdownProps } from './CheckBoxForDropdown.types'

const CheckBoxForDropdown = ({
  _css,
  children,
  _active,
  _disabled,
  onClick,
  className,
  ...props
}: CheckBoxForDropdownProps) => {
  return (
    <>
      <S.Wrap
        _css={_css}
        _active={_active}
        className={cn({ disabled: _disabled }, className)}
        onClick={(e) => {
          e.stopPropagation()
          if (_disabled) return
          if (onClick) onClick(e)
        }}
        {...props}
      >
        <S.Contents className="checkbox-contents">
          <S.ImageBox>
            {_disabled ? (
              <>
                <img src={ImagesCheckboxDisabled} alt={'checkedImg'} />
              </>
            ) : (
              <>
                {_active && (
                  <img src={ImagesCheckboxChecked} alt={'checkedImg'} />
                )}
                {!_active && (
                  <img src={ImagesCheckboxUnChecked} alt={'unCheckedImg'} />
                )}
              </>
            )}
          </S.ImageBox>
          {children && <S.ChildrenBox>{children}</S.ChildrenBox>}
        </S.Contents>
      </S.Wrap>
    </>
  )
}
export default CheckBoxForDropdown
