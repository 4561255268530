import { css } from '@emotion/react'
import { IconsAreaCheck, IconsAreaChevronRight } from 'assets'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import CheckBoxForDropdown from 'components/CheckBoxForDropdown'
import Chips from 'components/Chips'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DraggablePopup from 'components/DraggablePopup'
import LiveSearchInput from 'components/LiveSearchInput'
import MiniPopup from 'components/MiniPopup/MiniPopup'
import Modal from 'components/Modal/Modal'
import UnderlineInput from 'components/UnderlineInput'
import useFetch from 'hooks/useFetch'
import { clone, groupBy, uniq, uniqBy } from 'lodash-es'
import AlertRow from 'pages/Work/components/AlertRow'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { FlexColumn, FlexRow } from 'themes/Shared.styles'
import { cn, objectKeys, updateAndDeleteBehind } from 'utils/helpers'
import highlight from 'utils/highlight'
import { parseZoneArrayGroupBy } from './EditServiceArea.helpers'
import S from './EditServiceArea.styles'
import { ApiZoneResponse, EditServiceAreaProps } from './EditServiceArea.types'

const EditServiceArea = ({
  _css,
  _onClose,
  _emitValue,
  _open,
  _data,
  ...props
}: EditServiceAreaProps) => {
  const [groupName, set_groupName] = useState<string>(_data?.groupName ?? '')
  const [selectedInLiveSearch, set_selectedInLiveSearch] = useState<
    ApiZoneResponse[]
  >([])

  const [zone] = useFetch<ApiZoneResponse[]>(
    'user',
    `/zone${_data?.id ? `/?zoneGroupId=${_data?.id}` : ''}`
  )

  const [selected, set_selected] = useState<string[]>([])

  const [areas, set_areas] = useState<{
    [key in string]: {
      [key in string]: ApiZoneResponse[]
    }
  }>()

  const [selectedCodes, set_selectedCodes] = useState<ApiZoneResponse[]>([])

  const toggleSelected = (item: ApiZoneResponse) => {
    set_selectedCodes((prev) =>
      prev.some((code) => code.bcode === item.bcode)
        ? prev.filter((code) => code.bcode !== item.bcode)
        : [...prev, item]
    )
  }

  useEffect(() => {
    if (zone) {
      const currentSelected = zone.filter((item) => item.selected)
      set_selectedCodes(currentSelected)
      const cities = parseZoneArrayGroupBy(zone, 'si')
      const allArea = objectKeys(cities).reduce(
        (prev, curr) => {
          prev[curr] = parseZoneArrayGroupBy(cities[curr], 'gu')
          return prev
        },
        {} as {
          [key in string]: {
            [key in string]: ApiZoneResponse[]
          }
        }
      )
      set_areas(allArea)
    }
  }, [zone])

  const cities = useMemo(() => {
    if (!areas) return []
    return objectKeys(areas)
  }, [areas])
  const districts = useMemo(() => {
    const selectedCity = selected?.[0]
    if (!areas) return []
    if (!selectedCity) return []
    if (!areas[selectedCity]) return []
    return objectKeys(areas[selectedCity])
  }, [selected, areas])

  const streets = useMemo(() => {
    const selectedCity = selected?.[0]
    const selectedDistrict = selected?.[1]
    if (!areas) return []
    if (!selectedCity) return []
    if (!selectedDistrict) return []
    if (!areas[selectedCity]) return []
    if (!areas[selectedCity][selectedDistrict]) return []
    return areas[selectedCity][selectedDistrict]
  }, [selected, areas])

  const checkAllSelected = useCallback(
    (si: string, gu: string, list: ApiZoneResponse[]) => {
      if (!zone) return false
      const currentZoneParty = zone.filter(
        (data) => data.si === si && data.gu === gu
      )
      return currentZoneParty.every((item) =>
        list.map((data) => data.bcode).includes(item.bcode)
      )
    },
    [zone]
  )

  const AllSelectedCurrent = useMemo(() => {
    return streets.every((item) =>
      selectedCodes.map((data) => data.bcode).includes(item.bcode)
    )
  }, [selectedCodes, streets])

  const AllDisabledCurrent = useMemo(() => {
    return streets.every((item) => item.disable)
  }, [streets])

  const toggleAllSelected = (item: ApiZoneResponse) => {
    if (!zone) return
    const currentZoneParty = zone.filter(
      (data) => data.si === item.si && data.gu === item.gu
    )
    if (AllSelectedCurrent) {
      set_selectedCodes((prev) =>
        prev.filter(
          (data) =>
            !(
              currentZoneParty[0].si === data.si &&
              currentZoneParty[0].gu === data.gu
            )
        )
      )
    } else {
      set_selectedCodes((prev) => uniq([...prev, ...currentZoneParty]))
    }
  }
  const chips = useMemo(() => {
    const grouped = groupBy(
      selectedCodes.map((item) => ({
        ...item,
        groupName: item.si + '-' + item.gu,
      })),
      (value) => value.groupName
    )
    return Object.keys(grouped)
      .map((keyName) => {
        return checkAllSelected(
          keyName.split('-')[0],
          keyName.split('-')[1],
          grouped[keyName]
        )
          ? [
              {
                ...grouped[keyName][0],
                isAll: true,
                fullName: `${keyName.split('-')[0]} ${
                  keyName.split('-')[1]
                } 전체`,
              },
            ]
          : grouped[keyName].map((item) => ({
              ...item,
              isAll: false,
              fullName: `${item.si} ${item.gu} ${item.dong}`,
            }))
      })
      .flat()
  }, [selectedCodes, checkAllSelected])

  const removeByChip = (item: ApiZoneResponse) => {
    if (item.isAll) {
      set_selectedCodes((prev) =>
        prev.filter((data) => !(data.si === item.si && data.gu === item.gu))
      )
    } else {
      set_selectedCodes((prev) =>
        prev.filter((data) => data.bcode !== item.bcode)
      )
    }
  }
  const [openAlert, set_openAlert] = useState<boolean>(false)
  const handleSubmit = () => {
    const value = {
      name: groupName,
      zoneBcodes: selectedCodes.map((item) => item.bcode),
    }
    _emitValue(value, _data?.id)
    _onClose()
  }
  return (
    <>
      {openAlert && (
        <Modal open={openAlert} onClose={() => set_openAlert(false)}>
          <MiniPopup
            _title="서비스 지역 수정"
            _buttonHandler={
              <>
                <GrayScaleFill onClick={() => set_openAlert(false)}>
                  취소
                </GrayScaleFill>
                <PrimaryButton onClick={handleSubmit}>확인</PrimaryButton>
              </>
            }
          >
            서비스 지역 그룹을 수정하시면
            <br />
            삭제된 지역에서는 수수다앱 서비스를
            <br />
            이용할 수 없습니다.
          </MiniPopup>
        </Modal>
      )}
      <DraggablePopup
        _title={`서비스 지역 ${_data ? '수정' : '추가'}`}
        _open={_open}
        _onClose={_onClose}
        _bottom={
          <>
            <GrayScaleFill onClick={_onClose}>취소</GrayScaleFill>
            <PrimaryButton
              onClick={() => {
                if (_data) {
                  set_openAlert(true)
                } else {
                  handleSubmit()
                }
              }}
            >
              완료
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <FlexColumn
            gap={24}
            _css={css`
              width: 696px;
              & > div {
                width: 100%;
              }
            `}
          >
            <AlertRow className="flex-start">
              <div>
                추가하신 지역의 상세 서비스 및 가격 관리는{' '}
                <span className="bold">{`[서비스 관리 > 서비스 설정]`}</span>{' '}
                메뉴에서 관리할 수 있습니다.
                <br />
                별도로 카테고리 설정을 하지 않으실 경우, 기본 서비스 가격으로
                책정됩니다.
              </div>
            </AlertRow>
            <BoxRowComponent _label={'지역 그룹 이름 *'}>
              <UnderlineInput
                placeholder="내용 입력"
                value={groupName}
                handleValue={(value) => {
                  set_groupName(value)
                }}
              />
            </BoxRowComponent>
            <BoxRowComponent _label={'지역 추가'}>
              <LiveSearchInput<ApiZoneResponse>
                _removeAfter
                _suffix={(props) => {
                  return (
                    <S.LiveSearchButtonBox>
                      <GrayScaleFill _mini onClick={props.clearList}>
                        취소
                      </GrayScaleFill>
                      <PrimaryButton
                        _mini
                        onClick={() => {
                          let cloned = clone(selectedInLiveSearch)
                          set_selectedCodes((prev) =>
                            uniqBy([...prev, ...cloned], (value) => value.bcode)
                          )
                          props.clearList()
                        }}
                      >
                        완료
                      </PrimaryButton>
                    </S.LiveSearchButtonBox>
                  )
                }}
                _placeholder="지역 검색"
                _type="user"
                _url={`/zone${_data?.id ? `?zoneGroupId=${_data.id}` : ''}`}
                _queryName={'keyword'}
                _css={css`
                  .list-inner {
                    padding-bottom: 0px;
                  }
                `}
                _emitValue={(value) => {
                  set_selectedInLiveSearch([])
                }}
                _listParser={(response: ApiZoneResponse[]) => {
                  let nextList = response
                  return {
                    _list: nextList,
                    _cursor: null,
                  }
                }}
                _setTitle={(value) => value.dong}
                _ListContent={(props) => {
                  return (
                    <CheckBoxForDropdown
                      _active={selectedInLiveSearch.some(
                        (item) => item.bcode === props.item.bcode
                      )}
                      _disabled={props.item.disable}
                      onClick={(e) => {
                        set_selectedInLiveSearch((prev) =>
                          prev.some((item) => item.bcode === props.item.bcode)
                            ? prev.filter(
                                (item) => item.bcode !== props.item.bcode
                              )
                            : [...prev, props.item]
                        )
                      }}
                    >
                      {highlight(
                        `${props.item.si}>${props.item.gu}>${props.item.dong}`,
                        props.term
                      )}
                    </CheckBoxForDropdown>
                  )
                }}
              />
            </BoxRowComponent>
            <S.AreaGroupContainer className="area-group">
              <div className="parents">
                {cities.length > 0 && (
                  <>
                    {cities.map((cityName, cityIndex) => {
                      return (
                        <S.Parents
                          className={cn({
                            active: selected[0] === cityName,
                          })}
                          key={'cities' + cityName}
                          onClick={() => {
                            set_selected((prev) => {
                              let next = updateAndDeleteBehind(
                                clone(prev),
                                0,
                                cityName
                              ) as string[]
                              return next
                            })
                          }}
                        >
                          <div>{cityName}</div>
                          <IconsAreaChevronRight />
                        </S.Parents>
                      )
                    })}
                  </>
                )}
              </div>
              <div className="parents">
                {districts.length > 0 && (
                  <>
                    {districts.map((item, districtIndex) => {
                      return (
                        <S.Parents
                          className={cn({
                            active: selected[1] === item,
                          })}
                          key={'districts' + item + districtIndex}
                          onClick={() => {
                            set_selected((prev) =>
                              updateAndDeleteBehind(prev, 1, item)
                            )
                          }}
                        >
                          <div>{item}</div>
                          <IconsAreaChevronRight />
                        </S.Parents>
                      )
                    })}
                  </>
                )}
              </div>
              <div className="streets-wrap">
                <div className="streets">
                  {streets.length > 0 && (
                    <>
                      {streets.map((item, itemIndex) => {
                        return (
                          <Fragment key={'streets' + item.bcode}>
                            {itemIndex === 0 && (
                              <S.BigChips
                                className={cn({ active: AllSelectedCurrent })}
                                onClick={() => {
                                  toggleAllSelected(item)
                                }}
                                disabled={AllDisabledCurrent}
                              >
                                <div>전체</div>
                                <IconsAreaCheck />
                              </S.BigChips>
                            )}
                            <S.BigChips
                              data-id={item.bcode}
                              className={cn({
                                active: selectedCodes.some(
                                  (data) => data.bcode === item.bcode
                                ),
                              })}
                              disabled={item.disable}
                              onClick={() => {
                                toggleSelected(item)
                              }}
                            >
                              <div>{item.dong}</div>
                              <IconsAreaCheck />
                            </S.BigChips>
                          </Fragment>
                        )
                      })}
                    </>
                  )}
                </div>
              </div>
            </S.AreaGroupContainer>
            <FlexRow
              gap={8}
              _css={css`
                flex-wrap: wrap;
                width: 696px;
              `}
            >
              {chips.map((item) => {
                return (
                  <Chips
                    key={'chips' + item.bcode}
                    _type="border"
                    onClick={() => {
                      removeByChip(item)
                    }}
                  >
                    {`${item.fullName}`}
                  </Chips>
                )
              })}
            </FlexRow>
          </FlexColumn>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default EditServiceArea
