import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const WorkerAssignmentTabStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  PartsTab: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 0;
    justify-content: space-between;
    & > div {
      padding: 8px 0;
      cursor: pointer;
      ${typography['Body/Small/Bold']}
      box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
      border-radius: 16px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        background-color: ${colors['Primary/Default']};
        color: #fff;
        box-shadow: none;
      }
    }
  `,
  WorkerName: styled.div`
    padding: 4px 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    ${typography['Body/Caption/Regular']}
    color: ${colors['Grayscale/Gray Dark']};
    box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Default']};
  `,
}

export default WorkerAssignmentTabStyle
