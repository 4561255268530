import { css } from '@emotion/react'
import { ImagesDetailsArrowRightCircle } from 'assets'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryOutlineButton,
} from 'components/Buttons'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import { useGallery } from 'components/Gallery/Gallery.hooks'
import { clone } from 'lodash-es'
import {
  ApiServiceItemType,
  ConstructionValuesType,
} from 'pages/Work/Work.types'
import ConstructionInfo from 'pages/Work/components/ConstructionInfo'
import { useEffect, useState } from 'react'
import { GalleryStatesType, setCurrentGallery } from 'store/gallerySlice'
import { setToast } from 'store/toastSlice'
import { ContentRadiusBox, FlexColumn, FlexRow } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import { getFileNameFromUrl } from 'utils/helpers'
import { AssignServiceInfoProps } from './AssignServiceInfo.types'

const updateGallery = (
  value: ConstructionValuesType[],
  target: GalleryStatesType,
  type: '시공전 사진' | '시공후 사진'
): GalleryStatesType => {
  return {
    ...target,
    data: {
      ...target.data,
      [type]: value
        .filter((item) => item.id !== null)
        .map((item) => {
          return {
            id: item.id!,
            title: item.title,
            list: {
              '360 사진': item['360도 사진'],
              사진: item.사진,
            },
          }
        }),
    },
  }
}
const parseGalleryTarget = (
  value: 'beforeConstruction' | 'afterConstruction'
) => {
  return value === 'beforeConstruction' ? '시공전 사진' : '시공후 사진'
}
const AssignServiceInfo = <
  T extends {
    afterConstruction: ConstructionValuesType[]
    beforeConstruction: ConstructionValuesType[]
  }
>({
  householdsId,
  taskId,
  _css,
  inputs,
  handleInput,
  handleModal,
  _type,
  ...props
}: AssignServiceInfoProps<T>) => {
  const { dispatch, openGallery, currentGallery } = useGallery()
  const [openServiceInfo, set_openServiceInfo] = useState<{
    open: boolean
    id?: number | string
    taskId?: string
    item?: ConstructionValuesType
  }>({ open: false })
  const [galleryTarget, set_galleryTarget] = useState<
    '시공전 사진' | '시공후 사진'
  >(parseGalleryTarget(_type))
  useEffect(() => {
    set_galleryTarget(parseGalleryTarget(_type))
  }, [_type])

  const handleAddConstruction = async (value: ConstructionValuesType) => {
    try {
      const postData = {
        title: value.title,
        taskId: taskId,
        householdsId: householdsId,
        image: value.사진.map((item) => item.name),
        isBefore: _type === 'beforeConstruction',
        image360:
          value['360도 사진']?.length > 0
            ? value['360도 사진'].map((item) => item.name)[0]
            : undefined,
        ...(typeof openServiceInfo.id === 'number' && {
          id: openServiceInfo.id,
        }),
      }
      const res = await callAxios('task')[
        typeof openServiceInfo.id === 'number' ? 'put' : 'post'
      ]<DoubleDResponse<ApiServiceItemType>>(`/construction`, postData)
      if (res.data.data) {
        const itemNext = res.data.data
        const nextItem = {
          id: itemNext.id,
          title: itemNext.title,
          사진: itemNext.imageUrl.map((url) => ({
            id: getFileNameFromUrl(url),
            name: getFileNameFromUrl(url),
            url: url,
          })),
          '360도 사진': itemNext.image360Url
            ? [
                {
                  id: getFileNameFromUrl(itemNext.image360Url),
                  name: getFileNameFromUrl(itemNext.image360Url),
                  url: itemNext.image360Url,
                },
              ]
            : [],
        }
        const nextData =
          typeof openServiceInfo.id === 'number'
            ? inputs[_type].map((item) =>
                item.id === openServiceInfo.id ? nextItem : item
              )
            : [...inputs[_type], nextItem]
        handleInput(_type, nextData)
        set_openServiceInfo({ open: false })
        const update = updateGallery(nextData, currentGallery, galleryTarget)
        const forUpdate = { ...currentGallery, ...update }
        dispatch(setCurrentGallery(forUpdate))
      }
    } catch (error) {
      handleError(error)
    }
  }
  const handleDelete = async (id: string | number) => {
    try {
      await callAxios('task').delete(`/construction/${id}`)
      const currentMeasurementList = currentGallery.data[galleryTarget]
      const nextGallery = {
        ...currentGallery,
        data: {
          ...currentGallery.data,
          [galleryTarget]: currentMeasurementList
            ? currentMeasurementList.filter((item) => item.id !== id)
            : null,
        },
      }
      dispatch(setCurrentGallery(nextGallery))
      handleInput(_type, (prev) => {
        let cloned = clone(prev[_type])
        cloned = cloned.filter((ft) => id !== ft.id)
        return cloned
      })
      handleModal(null)
      dispatch(
        setToast({
          message: '삭제 되었습니다.',
          type: 'error',
        })
      )
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      {openServiceInfo.open && taskId && (
        <ConstructionInfo
          _galleryId={{
            taskId: taskId,
          }}
          _type={galleryTarget}
          _id={openServiceInfo.id}
          _open={openServiceInfo.open}
          _onClose={() => set_openServiceInfo({ open: false })}
          _item={openServiceInfo.item}
          _emitValue={handleAddConstruction}
        />
      )}
      <FlexColumn
        gap={12}
        _css={css`
          & > div {
            width: 100%;
          }
        `}
      >
        <ContentRadiusBox>
          <BoxRowComponent
            _label={
              _type === 'beforeConstruction' ? '시공 전 정보' : '시공 후 정보'
            }
            _contents={
              <PrimaryOutlineButton
                _css={css`
                  padding: 5px 0;
                  width: 100%;
                `}
                onClick={() => {
                  set_openServiceInfo({ open: true, taskId: taskId })
                }}
              >
                입력
              </PrimaryOutlineButton>
            }
          />
        </ContentRadiusBox>
        {inputs[_type]?.map((item) => {
          const with360 =
            currentGallery.data[galleryTarget]?.find(
              (data) => data.id === item.id
            )?.list['360 사진'] ?? []
          const withSite =
            currentGallery.data[galleryTarget]?.find(
              (data) => data.id === item.id
            )?.list['사진'] ?? []
          return (
            <div key={item.id}>
              <ContentRadiusBox>
                <BoxRowComponent _label={'시공 장소'} _contents={item.title} />
                <BoxRowComponent
                  _label={'사진'}
                  _emptyType={'hide'}
                  _emptyCheck={withSite.length > 0}
                  _contents={
                    <FlexRow
                      gap={8}
                      justifyContent="flex-end"
                      onClick={() => {
                        if (!item.id) return
                        openGallery({
                          ...currentGallery,
                          target: [galleryTarget, item.id, '사진'],
                        })
                      }}
                      _css={`cursor:pointer;`}
                    >
                      <div>
                        {withSite.length
                          ? withSite.length + '개'
                          : '이미지 없음'}
                      </div>
                      <img src={ImagesDetailsArrowRightCircle} alt="" />
                    </FlexRow>
                  }
                />
                <BoxRowComponent
                  _label={'360도 사진'}
                  _emptyType={'hide'}
                  _emptyCheck={with360.length > 0}
                  _contents={
                    <FlexRow
                      gap={8}
                      justifyContent="flex-end"
                      onClick={() => {
                        if (!item.id) return
                        openGallery({
                          ...currentGallery,
                          target: [galleryTarget, item.id, '360 사진'],
                        })
                      }}
                      _css={`cursor:pointer;`}
                    >
                      <div>
                        {with360.length ? with360.length + '개' : '이미지 없음'}
                      </div>
                      <img src={ImagesDetailsArrowRightCircle} alt="" />
                    </FlexRow>
                  }
                />
                <BoxRowComponent
                  _label={
                    _type === 'beforeConstruction'
                      ? '시공 전 정보'
                      : '시공 후 정보'
                  }
                  _contents={
                    <FlexRow gap={16}>
                      <GrayScaleOutline
                        _css={css`
                          padding: 5px 0;
                          flex: 1;
                        `}
                        onClick={() => {
                          if (!item.id) return
                          set_openServiceInfo({
                            open: true,
                            id: item.id,
                            taskId: taskId,
                            item,
                          })
                        }}
                      >
                        수정
                      </GrayScaleOutline>
                      <GrayScaleFill
                        _css={css`
                          padding: 5px 0;
                          width: 97px;
                        `}
                        onClick={() => {
                          if (!item.id) return
                          handleModal({
                            title: '시공정보 삭제',
                            content: '시공 정보를 삭제하시겠습니까?',
                            onSubmit: () => {
                              if (!item.id) return
                              handleDelete(item.id)
                            },
                          })
                        }}
                      >
                        삭제
                      </GrayScaleFill>
                    </FlexRow>
                  }
                />
              </ContentRadiusBox>
            </div>
          )
        })}
      </FlexColumn>
    </>
  )
}
export default AssignServiceInfo
