import { css } from '@emotion/react'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryOutlineButton,
} from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import { useAppDispatch } from 'hooks/reduxHooks'
import { Fragment } from 'react'
import { setToast } from 'store/toastSlice'
import { ContentRadiusBox, FlexRow } from 'themes/Shared.styles'
import { thousand } from 'utils/helpers'
import { ExtraFeesInfoProps } from './ExtraFeesInfo.types'

const ExtraFeesInfo = ({
  _css,
  _readOnly,
  extraFees,
  handleExtraFees,
  handleModal,
  openExtraFeeModal,
  ...props
}: ExtraFeesInfoProps) => {
  const dispatch = useAppDispatch()
  return (
    <>
      {!_readOnly && (
        <BoxLayout _title={'추가금 내역'}>
          <ContentRadiusBox>
            <BoxRowComponent
              _label={'추가금'}
              _contents={
                <PrimaryOutlineButton
                  _css={css`
                    padding: 5px 0;
                    width: 100%;
                  `}
                  onClick={() => {
                    openExtraFeeModal({ open: true })
                  }}
                >
                  입력
                </PrimaryOutlineButton>
              }
            />
          </ContentRadiusBox>
        </BoxLayout>
      )}
      {extraFees.length > 0 && (
        <div>
          {extraFees.map((extraFee, extraFeeIndex) => {
            return (
              <Fragment key={'extraFee' + extraFeeIndex}>
                <ContentRadiusBox
                  _css={css`
                    margin-top: ${_readOnly ? '0px' : '12px'};
                  `}
                >
                  <BoxRowComponent
                    _label={'추가 발생 작업'}
                    _contents={extraFee.taskName}
                  />
                  <BoxRowComponent
                    _label={'추가금'}
                    _contents={thousand(extraFee.price ?? 0) + '원'}
                  />
                  {!_readOnly && (
                    <BoxRowComponent
                      _label={'추가금 정보'}
                      _contents={
                        <FlexRow gap={16}>
                          <GrayScaleOutline
                            _css={css`
                              padding: 5px 0;
                              flex: 1;
                            `}
                            onClick={() => {
                              openExtraFeeModal({
                                open: true,
                                data: {
                                  index: extraFeeIndex,
                                  data: extraFee,
                                },
                              })
                            }}
                          >
                            수정
                          </GrayScaleOutline>
                          <GrayScaleFill
                            _css={css`
                              padding: 5px 0;
                              width: 97px;
                            `}
                            onClick={() => {
                              handleModal({
                                title: '추가금 삭제',
                                content: '추가금을 삭제 하시겠습니까?',
                                onSubmit: () => {
                                  handleExtraFees(
                                    extraFees.filter(
                                      (_, itemIndex) =>
                                        itemIndex !== extraFeeIndex
                                    )
                                  )
                                  dispatch(
                                    setToast({
                                      message: '삭제 되었습니다.',
                                      type: 'error',
                                    })
                                  )
                                  handleModal(null)
                                },
                              })
                            }}
                          >
                            삭제
                          </GrayScaleFill>
                        </FlexRow>
                      }
                    />
                  )}
                </ContentRadiusBox>
                {extraFee.messageToUser && (
                  <ContentRadiusBox className="question">
                    <BoxRowComponent
                      _label={'회원 전달 내용'}
                      _contents={extraFee.messageToUser}
                    />
                  </ContentRadiusBox>
                )}
                {extraFee.note && (
                  <ContentRadiusBox className="question">
                    <BoxRowComponent
                      _label={'상세 내용'}
                      _contents={extraFee.note}
                    />
                  </ContentRadiusBox>
                )}
              </Fragment>
            )
          })}
        </div>
      )}
    </>
  )
}
export default ExtraFeesInfo
