import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import DraggablePopup from 'components/DraggablePopup'
import UnderlineInput from 'components/UnderlineInput'
import { useAppDispatch } from 'hooks/reduxHooks'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useMatchInput from 'hooks/useMatchInput'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { setToast } from 'store/toastSlice'
import {
  passwordInputRegex,
  passwordRegex,
} from 'templates/Authorization/Authorization.helpers'
import PasswordCheck from 'templates/Authorization/LoginComponent/PasswordCheck'
import { colors } from 'themes/styles'
import callAxios, { handleError } from 'utils/callAxios'

const ModalContentWrapper = styled.form`
  & {
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${colors['Grayscale/Gray Lighter']};

    padding: 0px 32px 0px;
  }
  & .isLabelInput {
    margin-top: 32px;
  }
`
interface PasswordValue {
  prevPassword: string
  newPassword: string
  checkPassword: string
}
const PASSWORD_INITIAL_VALUE: PasswordValue = {
  prevPassword: '',
  newPassword: '',
  checkPassword: '',
}
export interface PasswordChangeModalProps {
  _isModal: boolean
  _closeModal: () => void
}

const PasswordChangeModal = ({
  _isModal,
  _closeModal,
  ...props
}: PasswordChangeModalProps) => {
  const { inputs, handleInput, handleValues } = useMatchInput(
    PASSWORD_INITIAL_VALUE
  )
  const trueWhenEmpty = useEmptyCheck(inputs)
  const prevPasswordRef = useRef() as MutableRefObject<HTMLDivElement>
  const newPasswordRef = useRef() as MutableRefObject<HTMLDivElement>
  const checkPasswordRef = useRef() as MutableRefObject<HTMLDivElement>
  const [prevPasswordError, set_prevPasswordError] = useState<string>('')
  const [newPasswordError, set_newPasswordError] = useState<string>('')
  const [checkPasswordError, set_checkPasswordError] = useState<string>('')
  const [passwordType, set_passwordType] = useState<'password' | 'text'>(
    'password'
  )
  const dispatch = useAppDispatch()
  const togglePasswordType = () => {
    set_passwordType((type) => (type === 'password' ? 'text' : 'password'))
  }
  const canNotSubmit = useMemo(() => {
    return (
      trueWhenEmpty ||
      !!prevPasswordError ||
      !!newPasswordError ||
      !!checkPasswordError
    )
  }, [prevPasswordError, newPasswordError, checkPasswordError, trueWhenEmpty])

  const handleSubmit = async () => {
    if (canNotSubmit) return
    try {
      if (inputs.checkPassword !== inputs.newPassword) {
        set_prevPasswordError('비밀번호가 틀립니다.')
        return
      }
      const postData = {
        pwd: inputs.prevPassword,
        newPwd: inputs.newPassword,
      }
      await callAxios('user').patch(`/admin/pwd`, postData)
      dispatch(
        setToast({ message: '비밀번호가 변경되었습니다.', type: 'success' })
      )
      _closeModal()
    } catch (error) {
      handleError(error)
    }
  }
  const isValidPasswordForm = useCallback(
    (value: string) => {
      if (value.length < 8) {
        return '8자리 이상 입력해주세요.'
      }
      if (!passwordRegex.test(value)) {
        return '조합된 비밀번호를 입력해 주세요.'
      }
      if (value === inputs.prevPassword)
        return '기존 비밀번호와 다른 비밀번호를 입력하세요.'
      return ''
    },
    [inputs.prevPassword]
  )

  const resetAllValue = useCallback(() => {
    handleValues(PASSWORD_INITIAL_VALUE)
    set_checkPasswordError('')
    set_newPasswordError('')
    set_prevPasswordError('')
    set_passwordType('password')
  }, [handleValues])

  // modal toggle 시 값 reset
  useEffect(() => {
    resetAllValue()
  }, [resetAllValue])

  return (
    <DraggablePopup
      _css={css`
        min-width: 480px;
      `}
      _title={'비밀번호 변경'}
      _open={_isModal}
      _onClose={_closeModal}
      _bottom={
        <>
          <GrayScaleFill onClick={_closeModal}>취소</GrayScaleFill>
          <PrimaryButton disabled={canNotSubmit} onClick={handleSubmit}>
            확인
          </PrimaryButton>
        </>
      }
    >
      <ModalContentWrapper>
        <UnderlineInput
          label="기존 비밀번호를 입력하세요."
          value={inputs.prevPassword}
          ref={prevPasswordRef}
          type={passwordType}
          handleValue={(value) => {
            set_prevPasswordError('')
            handleInput('prevPassword', value)
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              newPasswordRef.current?.querySelector('input')?.focus()
            }
          }}
          onBlur={() => {
            if (inputs.prevPassword.length < 8) {
              set_prevPasswordError('8자리 이상 입력해주세요.')
            }
          }}
          error={prevPasswordError}
        />
        <UnderlineInput
          label="새로운 비밀번호를 입력하세요."
          value={inputs.newPassword}
          ref={newPasswordRef}
          type={passwordType}
          handleValue={(value) => {
            let errorMsg = ''
            if (value) {
              // 부적합한 기호 타이핑 방지
              if (!passwordInputRegex.test(value)) return
              errorMsg = isValidPasswordForm(value)
            }
            // checkPassword 존재 이후 수정했을 때
            if (inputs.checkPassword.length >= 8 && value.length >= 8) {
              if (inputs.checkPassword !== value)
                set_checkPasswordError('비밀번호가 일치하지 않습니다.')
            }
            set_newPasswordError(errorMsg)
            handleInput('newPassword', value)
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              checkPasswordRef.current?.querySelector('input')?.focus()
            }
          }}
          error={newPasswordError}
        />
        <UnderlineInput
          label="한번 더 입력해주세요."
          value={inputs.checkPassword}
          ref={checkPasswordRef}
          type={passwordType}
          handleValue={(value) => {
            let errorMsg = ''
            if (value.length >= 8) {
              const isDiff = inputs.newPassword === value
              if (!isDiff) errorMsg = '비밀번호가 일치하지 않습니다.'
            }
            set_checkPasswordError(errorMsg)
            handleInput('checkPassword', value)
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              handleSubmit()
            }
          }}
          error={checkPasswordError}
        />
        <PasswordCheck
          _active={passwordType === 'text'}
          _css={css`
            margin-top: 8px;
            justify-content: flex-end;
          `}
          onClick={() => {
            togglePasswordType()
          }}
        />
      </ModalContentWrapper>
    </DraggablePopup>
  )
}
export default PasswordChangeModal
