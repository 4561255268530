import GlobalErrorPopup from 'layout/GlobalErrorPopup'
import LoadingPage from 'layout/LoadingPage'
import Login from 'layout/Login'
import useAuthGuard from './AuthGuard.hooks'
import { AuthGuardProps } from './AuthGuard.types'

const AuthGuard = ({ children }: AuthGuardProps) => {
  const currentStatus = useAuthGuard()

  if (currentStatus === 'idle') return <LoadingPage />
  return (
    <>
      <GlobalErrorPopup />
      {currentStatus === 'authorized' && <>{children}</>}
      {currentStatus === 'unauthorized' && <Login />}
    </>
  )
}
export default AuthGuard
