import { css } from '@emotion/react'
import { PrimaryButton } from 'components/Buttons'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DraggablePopup from 'components/DraggablePopup'
import LiveSearchInput from 'components/LiveSearchInput'
import { LiveSearchDefaultDiv } from 'components/LiveSearchInput/LiveSearchInput.styles'
import UnderlineFormatInput, {
  getFormats,
} from 'components/UnderlineInput/UnderlineFormatInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useMatchInput from 'hooks/useMatchInput'
import { SampleUserResponse, sampleUserType } from 'themes/sample'
import highlight from 'utils/highlight'
import S from './AddRecommenderPopup.styles'
import {
  AddRecommenderPopupProps,
  RECOMMENDER_INITIAL_VALUE,
} from './AddRecommenderPopup.types'

const AddRecommenderPopup = ({
  _css,
  _onClose,
  ...props
}: AddRecommenderPopupProps) => {
  const { inputs, handleInput, handleValues } = useMatchInput(
    RECOMMENDER_INITIAL_VALUE
  )
  const trueWhenEmpty = useEmptyCheck(inputs)
  return (
    <>
      <DraggablePopup
        _title={'정기회원 소개 추가'}
        _open={true}
        _onClose={_onClose}
        _bottom={
          <>
            <PrimaryButton disabled={trueWhenEmpty} onClick={_onClose}>
              저장
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <BoxRowComponent _label={'회원명 *'}>
            <LiveSearchInput<sampleUserType>
              _type="dev"
              _url="/dummy/user?limit=12"
              _placeholder="검색"
              _defaultValue={inputs.name ?? ''}
              _emitValue={(value) => {
                if (value) {
                  handleValues((prev) => ({
                    ...prev,
                    name: value.name,
                    phone: value.phone.replace(/-/g, ''),
                  }))
                }
              }}
              _listParser={(response: SampleUserResponse) => {
                return {
                  _list: response.list,
                  _cursor: response._next ? `&cursor=${response._next}` : null,
                }
              }}
              _setTitle={(value) => value.name}
              _ListContent={(props) => {
                return (
                  <LiveSearchDefaultDiv
                    _css={css`
                      padding: 8px 12px;
                    `}
                    onClick={(e) => {
                      props.handleSubmit(props.item)
                    }}
                  >
                    {highlight(props.item.name, props.term)}{' '}
                    {highlight(props.item.phone, props.term)}
                  </LiveSearchDefaultDiv>
                )
              }}
            />
          </BoxRowComponent>
          <BoxRowComponent _label={'전화번호 *'}>
            <UnderlineFormatInput
              format={getFormats('phone', inputs.phone)}
              value={inputs.phone}
              handleValue={(value) => {
                handleInput('phone', value)
              }}
              readOnly
              placeholder="전화번호"
            />
          </BoxRowComponent>
          <BoxRowComponent _label={'정산 금액 *'}>
            <UnderlineNumberInput
              value={inputs.settlementAmount}
              handleValue={(value) => {
                handleInput('settlementAmount', value)
              }}
              suffix="원"
              placeholder="검색"
            />
          </BoxRowComponent>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default AddRecommenderPopup
