import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import configSlice from './configSlice'
import errorSlice from './errorSlice'
import gallerySlice from './gallerySlice'
import refreshSlice from './refreshSlice'
import signSlice from './signSlice'
import toastSlice from './toastSlice'
export const store = configureStore({
  reducer: {
    auth: authSlice,
    sign: signSlice,
    error: errorSlice,
    config: configSlice,
    toast: toastSlice,
    gallery: gallerySlice,
    refresh: refreshSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: true }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
