import { ImagesModalClose } from 'assets'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import { isJSXComponent } from 'components/Popup/Popup.types'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import DragOrPinchZoom from 'utils/DragAndZoom/DragOrPinchZoom'
import { cn } from 'utils/helpers'
import S from './DraggablePopup.styles'
import { DraggablePopupProps } from './DraggablePopup.types'

const DraggablePopup = ({
  _open,
  _onClose,
  _css,
  _bottom,
  _title,
  children,
  ...props
}: DraggablePopupProps) => {
  const boxRef = useRef() as MutableRefObject<HTMLDivElement>
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [ctr, set_ctr] = useState<DragOrPinchZoom>()
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setCenter()
    })
    const dragEvent: DragOrPinchZoom = new DragOrPinchZoom(boxRef.current, {
      restrictElement: headerRef.current,

      restrictPosition: (currentXY) => {
        return dragEvent.areaRestrictions(currentXY, {
          type: 'inner',
          threshold: 0 * dragEvent.ts.scale,
        })
      },
      beforeFire() {
        headerRef.current.style.cursor = 'grabbing'
      },
      afterFire() {
        headerRef.current.style.cursor = ''
      },
    })
    function setCenter() {
      if (!boxRef?.current) return
      const computedStyle = getComputedStyle(boxRef.current)
      boxRef.current.style.left = `calc(50% - (${computedStyle.width} / 2))`
      boxRef.current.style.top = `calc(50% - (${computedStyle.height} / 2))`
      dragEvent.setTransform()
    }
    function setModal() {
      set_ctr(dragEvent)
      setCenter()
      window.addEventListener('resize', setCenter)
      if (boxRef.current) resizeObserver.observe(boxRef.current)
    }
    function removeModal() {
      set_ctr(undefined)
      window.removeEventListener('resize', setCenter)
      if (boxRef.current) resizeObserver.unobserve(boxRef.current)
    }
    if (_open) {
      setModal()
    } else {
      removeModal()
    }
    return () => {
      set_ctr(undefined)
      window.removeEventListener('resize', setCenter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_open])

  return (
    <>
      {_open && (
        <S.Wrap ref={boxRef} _css={_css} {...props}>
          <div className="drag-modal-container">
            <div className="drag-modal-header">
              <div
                className="drag-modal-header-title"
                ref={headerRef}
                onTouchStart={(e) => {
                  ctr?.onDragStart(e)
                }}
                draggable={false}
                onMouseDown={ctr?.onDragStart}
              >
                {_title}
              </div>
              <img
                draggable={false}
                src={ImagesModalClose}
                className="drag-modal-close"
                alt="close"
                onClick={(e) => {
                  e.stopPropagation()
                  _onClose()
                }}
              />
            </div>
            <div
              className={cn({ 'no-bottom': !_bottom }, 'drag-modal-contents')}
            >
              <div>{children}</div>
            </div>
            {_bottom && (
              <div className="drag-modal-bottom">
                {isJSXComponent(_bottom) ? (
                  _bottom
                ) : (
                  <>
                    <GrayScaleFill onClick={_onClose}>
                      {_bottom._cancelTitle ?? '취소'}
                    </GrayScaleFill>
                    <PrimaryButton
                      disabled={_bottom._submitDisabled}
                      onClick={_bottom._onSubmit}
                    >
                      {_bottom._submitTitle ?? '확인'}
                    </PrimaryButton>
                  </>
                )}
              </div>
            )}
          </div>
        </S.Wrap>
      )}
    </>
  )
}
export default DraggablePopup
