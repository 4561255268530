import { css } from '@emotion/react'
import { ImagesServiceChevronRight } from 'assets'
import AssignMeasurement from 'components/AssignMeasurement'
import Badge from 'components/Badge'
import { SERVICE_BADGE_COLOR } from 'components/Badge/Badge'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import DetailsComponents, { ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import NotFoundItem from 'components/NotFoundItem/NotFoundItem'
import OrderIcon from 'components/OrderIcon'
import RowTab from 'components/RowTab'
import { TD, TH } from 'components/TableComponent/TableComponent'
import { toggleOrder } from 'components/TableComponent/TableComponent.helpers'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { workStatusToKorean } from 'hooks/useAllMatch'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import LoadingPage from 'layout/LoadingPage'
import { clone, orderBy } from 'lodash-es'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  Flex,
  FlexColumn,
  FlexRow,
} from 'themes/Shared.styles'
import {
  ELEVATOR_DROPDOWN_LIST,
  FREE_PARKING_DROPDOWN_LIST,
  RESIDENT_TYPE_DROPDOWN_LIST,
  ROOM_TYPE_DROPDOWN_LIST,
} from 'types/services.type'
import callAxios, { handleError } from 'utils/callAxios'
import {
  cn,
  getHouseSize,
  getKeyByValue,
  getKeyNameByValue,
  objectKeys,
} from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import {
  ApiBuildingDetails,
  ApiHouseServiceInfoItems,
  BUILDING_INFO_INITIAL_VALUE,
  BuildingInfoValuesType,
  HouseHold,
} from '../Building.types'
import EditHouseholdPopup from '../EditBuilding/EditHouseholdPopup'
import EditBuildingPopup from '../EditBuildingPopup'
import B from './BuildingDetails.styles'
import { BuildingDetailsProps } from './BuildingDetails.types'

const tabs = ['기본 정보', '실측 정보', '시공 정보'] as const
const BuildingDetails = ({ _css, _id, ...props }: BuildingDetailsProps) => {
  const { Layout: L } = S
  const [response, isError, refreshResponse] = useFetch<ApiBuildingDetails>(
    'user',
    `/building/${_id}`,
    {
      disabled: !_id,
    }
  )
  const [orderByRecent, set_orderByRecent] = useState<
    'ASC' | 'DESC' | 'INITIAL'
  >('INITIAL')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [currentTab, set_currentTab] =
    useState<typeof tabs[number]>('기본 정보')
  const buildingData = useMatchInput(BUILDING_INFO_INITIAL_VALUE)
  const { inputs, handleInput, fetchInit } = buildingData
  const [editBuildingPopup, set_editBuildingPopup] =
    useState<BuildingInfoValuesType | null>(null)
  const [addressDefault, set_addressDefault] = useState<{
    address: string
    addressOld: string
    addressDetails: string
    bcode: string
    elevator: 1 | 2
  }>({
    address: '',
    addressOld: '',
    addressDetails: '',
    bcode: '',
    elevator: 1,
  })
  useEffect(() => {
    if (response && objectKeys(response).length > 0) {
      const addressInitial = {
        address: response.fullLegalAddress,
        addressOld: response.landLotAddress,
        addressDetails: response.name,
        bcode: response.bcode,
        elevator: response.isExistElevator ? 1 : 2,
      } as const
      set_addressDefault(addressInitial)
      const houseHolds: HouseHold[] = response.households.map((house) => {
        return {
          id: house.id,
          name: house.name,
          type: house.type as ValueOf<typeof ROOM_TYPE_DROPDOWN_LIST>,
          living: house.isLive ? 1 : 2,
          recentServiceDate: null,
          roomSize: house.exclusiveArea,
          floorCount: house.floor,

          ...addressInitial,
        }
      })
      fetchInit({
        ...BUILDING_INFO_INITIAL_VALUE,
        residentType: response.type as ValueOf<
          typeof RESIDENT_TYPE_DROPDOWN_LIST
        >,
        buildingName: response.name,
        address: response.fullLegalAddress,
        addressOld: response.landLotAddress,
        bcode: response.bcode,
        userInfo: {
          id: response.user?.id ?? '',
          name: response.user?.name ?? '',
          phone: response.user?.phone ?? '',
        },
        businessNo: response.businessNo ?? '',
        businessPhone: '',
        groupName: response.zoneGroup?.name ?? '',

        partnerInfo: {
          id: response.partner?.id ?? '',
          name: response.partner?.name ?? '',
          phone: response.partner?.phone ?? '',
          referralFee: null, // 상세에서는 안보인다
          referralFeeLimit: null, // 상세에서는 안보인다. 수정도 불가능
        },

        floorCount: response.highestFloor,
        elevator: response.isExistElevator ? 1 : 2,
        freeParking: response.parking ? '가능' : '불가능',
        frontPwd: response.frontPwd,
        doorPwd: response.doorPwd,

        parkingMethod: response.parkingInfo,

        remarks: response.note,

        houseInfo: houseHolds,

        measurementInfo: [],

        serviceInfo: [],

        totalHouseCount: response.households.length,
        totalServiceCount: 0,
      })
      set_selectedHouse(houseHolds[0])
    }
  }, [fetchInit, response])
  const [deleteBuildingPopup, set_deleteBuildingPopup] =
    useState<boolean>(false)
  const [selectedHouse, set_selectedHouse] = useState<HouseHold>()
  const [editHouseHolds, set_editHouseHolds] = useState<Partial<HouseHold>>()

  const [constructionInfo] = useFetch<ApiHouseServiceInfoItems[]>(
    'task',
    `/task/list/households/${selectedHouse?.id}`,
    {
      disabled: !selectedHouse?.id || currentTab !== '시공 정보',
    }
  )

  useEffect(() => {
    if (constructionInfo) {
      handleInput('serviceInfo', constructionInfo)
    }
  }, [constructionInfo, handleInput])

  const [openModal, set_openModal] = useState<{
    title: string
    content: string
    btnTitle?: string
    btnSubTitle?: string
    onSubmit: () => void
  } | null>(null)
  const handleModal = (
    value: {
      title: string
      content: string
      onSubmit: () => void
    } | null
  ) => {
    set_openModal(value)
  }
  const handleDeleteBuilding = async () => {
    try {
      await callAxios('user').delete(`/building/${_id}`)
      set_deleteBuildingPopup(false)
      dispatch(setToast(['삭제 되었습니다.', 'error']))
      navigate(`/member/building`)
    } catch (error) {
      handleError(error)
    }
  }
  const handleEditMode = () => {
    if (!selectedHouse) return
    set_editHouseHolds(selectedHouse)
  }
  const houseHolds = useMemo(() => {
    let houseInfo = clone(inputs.houseInfo)
    if (houseInfo.length > 0) {
      return orderBy(
        houseInfo,
        (item) => item.recentServiceDate,
        orderByRecent.toLowerCase() as 'asc' | 'desc'
      )
    } else {
      return []
    }
  }, [inputs.houseInfo, orderByRecent])

  const handleAddHouseHold = async (value: HouseHold) => {
    try {
      const postData = {
        name: value.name,
        type: value.type,
        exclusiveArea: value.roomSize ?? 0,
        susudaArea: value.roomSize
          ? getHouseSize(value.roomSize, '평').susudaSize
          : 0,
        floor: value.floorCount ?? 1,
        isLive: value.living === 1,
      }
      await callAxios('user').post(`/households/${_id}`, postData)
      refreshResponse()
    } catch (error) {
      handleError(error)
    }
  }
  const handleRemoveHouseHold = async (value: HouseHold) => {
    if (inputs.houseInfo.length <= 1) {
      dispatch(
        setToast({
          message: '최소한 1개의 세대는 있어야합니다.',
          type: 'error',
        })
      )
      return
    }
    try {
      const removed = inputs.houseInfo.filter((item) => item.id !== value.id)
      if (!removed.length) {
        dispatch(
          setToast({
            message: '최소한 1개의 세대는 있어야합니다.',
            type: 'error',
          })
        )
        return
      }
      await callAxios('user').delete(`/households/${value.id}`)
      handleInput('houseInfo', removed)
      set_selectedHouse({ ...removed[0], ...addressDefault })
      dispatch(
        setToast({
          message: '삭제 되었습니다.',
          type: 'error',
        })
      )
    } catch (error) {
      handleError(error)
    }
  }
  const handleUpdateHouseHold = async (value: HouseHold) => {
    if (!value.id) return
    try {
      const putData = {
        name: value.name,
        type: value.type,
        exclusiveArea: value.roomSize ?? 0,
        floor: value.floorCount ?? 1,
        isLive: value.living === 1,
        susudaArea: value.roomSize
          ? getHouseSize(value.roomSize, '평').susudaSize
          : 0,
      }
      await callAxios('user').put(`/households/${value.id}`, putData)
      handleInput('houseInfo', (prev) => {
        let cloned = clone(prev.houseInfo)
        cloned = cloned.map((item) =>
          item.id === value.id ? { ...item, ...value } : item
        )
        const { name, type, roomSize, floorCount, living } = value
        const nextValue: Partial<HouseHold> = {
          name,
          type,
          roomSize,
          floorCount,
          living,
        }
        set_selectedHouse((prev) =>
          prev
            ? {
                ...prev,
                ...nextValue,
                ...addressDefault,
              }
            : prev
        )
        return cloned
      })
      dispatch(
        setToast({
          message: '수정되었습니다.',
          type: 'success',
        })
      )
    } catch (error) {
      handleError(error)
    }
  }
  const handleEditBuilding = async (value: BuildingInfoValuesType) => {
    if (!_id) return
    try {
      await callAxios('user').patch(`/building/${_id}`, {
        type: value.residentType,
        name: value.buildingName,
        businessNo: value.businessNo,
        highestFloor: value.floorCount,
        isExistElevator: value.elevator === 1,
        parking: value.freeParking,
        parkingInfo: value.parkingMethod,
        frontPwd: value.frontPwd,
        note: value.remarks,
      })

      fetchInit(value)
      dispatch(
        setToast({
          message: '수정 되었습니다.',
          type: 'success',
        })
      )

      set_editBuildingPopup(null)
    } catch (error) {
      handleError(error)
    }
  }
  const [orderStatus, set_orderStatus] = useState<{
    orderDate: 'ASC' | 'DESC' | 'INITIAL'
    serviceDate: 'ASC' | 'DESC' | 'INITIAL'
  }>({
    orderDate: 'INITIAL',
    serviceDate: 'INITIAL',
  })
  if (!response) return <LoadingPage />
  if (isError) return <Navigate to={`/404?from=${window.location.pathname}`} />
  return (
    <>
      {editBuildingPopup && (
        <EditBuildingPopup
          _data={editBuildingPopup}
          _onClose={() => {
            set_editBuildingPopup(null)
          }}
          _emitValue={handleEditBuilding}
        />
      )}
      {editHouseHolds && (
        <EditHouseholdPopup
          _open={true}
          _id={editHouseHolds.id}
          _onClose={() => {
            set_editHouseHolds(undefined)
          }}
          _emitValue={async (value, type) => {
            if (type === 'remove') {
              await handleRemoveHouseHold(value)
            } else if (type === 'update') {
              await handleUpdateHouseHold(value)
            } else {
              await handleAddHouseHold(value)
            }
            set_editHouseHolds(undefined)
          }}
        />
      )}
      {openModal && (
        <Modal open={openModal !== null} onClose={() => set_openModal(null)}>
          <MiniPopup
            _title={openModal.title}
            _buttonHandler={
              <>
                <GrayScaleFill
                  onClick={() => {
                    set_openModal(null)
                  }}
                >
                  {openModal.btnSubTitle ?? '아니요'}
                </GrayScaleFill>
                <PrimaryButton onClick={openModal.onSubmit}>
                  {openModal.btnTitle ?? '삭제'}
                </PrimaryButton>
              </>
            }
          >
            {openModal.content}
          </MiniPopup>
        </Modal>
      )}
      <Modal
        open={deleteBuildingPopup}
        onClose={() => set_deleteBuildingPopup(false)}
      >
        <MiniPopup
          _title="건물 삭제"
          _buttonHandler={
            <>
              <GrayScaleFill onClick={() => set_deleteBuildingPopup(false)}>
                아니오
              </GrayScaleFill>
              <PrimaryButton onClick={handleDeleteBuilding}>
                삭제하기
              </PrimaryButton>
            </>
          }
        >
          해당 건물을 삭제하시겠습니까?
        </MiniPopup>
      </Modal>
      <DetailsComponents
        _title={'건물 상세'}
        _topLeftControls={
          <L.ButtonRow>
            <PrimaryButton
              padding={'5px 12px'}
              _mini
              onClick={() => {
                set_editBuildingPopup(inputs)
              }}
            >
              건물 정보 수정
            </PrimaryButton>
            <GrayScaleFill
              padding={'5px 12px'}
              _mini
              onClick={() => {
                set_deleteBuildingPopup(true)
              }}
            >
              삭제
            </GrayScaleFill>
            <ToList _to="/member/building" />
          </L.ButtonRow>
        }
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout _title={'기본 정보'}>
              <ContentRadiusBox>
                <BoxRowComponent
                  _label={'건물 유형'}
                  _contents={getKeyNameByValue(
                    RESIDENT_TYPE_DROPDOWN_LIST,
                    inputs.residentType
                  )}
                />
                <BoxRowComponent
                  _label={'건물명'}
                  _contents={inputs.buildingName}
                />
                <BoxRowComponent
                  _label={'도로명 주소'}
                  _contents={inputs.address}
                />
                <BoxRowComponent
                  _label={'지번 주소'}
                  _contents={inputs.addressOld}
                />
                <BoxRowComponent
                  _label={'회원명'}
                  _contents={
                    inputs.userInfo ? (
                      <FlexRow
                        gap={8}
                        justifyContent="flex-end"
                        className="pointer"
                        onClick={() => {
                          if (!inputs.userInfo) return
                          navigate(`/member/user/${inputs.userInfo.id}`)
                        }}
                      >
                        <div>{inputs.userInfo.name}</div>
                        <img src={ImagesServiceChevronRight} alt="right" />
                      </FlexRow>
                    ) : null
                  }
                />
                <BoxRowComponent
                  _label={'전화번호'}
                  _contents={parseToFormattedStr(
                    inputs.userInfo?.phone ?? '',
                    'phone'
                  )}
                />
                <BoxRowComponent
                  _label={'법인/사업자 번호'}
                  _contents={inputs.businessNo}
                />
                <BoxRowComponent
                  _label={'지역그룹'}
                  _contents={inputs.groupName}
                />
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout
              _title={'세대 정보'}
              _titleSuffix={
                <FlexRow gap={12} width="auto">
                  <L.TotalCount>
                    총 세대수{' '}
                    <span className="count">{inputs.totalHouseCount}</span>
                  </L.TotalCount>
                  <PrimaryButton
                    padding={'5px 12px'}
                    _mini
                    onClick={() => {
                      set_editHouseHolds({})
                    }}
                  >
                    세대 추가
                  </PrimaryButton>
                </FlexRow>
              }
            >
              <B.HouseTableWrap>
                <B.HouseTable
                  className={cn(
                    {
                      'not-found': inputs.houseInfo.length === 0,
                    },
                    'house'
                  )}
                >
                  <thead>
                    <tr>
                      <TH>세대명</TH>
                      <TH>거주형태</TH>
                      <TH>거주여부</TH>
                      <TH>
                        <FlexRow
                          gap={4}
                          justifyContent="flex-end"
                          _css={css`
                            user-select: none;
                            cursor: pointer;
                          `}
                          onClick={() => {
                            set_orderByRecent((prev) => toggleOrder(prev))
                          }}
                        >
                          최근 시공일
                          <OrderIcon _order={orderByRecent} />
                        </FlexRow>
                      </TH>
                    </tr>
                  </thead>
                  <tbody>
                    {houseHolds.length > 0 ? (
                      <>
                        {houseHolds.map((item) => {
                          return (
                            <tr
                              key={'list' + item.id}
                              onClick={() => {
                                set_selectedHouse({
                                  ...item,
                                  ...addressDefault,
                                })
                              }}
                              className={cn({
                                selected: item.id === selectedHouse?.id,
                              })}
                            >
                              <TD>{item.name}</TD>
                              <TD>
                                {getKeyNameByValue(
                                  ROOM_TYPE_DROPDOWN_LIST,
                                  item.type
                                )}
                              </TD>
                              <TD>
                                {item.living === 1
                                  ? '공실'
                                  : item.living === 2
                                  ? '거주중'
                                  : '-'}
                              </TD>
                              <TD>
                                {item.recentServiceDate
                                  ? dayjs(item.recentServiceDate).format(
                                      'YYYY-MM-DD'
                                    )
                                  : '-'}
                              </TD>
                            </tr>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={4}>
                            <div>추가된 세대가 없습니다</div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </B.HouseTable>
              </B.HouseTableWrap>
            </BoxLayout>
            <BoxLayout _title={'파트너 정보'}>
              <ContentRadiusBox>
                <BoxRowComponent
                  _label={'파트너명'}
                  _contents={
                    inputs.partnerInfo?.name ? (
                      <FlexRow
                        gap={8}
                        justifyContent="flex-end"
                        className="pointer"
                        onClick={() => {
                          if (!inputs.partnerInfo) return
                          navigate(`/member/partner/${inputs.partnerInfo.id}`)
                        }}
                      >
                        <div>{inputs.partnerInfo.name}</div>
                        <img src={ImagesServiceChevronRight} alt="right" />
                      </FlexRow>
                    ) : null
                  }
                />
                <BoxRowComponent
                  _label={'전화번호'}
                  _contents={inputs.partnerInfo?.phone}
                />
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout _title={'기타 정보'}>
              <ContentRadiusBox>
                <BoxRowComponent
                  _label={'층 수'}
                  _contents={`${inputs.floorCount}층`}
                />
                <BoxRowComponent
                  _label={'엘리베이터'}
                  _contents={getKeyNameByValue(
                    ELEVATOR_DROPDOWN_LIST,
                    inputs.elevator
                  )}
                />
                <BoxRowComponent
                  _label={'무료주차'}
                  _contents={getKeyNameByValue(
                    FREE_PARKING_DROPDOWN_LIST,
                    inputs.freeParking
                  )}
                />
                <BoxRowComponent
                  _label={'현관 비밀번호'}
                  _contents={inputs.frontPwd}
                />
              </ContentRadiusBox>
              <ContentRadiusBox
                className="gray"
                _css={css`
                  margin-top: 12px;
                `}
              >
                <BoxRowComponent
                  _label={'주차방법'}
                  _contents={inputs.parkingMethod}
                />
              </ContentRadiusBox>
              <ContentRadiusBox
                className="gray"
                _css={css`
                  margin-top: 12px;
                `}
              >
                <BoxRowComponent _label={'비고'} _contents={inputs.remarks} />
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _topRightHeader={
          selectedHouse ? (
            <>
              <L.LeftHeader>
                <h1>{selectedHouse.name}</h1>
              </L.LeftHeader>
              <RowTab
                _tabs={tabs}
                _tab={currentTab}
                _emitValue={(value) => {
                  set_currentTab(value)
                }}
                _css={css`
                  padding: 24px 32px 0;
                `}
              />
            </>
          ) : (
            <></>
          )
        }
        _rightContents={
          selectedHouse ? (
            <ContentBoxWrap>
              {currentTab === '기본 정보' && (
                <>
                  <ContentRadiusBox
                    _css={css`
                      & > label {
                        min-width: 110px;
                      }
                    `}
                  >
                    <BoxRowComponent
                      _label={'도로명 주소'}
                      _contents={selectedHouse.address}
                    />
                    <BoxRowComponent
                      _label={'지번 주소'}
                      _contents={selectedHouse.addressOld}
                    />
                    <BoxRowComponent
                      _label={'상세 주소'}
                      _contents={selectedHouse.addressDetails}
                    />
                    <BoxRowComponent
                      _label={'거주 형태'}
                      _contents={selectedHouse.type}
                    />
                    <BoxRowComponent
                      _label={'전용 면적 / 수수다 면적'}
                      _css={css`
                        & > label {
                          width: 110px;
                          min-width: 110px;
                        }
                      `}
                      _contents={getHouseSize(
                        selectedHouse?.roomSize,
                        '평'
                      ).toString()}
                    />
                    <BoxRowComponent
                      _label={'층수'}
                      _contents={`${selectedHouse.floorCount}층`}
                    />
                    <BoxRowComponent
                      _label={'엘리베이터'}
                      _contents={getKeyNameByValue(
                        ELEVATOR_DROPDOWN_LIST,
                        selectedHouse.elevator
                      )}
                    />
                    <BoxRowComponent
                      _label={'거주여부'}
                      _contents={
                        selectedHouse.living === 1
                          ? '공실'
                          : selectedHouse.living === 2
                          ? '거주중'
                          : '-'
                      }
                    />
                  </ContentRadiusBox>
                </>
              )}
              {currentTab === '실측 정보' && selectedHouse.id && (
                <>
                  <AssignMeasurement
                    householdsId={selectedHouse.id}
                    inputs={inputs}
                    handleInput={handleInput}
                    handleModal={handleModal}
                  />
                </>
              )}
              {currentTab === '시공 정보' && (
                <>
                  <FlexColumn
                    gap={10}
                    _css={css`
                      height: 100%;
                      & > div {
                        width: 100%;
                        &.service-count {
                          padding: 0 16px;
                        }
                      }
                    `}
                  >
                    {inputs.serviceInfo.length > 0 ? (
                      <>
                        <Flex
                          justifyContent={'flex-end'}
                          className="service-count"
                        >
                          <L.TotalCount>
                            총 이용 횟수{' '}
                            <span className="count">
                              {inputs.serviceInfo.length}
                            </span>
                          </L.TotalCount>
                        </Flex>
                        <B.MiniTableWrap className="house">
                          <B.MiniTable>
                            <thead>
                              <tr>
                                <TH>서비스명</TH>
                                <TH>숙련공명</TH>
                                <TH>
                                  <FlexRow gap={4} justifyContent="flex-start">
                                    주문일
                                    <OrderIcon
                                      _order={orderStatus.orderDate}
                                      onClick={() => {
                                        set_orderStatus((prev) => ({
                                          ...prev,
                                          serviceDate: 'INITIAL',
                                          orderDate: toggleOrder(
                                            orderStatus.orderDate
                                          ),
                                        }))
                                      }}
                                    />
                                  </FlexRow>
                                </TH>
                                <TH>
                                  <FlexRow gap={4} justifyContent="flex-start">
                                    시공일
                                    <OrderIcon
                                      _order={orderStatus.serviceDate}
                                      onClick={() => {
                                        set_orderStatus((prev) => ({
                                          ...prev,
                                          orderDate: 'INITIAL',
                                          serviceDate: toggleOrder(
                                            orderStatus.serviceDate
                                          ),
                                        }))
                                      }}
                                    />
                                  </FlexRow>
                                </TH>
                              </tr>
                            </thead>
                            <tbody>
                              {orderBy(
                                inputs.serviceInfo,
                                (value) =>
                                  orderStatus.orderDate !== 'INITIAL'
                                    ? value.orderAt
                                    : value.finishedAt,
                                (orderStatus.orderDate !== 'INITIAL'
                                  ? orderStatus.orderDate
                                  : orderStatus.serviceDate
                                ).toLowerCase() as 'asc' | 'desc'
                              ).map((item, itemIndex) => {
                                return (
                                  <Fragment
                                    key={
                                      'serviceInfo' + item.taskId + itemIndex
                                    }
                                  >
                                    <tr
                                      onClick={() => {
                                        navigate(
                                          item.unpaid
                                            ? `/unpaid/${item.taskId}`
                                            : `/work/${getKeyByValue(
                                                workStatusToKorean,
                                                item.status
                                              )}/${item.taskId}`
                                        )
                                      }}
                                    >
                                      <TD>{item.serviceName}</TD>
                                      <TD>{item.technicianName}</TD>
                                      <TD>
                                        {item.orderAt
                                          ? dayjs(item.orderAt).format(
                                              'YYYY-MM-DD'
                                            )
                                          : '-'}
                                      </TD>
                                      <TD>
                                        {item.status === '시공취소' ? (
                                          <>
                                            <Badge
                                              {...SERVICE_BADGE_COLOR[
                                                '시공취소'
                                              ]}
                                            >
                                              시공취소
                                            </Badge>
                                          </>
                                        ) : item.finishedAt ? (
                                          dayjs(item.finishedAt).format(
                                            'YYYY-MM-DD'
                                          )
                                        ) : (
                                          '-'
                                        )}
                                      </TD>
                                    </tr>
                                  </Fragment>
                                )
                              })}
                            </tbody>
                          </B.MiniTable>
                        </B.MiniTableWrap>
                      </>
                    ) : (
                      <NotFoundItem
                        _css={css`
                          height: 100%;
                          & > div {
                            height: 100%;
                          }
                        `}
                      >
                        시공 내역이 없습니다.
                      </NotFoundItem>
                    )}
                  </FlexColumn>
                </>
              )}
            </ContentBoxWrap>
          ) : (
            <></>
          )
        }
        _bottomRightControls={
          <L.RightBottom>
            <GrayScaleOutline
              _css={css`
                width: 100%;
                padding: 12px 0;
              `}
              onClick={handleEditMode}
            >
              세대 기본 정보 수정
            </GrayScaleOutline>
          </L.RightBottom>
        }
      />
    </>
  )
}
export default BuildingDetails
