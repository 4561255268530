import { css } from '@emotion/react'
import { IconsRefreshFilter, ImagesInputSearchIcon } from 'assets'
import { GrayScaleOutline } from 'components/Buttons'
import Dropdown from 'components/Dropdown'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineFormatInput, {
  getFormats,
} from 'components/UnderlineInput/UnderlineFormatInput'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getKeyNameByValue, onInputNumber } from 'utils/helpers'
import S from './FilterPart.styles'
import { FilterPartProps } from './FilterPart.types'

const FilterPart = <T extends object>({
  _css,
  _placeholder,
  _emitValue,
  _refresh,
  _value,
  _queryNameList,
  _queryNameFormat,
  _FilterContents: FilterContents,
  ...props
}: FilterPartProps<T>) => {
  const [term, set_term] = useState<string>('')
  const [queryName, set_queryName] = useState<string>('')
  const [dropdownObject, set_dropdownObject] = useState<T>()
  const [searchParams, set_searchParams] = useSearchParams()

  const isFormattedInput = (value: string) => {
    if (_queryNameFormat) {
      return _queryNameFormat[value]
    }
    return undefined
  }
  useEffect(() => {
    set_term(_value?.terms ? _value.terms : '')
    set_queryName(
      _value?.queryName
        ? _value.queryName
        : _queryNameList
        ? String(Object.values(_queryNameList)[0])
        : ''
    )
    set_dropdownObject(_value)
  }, [_value, _queryNameList])
  useEffect(() => {
    if (_queryNameList) {
      set_queryName(String(Object.values(_queryNameList)[0]))
    }
  }, [_queryNameList])

  const handleSearch = (value?: T) => {
    if (!dropdownObject) return
    if (value) {
      set_dropdownObject(value)
    }
    let nextQueryName = queryName
      ? queryName
      : _queryNameList
      ? String(Object.values(_queryNameList)[0])
      : ''

    if (_emitValue)
      _emitValue({
        ...(value ?? dropdownObject),
        terms: term,
        queryName: nextQueryName,
        sortType: searchParams.get('sortType'),
        sortValue: searchParams.get('sortValue'),
      })
  }
  const handleFilterDropDowns = (value: T) => {
    set_dropdownObject(value)
    handleSearch(value)
  }
  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <S.Container>
          {FilterContents && (
            <FilterContents
              _value={dropdownObject}
              _emitValue={handleFilterDropDowns}
            />
          )}
          <S.QueryWrap>
            {_queryNameList && (
              <Dropdown.Underline
                _value={
                  queryName ? queryName : Object.values(_queryNameList)[0]
                }
                _list={_queryNameList}
                _emitValue={(value) => {
                  set_queryName(String(value))
                  set_term('')
                }}
              />
            )}
            <S.SubmitWrap>
              {_queryNameFormat && !!isFormattedInput(queryName) ? (
                <UnderlineFormatInput
                  format={getFormats(_queryNameFormat[queryName], term)}
                  value={term}
                  _css={css`
                    width: 280px;
                  `}
                  handleValue={(value) => {
                    const numberVal = onInputNumber(value, term)
                    set_term(numberVal)
                  }}
                  placeholder={`${
                    _queryNameList
                      ? `${getKeyNameByValue(_queryNameList, queryName)} 검색`
                      : _placeholder ?? '검색어 입력'
                  }`}
                  after={<img src={ImagesInputSearchIcon} alt="search" />}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      e.preventDefault()
                      handleSearch()
                    }
                  }}
                />
              ) : (
                <UnderlineInput
                  value={term}
                  _css={css`
                    width: 280px;
                  `}
                  handleValue={(value) => set_term(value)}
                  placeholder={`${
                    _queryNameList
                      ? `${getKeyNameByValue(_queryNameList, queryName)} 검색`
                      : _placeholder ?? '검색어 입력'
                  }`}
                  after={<img src={ImagesInputSearchIcon} alt="search" />}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      e.preventDefault()
                      handleSearch()
                    }
                  }}
                />
              )}

              <GrayScaleOutline
                _css={css`
                  width: 80px;
                  height: 40px;
                `}
                onClick={() => {
                  handleSearch()
                }}
              >
                검색
              </GrayScaleOutline>
              <GrayScaleOutline
                _icon={true}
                _css={css`
                  margin-left: 8px;
                `}
                disabled={searchParams.toString() === ''}
                onClick={() => {
                  if (_refresh) _refresh()
                  set_searchParams('')
                }}
              >
                <IconsRefreshFilter width={24} height={24} />
              </GrayScaleOutline>
            </S.SubmitWrap>
          </S.QueryWrap>
        </S.Container>
      </S.Wrap>
    </>
  )
}
export default FilterPart
