import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import { CustomerInfoValuesType } from 'pages/Work/Work.types'
import { ContentRadiusBox } from 'themes/Shared.styles'
import { parseToFormattedStr } from 'utils/parsers'

interface CustomerInfoProps<T extends CustomerInfoValuesType> {
  inputs: Pick<T, keyof CustomerInfoValuesType>
}
const CustomerInfo = <T extends CustomerInfoValuesType>({
  inputs,
  ...props
}: CustomerInfoProps<T>) => {
  return (
    <>
      <ContentRadiusBox>
        <BoxRowComponent _label={`주문자 명`} _contents={inputs.name} />
        <BoxRowComponent
          _label={`전화 번호`}
          _contents={parseToFormattedStr(inputs.phone, 'phone')}
        />
        <BoxRowComponent
          _label={`회원 구분`}
          _contents={
            inputs.customerType === 'CUSTOMER' ? '일반 회원' : '정기 회원'
          }
        />
        <BoxRowComponent
          _label={`주문 경로`}
          _contents={inputs.funnels.join(' > ')}
        />
      </ContentRadiusBox>
    </>
  )
}
export default CustomerInfo
