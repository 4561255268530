import dayjs from 'dayjs'

import { OrderItemType } from 'pages/Work/Work.types'

export const oneProduct: OrderItemType = {
  id: 1,
  orderAt: dayjs().format('YYYY-MM-DD'),
  paidAt: dayjs().format('YYYY-MM-DD'),

  name: '청소 > 이사/입주청소',
  amount: 200000,

  discount: {
    type: '쿠폰적용',
    amount: 20000,
  },
}
export const testProduct: OrderItemType[] = [
  {
    id: 2,
    orderAt: dayjs().format('YYYY-MM-DD'),

    name: '청소 > 이사/입주청소',
    amount: 200000,

    discount: {
      type: '쿠폰적용',
      amount: 20000,
    },
  },
  {
    id: 3,
    orderAt: dayjs().format('YYYY-MM-DD'),
    paidAt: dayjs().format('YYYY-MM-DD'),

    name: '청소 > 이사/입주청소',
    amount: 200000,

    discount: {
      type: '쿠폰적용',
      amount: 20000,
    },

    cancelRequest: true,
  },
  {
    id: 4,
    orderAt: dayjs().format('YYYY-MM-DD'),
    serviceDoneAt: dayjs().format('YYYY-MM-DD'),

    name: '청소 > 이사/입주청소',
    amount: 200000,

    discount: {
      type: '쿠폰적용',
      amount: 20000,
    },
  },
  {
    id: 5,
    orderAt: dayjs().format('YYYY-MM-DD'),
    cancelledAt: dayjs().format('YYYY-MM-DD'),

    name: '청소 > 이사/입주청소',
    amount: 200000,

    discount: {
      type: '쿠폰적용',
      amount: 20000,
    },
  },
]

export type sampleUserType = { id: number; name: string; phone: string }

export interface SampleUserResponse {
  list: {
    id: number
    name: string
    phone: string
  }[]
  count: number
  _next: number
}
