export {}

export type DropdownValueOf<T> = T[keyof T]
export type UniqueKeyValue = { [key in string]: number | string | null }
export const isCorrectType = <T extends object>(
  target: T,
  value: any
): value is T => {
  return (Object.keys(target) as Array<KeyOf<T>>).some(
    (keyName) => keyName === value
  )
}

type TYear = `${number}${number}${number}${number}`
type TMonth = `${number}${number}`
type TDay = `${number}${number}`
type THours = `${number}${number}`
type TMinutes = `${number}${number}`
type TSeconds = `${number}${number}`
type TMilliseconds = `${number}${number}${number}`
type TDateISODate = `${TYear}-${TMonth}-${TDay}`
type TDateISOTime = `${THours}:${TMinutes}:${TSeconds}.${TMilliseconds}`
export type TDateISO = `${TDateISODate}T${TDateISOTime}Z`
export type UserType = 'CUSTOMER' | 'REGULAR'
export type GenderType = 'F' | 'M'
export type UserStatusType = 'N' | 'D' | 'W'
export interface PaginationResponseProps {
  cursor: TDateISO | number | null
  take: number
  totalRow: number
}
export interface PaginationRequestProps {
  cursor: TDateISO | number | null
  take: number
  order: 'asc' | 'desc'
}

export const PWD_NOT_CHANGED = '변경전'
