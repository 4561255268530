import { ImagesDetailsCopy } from 'assets'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import { useDispatch } from 'react-redux'
import { setError } from 'store/errorSlice'
import { copyToClipboard } from 'utils/helpers'
import { CopyProps } from './Copy.types'

const Copy = ({ _text, _css, ...props }: CopyProps) => {
  const dispatch = useDispatch()
  return (
    <S.CopyText _css={_css} {...props}>
      {_text}
      <img
        src={ImagesDetailsCopy}
        alt="복사하기"
        onClick={() => {
          copyToClipboard(_text)
          dispatch(
            setError({ title: '알림', errorMessage: '복사 되었습니다.' })
          )
        }}
      />
    </S.CopyText>
  )
}
export default Copy
