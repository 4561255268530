import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import { useAppDispatch } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import { MatchInputProps } from 'hooks/useMatchInput'
import { orderBy, sortBy } from 'lodash-es'
import { isArray } from 'mathjs'
import { ApiServiceCategory } from 'pages/Services/Setting/SelectCategory/SelectCategory.types'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { initialGalleryData, setCurrentGallery } from 'store/gallerySlice'
import { setToast } from 'store/toastSlice'
import callAxios, { extractData, handleError } from 'utils/callAxios'
import {
  getFileNameFromUrl,
  objectKeys,
  thousand,
  updateAndDeleteBehind,
} from 'utils/helpers'
import { parseObjectToQueryString } from 'utils/parsers'
import {
  ApiPriceOptions,
  CategoryDropdownType,
  EstimatesDetailsValuesDefaultType,
  EstimatesDetailsValuesForUserType,
  MaterialPriceType,
  OptionNames,
  ServiceItemInEstimatesType,
  serviceRadio,
} from './AddEstimates/AddEstimates.types'
import {
  ApiEstimatesDetails,
  parserForEstimatesDetails,
} from './EstimatesDetails/EstimatesDetails.types'

type ForTotalPrice = {
  _optionPriceValues: {
    [x: string]: number | null
  }
  _priceTableOptionValues: {
    h1?: string | undefined
    h2?: string | undefined
    h3?: string | undefined
    h4?: string | undefined
  }
  _selectedMaterial: MaterialPriceType | null
  _serviceProductId: number | null
  _serviceQuantity: number | null
}
export const useServiceControlOnEstimates = <
  T extends EstimatesDetailsValuesDefaultType
>(
  initialValue: MatchInputProps<T>,
  _id?: number
) => {
  const dispatch = useAppDispatch()
  const { inputs, handleInput } = initialValue

  // 시공 정보 관련 항목
  const [currentServiceType, set_currentServiceType] =
    useState<typeof serviceRadio[number]>('청소/도배/타일/바닥')
  const [selectedServiceCategory, set_selectedServiceCategory] = useState<
    number[]
  >([])
  const handleSelectedServiceCategory = useCallback(
    (value: number[] | ((values: number[]) => number[])) => {
      if (isArray(value)) {
        set_selectedServiceCategory(value)
      } else {
        set_selectedServiceCategory((prev) => value(prev))
      }
    },
    []
  )

  const [servicePriceOptions, set_servicePriceOptions] =
    useState<ApiPriceOptions | null>(null)
  const handleServicePriceOption = useCallback(
    (value: ApiPriceOptions | null) => {
      set_servicePriceOptions(value)
    },
    []
  )
  const [serviceProductId, set_serviceProductId] = useState<number | null>(null)
  const [priceTableOptionValues, set_priceTableOptionValues] = useState<{
    h1?: string
    h2?: string
    h3?: string
    h4?: string
  }>({})
  const [optionPriceValues, set_optionPriceValues] = useState<{
    [key in string]: number | null
  }>({})
  const [materialItemList, set_materialItemList] = useState<
    MaterialPriceType[]
  >([])
  const [quantityTitle, set_quantityTitle] = useState<{ name: string } | null>(
    null
  )
  const [serviceQuantity, set_serviceQuantity] = useState<number | null>(null)

  const [selectedMaterial, set_selectedMaterial] =
    useState<MaterialPriceType | null>(null)
  const [lastSelectedServiceCategoryId, set_lastSelectedServiceCategoryId] =
    useState<number | null>(null)

  const getServicePriceOptions = useCallback(
    async (lastId: number | null, zoneGroupId: number) => {
      if (!lastId) return null
      if (!zoneGroupId) return null
      try {
        const res = await callAxios('order').get(
          `/service-product/${lastId}/header?zoneGroupId=${zoneGroupId}`
        )
        return extractData<ApiPriceOptions>(res)
      } catch (error) {
        handleError(error)
        return null
      }
    },
    []
  )

  const handleLastSelectedId = useCallback(
    async (value: number | null) => {
      set_lastSelectedServiceCategoryId(value)
      if (!value) set_servicePriceOptions(null)
      if (!inputs.zoneGroupId) return
      const nextServicePriceOptions = await getServicePriceOptions(
        value,
        inputs.zoneGroupId
      )
      if (nextServicePriceOptions)
        set_servicePriceOptions(nextServicePriceOptions)
    },
    [inputs.zoneGroupId, getServicePriceOptions]
  )
  const [categoryDropdown, set_categoryDropdown] = useState<
    CategoryDropdownType[]
  >([])
  const handleSetCategoryDropdown = useCallback(
    (cb: (prev: CategoryDropdownType[]) => CategoryDropdownType[]) => {
      set_categoryDropdown((prev) => cb(prev))
    },
    []
  )
  const handleServiceRequest = useCallback(
    (value: string) => {
      handleInput('serviceItem', (prev) => ({
        ...prev.serviceItem,
        serviceRequests: value,
      }))
    },
    [handleInput]
  )
  const handleCurrentServiceType = useCallback(
    (value: typeof serviceRadio[number]) => {
      set_currentServiceType(value)
    },
    []
  )
  const handleSelectedMaterial = useCallback(
    (value: MaterialPriceType | null) => {
      set_selectedMaterial(value)
    },
    []
  )
  // 시공 탭 변경시 시공선택항목 초기화
  const restoreServiceOptions = useCallback((all?: boolean) => {
    set_optionPriceValues({})
    set_priceTableOptionValues({})
    set_serviceProductId(null)
    set_materialItemList([])
    set_selectedMaterial(null)
    set_quantityTitle(null)
    set_lastSelectedServiceCategoryId(null)
    if (all) {
      set_selectedServiceCategory([])
      set_servicePriceOptions(null)
    }
  }, [])

  // 시공 정보의 첫번째 분류 항목 조회
  const getMainCategoryList = useCallback(
    async (
      zoneGroupId: number,
      serviceType: 'main' | 'sub',
      userType: 'CUSTOMER' | 'REGULAR'
    ) => {
      if (!zoneGroupId) return null
      if (!serviceType) return null
      if (!userType) return null
      const res = await callAxios('order').get(
        `/category/list?zoneGroupId=${zoneGroupId}&type=${serviceType}&userType=${userType}`
      )
      const mainList = extractData<ApiServiceCategory[]>(res)
      if (serviceType === 'sub' && mainList.length < 1)
        dispatch(setToast(['등록된 기타 서비스가 없습니다.', 'error']))
      return mainList
    },
    [dispatch]
  )

  const setMainCategoryList = useCallback(
    (nextData: ApiServiceCategory[] | null, init?: boolean) => {
      if (!init) restoreServiceOptions(true)
      let currentList: CategoryDropdownType[] = []
      if (nextData && nextData.length) {
        currentList = [
          ...currentList,
          {
            list: nextData.reduce((prev, curr) => {
              prev[curr.name] = curr.id
              return prev
            }, {} as DropdownListType),
            isFirst: true,
          },
        ]
      }
      set_categoryDropdown(currentList)
    },
    [restoreServiceOptions]
  )
  // 지역그룹, 유저타입이 정해지면 첫번째 목록 조회
  // useEffect(() => {
  //   async function callGetCategory() {
  //     let currentList: CategoryDropdownType[] = []
  //     const nextData = await getMainCategoryList(true)
  //     if (nextData && nextData.length) {
  //       currentList = [
  //         ...currentList,
  //         {
  //           list: nextData.reduce((prev, curr) => {
  //             prev[curr.name] = curr.id
  //             return prev
  //           }, {} as DropdownListType),
  //           isFirst: true,
  //         },
  //       ]
  //     }
  //     set_categoryDropdown(currentList)
  //   }
  //   if (inputs.zoneGroupId && inputs.customerType && currentServiceType) {
  //     callGetCategory()
  //   }
  // }, [
  //   inputs.zoneGroupId,
  //   inputs.customerType,
  //   currentServiceType,
  //   getMainCategoryList,
  // ])
  // 견적서 상세에서 가격 fetch
  const getTotalPrice = useCallback(async (standards: ForTotalPrice) => {
    const nullReturn = {
      taskPriceAndOptionPrice: null,
      priceTagId: null,
      materialPriceTotal: null,
    }
    const {
      _optionPriceValues,
      _priceTableOptionValues,
      _selectedMaterial,
      _serviceProductId,
      _serviceQuantity,
    } = standards
    if (!_serviceProductId) return nullReturn
    const res = await callAxios('order').get(
      `/service-product/${_serviceProductId}/tags?${parseObjectToQueryString(
        _priceTableOptionValues,
        true
      )}${_serviceQuantity ? `&quantity=${_serviceQuantity}` : ''}`
    )
    let {
      materialPrice,
      taskPrice,
      id: priceTagId,
    } = extractData<{
      materialPrice: number
      taskPrice: number
      serviceProductId: number
      id: number
    }>(res)

    const optionPriceTotal = objectKeys(_optionPriceValues).reduce(
      (prev: number, curr) => {
        const nextPrice = _optionPriceValues[curr]
        if (nextPrice) prev += nextPrice
        return prev
      },
      0
    )
    const materialPriceTotal =
      (_selectedMaterial && _selectedMaterial.count
        ? _selectedMaterial.price * _selectedMaterial.count
        : 0) + (materialPrice ?? 0)
    const taskPriceAndOptionPrice = taskPrice + optionPriceTotal
    return {
      priceTagId,
      taskPriceAndOptionPrice,
      materialPriceTotal,
    }
  }, [])
  // 현재 선택값으로 전체 금액 조회하여 계산, 값 업데이트
  // 재료비 분리 계산 (기타일 경우 그리고 아닐 경우), 양중비는 답장할때는 api 서버에서 받고 관리자가 작성할때는 맘대로, 현재 공임비는 재료비를 분리(옵션 가격은 포함), 토탈 금액 필요 x
  const getCurrentServicePrice = useCallback(async () => {
    if (!serviceProductId) return
    if (quantityTitle && !serviceQuantity) return
    try {
      const { taskPriceAndOptionPrice, priceTagId, materialPriceTotal } =
        await getTotalPrice({
          _optionPriceValues: optionPriceValues,
          _priceTableOptionValues: priceTableOptionValues,
          _selectedMaterial: selectedMaterial,
          _serviceProductId: serviceProductId,
          _serviceQuantity: serviceQuantity,
        })
      handleInput('serviceMaterialPrice', materialPriceTotal)
      handleInput('serviceFee', taskPriceAndOptionPrice)
      handleInput(
        'serviceItem',
        (prev) =>
          ({
            ...prev.serviceItem,
            serviceCategoryId: serviceProductId,
            serviceName: selectedServiceCategory
              .map((num, numIndex) => {
                const dropdownList = categoryDropdown[numIndex].list
                const nextValues = objectKeys(
                  categoryDropdown[numIndex].list
                ).find((keyName) => dropdownList[keyName] === num)
                return nextValues
              })
              .join(' > '),
            priceTagId,
          } as ServiceItemInEstimatesType)
      )
    } catch (error) {
      handleError(error)
    }
  }, [
    categoryDropdown,
    getTotalPrice,
    handleInput,
    optionPriceValues,
    priceTableOptionValues,
    quantityTitle,
    selectedMaterial,
    selectedServiceCategory,
    serviceProductId,
    serviceQuantity,
  ])

  // 시공 정보 - 대, 중, 소분류 조회
  const getCategoryList = useCallback(
    async (id: number, isCategory?: boolean) => {
      if (id < 0) return { list: {} }
      try {
        const res = await callAxios('order').get(
          `/subCategory/list?${
            isCategory ? `categoryId` : `subCategoryId`
          }=${id}`
        )
        const nextData = extractData<ApiServiceCategory[]>(res)
        if (nextData && nextData.length > 0) {
          return {
            list: nextData.reduce((prev, curr) => {
              prev[curr.name] = curr.id
              return prev
            }, {} as DropdownListType),
            isCategory,
            isEstimatesItem: nextData
              .map((item) => (item.isEstimates ? item.id : null))
              .filter((ii) => !!ii) as number[],
          }
        } else {
          return { list: {} } as CategoryDropdownType
        }
      } catch (error) {
        handleError(error)
        return { list: {} } as CategoryDropdownType
      }
    },
    []
  )
  const selectedIsEstimates = useMemo(() => {
    const lastCategory = categoryDropdown[categoryDropdown.length - 1]
    const lastSelect =
      selectedServiceCategory[selectedServiceCategory.length - 1]
    if (
      lastCategory?.isEstimatesItem &&
      lastSelect &&
      lastCategory.isEstimatesItem.includes(lastSelect)
    ) {
      return true
    }
    return false
  }, [categoryDropdown, selectedServiceCategory])

  // 대,중,소 분류 및 옵션, 자재 등 드롭다운 파싱
  const priceOptionDropdowns = useMemo(() => {
    if (!servicePriceOptions) {
      set_serviceQuantity(null)
      restoreServiceOptions()
      return null
    }
    if (servicePriceOptions) {
      set_quantityTitle(servicePriceOptions.quantity)
      set_materialItemList(
        servicePriceOptions.material.map((material) => ({
          ...material,
          count: null, // 선택 수량 카운팅 하기위해서 넣음
          maxCount: material.maxQuantity ?? 10,
        }))
      )
      set_serviceProductId(servicePriceOptions.id)
      const initialPriceTableOptions =
        servicePriceOptions.priceTagHeader.reduce(
          (prev, curr) => {
            prev[curr.priceTagColumn as OptionNames] = ''
            return prev
          },
          {} as {
            [key in OptionNames]: string
          }
        )
      const initialOptionPrice = servicePriceOptions.serviceOption.reduce(
        (prev, _curr, reduceIndex) => {
          prev[_curr.name] = null
          return prev
        },
        {} as {
          [key in string]: number | null
        }
      )
      set_priceTableOptionValues((prev) =>
        prev ? prev : initialPriceTableOptions
      )
      set_optionPriceValues((prev) => (prev ? prev : initialOptionPrice))
    }
    return {
      priceTagHeader: servicePriceOptions.priceTagHeader.reduce(
        (prev, curr) => {
          prev[curr.title] = {
            id: curr.id,
            priceTagColumn: curr.priceTagColumn as OptionNames,
            option: curr.option.reduce((prev, curr) => {
              prev[curr] = curr
              return prev
            }, {} as { [key in string]: string }),
          }
          return prev
        },
        {} as {
          [key in string]: {
            id: number
            priceTagColumn: OptionNames
            option: { [key in string]: string }
          }
        }
      ),
      serviceOptions: servicePriceOptions.serviceOption.reduce(
        (prev, curr, reduceIndex) => {
          prev[curr.name] = {
            id: reduceIndex,
            option: {
              [`예 (+${thousand(curr.price)}원)`]: curr.price,
              아니오: 0,
            },
          }
          return prev
        },
        {} as {
          [key in string]: {
            id: number
            option: { [key in string]: number }
          }
        }
      ),
    }
  }, [servicePriceOptions, restoreServiceOptions])

  // (기타에서) 옵션값이 선택되었는지 확인
  const checkOptions = useMemo(() => {
    if (
      objectKeys(priceTableOptionValues).some(
        (keyName) => priceTableOptionValues[keyName] === ''
      )
    )
      return true
    if (
      objectKeys(optionPriceValues).some(
        (keyName) => optionPriceValues[keyName] === null
      )
    )
      return true
    if (quantityTitle !== null && !serviceQuantity) return true
    return false
  }, [
    priceTableOptionValues,
    optionPriceValues,
    quantityTitle,
    serviceQuantity,
  ])

  // 각 카테고리 드롭다운 선택시 다음 카테고리 목록 조회해서 추가
  const handleCategoryDropdown = useCallback(
    async (index: number, value: number | string) => {
      if (typeof value === 'string') return
      set_selectedServiceCategory((prev) => {
        return updateAndDeleteBehind(prev, index, value)
      })
      const currentDropdown = categoryDropdown[index]
      const nextValue = await getCategoryList(value, currentDropdown.isFirst)
      if (Object.keys(nextValue.list).length) {
        set_categoryDropdown((prev) => {
          return updateAndDeleteBehind(prev, index + 1, nextValue)
        })
      } else {
        handleLastSelectedId(value)
      }
    },
    [categoryDropdown, getCategoryList, handleLastSelectedId]
  )

  const handleServiceType = useCallback(
    async (
      serviceType: '청소/도배/타일/바닥' | '기타 서비스',
      noInit?: boolean
    ) => {
      set_currentServiceType(serviceType)
      if (!noInit) {
        if (inputs.zoneGroupId && inputs.customerType) {
          setMainCategoryList(
            await getMainCategoryList(
              inputs.zoneGroupId,
              serviceType === '기타 서비스' ? 'sub' : 'main',
              inputs.customerType
            )
          )
        } else {
          setMainCategoryList(null)
        }
        set_servicePriceOptions(null)
        set_optionPriceValues({})
        set_priceTableOptionValues({})
        set_lastSelectedServiceCategoryId(null)
        set_selectedServiceCategory([])
        set_selectedMaterial(null)
      }
    },
    [
      getMainCategoryList,
      inputs.customerType,
      inputs.zoneGroupId,
      setMainCategoryList,
    ]
  )
  const handleDropdown = useCallback(
    (value: any, dropdownIndex: number) => {
      if (dropdownIndex === 0) {
        set_selectedServiceCategory([])
      }
      if (typeof value !== 'number') return
      set_categoryDropdown((prev) => {
        return updateAndDeleteBehind(prev, dropdownIndex + 1, null).filter(
          (ii) => !!ii
        ) as CategoryDropdownType[]
      })
      set_lastSelectedServiceCategoryId(null)
      set_servicePriceOptions(null)
      handleCategoryDropdown(dropdownIndex, value)
    },
    [handleCategoryDropdown]
  )
  const handlePriceTableOptionValues = useCallback(
    (tagName: OptionNames, value: string) => {
      set_priceTableOptionValues((prev) => ({
        ...prev,
        [tagName]: value,
      }))
    },
    []
  )
  const handlePriceTableOptionValuesAll = useCallback(
    (value: { h1?: string; h2?: string; h3?: string; h4?: string }) => {
      set_priceTableOptionValues(value)
    },
    []
  )
  const handleOptionPriceValue = useCallback(
    (tagName: string, value: number) => {
      set_optionPriceValues((prev) => ({
        ...prev,
        [tagName]: value,
      }))
    },
    []
  )
  const handleOptionPriceValues = useCallback(
    (value: { [x: string]: number | null }) => {
      set_optionPriceValues(value)
    },
    []
  )
  const handleServiceQuantity = useCallback((value: number | null) => {
    set_serviceQuantity(value)
  }, [])
  const fireFirstRef = useRef(false) as MutableRefObject<boolean>

  const [res] = useFetch<ApiEstimatesDetails>('order', `/estimates/${_id}`, {
    disabled: !_id,
  })
  const [responseData, set_responseData] =
    useState<EstimatesDetailsValuesForUserType | null>(null)
  const [errorZoneGroup, set_errorZoneGroup] = useState<boolean>(false)
  const [serviceRowList, set_serviceRowList] = useState<{
    title: string
    list: { name: string; value: string }[]
    requests: string
  }>({
    title: '',
    list: [],
    requests: '',
  })
  useEffect(() => {
    const getRowList = (response: ApiEstimatesDetails) => {
      const subCategories = orderBy(
        response.serviceInfo.subCategory,
        (value) => value.order
      ).map((item, itemIndex) => ({
        name: itemIndex + 1 + '차 분류',
        value: item.name,
      }))
      const tags = orderBy(
        response.serviceInfo.tag,
        (value) => value.priceTagColumn
      ).map((item) => ({ name: item.name, value: item.value }))
      const options = response.serviceInfo.serviceOption.map((item) => ({
        name: item.name,
        value: thousand(item.price) + '원',
      }))
      const materials = response.material
        ? [
            {
              name: '자재',
              value: `${response.material.name} (${thousand(
                response.material.quantity
              )})`,
            },
          ]
        : []
      const quantities = response.serviceInfo.quantity
        ? [
            {
              name: response.serviceInfo.quantity.name,
              value: thousand(response.serviceInfo.quantity.quantity),
            },
          ]
        : []
      return [
        ...subCategories,
        ...quantities,
        ...tags,
        ...options,
        ...materials,
      ]
    }
    const fetchCategoryDropdown = async (
      selectedList: number[],
      zoneGroupId: number,
      serviceType: 'main' | 'sub',
      customerType: string
    ) => {
      const res = await callAxios('order').get(
        `/category/list?zoneGroupId=${zoneGroupId}&type=${serviceType}&userType=${customerType}`
      )
      const mainCategory = extractData<ApiServiceCategory[]>(res)
      const nextValue = await Promise.all(
        selectedList.map((num, numIndex) =>
          getCategoryList(num, numIndex === 0)
        )
      )
      const nextCategoryDropdown = [
        {
          list: mainCategory.reduce((prev, curr) => {
            prev[curr.name] = curr.id
            return prev
          }, {} as DropdownListType),
          isFirst: true,
        },
        ...nextValue,
      ]
      set_categoryDropdown(nextCategoryDropdown)
      return nextCategoryDropdown
    }
    const fetchInit = async (response: ApiEstimatesDetails) => {
      const resData = parserForEstimatesDetails(response)
      let totalPriceConfig: ForTotalPrice = {
        _optionPriceValues: {},
        _priceTableOptionValues: {},
        _selectedMaterial: null,
        _serviceProductId: response.serviceProductId,
        _serviceQuantity: response.serviceInfo?.quantity?.quantity ?? null,
      }
      set_responseData(resData)
      dispatch(
        setCurrentGallery({
          ...initialGalleryData,
          data: {
            ...initialGalleryData.data,
            '요청 사진':
              response.serviceRequestsImageUrl.length > 0
                ? response.serviceRequestsImageUrl.map((item) => ({
                    id: getFileNameFromUrl(item),
                    name: getFileNameFromUrl(item),
                    url: item,
                  }))
                : null,
          },
        })
      )
      handleServiceRequest(response.serviceRequests)
      if (response.serviceInfo.category.name === '기타') {
        set_currentServiceType('기타 서비스')
      }
      const currentSelectedMaterial = response.material
        ? {
            id: response.material.id,
            name: response.material.name,
            price: response.material.price,
            image: response.material.imageUrl,
            imageUrl: response.material.imageUrl,
            count: response.material.quantity,
            maxQuantity: response.material.quantity,
          }
        : null
      totalPriceConfig._selectedMaterial = currentSelectedMaterial
      set_selectedMaterial(currentSelectedMaterial)
      if (response.addressInfo.zoneGroupId < 0) {
        set_errorZoneGroup(true)
        return
      }
      set_serviceRowList({
        title: response.serviceInfo.category.name,
        list: getRowList(response),
        requests: response.serviceRequests,
      })
      const currentServiceInfo = response.serviceInfo
      if (currentServiceInfo) {
        if (
          currentServiceInfo?.category?.id &&
          currentServiceInfo?.subCategory?.length > 0
        ) {
          const selectedSubCategory = sortBy(
            currentServiceInfo.subCategory,
            (value) => value.order
          )
          const selectedList = [...selectedSubCategory.map((item) => item.id)]
          const isSub = currentServiceInfo.category.name === '기타'
          set_selectedServiceCategory(
            isSub
              ? selectedList
              : [currentServiceInfo.category.id, ...selectedList]
          )
          const categoryList = [
            ...sortBy(currentServiceInfo.subCategory, (value) => value.order)
              .map((item) => item.parentId ?? null)
              .filter((ii) => !!ii),
          ]
          await fetchCategoryDropdown(
            isSub
              ? categoryList
              : [currentServiceInfo.category.id, ...categoryList],
            response.addressInfo.zoneGroupId,
            currentServiceInfo.category.name === '기타' ? 'sub' : 'main',
            response.orderInfo.userType
          )
          if (currentServiceInfo.serviceOption?.length > 0) {
            totalPriceConfig._optionPriceValues =
              currentServiceInfo.serviceOption.reduce((prev, curr) => {
                prev[curr.name] = curr.price
                return prev
              }, {} as { [key in string]: number })
            set_optionPriceValues(totalPriceConfig._optionPriceValues)
          }
          if (currentServiceInfo.quantity?.quantity) {
            set_serviceQuantity(currentServiceInfo.quantity.quantity)
          }
          if (currentServiceInfo.tag?.length > 0) {
            const currentPriceTableTags = currentServiceInfo.tag.reduce(
              (prev, curr) => {
                prev[curr.priceTagColumn] = curr.value
                return prev
              },
              {} as {
                h1?: string
                h2?: string
                h3?: string
                h4?: string
              }
            )
            totalPriceConfig._priceTableOptionValues = currentPriceTableTags
            set_priceTableOptionValues(currentPriceTableTags)
          }
          if (selectedList.length > 0) {
            const lastId = selectedList[selectedList.length - 1]
            set_lastSelectedServiceCategoryId(lastId)
            const nextServiceOptions = await getServicePriceOptions(
              lastId,
              response.addressInfo.zoneGroupId
            )
            if (nextServiceOptions) set_servicePriceOptions(nextServiceOptions)
          }
        }
        const { priceTagId } = await getTotalPrice(totalPriceConfig)
        // handleInput('serviceMaterialPrice', materialPriceTotal) // 제거 04.26
        // handleInput('serviceFee', taskPriceAndOptionPrice) // 제거 04.26
        handleInput('serviceItem', {
          serviceCategoryName: response.serviceInfo.category.name,
          serviceCategoryId: response.serviceProductId,
          serviceName: response.servicePath,
          serviceRequests: response.serviceRequests,
          priceTagId,
        } as ServiceItemInEstimatesType)
      }
    }
    if (res && !fireFirstRef.current) {
      fireFirstRef.current = true
      fetchInit(res)
    }
  }, [
    dispatch,
    getCategoryList,
    res,
    handleLastSelectedId,
    getTotalPrice,
    handleServiceRequest,
    handleInput,
    getServicePriceOptions,
  ])
  return {
    errorZoneGroup,
    selectedIsEstimates,
    serviceRowList,
    responseData,
    currentServiceType,
    selectedServiceCategory,
    servicePriceOptions,
    serviceProductId,
    priceTableOptionValues,
    optionPriceValues,
    materialItemList,
    quantityTitle,
    serviceQuantity,
    selectedMaterial,
    lastSelectedServiceCategoryId,
    categoryDropdown,
    handleOptionPriceValue,
    handleServiceQuantity,
    handlePriceTableOptionValues,
    handleDropdown,
    handleServiceType,
    handleServiceRequest,
    handleCurrentServiceType,
    handleSelectedMaterial,
    restoreServiceOptions,
    getMainCategoryList,
    getCurrentServicePrice,
    getCategoryList,
    handleLastSelectedId,
    priceOptionDropdowns,
    checkOptions,
    handleCategoryDropdown,
    handleSetCategoryDropdown,
    handleServicePriceOption,
    handleSelectedServiceCategory,
    handleOptionPriceValues,
    handlePriceTableOptionValuesAll,
    setMainCategoryList,
  }
}
export default useServiceControlOnEstimates
