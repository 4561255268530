import { PeriodLabelProps } from 'pages/Main/components/PeriodDataBox/PeriodDataBox'
import { UserBoxProps } from 'pages/Main/components/UserBox/UserBox'

export type TodayCondition = { [key in string]: number }
export type UserCondition = { [key in string]: Omit<UserBoxProps, 'label'> }
export interface StaffCondition {
  관리자: Omit<UserBoxProps, 'label'>
  숙련공: Omit<UserBoxProps, 'label'>
  실측공: Omit<UserBoxProps, 'label'>
  자재지원팀: Omit<UserBoxProps, 'label'>
}
export type GraphRatioType = {
  total: number // 총 서비스 개수
  serviceCounts: ServiceSchema[] // 서비스 카테고리의 통계. 오름차순.
  etcCounts: ServiceSchema[]
}
export interface PeriodDataConditionProps {
  data: {
    [key in PeriodLabelProps]: number
  }
  ratio: GraphRatioType | null
}
export type ServiceSchema = {
  name: string
  count: number
}
export type PeriodStatsType = {
  user: {
    total: number // 모든 사용자 유형에 총합
    U1: number // 일반 회원 총합
    U2: number // 정기 회원 총합
    U3: number // 트너 회원 총합
    A1: number // 리자 총합
    A2: number // 원팀 총합
    T1: number // 련공 총합
    T2: number // 측공 총합
  }
  order: {
    paymentAmount: number //결제 총합
    ordersCounts: number //주문 총합
    cancellationsCounts: number //취소 주문 총합
    receivablesCounts: number //수금 주문 총합
  }
  rate: GraphRatioType
}
export type UserCountData = {
  U1: number // 일반 회원
  U2: number // 정기 회원
  U3: number // 파트너 회원
  A1: number // 관리자
  A2: number // 자재지원팀
  T1: number // 숙련공
  T2: number // 실측공
}
export type UserStatsType = {
  today: UserCountData // 가입자현황, 내부직원현황의 굵은 글자 부분
  change: UserCountData // 가입자현황, 내부직원현황의 작은 녹색 부분
  todayTotal: number // 가입자 현황 - 총 가입자 굵은 글자 부분
  changeTotal: number // 가입자 현황 - 총 가입자 작은 녹색 글자 부분
}
export const userCountDataLabel = {
  user: {
    U1: '일반 회원',
    U2: '정기 회원',
    U3: '파트너 회원',
  },
  worker: {
    A1: '관리자',
    A2: '자재지원팀',
    T1: '숙련공',
    T2: '실측공',
  },
}
export type TodayStatsType = {
  paymentAmount: number // 결제 금액. 음수 가능성 있음(결제취소가 더 많이 일어난 경우)
  ordersCounts: number // 주문 건수
  cancellationsCounts: number // 취소 건수
  receivablesCounts: number // 미수금 건수
}
export const todayStatsLabel = {
  paymentAmount: {
    label: '결제 금액',
    unit: '원',
  },
  ordersCounts: {
    label: '주문 건수',
    unit: '건',
  },
  cancellationsCounts: {
    label: '취소 건수',
    unit: '건',
  },
  receivablesCounts: {
    label: '미수금 건수',
    unit: '건',
  },
}
