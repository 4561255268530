import styled from '@emotion/styled/macro'
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

const KakaoPostalCodeBodyWrap = styled.div`
  width: 100%;
  height: 100%;
`

interface ThemeType {
  bgColor?: string | null
  searchBgColor?: string
  contentBgColor?: string
  pageBgColor?: string
  textColor?: string
  queryTextColor?: string
  postcodeTextColor?: string
  emphTextColor?: string
  outlineColor?: string
}

export interface PostcodeType {
  address: string
  addressEnglish: string
  addressType: 'R' | 'J'
  apartment: 'Y' | 'N'
  autoJibunAddress: string
  autoJibunAddressEnglish: string
  autoRoadAddress: string
  autoRoadAddressEnglish: string
  bcode: string
  bname: string
  bname1: string
  bname1English: string
  bname2: string
  bname2English: string
  bnameEnglish: string
  buildingCode: string
  buildingName: string
  hname: string
  jibunAddress: string
  jibunAddressEnglish: string
  noSelected: 'Y' | 'N'
  // postcode: string // deprecated
  // postcode1: string // deprecated
  // postcode2: string // deprecated
  // postcodeSeq: string // deprecated
  query: string
  roadAddress: string
  roadAddressEnglish: string
  roadname: string
  roadnameCode: string
  roadnameEnglish: string
  sido: string
  sidoEnglish: string
  sigungu: string
  sigunguCode: string
  sigunguEnglish: string
  userLanguageType: 'K' | 'E'
  userSelectedType: 'R' | 'J'
  zonecode: string
}
interface KakaoPostalItemType {
  onSearch?: boolean | undefined
  alwaysShowEngAddr?: boolean
  animation?: boolean
  autoClose?: boolean
  autoMapping?: boolean
  autoResize?: boolean
  defaultQuery?: string | null
  errorMessage?: string | null
  height?: number
  hideEngBtn?: boolean
  hideMapBtn?: boolean
  maxSuggestItems?: number
  pleaseReadGuide?: number
  pleaseReadGuideTimer?: number
  scriptUrl?: string
  shorthand?: boolean
  showMoreHName?: boolean
  style?: React.CSSProperties
  submitMode?: boolean
  theme?: ThemeType
  useSuggest?: boolean
  useBannerLink?: boolean
  width?: string | null
  focusInput?: boolean
  focusContent?: boolean
}
interface KakaoPostalCodeType {
  onComplete: (value: PostcodeType) => void
  onClose: () => void
  item?: KakaoPostalItemType
}
declare global {
  interface Window {
    daum: any
  }
}
const KakaoPostalCode: React.FC<KakaoPostalCodeType> = (props) => {
  const { onComplete, onClose } = props
  const [heightNum, set_heightNum] = useState<number>()
  const wrap_ref = useRef() as MutableRefObject<HTMLDivElement>
  const initial = useCallback(
    (item?: KakaoPostalItemType) => {
      if (window?.daum) {
        var width = 500 //팝업의 너비
        var height = 500 //팝업의 높이
        window?.daum?.postcode.load(() => {
          try {
            const Postcode = new window.daum.Postcode({
              oncomplete: function oncomplete(data: PostcodeType) {
                onComplete(data)
              },
              onclose: () => onClose(),
              onsearch: item?.onSearch,
              onresize: function onresize(size: { height: number }) {
                if (item?.autoResize) set_heightNum(size.height)
              },
              alwaysShowEngAddr: item?.alwaysShowEngAddr ?? false,
              animation: item?.animation ?? false,
              autoMapping: item?.autoMapping ?? true,
              autoResize: item?.autoResize ?? false,
              width,
              height,
              hideEngBtn: item?.hideEngBtn ?? false,
              hideMapBtn: item?.hideMapBtn ?? false,
              maxSuggestItems: item?.maxSuggestItems ?? 10,
              pleaseReadGuide: item?.pleaseReadGuide ?? 0,
              pleaseReadGuideTimer: item?.pleaseReadGuideTimer ?? 1.5,
              shorthand: item?.shorthand ?? true,
              showMoreHName: item?.showMoreHName ?? false,
              submitMode: item?.submitMode ?? true,
              theme: item?.theme ?? null,
              useSuggest: item?.useSuggest ?? true,
              useBannerLink: item?.useBannerLink ?? true,
              focusInput: item?.focusInput ?? true,
              focusContent: item?.focusContent ?? true,
            })
            Postcode.open({
              popupTitle: '주소 검색',
              popupKey: 'double_address1',
              left: window.screen.width / 2 - width / 2 + window.screenX,
              top: window.screen.height / 2 - height / 2 + window.screenY,
            })
          } catch (error) {
            onClose()
          }
          // if (wrap_ref?.current) {
          //   Postcode.embed(wrap_ref.current, {
          //     q: item?.defaultQuery,
          //     autoClose: item?.autoClose,
          //   })
          // }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    const scriptId = 'daum_postcode_script'
    const isExist = !!document.getElementById(scriptId)

    if (!isExist) {
      const script = document.createElement('script')
      script.src =
        props.item?.scriptUrl ??
        'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
      script.onload = () => initial(props.item)
      // script.onerror = error => handleError(error);
      script.id = scriptId
      document.body.appendChild(script)
    } else initial(props.item)
  }, [initial, props.item])

  return (
    <>
      <KakaoPostalCodeBodyWrap
        ref={wrap_ref}
        {...(heightNum && { style: { height: heightNum } })}
      ></KakaoPostalCodeBodyWrap>
    </>
  )
}
interface PostalCodeType {
  onClose: () => void
  onComplete: (data: PostcodeType) => void
}
const PostalCode = (props: PostalCodeType) => {
  const { onComplete, onClose } = props
  return (
    <>
      <KakaoPostalCode onClose={onClose} onComplete={onComplete} />
    </>
  )
}
export default PostalCode
