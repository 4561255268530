import { css } from '@emotion/react'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound'
import DraggablePopup from 'components/DraggablePopup'
import PopupInputLayout from 'components/PopupInputLayout'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { typography } from 'themes/styles'
import callAxios, { handleError } from 'utils/callAxios'
import { thousand } from 'utils/helpers'
import S from './CancelWork.styles'
import {
  ApiCancelInfo,
  ApiRefundTaskInfo,
  CancelPostData,
  CancelWorkProps,
} from './CancelWork.types'

const CancelWork = ({
  _css,
  _onClose,
  _paymentInfo,
  _selected,
  _orderId,
  _emitValue,
  ...props
}: CancelWorkProps) => {
  const timeoutRef = useRef() as MutableRefObject<NodeJS.Timeout>

  const fireFirstRef = useRef(false) as MutableRefObject<boolean>

  const [refundTaskList, set_refundTaskList] = useState<ApiRefundTaskInfo[]>([])

  const [fixedAmount, set_fixedAmount] = useState<{
    cancelPoint: number
    cancelDispatchAmount: number
    cancelAmount: number
  }>({
    cancelPoint: 0,
    cancelDispatchAmount: 0,
    cancelAmount: 0,
  })

  const [postData, set_postData] = useState<CancelPostData | null>(null)

  useEffect(() => {
    set_postData({
      cancelDispatch: false,
      tasks: [..._selected.selectIds, ..._selected.rejectIds].map((item) => {
        return {
          id: String(item),
          reject: _selected.rejectIds.includes(item),
          cancelAmount: null,
          cancelTaskPrice: null,
        }
      }),
    })
  }, [_selected])
  const getPostDataWithRefundTaskInfo = (
    value: ApiRefundTaskInfo[],
    prevPostData: CancelPostData
  ) => {
    return value.reduce((prev, curr) => {
      prev.cancelDispatch = prevPostData.cancelDispatch
      prev.tasks = prevPostData.tasks.map((item) => {
        const cancelItem = value.find((data) => data.taskId === item.id)
        if (!item.reject && cancelItem) {
          return {
            id: item.id,
            reject: false,
            cancelAmount: cancelItem.requestCancelAmount,
            cancelTaskPrice: cancelItem.requestCancelTaskPrice,
          }
        } else {
          return {
            id: item.id,
            reject: true,
            cancelAmount: null,
            cancelTaskPrice: null,
          }
        }
      })
      return prev
    }, {} as CancelPostData)
  }
  const fetchCurrent = useCallback(
    async (
      prevPostData: CancelPostData | null,
      value?: ApiRefundTaskInfo[]
    ) => {
      if (!prevPostData) return
      try {
        let currentPostData = prevPostData
        if (value && prevPostData) {
          currentPostData = getPostDataWithRefundTaskInfo(value, prevPostData)
        }
        const res = await callAxios('task').post<
          DoubleDResponse<ApiCancelInfo>
        >(`/order/${_orderId}/cancel-info`, {
          ...currentPostData,
        })
        if (res.data.data) {
          set_fixedAmount({
            ...res.data.data.result,
            cancelDispatchAmount: res.data.data.current.remainDispatchPrice,
          })
          if (!fireFirstRef.current) {
            fireFirstRef.current = true
            set_refundTaskList(res.data.data.current.refundTaskInfo)
            set_postData(
              getPostDataWithRefundTaskInfo(
                res.data.data.current.refundTaskInfo,
                prevPostData
              )
            )
          }
        }
      } catch (error) {
        handleError(error)
      }
    },
    [_orderId]
  )

  useEffect(() => {
    if (postData && !fireFirstRef.current) {
      fetchCurrent(postData)
    }
  }, [_orderId, fetchCurrent, postData])

  const handleGetPoint = async (
    prevPostData: CancelPostData,
    value: ApiRefundTaskInfo[]
  ) => {
    try {
      fetchCurrent(prevPostData, value)
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      <DraggablePopup
        _title={'환불 금액 수정'}
        _open={true}
        _onClose={_onClose}
        _bottom={
          <>
            <GrayScaleFill onClick={_onClose}>취소</GrayScaleFill>
            <PrimaryButton
              disabled={!postData}
              onClick={() => {
                if (!postData) return
                _emitValue(postData)
              }}
            >
              완료
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <FlexColumn gap={12} width={'100%'}>
            {refundTaskList.map((task, taskIndex) => {
              return (
                <ContentRadiusBox
                  key={task.taskId}
                  _css={css`
                    padding: 24px 16px;
                  `}
                >
                  <FlexColumn
                    gap={24}
                    _css={css`
                      padding: 0 12px;
                    `}
                  >
                    <TXT _textStyle="Title/Bold" _color="Grayscale/Black light">
                      {task.serviceName}
                    </TXT>
                    <FlexColumn gap={12} width={'100%'}>
                      <PopupInputLayout
                        _gap={4}
                        _title={`총 환불금액(결제금 ${thousand(
                          task.remainAmount
                        )}원) *`}
                      >
                        <UnderlineNumberInput
                          value={task.requestCancelAmount}
                          suffix="원"
                          isAllowed={(values) => {
                            if (!values.floatValue) return true
                            return (
                              values.floatValue >= 0 &&
                              values.floatValue <= (task.remainAmount ?? 0)
                            )
                          }}
                          handleValue={(value) => {
                            if (!refundTaskList) return
                            if (!postData) return
                            const nextTaskList = refundTaskList.map((item) => {
                              item =
                                item.taskId === task.taskId
                                  ? {
                                      ...item,
                                      requestCancelAmount: value,
                                    }
                                  : item
                              return item
                            })
                            set_refundTaskList(nextTaskList)
                            if (timeoutRef.current)
                              clearTimeout(timeoutRef.current)
                            if (
                              nextTaskList.every(
                                (item) =>
                                  item.requestCancelAmount &&
                                  item.requestCancelAmount >= 100
                              )
                            ) {
                              timeoutRef.current = setTimeout(() => {
                                handleGetPoint(postData, nextTaskList)
                              }, 500)
                            }
                          }}
                        />
                      </PopupInputLayout>
                      <PopupInputLayout
                        _gap={4}
                        _title={`공임비 환불금액(결제된 공임비 ${thousand(
                          task.remainTaskPrice
                        )}원) *`}
                      >
                        <UnderlineNumberInput
                          value={task.requestCancelTaskPrice}
                          suffix="원"
                          isAllowed={(values) => {
                            if (!values.floatValue) return true
                            return (
                              values.floatValue >= 0 &&
                              values.floatValue <= (task.remainTaskPrice ?? 0)
                            )
                          }}
                          handleValue={(value) => {
                            if (!refundTaskList) return
                            if (!postData) return
                            const nextTaskList = refundTaskList.map((item) =>
                              item.taskId === task.taskId
                                ? { ...item, requestCancelTaskPrice: value }
                                : item
                            )
                            set_refundTaskList(nextTaskList)
                            if (timeoutRef.current)
                              clearTimeout(timeoutRef.current)
                            timeoutRef.current = setTimeout(() => {
                              handleGetPoint(postData, nextTaskList)
                            }, 500)
                          }}
                        />
                      </PopupInputLayout>
                    </FlexColumn>
                  </FlexColumn>
                </ContentRadiusBox>
              )
            })}
          </FlexColumn>
          <ContentRadiusBox
            _css={css`
              padding: 24px 16px;
            `}
          >
            <FlexRow
              gap={16}
              width={'100%'}
              _css={css`
                padding: 0 12px;
              `}
            >
              <CheckBoxRound
                _mini
                _active={postData?.cancelDispatch}
                onClick={() => {
                  if (!postData) return postData
                  if (!refundTaskList) return postData
                  const nextPostData = {
                    ...postData,
                    cancelDispatch: !postData.cancelDispatch,
                  }
                  set_postData(nextPostData)
                  handleGetPoint(nextPostData, refundTaskList)
                }}
              >
                출장비 환불
              </CheckBoxRound>
              <UnderlineNumberInput
                _css={css`
                  flex: 1;
                `}
                value={fixedAmount.cancelDispatchAmount}
                suffix="원"
                handleValue={() => {}}
                readOnly
              />
            </FlexRow>
          </ContentRadiusBox>
          <FlexRow
            gap={17}
            _css={css`
              justify-content: center;
              color: #505361;
              div {
                ${typography['Body/Large/Regular']}
              }
              span {
                margin-left: 4px;
                ${typography['Body/Large/Bold']}
              }
            `}
          >
            <div>
              총 환불 금액<span>{thousand(fixedAmount.cancelAmount)}원</span>
            </div>
            <div>
              포인트 환불 예정<span>{thousand(fixedAmount.cancelPoint)}원</span>
            </div>
          </FlexRow>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default CancelWork
