import { css } from '@emotion/react'
import { ImagesDetailsProfile } from 'assets'
import Badge from 'components/Badge'
import { BADGE_COLOR } from 'components/Badge/Badge'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import dayjs from 'dayjs'
import {
  ContentBoxWrap,
  ContentProfileBox,
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
} from 'themes/Shared.styles'
import { parseToFormattedStr } from 'utils/parsers'
import { UserDetailsReadOnlyProps } from './UserDetailsReadOnly.types'

const UserDetailsReadOnly = ({
  _css,
  inputs,
  ...props
}: UserDetailsReadOnlyProps) => {
  return (
    <>
      <ContentBoxWrap>
        <ContentProfileBox>
          <div className="wrap">
            <FlexColumn gap={12} alignItems="center">
              <div
                data-profile
                style={{
                  backgroundImage: `url(${
                    inputs.profileImage
                      ? inputs.profileImage
                      : ImagesDetailsProfile
                  })`,
                }}
              />
              <div>
                <div className="name">{inputs.name}</div>
                <div className="phone">
                  {parseToFormattedStr(inputs.phone, 'phone')}
                </div>
                <FlexRow gap={4} className="badges" justifyContent={'center'}>
                  {inputs.userState !== 'N' && (
                    <Badge
                      {...(inputs.userState === 'D'
                        ? BADGE_COLOR.Danger
                        : BADGE_COLOR.Gray)}
                    >
                      {inputs.userState === 'D' ? '휴면' : '탈퇴'}
                    </Badge>
                  )}
                  <Badge
                    {...(inputs.userType === 'CUSTOMER'
                      ? BADGE_COLOR.PrimaryLight
                      : BADGE_COLOR.SuccessLight)}
                  >
                    {inputs.userType === 'CUSTOMER' ? '일반 회원' : '정기 회원'}
                  </Badge>
                </FlexRow>
              </div>
            </FlexColumn>
          </div>
        </ContentProfileBox>
        <BoxLayout _title={'기본 정보'}>
          <ContentRadiusBox>
            <BoxRowComponent _label={'아이디'} _contents={inputs.userId} />
            <BoxRowComponent
              _label={'성별'}
              _contents={inputs.gender === 'F' ? '여' : '남'}
            />
            {inputs.userType === 'REGULAR' && (
              <>
                <BoxRowComponent
                  _label={'주민등록번호'}
                  _contents={parseToFormattedStr(
                    inputs.residentNumber,
                    'residentNumber'
                  )}
                />
                <BoxRowComponent
                  _label={'계좌번호'}
                  _contents={inputs.accountNumber}
                />
                <BoxRowComponent
                  _label={'현금영수증 발행'}
                  _contents={inputs.subscribedCustomer?.cashReceiptCode}
                />
              </>
            )}
            <BoxRowComponent _label={'이메일'} _contents={inputs.email} />
            {inputs.subscribedCustomer?.address && (
              <BoxRowComponent
                _label={'실주소'}
                _contents={
                  inputs.subscribedCustomer?.address +
                  ' ' +
                  inputs.subscribedCustomer?.addressDetails
                }
              />
            )}
            <BoxRowComponent
              _label={'가입일시'}
              _contents={
                inputs.createdAt
                  ? dayjs(inputs.createdAt).format('YYYY-MM-DD')
                  : null
              }
            />
            <BoxRowComponent
              _label={'최근 이용일시'}
              _contents={
                inputs.recentUsedAt
                  ? dayjs(inputs.recentUsedAt).format('YYYY-MM-DD')
                  : null
              }
            />
            {inputs.userState === 'W' && (
              <>
                <BoxRowComponent
                  _label={'탈퇴 사유'}
                  _contents={
                    inputs.quitAt
                      ? dayjs(inputs.quitAt).format('YYYY-MM-DD')
                      : null
                  }
                />
                <BoxRowComponent
                  _label={'탈퇴 사유'}
                  _contents={inputs.quitReason}
                />
              </>
            )}
          </ContentRadiusBox>
          {inputs.userState === 'W' && (
            <ContentRadiusBox
              className="question"
              _css={css`
                margin-top: 12px;
              `}
            >
              <BoxRowComponent
                _label={'탈퇴의견'}
                _contents={inputs.quitReasonWhy}
              />
            </ContentRadiusBox>
          )}
        </BoxLayout>
      </ContentBoxWrap>
    </>
  )
}
export default UserDetailsReadOnly
