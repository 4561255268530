import styled from '@emotion/styled/macro'
import { colors } from 'themes/styles'

const AdminPopUpStyle = {
  ContentWrapper: styled.div`
    .hr {
      background-color: ${colors['Grayscale/Gray Lighter']};
      height: 1px;
      width: 100%;
    }
    .padding-wrapper {
      padding: 32px 32px 0px;
    }
  `,
}
export default AdminPopUpStyle
