import { css } from '@emotion/react'
import { ImagesDetailsArrowRightCircle } from 'assets'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import DraggablePopup from 'components/DraggablePopup'
import { useGallery } from 'components/Gallery/Gallery.hooks'
import PhotoUploadBox from 'components/PhotoUploadBox'
import PopupInputLayout from 'components/PopupInputLayout'
import Textarea from 'components/Textarea'
import UnderlineInput from 'components/UnderlineInput'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useMatchInput from 'hooks/useMatchInput'
import { useEffect } from 'react'
import { FlexRow, TXT } from 'themes/Shared.styles'
import S from './MeasurementInfo.styles'
import {
  MeasurementInfoProps,
  MEASUREMENT_INITIAL_VALUE,
} from './MeasurementInfo.types'

const MeasurementInfo = ({
  _css,
  _open,
  _id,
  _galleryId,
  _item,
  _onClose,
  _emitValue,
  ...props
}: MeasurementInfoProps) => {
  const { inputs, handleInput, handleValues } = useMatchInput(
    MEASUREMENT_INITIAL_VALUE
  )

  const trueWhenEmpty = useEmptyCheck(inputs, '360도 사진', 'etc', 'id', '사진')
  useEffect(() => {
    if (_item) {
      handleValues(_item)
    }
  }, [_id, handleValues, _item])
  const { openGallery, currentGallery } = useGallery((updated) => {
    const updatedWith360 = _item
      ? updated.data['실측 사진']?.find((data) => data.id === _item.id)?.list[
          '360 사진'
        ] ?? []
      : []
    const updatedWithSite = _item
      ? updated.data['실측 사진']?.find((data) => data.id === _item.id)?.list[
          '사진'
        ] ?? []
      : []
    handleInput('360도 사진', updatedWith360)
    handleInput('사진', updatedWithSite)
  })

  const with360 = _item
    ? currentGallery.data['실측 사진']?.find((data) => data.id === _item.id)
        ?.list['360 사진'] ?? []
    : []
  const withSite = _item
    ? currentGallery.data['실측 사진']?.find((data) => data.id === _item.id)
        ?.list['사진'] ?? []
    : []
  return (
    <>
      <DraggablePopup
        _title={_id ? '실측 정보' : '실측 정보 입력'}
        _open={true}
        _onClose={_onClose}
        _bottom={
          <>
            <GrayScaleFill onClick={_onClose}>취소</GrayScaleFill>
            <PrimaryButton
              disabled={trueWhenEmpty}
              onClick={() => {
                _emitValue({ ...inputs, id: inputs.id })
              }}
            >
              확인
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <PopupInputLayout _title="제목" _required={true} _gap={4}>
            <UnderlineInput
              value={inputs.title}
              handleValue={(value) => handleInput('title', value)}
            />
          </PopupInputLayout>
          <PopupInputLayout
            _title="사이즈 또는 규격, 평수"
            _required={true}
            _gap={4}
          >
            <UnderlineInput
              value={inputs.size}
              handleValue={(value) => handleInput('size', value)}
            />
          </PopupInputLayout>
          <PopupInputLayout _title="사진">
            <FlexRow
              gap={8}
              onClick={() => {
                if (!_item || !(withSite.length > 0)) return
                if (!inputs.id) return
                openGallery({
                  ...currentGallery,
                  target: ['실측 사진', inputs.id, '사진'],
                })
              }}
            >
              {(!_item || !(withSite.length > 0)) && (
                <PhotoUploadBox
                  _type={'task'}
                  _url={'/measurement/image'}
                  _optionValue={{ ..._galleryId, isImage360: false }}
                  _emitValue={(value) => {
                    handleInput('사진', value)
                  }}
                />
              )}
              <TXT
                _css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                `}
                _color={
                  inputs['사진'].length > 0
                    ? 'Grayscale/Black light'
                    : 'Grayscale/Gray Light'
                }
                _textStyle="Body/Large/Bold"
              >
                {_item && withSite.length > 0 ? (
                  <>
                    {`등록된 사진 ${withSite.length}개`}
                    <img src={ImagesDetailsArrowRightCircle} alt="" />
                  </>
                ) : inputs['사진'].length ? (
                  `등록된 사진 ${inputs['사진'].length}개`
                ) : (
                  '등록된 사진 없음'
                )}
              </TXT>
            </FlexRow>
          </PopupInputLayout>
          <PopupInputLayout _title="360도 사진">
            <FlexRow
              gap={8}
              onClick={() => {
                if (!_item || !(with360.length > 0)) return
                if (!inputs.id) return
                openGallery({
                  ...currentGallery,
                  target: ['실측 사진', inputs.id, '360 사진'],
                })
              }}
            >
              {(!_item || !(with360.length > 0)) && (
                <PhotoUploadBox
                  _type={'task'}
                  _url={'/measurement/image'}
                  _optionValue={{ ..._galleryId, isImage360: true }}
                  multiple={false}
                  _emitValue={(value) => {
                    handleInput('360도 사진', [value[0]])
                  }}
                />
              )}
              <TXT
                _css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                `}
                _color={
                  inputs['360도 사진'].length > 0
                    ? 'Grayscale/Black light'
                    : 'Grayscale/Gray Light'
                }
                _textStyle="Body/Large/Bold"
              >
                {_item && with360.length > 0 ? (
                  <>
                    {`등록된 사진 ${with360.length}개`}
                    <img src={ImagesDetailsArrowRightCircle} alt="" />
                  </>
                ) : inputs['360도 사진'].length ? (
                  `등록된 사진 ${inputs['360도 사진'].length}개`
                ) : (
                  '등록된 사진 없음'
                )}
              </TXT>
            </FlexRow>
          </PopupInputLayout>
          <PopupInputLayout _title="특이 사항">
            <Textarea
              minHeight={'120px'}
              placeholder="특이사항을 입력해주세요."
              value={inputs.etc}
              onChange={(e) => handleInput('etc', e.target.value)}
            />
          </PopupInputLayout>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default MeasurementInfo
