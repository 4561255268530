// import S from './AdminInfo.styles'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import { FlexColumn } from 'themes/Shared.styles'

import { ContentRadiusBox } from 'themes/Shared.styles'
export interface AdminInfoProps {
  _name: string
  _phone: string
  _team: string
  _position: string
  _part: string
}

export const AdminInfo = ({
  _name,
  _part,
  _phone,
  _position,
  _team,
  ...props
}: AdminInfoProps) => {
  return (
    <>
      <ContentRadiusBox>
        <BoxRowComponent _label={'이름'} _contents={_name} />
        <BoxRowComponent _label={'휴대폰 번호'} _contents={_phone} />
        <BoxRowComponent _label={'소속'} _contents={_team} />
        <BoxRowComponent _label={'직급'} _contents={_position} />
      </ContentRadiusBox>
      <S.WithQuestionBox>
        <ContentRadiusBox className="question">
          <BoxRowComponent
            _label={'담당 업무'}
            _contents={
              <FlexColumn gap={0} alignItems="flex-start">
                {_part}
              </FlexColumn>
            }
          />
        </ContentRadiusBox>
      </S.WithQuestionBox>
    </>
  )
}
