import styled from '@emotion/styled/macro'
import useMatchInput from 'hooks/useMatchInput'
import { useEffect } from 'react'

const TestWrap = styled.div``

interface TestProps {}

type Paths<T> = {
  [K in keyof T]: T[K] extends object
    ? `${string & K}.${Paths<T[K]>}` | `${string & K}`
    : `${string & K}`
}[keyof T]

type PathValue<T, P extends string> = P extends `${infer K}.${infer R}`
  ? K extends keyof T
    ? PathValue<T[K], R>
    : never
  : P extends keyof T
  ? T[P]
  : never

export function getValueWithPathName<T, P extends Paths<T>>(
  o: T,
  path: P
): PathValue<T, P> {
  const keys = path.split('.') as string[]
  let current: any = o

  for (const key of keys) {
    if (current[key] === undefined) {
      throw new Error(`Path "${path}" does not exist in object.`)
    }
    current = current[key]
  }

  return current
}

export const toIsoString = (value: string) => {
  const year = value.slice(0, 4)
  const month = value.slice(4, 6)
  const day = value.slice(6, 8)

  const hour = value.slice(9, 11)
  const minute = value.slice(11, 13)
  const second = value.slice(13, value.length - 1)
  return `${year}-${month}-${day}T${hour}:${minute}:${second}Z`
}
const Test = ({ ...props }: TestProps) => {
  const { inputs, pickAndUpdate } = useMatchInput<{
    a: string
    b: string
    c: {
      d: string
      e: number
      f: {
        g: number
      }
    }
  }>({
    a: '',
    b: '',
    c: {
      d: '',
      e: 0,
      f: {
        g: 0,
      },
    },
  })
  useEffect(() => {
    pickAndUpdate('c.f.g', 2)
  }, [pickAndUpdate])
  useEffect(() => {
    console.log('inputs', inputs)
  }, [inputs])
  return (
    <>
      <TestWrap></TestWrap>
    </>
  )
}
export default Test
