import { NonIndexRouteObject } from 'react-router-dom'
import { PageAuthority, pageAuthorities } from 'types/api/samples/adminWeb'
import Building from './Member/Building'
import EditBuilding from './Member/Building/EditBuilding'
import Partner from './Member/Partner'
import EditPartner from './Member/Partner/EditPartner'
import User from './Member/User'
import EditUser from './Member/User/EditUser'
import Area from './Services/Area'
import Funnel from './Services/Funnel'
import Setting from './Services/Setting'
import SettlementForPartnerHistory from './Settlement/SettlementForPartnerHistory/SettlementForPartnerHistory'
import SettlementForPartnerQueue from './Settlement/SettlementForPartnerQueue/SettlementForPartnerQueue'
import SettlementForTechsHistory from './Settlement/SettlementForTechsHistory/SettlementForTechsHistory'
import SettlementForTechsQueue from './Settlement/SettlementForTechsQueue/SettlementForTechsQueue'
import Coupon from './Support/Coupon'
import CouponPublish from './Support/Coupon/CouponPublish'
import Event from './Support/Event'
import EventPublish from './Support/Event/EventPublish/EventPublish'
import Notice from './Support/Notice'
import NoticePublish from './Support/Notice/NoticePublish/NoticePublish'
import Point from './Support/Point'
import PointPublish from './Support/Point/Publish/PointPublish'
import Admin from './System/Admin'
import LogList from './System/Log/LogList'
import Techs from './System/Techs'
import EditTechs from './System/Techs/EditTechs'
import Unpaid from './Unpaid'
import Calendar from './Work/Calendar'
import Estimates from './Work/Estimates'
import AddEstimates from './Work/Estimates/AddEstimates'
import WorkDetailsPage from './Work/WorkDetailsPage'
import WorkList from './Work/WorkList'

export interface ExtraRouteObject extends NonIndexRouteObject {
  name?: string
  code?: PageAuthority
  path: string
}

export const isPageAuthority = (
  value: string | undefined
): value is PageAuthority => {
  return value !== undefined && pageAuthorities.includes(value as PageAuthority)
}

export const authorizedPages: ExtraRouteObject[] = [
  // ** 시공 관리

  {
    path: '/work/estimates',
    name: '견적 리스트',
    code: 'estimates',
    element: <Estimates />,
  },
  {
    path: '/work/estimates/new/:id',
    name: '견적서 작성',
    code: 'estimates',
    element: <AddEstimates />,
  },
  {
    path: '/work/estimates/new',
    name: '견적서 작성',
    code: 'estimates',
    element: <AddEstimates />,
  },
  {
    path: '/work/estimates/:id',
    name: '견적 상세',
    element: <Estimates />,
  },
  {
    path: '/work/ordered',
    name: '주문 완료',
    code: 'ordered',
    element: <WorkList />,
  },
  {
    path: '/work/ordered/:id',
    name: '주문 완료 상세',
    code: 'ordered',
    element: <WorkDetailsPage />,
  },
  {
    path: '/work/waiting',
    name: '시공 대기',
    code: 'waiting',
    element: <WorkList />,
  },
  {
    path: '/work/waiting/:id',
    name: '시공 대기 상세',
    code: 'waiting',
    element: <WorkDetailsPage />,
  },
  {
    path: '/work/cancelled',
    name: '시공 취소',
    code: 'cancelled',
    element: <WorkList />,
  },
  {
    path: '/work/cancelled/:id',
    name: '시공 취소 상세',
    code: 'cancelled',
    element: <WorkDetailsPage />,
  },
  {
    path: '/work/ongoing',
    name: '진행중',
    code: 'ongoing',
    element: <WorkList />,
  },
  {
    path: '/work/ongoing/:id',
    name: '진행중 상세',
    code: 'ongoing',
    element: <WorkDetailsPage />,
  },
  {
    path: '/work/completed',
    name: '시공 완료',
    code: 'completed',
    element: <WorkList />,
  },
  {
    path: '/work/completed/:id',
    name: '시공 완료 상세',
    code: 'completed',
    element: <WorkDetailsPage />,
  },
  {
    path: '/work/calendar',
    name: '예약 캘린더',
    code: 'reservation',
    element: <Calendar />,
  },
  // ** 정산 관리

  {
    path: '/settlement/techs/queue',
    name: '숙련공 정산 대기',
    code: 'techs-queue',
    element: <SettlementForTechsQueue />,
  },
  {
    path: '/settlement/techs/queue/:id',
    name: '숙련공 정산 대기 상세',
    code: 'techs-queue',
    element: <SettlementForTechsQueue />,
  },
  {
    path: '/settlement/techs/history',
    name: '숙련공 정산 내역',
    code: 'techs-history',
    element: <SettlementForTechsHistory />,
  },
  {
    path: '/settlement/techs/history/:id',
    name: '숙련공 정산 내역 상세',
    code: 'techs-history',
    element: <SettlementForTechsHistory />,
  },
  {
    path: '/settlement/partner/queue',
    name: '파트너 정산 대기',
    code: 'partner-queue',
    element: <SettlementForPartnerQueue />,
  },
  {
    path: '/settlement/partner/queue/:id',
    name: '파트너 정산 대기 상세',
    code: 'partner-queue',
    element: <SettlementForPartnerQueue />,
  },
  {
    path: '/settlement/partner/history',
    name: '파트너 정산 내역',
    code: 'partner-history',
    element: <SettlementForPartnerHistory />,
  },
  {
    path: '/settlement/partner/history/:id',
    name: '파트너 정산 내역 상세',
    code: 'partner-history',
    element: <SettlementForPartnerHistory />,
  },

  // ** 미수금 관리
  {
    path: '/unpaid',
    name: '미수금 관리',
    code: 'unpaid',
    element: <Unpaid />,
  },
  {
    path: '/unpaid/:id',
    name: '미수금 관리 상세',
    code: 'unpaid',

    element: <Unpaid />,
  },
  // ** 회원 및 건물 관리
  {
    path: '/member/user',
    name: '회원 관리',
    code: 'user',
    element: <User />,
  },
  {
    path: '/member/user/edit/:id',
    name: '회원 수정',
    code: 'user',
    element: <EditUser />,
  },
  {
    path: '/member/user/:id',
    name: '회원 상세',
    code: 'user',
    element: <User />,
  },
  {
    path: '/member/partner',
    name: '파트너 관리',
    code: 'partner',
    element: <Partner />,
  },
  {
    path: '/member/partner/:id',
    name: '파트너 상세',
    code: 'partner',
    element: <Partner />,
  },
  {
    path: '/member/partner/new',
    name: '파트너 등록',
    code: 'partner',
    element: <EditPartner />,
  },
  {
    path: '/member/partner/edit/:id',
    name: '파트너 수정',
    code: 'partner',
    element: <EditPartner />,
  },
  {
    path: '/member/building',
    name: '건물 관리',
    code: 'building',
    element: <Building />,
  },
  {
    path: '/member/building/:id',
    name: '건물 상세',
    code: 'building',
    element: <Building />,
  },
  {
    path: '/member/building/new',
    name: '건물 등록',
    code: 'building',
    element: <EditBuilding />,
  },
  // ** 서비스 관리
  {
    path: '/services/area',
    name: '서비스지역 관리',
    code: 'area',

    element: <Area />,
  },
  {
    path: '/services/area/:id',
    name: '서비스 지역 상세',
    code: 'area',
    element: <Area />,
  },

  {
    path: '/services/setting',
    name: '서비스 설정',
    code: 'setting',
    element: <Setting />,
  },

  {
    path: '/services/funnel',
    name: '주문 유입 경로 설정',
    code: 'funnel',

    element: <Funnel />,
  },
  // ** 시스템 관리
  {
    path: '/system/admin',
    name: '관리자',
    code: 'admin',
    element: <Admin />,
  },
  {
    path: '/system/admin/:id',
    name: '관리자 상세',
    code: 'admin',
    element: <Admin />,
  },
  {
    path: '/system/techs',
    name: '숙련공 관리',
    code: 'techs',

    element: <Techs />,
  },
  {
    path: '/system/techs/:id',
    name: '숙련공 상세',
    code: 'techs',

    element: <Techs />,
  },
  {
    path: '/system/techs/new',
    name: '숙련공 등록',
    code: 'techs',
    element: <EditTechs />,
  },
  {
    path: '/system/techs/edit/:id',
    name: '숙련공 수정',
    code: 'techs',
    element: <EditTechs />,
  },

  {
    path: '/system/log',
    name: '작업 로그',
    code: 'log',

    element: <LogList />,
  },
  // ** 고객 지원
  {
    path: '/customer/coupon',
    name: '쿠폰',
    code: 'coupon',
    element: <Coupon />,
  },
  {
    path: '/customer/coupon/new',
    name: '쿠폰 발급',
    code: 'coupon',
    element: <CouponPublish />,
  },
  {
    path: '/customer/coupon/edit/:id',
    name: '쿠폰 수정',
    code: 'coupon',

    element: <CouponPublish />,
  },
  {
    path: '/customer/coupon/:id',
    name: '쿠폰 상세',
    code: 'coupon',

    element: <Coupon />,
  },
  {
    path: '/customer/point',
    name: '포인트',
    code: 'point',

    element: <Point />,
  },
  {
    path: '/customer/point/:id',
    name: '포인트 상세',
    code: 'point',

    element: <Point />,
  },
  {
    path: '/customer/point/publish',
    name: '포인트 발급',
    code: 'point',

    element: <PointPublish />,
  },
  {
    path: '/customer/event',
    name: '이벤트',
    code: 'event',
    element: <Event />,
  },
  {
    path: '/customer/event/:id',
    name: '이벤트 상세',
    code: 'event',

    element: <Event />,
  },
  {
    path: '/customer/event/publish',
    name: '이벤트 작성',
    code: 'event',

    element: <EventPublish />,
  },
  {
    path: '/customer/event/publish/:id',
    name: '이벤트 수정',
    code: 'event',

    element: <EventPublish />,
  },
  {
    path: '/customer/notice',
    name: '공지사항',
    code: 'notice',
    element: <Notice />,
  },
  {
    path: '/customer/notice/:id',
    name: '공지사항 상세',
    code: 'notice',
    element: <Notice />,
  },
  {
    path: '/customer/notice/publish',
    name: '공지사항 작성',
    code: 'notice',

    element: <NoticePublish />,
  },
  {
    path: '/customer/notice/publish/:id',
    name: '공지사항 수정',
    code: 'notice',

    element: <NoticePublish />,
  },
]
