import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const PeriodRatioBoxStyle = {
  Wrapper: styled.div`
    .title {
      ${typography['Body/Small/Bold']};
      color: ${colors['Grayscale/Gray Default']};
      margin-top: 13px;
      margin-bottom: 14px;
    }
  `,
  GraphWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 37px;
    .list {
      width: 118px;
      display: flex;
      flex-direction: column-reverse;
      gap: 8px;
      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${typography['Body/Large/Bold']};
        color: ${colors['Grayscale/Gray Default']};

        span {
          ${typography['Body/Caption/Bold']};
          color: ${colors['Grayscale/Gray Dark']};
        }
      }
    }
  `,
  ListDot: styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    ${addCssProps}
  `,
  RoundBoxWrapper: styled.div`
    padding: 13px 24px 8px;
    height: 122px;
    background-color: ${colors['Grayscale/Background Light']};
    border-radius: 12px;
    .title {
      margin-top: 0px;
    }
    .overflow {
      touch-action: none;
      overflow: auto;
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #a8b3c6;
        border-radius: 15px;
        border: 10px solid #dadfe8;
        background-clip: padding-box;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #a8b3c6;
        border-radius: 15px;
        border: 10px solid #dadfe8;
        background-clip: padding-box;
      }
    }
    .flex-row {
      display: flex;
      align-items: center;
      gap: 32px;
      height: 44px;
      margin-bottom: 8px;
      .box {
        flex-shrink: 0;
        display: flex;
        align-items: baseline;
        gap: 6px;

        ${typography['Headline/Bold']};
        color: ${colors['Grayscale/Gray Default']};

        span {
          ${typography['Body/Caption/Bold']};
          color: ${colors['Primary/Default']};
        }
      }
    }
  `,
}

export default PeriodRatioBoxStyle
