import { css } from '@emotion/react'
import { PrimaryButton } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import DownloadSpreadSheet from 'components/DownloadSpreadSheet'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import PeriodDropdownFilter from 'components/TableComponent/PeriodDropdownFilter'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  isTimeFilter,
} from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'

import { rebuildSpreadSheetDataForApi } from 'components/DownloadSpreadSheet/SelectFilterForSpreadSheet/SelectFilterForSpreadSheet'
import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import TableCell from 'components/TableComponent/TableCell'
import { TableRow } from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import useFetch from 'hooks/useFetch'
import useSearchFilter from 'hooks/useSearchFilter'
import { ApiZoneGroupItem } from 'pages/Services/Setting/Setting.types'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow } from 'themes/Shared.styles'
import { RESIDENT_TYPE_DROPDOWN_LIST } from 'types/services.type'
import {
  blobGetXlsx,
  cn,
  parserForPeriodQuery,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
} from 'utils/helpers'
import S from './BuildingList.styles'
import {
  ApiBuildingItem,
  BUILDING_QUERY_DROPDOWN_LIST,
  BuildingListProps,
} from './BuildingList.types'

// EDIT:THIS
type ResponseKeyName = {
  id: string
  groupName: string
  type: string
  buildingName: string
  address: string
  customerName: string
  partnerName: string
  createdAt: string
}

// EDIT:THIS
const responseParser = (response: ApiBuildingItem[]): ResponseKeyName[] => {
  const rebuild = response.map((item, index: number) => {
    return {
      id: String(item.id),
      groupName: item.zoneGroup?.name ?? '-',
      type: item.type,
      buildingName: item.name,
      address: item.fullLegalAddress,
      customerName: item.user?.name ?? '-',
      partnerName: item.partner?.name ?? '-',
      createdAt: dayjs(item.createdAt).format('YYYY-MM-DD'),
    } as ResponseKeyName
  })
  return rebuild
}
const defaultListTableConfigs: TableCellConfig<ResponseKeyName>[] = [
  {
    _type: 'dropdown',
    _id: 'groupName',
    _label: '지역그룹',
    _size: 120,
    _data: {},
    _value: '',
    _position: 'center',
  },
  {
    _type: 'dropdown',
    _id: 'type',
    _label: '건물 유형',
    _size: 100,
    _data: RESIDENT_TYPE_DROPDOWN_LIST,
    _value: '',
    _position: 'center',
  },
  {
    _type: 'default',
    _id: 'buildingName',
    _size: 160,
    _label: '건물명',
  },
  {
    _type: 'default',
    _id: 'address',
    _size: 'auto',
    _label: '도로명 주소',
  },
  {
    _type: 'default',
    _id: 'customerName',
    _size: 84,
    _label: '회원명',
  },
  {
    _type: 'default',
    _id: 'partnerName',
    _size: 84,
    _label: '파트너명',
  },
  {
    _type: 'order',
    _id: 'createdAt',
    _size: 160,
    _label: '가입일',
    _order: 'INITIAL',
    _position: 'left',
  },
]
const BuildingList = ({ _css, ...props }: BuildingListProps) => {
  const navigate = useNavigate()
  const topInfoRef = useRef() as MutableRefObject<HTMLDivElement>
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [tableHeaderOffset, set_tableHeaderOffset] = useState<number>(0)
  const [headerOffset, set_headerOffset] = useState<number>(0)
  const [buildingListTableConfigs, set_buildingListTableConfigs] = useState<
    TableCellConfig<ResponseKeyName>[]
  >(defaultListTableConfigs)

  const [groupList] = useFetch<DoubleDListWithPaging<ApiZoneGroupItem>>(
    'user',
    `/zone-group?take=9999`
  )
  const [response] = useFetch<DoubleDListWithPaging<ApiZoneGroupItem>>(
    'user',
    `/zone-group?take=9999`
  )
  const [serviceGroupDropdown, set_serviceGroupDropdown] =
    useState<DropdownListType | null>(null)

  useEffect(() => {
    if (groupList?.list) {
      set_serviceGroupDropdown({
        '지역 전체': 'all',
        ...groupList.list.reduce((prev, curr) => {
          prev[curr.name] = String(curr.id)
          return prev
        }, {} as DropdownListType),
      } as DropdownListType)
    }
  }, [groupList])
  const [stats] = useFetch<{
    total: number
    Apartment: number
    Officetel: number
    Villa: number
    House: number
  }>('user', `/building/stats`)
  useEffect(() => {
    if (response) {
      const localGroupDropdown = response.list.reduce((prev, curr) => {
        prev[curr.name] = curr.name
        return prev
      }, {} as { [key in string]: string })
      let rebuildConfigs = [
        ...defaultListTableConfigs.map((item) => {
          if (item._id === 'groupName' && item._type === 'dropdown') {
            item._data = {
              전체: '',
              ...localGroupDropdown,
            }
          }
          return item
        }),
      ]
      set_buildingListTableConfigs(rebuildConfigs)
    }
  }, [response])
  useEffect(() => {
    if (topInfoRef.current && headerRef.current) {
      const headerSize =
        topInfoRef.current.getBoundingClientRect().height +
        headerRef.current.getBoundingClientRect().height
      set_headerOffset(topInfoRef.current.getBoundingClientRect().height)
      set_tableHeaderOffset(headerSize)
    }
  }, [])

  const [selectedId, set_selectedId] = useState<string>('')

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: PERIOD_DROPDOWN_INITIAL_VALUE,
      queryList: BUILDING_QUERY_DROPDOWN_LIST,
    },
    (
      {
        startAt,
        endAt,
        month,
        year,
        periodType,
        searchType,
        searchValue,
        ...rest
      },
      queryName
    ) => {
      return {
        period: {
          start: startAt ? dayjs(startAt, 'YYYY-MM-DD') : null,
          end: endAt ? dayjs(endAt, 'YYYY-MM-DD') : null,
        },
        monthValue: month ?? '',
        yearValue: year ?? '',
        periodType: isTimeFilter(periodType) ? periodType : 'all',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    // filterList => query object로 파싱하세요. 주소 search query로 갑니다.
    ({ queryName = 'search', terms = '', ...rest }) => {
      return removeUndefined({
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        ...parserForPeriodQuery(rest),
      })
    }
  )

  const apiURI = useMemo(() => {
    // EDIT:THIS
    return `/building/list?take=10${
      window.location.search ? '&' + searchParams.toString() : ''
    }`
  }, [searchParams])

  return (
    <ContentsBox _type="relative" _css={css``}>
      {/* ContentsBox에서 스타일 상속 받음 */}
      <S.Wrap _css={_css} {...props}>
        {/* EDIT:THIS */}
        <TopInfo ref={topInfoRef}>
          <TopInfoRow
            _label={`전체`}
            _contents={`${thousand(stats?.total)}개`}
          />
          <TopInfoRow
            _label={`오피스텔`}
            _contents={`${thousand(stats?.Officetel)}개`}
          />
          <TopInfoRow
            _label={`아파트`}
            _contents={`${thousand(stats?.Apartment)}개`}
          />
          <TopInfoRow
            _label={`빌라`}
            _contents={`${thousand(stats?.Villa)}개`}
          />
          <TopInfoRow
            _label={`주택`}
            _contents={`${thousand(stats?.House)}개`}
          />
        </TopInfo>
        {/* EDIT:THIS */}
        <TableTitle
          ref={headerRef}
          _css={css`
            top: ${headerOffset}px;
          `}
          _left={`건물 리스트`}
          _right={
            <FlexRow gap={16}>
              <DownloadSpreadSheet
                _tableConfig={[
                  '건물 유형',
                  '건물명',
                  '도로명 주소',
                  '회원명',
                  '파트너명',
                  '등록일',
                ]}
                {...(serviceGroupDropdown && {
                  _dropdownFilters: [
                    {
                      label: '지역 선택',
                      key: 'zoneGroupId',
                      value: 'all',
                      list: serviceGroupDropdown,
                    },
                  ],
                })}
                _emitValue={async (value) => {
                  const rebuild = rebuildSpreadSheetDataForApi(value)
                  blobGetXlsx(
                    'user',
                    `/building/excel${rebuild.queryString.replace(
                      'zoneGroupId=all&',
                      ''
                    )}`,
                    '건물_목록'
                  )
                }}
              />
              <PrimaryButton
                _css={css`
                  width: 160px;
                  height: 40px;
                `}
                onClick={() => {
                  navigate(`/member/building/new`)
                }}
              >
                건물 등록
              </PrimaryButton>
            </FlexRow>
          }
        />
        {/* EDIT:THIS */}
        <FilterPart<PeriodDropdownValuesType>
          _value={initialValues}
          _queryNameList={BUILDING_QUERY_DROPDOWN_LIST}
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _FilterContents={({ _value, ...props }) => {
            return <PeriodDropdownFilter _value={_value} {...props} />
          }}
        />
        {/* EDIT:THIS */}
        <RoundFillBox>
          <TableComponent<ResponseKeyName>
            _type="user"
            _url={apiURI}
            _topOffset={tableHeaderOffset}
            _config={buildingListTableConfigs}
            _parser={(response: DoubleDListWithPaging<ApiBuildingItem>) => {
              const rebuild = responseParser(response.list)
              return {
                _list: rebuild,
                _cursor: disposeCursor(response.paging.cursor, 'cursor'),
              }
            }}
            _EmptyContent={`건물이 없습니다`}
            _ListContent={({ _row, _index }) => {
              const rowId = _row.id ? String(_row.id) : String(_index)
              return (
                <TableRow
                  className={cn({
                    active: selectedId === rowId,
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    set_selectedId(rowId)
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation()
                    // EDIT:THIS !
                    navigate(`/member/building/${rowId}`)
                  }}
                >
                  {buildingListTableConfigs.map((item, columnIndex) => {
                    // EDIT:THIS
                    const _id = item._id as keyof ResponseKeyName
                    const _value = _row[_id]
                    return (
                      <TableCell
                        key={'body' + _index + columnIndex}
                        _position={item._position}
                        _size={item._size}
                      >
                        <div>{_value}</div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrap>
    </ContentsBox>
  )
}
export default BuildingList
