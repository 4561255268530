import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { AdditionalCss, ColorKey } from 'themes/styles.type'
export type ChipsColorTitle =
  | 'primary'
  | 'green'
  | 'grayscale'
  | 'orange'
  | 'pink'
  | 'yellow'
type ChipsColorType = {
  _textColor: ColorKey
  _pointColor: ColorKey
}
const CHIPS_COLOR_VALUES = {
  primary: {
    _textColor: 'Primary/Dark',
    _pointColor: 'Primary/Default',
  },
  green: {
    _textColor: 'Success/Dark',
    _pointColor: 'Success/Default',
  },
  grayscale: {
    _textColor: 'Grayscale/Gray Dark',
    _pointColor: 'Grayscale/Gray Default',
  },
  orange: {
    _textColor: 'Accent/Dark',
    _pointColor: 'Accent/Default',
  },
  pink: {
    _textColor: 'Danger/Dark',
    _pointColor: 'Danger/Default',
  },
  yellow: {
    _textColor: 'Warning/Dark',
    _pointColor: 'Warning/Default',
  },
} as Record<ChipsColorTitle, ChipsColorType>

const ChipsStyle = {
  Chip: styled.div(
    ({
      _css,
      _color = 'grayscale',
    }: {
      _css?: AdditionalCss
      _color?: ChipsColorTitle
    }) => css`
      white-space: nowrap;
      cursor: pointer;
      border-radius: 20px;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      ${_color
        ? css`
            &.border:not(.type) {
              color: ${colors[CHIPS_COLOR_VALUES[_color]._textColor]};
              border: 1px solid
                ${colors[CHIPS_COLOR_VALUES[_color]._pointColor]};
              ${typography['Body/Small/Regular']}
              background-color: #fff;
              svg {
                path {
                  fill: ${colors[CHIPS_COLOR_VALUES[_color]._pointColor]};
                }
              }
            }
            &.fill:not(.border) {
              ${typography['Body/Small/Bold']}
              ${_color === 'grayscale'
                ? css`
                    color: ${colors['Grayscale/Gray Default']};
                    background-color: ${colors['Grayscale/Gray Lighter']};
                    svg {
                      path {
                        fill: ${colors['Grayscale/Gray Default']};
                      }
                    }
                  `
                : css`
                    color: #fff;
                    background-color: ${colors[
                      CHIPS_COLOR_VALUES[_color]._pointColor
                    ]};
                    svg {
                      path {
                        fill: #fff;
                      }
                    }
                  `}
            }
          `
        : ''}
      ${_css ? _css : ''}
    `
  ),
}

export default ChipsStyle
