import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import { AuthorizationDefault } from '../Authorization.styles'

const LoginComponentStyle = {
  Wrap: styled.div`
    ${AuthorizationDefault}
    ${addCssProps}
  `,
  Header: styled.div`
    padding: 32px;
    ${typography['Display/2 Bold']}
  `,
  Contents: styled.form`
    padding: 32px 32px 72px;
  `,
  Bottom: styled.div`
    padding: 0px 32px 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  PasswordContents: styled.div`
    padding: 32px 32px 40px;
    ${typography['Title/Regular']}
    color: ${colors['Grayscale/Gray Dark']};
    display: flex;
    flex-direction: column;
    gap: 72px;
    justify-content: space-between;
  `,
}
export default LoginComponentStyle
