import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'

const LogoPartStyle = {
  Wrap: styled.div`
    user-select: none;
    cursor: pointer;
    position: absolute;
    top: 106px;
    z-index: 2;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      width: 100%;
      max-width: 920px;
      padding: 0 32px;
    }
    ${addCssProps}
  `,
}
export default LogoPartStyle
