import { css } from '@emotion/react'
import {
  IconsServiceImage,
  ImagesServiceChevronRight,
  ImagesServicePlusSmall,
} from 'assets'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import Dropdown from 'components/Dropdown'
import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import QuillEditorComponent from 'components/QuillEditorComponent'
import { QUILL_DEFAULT_OPTIONS } from 'components/QuillEditorComponent/QuillEditorComponent'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import { useBlock } from 'hooks/useBlock'
import { useControlQuillUrls } from 'hooks/useControlQuillUrls'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import { sortBy } from 'lodash-es'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ContentRadiusBox,
  Flex,
  FlexColumn,
  FlexRow,
} from 'themes/Shared.styles'
import callAxios, { extractData, handleError } from 'utils/callAxios'
import { getFileToBlob, updateAndDeleteBehind } from 'utils/helpers'
import { parseQueryStringToObject } from 'utils/parsers'
import { callFileUpload } from 'utils/services'
import { ApiServiceCategory } from '../SelectCategory/SelectCategory.types'
import { SettingStyles } from '../Setting.styles'
import EtcServices from '../components/EtcServices'
import LimitReservation from '../components/LimitReservation'
import ServiceMaterials from '../components/ServiceMaterials'
import ServicePriceTable from '../components/ServicePriceTable'
import S from './ServiceManagement.styles'
import {
  ApiServiceManagement,
  ApiTip,
  CurrentIdsValue,
  SERVICE_PRICE_INITIAL_VALUE,
  ServiceManagementProps,
  checkServiceType,
  hasErrorMessage,
  parseForApiServiceValue,
  parserForServiceValue,
} from './ServiceManagement.types'

const CrumbsChain = ({
  categoryChain,
  ...props
}: {
  categoryChain: string[]
}) => {
  return (
    <>
      {categoryChain.map((chain, chainIndex) => {
        return (
          <Fragment key={'crumbs' + chain + chainIndex}>
            <span>{chain}</span>
            {chainIndex !== categoryChain.length - 1 && (
              <img src={ImagesServicePlusSmall} alt="plus" />
            )}
          </Fragment>
        )
      })}
    </>
  )
}
type CategoryDropdownType = {
  list: DropdownListType
  isFirst?: boolean
  isCategory?: boolean
}
const ServiceManagement = ({ _css, ...props }: ServiceManagementProps) => {
  const navigate = useNavigate()
  const [currentValue, set_currentValue] = useState<CurrentIdsValue | null>(
    null
  )
  const servicePriceValues = useMatchInput(SERVICE_PRICE_INITIAL_VALUE)
  const { inputs, handleInput, isMatched, syncCurrentValue } =
    servicePriceValues

  const dispatch = useDispatch()
  const { showPrompt, confirmNavigation, cancelNavigation } = useBlock(
    !isMatched
  )
  const [lastSelectedCategoryId, set_lastSelectedCategoryId] = useState<
    number | null
  >(null)
  const [files, set_files] = useState<string>('')
  const [selectedCategory, set_selectedCategory] = useState<
    (string | number)[]
  >([])
  const [searchParams, set_searchParams] = useSearchParams()
  const [tipList, set_tipList] = useState<ApiTip[]>([])
  const [serviceValues, set_serviceValues] =
    useState<ApiServiceManagement | null>(null)
  const [openModal, set_openModal] = useState<JSX.Element | null>(null)
  const [currentTipsId, set_currentTipsId] = useState<number | null>(null)
  const [categoryDropdown, set_categoryDropdown] = useState<
    CategoryDropdownType[]
  >([])
  const [response] = useFetch<ApiServiceManagement>(
    'order',
    `/service-product?subCategoryId=${currentValue?.lastCategoryId}&zoneGroupId=${currentValue?.zoneGroupId}`,
    {
      disabled:
        isNaN(Number(currentValue?.zoneGroupId)) ||
        isNaN(Number(currentValue?.lastCategoryId)) ||
        !currentValue?.lastCategoryId ||
        !currentValue?.zoneGroupId,
    }
  )

  const getTip = useMemo(() => {
    const currentType = currentValue?.type
    if (currentType !== 'main') return null
    const getTip = inputs.tips.find((item) => item.id === currentTipsId)
    return getTip
  }, [inputs.tips, currentTipsId, currentValue?.type])

  const { handleDeleteSharedImages } = useControlQuillUrls([
    ...inputs.tips.map((item) => item.subText),
    ...inputs.materials?.map((item) => item.quillHTML ?? ''),
  ])

  const tipsDropdownList = useMemo(() => {
    if (tipList.length > 0) {
      return tipList.reduce((prev, curr) => {
        prev[curr.title] = curr.id ?? ''
        return prev
      }, {} as DropdownListType)
    } else {
      return {}
    }
  }, [tipList])

  useEffect(() => {
    const fetchInit = servicePriceValues.fetchInit
    if (response) {
      set_serviceValues(response)
      const nextValue = parserForServiceValue(response)
      // fetch 받자마자 table row 정렬하여 저장
      const extractNumber = (str: string) => {
        const matches = str.match(/\d+/g)
        return matches ? parseInt(matches[0], 10) : null
      }
      const sortedTableRow = sortBy(nextValue.tableRow, [
        (option) =>
          extractNumber(option.dropdowns['h1'])
            ? extractNumber(option.dropdowns['h1'])
            : option.dropdowns['h1'],
        (option) =>
          extractNumber(option.dropdowns['h2'])
            ? extractNumber(option.dropdowns['h2'])
            : option.dropdowns['h2'],
        (option) =>
          extractNumber(option.dropdowns['h3'])
            ? extractNumber(option.dropdowns['h3'])
            : option.dropdowns['h3'],
        (option) =>
          extractNumber(option.dropdowns['h4'])
            ? extractNumber(option.dropdowns['h4'])
            : option.dropdowns['h4'],
      ])
      fetchInit({ ...nextValue, tableRow: sortedTableRow })
      set_tipList(response.tip)
    }
  }, [response, servicePriceValues.fetchInit])

  useEffect(() => {
    const { zoneGroupId, type, categoryId, lastCategoryId } =
      parseQueryStringToObject<{
        zoneGroupId: string
        type: string
        lastCategoryId: string
        categoryId?: string
      }>(searchParams.toString())
    if (zoneGroupId) {
      handleInput('zoneGroupId', Number(zoneGroupId))
    }
    set_currentValue((prev) => {
      if (zoneGroupId && type) {
        return {
          zoneGroupId,
          type,
          categoryId,
          lastCategoryId,
        }
      }
      return prev
    })
  }, [searchParams, handleInput])

  const getMainCategoryList = useCallback(async () => {
    if (!currentValue) return null
    if (!serviceValues) return null
    const res = await callAxios('order').get(
      `/category/list?zoneGroupId=${currentValue.zoneGroupId}&type=${currentValue.type}&userType=${serviceValues?.userType}`
    )
    return extractData<ApiServiceCategory[]>(res)
  }, [currentValue, serviceValues])

  const getCategoryList = useCallback(
    async (id: number, isCategory?: boolean) => {
      if (id < 0) return { list: {} }
      try {
        const res = await callAxios('order').get(
          `/subCategory/list?${
            isCategory ? `categoryId` : `subCategoryId`
          }=${id}`
        )
        const nextData = extractData<ApiServiceCategory[]>(res)
        if (nextData && nextData.length > 0) {
          return {
            list: nextData.reduce((prev, curr) => {
              if (prev[curr.name]) {
                prev[curr.name + '__' + curr.id] = curr.id
              } else {
                prev[curr.name] = curr.id
              }
              return prev
            }, {} as DropdownListType),
            isCategory,
          }
        } else {
          return { list: {} } as CategoryDropdownType
        }
      } catch (error) {
        handleError(error)
        return { list: {} } as CategoryDropdownType
      }
    },
    []
  )

  useEffect(() => {
    async function callGetCategory() {
      if (!currentValue) return
      if (!serviceValues) return
      try {
        const subCategories = serviceValues?.subCategoryArray
        let currentList: CategoryDropdownType[] = []
        const nextData = await getMainCategoryList()
        if (nextData && nextData.length) {
          currentList = [
            ...currentList,
            {
              list: nextData.reduce((prev, curr) => {
                prev[curr.name] = curr.id
                return prev
              }, {} as DropdownListType),
              isFirst: true,
            },
          ]
        }
        if (
          currentValue.type !== 'sub' &&
          subCategories &&
          subCategories.length
        ) {
          currentList = [
            ...currentList,
            ...(await Promise.all(
              sortBy(subCategories, (item) => item.order).map(
                (item, itemIndex) =>
                  getCategoryList(
                    item.parentId ?? serviceValues.category.id,
                    !item.parentId
                  )
              )
            )),
          ]
        }
        const categoryDropdownList = sortBy(
          subCategories,
          (item) => item.order
        ).map((item) => {
          return { [item.name]: item.id } as DropdownListType
        })
        const selectedCategories = categoryDropdownList.map(
          (item) => Object.values(item)[0]
        )
        set_selectedCategory(
          currentValue.type !== 'sub'
            ? [serviceValues.category.id, ...selectedCategories]
            : [...selectedCategories]
        )
        set_categoryDropdown(currentList)
      } catch (error) {
        handleError(error)
      }
    }
    const subCategories = serviceValues?.subCategoryArray
    if (subCategories && subCategories.length && currentValue) {
      callGetCategory()
    }
  }, [serviceValues, getCategoryList, currentValue, getMainCategoryList])

  const closeModal = () => {
    set_openModal(null)
  }

  const handleInActive = async () => {
    try {
      if (!response) return
      await callAxios('order').patch(
        `/service-product/${response.id}/${
          inputs.isActive ? `disable` : `enable`
        }`
      )
      if (inputs.isActive) {
        dispatch(setToast(['서비스가 활성화 되었습니다.', 'success']))
      } else {
        dispatch(setToast(['서비스가 비활성화 되었습니다.', 'error']))
      }
      handleInput('isActive', (prev) => !prev.isActive)
      closeModal()
    } catch (error) {
      handleError(error)
    }
  }

  const handleSubmit = async () => {
    if (!currentValue) return
    try {
      await handleDeleteSharedImages()
      const postData = parseForApiServiceValue(
        inputs,
        currentValue.type as 'sub' | 'main'
      )
      let url = response
        ? `/service-product/${response.id}/${currentValue.type}`
        : `/service-product`
      let method = response ? 'put' : ('post' as 'put' | 'post')
      if (hasErrorMessage(postData)) {
        dispatch(setToast([postData.message, 'error']))
      } else if (postData) {
        const res = await callAxios('order')[method](url, postData)
        if (res) {
          dispatch(setToast(['서비스가 배포 되었습니다.', 'success']))
          syncCurrentValue()
          if (method === 'post') {
            confirmNavigation(() => set_searchParams(''))
          }
        }
      }
    } catch (error) {
      handleError(error)
    }
  } //
  const handleCategoryDropdown = async (
    index: number,
    value: number | string
  ) => {
    if (typeof value === 'string') return
    set_selectedCategory((prev) => {
      return updateAndDeleteBehind(prev, index, value)
    })
    const currentDropdown = categoryDropdown[index]
    const nextValue = await getCategoryList(value, currentDropdown.isFirst)
    if (Object.keys(nextValue.list).length) {
      set_categoryDropdown((prev) => {
        return updateAndDeleteBehind(prev, index + 1, nextValue)
      })
    } else {
      set_lastSelectedCategoryId(value)
    }
  }

  const InActive = () => {
    return (
      <MiniPopup
        _title="서비스 비활성화"
        _buttonHandler={
          <>
            <GrayScaleFill onClick={closeModal}>취소</GrayScaleFill>
            <PrimaryButton onClick={handleInActive}>비활성화하기</PrimaryButton>
          </>
        }
      >
        해당 서비스를 비활성화 하시겠습니까?
        <br />
        비활성화하시면, 수수다앱에서 신청이 불가능하며
        <br />
        추후에 비활성화를 해제하실 수 있습니다.
      </MiniPopup>
    )
  }

  const ShowPrompt = useMemo(() => {
    return (
      <MiniPopup
        _title="페이지 이동"
        _buttonHandler={
          <FlexColumn gap={8}>
            <GrayScaleFill
              padding={`12px 20px`}
              width={`100%`}
              onClick={() => {
                closeModal()
                cancelNavigation()
              }}
            >
              페이지 머무르기
            </GrayScaleFill>
            <PrimaryButton
              padding={`12px 20px`}
              width={`100%`}
              onClick={() => {
                confirmNavigation()
              }}
            >
              저장하지 않고 나가기
            </PrimaryButton>
          </FlexColumn>
        }
      >
        변경하신 내용이 있습니다.
        <br />
        변경 내용 을 저장하지 않고 이동하시겠습니까?
      </MiniPopup>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (showPrompt) {
      set_openModal(ShowPrompt)
    } else {
      set_openModal(null)
    }
  }, [ShowPrompt, showPrompt])

  const handleThumbnailUpload = async (...files: File[]) => {
    const currentFile = files[0]
    if (!currentFile) return
    const fileId = await callFileUpload(
      currentFile,
      'order',
      '/service-product/icon'
    )
    if (fileId) {
      const blob = await getFileToBlob(currentFile)
      handleInput('icon', { id: fileId, url: blob })
    }
  }

  const trueWhenEmpty = useMemo(() => {
    if (currentValue?.type === 'main') {
      return false
    } else if (currentValue?.type === 'sub') {
      if (!inputs.icon?.id) return true
      if (!inputs.name) return true
      return false
    }
    return false
  }, [inputs, currentValue])
  if (!checkServiceType(currentValue?.type)) return <></>
  return (
    <>
      <Modal open={openModal !== null} onClose={closeModal}>
        {openModal}
      </Modal>
      <S.Wrap _css={_css} {...props}>
        <S.ContentsWrap>
          <S.TopButtons>
            {currentValue?.type === 'sub' &&
            !inputs.isActive &&
            currentValue?.categoryId ? (
              <>
                <PrimaryButton onClick={handleInActive}>
                  비활성화 해제
                </PrimaryButton>
              </>
            ) : (
              <>
                {currentValue?.type === 'sub' && currentValue.categoryId && (
                  <GrayScaleOutline
                    onClick={() => {
                      set_openModal(InActive)
                    }}
                  >
                    서비스 비활성화
                  </GrayScaleOutline>
                )}
                {currentValue?.type === 'sub' && !currentValue.categoryId && (
                  <GrayScaleOutline
                    onClick={() => {
                      navigate('/services/setting')
                    }}
                  >
                    작성 취소
                  </GrayScaleOutline>
                )}
                <PrimaryButton disabled={trueWhenEmpty} onClick={handleSubmit}>
                  {currentValue?.type === 'sub' ? '배포' : '저장'}
                </PrimaryButton>
              </>
            )}
          </S.TopButtons>
          <S.Content>
            <S.BreadCrumbs>
              <div className="group">
                <span className="label">서비스 지역</span>
                <span>{serviceValues?.zoneGroup.name}</span>
              </div>
              <img src={ImagesServiceChevronRight} alt="right" />
              <div className="group">
                <span className="label">
                  {currentValue?.type === 'sub' ? '기타 서비스' : '카테고리'}
                </span>
                <span>
                  {currentValue?.categoryId && serviceValues?.name
                    ? serviceValues?.name
                    : '신규 추가'}
                </span>
              </div>
              {serviceValues?.subCategoryArray && (
                <>
                  <img src={ImagesServiceChevronRight} alt="right" />
                  <div className="group">
                    <span className="label">분류</span>
                    <div className="category-chain">
                      <CrumbsChain
                        categoryChain={sortBy(
                          serviceValues?.subCategoryArray,
                          (item) => item.order
                        ).map((item) => item.name)}
                      />
                    </div>
                  </div>
                </>
              )}
            </S.BreadCrumbs>
            {currentValue?.categoryId && (
              <ContentRadiusBox>
                <Flex
                  gap={16}
                  justifyContent="space-between"
                  alignItems={'center'}
                >
                  <FlexRow
                    gap={16}
                    _css={css`
                      flex: 1;
                      flex-wrap: wrap;
                    `}
                  >
                    {categoryDropdown.map((dropdown, dropdownIndex) => {
                      return (
                        <Dropdown.Underline
                          _css={css`
                            min-width: 192px;
                          `}
                          key={'dropdown' + dropdownIndex}
                          _list={dropdown.list}
                          _parseLabel={(value) => {
                            if (value.includes('__')) {
                              return value.split('__')[0]
                            }
                            return value
                          }}
                          _value={selectedCategory[dropdownIndex]}
                          _emitValue={(value) => {
                            set_categoryDropdown((prev) => {
                              return updateAndDeleteBehind(
                                prev,
                                dropdownIndex + 1,
                                null
                              ).filter((ii) => !!ii) as CategoryDropdownType[]
                            })
                            set_lastSelectedCategoryId(null)
                            handleCategoryDropdown(dropdownIndex, value)
                          }}
                          _placeholder={'선택'}
                        />
                      )
                    })}
                  </FlexRow>
                  <GrayScaleOutline
                    disabled={
                      !lastSelectedCategoryId ||
                      String(lastSelectedCategoryId) ===
                        currentValue.lastCategoryId
                    }
                    width={'84px'}
                    height={'40px'}
                    onClick={() => {
                      if (!lastSelectedCategoryId) return
                      searchParams.set(
                        'lastCategoryId',
                        `${lastSelectedCategoryId}`
                      )
                      set_categoryDropdown([])
                      set_searchParams(searchParams.toString())
                    }}
                  >
                    조회
                  </GrayScaleOutline>
                </Flex>
              </ContentRadiusBox>
            )}
            <BoxLayout _title={'기본 정보 *'}>
              <FlexColumn gap={12}>
                {currentValue?.type === 'sub' && (
                  <ContentRadiusBox>
                    <FlexRow gap={24} width={'100%'}>
                      <SettingStyles.UploadImageWrap>
                        {inputs.icon ? (
                          <SettingStyles.Thumbnail
                            src={inputs.icon.url}
                            data-filename={
                              inputs.icon.url.split('/')[
                                inputs.icon.url.split('/').length - 1
                              ]
                            }
                            alt="current-material"
                            width={100}
                            height={100}
                          />
                        ) : (
                          <SettingStyles.UploadImage>
                            <IconsServiceImage />
                          </SettingStyles.UploadImage>
                        )}
                        <input
                          type="file"
                          multiple={false}
                          value={files}
                          accept={
                            'image/x-png, image/png, image/gif, image/jpeg'
                          }
                          onChange={async (e) => {
                            set_files(e.target.value)
                            const getFiles = e.target.files as FileList
                            if (getFiles) {
                              await handleThumbnailUpload(...getFiles)
                              setTimeout(() => {
                                set_files('')
                              }, 0)
                            }
                          }}
                        />
                      </SettingStyles.UploadImageWrap>
                      <UnderlineInput
                        _css={css`
                          width: 560px;
                        `}
                        value={inputs.name}
                        handleValue={(value) => {
                          handleInput('name', value)
                        }}
                        placeholder="서비스 이름 입력 (띄어쓰기 포함 8자 제한)"
                        maxLength={8}
                      />
                    </FlexRow>
                  </ContentRadiusBox>
                )}
                <ContentRadiusBox>
                  <BoxRowComponent _label={'양중비'}>
                    <div>
                      <UnderlineNumberInput
                        _css={css`
                          width: 436px;
                        `}
                        value={inputs.loadFees}
                        handleValue={(value) => {
                          handleInput('loadFees', value)
                        }}
                        placeholder="금액 입력"
                        suffix="원"
                      />
                    </div>
                  </BoxRowComponent>
                </ContentRadiusBox>
                {/* 서비스 가격 테이블 */}
                {currentValue && (
                  <ServicePriceTable
                    _currentValue={currentValue}
                    _categoryName={serviceValues?.category.name ?? ''}
                    {...servicePriceValues}
                  />
                )}
              </FlexColumn>
            </BoxLayout>
            {/* 기타 서비스 */}
            {currentValue && currentValue?.type === 'sub' && (
              <EtcServices {...servicePriceValues} />
            )}
            {/* 자재 */}
            {currentValue && currentValue.type === 'sub' && (
              <ServiceMaterials
                _currentValue={currentValue}
                {...servicePriceValues}
              />
            )}
            {/* 예약 제한 */}
            <LimitReservation {...servicePriceValues} />
            {currentValue?.type === 'sub' && (
              <BoxLayout _title={'Tip*'}>
                <QuillEditorComponent
                  _quillHTML={inputs.tips[0].subText ?? ''}
                  _css={css`
                    &.super-ql .ql-toolbar.ql-snow {
                      margin-top: 0px;
                    }
                  `}
                  _options={{
                    ...QUILL_DEFAULT_OPTIONS,
                    placeholder: '상세 내용 입력',
                  }}
                  _emitValue={(value) => {
                    handleInput('tips', (prev) => {
                      return [{ ...prev.tips[0], subText: value }]
                    })
                  }}
                />
              </BoxLayout>
            )}
            {response &&
              currentValue?.type === 'main' &&
              inputs.tips.length > 0 && (
                <BoxLayout _title={'Tip 설정'}>
                  <ContentRadiusBox>
                    <BoxRowComponent
                      _label={'안내 선택'}
                      _css={css`
                        max-width: 504px;
                      `}
                    >
                      <Dropdown.Underline
                        _value={currentTipsId}
                        _list={tipsDropdownList}
                        _emitValue={(value) => {
                          set_currentTipsId(Number(value))
                          handleInput('tips', (prev) => {
                            return prev.tips.map((item) =>
                              item.id === currentTipsId
                                ? {
                                    ...item,
                                    subText:
                                      prev.tips.find(
                                        (item) => item.id === value
                                      )?.subText ?? '',
                                  }
                                : item
                            )
                          })
                        }}
                      />
                    </BoxRowComponent>
                  </ContentRadiusBox>
                  {getTip && (
                    <QuillEditorComponent
                      _quillHTML={getTip.subText ?? ''}
                      _options={{
                        ...QUILL_DEFAULT_OPTIONS,
                        placeholder: '상세 내용 입력',
                      }}
                      _emitValue={(value) => {
                        handleInput('tips', (prev) => {
                          return prev.tips.map((item) =>
                            item.id === currentTipsId
                              ? { ...item, subText: value }
                              : item
                          )
                        })
                      }}
                    />
                  )}
                </BoxLayout>
              )}
          </S.Content>
        </S.ContentsWrap>
      </S.Wrap>
    </>
  )
}
export default ServiceManagement
