import { css } from '@emotion/react'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DraggablePopup from 'components/DraggablePopup'
import Dropdown from 'components/Dropdown'
import { useMemo, useState } from 'react'
import { cn, thousand } from 'utils/helpers'
import { MaterialPriceType } from '../AddEstimates.types'
import S from './SelectMaterialPopup.styles'
import { SelectMaterialPopupProps } from './SelectMaterialPopup.types'

const SelectMaterialPopup = ({
  _css,
  _onClose,
  _emitValue,
  _data,
  ...props
}: SelectMaterialPopupProps) => {
  const [selectedMaterial, set_selectedMaterial] =
    useState<MaterialPriceType | null>(null)

  const maxCountDropdown = useMemo(() => {
    if (!selectedMaterial) return null
    return Array.from(Array(selectedMaterial.maxQuantity).keys()).reduce(
      (prev, curr) => {
        prev[`${curr + 1}`] = curr + 1
        return prev
      },
      {} as { [key in string]: number }
    )
  }, [selectedMaterial])

  return (
    <>
      <DraggablePopup
        _title={'자재 정보 입력'}
        _onClose={_onClose}
        _css={css`
          & .drag-modal-contents {
            overflow: initial;
          }
        `}
        _open={true}
        _bottom={
          <>
            <GrayScaleFill onClick={_onClose}>취소</GrayScaleFill>
            <PrimaryButton
              disabled={
                !selectedMaterial ||
                (selectedMaterial && !selectedMaterial.count)
              }
              onClick={() => {
                if (!selectedMaterial) return
                _emitValue(selectedMaterial)
                _onClose()
              }}
            >
              확인
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <S.RowList>
            {_data.map((material) => {
              return (
                <S.MaterialRow
                  key={'material-item-' + material.id}
                  className={cn({
                    active: selectedMaterial?.id === material.id,
                  })}
                  onClick={() => {
                    set_selectedMaterial(material)
                  }}
                >
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${material.imageUrl})`,
                    }}
                  ></div>
                  <div className="box">
                    <div className="title">{material.name}</div>
                    <div className="option">
                      {thousand(material.price ?? 0)}원
                    </div>
                  </div>
                </S.MaterialRow>
              )
            })}
          </S.RowList>
          {maxCountDropdown && selectedMaterial && (
            <BoxRowComponent
              _label={'수량'}
              _css={css`
                padding: 0px 12px;
              `}
            >
              <Dropdown.Underline
                _list={maxCountDropdown}
                _value={selectedMaterial.count}
                _emitValue={(value) => {
                  set_selectedMaterial((prev) =>
                    prev !== null ? { ...prev, count: value } : prev
                  )
                }}
              />
            </BoxRowComponent>
          )}
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default SelectMaterialPopup
