import CouponDetails from 'pages/Support/Coupon/CouponDetails/CouponDetails'
import { CouponList } from 'pages/Support/Coupon/CouponList/CouponList'
import { useParams } from 'react-router-dom'

export interface CouponProps {}

const Coupon = (props: CouponProps) => {
  const { id } = useParams<'id'>()
  return (
    <>
      {id && <CouponDetails _id={id} />}
      {!id && <CouponList />}
    </>
  )
}
export default Coupon
