import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'

const AdminListStyle = {
  Wrapper: styled.div`
    ${addCssProps}
  `,
}

export default AdminListStyle
