import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { ProgressCircleProps } from 'components/ProgressCircle/ProgressCircle'
import { colors } from 'themes/styles'

/*
  progress circle size 계산 방법
  size = 100
  strokeWidth = 10

  center = size / 2 = 50
  radius = center - strokeWidth = 40
  
  progress = 0
  arcLength = 2 * 3.14(파이) * radius = 251.2
  arcOffset = arcLength * ((100 - progress)/100) = 62.8

*/

const ProgressCircleStyles = {
  Wrap: styled.div((props: Required<ProgressCircleProps>) => {
    const size = props._size
    const strokeWidth = props._strokeWidth
    const center = props._size / 2
    const radius = center - strokeWidth
    const arcLength = 2 * Math.PI * radius
    const arcOffset = arcLength * ((100 - props._progress) / 100)

    return css`
      & {
        width: ${size}px;
        height: ${size}px;
      }
      & .svg_indicator {
        width: ${size}px;
        height: ${size}px;
        transform: rotate(-90deg);
      }

      & .svg_indicator__track,
      & .svg_indicator__indication {
        cx: ${center}px;
        cy: ${center}px;
        r: ${radius}px;
        fill: transparent;
        stroke-width: ${strokeWidth}px;
      }
      & .svg_indicator__track {
        stroke: ${colors['Primary/Light']};
      }
      & .svg_indicator__indication {
        stroke: ${colors['Primary/Default']};
        stroke-dasharray: ${arcLength}px;
        stroke-dashoffset: ${arcOffset}px;
      }
    `
  }),
}

export default ProgressCircleStyles
