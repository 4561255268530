import { css } from '@emotion/react'
import { PrimaryButton, PrimaryOutlineButton } from 'components/Buttons'
import DetailsDefault from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import RadioButton from 'components/RadioButton'
import dayjs from 'dayjs'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import _ from 'lodash'
import { fetchCouponUsageHistory } from 'pages/Support/Coupon/CouponDetails/CouponDetails'
import { APICouponDetailData } from 'pages/Support/Coupon/CouponDetails/CouponDetails.types'
import { IssueMethod } from 'pages/Support/Coupon/CouponPublish/ByTarget/ByTarget'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import { ContentBoxWrap, FlexRow } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import TargetList from '../../components/TargetList'
import ByCode from './ByCode'
import ByTarget from './ByTarget'
import CouponCodeCount from './CouponCodeCount'
import {
  APICreateNewCoupon,
  APIEditCoupon,
  COUPON_DISCOUNT_TYPE,
  COUPON_PUBLISH_INITIAL_VALUE,
  COUPON_TARGET_TYPE,
  CouponMatchInput,
  PublishCouponProps,
} from './CouponPublish.types'
import DiscountByAmount from './DiscountByAmount'
import DiscountByPercent from './DiscountByPercent'

const PublishCoupon = ({ _css, ...props }: PublishCouponProps) => {
  const { id: couponId } = useParams<'id'>()
  const couponInputs = useMatchInput(COUPON_PUBLISH_INITIAL_VALUE)
  const { inputs, handleInput, fetchInit, isMatched } = couponInputs
  const [optionalInputs, set_optionalInputs] = useState<
    Array<keyof CouponMatchInput>
  >([])
  const trueWhenEmpty = useEmptyCheck(inputs, ...optionalInputs)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // ** 신규 발급
  // api : 새 쿠폰 생성
  const createNewCoupon = async (body: APICreateNewCoupon) => {
    try {
      await callAxios('order').post('/coupon', { ...body })
      dispatch(
        setToast({
          message: '쿠폰이 발행되었습니다.',
          type: 'success',
        })
      )
      navigate('/customer/coupon')
    } catch (e) {
      handleError(e)
    }
  }
  // ** 수정
  // api : 새 쿠폰 생성
  const editCoupon = async (body: APIEditCoupon) => {
    if (!couponId) return
    try {
      await callAxios('order').put(`/coupon/${couponId}`, body)
      dispatch(
        setToast({
          message: '수정되었습니다',
          type: 'success',
        })
      )
      navigate(`/customer/coupon/${couponId}`)
    } catch (e) {
      handleError(e)
    }
  }

  // 처음부터 전체를 다 불러와야해서 take = 10000으로 설정 (전체 중에서, 추가, 삭제 확인하기 위하여 )
  const COUPON_HISTORY_API_URL = useMemo(() => {
    return `/coupon/${couponId}/history?take=10000&type=unused`
  }, [couponId])
  // couponId 있을 경우(수정 모드일때)에만 fetch
  const [response] = useFetch<APICouponDetailData>(
    'order',
    `/coupon/${couponId}`,
    {
      disabled: !couponId,
    }
  )
  // api : 초기 쿠폰 사용 정보 불러오기
  useEffect(() => {
    if (!response) return
    const controller = new AbortController()
    const signal = controller.signal
    const parsedInput: CouponMatchInput = {
      couponId: couponId,
      startAt: dayjs(response.startAt),
      endAt: dayjs(response.endAt),
      name: response.name,
      isPercent: response.isPercent,
      amount: response.amount,
      minAmount: response.minAmount,
      maxAmount: response.maxAmount,
      categoryName: response.categoryName,
      target: response.target,
      code: response.code,
      issuedCount: response.issuedCount,
      usedCount: response.useCount,
      issueMethod: 'search',
      userList: [],
    }
    // 타겟 발급 일 경우 발급 대상자 목록 불러오기
    if (response.target === 'specify') {
      fetchCouponUsageHistory(
        COUPON_HISTORY_API_URL,
        signal,
        (extractedData) => {
          parsedInput['userList'] = extractedData.list.map((item) => ({
            id: item.userId,
            userName: item.userName,
            name: item.name,
            phone: item.phone,
          }))
          fetchInit(parsedInput)
        }
      )
    } else {
      fetchInit(parsedInput)
    }
    return () => controller.abort()
  }, [response, fetchInit, COUPON_HISTORY_API_URL, couponId])
  // ** 공통
  // 필수값 filled 체크
  useEffect(() => {
    const optionalAttr: Array<keyof CouponMatchInput> = [
      'issueMethod',
      'couponId',
      'usedCount',
    ]
    // 금액할인 vs 정률 할인
    if (!inputs.isPercent) optionalAttr.push('maxAmount')
    if (inputs.target === 'code') optionalAttr.push('userList')
    if (inputs.target === 'specify') optionalAttr.push('code', 'issuedCount')
    set_optionalInputs(optionalAttr)
  }, [inputs])

  // 화면 깜빡임 제거용
  if (couponId && !inputs.couponId) return <></>
  return (
    <>
      <DetailsDefault
        _css={css`
          .radius-box-gap {
            gap: 12px;
          }
        `}
        _title={'쿠폰 발급'}
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout
              _title={'쿠폰 정보'}
              _titleSuffix={
                <FlexRow gap={24} width="auto">
                  {COUPON_DISCOUNT_TYPE.map((item) => {
                    return (
                      <RadioButton
                        _disabled={!!couponId}
                        _mini
                        onClick={() => {
                          // 수정시엔 쿠폰 타입 수정 불가
                          if (couponId) return
                          handleInput('amount', null)
                          handleInput('isPercent', item === '정률 할인')
                        }}
                        _active={
                          item === '정률 할인'
                            ? inputs.isPercent
                            : !inputs.isPercent
                        }
                        key={item}
                      >
                        {item}
                      </RadioButton>
                    )
                  })}
                </FlexRow>
              }
            >
              {inputs.isPercent ? (
                <DiscountByPercent {...couponInputs} />
              ) : (
                <DiscountByAmount {...couponInputs} />
              )}
            </BoxLayout>
            <BoxLayout
              _title={
                inputs.target === 'code'
                  ? '쿠폰 발급 설정 '
                  : '발급 대상자 검색'
              }
              _titleSuffix={
                <FlexRow gap={24} width="auto">
                  {COUPON_TARGET_TYPE.map((item) => {
                    return (
                      <RadioButton
                        _mini
                        _disabled={!!couponId}
                        onClick={() => {
                          // 수정 모드에선 변경 불가
                          if (!!couponId) return
                          handleInput(
                            'target',
                            item === '코드 발급' ? 'code' : 'specify'
                          )
                        }}
                        _active={
                          inputs.target === 'code'
                            ? item === '코드 발급'
                            : item === '타겟 발급'
                        }
                        key={item}
                      >
                        {item}
                      </RadioButton>
                    )
                  })}
                </FlexRow>
              }
            >
              {inputs.target === 'code' ? (
                <ByCode {...couponInputs} />
              ) : (
                <ByTarget {...couponInputs} _couponId={couponId} />
              )}
            </BoxLayout>
          </ContentBoxWrap>
        }
        _rightContents={
          <ContentBoxWrap
            _css={css`
              padding-right: 0 !important;
              padding-bottom: 0 !important;
            `}
          >
            {inputs.target === 'code' ? (
              <CouponCodeCount {...couponInputs} />
            ) : (
              <TargetList
                {...couponInputs}
                _list={inputs.userList}
                _setList={(newList) => handleInput('userList', newList)}
              />
            )}
          </ContentBoxWrap>
        }
        _bottomRightControls={
          <S.Layout.RightBottom>
            <PrimaryOutlineButton
              _css={css`
                width: 160px;
                height: 48px;
              `}
              // disabled={selectedIds.length < 1}
              onClick={() => {
                // 편집모드일 때
                if (couponId) {
                  navigate(`/customer/coupon/${couponId}`)
                  return
                }
                // 신규발급일 때
                navigate('/customer/coupon')
              }}
            >
              취소
            </PrimaryOutlineButton>
            <PrimaryButton
              disabled={couponId ? trueWhenEmpty || isMatched : trueWhenEmpty}
              _css={css`
                flex: 1;
                height: 48px;
              `}
              onClick={() => {
                if (!couponId) {
                  const parsedData = parseInputToAPICreateNewCoupon(inputs)
                  createNewCoupon(parsedData)
                } else {
                  const parsedData = parseInputToAPIUpdateCoupon(inputs)
                  editCoupon(parsedData)
                }
              }}
            >
              {couponId ? '저장' : '쿠폰 발행'}
            </PrimaryButton>
          </S.Layout.RightBottom>
        }
      />
    </>
  )
}
// 타겟 발급 : 발급 대상자 개수가 발행 개수 |  코드 발급 : 발행 개수 직접 작성
const getIssuedCount = (value: CouponMatchInput) => {
  if (value.target === 'code') return value.issuedCount ?? 0
  return value.userList?.length || 0
}
// matchInput -> 쿠폰 생성 api 데이터로 파싱
const parseInputToAPICreateNewCoupon = (value: CouponMatchInput) => {
  let parsedData: APICreateNewCoupon = {
    startAt: value.startAt?.toISOString() ?? '',
    endAt: value.endAt?.toISOString() ?? '',
    name: value.name,
    isPercent: value.isPercent,
    amount: value.amount ?? 0,
    minAmount: value.minAmount ?? 0,
    maxAmount: value.maxAmount ?? undefined,
    categoryName: value.categoryName,
    target: value.target,
    issuedCount: getIssuedCount(value),
    code: value.code,
    userList: value.userList.length > 0 ? value.userList : undefined,
  }
  parsedData = _.omit(parsedData, [
    ...extractUselessAttr(parsedData, value.issueMethod),
  ]) as APICreateNewCoupon
  return parsedData
}
// matchInput -> 쿠폰 수정 api 데이터로 파싱
const parseInputToAPIUpdateCoupon = (
  value: CouponMatchInput
): APIEditCoupon => {
  let parsedData: APICreateNewCoupon = {
    startAt: value.startAt?.toISOString() ?? '',
    endAt: value.endAt?.toISOString() ?? '',
    name: value.name,
    amount: value.amount ?? 0,
    minAmount: value.minAmount ?? 0,
    maxAmount: value.maxAmount ?? 0,
    categoryName: value.categoryName,
    issuedCount: getIssuedCount(value),
    userList: value.userList,
    isPercent: false,
    code: null,
    target: 'specify',
  }
  const result = _.omit(parsedData, [
    ...extractUselessAttr(parsedData, value.issueMethod),
    'isPercent',
    'excelFileName',
    'code',
    'target',
  ]) as APIEditCoupon

  return result
}

// 각 옵션 선택값에 따른 불필요한 속성들 추출
const extractUselessAttr = (
  value: APICreateNewCoupon,
  issueMethod: IssueMethod
) => {
  const uselessAttr: Array<keyof APICreateNewCoupon> = []
  if (!value.isPercent) uselessAttr.push('maxAmount')
  if (value.target === 'code') uselessAttr.push('userList')
  if (value.target === 'specify') uselessAttr.push('code')
  return uselessAttr
}

export default PublishCoupon
