import styled from '@emotion/styled/macro'
import { buttonAssets } from 'components/Buttons/Buttons.styles'
import { typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const PhotoUploadBoxStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  Label: styled.label`
    white-space: nowrap;
    padding: 7px 0;
    display: block;
    button {
      ${typography['Body/Small/Bold']}
    }
  `,
  Upload: styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    ${buttonAssets({
      _mini: true,
      disabledType: 'fill',
      borderColor: 'Primary/Default',
      backgroundColor: 'White/White off',
      hoverBackgroundColor: 'Grayscale/Background Dark',
      textColor: 'Primary/Default',
    })}
    padding: 5px 12px;
    border-radius: 8px;
  `,
}

export default PhotoUploadBoxStyle
