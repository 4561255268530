import { cn } from 'utils/helpers'
import S from './RowTab.styles'
import { RowTabProps } from './RowTab.types'

const RowTab = <T extends readonly string[]>({
  _css,
  _tab,
  _tabs,
  _emitValue,
  _type = 'line',
  className,
  ...props
}: RowTabProps<T>) => {
  return (
    <>
      <S.Wrap _css={_css} className={cn(className, `tab-${_type}`)} {...props}>
        {_tabs.map((tab) => {
          return (
            <S.Tab
              key={tab}
              className={cn({ selected: tab === _tab }, `tab-${_type}`)}
              onClick={() => {
                if (_emitValue) _emitValue(tab)
              }}
            >
              {tab}
            </S.Tab>
          )
        })}
      </S.Wrap>
    </>
  )
}
export default RowTab
