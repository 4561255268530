import { css } from '@emotion/react'
import { ImagesDetailsProfile } from 'assets'
import Badge from 'components/Badge'
import { BADGE_COLOR } from 'components/Badge/Badge'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import RadioButton from 'components/RadioButton'
import TextArea from 'components/Textarea/Textarea'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineFormatInput, {
  getFormats,
} from 'components/UnderlineInput/UnderlineFormatInput'
import {
  ContentBoxWrap,
  ContentProfileBox,
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
  readOnlyCss,
} from 'themes/Shared.styles'
import { ModifyUserProfilesProps } from './ModifyUserProfiles.types'

const ModifyUserProfiles = ({
  _css,
  inputs,
  handleInput,
  handleValues,
  ...props
}: ModifyUserProfilesProps) => {
  return (
    <>
      <ContentBoxWrap>
        <ContentProfileBox>
          <div className="wrap">
            <FlexColumn gap={12} alignItems="center">
              <div
                data-profile
                style={{
                  backgroundImage: `url(${
                    inputs.profileImage
                      ? inputs.profileImage
                      : ImagesDetailsProfile
                  })`,
                }}
              />
              <div>
                <div className="name">{inputs.name}</div>
                <div className="phone">{inputs.phone}</div>
                <FlexRow gap={4} className="badges" justifyContent={'center'}>
                  <Badge
                    {...(inputs.userType === 'CUSTOMER'
                      ? BADGE_COLOR.PrimaryLight
                      : BADGE_COLOR.SuccessLight)}
                  >
                    {inputs.userType === 'CUSTOMER' ? '일반 회원' : '정기 회원'}
                  </Badge>
                </FlexRow>
              </div>
            </FlexColumn>
          </div>
        </ContentProfileBox>
        <BoxLayout _title={'기본 정보'}>
          <ContentRadiusBox gap={12}>
            <BoxRowComponent _label={'이름'}>
              <UnderlineInput
                {...readOnlyCss}
                value={inputs.name}
                handleValue={(value) => handleInput('name', value)}
              />
            </BoxRowComponent>
            <BoxRowComponent _label={'휴대폰 번호'}>
              <UnderlineFormatInput
                {...readOnlyCss}
                format={getFormats('phone', inputs.phone)}
                value={inputs.phone?.replace(/-/g, '') ?? ''}
                handleValue={(value) => handleInput('phone', value)}
              />
            </BoxRowComponent>
            <BoxRowComponent _label={'아이디'}>
              <UnderlineInput
                {...readOnlyCss}
                value={inputs.userId}
                handleValue={(value) => handleInput('userId', value)}
              />
            </BoxRowComponent>
            <BoxRowComponent _label={'성별'}>
              <RadioButton
                _mini
                _active={inputs.gender === 'M'}
                _disabled={true}
              >
                남
              </RadioButton>
              <RadioButton
                _mini
                _active={inputs.gender === 'F'}
                _disabled={true}
              >
                여
              </RadioButton>
            </BoxRowComponent>
            {inputs.userType === 'REGULAR' && (
              <>
                <BoxRowComponent _label={'주민등록번호'}>
                  <UnderlineFormatInput
                    format={getFormats('residentNumber')}
                    placeholder="주민등록번호 입력"
                    value={inputs.residentNumber?.replace(/-/g, '') ?? ''}
                    handleValue={(value) =>
                      handleInput('residentNumber', value)
                    }
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'계좌번호'}>
                  <UnderlineInput
                    placeholder="(예) 국민 1234567890"
                    value={inputs.accountNumber}
                    handleValue={(value) => handleInput('accountNumber', value)}
                  />
                </BoxRowComponent>
              </>
            )}
            <BoxRowComponent _label={'이메일'}>
              <UnderlineInput
                placeholder="이메일 입력"
                value={inputs.email}
                handleValue={(value) => handleInput('email', value)}
              />
            </BoxRowComponent>
          </ContentRadiusBox>
        </BoxLayout>
        <BoxLayout _title={'기타 정보'}>
          <ContentRadiusBox>
            <BoxRowComponent _label={'비고'} className="flex-start">
              <TextArea
                placeholder="내용 입력"
                _css={css`
                  border: none;
                  border-radius: 0px;
                  padding: 10px 0;
                  min-height: 120px;
                `}
                value={inputs.etc.remarks}
                onChange={(e) =>
                  handleInput('etc', (prev) => ({
                    ...prev.etc,
                    remarks: e.target.value,
                  }))
                }
              />
            </BoxRowComponent>
          </ContentRadiusBox>
        </BoxLayout>
      </ContentBoxWrap>
    </>
  )
}
export default ModifyUserProfiles
