import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const ImageWithLoadingStyle = {
  Wrap: styled.div`
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    &.contain {
      background-size: contain;
    }
    &.cover {
      background-size: cover;
    }
    &.isError {
      width: 300px;
      height: 300px;
    }
    position: relative;
    .createdAt {
      position: absolute;
      bottom: 8px;
      right: 8px;
      ${typography['Body/Small/Bold']}
      color: ${colors['White/White off']};
      background-color: ${colors['Grayscale/Black']};
      padding: 2px 8px;
    }
    ${addCssProps}
  `,
}

export default ImageWithLoadingStyle
