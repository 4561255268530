import { css } from '@emotion/react'
import { IconsDangerCircle, ImagesInputRemove } from 'assets'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import { MutableRefObject, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setToast } from 'store/toastSlice'
import { FlexRow, TXT } from 'themes/Shared.styles'
import { DoubleDFile } from 'types/services.type'
import callAxios, { extractData, handleError } from 'utils/callAxios'
import { HandleEllipsisToolTip, getFileToBlob, uuid } from 'utils/helpers'
import { callFileUpload } from 'utils/services'
import S from './ApiFileUploadBox.styles'
import { ApiFileUploadBoxProps, FILE_TYPE } from './ApiFileUploadBox.types'

const ApiFileUploadBox = ({
  _css,
  _fileList,
  _emitValue,
  _uploadState,
  _type,
  _url,
  _fileType,
  _multiple,
  _btnText,
  _btnWidth = 86,
  _progress = 100,
  _placeholder = '등록된 파일 없음',
  _optionValue,
  _emitExcelUserList,
  _method = 'replace',
  _maxLength,
  ...props
}: ApiFileUploadBoxProps) => {
  const [files, set_files] = useState<string>('')
  const [progress, set_progress] = useState<number>(0)
  const [uploadState, set_uploadState] = useState<
    'progress' | 'success' | 'error' | 'idle'
  >('idle')
  const uuidRef = useRef('fileUpload-' + uuid()) as MutableRefObject<string>
  const dispatch = useDispatch()
  // 파일 업로드 핸들러 except 엑셀
  const handleUpload = async (...fileList: File[]) => {
    try {
      const uploadedList = (
        await Promise.all(
          fileList
            .map(async (file, index) => {
              const fileName = await callFileUpload(
                file,
                _type,
                _url,
                _optionValue
              )
              const blob = await getFileToBlob(file)
              if (fileName) {
                // file 하나 업로드 완료시 progress 업데이트
                const progress = ((index + 1) / fileList.length) * 100
                set_progress(Math.round(progress))
                return {
                  id: fileName ?? '',
                  name: fileName ?? '',
                  url: blob,
                }
              } else {
                return null
              }
            })
            .filter((ii) => !!ii)
        )
      ).filter((ii) => !!ii) as DoubleDFile[]
      if (uploadedList.length > 0) {
        if (_method === 'append') {
          _emitValue([..._fileList, ...uploadedList])
        } else {
          _emitValue(uploadedList)
        }
      }
    } catch (error) {
      handleError(error)
    }
  }

  // 엑셀 업로드 후 엑셀 데이터를 배열 형태로 받아 emit
  const handleExcelUpload = async (...fileList: File[]) => {
    const excelFile = fileList[0]
    const formData = new FormData()
    formData.append('file', excelFile)
    try {
      set_uploadState('progress')
      // 엑셀 파일에서 유저 리스트 추출
      if (_emitExcelUserList) {
        const res = await callAxios(_type).post(_url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              let percentage =
                (progressEvent.loaded / progressEvent.total) * 100
              set_progress(Math.round(percentage))
            }
          },
        })
        if (res.status === 201) {
          _emitExcelUserList(extractData(res))
          const blob = await getFileToBlob(excelFile)
          _emitValue([
            {
              id: excelFile.name,
              name: excelFile.name,
              url: blob,
            },
          ])
          set_uploadState('success')
        }
      } else {
        // 단순히 엑셀 파일 return
        const blob = await getFileToBlob(excelFile)
        _emitValue([
          {
            id: excelFile.name,
            name: excelFile.name,
            url: blob,
            file: excelFile,
          },
        ])
        set_uploadState('success')
      }
    } catch (e) {
      set_uploadState('error')
      handleError(e)
    }
  }

  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <S.InputWrap>
          {/* <S.Label htmlFor={uuidRef.current}> */}
          <label htmlFor={uuidRef.current}>
            <S.Upload
              _css={css`
                width: ${_btnWidth + 'px'};
              `}
            >
              {_btnText ? _btnText : '업로드'}
            </S.Upload>
          </label>
          <input
            id={uuidRef.current}
            type="file"
            multiple={_multiple}
            value={files}
            accept={FILE_TYPE[_fileType]}
            onChange={async (e) => {
              set_files(e.target.value)
              const getFiles = e.target.files as FileList
              if (
                _maxLength &&
                _maxLength < getFiles.length + _fileList.length
              ) {
                dispatch(
                  setToast([
                    `최대 파일 개수는 ${_maxLength}개 입니다.`,
                    'error',
                  ])
                )
                return
              }
              if (getFiles) {
                // 파일 처리 시작 전 uploadState progress로 변경
                _fileType === 'excel'
                  ? await handleExcelUpload(...getFiles)
                  : await handleUpload(...getFiles)
                setTimeout(() => {
                  set_files('')
                }, 0)
              }
            }}
          />
          {/* </S.Label> */}
        </S.InputWrap>

        {_fileList.length ? (
          <S.Files
            _css={css`
              max-width: calc(100% - ${_btnWidth}px - 24px);
            `}
          >
            {_fileList.map((item) => {
              return (
                <FlexRow
                  _css={css`
                    position: relative;
                    width: 100%;
                  `}
                  key={item.id}
                  gap={10}
                  alignItems="center"
                  onMouseEnter={(e) => HandleEllipsisToolTip('mouseEnter', e)}
                  onMouseLeave={(e) => HandleEllipsisToolTip('mouseLeave', e)}
                >
                  {uploadState === 'error' ? (
                    <S.UploadStateBox>
                      <FlexRow gap={12}>
                        <IconsDangerCircle />
                        업로드 실패
                      </FlexRow>
                    </S.UploadStateBox>
                  ) : uploadState === 'progress' ? (
                    <S.UploadStateBox>
                      <FlexRow gap={12}>
                        <ProgressCircle _progress={progress ?? 0} />
                        업로드중...
                      </FlexRow>
                    </S.UploadStateBox>
                  ) : (
                    <>
                      <S.File>{item.name}</S.File>
                      <img
                        src={ImagesInputRemove}
                        alt="text remove"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          _emitValue([
                            ..._fileList.filter((file) => {
                              return file.id !== item.id
                            }),
                          ])
                        }}
                      />
                    </>
                  )}
                </FlexRow>
              )
            })}
          </S.Files>
        ) : (
          <TXT
            _textStyle="Body/Large/Bold"
            _color="Grayscale/Gray Light"
            _css={css`
              padding: 10px 0;
            `}
          >
            {_placeholder}
          </TXT>
        )}
      </S.Wrap>
    </>
  )
}
export default ApiFileUploadBox
