import { ConstructionValuesType } from 'pages/Work/Work.types'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export const CONSTRUCTION_INITIAL_VALUE: ConstructionValuesType = {
  id: null,
  title: '',
  사진: [],
  '360도 사진': [],
}

type GalleryRequiredId = {
  taskId?: string
  householdsId?: string
}

export interface ConstructionInfoProps extends DivAttributes {
  _css?: AdditionalCss
  _open: boolean
  _onClose: () => void
  _emitValue: (value: ConstructionValuesType) => void
  _id?: number | string
  _galleryId: GalleryRequiredId
  _item?: ConstructionValuesType
  _type: '시공전 사진' | '시공후 사진'
}
