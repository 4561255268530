import S from './ContentsBox.styles'
import { ContentsBoxProps } from './ContentsBox.types'

const ContentsBox = ({
  _css,
  _type = 'absolute',
  children,
  ...props
}: ContentsBoxProps) => {
  return (
    <>
      {_type === 'absolute' && (
        <S.AbsoluteBox _css={_css} {...props}>
          {children}
        </S.AbsoluteBox>
      )}
      {_type === 'relative' && (
        <S.RelativeBox _css={_css} {...props}>
          {children}
        </S.RelativeBox>
      )}
    </>
  )
}
export default ContentsBox
