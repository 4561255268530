import { css } from '@emotion/react'
import Badge from 'components/Badge'
import { PrimaryButton } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import DownloadSpreadSheet from 'components/DownloadSpreadSheet'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import PeriodDropdownFilter from 'components/TableComponent/PeriodDropdownFilter'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  isPeriod,
  isTimeFilter,
} from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import TableCell from 'components/TableComponent/TableCell'
import T from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import useSearchFilter from 'hooks/useSearchFilter'
import { COUPON_TARGET_TYPE_BADGE_COLOR } from 'pages/Support/Coupon/Coupon.types'
import {
  APICouponListRow,
  COUPON_QUERY_DROPDOWN_LIST,
  COUPON_TARGET_TYPE_DROPDOWN_LIST,
  CouponListItemType,
  isCouponCount,
} from 'pages/Support/Coupon/CouponList/CouponList.types'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow, TXT } from 'themes/Shared.styles'
import {
  blobGetXlsx,
  cn,
  isBetweenPeriod,
  isObjectKeyType,
  parserForPeriodQuery,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
} from 'utils/helpers'

import { rebuildSpreadSheetDataForApi } from 'components/DownloadSpreadSheet/SelectFilterForSpreadSheet/SelectFilterForSpreadSheet'
import useFetch from 'hooks/useFetch'
import S from './CouponList.styles'

const CouponListTableConfigs: TableCellConfig<CouponListItemType>[] = [
  {
    _type: 'dropdown',
    _id: 'type',
    _label: '쿠폰 유형',
    _data: COUPON_TARGET_TYPE_DROPDOWN_LIST,
    _size: 106,
  },
  {
    _type: 'order',
    _id: 'dateOrder',
    _label: '유효 기간',
    _order: 'DESC',
    _isNumber: true,
    _size: 250,
  },
  {
    _type: 'default',
    _id: 'couponName',
    _label: '쿠폰명',
    _size: 326,
  },
  {
    _type: 'default',
    _id: 'code',
    _label: '쿠폰 코드',
    _size: 160,
  },
  {
    _type: 'default',
    _id: 'issuerName',
    _label: '발급자명',
    _size: 84,
  },
  {
    _type: 'default',
    _id: 'discount',
    _label: '할인 금액/할인율',
    _size: 120,
  },
  {
    _type: 'default',
    _id: 'count',
    _label: '사용/발급 수',
    _position: 'right',
    _size: 120,
  },
]
// api 데이터 parser
const parserForCouponListTable = (
  response: DoubleDListWithPaging<APICouponListRow>
) => {
  const rebuild = response.list.map((row: APICouponListRow, index: number) => {
    return {
      id: row.id.toString(),
      type: row.target === 'code' ? '코드발급' : '회원지정',
      dateOrder: Number(dayjs(row.startAt).format('YYYYMMDD')),
      validDate: {
        start: dayjs(row.startAt),
        end: dayjs(row.endAt),
      },
      couponName: row.name,
      code: row.code,
      issuerName: row.issuerName,
      discount: row.amount.toString(),
      count: {
        used: row.useCount,
        total: row.issuedCount,
      },
      isPercent: row.isPercent,
    } as CouponListItemType
  })
  return {
    _list: rebuild,
    _cursor: disposeCursor(response.paging.cursor),
  }
}

export interface CouponListProps {}

export const CouponList = (props: CouponListProps) => {
  const [selectedId, set_selectedId] = useState<string>('')
  const navigate = useNavigate()

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: PERIOD_DROPDOWN_INITIAL_VALUE,
      queryList: COUPON_QUERY_DROPDOWN_LIST,
    },
    ({
      startAt,
      endAt,
      month,
      year,
      periodType,
      searchType,
      searchValue,
      ...rest
    }) => {
      return {
        period: {
          start: startAt ? dayjs(startAt, 'YYYY-MM-DD') : null,
          end: endAt ? dayjs(endAt, 'YYYY-MM-DD') : null,
        },
        monthValue: month ?? '',
        yearValue: year ?? '',
        periodType: isTimeFilter(periodType) ? periodType : 'all',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    ({ queryName = 'search', terms = '', ...rest }) => {
      return removeUndefined({
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        ...parserForPeriodQuery(rest),
      })
    }
  )
  const apiURI = useMemo(() => {
    return `/coupon/list?take=10${
      window.location.search ? '&' + searchParams.toString() : ''
    }`
  }, [searchParams])

  const [stats] = useFetch<{ issued: number; used: number }>(
    'order',
    `/coupon/stats`
  )
  return (
    <ContentsBox _type="relative">
      <S.Wrapper>
        {/* TopInfo */}
        <TopInfo>
          <TopInfoRow
            _label={'발행 수'}
            _contents={`${thousand(stats?.issued)}개`}
          />
          <TopInfoRow
            _label={'사용 수'}
            _contents={`${thousand(stats?.used)}개`}
          />
        </TopInfo>
        {/* TableTitle */}
        <TableTitle
          _left={'쿠폰 리스트'}
          _right={
            <FlexRow gap={16}>
              <DownloadSpreadSheet
                _emitValue={async (value) => {
                  const rebuild = rebuildSpreadSheetDataForApi(value)
                  blobGetXlsx(
                    'order',
                    `/coupon/list/excel${rebuild.queryString}`,
                    '쿠폰_목록'
                  )
                }}
                _noPeriod
                _dropdownFilters={[
                  {
                    label: '필터',
                    value: '전체',
                    key: 'status',
                    list: {
                      전체: '전체',
                      '회원 지정': '회원 지정',
                      '코드 발급': '코드 발급',
                    },
                  },
                ]}
                _tableConfig={[
                  '쿠폰 유형',
                  '유효 기간',
                  '쿠폰명',
                  '쿠폰코드',
                  '발급자명',
                  '할인금액/할인율',
                  '사용/발급 수',
                ]}
              />
              <PrimaryButton
                onClick={() => navigate('/customer/coupon/new')}
                _css={css`
                  width: 180px;
                  height: 40px;
                `}
              >
                쿠폰 발급
              </PrimaryButton>
            </FlexRow>
          }
        />
        {/* Filter */}
        <FilterPart<PeriodDropdownValuesType>
          _value={initialValues}
          _placeholder="쿠폰 제목 검색"
          _queryNameList={COUPON_QUERY_DROPDOWN_LIST}
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _FilterContents={({ _value, ...props }) => {
            return <PeriodDropdownFilter _value={_value} {...props} />
          }}
        />
        {/* Table */}
        <RoundFillBox>
          <TableComponent<CouponListItemType>
            _config={CouponListTableConfigs}
            _type="order"
            _url={apiURI}
            _parser={parserForCouponListTable}
            _ListContent={({ _row, _index }) => {
              const rowId = _row.id ? String(_row.id) : String(_index)
              return (
                <T.Row
                  className={cn({
                    active: selectedId === rowId,
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    set_selectedId(rowId)
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation()
                    navigate(`/customer/coupon/${rowId}`)
                  }}
                >
                  {CouponListTableConfigs.map((item, columnIndex) => {
                    const _id = item._id
                    const _value = _row[item._id]

                    return (
                      <TableCell
                        key={'body' + _index + columnIndex}
                        _size={item._size}
                      >
                        {_id === 'type' &&
                        isObjectKeyType(
                          COUPON_TARGET_TYPE_BADGE_COLOR,
                          _value
                        ) ? (
                          <>
                            <Badge {...COUPON_TARGET_TYPE_BADGE_COLOR[_value]}>
                              {_value}
                            </Badge>
                          </>
                        ) : _id === 'dateOrder' &&
                          isPeriod(_row['validDate']) ? (
                          <div>
                            {isBetweenPeriod(
                              dayjs(),
                              _row['validDate'].start,
                              _row['validDate'].end
                            ) === '종료'
                              ? '만료됨'
                              : `${_row['validDate'].start?.format(
                                  'YYYY-MM-DD(ddd)'
                                )} ~ ${_row['validDate'].end?.format(
                                  'YYYY-MM-DD(ddd)'
                                )}`}
                          </div>
                        ) : _id === 'count' && isCouponCount(_value) ? (
                          <TXT
                            _textStyle="Body/Small/Bold"
                            _color="Grayscale/Black light"
                          >
                            {_value.used}/{_value.total}
                          </TXT>
                        ) : _id === 'discount' && typeof _value === 'string' ? (
                          <div>
                            {thousand(_value)}
                            {_row.isPercent ? '%' : '원'}
                          </div>
                        ) : (typeof _value == 'number' ||
                            typeof _value === 'string') &&
                          _value ? (
                          <div>{_value}</div>
                        ) : (
                          <div>-</div>
                        )}
                      </TableCell>
                    )
                  })}
                </T.Row>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrapper>
    </ContentsBox>
  )
}
