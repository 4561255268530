import { GrayScaleOutline } from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'

import S from 'components/DetailsComponents/DetailsComponents.styles'
import { useGallery } from 'components/Gallery/Gallery.hooks'
import { MatchInputProps } from 'hooks/useMatchInput'
import {
  OrderStateType,
  ServiceDetailsValuesType,
  ServiceRowListType,
} from 'pages/Work/Work.types'
import { useDispatch } from 'react-redux'
import { setCurrentGallery } from 'store/gallerySlice'
import { ContentBoxWrap, ContentRadiusBox } from 'themes/Shared.styles'
import { DoubleDFile } from 'types/services.type'
import callAxios, { handleError } from 'utils/callAxios'
import { getFileNameFromUrl, thousand } from 'utils/helpers'
import AddressInfo from '../AddressInfo'
import EnteringInfo from '../EnteringInfo'
import PhotoAndRequests from '../PhotoAndRequests'

interface RequestDetailsProps
  extends MatchInputProps<ServiceDetailsValuesType> {
  _type?: OrderStateType
  _serviceRowList: ServiceRowListType
  handleOpenSettlementRatio: () => void
}
const TH = ({ children, ...props }: { children?: React.ReactNode }) => (
  <th>
    <div>{children}</div>
  </th>
)
const TD = ({ children, ...props }: { children?: React.ReactNode }) => (
  <td>
    <div>{children}</div>
  </td>
)

const RequestDetails = ({
  _type,
  inputs,
  _serviceRowList,
  handleOpenSettlementRatio,
  ...props
}: RequestDetailsProps) => {
  const dispatch = useDispatch()
  const { currentGallery } = useGallery()
  const handleFirstImageUpload = async (value: DoubleDFile[]) => {
    if (!currentGallery.requestId) return
    const requestId = currentGallery.requestId
    try {
      const res = await callAxios('task').put<
        DoubleDResponse<{ image: string[] }>
      >(`/gallery/service-requests/image`, {
        id: requestId,
        image: value.map((item) => item.name),
      })
      if (res?.data?.data?.image) {
        dispatch(
          setCurrentGallery({
            ...currentGallery,
            data: {
              ...currentGallery.data,
              '요청 사진': res.data.data.image.map((url) => ({
                id: getFileNameFromUrl(url),
                name: getFileNameFromUrl(url),
                url: url,
              })),
            },
          })
        )
      }
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      <ContentBoxWrap>
        {inputs.serviceState === '시공완료' && (
          <BoxLayout _title={'숙련공별 정산내역'}>
            <S.MiniTableWrap>
              <S.MiniTable>
                <thead>
                  <tr>
                    <TH>이름</TH>
                    <TH>소속</TH>
                    <TH>시간</TH>
                    <TH>비율</TH>
                    <TH>시간 외 근무</TH>
                    <TH>정산 금액</TH>
                  </tr>
                </thead>
                <tbody>
                  {inputs.settlementRatios.map((item) => {
                    return (
                      <tr key={'list' + item.id}>
                        <TD>{item.name}</TD>
                        <TD>{item.company}</TD>
                        <TD>{item.serviceTime}시간</TD>
                        <TD>{item.ratio}%</TD>
                        <TD>{item.dutyState ? '휴무' : '-'}</TD>
                        <TD>{thousand(item.amount ?? 0)}원</TD>
                      </tr>
                    )
                  })}
                </tbody>
              </S.MiniTable>
            </S.MiniTableWrap>
            {!inputs.hasSettlement && (
              <GrayScaleOutline
                padding={`5px 0px`}
                width={'100%'}
                margin={`19px 0 0`}
                onClick={handleOpenSettlementRatio}
              >
                수정
              </GrayScaleOutline>
            )}
          </BoxLayout>
        )}
        <BoxLayout _title={'주소지 정보'}>
          <AddressInfo inputs={inputs} />
        </BoxLayout>
        <BoxLayout _title={`주문 상세 (${_serviceRowList.title})`}>
          <ContentRadiusBox>
            {_serviceRowList.list.map((row, rowIndex) => {
              return (
                <BoxRowComponent key={`row` + rowIndex} _label={row.name}>
                  {row.value}
                </BoxRowComponent>
              )
            })}
          </ContentRadiusBox>
          <ContentRadiusBox className="question">
            <BoxRowComponent
              _label={`시공 요청 사항`}
              _contents={_serviceRowList.requests}
            />
          </ContentRadiusBox>
        </BoxLayout>
        <BoxLayout _title={'방문시 참고사항'}>
          <EnteringInfo inputs={inputs} />
        </BoxLayout>
        <BoxLayout _title={'사진, 요청사항'}>
          <PhotoAndRequests
            _gallery={{
              gallery: { ...currentGallery, target: ['요청 사진'] },
              count: currentGallery.data['요청 사진']?.length ?? 0,
            }}
            _requests={inputs.serviceInfo.requests}
            _emitValue={handleFirstImageUpload}
          />
        </BoxLayout>
        {inputs.survey && (
          <BoxLayout _title={`만족도 조사`}>
            <ContentRadiusBox className="question">
              <BoxRowComponent
                _label={`회원 입력 사항`}
                _contents={inputs.survey}
              />
            </ContentRadiusBox>
          </BoxLayout>
        )}
      </ContentBoxWrap>
    </>
  )
}
export default RequestDetails
