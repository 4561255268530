import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import { CancelServiceModalProps } from './CancelServiceModal.types'

const CancelServiceModal = ({
  _css,
  _open,
  _type,
  _onClose,
  _onSubmit,
  ...props
}: CancelServiceModalProps) => {
  return (
    <>
      <Modal open={_open} onClose={_onClose}>
        <MiniPopup
          _title={_type === 'payment' ? '결제 취소' : '시공 취소'}
          _buttonHandler={
            <>
              <GrayScaleFill onClick={_onClose}>아니오</GrayScaleFill>
              <PrimaryButton onClick={_onSubmit}>예</PrimaryButton>
            </>
          }
        >
          {_type === 'payment' ? '결제된' : '선택한'} 서비스를 취소하시겠습니까?
        </MiniPopup>
      </Modal>
    </>
  )
}
export default CancelServiceModal
