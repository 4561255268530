import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import { AdditionalCss } from 'themes/styles.type'

const CheckBoxForDropdownStyle = {
  Wrap: styled.div<{
    _css?: AdditionalCss
    _active?: boolean
  }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    padding: 4px 12px;
    .checkbox-contents {
      span.highlight {
        color: ${colors['Primary/Default']};
      }
      ${({ _active }) =>
        _active
          ? css`
              ${typography['Body/Caption/Bold']}
              color: ${colors['Grayscale/Gray Dark']};
            `
          : css`
              ${typography['Body/Caption/Regular']}
              color: ${colors['Grayscale/Gray Default']};
            `}
    }
    &:hover {
      background-color: ${colors['Grayscale/Background Light']};
    }
    &.disabled {
      .checkbox-contents {
        color: ${colors['Grayscale/Gray Light']};
        span.highlight {
          color: ${colors['Primary/Default']};
        }
      }
    }
    img {
      width: 24px;
      height: 24px;
      min-height: 24px;
    }
    ${addCssProps}
  `,
  ImageBox: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  `,
  ChildrenBox: styled.div`
    flex: 1;
  `,
  Contents: styled.div`
    flex: 1;
    display: flex;
    user-select: none;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    & > img {
      width: 32px;
      height: 32px;
      min-height: 32px;
    }
  `,
}

export default CheckBoxForDropdownStyle
