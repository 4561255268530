import styled from '@emotion/styled/macro'
import { quillSnowStyle } from 'components/QuillEditorComponent/QuillEditorComponent.styles'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const EventDetailStyles = {
  RightTopHeaderWrap: styled.div`
    & {
      padding: 24px 32px;
      border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
    }
    .header-title {
      margin-top: 16px;
      ${typography['Headline/Bold']};
      color: ${colors['Grayscale/Black light']};
    }
    .header-info-wrap {
      & {
        margin-top: 8px;
        ${typography['Body/Small/Regular']};
        color: ${colors['Grayscale/Black light']};
      }
      & .vertical-bar {
        min-width: 1px;
        height: 11px;
        background-color: ${colors['Grayscale/Gray Light']};
      }
    }
  `,
  LinkText: styled.a`
    ${typography['Body/Large/Underline bold']};
    color: ${colors['Grayscale/Gray Default']};
    text-decoration: underline;
  `,
  ImagePopup: styled.div`
    & {
      position: relative;
      background-color: transparent;
    }
  `,
  ImageBox: styled.div`
    & {
      overflow: hidden;

      max-width: 1125px;
      max-height: 636px;
      ${addCssProps}
    }
    & img {
      object-fit: contain;
    }
  `,
  RightContentsWrap: styled.div`
    ${quillSnowStyle}
    .ql-container.ql-snow {
      border: none;
    }
  `,
  RightBottom: styled.div`
    width: 100%;
    padding: 24px 32px;
    border-top: 1px solid ${colors['Grayscale/Gray Lighter']}; ;
  `,
}
export default EventDetailStyles
