import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const PasswordCheckStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    gap: 0px;
    ${typography['Body/Caption/Bold']}
    color: ${colors['Grayscale/Gray Light']};
    user-select: none;
    cursor: pointer;
    & > img {
      width: 24px;
      height: 24px;
    }
    &.active {
      color: ${colors['Grayscale/Black']};
    }
    ${addCssProps}
  `,
}
export default PasswordCheckStyle
