import { DoubleDFile } from 'types/services.type'
import { GenderType, UserStatusType, UserType } from 'types/share'

export type ApiUserDetails = {
  contracts: {
    id: string
    fileName: string
    contractsUrl: string
  }[]
  couponCount: number
  createdAt: string
  id: string
  status: UserStatusType
  userType: UserType
  name: string
  userName: string
  phone: string
  gender: GenderType
  personalNo: string
  accountInfo: string
  email: string
  lastLoginAt: string
  pointWallet: {
    point: number
    money: number
  }
  refererCount: number
  note: string | null
  regularInfo: {
    accountNo: string
    cashReceiptNo: string
    fullLegalAddress: string
    landLotAddress: string
    id: string
    details: string
  } | null

  deletedAt: string | null
  opinion: string | null
  question: string | null
}

export type CouponInfoValues = {
  id: number
  name: string
  validDate: {
    start: string
    end: string
  }
  discount: {
    amount: number
    type: 'percent' | 'price'
  }
  categoryName: string
  createdAt: string
}
export type PointInfoValues = {
  id: number
  reason: string
  validDate: {
    start: string
    end: string
  }
  amount: number
  createdAt: string
}
export type RecommendsInfoValues = {
  id: number
  name: string
  phone: string
  createdAt: string
}
export type UserProfileValues = {
  id: string
  profileImage: string
  name: string
  phone: string

  userType: UserType
  userState: UserStatusType
  quitAt: string | null
  quitReason: string | null
  quitReasonWhy?: string | null

  userId: string
  gender: GenderType
  residentNumber: string
  accountNumber: string
  email: string
  createdAt: string
  recentUsedAt: string

  etc: {
    couponCount: number | null
    pointAmount: number | null
    moneyAmount: number | null
    referer: number | null
    remarks: string
  }
  subscribedCustomer: null | {
    cashReceiptCode: string
    address: string
    addressDetails: string
    addressOld: string
    contractFiles: DoubleDFile[]

    buildingInfo: boolean
  }
}
export const defaultSubscribedInfo = {
  cashReceiptCode: '',
  address: '',
  addressDetails: '',
  addressOld: '',
  contractFiles: [],

  buildingInfo: false,
}
export const USER_PROFILE_INITIAL_VALUE: UserProfileValues = {
  id: '',
  profileImage: '',
  name: '',
  phone: '',

  userType: 'CUSTOMER',
  userState: 'N',
  quitAt: '',
  quitReason: '',
  quitReasonWhy: '',

  userId: '',
  gender: 'M',
  residentNumber: '',
  accountNumber: '',
  email: '',
  createdAt: '',
  recentUsedAt: '',

  etc: {
    couponCount: 0,
    pointAmount: null,
    moneyAmount: null,
    referer: null,
    remarks: '',
  },

  subscribedCustomer: null,
}

export const parseForUserDetails = (
  response: ApiUserDetails
): UserProfileValues => {
  return {
    ...USER_PROFILE_INITIAL_VALUE,
    id: response.id,
    userId: response.userName,
    createdAt: response.createdAt,
    recentUsedAt: response.lastLoginAt,
    name: response.name,
    phone: response.phone,
    userState: response.status,
    userType: response.userType,
    email: response.email,
    gender: response.gender,
    residentNumber: response.personalNo,
    accountNumber: response.regularInfo?.accountNo ?? '',
    etc: {
      couponCount: response.couponCount,
      pointAmount: response.pointWallet.point,
      moneyAmount: response.pointWallet.money,
      referer: response.refererCount,
      remarks: response.note ?? '',
    },
    subscribedCustomer: {
      ...USER_PROFILE_INITIAL_VALUE.subscribedCustomer,
      cashReceiptCode: response.regularInfo?.cashReceiptNo ?? '',
      address: response.regularInfo?.fullLegalAddress ?? '',
      addressDetails: response.regularInfo?.details ?? '',
      addressOld: response.regularInfo?.landLotAddress ?? '',
      contractFiles:
        response.contracts.map((item) => ({
          id: item.id,
          name: item.fileName,
          url: item.contractsUrl,
        })) ?? [],
      buildingInfo: true,
    },

    quitAt: response.deletedAt,
    quitReason: response.question, //탈퇴 사유
    quitReasonWhy: response.opinion, // 탈퇴 의견
  }
}
