import { css } from '@emotion/react'
import { GrayScaleOutline, PrimaryButton } from 'components/Buttons'
import DetailsComponents, { ToList } from 'components/DetailsComponents'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import PostalCode, { PostcodeType } from 'components/PostalCode/PostalCode'
import { useAppDispatch } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import callAxios, { handleError } from 'utils/callAxios'
import {
  ApiUserDetails,
  defaultSubscribedInfo,
  parseForUserDetails,
  USER_PROFILE_INITIAL_VALUE,
} from '../User.types'
import { EditUserProps } from './EditUser.types'
import ModifyUserExtraInfo from './ModifyUserExtraInfo'
import ModifyUserProfiles from './ModifyUserProfiles'

const EditUser = ({ _css, ...props }: EditUserProps) => {
  const { id } = useParams()
  const { Layout: L } = S
  const dispatch = useAppDispatch()
  const userData = useMatchInput(USER_PROFILE_INITIAL_VALUE)
  const navigate = useNavigate()
  const [response] = useFetch<ApiUserDetails>('user', `/users/${id}`, {
    disabled: !id,
  })
  useEffect(() => {
    const fetchInit = userData.fetchInit
    if (response) {
      fetchInit(parseForUserDetails(response))
    }
  }, [response, userData.fetchInit])
  const handleEditMode = () => {
    navigate(`/member/user/${id}`)
  }
  const saveEdit = async () => {
    await handleSubmit()
    dispatch(setToast(['저장되었습니다.', 'success']))
    navigate(`/member/user/${id}`)
  }
  const [openPopupForGetPostalCode, set_openPopupForGetPostalCode] =
    useState<boolean>(false)

  const handleGetAddress = (value: PostcodeType) => {
    let fullAddress = value.address
    let extraAddress = ''

    if (value.addressType === 'R') {
      if (value.bname !== '') {
        extraAddress += value.bname
      }
      if (value.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${value.buildingName}` : value.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }
    userData.handleInput('subscribedCustomer', (prev) => {
      let nextValue = {
        ...defaultSubscribedInfo,
        ...prev.subscribedCustomer,
      }
      return {
        ...nextValue,
        address: fullAddress,
        addressOld: value.jibunAddress,
      }
    })
    set_openPopupForGetPostalCode(false)
  }
  const handlePostalCode = (value: boolean) => {
    set_openPopupForGetPostalCode(value)
  }
  const handleSubmit = async () => {
    const { inputs } = userData
    try {
      if (!inputs.id) return
      await callAxios('user').post(`/users/${inputs.id}/change-grade`, {
        regular: inputs.userType === 'REGULAR',
        accountNo: inputs.accountNumber,
        cashReceiptNo: inputs.subscribedCustomer?.cashReceiptCode ?? '',
        personalNo: inputs.residentNumber,
        email: inputs.email,
        fullLegalAddress: inputs.subscribedCustomer?.address,
        landLotAddress: inputs.subscribedCustomer?.addressOld,
        details: inputs.subscribedCustomer?.addressDetails,
        note: inputs.etc.remarks,
        contracts:
          inputs.subscribedCustomer?.contractFiles.map((item) => item.name) ??
          [],
      })
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      {openPopupForGetPostalCode && (
        <PostalCode
          onClose={() => handlePostalCode(false)}
          onComplete={(value) => {
            handleGetAddress(value)
          }}
        />
      )}
      <DetailsComponents
        _title={'회원 수정'}
        _topLeftControls={
          <L.ButtonRow>
            <ToList _to="/member/user" />
          </L.ButtonRow>
        }
        _leftContents={
          <>
            <ModifyUserProfiles {...userData} />
          </>
        }
        _rightContents={
          <>
            <ModifyUserExtraInfo
              handlePostalCode={handlePostalCode}
              {...userData}
            />
          </>
        }
        _bottomRightControls={
          <>
            <L.RightBottom>
              <GrayScaleOutline
                width={160}
                height={48}
                padding={`12px 0`}
                onClick={handleEditMode}
              >
                취소
              </GrayScaleOutline>
              <PrimaryButton
                _css={css`
                  flex: 1;
                `}
                height={48}
                padding={`12px 0`}
                onClick={saveEdit}
              >
                완료
              </PrimaryButton>
            </L.RightBottom>
          </>
        }
      />
    </>
  )
}
export default EditUser
