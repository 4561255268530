import { css } from '@emotion/react'
import DraggablePopup from 'components/DraggablePopup'
import useFetch from 'hooks/useFetch'
import { useEffect, useState } from 'react'
import { FlexRow, TXT } from 'themes/Shared.styles'
import {
  CouponPopupContents,
  PointPopupContents,
  RecommendsPopupContents,
} from '../UserPopupItems/UserPopupItems'
import { ExtraPopupItems } from '../UserPopupItems/UserPopupItems.types'
import {
  ApiCouponsByMemberId,
  ApiPointsByMemberId,
  UserExtraInfoPopupProps,
} from './UserExtraInfoPopup.types'

const UserExtraInfoPopup = ({
  _css,
  _onClose,
  _open,
  ...props
}: UserExtraInfoPopupProps) => {
  const [response] = useFetch<any>(
    _open?._type === 'referer' ? 'user' : 'order',
    `/user/${_open?._id}/${_open?._type}/list?take=9999`,
    {
      disabled: !_open?._id && !_open?._type,
    }
  )
  const [items, set_items] = useState<ExtraPopupItems>()
  useEffect(() => {
    const currentType = _open?._type
    if (response && currentType) {
      if (currentType === 'coupon') {
        const listResponse = response.list as ApiCouponsByMemberId[]
        set_items({
          type: 'coupon',
          _items: listResponse.map((item) => {
            return {
              id: item.id,
              name: item.name,
              validDate: {
                start: item.startAt,
                end: item.endAt,
              },
              discount: {
                amount: item.amount,
                type: item.isPercent ? 'percent' : 'price',
              },
              categoryName: item.categoryName,
              createdAt: item.createdAt,
            }
          }),
        })
      }
      if (currentType === 'point') {
        const listResponse = response.list as ApiPointsByMemberId[]
        set_items({
          type: 'point',
          _earnedPoint: _open?._data ?? 0,
          _items: listResponse.map((item) => {
            return {
              id: item.id,
              reason: item.note,
              validDate: {
                start: item.startAt,
                end: item.endAt,
              },
              amount: item.amount,
              createdAt: item.createdAt,
            }
          }),
        })
      }
      if (currentType === 'referer') {
        // TODO - 회원 추천인 목록 조회 api 없음
        // const listResponse = response.list as any[]
        set_items({
          type: 'referer',
          _items: [],
        })
      }
    }
  }, [response, _open])
  return (
    <>
      {_open && items && (
        <DraggablePopup
          _css={css`
            .drag-modal-contents {
              overflow-y: initial;
              padding: 0px 8px;
              & > div {
                max-height: calc(100vh - 100px - 64px);
                overflow-y: auto;
                height: 100%;
              }
            }
          `}
          _title={
            <FlexRow gap={8}>
              <div>{_open._title}</div>
              <TXT _color="Primary/Default">{_open._sub}</TXT>
            </FlexRow>
          }
          _open={true}
          _onClose={_onClose}
        >
          {items.type === 'coupon' && (
            <CouponPopupContents _items={items._items} />
          )}
          {items.type === 'point' && (
            <PointPopupContents
              _earnedPoint={items._earnedPoint}
              _items={items._items}
            />
          )}
          {items.type === 'referer' && (
            <RecommendsPopupContents _items={items._items} />
          )}
        </DraggablePopup>
      )}
    </>
  )
}
export default UserExtraInfoPopup
