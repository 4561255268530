import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'

const SearchTechBoxStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  SubmitWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
  `,
}

export default SearchTechBoxStyle
