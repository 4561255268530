import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import DetailsComponents, { ToList } from 'components/DetailsComponents'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import { useAppDispatch } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import LoadingPage from 'layout/LoadingPage'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ApiUserDetails,
  USER_PROFILE_INITIAL_VALUE,
  parseForUserDetails,
} from '../User.types'
import { UserDetailsProps } from './UserDetails.types'
import UserDetailsExtraInfoReadOnly from './UserDetailsExtraInfoReadOnly'
import UserDetailsReadOnly from './UserDetailsReadOnly'
import UserExtraInfoPopup from './UserExtraInfoPopup'
import { ExtraInfoOpenType } from './UserExtraInfoPopup/UserExtraInfoPopup.types'

const UserDetails = ({ _css, _id, ...props }: UserDetailsProps) => {
  const { Layout: L } = S
  const dispatch = useAppDispatch()
  const userData = useMatchInput(USER_PROFILE_INITIAL_VALUE)
  const [response, isError] = useFetch<ApiUserDetails>(
    'user',
    `/users/${_id}`,
    {
      disabled: !_id,
    }
  )
  const navigate = useNavigate()
  const handleEditMode = () => {
    navigate(`/member/user/edit/${_id}`)
  }
  const [openExtraModal, set_openExtraModal] =
    useState<ExtraInfoOpenType | null>(null)
  const handleModal = (value: ExtraInfoOpenType | null) => {
    set_openExtraModal(value)
  }
  const [openModal, set_openModal] = useState<boolean>(false)
  useEffect(() => {
    const fetchInit = userData.fetchInit
    if (response) {
      fetchInit(parseForUserDetails(response))
    }
  }, [response, userData.fetchInit])

  if (!response) return <LoadingPage />
  if (isError) return <Navigate to={`/404?from=${window.location.pathname}`} />
  return (
    <>
      {openModal && (
        <Modal open={openModal} onClose={() => set_openModal(false)}>
          <MiniPopup
            _title={`계정 복구`}
            _buttonHandler={
              <>
                <GrayScaleFill
                  onClick={() => {
                    set_openModal(false)
                  }}
                >
                  아니요
                </GrayScaleFill>
                <PrimaryButton
                  onClick={() => {
                    dispatch(setToast(['수정되었습니다.', 'success']))
                    userData.handleInput('userState', 'N')
                    set_openModal(false)
                  }}
                >
                  계정 복구
                </PrimaryButton>
              </>
            }
          >
            탈퇴한 계정입니다.
            <br />
            계정을 복구 하시겠습니까?
          </MiniPopup>
        </Modal>
      )}
      {openExtraModal && (
        <UserExtraInfoPopup
          _open={openExtraModal}
          _onClose={() => handleModal(null)}
        />
      )}
      <DetailsComponents
        _title={'회원 상세'}
        _topLeftControls={
          <L.ButtonRow>
            <ToList _to="/member/user" />
          </L.ButtonRow>
        }
        _leftContents={
          <>
            <UserDetailsReadOnly {...userData} />
          </>
        }
        _rightContents={
          <>
            <UserDetailsExtraInfoReadOnly
              handleModal={handleModal}
              {...userData}
            />
          </>
        }
        _bottomRightControls={
          <>
            {userData.inputs.userState === 'W' ? (
              <>
                <L.RightBottom>
                  <GrayScaleOutline
                    width={'100%'}
                    height={48}
                    padding={`12px 0`}
                    onClick={() => {
                      set_openModal(true)
                    }}
                  >
                    계정 복구
                  </GrayScaleOutline>
                </L.RightBottom>
              </>
            ) : (
              <>
                <L.RightBottom>
                  <GrayScaleOutline
                    width={'100%'}
                    height={48}
                    padding={`12px 0`}
                    onClick={handleEditMode}
                  >
                    수정
                  </GrayScaleOutline>
                </L.RightBottom>
              </>
            )}
          </>
        }
      />
    </>
  )
}
export default UserDetails
