import styled from '@emotion/styled/macro'
import { RoundBox } from 'components/ContentsBox/ContentsBox.styles'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const FilterComponentStyle = {
  Wrap: styled(RoundBox)`
    position: relative;
    z-index: 3;
    width: 100%;
    height: 96px;
    margin-bottom: 20px;
    padding: 24px 32px;
    ${addCssProps}
  `,
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  `,
  FilterWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
  `,
  QueryWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    & > div {
      min-width: 120px;
    }
  `,
  SubmitWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
  `,
  PeriodWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    ${typography['Body/Large/Bold']}
    color: ${colors['Grayscale/Gray Default']};
  `,
}
export default FilterComponentStyle
