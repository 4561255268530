import { isValidElement } from 'react'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export const isJSXComponent = (value: any): value is JSX.Element => {
  return isValidElement(value)
}
export interface PopupProps extends DivAttributes {
  _css?: AdditionalCss
  _title: string
  _buttonHandler?: Function | JSX.Element
  _buttonText?: string
  onClose?: () => void
}
