import styled from '@emotion/styled/macro'
import { RoundInBox } from 'components/ContentsBox/ContentsBox.styles'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const TopInfoStyle = {
  Wrap: styled(RoundInBox)`
    position: sticky;
    top: 0;
    z-index: 2;
    & > div {
      width: 100%;
      padding: 14px 32px;
      border-radius: 0 0 12px 12px;
      background-color: ${colors['Grayscale/Background Dark']};
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
    }
    ${addCssProps}
  `,
  Column: styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    & > label {
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Dark']};
    }
    & > div {
      ${typography['Body/Caption/Bold']}
      color: ${colors['White/White off']};
      padding: 3px 8px;
      border-radius: 12px;
      background-color: ${colors['Grayscale/Gray Light']};
    }
    ${addCssProps}
  `,
}

export default TopInfoStyle
