import { PickerPeriod } from 'components/PeriodPicker/PeriodPicker.types'
import dayjs from 'dayjs'
import { clone, uniq } from 'lodash-es'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { UserType } from 'types/share'
import { getFileNameFromUrl, objectKeys } from 'utils/helpers'

export const TIP_DROPDOWN_LIST = {
  '도배지 종류 안내': '도배지 종류 안내',
}
// const cleanDefaultValues: ServiceDefaultValues = {

// }
export const etcDefaultValues: ServiceDefaultValues = {
  h1: {
    id: null,
    list: [],
    label: '시공 장소',
    value: '',
    subText: '',
  },
  h2: {
    id: null,
    list: [],
    label: '중분류',
    value: '',
    subText: '',
  },
  h3: {
    id: null,
    list: [],
    label: '소분류',
    value: '',
    subText: '',
  },
}
export type ServiceItemDefault = {
  id: number | null
  list: string[]
  label: string
  value: string | number
  subText: string
}
export type ServiceDefaultValues = {
  [key in string]: ServiceItemDefault
}
export type ServiceCellValues = {
  [key in string]: string
} & { id?: number }
export type ServiceListValues = {
  [key in string]: Omit<ServiceItemDefault, 'value'>
}
export type TableRowValues = {
  id?: number
  dropdowns: ServiceCellValues
  price: number | null
  materialPrice: number | null
}

const initialMandatory = {
  제조사: '',
  원산지: '',
  '상품 규격': '',
  재질: '',
  '설치 형태': '',
  '배송 정보': '',
  '소비자 상담': '',
  인증: '',
  '교환/반품 안내': '',
  '교환/반품 제한': '',
  '판매자 정보': '',
}

export type ExtraValuesType = {
  id?: string
  title: string
  price: number | null
}
export type MandatoryProductInfoProps = typeof initialMandatory
export type HolidaysType = 'sunday' | 'saturday' | 'holiday'
export type MaterialValuesType = {
  id?: number | string
  thumbnail: {
    id?: string
    url: string
  }
  title: string
  price: number | null
  count: number | null

  quillHTML: string | null

  //상세 정보
  mandatoryProductInfo: MandatoryProductInfoProps
}
export const initialMaterialValue = {
  thumbnail: {
    url: '',
  },
  title: '',
  price: null,
  count: null,

  quillHTML: null,

  //상세 정보
  mandatoryProductInfo: clone(initialMandatory),
}
export type ServicePriceInputValues = {
  alertSubText: string | null
  zoneGroupId: number | null
  userType: UserType
  icon?: {
    id?: string
    url: string
  } | null
  name: string

  isActive?: boolean

  loadFees: number | null
  tableRow: TableRowValues[]
  dropdownsByType: ServiceDefaultValues | null
  reservationLimit: {
    dates: (PickerPeriod & { id?: number })[]
    holidays: HolidaysType[]
  }
  tips: ApiTip[]
  materials: MaterialValuesType[]

  options: {
    title: string
    subText: string
    option: ExtraValuesType[]
  }
}

export const SERVICE_PRICE_INITIAL_VALUE: ServicePriceInputValues = {
  alertSubText: null,
  zoneGroupId: null,
  userType: 'CUSTOMER',
  icon: null,
  name: '',

  loadFees: null,
  tableRow: [
    {
      dropdowns: { h1: '', h2: '', h3: '' },
      price: null,
      materialPrice: null,
    },
  ],
  dropdownsByType: { ...etcDefaultValues },
  reservationLimit: {
    dates: [],
    holidays: [],
  },
  tips: [{ id: null, title: '', subText: '' }],
  materials: [],
  options: {
    title: '',
    subText: '',
    option: [],
  },
}
export const parserForDropdownValues = (
  value: ServiceListValues
): ServiceCellValues => {
  return Object.keys(clone(value)).reduce((prev, curr) => {
    prev[curr] = ''
    return prev
  }, {} as ServiceCellValues)
}
export const emptyRow = (value: ServiceListValues) => {
  return {
    dropdowns: parserForDropdownValues(value),
    price: null,
    materialPrice: null,
  }
}

export interface ServiceManagementProps extends DivAttributes {
  _css?: AdditionalCss
}
export type PriceTagHeaderType = {
  id: number
  priceTagColumn: string
  title: string
  subText: string
  optionType: string
  option: string[]
}
export type OptionAndPrice = {
  name: string
  price: number
}
export type ServiceOptionType = {
  title: string
  subText: string
  option: OptionAndPrice[]
}
export type ApiTip = { id: number | null; title: string; subText: string }
export type ApiServiceManagement = {
  alertSubText: string | null
  id: number
  zoneGroup: {
    id: number
    name: string
  }
  category: {
    id: number
    isActive: boolean
    name: string
  }
  icon: string
  iconUrl: string
  userType: UserType
  name: string
  liftingCost: number
  priceTagHeader: PriceTagHeaderType[]
  priceTag: {
    id: number
    h1: string
    h2: string
    h3: string
    h4: string
    taskPrice: number | null
    materialPrice: number | null
  }[]
  serviceOption: ServiceOptionType | null
  closing: {
    sunday: boolean
    saturday: boolean
    holiday: boolean
  }
  closingDate: {
    id: number
    startAt: string
    endAt: string
  }[]
  material: {
    id: number
    image: string
    imageUrl: string
    name: string
    maxQuantity: number
    price: number
    description: string
    manufacturer: string
    madeIn: string
    standard: string
    texture: string
    installationForm: string
    deliveryInfo: string
    consultationNum: string
    certification: string
    exchangeInfo: string
    exchangeRule: string
    reseller: string
  }[]
  subCategoryArray: {
    id: number
    name: string
    order: number
    parentId: number | null
    path: string
  }[]
  tip: ApiTip[]
}
export type CurrentIdsValue = {
  zoneGroupId: string
  type: string
  lastCategoryId: string
  categoryId?: string
}
export const parserForServiceValue = (
  prev: ApiServiceManagement
): ServicePriceInputValues => {
  return {
    ...SERVICE_PRICE_INITIAL_VALUE,
    alertSubText: prev.alertSubText,
    zoneGroupId: prev.zoneGroup.id,
    userType: 'CUSTOMER',
    icon: { id: getFileNameFromUrl(prev.iconUrl), url: prev.iconUrl },
    name: prev.name,
    isActive: prev.category.isActive,
    loadFees: prev.liftingCost,
    tableRow: prev.priceTag.map((item) => {
      return {
        id: item.id,
        dropdowns: objectKeys(item).reduce((p, c) => {
          const cellValue = item[c]
          if (c === 'id') {
            p[c] = item[c]
            return p
          }
          if (typeof cellValue === 'number') return p
          if (c === 'taskPrice') return p
          if (c === 'materialPrice') return p
          p[c] = cellValue ?? ''
          return p
        }, {} as ServiceCellValues),
        price: item.taskPrice,
        materialPrice: item.materialPrice,
      }
    }),
    options: {
      title: prev.serviceOption?.title ?? '',
      subText: prev.serviceOption?.subText ?? '',
      option: prev.serviceOption?.option
        ? prev.serviceOption?.option.map((item) => {
            return {
              title: item.name,
              price: item.price,
            }
          })
        : [],
    },
    dropdownsByType: prev.priceTagHeader.reduce((prev, curr) => {
      prev[curr.priceTagColumn] = {
        id: curr.id,
        list: curr.option,
        label: curr.title,
        subText: curr.subText,
        value: '',
      }
      return prev
    }, {} as ServiceDefaultValues),
    reservationLimit: {
      dates: prev.closingDate.map((item) => ({
        id: item.id,
        start: dayjs(item.startAt),
        end: dayjs(item.endAt),
      })),
      holidays: objectKeys(prev.closing).filter(
        (keyName) => prev.closing[keyName]
      ),
    },
    tips: prev.tip,
    materials: prev.material.map((item) => {
      return {
        id: item.id,
        thumbnail: {
          id: getFileNameFromUrl(item.imageUrl),
          url: item.imageUrl,
        },
        price: item.price,
        count: item.maxQuantity,
        title: item.name,
        quillHTML: item.description,
        mandatoryProductInfo: {
          제조사: item.manufacturer,
          원산지: item.madeIn,
          '상품 규격': item.standard,
          재질: item.texture,
          '설치 형태': item.installationForm,
          '배송 정보': item.deliveryInfo,
          '소비자 상담': item.consultationNum,
          인증: item.certification,
          '교환/반품 안내': item.exchangeInfo,
          '교환/반품 제한': item.exchangeRule,
          '판매자 정보': item.reseller,
        },
      }
    }),
  }
}
export type PostMainServicePriceValue = {
  zoneGroupId: number
  userType: UserType
  name: string
  liftingCost: number
  priceTag: {
    id: number | null
    taskPrice: number
    materialPrice: number
  }[]
  serviceOption: {
    title: string
    subText: string
    option: {
      name: string
      price: number
    }[]
  } | null

  closing: {
    sunday: boolean
    saturday: boolean
    holiday: boolean
  }
  closingDate?: {
    id: number | null
    startAt: string
    endAt: string
  }[]
  material?: {
    id: number | null
    name: string
    maxQuantity: number
    price: number
    description: string
    manufacturer: string
    madeIn: string
    standard: string
    texture: string
    installationForm: string
    deliveryInfo: string
    consultationNum: string
    certification: string
    exchangeInfo: string
    exchangeRule: string
    reseller: string
  }[]

  tip: {
    id: number | null
    subText: string
  }[]
}

export type PostSubServicePriceValue = {
  zoneGroupId: number
  userType: UserType
  name: string
  icon?: string
  liftingCost: number
  priceTag: {
    id: number | null
    h1: string
    h2: string | null
    h3: string | null
    h4: string | null
    taskPrice: number
    materialPrice: number
  }[]

  priceTagHeader: {
    id: number | null
    priceTagColumn: string
    title: string
    subText: string
    option: string[]
  }[]

  serviceOption: {
    title: string
    subText: string
    option: {
      name: string
      price: number
    }[]
  } | null

  closing: {
    sunday: boolean
    saturday: boolean
    holiday: boolean
  }
  closingDate?: {
    id: number | null
    startAt: string
    endAt: string
  }[]
  material?: {
    id: number | null
    name: string
    maxQuantity: number
    price: number
    description: string
    manufacturer: string
    madeIn: string
    standard: string
    texture: string
    installationForm: string
    deliveryInfo: string
    consultationNum: string
    certification: string
    exchangeInfo: string
    exchangeRule: string
    reseller: string
  }[]

  tip: {
    id: number | null
    subText: string
  }[]
}
export const hasErrorMessage = (value: any): value is { message: string } => {
  return value !== undefined && 'message' in value
}
export const checkServiceType = (value?: string): boolean => {
  return value === 'main' || value === 'sub'
}
export const parseForApiServiceValue = (
  value: ServicePriceInputValues,
  type: 'main' | 'sub' = 'main'
) => {
  let message = ''
  if (type === 'sub' && !value.icon?.id) {
    message = '아이콘은 필수 입니다.'
    return { message }
  }
  if (!value.dropdownsByType) {
    message = '가격 테이블이 비어있습니다.'
    return { message }
  }
  if (!value.zoneGroupId) {
    message = '지역 그룹이 없습니다.'
    return { message }
  }
  if (type === 'sub' && !value.tips[0].subText) {
    message = '팁을 작성해 주세요.'
    return { message }
  }
  const priceTag =
    type === 'sub'
      ? value.tableRow.map((item) => {
          return {
            ...item.dropdowns,
            id: item.id ?? undefined,
            taskPrice: item.price ?? 0,
            materialPrice: item.materialPrice ?? 0,
          }
        })
      : (value.tableRow.map((item) => {
          return {
            id: item.id ?? undefined,
            taskPrice: item.price ?? 0,
            materialPrice: item.materialPrice ?? 0,
          }
        }) as object[])
  const subTagList = objectKeys(value.dropdownsByType).map((keyName) => {
    return {
      id: value.dropdownsByType![keyName].id ?? undefined,
      priceTagColumn: keyName,
      title: value.dropdownsByType![keyName].label,
      subText: value.dropdownsByType![keyName].subText,
      option: uniq(
        priceTag
          .map((item: { [key in string]: any }) => item[keyName] ?? null)
          .filter((ii) => !!ii)
      ),
    }
  })
  const postData = {
    zoneGroupId: value.zoneGroupId,
    userType: value.userType,
    name: value.name,
    liftingCost: value.loadFees ?? 0,
    priceTag,
    serviceOption: value.options.option.length
      ? {
          title: value.options.title,
          subText: value.options.subText,
          option: value.options.option.map((item) => {
            return {
              name: item.title,
              price: item.price ?? 0,
            }
          }),
        }
      : null,
    closing: {
      sunday: value.reservationLimit.holidays.includes('sunday'),
      saturday: value.reservationLimit.holidays.includes('saturday'),
      holiday: value.reservationLimit.holidays.includes('holiday'),
    },
    closingDate: value.reservationLimit.dates.length
      ? value.reservationLimit.dates.map((item) => {
          return {
            id: item.id ?? undefined,
            startAt: item.start?.toISOString() ?? '',
            endAt: item.end?.toISOString() ?? '',
          }
        })
      : undefined,
    material:
      type === 'sub' && value.materials.length
        ? value.materials.map((item) => {
            return {
              id: typeof item.id === 'number' ? item.id : undefined,
              name: item.title,
              image: item.thumbnail.id,
              maxQuantity: item.count ?? 1,
              price: item.price ?? 0,
              description: item.quillHTML ?? '',
              manufacturer: item.mandatoryProductInfo.제조사,
              madeIn: item.mandatoryProductInfo.원산지,
              standard: item.mandatoryProductInfo['상품 규격'],
              texture: item.mandatoryProductInfo.재질,
              installationForm: item.mandatoryProductInfo['설치 형태'],
              deliveryInfo: item.mandatoryProductInfo['배송 정보'],
              consultationNum: item.mandatoryProductInfo['소비자 상담'],
              certification: item.mandatoryProductInfo.인증,
              exchangeInfo: item.mandatoryProductInfo['교환/반품 안내'],
              exchangeRule: item.mandatoryProductInfo['교환/반품 제한'],
              reseller: item.mandatoryProductInfo['판매자 정보'],
            }
          })
        : undefined,
    ...(type === 'sub' && {
      priceTagHeader: subTagList,
      icon: value.icon!.id ?? '',
      tip: {
        id: value.tips[0].id,
        subText: value.tips[0].subText,
      },
    }),
    ...(type === 'main' && {
      tip: value.tips.map((item) => {
        return {
          id: item.id,
          subText: item.subText,
        }
      }),
    }),
  }
  return postData
}
