import { css } from '@emotion/react'
import { ImagesInputRemove, ImagesInputSearchIcon } from 'assets'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
  PrimaryOutlineButton,
} from 'components/Buttons'
import DatePicker from 'components/DatePicker'
import DetailsDefault, { ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import Dropdown from 'components/Dropdown'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import NotFoundItem from 'components/NotFoundItem'
import PostalCode from 'components/PostalCode'
import { PostcodeType } from 'components/PostalCode/PostalCode'
import UnderlineInput from 'components/UnderlineInput'
import dayjs from 'dayjs'
import { useBlock } from 'hooks/useBlock'
import useMatchInput from 'hooks/useMatchInput'

import Badge from 'components/Badge'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import { FILE_TYPE } from 'components/FileUploadBox/FileUploadBox.types'
import RadioButton from 'components/RadioButton'
import RowTab from 'components/RowTab'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import { TaskAdditionalCost } from 'pages/Work/components/ExtraFeesInService/ExtraFeesInService.types'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  Flex,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { colors, typography } from 'themes/styles'
import {
  COMMUNICATION_METHODS_DROPDOWN_LIST,
  DoubleDFile,
  ELEVATOR_DROPDOWN_LIST,
  FLOORS_DROPDOWN_LIST,
  FREE_PARKING_DROPDOWN_LIST,
  HAS_PASSWORD_DROPDOWN_LIST,
  LIVE_IN_DROPDOWN_LIST,
  RESIDENT_TYPE_DROPDOWN_LIST,
} from 'types/services.type'
import { blobNewWindow } from 'utils/DragAndZoom/utils'
import callAxios, { extractData, handleError } from 'utils/callAxios'
import {
  HandleEllipsisToolTip,
  getFileToBlob,
  handleNumber,
  objectKeys,
  thousand,
} from 'utils/helpers'
import { callFileUpload } from 'utils/services'
import ExtraFee from '../../components/ExtraFee'
import ExtraFeesInfo from '../../components/ExtraFeesInfo'
import OrderItem from '../../components/OrderItem'
import { parserForEstimatesToPostData } from '../Estimates.helpers'
import { useServiceControlOnEstimates } from '../Estimates.hooks'
import A, { BoxTextarea } from './AddEstimates.styles'
import {
  ADD_ESTIMATES_INITIAL_VALUE,
  AddEstimatesProps,
  serviceRadio,
} from './AddEstimates.types'
import CustomerInfoInEstimates from './CustomerInfoInEstimates'
import SelectMaterialPopup from './SelectMaterialPopup'

const tabs = ['주문 정보', '결제 정보'] as const
const AddEstimates = ({ _css, ...props }: AddEstimatesProps) => {
  const { Layout: L } = S
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const inputData = useMatchInput(ADD_ESTIMATES_INITIAL_VALUE)
  const { inputs, handleInput, isMatched } = inputData

  const { showPrompt, confirmNavigation, cancelNavigation } = useBlock(
    !isMatched
  )

  // 시공 정보 관련 항목
  const {
    currentServiceType,
    selectedServiceCategory,
    priceTableOptionValues,
    optionPriceValues,
    materialItemList,
    quantityTitle,
    serviceQuantity,
    selectedMaterial,
    lastSelectedServiceCategoryId,
    categoryDropdown,
    selectedIsEstimates,
    handleOptionPriceValue,
    handleServiceQuantity,
    handlePriceTableOptionValues,
    handleDropdown,
    handleServiceType,
    handleServiceRequest,
    handleSelectedMaterial,
    getCurrentServicePrice,
    getMainCategoryList,
    setMainCategoryList,
    priceOptionDropdowns,
    checkOptions,
  } = useServiceControlOnEstimates(inputData)

  const updateFirstCategoryDropdown = useCallback(
    async (
      zoneGroupId?: number | null,
      userType?: 'CUSTOMER' | 'REGULAR' | null
    ) => {
      if (zoneGroupId && userType && currentServiceType) {
        setMainCategoryList(
          await getMainCategoryList(
            zoneGroupId,
            currentServiceType === '기타 서비스' ? 'sub' : 'main',
            userType
          ),
          true
        )
      }
    },
    [currentServiceType, getMainCategoryList, setMainCategoryList]
  )

  // 사진, 요청사항
  const [files, set_files] = useState<string>('')
  // 사진, 요청사항 이미지 업로드
  const handleUpload = async (...fileList: File[]) => {
    const uploadedList = (
      await Promise.all(
        fileList
          .map(async (file) => {
            const fileName = await callFileUpload(
              file,
              'order',
              '/estimates/file'
            )
            const blob = await getFileToBlob(file)
            if (fileName) {
              return {
                id: fileName ?? '',
                name: fileName ?? '',
                url: blob,
              }
            } else {
              return null
            }
          })
          .filter((ii) => !!ii)
      )
    ).filter((ii) => !!ii) as DoubleDFile[]
    if (uploadedList.length > 0) {
      handleInput('requestImages', (prev) => [
        ...prev.requestImages,
        ...uploadedList,
      ])
    }
  }

  // 주문 정보 | 결제 정보 탭
  const [currentTab, set_currentTab] =
    useState<typeof tabs[number]>('주문 정보')

  // 팝업
  const [openPopupForGetPostalCode, set_openPopupForGetPostalCode] =
    useState<boolean>(false)
  const [openExtraFee, set_openExtraFee] = useState<{
    open: boolean
    data?: {
      index: number
      data: TaskAdditionalCost
    }
  }>({ open: false })
  const [openModal, set_openModal] = useState<{
    title: string
    content: string
    txt?: [string, string]
    onSubmit?: () => void
  } | null>(null)

  // 주소 업데이트
  const handleGetAddress = async (value: PostcodeType) => {
    try {
      const res = await callAxios('user').get(
        `/zone/available?bcode=${value.bcode}`
      )
      const { zoneGroupId } = extractData<{ zoneGroupId: number }>(res)
      if (zoneGroupId) {
        handleInput('zoneGroupId', zoneGroupId)
      }
      let fullAddress = value.address
      let extraAddress = ''

      if (value.addressType === 'R') {
        if (value.bname !== '') {
          extraAddress += value.bname
        }
        if (value.buildingName !== '') {
          extraAddress +=
            extraAddress !== '' ? `, ${value.buildingName}` : value.buildingName
        }
        fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
      }
      handleInput('address', fullAddress)
      handleInput('bcode', Number(value.bcode))
      handleInput('addressOld', value.jibunAddress)
      set_openPopupForGetPostalCode(false)
      await updateFirstCategoryDropdown(zoneGroupId, inputs.customerType)
    } catch (error) {
      handleInput('zoneGroupId', null)
      handleInput('addressOld', '')
      handleInput('address', '')
      handleError(error)
    }
  }
  // 추가금
  const [extraFees, set_extraFees] = useState<TaskAdditionalCost[]>([])
  const handleExtraFees = (fee: TaskAdditionalCost[]) => {
    set_extraFees(fee)
  }

  const [openMaterial, set_openMaterial] = useState<boolean>(false)

  // 결제 정보 - 주문 금액
  const totalAmount = useMemo(() => {
    let total = extraFees.reduce((prev, curr) => {
      prev += handleNumber(curr.price)
      return prev
    }, 0)
    if (inputs.serviceFee) {
      total += inputs.serviceFee
    }
    if (inputs.dispatchFee) {
      total += inputs.dispatchFee
    }
    if (inputs.serviceMaterialPrice) {
      total += inputs.serviceMaterialPrice
    }
    if (inputs.liftingPrice) {
      total += inputs.liftingPrice
    }
    // 부가세
    const VAT_RATE = 1.1
    const totalWithVAT = Math.floor(total * VAT_RATE)
    return totalWithVAT
  }, [
    extraFees,
    inputs.serviceFee,
    inputs.dispatchFee,
    inputs.serviceMaterialPrice,
    inputs.liftingPrice,
  ])

  // 결제 정보 - 미수금액
  const unpaidFee = useMemo(() => {
    const prePaidFee = inputs.prePaidFee ?? 0
    return totalAmount - prePaidFee
  }, [inputs.prePaidFee, totalAmount])

  // 빈값 체크
  const trueWhenEmpty = useMemo(() => {
    if (!inputs.name) return true
    if (!inputs.phone) return true
    if (!inputs.customerType) return true
    if (inputs.funnels.length < 1) return true
    // if (!inputs.serviceItem.serviceCategoryId) return true
    // if (!inputs.orderDate) return true
    // if (!inputs.buyerName) return true
    // if (!inputs.serviceFee) return true
    if (!inputs.address) return true
    if (!inputs.addressDetails) return true
    if (!inputs.residentType) return true
    if (!inputs.buildingSize) return true
    if (!inputs.floors) return true
    if (!inputs.elevator) return true
    // if (!inputs.desiredDate) return true
    // if (!inputs.scheduledDate) return true
    // if (!inputs.freeParking) return true
    // if (!inputs.liveIn) return true
    // if (!inputs.hasPassword) return true
    // if (inputs.hasPassword === 2 && !inputs.frontPwd) return true
    // if (!inputs.communicationMethod) return true
    return false
  }, [inputs])

  // 견적서 전송
  const handleSubmit = async () => {
    if (selectedIsEstimates) {
      set_openModal({
        title: '시공 정보 변경',
        content: `시공 정보가 ‘문의'로 되어있습니다.
        서비스를 선택해야 견적서를 전송할 수 있습니다.`,
      })
      return
    }
    // if (inputs.serviceItem.serviceCategoryId === null) {
    //   dispatch(setToast(['서비스를 선택해주세요', 'error']))
    //   return
    // }
    if (inputs.funnels.length !== 3) {
      dispatch(setToast(['주문 경로를 선택해주세요', 'error']))
      return
    }
    const postData = parserForEstimatesToPostData(inputs, {
      optionPriceValues,
      selectedMaterial,
      extraFees,
      serviceQuantity,
    })

    try {
      await callAxios('task').post(`/order`, postData)
      confirmNavigation(() => {
        dispatch(setToast(['견적서를 발송했습니다.', 'success']))
        navigate('/work/estimates')
      })
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      {openMaterial && (
        <SelectMaterialPopup
          _onClose={() => set_openMaterial(false)}
          _data={materialItemList}
          _emitValue={(value) => handleSelectedMaterial(value)}
        />
      )}
      {showPrompt && (
        <Modal open={showPrompt} onClose={cancelNavigation}>
          <MiniPopup
            _title={'페이지 이동'}
            _buttonHandler={
              <FlexColumn gap={8}>
                <GrayScaleFill
                  width={'100%'}
                  padding={'12px 20px'}
                  onClick={() => {
                    cancelNavigation()
                  }}
                >
                  페이지 머무르기
                </GrayScaleFill>
                <PrimaryButton
                  width={'100%'}
                  padding={'12px 20px'}
                  onClick={() => confirmNavigation()}
                >
                  저장하지 않고 나가기
                </PrimaryButton>
              </FlexColumn>
            }
          >
            변경하신 내용이 있습니다.
            <br />
            변경 내용 을 저장하지 않고 이동하시겠습니까?
          </MiniPopup>
        </Modal>
      )}
      {openExtraFee.open && (
        <ExtraFee
          _noUserRequest={true}
          _data={openExtraFee.data}
          _open={openExtraFee.open}
          _onClose={() => set_openExtraFee((prev) => ({ open: false }))}
          _emitValue={(value) => {
            const prevExtra = [...extraFees]
            const nextExtra = openExtraFee.data
              ? prevExtra.map((item) =>
                  item.id === value.id ? { ...item, ...value } : item
                )
              : [...prevExtra, value]
            handleExtraFees(nextExtra)
            set_openExtraFee((prev) => ({ open: false }))
          }}
        />
      )}
      {openModal && (
        <Modal open={openModal !== null} onClose={() => set_openModal(null)}>
          <MiniPopup
            _title={openModal.title}
            _buttonHandler={
              openModal.onSubmit ? (
                <>
                  <GrayScaleFill
                    onClick={() => {
                      set_openModal(null)
                    }}
                  >
                    {openModal.txt?.[0] ?? '아니오'}
                  </GrayScaleFill>
                  <PrimaryButton onClick={openModal.onSubmit}>
                    {openModal.txt?.[1] ?? '삭제'}
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    set_openModal(null)
                  }}
                >
                  {openModal.txt?.[0] ?? '확인'}
                </PrimaryButton>
              )
            }
          >
            {openModal.content}
          </MiniPopup>
        </Modal>
      )}
      {openPopupForGetPostalCode && (
        <PostalCode
          onClose={() => set_openPopupForGetPostalCode(false)}
          onComplete={(value) => {
            handleGetAddress(value)
          }}
        />
      )}
      <DetailsDefault
        _title={'견적서 작성'}
        _topLeftControls={<ToList _to="/work/estimates" />}
        _leftTab={
          <RowTab
            _tabs={tabs}
            _tab={currentTab}
            _emitValue={(value) => {
              set_currentTab(value)
            }}
          />
        }
        _leftContents={
          <ContentBoxWrap>
            {currentTab === '주문 정보' && (
              <>
                <CustomerInfoInEstimates
                  updateFirstCategoryDropdown={updateFirstCategoryDropdown}
                  {...inputData}
                />
                <BoxLayout _title={'주문 서비스'}>
                  {inputs.serviceItem.serviceCategoryId ? (
                    <OrderItem
                      _item={{
                        id: inputs.serviceItem.serviceCategoryId,
                        name: inputs.serviceItem.serviceName,
                      }}
                    />
                  ) : (
                    <NotFoundItem _useImage={true}>
                      선택한 서비스가 없습니다.
                    </NotFoundItem>
                  )}
                </BoxLayout>
              </>
            )}
            {currentTab === '결제 정보' && (
              <>
                <BoxLayout _title={'주문 금액'}>
                  <FlexColumn gap={12}>
                    <ContentRadiusBox>
                      <BoxRowComponent _label={'결제일'}>
                        <DatePicker
                          _value={dayjs(inputs.orderDate)}
                          _emitValue={(value) => {
                            handleInput('orderDate', value.format('YYYY-MM-DD'))
                          }}
                        />
                      </BoxRowComponent>
                      <BoxRowComponent _label={'결제자 명'}>
                        <UnderlineInput
                          placeholder="내용 입력"
                          value={inputs.buyerName}
                          handleValue={(value) =>
                            handleInput('buyerName', value)
                          }
                        />
                      </BoxRowComponent>
                    </ContentRadiusBox>
                    <ContentRadiusBox>
                      <BoxRowComponent _label={'출장비'}>
                        <UnderlineNumberInput
                          suffix="원"
                          placeholder="내용 입력"
                          value={inputs.dispatchFee}
                          handleValue={(value) =>
                            handleInput('dispatchFee', value)
                          }
                        />
                      </BoxRowComponent>
                    </ContentRadiusBox>
                    <ContentRadiusBox>
                      <BoxRowComponent _label={'양중비'}>
                        <UnderlineNumberInput
                          suffix="원"
                          placeholder="내용 입력"
                          value={inputs.liftingPrice}
                          handleValue={(value) =>
                            handleInput('liftingPrice', value)
                          }
                        />
                      </BoxRowComponent>
                    </ContentRadiusBox>
                    {extraFees.map((item, itemIndex) => {
                      return (
                        <ContentRadiusBox key={'extra' + itemIndex}>
                          <BoxRowComponent _label={item.taskName}>
                            {thousand(item.price ?? 0)}원
                          </BoxRowComponent>
                        </ContentRadiusBox>
                      )
                    })}
                    <ContentRadiusBox>
                      <BoxRowComponent _label={'공임비'}>
                        <UnderlineNumberInput
                          suffix="원"
                          placeholder="내용 입력"
                          value={inputs.serviceFee}
                          handleValue={(value) =>
                            handleInput('serviceFee', value)
                          }
                        />
                      </BoxRowComponent>
                    </ContentRadiusBox>
                    <ContentRadiusBox>
                      <BoxRowComponent _label={'재료비'}>
                        <UnderlineNumberInput
                          suffix="원"
                          placeholder="내용 입력"
                          value={inputs.serviceMaterialPrice}
                          handleValue={(value) =>
                            handleInput('serviceMaterialPrice', value)
                          }
                        />
                      </BoxRowComponent>
                    </ContentRadiusBox>
                    <ContentRadiusBox>
                      <BoxRowComponent
                        _css={css`
                          position: relative;
                          height: 66px;
                          align-items: flex-start;
                          & > div:first-of-type {
                            text-align: left;
                            max-width: 90px;
                          }
                          & .formula {
                            position: absolute;
                            bottom: 0;
                            display: flex;
                            align-items: center;
                            gap: 4px;
                          }
                        `}
                        _label={
                          <TXT
                            _css={css`
                              padding-top: 8px;
                            `}
                            _textStyle="Title/Bold"
                            _color="Grayscale/Gray Default"
                          >
                            주문금액
                          </TXT>
                        }
                        _contents={
                          <TXT
                            _css={css`
                              padding-top: 5px;
                            `}
                            _textStyle="Display/1 Bold"
                            _color="Primary/Default"
                          >
                            {thousand(totalAmount)}원
                          </TXT>
                        }
                      >
                        <div className="formula">
                          <Badge
                            _bgColor="Primary/Lighter"
                            _textColor="Primary/Default"
                          >
                            계산식
                          </Badge>
                          <TXT
                            _color="Primary/Default"
                            _textStyle="Body/Caption/Regular"
                          >{`{(상품금액 - 쿠폰,포인트 할인) * 1.1(VAT)} + 출장비`}</TXT>
                        </div>
                      </BoxRowComponent>
                    </ContentRadiusBox>
                  </FlexColumn>
                </BoxLayout>
                <div>
                  <ExtraFeesInfo
                    extraFees={extraFees}
                    handleExtraFees={handleExtraFees}
                    handleModal={(value) => set_openModal(value)}
                    openExtraFeeModal={(value) => {
                      set_openExtraFee(value)
                    }}
                  />
                </div>
                <BoxLayout _title={'수금 정보'}>
                  <FlexColumn gap={12}>
                    <ContentRadiusBox>
                      <BoxRowComponent _label={'금액'}>
                        <UnderlineNumberInput
                          placeholder="금액 입력"
                          value={inputs.prePaidFee}
                          handleValue={(value) =>
                            handleInput('prePaidFee', value)
                          }
                        />
                      </BoxRowComponent>
                      <BoxRowComponent
                        _label={'사유 입력'}
                        className="flex-start"
                      >
                        <BoxTextarea
                          value={inputs.prePaidReason}
                          placeholder="입력"
                          onChange={(e) =>
                            handleInput('prePaidReason', e.target.value)
                          }
                        />
                      </BoxRowComponent>
                    </ContentRadiusBox>
                    <ContentRadiusBox>
                      <BoxRowComponent
                        _css={css`
                          position: relative;
                          height: 66px;
                          align-items: flex-start;
                          & > div:first-of-type {
                            text-align: left;
                          }
                          & .formula {
                            position: absolute;
                            bottom: 0;
                            display: flex;
                            align-items: center;
                            gap: 4px;
                          }
                        `}
                        _label={
                          <TXT
                            _css={css`
                              padding-top: 8px;
                            `}
                            _textStyle="Title/Bold"
                            _color="Grayscale/Gray Default"
                          >
                            미수금액
                          </TXT>
                        }
                        _contents={
                          <TXT
                            _css={css`
                              padding-top: 5px;
                            `}
                            _textStyle="Display/1 Bold"
                            _color="Primary/Default"
                          >
                            {thousand(unpaidFee)}원
                          </TXT>
                        }
                      >
                        {' '}
                        <div className="formula">
                          <Badge
                            _bgColor="Primary/Lighter"
                            _textColor="Primary/Default"
                          >
                            계산식
                          </Badge>
                          <TXT
                            _color="Primary/Default"
                            _textStyle="Body/Caption/Regular"
                          >{`{(상품금액 - 쿠폰,포인트 할인) * 1.1(VAT)} + 출장비`}</TXT>
                        </div>
                      </BoxRowComponent>
                    </ContentRadiusBox>
                  </FlexColumn>
                </BoxLayout>
              </>
            )}
          </ContentBoxWrap>
        }
        _rightContents={
          <ContentBoxWrap>
            <BoxLayout _title={'주소지 정보'}>
              <ContentRadiusBox gap={12}>
                <BoxRowComponent _label={'주소 *'}>
                  <UnderlineInput
                    value={inputs.address}
                    noRemoveButton
                    handleValue={(value) => {}}
                    after={
                      <img
                        src={ImagesInputSearchIcon}
                        alt="search"
                        onClick={() => {
                          set_openPopupForGetPostalCode(true)
                        }}
                      />
                    }
                    placeholder="주소 검색"
                    onClick={() => {
                      set_openPopupForGetPostalCode(true)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={''}>
                  <UnderlineInput
                    value={inputs.addressDetails}
                    handleValue={(value) =>
                      handleInput('addressDetails', value)
                    }
                    placeholder="상세주소 입력"
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'거주지 유형 *'}>
                  <Dropdown.Underline
                    _list={RESIDENT_TYPE_DROPDOWN_LIST}
                    _value={inputs.residentType}
                    _emitValue={(value) => {
                      handleInput('residentType', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'건물 면적 *'}>
                  <UnderlineNumberInput
                    value={inputs.buildingSize}
                    handleValue={(value) => handleInput('buildingSize', value)}
                    placeholder="내용 입력"
                    suffix="평"
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'층수 *'}>
                  <Dropdown.Underline
                    _list={FLOORS_DROPDOWN_LIST}
                    _value={inputs.floors}
                    _emitValue={(value) => {
                      handleInput('floors', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'엘리베이터 *'}>
                  <Dropdown.Underline
                    _list={ELEVATOR_DROPDOWN_LIST}
                    _value={inputs.elevator}
                    _emitValue={(value) => {
                      handleInput('elevator', value)
                    }}
                  />
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout _title={'시공 정보 추가'}>
              {inputs.zoneGroupId && inputs.customerType ? (
                <ContentRadiusBox gap={12}>
                  <>
                    <BoxRowComponent _label={'서비스 유형'}>
                      {serviceRadio.map((serviceType) => {
                        return (
                          <RadioButton
                            key={serviceType}
                            _mini
                            _active={currentServiceType === serviceType}
                            onClick={() => {
                              handleServiceType(serviceType)
                            }}
                          >
                            {serviceType}
                          </RadioButton>
                        )
                      })}
                    </BoxRowComponent>
                    {categoryDropdown.map((dropdown, dropdownIndex) => {
                      return (
                        <BoxRowComponent
                          key={'service-dropdown-' + dropdownIndex}
                          _label={dropdownIndex === 0 ? '서비스 이름*' : ''}
                        >
                          <Dropdown.Underline
                            _list={dropdown.list}
                            _value={selectedServiceCategory[dropdownIndex]}
                            _emitValue={(value) =>
                              handleDropdown(value, dropdownIndex)
                            }
                          />
                        </BoxRowComponent>
                      )
                    })}
                    {priceOptionDropdowns && (
                      <>
                        {objectKeys(priceOptionDropdowns.priceTagHeader)
                          .length > 0 && (
                          <>
                            {objectKeys(
                              priceOptionDropdowns.priceTagHeader
                            ).map((keyName, keyIndex) => {
                              const tagName =
                                priceOptionDropdowns.priceTagHeader[keyName]
                                  .priceTagColumn
                              return (
                                <BoxRowComponent
                                  _label={keyName}
                                  key={keyName + keyIndex}
                                >
                                  <Dropdown.Underline
                                    _list={
                                      priceOptionDropdowns.priceTagHeader[
                                        keyName
                                      ].option
                                    }
                                    _placeholder={'선택'}
                                    _value={priceTableOptionValues[tagName]}
                                    _emitValue={(value) => {
                                      handlePriceTableOptionValues(
                                        tagName,
                                        value
                                      )
                                    }}
                                  />
                                </BoxRowComponent>
                              )
                            })}
                          </>
                        )}
                        {objectKeys(priceOptionDropdowns.serviceOptions)
                          .length > 0 && (
                          <>
                            {objectKeys(
                              priceOptionDropdowns.serviceOptions
                            ).map((keyName, keyIndex) => {
                              return (
                                <BoxRowComponent
                                  _label={keyName}
                                  key={keyName + keyIndex}
                                >
                                  <Dropdown.Underline
                                    _list={
                                      priceOptionDropdowns.serviceOptions[
                                        keyName
                                      ].option
                                    }
                                    _placeholder={'선택'}
                                    _value={optionPriceValues[keyName] ?? null}
                                    _emitValue={(value) => {
                                      handleOptionPriceValue(keyName, value)
                                    }}
                                  />
                                </BoxRowComponent>
                              )
                            })}
                          </>
                        )}
                      </>
                    )}
                    {quantityTitle && (
                      <BoxRowComponent _label={quantityTitle.name}>
                        <UnderlineNumberInput
                          value={serviceQuantity}
                          handleValue={handleServiceQuantity}
                        />
                      </BoxRowComponent>
                    )}
                    {materialItemList.length > 0 && (
                      <>
                        <BoxRowComponent
                          _label={'자재'}
                          _contents={
                            <>
                              {!selectedMaterial ? (
                                <PrimaryOutlineButton
                                  _css={css`
                                    padding: 5px 0;
                                    width: 100%;
                                  `}
                                  onClick={() => {
                                    set_openMaterial(true)
                                  }}
                                >
                                  입력
                                </PrimaryOutlineButton>
                              ) : (
                                <>
                                  <Flex
                                    justifyContent={'space-between'}
                                    _css={css`
                                      div {
                                        text-align: left;
                                      }
                                    `}
                                  >
                                    <div>
                                      <div>{selectedMaterial.name}</div>
                                      <div>
                                        {thousand(selectedMaterial.count ?? 0)}
                                        개
                                      </div>
                                    </div>
                                    <GrayScaleFill
                                      _mini
                                      padding={`5px 0`}
                                      width={97}
                                      onClick={() => {
                                        handleSelectedMaterial(null)
                                      }}
                                    >
                                      삭제
                                    </GrayScaleFill>
                                  </Flex>
                                </>
                              )}
                            </>
                          }
                        />
                      </>
                    )}
                    {!checkOptions && lastSelectedServiceCategoryId && (
                      <>
                        <BoxRowComponent
                          _label={'시공 요청 사항'}
                          className="flex-start"
                        >
                          <BoxTextarea
                            placeholder="내용 입력"
                            value={inputs.serviceItem.serviceRequests}
                            onChange={(e) => {
                              handleServiceRequest(e.target.value)
                            }}
                          />
                        </BoxRowComponent>
                      </>
                    )}
                    <BoxRowComponent _label={''}>
                      {!inputs.serviceItem.serviceCategoryId ? (
                        <>
                          <PrimaryButton
                            disabled={
                              !lastSelectedServiceCategoryId || checkOptions
                            }
                            padding={'5px 0px;'}
                            width={'100%'}
                            onClick={async () => {
                              await getCurrentServicePrice()
                            }}
                          >
                            추가
                          </PrimaryButton>
                        </>
                      ) : (
                        <>
                          <GrayScaleOutline
                            padding={'5px 0px;'}
                            width={'100%'}
                            disabled={
                              !lastSelectedServiceCategoryId || checkOptions
                            }
                            onClick={async () => {
                              if (selectedIsEstimates) {
                                set_openModal({
                                  title: '시공 정보 변경',
                                  content: `시공 정보가 ‘문의'로 되어있습니다.
                                  서비스를 선택해야 견적서를 전송할 수 있습니다.`,
                                })
                                return
                              }
                              set_openModal({
                                title: '시공 정보 변경',
                                content: '변경하시면 가격도 함께 변경됩니다.',
                                txt: ['취소', '변경'],
                                onSubmit: async () => {
                                  await getCurrentServicePrice()
                                  set_openModal(null)
                                },
                              })
                            }}
                          >
                            수정
                          </GrayScaleOutline>
                        </>
                      )}
                    </BoxRowComponent>
                  </>
                </ContentRadiusBox>
              ) : (
                <>
                  <NotFoundItem
                    _css={css`
                      div.not-found {
                        color: ${colors['Grayscale/Gray Light']};
                        ${typography['Body/Caption/Bold']}
                      }
                    `}
                  >
                    회원정보 및 주소지 정보 먼저 선택해주세요.
                  </NotFoundItem>
                </>
              )}
            </BoxLayout>
            {!id && (
              <BoxLayout _title={'방문시 참고사항'}>
                <ContentRadiusBox gap={12}>
                  <BoxRowComponent _label={'방문 희망 일자'}>
                    <DatePicker
                      _value={
                        inputs.desiredDate ? dayjs(inputs.desiredDate) : null
                      }
                      _emitValue={(value) =>
                        handleInput('desiredDate', value.format('YYYY-MM-DD'))
                      }
                    />
                  </BoxRowComponent>
                  <BoxRowComponent _label={'방문(예정) 일자'}>
                    <DatePicker
                      _value={
                        inputs.scheduledDate
                          ? dayjs(inputs.scheduledDate)
                          : null
                      }
                      _emitValue={(value) =>
                        handleInput('scheduledDate', value.format('YYYY-MM-DD'))
                      }
                    />
                  </BoxRowComponent>
                  <BoxRowComponent _label={'무료주차'}>
                    <Dropdown.Underline
                      _list={FREE_PARKING_DROPDOWN_LIST}
                      _value={inputs.freeParking}
                      _emitValue={(value) => {
                        handleInput('freeParking', value)
                      }}
                    />
                  </BoxRowComponent>
                  <BoxRowComponent _label={'주차 방법'} className="flex-start">
                    <BoxTextarea
                      value={inputs.parkingMethod}
                      onChange={(e) => {
                        handleInput('parkingMethod', e.target.value)
                      }}
                      placeholder="입력"
                    />
                  </BoxRowComponent>
                  <BoxRowComponent _label={'거주 여부'}>
                    <Dropdown.Underline
                      _list={LIVE_IN_DROPDOWN_LIST}
                      _value={inputs.liveIn}
                      _emitValue={(value) => {
                        handleInput('liveIn', value)
                      }}
                    />
                  </BoxRowComponent>
                  <BoxRowComponent _label={'비밀 번호'}>
                    <Dropdown.Underline
                      _list={HAS_PASSWORD_DROPDOWN_LIST}
                      _value={inputs.hasPassword}
                      _emitValue={(value) => {
                        handleInput('hasPassword', value)
                      }}
                    />
                  </BoxRowComponent>
                  {inputs.hasPassword === 2 && (
                    <>
                      <BoxRowComponent _label={'현관 비밀 번호 *'}>
                        <UnderlineInput
                          placeholder="내용 입력"
                          value={inputs.frontPwd}
                          handleValue={(value) => {
                            handleInput('frontPwd', value)
                          }}
                        />
                      </BoxRowComponent>
                      <BoxRowComponent
                        _label={'자택 비밀 번호'}
                        className="flex-start"
                      >
                        <BoxTextarea
                          value={inputs.doorPwd}
                          onChange={(e) => {
                            handleInput('doorPwd', e.target.value)
                          }}
                          placeholder="입력"
                        />
                      </BoxRowComponent>
                    </>
                  )}
                  <BoxRowComponent _label={'소통 방법'}>
                    <Dropdown.Underline
                      _list={COMMUNICATION_METHODS_DROPDOWN_LIST}
                      _value={inputs.communicationMethod}
                      _emitValue={(value) => {
                        handleInput('communicationMethod', value)
                      }}
                    />
                  </BoxRowComponent>
                </ContentRadiusBox>
              </BoxLayout>
            )}
            <BoxLayout _title={'사진, 요청사항'}>
              <ContentRadiusBox>
                <BoxRowComponent
                  _label={'사진'}
                  _css={css`
                    align-items: flex-start;
                    & > label {
                      padding: 12px 0;
                    }
                  `}
                >
                  <FlexRow gap={24} alignItems="flex-start">
                    <A.Label htmlFor={'request-images'}>
                      <A.Upload style={{ marginTop: '7px' }}>
                        사진 업로드
                      </A.Upload>
                      <input
                        id={'request-images'}
                        type="file"
                        hidden
                        multiple={true}
                        value={files}
                        accept={FILE_TYPE['image']}
                        onChange={async (e) => {
                          set_files(e.target.value)
                          const getFiles = e.target.files as FileList
                          if (getFiles) {
                            await handleUpload(...getFiles)
                            setTimeout(() => {
                              set_files('')
                            }, 0)
                          }
                        }}
                      />
                    </A.Label>
                    {inputs.requestImages.length > 0 ? (
                      <A.Files
                        _css={css`
                          max-width: calc(100% - 84px - 24px);
                        `}
                      >
                        {inputs.requestImages.map((imageUrl) => {
                          return (
                            <FlexRow
                              _css={css`
                                position: relative;
                                width: 100%;
                              `}
                              key={imageUrl.name}
                              gap={10}
                              alignItems="center"
                              onMouseEnter={(e) =>
                                HandleEllipsisToolTip('mouseEnter', e)
                              }
                              onMouseLeave={(e) =>
                                HandleEllipsisToolTip('mouseLeave', e)
                              }
                            >
                              <A.File>
                                <Link
                                  to={imageUrl.url}
                                  download={imageUrl.name}
                                  target="_blank"
                                  {...(!imageUrl.url.includes('http') && {
                                    onClick: (e) => {
                                      e.preventDefault()
                                      blobNewWindow(imageUrl.url)
                                    },
                                  })}
                                >
                                  {imageUrl.name}
                                </Link>
                              </A.File>
                              <img
                                src={ImagesInputRemove}
                                alt="text remove"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  handleInput('requestImages', (prev) =>
                                    prev.requestImages.filter((galleryUrl) => {
                                      return galleryUrl !== imageUrl
                                    })
                                  )
                                }}
                              />
                            </FlexRow>
                          )
                        })}
                      </A.Files>
                    ) : (
                      <TXT
                        _textStyle="Body/Large/Bold"
                        _color="Grayscale/Gray Light"
                        _css={css`
                          padding: 10px 0;
                        `}
                      >
                        {`등록된 사진 없음`}
                      </TXT>
                    )}
                  </FlexRow>
                </BoxRowComponent>
                <BoxRowComponent _label={'요청 사항'} className="flex-start">
                  <BoxTextarea
                    value={inputs.requests}
                    onChange={(e) => {
                      handleInput('requests', e.target.value)
                    }}
                    placeholder="입력"
                  />
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _bottomRightControls={
          <L.RightBottom>
            <GrayScaleOutline
              width={160}
              height={48}
              padding={`12px 0`}
              onClick={() => {
                navigate('/work/estimates')
              }}
            >
              취소
            </GrayScaleOutline>
            <PrimaryButton
              _css={css`
                flex: 1;
              `}
              height={48}
              padding={`12px 0`}
              onClick={handleSubmit}
              disabled={trueWhenEmpty}
            >
              견적서 전송
            </PrimaryButton>
          </L.RightBottom>
        }
      />
    </>
  )
}
export default AddEstimates
