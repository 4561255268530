import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import Copy from 'components/DetailsComponents/Copy'
import dayjs from 'dayjs'
import { OrderInfoValuesType } from 'pages/Work/Work.types'
import { ContentRadiusBox } from 'themes/Shared.styles'

interface OrderInfoProps<T extends OrderInfoValuesType> {
  inputs: Pick<T, keyof OrderInfoValuesType>
}
const OrderInfo = <T extends OrderInfoValuesType>({
  inputs,
  ...props
}: OrderInfoProps<T>) => {
  return (
    <>
      <ContentRadiusBox>
        <BoxRowComponent
          _label={`주문 일시`}
          _contents={
            inputs.orderDate
              ? dayjs(inputs.orderDate).format('YYYY-MM-DD HH:mm')
              : '-'
          }
        />
        <BoxRowComponent _label={`결제 방법`} _contents={inputs.payMethod} />
        <BoxRowComponent
          _label={`주문 번호`}
          _contents={<Copy _text={inputs.orderNumber} />}
        />
      </ContentRadiusBox>
    </>
  )
}
export default OrderInfo
