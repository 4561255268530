import styled from '@emotion/styled/macro'
import { buttonAssets } from 'components/Buttons/Buttons.styles'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const FileUploadBoxStyle = {
  Wrap: styled.div`
    & {
      width: 100%;
      display: flex;
      gap: 24px;
    }
    input {
      display: none;
      visibility: hidden;
    }
    label {
      cursor: pointer;
      user-select: none;
    }
    &.disabled {
      label {
        cursor: default;
        background-color: transparent;
        svg {
          path {
            fill-opacity: 0.1;
          }
        }
      }
    }
    ${addCssProps}
  `,

  InputWrap: styled.div`
    padding: 7px 0;
    display: block;
    button {
      ${typography['Body/Small/Bold']}
    }
  `,
  Files: styled.div`
    max-width: calc(100% - 110px);

    display: flex;
    flex-direction: column;
    padding: 10px 0;
    gap: 12px;
    ${addCssProps}
  `,
  File: styled.div`
    position: relative;
    ${typography['Body/Large/Underline bold']}
    color: ${colors['Primary/Default']};
    border-bottom: 1px solid ${colors['Primary/Default']};
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  UploadStateBox: styled.div`
    position: relative;
    max-width: 100%;
    ${typography['Body/Large/Bold']};
    color: ${colors['Grayscale/Gray Light']};
  `,
  Upload: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${buttonAssets({
      _mini: true,
      disabledType: 'fill',
      borderColor: 'Primary/Default',
      backgroundColor: 'White/White off',
      hoverBackgroundColor: 'Grayscale/Background Dark',
      textColor: 'Primary/Default',
    })}
    border-radius: 8px;
    height: 30px;

    width: 86px;
    ${addCssProps}
  `,
}

export default FileUploadBoxStyle
