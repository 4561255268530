import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '.'

interface refreshState {
  value: number | null
}
const initialState: refreshState = {
  value: null,
}

export const refreshSlice = createSlice({
  name: 'refresh',
  initialState,
  reducers: {
    setRefresh: (state, action: { payload: number }) => {
      state.value = action.payload
    },
    cleanRefresh: (state) => {
      state.value = initialState.value
    },
  },
})
export const { setRefresh, cleanRefresh } = refreshSlice.actions

export const selectRefresh = (state: RootState) => state.refresh.value

export default refreshSlice.reducer
