import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import { AuthorizationDefault } from '../Authorization.styles'

const CertificationStyle = {
  Wrap: styled.div`
    ${AuthorizationDefault}
    ${addCssProps}
  `,
  PendingPart: styled.div`
    position: relative;
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    user-select: none;
    img {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      text-align: center;
    }
    h2 {
      ${typography['Display/2 Bold']}
      color: ${colors['White/White off']};
    }
    p {
      margin-top: 8px;
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Light']};
    }
  `,
}
export default CertificationStyle
