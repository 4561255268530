import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { CSSProperties } from 'react'
import { colors, typography } from 'themes/styles'
import { AdditionalCss } from 'themes/styles.type'

const TextArea = styled.textarea(
  ({
    minHeight,
    _css,
  }: {
    _css?: AdditionalCss
    minHeight?: CSSProperties['minHeight']
  }) => css`
    resize: none;
    width: 100%;
    min-height: ${minHeight ?? 'auto'};
    padding: 12px 16px;
    background-color: #fff;
    border: 1px solid ${colors['Grayscale/Gray Lighter']};
    color: ${colors['Grayscale/Black light']};
    caret-color: ${colors['Primary/Default']};
    ${typography['Body/Large/Bold']}
    border-radius: 16px;
    &::placeholder {
      color: ${colors['Grayscale/Gray Lighter']};
    }
    &:focus {
      border-color: ${colors['Primary/Default']};
      outline: none;
    }
    ${_css ? _css : ''}
  `
)

export default TextArea
