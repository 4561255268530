import { MeasurementValuesType } from 'pages/Work/Work.types'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export const MEASUREMENT_INITIAL_VALUE: MeasurementValuesType = {
  id: null,
  title: '',
  size: '',
  사진: [],
  '360도 사진': [],
  etc: '',
}

type GalleryRequiredId = {
  taskId?: string
  householdsId?: string
}
export interface MeasurementInfoProps extends DivAttributes {
  _css?: AdditionalCss
  _open: boolean
  _onClose: () => void
  _emitValue: (value: MeasurementValuesType) => void
  _id?: number | string
  _galleryId: GalleryRequiredId
  _item?: MeasurementValuesType
}
