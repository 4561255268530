import AdminDetails from 'pages/System/Admin/AdminDetails'
import AdminList from 'pages/System/Admin/AdminList'
import { useParams } from 'react-router-dom'

export interface AdminProps {}

const Admin = (props: AdminProps) => {
  const { id } = useParams<'id'>()

  return (
    <>
      {!id && <AdminList />}
      {id && <AdminDetails _id={id} />}
    </>
  )
}
export default Admin
