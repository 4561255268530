import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const ModifySettlementRatioStyle = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 654px;
    ${addCssProps}
  `,
  Label: styled.div`
    ${typography['Body/Caption/Regular']}
    color: ${colors['Grayscale/Gray Dark']};
    white-space: nowrap;
  `,
  Price: styled.div`
    ${typography['Body/Caption/Bold']}
    color: ${colors['Grayscale/Gray Dark']};
    white-space: nowrap;
  `,
  Divide: styled.div`
    width: 1px;
    height: 10px;
    background-color: ${colors['Grayscale/Gray Lighter']};
  `,
  TableBox: styled.div`
    overflow: auto;
    flex: 1;
  `,
  SeparatedTable: styled.div`
    width: 100%;
    .thead {
      width: 100%;
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Dark']};
      & > div {
        flex: 1;
        padding: 14px 12px;
      }
      margin-bottom: 7px;
    }
    .tbody {
      width: 100%;
      ${typography['Body/Large/Bold']}
      color: ${colors['Grayscale/Black light']};
    }
    .tr {
      padding: 16px;
      border: 1px solid ${colors['Grayscale/Gray Lighter']};
      border-radius: 16px;
      & > div {
        flex: 1;
        padding: 22px 12px;
        white-space: nowrap;
        min-width: auto;
        width: auto;
        max-width: auto;
        &:last-of-type {
          text-align: right;
        }
      }
    }
    .thead {
      & > div:first-of-type {
        margin-left: 16px;
      }
      & > div:last-of-type {
        margin-right: 16px;
        text-align: right;
      }
    }
  `,
}

export default ModifySettlementRatioStyle
