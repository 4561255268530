import { ImagesDropdownDown, ImagesDropdownUp } from 'assets'
import {
  dropdownListOnkeyDown,
  dropdownWrapperOnkeyDown,
} from 'components/Dropdown/Dropdown.helpers'
import useOutsideEvent from 'hooks/useOutsideEvent'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { cn, getObjectLabel } from 'utils/helpers'
import { DropdownListType } from '../Dropdown.types'
import S from './UnderlineDropdown.styles'
import { UnderlineDropdownProps } from './UnderlineDropdown.types'

const UnderlineDropdown = <T extends DropdownListType>({
  _css,
  _value,
  _list,
  _emitValue,
  _parseLabel,
  className,
  _placeholder = '선택',
  _disabled,
  _autoWidth,
  ...props
}: UnderlineDropdownProps<T>) => {
  const listRef = useRef() as MutableRefObject<HTMLDivElement>
  const beforeRef = useRef() as MutableRefObject<HTMLDivElement>
  const wrapRef = useRef() as MutableRefObject<HTMLDivElement>

  const [label, set_label] = useState<Extract<keyof T, string | number> | null>(
    null
  )
  const [listWidthSize, set_listWidthSize] = useState<number>(0)
  const [open, set_open] = useState<T>()
  useEffect(() => {
    if ((typeof _value === 'string' && _value) || typeof _value === 'number') {
      set_label(getObjectLabel(_list, _value))
    } else {
      set_label(null)
    }
  }, [_list, _value])
  useOutsideEvent(wrapRef.current, () => {
    set_open(undefined)
  })
  useEffect(() => {
    if (_list && beforeRef.current) {
      const listWidth = beforeRef.current.getBoundingClientRect().width
      set_listWidthSize(listWidth)
    }
  }, [_list])
  return (
    <>
      <S.Wrap
        _css={_css}
        ref={wrapRef}
        tabIndex={0}
        className={cn({ placeholder: !label, active: open }, className)}
        onKeyDown={(e) => {
          dropdownWrapperOnkeyDown(e, listRef.current, (e) => {
            if (e.code === 'Enter') {
              set_open((prev) => (prev ? undefined : _list))
            }
          })
        }}
        {...props}
      >
        <S.Label
          onClick={(e) => {
            if (_disabled) return
            e.stopPropagation()
            set_open((prev) => (prev ? undefined : _list))
          }}
          className={cn({
            placeholder: !label,
            active: open,
            disabled: _disabled,
          })}
          {...(_autoWidth &&
            listWidthSize && {
              style: {
                width: `${listWidthSize}px`,
              },
            })}
        >
          <label>
            {label
              ? _parseLabel
                ? _parseLabel(String(label))
                : label
              : _placeholder}
          </label>
          {!open && <img src={ImagesDropdownDown} alt="closed" />}
          {open && <img src={ImagesDropdownUp} alt="opened" />}
        </S.Label>
        {_list && listWidthSize === 0 && (
          <S.ListWrap style={{ opacity: 0 }}>
            <S.List>
              <div ref={beforeRef}>
                {(
                  Object.keys(_list) as Array<Extract<keyof T, string | number>>
                ).map((keyName) => {
                  return (
                    <S.Item key={keyName} className="dropdown-item before-item">
                      {keyName}
                    </S.Item>
                  )
                })}
              </div>
            </S.List>
          </S.ListWrap>
        )}
        {open && (
          <S.ListWrap>
            <S.List>
              <div ref={listRef}>
                {(
                  Object.keys(open) as Array<Extract<keyof T, string | number>>
                ).map((keyName) => {
                  return (
                    <S.Item
                      tabIndex={0}
                      key={keyName}
                      className="dropdown-item"
                      data-active={
                        _value === open[keyName] ? 'active' : 'inactive'
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        if (_emitValue) _emitValue(open[keyName])
                        set_open(undefined)
                        if (wrapRef.current) {
                          wrapRef.current.focus()
                        }
                      }}
                      onKeyDown={(e) => {
                        dropdownListOnkeyDown(e, listRef.current, (e) => {
                          if (e.code === 'Enter' && open) {
                            if (_emitValue) _emitValue(open[keyName])
                            set_open(undefined)
                            if (wrapRef.current) {
                              wrapRef.current.focus()
                            }
                          }
                        })
                      }}
                    >
                      {_parseLabel ? _parseLabel(String(keyName)) : keyName}
                    </S.Item>
                  )
                })}
              </div>
            </S.List>
          </S.ListWrap>
        )}
      </S.Wrap>
    </>
  )
}
export default UnderlineDropdown
