import {
  IconsDecreaseGrowth,
  IconsFlatGrowth,
  IconsIncreaseGrowth
} from 'assets'
import { SvgWrap } from 'themes/Shared.styles'
import { thousand } from 'utils/helpers'
import S from './UserBox.styles'

type growthState = 'increase' | 'decrease' | 'flat'

export interface UserBoxProps {
  _label?: string
  _growth: {
    state: growthState
    value?: number
  }
  _total: number
}

export const UserBox = ({
  _label,
  _growth,
  _total,
  ...props
}: UserBoxProps) => {
  const getStateSvg = (state: growthState) => {
    if (state === 'increase') {
      return (
        <SvgWrap _size={24} _svgSize={{ width: 12, height: 10 }}>
          <IconsIncreaseGrowth />
        </SvgWrap>
      )
    }

    if (state === 'decrease') {
      return (
        <SvgWrap _size={24} _svgSize={{ width: 12, height: 10 }}>
          <IconsDecreaseGrowth />
        </SvgWrap>
      )
    }
    if (state === 'flat') {
      return (
        <SvgWrap _size={24} _svgSize={{ width: 12, height: 10 }}>
          <IconsFlatGrowth />
        </SvgWrap>
      )
    }
  }
  return (
    <S.Wrapper>
      <div className="label">{_label}</div>
      <div className="arrow">
        {getStateSvg(_growth.state)}
        {_growth.state !== 'flat' && (
          <div className={_growth.state}>{_growth.value}</div>
        )}
      </div>
      <div className="number">
        {thousand(_total)}
        <span>명</span>
      </div>
    </S.Wrapper>
  )
}
