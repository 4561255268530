import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect } from 'react'
import { selectAuth, setAuth, TokenType } from 'store/authSlice'
import { checkToken } from 'utils/callAxios'

const useAuthGuard = () => {
  const { status } = useAppSelector(selectAuth)
  const dispatch = useAppDispatch()
  useEffect(() => {
    const deny = () => {
      localStorage.clear()
      dispatch(setAuth({ status: 'unauthorized', token: null }))
    }
    const authorized = (token: TokenType) => {
      if (!token) {
        deny()
        return
      }
      dispatch(setAuth({ status: 'authorized', token, isSuperAdmin: false }))
    }
    const authInit = async () => {
      const token = await checkToken()
      if (!token) {
        deny()
        return
      }
      authorized(token)
    }
    if (status === 'idle') {
      authInit()
    }
  }, [status, dispatch])
  return status
}
export default useAuthGuard
