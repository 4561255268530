import { Dayjs } from 'dayjs'
import { TargetUser } from 'pages/Support/support.types'

export const POINT_PUBLISH_INITIAL_VALUE: PointIssueMatchInput = {
  startAt: null,
  endAt: null,
  note: '',
  amount: null,
  userList: [],
}

export interface PointIssueMatchInput {
  startAt: Dayjs | null
  endAt: Dayjs | null
  note: string
  amount: number | null
  userList: TargetUser[]
}
// API
export interface APIIssuePoint {
  startAt: string
  endAt: string
  note: string
  amount: number
  userList: TargetUser[]
}
