import { API_URL_KEYS } from 'API_URL'
import {
  ImagesGalleryAdd,
  ImagesGalleryChecked,
  ImagesGalleryChevronLeft,
  ImagesGalleryChevronRight,
  ImagesGalleryUnChecked,
} from 'assets'
import ImageWithLoading from 'components/Gallery/ImageWithLoading'
import Panorama from 'components/Gallery/Panorama'
import { useAppDispatch } from 'hooks/reduxHooks'
import { clone } from 'lodash-es'
import {
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  CurrentGalleryTarget,
  GalleryItemType,
  PhotoWithPanoramaType,
  isDoubleDArray,
  isGalleryChildrenListType,
  saveToResultAndClose,
  setCurrentGallery,
  setGallery,
} from 'store/gallerySlice'
import { setToast } from 'store/toastSlice'
import { DoubleDFile } from 'types/services.type'
import callAxios, { handleError } from 'utils/callAxios'
import {
  cn,
  getFileNameFromUrl,
  getFileToBlob,
  smoothLeftTo,
} from 'utils/helpers'
import { callFileUpload } from 'utils/services'
import S from './Gallery.styles'
import { GalleryListType, GalleryProps } from './Gallery.types'

const Gallery = ({ _css, _item, ...props }: GalleryProps) => {
  const { data, target, id, requestId } = _item
  const [imageGroups, set_imageGroups] = useState<GalleryItemType | null>(null)

  const syncWithStore = useCallback(() => {
    set_imageGroups(data ?? null)
  }, [data])
  useEffect(() => {
    syncWithStore()
  }, [syncWithStore])
  const dispatch = useAppDispatch()
  const [files, set_files] = useState<string>('')
  const [selected, set_selected] = useState<number[]>([])
  const [willDelete, set_willDelete] = useState<DoubleDFile[]>([])
  const [editMode, set_editMode] = useState<boolean>(false)
  const dragXConfigRef = useRef({
    startX: 0,
    prevX: 0,
    draggable: false,
    dragged: false,
  })
  const [currentTarget, set_currentTarget] =
    useState<CurrentGalleryTarget | null>(null)
  const [currentIndex, set_currentIndex] = useState<number>(0)

  const removeAble = useMemo(() => {
    return selected.length > 0
  }, [selected])

  const getList = useCallback((value: GalleryItemType | null) => {
    if (!value) return []
    const listAble = (
      Object.keys(value) as Array<keyof GalleryItemType>
    ).reduce((prev, curr) => {
      let galleryItem = value[curr]
      if (isDoubleDArray(galleryItem) && galleryItem.length > 0) {
        prev = [...prev, { title: curr, list: galleryItem }]
      }
      if (isGalleryChildrenListType(galleryItem) && galleryItem.length > 0) {
        prev = [...prev, { title: curr, child: galleryItem }]
      }
      return prev
    }, [] as GalleryListType[])
    return listAble
  }, [])

  const List = useMemo(() => {
    return getList(data)
  }, [data, getList])

  const getImages = useCallback(
    (
      imageItems: GalleryItemType | null,
      target: CurrentGalleryTarget | null
    ) => {
      if (!imageItems) return []
      if (!target) return []
      let galleryItem = imageItems[target[0]]
      if (isDoubleDArray(galleryItem) && target.length === 1) {
        return galleryItem
      } else if (
        isGalleryChildrenListType(galleryItem) &&
        target.length === 3
      ) {
        const middleTarget = target[1]
        const lastTarget = target[2]
        return (
          galleryItem.find((item) => item.id === middleTarget)?.list?.[
            lastTarget
          ] ?? []
        )
      }
      return []
    },
    []
  )

  const currentImages = useMemo(() => {
    return getImages(imageGroups, currentTarget)
  }, [currentTarget, imageGroups, getImages])

  const parseCurrentTarget = useCallback(
    (value: CurrentGalleryTarget | null) => {
      if (!value) return null
      const firstCategory = value[0]
      const childId = value[1] ?? ''
      const firstItem = _item.data[firstCategory]
      const title =
        childId && isGalleryChildrenListType(firstItem)
          ? firstItem.find((item) => item.id === childId)?.title ?? ''
          : ''
      const categoryId =
        childId && isGalleryChildrenListType(firstItem)
          ? firstItem.find((item) => item.id === childId)?.id ?? ''
          : ''
      const lastKind = (value[2] ?? '') as keyof PhotoWithPanoramaType
      return {
        id: _item.id, // task id 또는 households id
        firstCategory,
        title,
        lastKind,
        categoryId,
        image360: lastKind === '360 사진',
      }
    },
    [_item.data, _item.id]
  )

  const currentCategoryTarget = useMemo(() => {
    return parseCurrentTarget(currentTarget)
  }, [currentTarget, parseCurrentTarget])
  const deleteQueueImages = useMemo(() => {
    return willDelete.map((item) => {
      return {
        ...currentCategoryTarget,
        url: item,
        fileName: item.name ? item.name : getFileNameFromUrl(item.url),
      }
    })
  }, [currentCategoryTarget, willDelete])

  useEffect(() => {
    set_currentTarget(target ?? ['요청 사진'])
  }, [target])

  const dragRef = useRef() as MutableRefObject<HTMLDivElement>
  const handleIndex = (type: 'prev' | 'next') => {
    if (!imageGroups) return
    if (!currentImages) return
    let nextIndex = currentIndex
    if (type === 'next') {
      nextIndex =
        currentIndex + 1 > currentImages.length - 1
          ? currentImages.length - 1
          : currentIndex + 1
    }
    if (type === 'prev') {
      nextIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1
    }
    const thumbnailElement = document.querySelector(
      `.thumbnail[data-index="${nextIndex}"]`
    ) as HTMLElement
    if (thumbnailElement) {
      const thumbnailsRect = dragRef.current.getBoundingClientRect()
      smoothLeftTo(
        thumbnailElement.offsetLeft - thumbnailsRect.left,
        dragRef.current
      )
    }
    set_currentIndex(nextIndex)
  }

  const handleSelected = (index: number) => {
    if (selected.includes(index)) {
      set_selected((prev) => prev.filter((i) => i !== index))
    } else {
      set_selected((prev) => [...prev, index])
    }
  }
  const handleDragEnd = (e: MouseEvent) => {
    const currentTarget = e.currentTarget as HTMLElement
    if (dragXConfigRef.current.draggable) {
      dragXConfigRef.current.draggable = false
    }
    if (!dragXConfigRef.current.dragged && e.type === 'mouseup') {
      const endTarget = e.target as HTMLElement
      const closestTarget = endTarget.closest(
        'div[data-filename]'
      ) as HTMLElement
      if (closestTarget?.classList?.contains('thumbnail')) {
        const nextIndex = closestTarget.getAttribute('data-index')!
        if (editMode) {
          handleSelected(Number(nextIndex))
        } else {
          set_currentIndex(Number(nextIndex))
        }
      }
    }
    currentTarget.removeEventListener('mousemove', handleDragMove)
    currentTarget.removeEventListener('mouseup', handleDragEnd)
    currentTarget.removeEventListener('mouseleave', handleDragEnd)
  }
  const handleDragMove = (e: MouseEvent) => {
    const currentTarget = e.currentTarget as HTMLElement
    if (dragXConfigRef.current.draggable) {
      const moveX = dragXConfigRef.current.startX - e.pageX
      if (Math.abs(moveX) > 1) {
        dragXConfigRef.current.dragged = true
      }
      currentTarget.scrollLeft = dragXConfigRef.current.prevX + moveX
    }
  }

  const handleDragDown = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement
    const currentTarget = e.currentTarget as HTMLElement
    if (target.closest('.add')) {
      currentTarget.addEventListener('mouseup', handleDragEnd)
      return
    }
    dragXConfigRef.current = {
      ...dragXConfigRef.current,
      draggable: true,
      dragged: false,
      startX: e.pageX,
      prevX: currentTarget.scrollLeft,
    }
    currentTarget.addEventListener('mousemove', handleDragMove)
    currentTarget.addEventListener('mouseup', handleDragEnd)
    currentTarget.addEventListener('mouseleave', handleDragEnd)
  }
  const handleSave = async (nextImages?: GalleryItemType) => {
    if (!imageGroups) return
    await bulkDelete()

    // dispatch(setGallery({ ..._item, data: nextImages ?? imageGroups }))
    restoreMode()
  }
  const restoreMode = () => {
    set_editMode(false)
    set_selected([])
  }

  const handleCancel = () => {
    syncWithStore()
    restoreMode()
  }
  const getImageApiUrl = () => {
    let firstCategory = currentCategoryTarget?.firstCategory
    let lastKind = currentCategoryTarget?.lastKind
    let categoryId = currentCategoryTarget?.categoryId
    let otherImages: DoubleDFile[] = []
    if (imageGroups && firstCategory) {
      const otherImagesList = imageGroups[firstCategory]
      if (
        firstCategory !== '요청 사진' &&
        isGalleryChildrenListType(otherImagesList)
      ) {
        const otherImageGroup = otherImagesList.find(
          (item) => item.id === categoryId
        )
        if (otherImageGroup) {
          if (lastKind === '360 사진') {
            otherImages = otherImageGroup.list['사진']
          } else {
            otherImages = otherImageGroup.list['360 사진']
          }
        }
      }
    }
    let optionValue: {
      [x: string]: string | number | boolean
    } = {}
    let currentCategory = currentCategoryTarget?.firstCategory
    let is360 = currentCategoryTarget?.image360 ?? false
    let uploadUrl: {
      type: API_URL_KEYS
      url: string
    } = {
      type: 'task',
      url: `/construction/image`,
    }
    let updateUrl = `/gallery/construction/image`
    if (currentCategory === '요청 사진') {
      updateUrl = `/gallery/service-requests/image`
      uploadUrl = {
        type: 'order',
        url: `/estimates/file`,
      }
      optionValue = {}
    }
    if (currentCategory === '실측 사진') {
      updateUrl = `/gallery/measurement/image`
      uploadUrl = {
        type: 'task',
        url: `/measurement/image`,
      }
      optionValue = {
        isImage360: is360,
      }
    }
    if (currentCategory === '시공전 사진') {
      optionValue = {
        isImage360: is360,
        isBefore: true,
      }
    }
    if (currentCategory === '시공후 사진') {
      optionValue = {
        isImage360: is360,
        isBefore: false,
      }
    }
    return {
      uploadUrl,
      updateUrl,
      optionValue,
      is360,
      otherImages,
    }
  }
  // uploadedList 없으면 삭제시 현재 리스트로 업데이트, 추가시엔 변경된 값을 넣어줌
  const callUpdate = async (uploadedList: DoubleDFile[] = []) => {
    if (!currentCategoryTarget) return
    const { updateUrl, is360, otherImages } = getImageApiUrl()
    const { lastKind, firstCategory, categoryId } = currentCategoryTarget
    let updateData: {
      id: number | string
      image: string[]
      image360?: string
    } = {
      id: requestId ?? '',
      image: [
        ...currentImages.map((item) => item.name),
        ...uploadedList.map((item) => item.name),
      ],
    }
    if (lastKind) {
      updateData = {
        id: currentCategoryTarget?.categoryId,
        image: is360
          ? [...otherImages.map((item) => item.name)]
          : [
              ...currentImages.map((item) => item.name),
              ...uploadedList.map((item) => item.name),
            ],
        image360: is360
          ? uploadedList
            ? uploadedList.map((item) => item.name)[0] ?? null
            : currentImages.map((item) => item.name)[0] ?? null
          : otherImages.map((item) => item.name)[0] ?? null,
        ...(firstCategory === '시공전 사진' && {
          isBefore: true,
        }),
        ...(firstCategory === '시공후 사진' && {
          isBefore: false,
        }),
      }
    }
    if (!updateData.id) {
      dispatch(setToast(['잘못된 요청입니다.', 'error']))
      return
    }
    const res = await callAxios(`task`).put<
      DoubleDResponse<{ imageUrl: string[]; image360Url: string | null }>
    >(updateUrl, updateData)
    let nextImages = clone(imageGroups)
    if (res.data.data && nextImages) {
      const responseItem: DoubleDFile[] = is360
        ? res.data.data.image360Url
          ? [
              {
                id: getFileNameFromUrl(res.data.data.image360Url),
                name: getFileNameFromUrl(res.data.data.image360Url),
                url: res.data.data.image360Url,
              },
            ]
          : []
        : res.data.data.imageUrl.map((url) => ({
            id: getFileNameFromUrl(url),
            name: getFileNameFromUrl(url),
            url,
          }))
      if (
        lastKind &&
        firstCategory !== '요청 사진' &&
        nextImages[firstCategory]
      ) {
        nextImages = {
          ...nextImages,
          [firstCategory]: nextImages[firstCategory]
            ? nextImages[firstCategory]?.map((item) =>
                item.id === categoryId
                  ? {
                      ...item,
                      list: {
                        ...item.list,
                        [lastKind]: responseItem,
                      },
                    }
                  : item
              )
            : null,
        }
      } else {
        nextImages = { ...nextImages, '요청 사진': responseItem }
      }
      set_imageGroups(nextImages)
      dispatch(
        setCurrentGallery({
          ..._item,
          data: nextImages,
        })
      )
      dispatch(
        setGallery({
          ..._item,
          data: nextImages,
        })
      )
      set_files('')
      if (uploadedList) {
        setTimeout(() => {
          set_currentIndex((prev) => responseItem.length - 1 + files.length)
          smoothLeftTo(dragRef.current.scrollWidth, dragRef.current)
        }, 200)
      }
    }
  }
  const handleAlignIndex = (
    nextImages: GalleryItemType,
    currentFocused?: DoubleDFile
  ) => {
    if (!currentTarget) return
    const galleryItem = getImages(nextImages, currentTarget)
    const existItem = galleryItem.findIndex(
      (item) => item.id === currentFocused?.id
    )
    if (existItem > -1) {
      set_currentIndex(existItem)
    } else {
      set_currentIndex(galleryItem[currentIndex - 1] ? currentIndex - 1 : 0)
    }
  }
  const bulkDelete = async () => {
    if (deleteQueueImages.length < 1) return
    if (!currentCategoryTarget) return null
    try {
      const categoryCheck = currentCategoryTarget?.firstCategory
      await Promise.all(
        deleteQueueImages.map(async (item) => {
          const currentApiType =
            categoryCheck === '요청 사진' ? 'order' : 'task'
          const currentDeleteApiURL =
            categoryCheck === '요청 사진'
              ? '/estimates/file'
              : categoryCheck === '실측 사진'
              ? '/measurement/image'
              : '/construction/image'
          return {
            res: await callAxios(currentApiType).delete(currentDeleteApiURL, {
              data: {
                fileName: item.fileName,
                ...(categoryCheck !== '요청 사진' && {
                  isImage360: item.image360,
                }),
                ...(categoryCheck === '시공전 사진' && { isBefore: true }),
                ...(categoryCheck === '시공후 사진' && { isBefore: false }),
              },
            }),
          }
        })
      )
      await callUpdate()
    } catch (error) {
      handleError(error)
    }
  }
  const handleDelete = async () => {
    if (!imageGroups) return
    if (!currentTarget) return
    const currentSelected = [...selected]
    const currentFocused = currentImages[currentIndex]
    set_willDelete(
      currentImages.filter((_, itemIndex) => selected.includes(itemIndex))
    )
    let prevImages = [...currentImages]
    let nextImages: GalleryItemType = clone(imageGroups)
    const galleryItem = nextImages[currentTarget[0]]
    const firstCategory = currentTarget[0]
    const categoryId = currentCategoryTarget?.categoryId
    if (
      categoryId &&
      isGalleryChildrenListType(galleryItem) &&
      currentTarget.length === 3 &&
      firstCategory !== '요청 사진'
    ) {
      const lastTarget = currentTarget[2]
      const ChildItem = nextImages[firstCategory]
      nextImages = {
        ...nextImages,
        [firstCategory]:
          ChildItem !== null
            ? ChildItem.map((item) =>
                item.id === categoryId
                  ? {
                      ...item,
                      list: {
                        ...item.list,
                        [lastTarget]: item.list[lastTarget].filter(
                          (_, ftIndex) => {
                            return !currentSelected.includes(ftIndex)
                          }
                        ),
                      },
                    }
                  : item
              )
            : null,
      }
    } else if (isDoubleDArray(galleryItem) && currentTarget.length === 1) {
      nextImages = {
        ...nextImages,
        [currentTarget[0]]: prevImages.filter(
          (_, index) => !currentSelected.includes(index)
        ),
      }
    }

    handleAlignIndex(nextImages, currentFocused)
    set_imageGroups((prev) => {
      if (!prev) return prev
      return nextImages
    })
    set_selected([])
  }
  const handleCurrentTab = (galleryTarget: CurrentGalleryTarget | null) => {
    set_currentTarget(galleryTarget)
    set_currentIndex(0)
    restoreMode()
    syncWithStore()
  }

  const handleUpload = async (fileList: File[]) => {
    if (!imageGroups) return
    if (!currentCategoryTarget) return
    try {
      const { optionValue, uploadUrl } = getImageApiUrl()
      const uploadedList = (
        await Promise.all(
          fileList
            .map(async (file) => {
              const fileName = await callFileUpload(
                file,
                uploadUrl.type,
                uploadUrl.url,
                optionValue
              )
              const blob = await getFileToBlob(file)
              if (fileName) {
                return {
                  id: fileName ?? '',
                  name: fileName ?? '',
                  url: blob,
                }
              } else {
                return null
              }
            })
            .filter((ii) => !!ii)
        )
      ).filter((ii) => !!ii) as DoubleDFile[]
      if (uploadedList.length > 0) {
        await callUpdate(uploadedList)
      }
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <S.Header>
          <div className="left">
            <div className="title">
              {currentCategoryTarget?.firstCategory && (
                <>
                  {currentCategoryTarget?.firstCategory}
                  <img src={ImagesGalleryChevronRight} alt="" />
                </>
              )}
              {currentCategoryTarget?.title && (
                <>
                  {currentCategoryTarget?.title}
                  <img src={ImagesGalleryChevronRight} alt="" />
                </>
              )}
              {currentCategoryTarget?.lastKind && (
                <>{currentCategoryTarget?.lastKind}</>
              )}
            </div>
            <div className="count">
              {currentIndex + 1}/{currentImages.length}
            </div>
          </div>
          <div className="buttons">
            {editMode && (
              <>
                <S.GalleryButton onClick={() => handleSave()}>
                  저장
                </S.GalleryButton>
                <S.GalleryButton onClick={handleCancel}>취소</S.GalleryButton>
                {removeAble && (
                  <>
                    <S.GalleryButton onClick={handleDelete}>
                      삭제
                    </S.GalleryButton>
                  </>
                )}
              </>
            )}
            {!editMode && (
              <>
                {id && (
                  <S.GalleryButton
                    onClick={() => {
                      set_editMode(true)
                    }}
                  >
                    수정
                  </S.GalleryButton>
                )}
                <S.GalleryButton
                  onClick={() => {
                    if (!imageGroups) return
                    dispatch(
                      saveToResultAndClose({ ..._item, data: imageGroups })
                    )
                  }}
                >
                  닫기
                </S.GalleryButton>
              </>
            )}
          </div>
        </S.Header>
        <S.Contents>
          <div className="left">
            <S.List>
              {List.map((listItem, listIndex) => {
                const Child = listItem.child
                const titleMatch =
                  currentTarget && currentTarget[0] === listItem.title
                const ChildMatch =
                  currentTarget?.length === 3
                    ? {
                        id: currentTarget[1],
                        child: currentTarget[2],
                      }
                    : null
                return (
                  <Fragment key={listItem.title}>
                    <S.GroupItem>
                      <div
                        className={cn(
                          {
                            active: titleMatch,
                            'click-able': !Child,
                          },
                          'title'
                        )}
                        onClick={() => {
                          if (!Child) {
                            handleCurrentTab([listItem.title])
                          }
                        }}
                      >
                        {listItem.title}
                      </div>
                      {Child && Child.length > 0 && (
                        <S.ChildItem className="children">
                          {Child.map((childItem) => {
                            const childData = childItem
                            return (
                              <Fragment key={childItem.id + listItem.title}>
                                <div
                                  className={cn(
                                    {
                                      active:
                                        titleMatch &&
                                        currentTarget?.[1] &&
                                        currentTarget[1] === childItem.id,
                                    },
                                    'child-box'
                                  )}
                                >
                                  <div className="child-title">
                                    <span className="dot"></span>
                                    {childItem.title}
                                  </div>

                                  {childData.list['360 사진'].length > 0 && (
                                    <div
                                      className={cn(
                                        {
                                          active:
                                            titleMatch &&
                                            ChildMatch?.child === '360 사진' &&
                                            childItem.id === ChildMatch.id,
                                        },
                                        'child-child',
                                        'click-able'
                                      )}
                                      onClick={() => {
                                        handleCurrentTab([
                                          listItem.title,
                                          childItem.id,
                                          '360 사진',
                                        ])
                                      }}
                                    >
                                      360 사진
                                    </div>
                                  )}
                                  {childData.list['사진'].length > 0 && (
                                    <div
                                      className={cn(
                                        {
                                          active:
                                            titleMatch &&
                                            ChildMatch?.child === '사진' &&
                                            childItem.id === ChildMatch.id,
                                        },
                                        'child-child',
                                        'click-able'
                                      )}
                                      onClick={() => {
                                        handleCurrentTab([
                                          listItem.title,
                                          childItem.id,
                                          '사진',
                                        ])
                                      }}
                                    >
                                      사진
                                    </div>
                                  )}
                                </div>
                              </Fragment>
                            )
                          })}
                        </S.ChildItem>
                      )}
                    </S.GroupItem>
                  </Fragment>
                )
              })}
            </S.List>
          </div>
          <div className="center">
            <S.ImageBox>
              <div className="prev">
                <div
                  className={cn({ none: currentIndex === 0 }, 'nav-box')}
                  onClick={() => handleIndex('prev')}
                >
                  <img src={ImagesGalleryChevronLeft} alt="prev" />
                </div>
              </div>
              <div className="box">
                {currentImages[currentIndex] ? (
                  <>
                    {currentCategoryTarget?.lastKind === '360 사진' ? (
                      <Panorama _imageURL={currentImages[currentIndex].url} />
                    ) : (
                      <ImageWithLoading
                        _backgroundSize="contain"
                        _imageURL={currentImages[currentIndex].url}
                        _type="original"
                      />
                    )}
                  </>
                ) : (
                  <div className="no-image">등록된 사진이 없습니다.</div>
                )}
              </div>
              <div className="next">
                <div
                  className={cn(
                    {
                      none: currentIndex === currentImages.length - 1,
                    },
                    'nav-box'
                  )}
                  onClick={() => handleIndex('next')}
                >
                  <img src={ImagesGalleryChevronRight} alt="next" />
                </div>
              </div>
            </S.ImageBox>
            <S.Thumbnails ref={dragRef} onMouseDown={handleDragDown}>
              {id && (
                <div
                  className="add thumbnail"
                  onClick={(e) => {
                    const lastKind = currentCategoryTarget?.lastKind
                    if (lastKind === '360 사진' && currentImages.length > 0) {
                      dispatch(
                        setToast([
                          '360 사진은 하나만 등록 가능합니다.',
                          'error',
                        ])
                      )
                      return
                    } else if (currentImages.length >= 20) {
                      dispatch(
                        setToast(['사진은 20개까지 등록 가능합니다.', 'error'])
                      )
                      return
                    }
                    e.stopPropagation()
                    const inputEl = document.querySelector(
                      '#addFile'
                    )! as HTMLInputElement
                    inputEl.click()
                  }}
                >
                  <label htmlFor="addFile">
                    <img src={ImagesGalleryAdd} alt="추가" />
                    <input
                      id="addFile"
                      type={'file'}
                      multiple={true}
                      hidden
                      value={files}
                      accept="image/x-png, image/png, image/gif, image/jpeg"
                      onChange={async (e) => {
                        set_files(e.target.value)
                        const getFiles = e.target.files
                          ? [...e.target.files]
                          : ([] as File[])
                        if (getFiles) {
                          await handleUpload(getFiles)
                        }
                      }}
                    />
                  </label>
                </div>
              )}
              {currentImages.map((item, imageIndex) => {
                return (
                  <ImageWithLoading
                    key={item.name + imageIndex}
                    _backgroundSize="cover"
                    data-index={imageIndex}
                    className={cn(
                      { active: currentIndex === imageIndex },
                      'thumbnail'
                    )}
                    _imageURL={item.url}
                  >
                    {editMode && (
                      <>
                        {selected.includes(imageIndex) ? (
                          <img src={ImagesGalleryChecked} alt="selectable" />
                        ) : (
                          <img src={ImagesGalleryUnChecked} alt="selectable" />
                        )}
                      </>
                    )}
                  </ImageWithLoading>
                )
              })}
            </S.Thumbnails>
          </div>
          <div className="right"></div>
        </S.Contents>
      </S.Wrap>
    </>
  )
}
export default Gallery
