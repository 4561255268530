import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { PrimaryButton } from 'components/Buttons'
import { FlexColumn, TXT } from 'themes/Shared.styles'

interface UnAuthorizedPageProps {}

const Wrapper = styled.div`
  & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
  }
`

const UnAuthorizedPage = (props: UnAuthorizedPageProps) => {
  return (
    <Wrapper>
      <FlexColumn gap={60} alignItems="center">
        <FlexColumn gap={12} alignItems="center">
          <TXT _textStyle="Display/1 Bold" _color="Grayscale/Gray Default">
            접근 권한 없음
          </TXT>
          <TXT _textStyle="Title/Regular" _color="Grayscale/Gray Default">
            페이지에 접근 권한이 없습니다. 최고 관리자에게 문의해 주세요.
          </TXT>
        </FlexColumn>
        <PrimaryButton
          _css={css`
            width: 160px;
            height: 48px;
          `}
          onClick={() => {
            window.history.back()
          }}
        >
          뒤로 가기
        </PrimaryButton>
      </FlexColumn>
    </Wrapper>
  )
}
export default UnAuthorizedPage
