import { css } from '@emotion/react'
import { ImagesServiceCancel, ImagesServiceRowPlus } from 'assets'
import { GrayScaleOutline } from 'components/Buttons'
import Dropdown from 'components/Dropdown'
import { clone, isArray, isEqual, sortBy, uniq } from 'lodash-es'
import AlertRow from 'pages/Work/components/AlertRow'
import { Fragment, useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { GroupedVirtuoso } from 'react-virtuoso'
import { ContentRadiusBox } from 'themes/Shared.styles'
import { appendAt, cn } from 'utils/helpers'
import {
  ServiceDefaultValues,
  TableRowValues,
  emptyRow,
  etcDefaultValues,
} from '../../ServiceManagement/ServiceManagement.types'
import S from './ServicePriceTable.styles'
import { ServicePriceTableProps } from './ServicePriceTable.types'

interface MainRowType {
  _categoryName: string
  row: TableRowValues
  dropdownsByType: ServiceDefaultValues
  handleTableRow: (
    nextValue: number | null,
    target: 'price' | 'materialPrice'
  ) => void
}
const MainRow = ({
  _categoryName,
  dropdownsByType,
  row,
  handleTableRow,
  ...props
}: MainRowType) => {
  return (
    <div className="row content">
      {Object.keys(row.dropdowns).map((keyName, keyIndex) => {
        const dropdownValues = dropdownsByType[keyName]?.list
        const dropdownLabel = dropdownsByType[keyName]?.label
        if (!dropdownValues) return <Fragment key={'no' + keyIndex}></Fragment>
        return (
          <div key={keyName} className="row-content">
            {row.dropdowns[keyName]}
            {dropdownLabel.includes('면적') ? '평' : ''}
          </div>
        )
      })}
      <NumericFormat
        thousandSeparator={true}
        suffix={'원'}
        value={row.price}
        onValueChange={(value, e) => {
          const nextValue =
            typeof value.floatValue === 'number' ? value.floatValue : null
          handleTableRow(nextValue, 'price')
        }}
        placeholder={'가격'}
      />
      {_categoryName !== '바닥' && (
        <NumericFormat
          thousandSeparator={true}
          suffix={'원'}
          value={row.materialPrice}
          onValueChange={(value, e) => {
            const nextValue =
              typeof value.floatValue === 'number' ? value.floatValue : null
            handleTableRow(nextValue, 'materialPrice')
          }}
          placeholder={'가격'}
        />
      )}
    </div>
  )
}

const ServicePriceTable = ({
  _css,
  inputs,
  _currentValue: currentValue,
  _categoryName,
  handleInput,
  handleValues,
  ...props
}: ServicePriceTableProps) => {
  const { dropdownsByType, tableRow } = inputs
  const [focusAfter, set_focusAfter] = useState<{
    rowIndex: number
    keyIndex: number
  } | null>(null)

  const set_dropdownsByType = (
    cb: (prev: ServiceDefaultValues) => ServiceDefaultValues
  ) => {
    handleInput('dropdownsByType', (prev) =>
      prev.dropdownsByType ? cb(prev.dropdownsByType) : null
    )
  }

  const set_tableRow = (cb: (prev: TableRowValues[]) => TableRowValues[]) => {
    handleInput('tableRow', (prev) => cb(prev.tableRow))
  }

  useEffect(() => {
    if (focusAfter) {
      let currentFocusAfter = clone(focusAfter)
      const inputs = document.querySelector(
        `[data-index="row-index-${currentFocusAfter.rowIndex + 1}-${
          currentFocusAfter.keyIndex
        }"]`
      ) as HTMLInputElement
      if (inputs) {
        inputs.focus()
      }
      set_focusAfter(null)
    }
  }, [focusAfter, tableRow])

  const handleDropdownList = (
    keyName: string,
    updateValue: { list: string[] },
    changeValue: {
      prev?: string
      next: string
    },
    rowIndex: number
  ) => {
    if (dropdownsByType === null) return
    let currentDropdowns = clone(dropdownsByType)
    if (
      !isEqual(
        sortBy(currentDropdowns[keyName], (item) => item),
        sortBy(updateValue.list, (item) => item)
      )
    ) {
      // 리스트가 같지 앖을때 업데이트
      set_dropdownsByType((prev) => {
        prev = {
          ...currentDropdowns,
          [keyName]: {
            ...currentDropdowns[keyName],
            list: uniq([...updateValue.list]),
          },
        }
        return prev
      })
    }
    if (changeValue) {
      if (changeValue.prev === '' && changeValue.next !== '') {
        // 이전 값이 없고 변경되는 값이 있으면 현재 row의 값만 변경
        set_tableRow((prev) =>
          prev.map((item, itemIndex) =>
            itemIndex === rowIndex
              ? {
                  ...item,
                  dropdowns: {
                    ...item.dropdowns,
                    [keyName]: changeValue.next,
                  },
                }
              : item
          )
        )
      } else if (changeValue.prev !== '') {
        // 이전 값이 있으면 이전값과 같은 row들 모두 변경
        set_tableRow((prev) =>
          prev.map((item) =>
            item.dropdowns[keyName] === changeValue.prev
              ? {
                  ...item,
                  dropdowns: {
                    ...item.dropdowns,
                    [keyName]: changeValue.next,
                  },
                }
              : item
          )
        )
      }
    }
  }
  const handleDropdown = (
    keyName: string,
    rowIndex: number,
    updateValue: string | number
  ) => {
    set_tableRow((prev) => {
      const cloned = [...prev].map((curr, index) => {
        if (index === rowIndex) {
          const updateCurrent = {
            ...curr,
            dropdowns: {
              ...curr.dropdowns,
              [keyName]: updateValue ? String(updateValue) : '',
            },
          }
          return updateCurrent
        }
        return curr
      })
      return cloned
    })
  }
  const RemoveRow = (index: number) => {
    const currentRow = clone(tableRow[index])
    if (currentValue?.type === 'sub') {
      // 기타 서비스일때 리스트 업데이트 로직
      let currentDropdowns = clone(dropdownsByType)
      const keyNames = Object.keys(etcDefaultValues)
      const values = tableRow.map((item) => {
        // 테이블의 현재 값들
        return keyNames.map((key) => item.dropdowns[key])
      })
      const currentRowValues = keyNames.map((key) => ({
        // 삭제할 row의 값들
        key,
        value: currentRow.dropdowns[key],
      }))
      const checkHowMany = currentRowValues.map((currentItem, currentIndex) => {
        // 현재 삭제할 값이 현재 row에 유일한지 체크
        const currentRows = values.map((rowsItem) => {
          return rowsItem[currentIndex]
        }) as string[]
        const total = currentRows.reduce(
          (prev, curr) => {
            if (currentItem.value && currentItem.value === curr) {
              prev.total += 1
              prev.value = currentItem.value
              prev.key = currentItem.key
            }
            return prev
          },
          { total: 0, value: '', key: '' }
        )
        return total
      })
      const rebuild = checkHowMany.reduce((prev, curr) => {
        // 기타서비스의 ServiceDefaultValues 값 업데이트
        prev = { ...currentDropdowns }
        if (curr.total === 1) {
          prev[curr.key].list = (prev[curr.key].list as string[]).filter(
            (ft) => ft !== curr.value
          )
        }
        return prev
      }, {} as ServiceDefaultValues)
      set_dropdownsByType((prev) => {
        // 기타서비스의 ServiceDefaultValues 값 업데이트
        prev = {
          ...prev,
          ...rebuild,
        }
        return prev
      })
    }
    set_tableRow((prev) => prev.filter((_, itemIndex) => itemIndex !== index))
    // row삭제
  }
  if (!dropdownsByType) return <></>
  return (
    <ContentRadiusBox gap={20}>
      {inputs.alertSubText && (
        <AlertRow
          _css={css`
            justify-content: center;
          `}
        >
          {inputs.alertSubText}
        </AlertRow>
      )}
      <S.PriceTable className={cn({ etc: currentValue?.type === 'sub' })}>
        {currentValue.type === 'main' && (
          <>
            {tableRow.length > 23 ? (
              <GroupedVirtuoso
                style={{ height: '799px', overscrollBehaviorY: 'contain' }}
                groupCounts={[tableRow.length]}
                groupContent={(index) => {
                  return (
                    <div className="row header">
                      {dropdownsByType && (
                        <>
                          {Object.keys(dropdownsByType).map(
                            (keyName, keyIndex) => {
                              return (
                                <div
                                  key={'label' + keyName + keyIndex}
                                  className="dropdown-header"
                                >
                                  {dropdownsByType[keyName].label}
                                </div>
                              )
                            }
                          )}
                        </>
                      )}
                      <div>공임비</div>
                      {_categoryName !== '바닥' && <div>재료비</div>}
                    </div>
                  )
                }}
                itemContent={(rowIndex) => {
                  const row = tableRow[rowIndex]
                  return (
                    <MainRow
                      _categoryName={_categoryName}
                      dropdownsByType={dropdownsByType}
                      row={row}
                      handleTableRow={(nextValue, target) => {
                        set_tableRow((prev) => {
                          if (
                            prev[rowIndex][target] === nextValue ||
                            currentValue === null
                          ) {
                            return prev
                          }
                          return prev.map((item, itemIndex) =>
                            itemIndex === rowIndex
                              ? {
                                  ...item,
                                  [target]: nextValue ?? 0,
                                }
                              : item
                          )
                        })
                      }}
                    />
                  )
                }}
              />
            ) : (
              <>
                <div className="row header">
                  {dropdownsByType && (
                    <>
                      {Object.keys(dropdownsByType).map((keyName) => {
                        return (
                          <div
                            key={'label' + keyName}
                            className="dropdown-header"
                          >
                            {dropdownsByType[keyName].label}
                          </div>
                        )
                      })}
                    </>
                  )}
                  <div>공임비</div>
                  {_categoryName !== '바닥' && <div>재료비</div>}
                </div>
                {tableRow.map((row, rowIndex) => {
                  return (
                    <MainRow
                      key={'main-row-' + rowIndex}
                      _categoryName={_categoryName}
                      dropdownsByType={dropdownsByType}
                      row={row}
                      handleTableRow={(nextValue, target) => {
                        set_tableRow((prev) => {
                          if (
                            prev[rowIndex][target] === nextValue ||
                            currentValue === null
                          ) {
                            return prev
                          }
                          return prev.map((item, itemIndex) =>
                            itemIndex === rowIndex
                              ? {
                                  ...item,
                                  [target]: nextValue ?? 0,
                                }
                              : item
                          )
                        })
                      }}
                    />
                  )
                })}
              </>
            )}
          </>
        )}
        {currentValue.type === 'sub' && (
          <>
            <div className="row header">
              {dropdownsByType && (
                <>
                  {Object.keys(dropdownsByType).map((keyName) => {
                    return (
                      <div key={'label' + keyName} className="dropdown-header">
                        {dropdownsByType[keyName].label}
                      </div>
                    )
                  })}
                </>
              )}
              <div>공임비</div>
            </div>
            {tableRow.map((row, rowIndex) => {
              return (
                <div className="row content" key={'row' + rowIndex}>
                  {Object.keys(row.dropdowns).map((keyName, keyIndex) => {
                    const dropdownList = dropdownsByType[keyName]?.list
                    if (!dropdownList)
                      return <Fragment key={'no' + keyIndex}></Fragment>
                    return (
                      <Dropdown.Service
                        key={keyName}
                        className="dropdown-content"
                        _type="td"
                        _list={isArray(dropdownList) ? dropdownList : []}
                        _dataIndex={`row-index-${rowIndex}-${keyIndex}`}
                        _placeholder={'입력 후 엔터'}
                        _value={String(row.dropdowns[keyName])}
                        _changeList={(list, value) => {
                          handleDropdownList(keyName, { list }, value, rowIndex)
                        }}
                        _appendList={() => {
                          const nextIndex = rowIndex + 1
                          set_tableRow((prev) => {
                            let selectedTarget = clone(prev[rowIndex])
                            selectedTarget = {
                              ...selectedTarget,
                              dropdowns: {
                                ...selectedTarget.dropdowns,
                                [keyName]: '',
                              },
                            }
                            return appendAt(prev, nextIndex, selectedTarget)
                          })
                          set_focusAfter({ rowIndex, keyIndex })
                        }}
                        _emitValue={(value) => {
                          handleDropdown(keyName, rowIndex, value)
                        }}
                      />
                    )
                  })}
                  <NumericFormat
                    thousandSeparator={true}
                    suffix={'원'}
                    data-index={`row-index-${rowIndex}-${
                      Object.keys(row.dropdowns).length
                    }`}
                    value={row.price}
                    onBlur={(e) => {
                      const currentValue = e.currentTarget.getAttribute(
                        `data-price-${rowIndex}`
                      )
                      const nextValue = currentValue
                        ? Number(currentValue)
                        : null
                      set_tableRow((prev) => {
                        if (
                          prev[rowIndex].price === nextValue ||
                          currentValue === null
                        ) {
                          return prev
                        }
                        return prev.map((item, itemIndex) =>
                          itemIndex === rowIndex
                            ? {
                                ...item,
                                price: nextValue,
                              }
                            : item
                        )
                      })
                    }}
                    onValueChange={(value, e) => {
                      if (e.event) {
                        const eventTarget = e.event
                          .currentTarget as HTMLInputElement
                        eventTarget.setAttribute(
                          `data-price-${rowIndex}`,
                          value.value
                        )
                      }
                    }}
                    placeholder={'가격'}
                  />
                  {rowIndex !== 0 && (
                    <button
                      className="delete"
                      onClick={() => {
                        RemoveRow(rowIndex)
                      }}
                    >
                      <img src={ImagesServiceCancel} alt="remove" />
                    </button>
                  )}
                </div>
              )
            })}
          </>
        )}
      </S.PriceTable>
      {currentValue?.type === 'sub' && (
        <GrayScaleOutline
          width={'30px'}
          height={'30px'}
          _css={css`
            border-radius: 15px;
            margin: 0 auto 0;
            box-shadow: inset 0px 0px 0px 1px #a3a7b5;
          `}
          onClick={() => {
            set_tableRow((prev) => {
              return [...prev, emptyRow(dropdownsByType)]
            })
          }}
        >
          <img src={ImagesServiceRowPlus} alt="append-empty-list" />
        </GrayScaleOutline>
      )}
    </ContentRadiusBox>
  )
}
export default ServicePriceTable
