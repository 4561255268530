import { API_URL, API_URL_KEYS, device_uuid, refreshURL } from 'API_URL'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import { unstable_batchedUpdates } from 'react-dom'
import { store } from 'store'
import { TokenType, setAuth } from 'store/authSlice'
import { setError } from 'store/errorSlice'

const iv = CryptoJS.enc.Base64.parse('') //giving empty initialization vector
const key = CryptoJS.SHA256('dd-pal') //hashing the key using SHA256

function encryptData(
  data: any,
  iv: CryptoJS.lib.WordArray,
  key: CryptoJS.lib.WordArray
) {
  let encryptedString = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encryptedString.toString()
}

function decryptData(
  encrypted: string,
  iv: CryptoJS.lib.WordArray,
  key: CryptoJS.lib.WordArray
) {
  try {
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
  } catch (error) {
    return ''
  }
}

const isTokenType = (value: any): value is TokenType => {
  return (
    value !== undefined &&
    'accessToken' in value &&
    'accessTokenExpiresIn' in value &&
    'refreshToken' in value &&
    'refreshTokenExpiresIn' in value
  )
}
/**
 * 토큰 to 로컬 스토리지
 *
 * @static
 * @param {TokenType} token
 */
export const setTokenToLocalStorage = async (token: TokenType) => {
  localStorage.setItem('dd-tks', encryptData(token, iv, key))
  return token
}

/**
 * 토큰 from 로컬스토리지
 *
 */
export const getLocalToken = (): TokenType => {
  const noneOfToken = {
    accessToken: '',
    accessTokenExpiresIn: '',
    refreshToken: '',
    refreshTokenExpiresIn: '',
  }
  const decrypted = decryptData(localStorage.getItem('dd-tks') ?? '', iv, key)
  if (!decrypted) return noneOfToken
  const parsed = JSON.parse(decrypted)
  if (isTokenType(parsed)) {
    return parsed
  } else {
    return noneOfToken
  }
}

const getRefreshToken = async (
  refreshToken: string,
  accessToken: string
): Promise<TokenType | null> => {
  try {
    if (!refreshURL) {
      return null
    }
    const postData = { accessToken }
    const refreshResponse = await axios(API_URL['user'] + refreshURL, {
      method: 'patch',
      headers: {
        Authorization: refreshToken,
        'Content-Type': 'application/json',
        deviceId: device_uuid,
      },
      data: postData,
    })

    const refreshedToken = refreshResponse
    return (refreshedToken?.data?.data ??
      refreshedToken?.data ??
      refreshedToken) as TokenType
  } catch (error: any) {
    localStorage.clear()
    window.location.href = '/'
    return null
  }
}
/**
 * 토큰 체크, 액세스 토큰 만료시 리프레시
 *
 * @private
 * @param {boolean} [init]
 * @memberof CALL_API
 */
export const checkToken = async (init?: boolean) => {
  const token = getLocalToken()

  if (!token.accessToken || !token.refreshToken) {
    return null
  }
  const refreshIn = dayjs(token.refreshTokenExpiresIn).diff(dayjs(), 'day')
  const expiresIn = dayjs(token.accessTokenExpiresIn).diff(dayjs(), 'minutes')

  if (refreshIn < 1) {
    localStorage.clear()
    window.location.reload()
    return null
  } else if (expiresIn < 2 || init) {
    if (!refreshURL) return null
    const refreshToken = `Bearer ${token.refreshToken}`
    const accessToken = `${token.accessToken}`
    const refreshedToken = await getRefreshToken(refreshToken, accessToken)
    if (refreshedToken) {
      setTokenToLocalStorage(refreshedToken)
    }
    return refreshedToken
  } else {
    return token
  }
}
export const handleError = (error: any) => {
  if (error.code === 'ERR_CANCELED') return
  if (error.response) error = error.response
  let errorMessage = isDDError(error?.data)
    ? error?.data?.message
    : error?.statusText ?? '알수 없는 에러'

  unstable_batchedUpdates(() => {
    store.dispatch(
      setError({
        title: null,
        errorMessage,
      })
    )
  })
}
const callAxios = (type?: API_URL_KEYS) => {
  const newAxios = axios.create(
    type
      ? {
          baseURL: API_URL[type],
        }
      : undefined
  )
  newAxios.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      let headers: any & { skipToken: boolean } = config.headers
      if (headers.skipToken) {
        delete headers.Authorization
        delete headers.skipToken
      } else if (!headers.Authorization) {
        const token = await checkToken()
        if (token?.accessToken) {
          headers = {
            ...config.headers,
            Authorization: `Bearer ${token?.accessToken}`,
          }
        }
      }
      config.headers = headers

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )
  newAxios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.code === 'ERR_CANCELED') {
        throw error
      }
      if (error.response.status >= 500) {
        if (error.response.status === 504) {
          window.location.href = `/500?from=${window.location.pathname}`
          return
        }
        unstable_batchedUpdates(() => {
          store.dispatch(
            setError({
              title: null,
              errorMessage: `서버에 접속할 수 없거나,\n인터넷 연결이 원활하지 않습니다.\n(${error.response.status}) ${error.response.statusText}`,
            })
          )
        })
      }
      if (error.response.status === 401) {
        localStorage.clear()
        unstable_batchedUpdates(() => {
          store.dispatch(
            setError({
              title: null,
              errorMessage: error.response.data?.message ?? '접근권한 없음.',
            })
          )
          store.dispatch(
            setAuth({
              status: 'unauthorized',
              token: null,
            })
          )
        })
      } else {
        throw error.response
      }
    }
  )

  return newAxios
}

type ErrorType = {
  message: string
  success: false
}
export const isError = (value: any): value is ErrorType => {
  return value !== undefined && value.success === false && 'message' in value
}

export type DoubleDError = {
  success: false
  error: string
  message: string
  data: {}
}
export const isDDError = (value: any): value is DoubleDError => {
  return (
    value !== undefined &&
    typeof value === 'object' &&
    'message' in value &&
    typeof value.message === 'string'
  )
}
export const extractData = <T>(response: AxiosResponse<T, any> | any): T => {
  return (
    response?.data?.data?.data ??
    response?.data?.data ??
    response?.data ??
    response
  )
}
export default callAxios
