import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import { MatchInputProps } from 'hooks/useMatchInput'
import { OrderStateType, ServiceDetailsValuesType } from 'pages/Work/Work.types'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { TechsType } from './AssignTeamPopup/AssignTeamPopup.types'

export interface WorkerAssignmentTabProps
  extends DivAttributes,
    MatchInputProps<ServiceDetailsValuesType> {
  _css?: AdditionalCss
  _type?: OrderStateType
}

export type ApiTechsListItem = {
  id: string
  name: string
  office: string
  isVacation: boolean
  scheduleCount: number
  availableSkill: string[]
  dispatchableZone: string[]
  createdAt: string
}

export type TechsListItemTypeForSelect = {
  id: string
  selected: string
  name: string
  company: string
  workable: string
  todayWork: string
  duty: string
  dispatchArea: string
}

export const parserForTechsListItemTypeForSelect = (
  response: DoubleDListWithPaging<ApiTechsListItem>,
  _type: TechsType
) => {
  const rebuild = response.list.map((item: ApiTechsListItem, index: number) => {
    return {
      id: item.id,
      selected: item.id,
      name: item.name,
      company: item.office,
      workable: item.isVacation ? '휴무일(추가정산)' : '근무일',
      todayWork: item.scheduleCount > 0 ? item.scheduleCount + '개' : '없음',
      duty: item.availableSkill?.join(' / ') ?? [],
      dispatchArea: item.dispatchableZone?.join(' / ') ?? [],
    } as TechsListItemTypeForSelect
  })
  return {
    _list: rebuild,
    _cursor: disposeCursor(response.paging.cursor, 'cursor'),
  }
}
