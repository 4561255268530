import { css } from '@emotion/react'
import {
  ImagesMiniCalendarChevronLeft,
  ImagesMiniCalendarChevronRight,
} from 'assets'
import ApiFileUploadBox from 'components/ApiFileUploadBox'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DraggablePopup from 'components/DraggablePopup'
import dayjs, { Dayjs } from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import AlertRow from 'pages/Work/components/AlertRow'
import { useMemo, useState } from 'react'
import { setToast } from 'store/toastSlice'
import { ContentRadiusBox, FlexColumn, FlexRow } from 'themes/Shared.styles'
import { DoubleDFile } from 'types/services.type'
import callAxios, { handleError } from 'utils/callAxios'
import S from './WagesForFreelancers.styles'
import {
  APIGuaranteeWages,
  WagesForFreelancersProps,
} from './WagesForFreelancers.types'

const WagesForFreelancers = ({
  _css,
  _onClose,
  _open,
  ...props
}: WagesForFreelancersProps) => {
  const [currentMonth, set_currentMonth] = useState<Dayjs>(dayjs())
  const [excelFile, set_excelFile] = useState<DoubleDFile[]>([])
  const dispatch = useAppDispatch()

  const onDownloadExcelForm = async () => {
    try {
      const res = await callAxios('user').get(
        '/technician/guarantee-wages/sample',
        {
          params: {
            month: currentMonth.format('YYYY-MM-DD'),
          },
        }
      )
      const response = await fetch(res.data.data)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'freelancer_guarantee_sample.xlsx'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (e) {
      handleError(e)
    }
  }
  const onSubmit = async (value: APIGuaranteeWages) => {
    const formData = new FormData()
    formData.append('file', value.file)
    formData.append('month', value.month)
    try {
      await callAxios('user').put('/technician/guarantee-wages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      dispatch(
        setToast({
          message: '저장되었습니다.',
          type: 'success',
        })
      )
      _onClose()
    } catch (e: any) {
      dispatch(
        setToast({
          message: e.data.message,
          type: 'error',
        })
      )
    }
  }
  const diff = useMemo(() => {
    return dayjs(currentMonth.format('YYYY-MM-DD')).diff(
      dayjs().format('YYYY-MM-DD'),
      'month'
    )
  }, [currentMonth])

  return (
    <>
      <DraggablePopup
        _title={'프리랜서 보존 공임 일괄조정'}
        _open={_open}
        _onClose={_onClose}
        _bottom={
          <>
            <GrayScaleFill onClick={_onClose}>취소</GrayScaleFill>
            <PrimaryButton
              onClick={() => {
                if (!excelFile[0].file) {
                  dispatch(
                    setToast({
                      message: '엑셀 파일을 업로드해주세요.',
                      type: 'error',
                    })
                  )
                  return
                }
                onSubmit({
                  file: excelFile[0].file,
                  month: currentMonth.format('M'),
                })
              }}
            >
              저장
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <FlexRow
            gap={12}
            justifyContent="center"
            _css={css`
              margin-bottom: 32px;
            `}
          >
            <GrayScaleOutline
              _css={css`
                width: 30px;
                height: 30px;
                border-radius: 50%;
              `}
              disabled={diff <= 0}
              onClick={() => {
                set_currentMonth((prev) => prev.subtract(1, 'month'))
              }}
            >
              <img src={ImagesMiniCalendarChevronLeft} alt="prev" />
            </GrayScaleOutline>
            <h1>{currentMonth.format('YYYY년 MM월')}</h1>
            <GrayScaleOutline
              _css={css`
                width: 30px;
                height: 30px;
                border-radius: 50%;
              `}
              disabled={diff > 0}
              onClick={() => {
                set_currentMonth((prev) => prev.add(1, 'month'))
              }}
            >
              <img src={ImagesMiniCalendarChevronRight} alt="next" />
            </GrayScaleOutline>
          </FlexRow>
          <FlexColumn gap={12}>
            <AlertRow
              _css={css`
                align-items: flex-start;
              `}
            >
              프리랜서 보존 공임 관련 설정은 정해진 양식의 Excel 파일 업로드를
              통해서만 가능합니다.
              <br />
              파일 업로드는 이번 달 및 다음 달까지 미리 업로드가 가능하며,
              업로드를 하지 않으신 경우 가장 최근에 업로드하신 정산 엑셀표
              데이터로 적용됩니다.
            </AlertRow>
            <ContentRadiusBox gap={12}>
              <BoxRowComponent _label={'엑셀로 업로드 *'}>
                <ApiFileUploadBox
                  _type={'order'}
                  _fileType={'excel'}
                  _url={``}
                  _fileList={excelFile}
                  _multiple={false}
                  _emitValue={(value) => {
                    set_excelFile(value)
                  }}
                />
              </BoxRowComponent>
              <BoxRowComponent _label={'양식 다운로드'}>
                <div style={{ flex: 1 }}>
                  <GrayScaleOutline
                    onClick={() => {
                      onDownloadExcelForm()
                    }}
                    _mini
                    width={'86px'}
                    padding="5px 0px"
                  >
                    다운로드
                  </GrayScaleOutline>
                </div>
              </BoxRowComponent>
            </ContentRadiusBox>
          </FlexColumn>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default WagesForFreelancers
