import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import Dropdown from 'components/Dropdown'
import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineFormatInput, {
  getFormats,
} from 'components/UnderlineInput/UnderlineFormatInput'
import useFetch from 'hooks/useFetch'
import { isArray } from 'lodash-es'
import { ApiFunnels } from 'pages/Services/Funnel/Funnel.types'
import { Fragment, useMemo } from 'react'
import { ContentRadiusBox } from 'themes/Shared.styles'
import { CUSTOMER_TYPE_DROPDOWN_LIST } from 'types/services.type'
import { updateAndDeleteBehind } from 'utils/helpers'
import { CustomerInfoInEstimatesProps } from './CustomerInfoInEstimates.types'

const dummyFunnels = [0, 1, 2] as const
const CustomerInfoInEstimates = ({
  inputs,
  handleInput,
  updateFirstCategoryDropdown,
  ...props
}: CustomerInfoInEstimatesProps) => {
  const [apiFunnels] = useFetch<ApiFunnels>(`order`, '/order-funnels')

  const categoryList = useMemo(() => {
    if (!apiFunnels) return [[], [], []]
    const selectedFunnelCategory = inputs.funnels
    const findSecond = apiFunnels.find(
      (item) => item.key === selectedFunnelCategory[0]
    )?.value
    const findThird = apiFunnels
      .find((item) => item.key === selectedFunnelCategory[0])
      ?.value.find((item) => item.key === selectedFunnelCategory[1])?.value
    const secondCategory = findSecond
      ? findSecond.reduce((prev, curr) => {
          prev[curr.key] = curr.key
          return prev
        }, {} as DropdownListType)
      : []
    const thirdCategory = findThird
      ? findThird.reduce((prev, curr) => {
          prev[curr] = curr
          return prev
        }, {} as DropdownListType)
      : []
    return [
      apiFunnels.reduce((prev, curr) => {
        prev[curr.key] = curr.key
        return prev
      }, {} as DropdownListType),
      secondCategory,
      thirdCategory,
    ]
  }, [apiFunnels, inputs.funnels])

  const handleFunnels = async (index: number, value: string) => {
    handleInput('funnels', (prev) =>
      updateAndDeleteBehind(prev.funnels, index, value)
    )
  }

  return (
    <BoxLayout _title={'회원 정보'}>
      <ContentRadiusBox gap={12}>
        <BoxRowComponent _label={'주문자 명 *'}>
          <UnderlineInput
            value={inputs.name}
            handleValue={(value) => {
              handleInput('name', value)
            }}
            placeholder="내용 입력"
          />
        </BoxRowComponent>
        <BoxRowComponent _label={'전화번호 *'}>
          <UnderlineFormatInput
            format={getFormats('phone', inputs.phone)}
            value={inputs.phone}
            handleValue={(value) => {
              handleInput('phone', value)
            }}
            placeholder="내용 입력"
          />
        </BoxRowComponent>
        <BoxRowComponent _label={'회원 구분 *'}>
          <Dropdown.Underline
            _list={CUSTOMER_TYPE_DROPDOWN_LIST}
            _value={inputs.customerType}
            _emitValue={async (value) => {
              await updateFirstCategoryDropdown(inputs.zoneGroupId, value)
              handleInput('customerType', value)
            }}
          />
        </BoxRowComponent>
        {categoryList && (
          <>
            {dummyFunnels.map((_item, itemIndex) => {
              const nextCategory = categoryList[itemIndex]
              if (isArray(nextCategory))
                return <Fragment key={'funnel' + itemIndex} />
              return (
                <BoxRowComponent
                  _label={itemIndex === 0 ? '주문 경로 *' : ''}
                  key={'funnel' + itemIndex}
                >
                  <Dropdown.Underline
                    _list={nextCategory}
                    _value={inputs.funnels[itemIndex]}
                    _emitValue={(value) => {
                      handleFunnels(itemIndex, String(value))
                    }}
                  />
                </BoxRowComponent>
              )
            })}
          </>
        )}
      </ContentRadiusBox>
    </BoxLayout>
  )
}
export default CustomerInfoInEstimates
