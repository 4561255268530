import styled from '@emotion/styled/macro'
import { colors } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

export const RoundBox = styled.div`
  background: #ffffff;
  border: 1px solid #dadfe8;
  border-radius: 12px;
  ${addCssProps}
`
export const RoundBoxRow = styled.div`
  background: #ffffff;
  border: 1px solid #dadfe8;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  overflow: hidden;
  ${addCssProps}
`
const AbsolutePadding = 64
export const RoundInBox = styled.div`
  background-color: ${colors['Grayscale/Background Light']};
  & > div {
    background: #ffffff;
    border: 1px solid #dadfe8;
    border-radius: 12px;
    padding: 24px 32px;
  }
  ${addCssProps}
`
const ContentsBoxStyle = {
  AbsolutePadding,
  RelativeBox: styled.div`
    background-color: ${colors['Grayscale/Background Light']};
    width: 100%;
    min-height: 100vh;
    color: ${colors['Grayscale/Gray Dark']};
    & > div {
      max-width: 1206px;
      margin: 0 auto;
      min-width: 1206px;
      width: 100%;
      height: 100%;
      padding: 0;
    }
    ${addCssProps}
  `,
  AbsoluteBox: styled.div`
    background-color: ${colors['Grayscale/Background Light']};
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 900px;
    color: ${colors['Grayscale/Gray Dark']};
    & > div {
      max-width: 1206px;
      min-width: 1206px;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      padding: ${AbsolutePadding}px 0;
    }
    @media print {
      min-height: auto;
      max-width: initial;
      min-width: initial;
      position: relative;
      & > div {
        max-width: initial;
        min-width: initial;
        display: block;
        padding: 0px;
      }
    }
    ${addCssProps}
  `,
}

export default ContentsBoxStyle
