import { css } from '@emotion/react'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import dayjs from 'dayjs'
import { EnteringInfoValuesType } from 'pages/Work/Work.types'
import { ContentRadiusBox } from 'themes/Shared.styles'
import {
  COMMUNICATION_METHODS_DROPDOWN_LIST,
  FREE_PARKING_DROPDOWN_LIST,
  HAS_PASSWORD_DROPDOWN_LIST,
  LIVE_IN_DROPDOWN_LIST,
} from 'types/services.type'
import { getKeyNameByValue } from 'utils/helpers'

interface EnteringInfoProps<T extends EnteringInfoValuesType> {
  inputs: Pick<T, keyof EnteringInfoValuesType>
}
const EnteringInfo = <T extends EnteringInfoValuesType>({
  inputs,
  ...props
}: EnteringInfoProps<T>) => {
  return (
    <>
      <ContentRadiusBox>
        <BoxRowComponent
          _label={`희망 방문일`}
          _contents={
            dayjs(inputs.scheduledDate).isValid()
              ? dayjs(inputs.scheduledDate).format('YYYY-MM-DD')
              : '-'
          }
        />
        <BoxRowComponent
          _label={`무료주차`}
          _contents={getKeyNameByValue(
            FREE_PARKING_DROPDOWN_LIST,
            inputs.freeParking
          )}
        />
        <BoxRowComponent
          _label={`거주 여부`}
          _contents={getKeyNameByValue(LIVE_IN_DROPDOWN_LIST, inputs.liveIn)}
        />
        <BoxRowComponent
          _label={`비밀번호`}
          _contents={getKeyNameByValue(
            HAS_PASSWORD_DROPDOWN_LIST,
            inputs.hasPassword
          )}
        />
        <BoxRowComponent
          _label={`소통방법`}
          _contents={getKeyNameByValue(
            COMMUNICATION_METHODS_DROPDOWN_LIST,
            inputs.communicationMethod
          )}
        />
      </ContentRadiusBox>
      <ContentRadiusBox className="question">
        <BoxRowComponent _label={`주차방법`} _contents={inputs.parkingMethod} />
      </ContentRadiusBox>
      {inputs.hasPassword === 2 && (
        <>
          <ContentRadiusBox
            _css={css`
              margin-top: 12px;
              & div > label {
                min-width: 84px;
              }
            `}
          >
            <BoxRowComponent
              _label={`공동 현관 비밀번호`}
              _contents={inputs.frontPwd}
            />
          </ContentRadiusBox>
          <ContentRadiusBox
            className="question"
            _css={css`
              & div > label {
                min-width: 84px;
              }
            `}
          >
            <BoxRowComponent
              _label={`자택 현관 비밀번호`}
              _contents={inputs.doorPwd}
            />
          </ContentRadiusBox>
        </>
      )}
    </>
  )
}
export default EnteringInfo
