import { css } from '@emotion/react'
import Badge from 'components/Badge'
import ContentsBox from 'components/ContentsBox'
import DownloadSpreadSheet from 'components/DownloadSpreadSheet'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import PeriodDropdownFilter from 'components/TableComponent/PeriodDropdownFilter'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  isTimeFilter,
} from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import TableCell from 'components/TableComponent/TableCell'
import T from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import useFetch from 'hooks/useFetch'
import useSearchFilter from 'hooks/useSearchFilter'
import { ApiFunnels } from 'pages/Services/Funnel/Funnel.types'
import {
  ApiUnpaid,
  OrderPathKeyType,
  OrderStatesType,
  UNPAID_BADGE_COLOR_SET,
  UNPAID_ORDER_STATE_DROPDOWN_LIST,
  UNPAID_QUERY_DROPDOWN_LIST,
  UNPAID_USER_TYPE_DROPDOWN_LIST,
  UserType,
} from 'pages/Unpaid/UnpaidList/UnpaidList.types'

import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  blobGetXlsx,
  cn,
  parserForPeriodQuery,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
} from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'

import { rebuildSpreadSheetDataForApi } from 'components/DownloadSpreadSheet/SelectFilterForSpreadSheet/SelectFilterForSpreadSheet'
import S from './UnpaidList.styles'
export interface UnpaidListProps {}
type UnpaidListItemType = {
  id: string
  orderState: OrderStatesType
  userType: UserType
  funnel: OrderPathKeyType
  createdAt: string
  PIC: string
  customer: string
  phone: string
  service: string
}

const defaultListTableConfigs: TableCellConfig<UnpaidListItemType>[] = [
  {
    _type: 'dropdown',
    _id: 'orderState',
    _label: '주문 상태',
    _data: { 전체: '', ...UNPAID_ORDER_STATE_DROPDOWN_LIST },
    _position: 'left',
    _size: 106,
  },
  {
    _type: 'dropdown',
    _id: 'userType',
    _label: '회원 분류',
    _data: { 전체: '', ...UNPAID_USER_TYPE_DROPDOWN_LIST },
    _size: 106,
  },
  {
    _type: 'dropdown',
    _id: 'funnel',
    _label: '주문 경로',
    _data: { 전체: '' },
    _position: 'left',
    _size: 106,
  },
  {
    _type: 'order',
    _id: 'createdAt',
    _label: '주문 일시',
    _order: 'INITIAL',
    _size: 160,
  },
  {
    _type: 'default',
    _id: 'PIC',
    _label: '담당자명',
    _position: 'left',

    _size: 84,
  },
  {
    _type: 'default',
    _id: 'customer',
    _label: '주문자명',
    _position: 'left',

    _size: 84,
  },
  {
    _type: 'default',
    _id: 'phone',
    _label: '전화번호',
    _position: 'left',

    _size: 120,
  },
  {
    _type: 'default',
    _id: 'service',
    _label: '서비스명',
    _position: 'left',
    _size: 400,
  },
]
// api 데이터 parser
const parserForUnpaidList = (response: DoubleDListWithPaging<ApiUnpaid>) => {
  const rebuild = response.list.map((item: ApiUnpaid, index: number) => {
    return {
      id: String(item.id),
      orderState: item.status.replace(/\s/g, ''),
      userType: item.customerType as UserType,
      funnel: item.orderFunnel,
      createdAt: item.createAt
        ? dayjs(item.createAt).format('YYYY-MM-DD(ddd) HH:mm')
        : '-',
      PIC: item.issuer,
      customer: item.customerName,
      phone: parseToFormattedStr(item.customerPhone, 'phone'),
      service: item.serviceName,
    } as UnpaidListItemType
  })
  return {
    _list: rebuild,
    _cursor: disposeCursor(response.paging.cursor, 'cursor'),
  }
}

export const UnpaidList = (props: UnpaidListProps) => {
  const [selectedId, set_selectedId] = useState<string>('')
  const navigate = useNavigate()

  const [stats] = useFetch<{ total: number }>('task', `/unpaid/stats`)
  const [apiFunnels] = useFetch<ApiFunnels>(`order`, '/order-funnels')
  const [unpaidListTableConfig, set_unpaidListTableConfig] = useState<
    TableCellConfig<UnpaidListItemType>[]
  >([])

  useEffect(() => {
    if (apiFunnels) {
      const flatter = apiFunnels
        .map((mainCategory) =>
          mainCategory.value
            .map((middleCategory) =>
              middleCategory.value.map((subdivision) => subdivision).flat()
            )
            .flat()
        )
        .flat()

      let rebuildConfigs = [
        ...defaultListTableConfigs.map((item) => {
          if (item._id === 'funnel' && item._type === 'dropdown') {
            item._data = {
              전체: '',
              ...flatter.reduce((prev, curr) => {
                prev[curr] = curr
                return prev
              }, {} as { [key in string]: string }),
            }
          }
          return item
        }),
      ]
      set_unpaidListTableConfig(rebuildConfigs)
    }
  }, [apiFunnels])

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: PERIOD_DROPDOWN_INITIAL_VALUE,
      queryList: UNPAID_QUERY_DROPDOWN_LIST,
    },
    // query object -> filterList
    (
      {
        startAt,
        endAt,
        month,
        year,
        periodType,
        searchType,
        searchValue,
        ...rest
      },
      queryName
    ) => {
      return {
        period: {
          start: startAt ? dayjs(startAt, 'YYYY-MM-DD') : null,
          end: endAt ? dayjs(endAt, 'YYYY-MM-DD') : null,
        },
        monthValue: month ?? '',
        yearValue: year ?? '',
        periodType: isTimeFilter(periodType) ? periodType : 'all',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    // filterList => query object
    ({ queryName = 'search', terms = '', ...rest }) => {
      return removeUndefined({
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        ...parserForPeriodQuery(rest),
      })
    }
  )
  const apiURI = useMemo(() => {
    return `/unpaid/list?take=10${
      window.location.search ? '&' + searchParams.toString() : ''
    }`
  }, [searchParams])

  return (
    <>
      <ContentsBox _type="relative">
        <S.Wrapper>
          {/* TopInfo */}
          <TopInfo>
            <TopInfoRow
              _label={'전체'}
              _contents={`${thousand(stats?.total)}원`}
            />
          </TopInfo>
          {/* TableTitle */}
          <TableTitle
            _left="미수금 관리"
            _right={
              <DownloadSpreadSheet
                _emitValue={async (value) => {
                  const rebuild = rebuildSpreadSheetDataForApi(value)
                  blobGetXlsx(
                    'task',
                    `/unpaid/list/excel${rebuild.queryString}`,
                    '미수금_목록'
                  )
                }}
                _noPeriod
                _tableConfig={[
                  '시공상태',
                  '회원분류',
                  '주문경로',
                  '주문일시',
                  '담당자명',
                  '주문자명',
                  '전화번호',
                  '서비스명',
                  '미수금액',
                ]}
              />
            }
          />
          {/* Filter */}
          <FilterPart<PeriodDropdownValuesType>
            _value={initialValues}
            _placeholder="쿠폰 제목 검색"
            _queryNameList={UNPAID_QUERY_DROPDOWN_LIST}
            _queryNameFormat={{ 전화번호: 'phone' }}
            _emitValue={(value) => {
              handleSetLocation(value)
            }}
            _FilterContents={({ _value, ...props }) => {
              // if (_type === 'queue') {
              //   return <div />
              // }
              return <PeriodDropdownFilter _value={_value} {...props} />
            }}
          />
          {/* Table */}
          <RoundFillBox>
            <TableComponent<UnpaidListItemType>
              _config={unpaidListTableConfig}
              _type="task"
              _url={apiURI}
              _parser={parserForUnpaidList}
              _ListContent={({ _row, _index }) => {
                const rowId = _row.id ? String(_row.id) : String(_index)
                return (
                  <T.Row
                    className={cn({
                      active: selectedId === rowId,
                    })}
                    onClick={(e) => {
                      e.stopPropagation()
                      set_selectedId(rowId)
                    }}
                    onDoubleClick={(e) => {
                      e.stopPropagation()
                      navigate(`/unpaid/${rowId}`)
                    }}
                  >
                    {unpaidListTableConfig.map((item, columnIndex) => {
                      const _id = item._id
                      const _value = _row[item._id] as any
                      return (
                        <TableCell
                          key={'body' + _index + columnIndex}
                          _size={item._size}
                          _position={item._position}
                        >
                          {_id === 'orderState' ? (
                            <Badge
                              _css={css`
                                width: 52px;
                                height: 24px;
                              `}
                              {...(_value &&
                                UNPAID_BADGE_COLOR_SET[
                                  _value as OrderStatesType
                                ])}
                            >
                              {_value}
                            </Badge>
                          ) : _id === 'userType' ? (
                            <div>
                              {_value === '1' ? '일반회원' : '정기회원'}
                            </div>
                          ) : _id === 'funnel' ? (
                            <div>{_value}</div>
                          ) : (
                            <div>{_value}</div>
                          )}
                        </TableCell>
                      )
                    })}
                  </T.Row>
                )
              }}
            />
          </RoundFillBox>
        </S.Wrapper>
      </ContentsBox>
    </>
  )
}
