import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import {
  CALENDAR_SCHEDULE_DOT_COLOR,
  DotColorsKeyType,
} from '../CalendarInReservation/CalendarInReservation.types'

const DayColumn = styled.div`
  flex: 1;
  box-sizing: border-box;
  position: relative;
  width: 98px;
  border-right: 1px solid ${colors['Grayscale/Background Dark']};
  border-top: 1px solid ${colors['Grayscale/Background Dark']};
  // 일요일
  &:first-of-type {
    .day-part {
      color: ${colors['Danger/Dark']};
    }
  }
  // 토요일
  &:last-of-type {
  }
  // 현재 월 외의 날짜
  &.isGrayed {
    .day-part {
      color: ${colors['Grayscale/Gray Light']};
    }
  }

  // 오늘
  &.isToday {
    .day-part {
      border-radius: 50%;
      box-shadow: inset 0px 0px 0px 1px ${colors['Primary/Default']};
      color: ${colors['Primary/Default']};
      ${typography['Menu/Bold']}
    }
  }
  // 선택된 날짜
  &.isSelected {
    background-color: ${colors['Primary/Default']};
    .day-part {
      color: ${colors['White/White off']};
    }
    * {
      color: ${colors['White/White off']};
    }
  }
  &.isAfter {
    /* background-color: #fbffd9; */
  }
  &.isBefore {
    /* background-color: #ffc2c2; */
  }

  // hover
  &:hover {
  }
`
const DayPart = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${typography['Body/Caption/Regular']}
  color: ${colors['Grayscale/Black']};
`
const CalendarDays = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0;
  position: relative;
  padding: 0;
  border-bottom: 1px solid ${colors['Grayscale/Background Dark']};
  border-left: 1px solid ${colors['Grayscale/Background Dark']};
  &.center {
    &::before {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: #ebedf1;
    }
    &::after {
      content: '';
      position: absolute;
      right: -1px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: #ebedf1;
    }
  }
`
const CalendarRow = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 0px;
  height: 91px;
`
const SelectedBackground = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors['Primary/Default']};
`
const TodayBackground = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px ${colors['Primary/Default']};
`
const Weeks = styled.div`
  flex: 1;
  text-align: left;
  padding: 4px 8px;
  color: ${colors['Grayscale/Gray Default']};
  ${typography['Menu/Bold']}
`
const WeeksRow = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 4px;
  background-color: ${colors['Grayscale/Background Light']};
  border-top: 1px solid ${colors['Grayscale/Background Dark']};
  border-left: 1px solid ${colors['Grayscale/Background Dark']};
  border-right: 1px solid ${colors['Grayscale/Background Dark']};
`
const CalendarInPopupStyle = {
  TableWrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
  `,
  TechsWrap: styled.div`
    display: flex;
    align-items: center;
    overflow-x: auto;
    gap: 16px;
    flex: 1;
  `,
  DayWrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  Holidays: styled.div`
    ${typography['Menu/Regular']}
    color: ${colors['Grayscale/Gray Light']};
  `,
  Techs: styled.div`
    padding: 0 6px;
    ${typography['Menu/Bold']}
    color: ${colors['Grayscale/Black light']};
  `,
  DayHeader: styled.div`
    padding: 4px 6px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  DayColumn,
  DayPart,
  CalendarDays,
  CalendarRow,
  SelectedBackground,
  TodayBackground,
  Weeks,
  WeeksRow,
  RightParts: styled.div`
    width: 380px;
  `,
  CalendarWrap: styled.div`
    ${addCssProps}
  `,
  CalendarHeader: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 16px;
    h1 {
      ${typography['Headline/Bold']}
      color: ${colors['Grayscale/Black']};
      white-space: nowrap;
    }
    img {
      width: 14px;
      height: 14px;
    }
  `,
  MoreCount: styled.div`
    ${typography['Menu/Regular']}
    color: ${colors['Grayscale/Gray Default']};
    text-align: right;
    position: absolute;
    bottom: 11px;
    right: 6px;
  `,
  RadiusBox: styled.div`
    padding: 16px;
    width: 100%;
    border-radius: 16px;
    border: 1px solid ${colors['Grayscale/Gray Lighter']};
  `,

  RightHeader: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${typography['Body/Small/Bold']}
    color: ${colors['Grayscale/Black light']};
    padding: 16px 24px 12px;
  `,
  SchedulesOuter: styled.div`
    padding: 0 8px;
    height: calc(100% - 48px - 16px);
  `,
  None: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    ${typography['Body/Caption/Bold']}
    color: ${colors['Grayscale/Gray Light']};
  `,
  SchedulesWrap: styled.div`
    padding: 0 16px 12px;
    height: 100%;
    overflow: auto;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  SchedulesGroup: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  AllDayWrap: styled.div`
    position: sticky;
    top: 0px;
    height: 24px;
    padding: 3px 0 15px;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    gap: 8px;
    ${typography['Body/Caption/Bold']}
    color: ${colors['Primary/Default']};
    background-color: #fff;
  `,
  Line: styled.div`
    flex: 1 0;
    height: 1px;
    background-color: ${colors['Grayscale/Gray Light']};
  `,
  ScheduleItem: styled.div(
    ({ _bgColor, ...props }: { _bgColor: DotColorsKeyType }) =>
      css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        .title {
          display: flex;
          align-items: center;
          gap: 12px;
          ${typography['Body/Caption/Bold']}
          color: ${colors['Grayscale/Gray Dark']};
          position: relative;
          &::before {
            content: '';
            width: 4px;
            height: 18px;
            border-radius: 8px;
            background-color: ${colors[CALENDAR_SCHEDULE_DOT_COLOR[_bgColor]]};
          }
        }
        .techs {
          padding-left: 16px;
          width: 100%;
          display: grid;
          gap: 10px 4px;
          grid-auto-flow: row;
          grid-template-columns: repeat(4, 1fr);
          justify-content: space-between;
          & > div {
            ${typography['Body/Caption/Regular']}
            color: ${colors['Grayscale/Gray Dark']};
            padding: 3px 6px;
            border-radius: 20px;
            white-space: nowrap;
            box-shadow: inset 0px 0px 0px 1px
              ${colors['Grayscale/Gray Default']};
          }
        }
      `
  ),
}

export default CalendarInPopupStyle
