import { useParams } from 'react-router-dom'
import UserDetails from './UserDetails'
import UserList from './UserList'

const User = () => {
  const { id } = useParams<'id'>()
  return (
    <>
      {id && <UserDetails _id={id} />}
      {!id && <UserList />}
    </>
  )
}
export default User
