import { css } from '@emotion/react'
import { PrimaryButton } from 'components/Buttons'
import UnderlineInput from 'components/UnderlineInput'
import { useState } from 'react'
import SA from '../Authorization.styles'
import LogoPart from '../LogoPart'
import S from './InitPassword.styles'
import { InitPasswordProps } from './InitPassword.types'

const InitPassword = ({ _css, ...props }: InitPasswordProps) => {
  const [username, set_username] = useState<string>('')
  const [usernameError, set_usernameError] = useState<string>('')
  const handleSubmit = async () => {
    window.location.reload()
  }
  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <LogoPart />
        <SA.Center>
          <SA.Header>비밀번호 초기화</SA.Header>
          <S.Contents>
            <p>
              관리자에게 비밀번호 초기화 요청을 합니다.
              <br />
              승인 후 핸드폰 번호 뒷자리로 변경됩니다.
            </p>
            <UnderlineInput
              label="아이디"
              value={username}
              handleValue={(value) => {
                set_usernameError('')
                set_username(value)
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit()
                }
              }}
              maxLength={20}
              error={usernameError}
            />
          </S.Contents>
          <SA.Bottom>
            <PrimaryButton
              _css={css`
                width: 100%;
                padding: 10px 20px;
              `}
              disabled={username.length < 6}
              onClick={handleSubmit}
            >
              초기화 요청
            </PrimaryButton>
          </SA.Bottom>
        </SA.Center>
      </S.Wrap>
    </>
  )
}
export default InitPassword
