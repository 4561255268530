import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import { LiveSearchUserInfo } from 'components/LiveSearchInput/LiveSearchInput.types'
import { PickerPeriod } from 'components/PeriodPicker/PeriodPicker.types'
import { Dayjs } from 'dayjs'
import { CouponTarget } from 'pages/Support/Coupon/Coupon.types'
import { IssueMethod } from 'pages/Support/Coupon/CouponPublish/ByTarget/ByTarget'
import { TargetUser } from 'pages/Support/support.types'
import { sampleUserType } from 'themes/sample'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { DoubleDFile } from 'types/services.type'

export interface PublishCouponProps extends DivAttributes {
  _css?: AdditionalCss
}
export const COUPON_DISCOUNT_TYPE = ['금액 할인', '정률 할인'] as const
export type DiscountValuesType = ValueOfArray<typeof COUPON_DISCOUNT_TYPE>

export const COUPON_TARGET_TYPE = ['타겟 발급', '코드 발급'] as const
export type CouponTargetType = ValueOfArray<typeof COUPON_TARGET_TYPE>

export const CATEGORIES_DROPDOWN_LIST: DropdownListType = {
  타일: '타일',
  청소: '청소',
  도배: '도배',
  바닥: '바닥',
  기타: '기타',
} as const
export type CategoryType = ValueOf<typeof CATEGORIES_DROPDOWN_LIST>

export interface CouponInitialValueType {
  discountType: DiscountValuesType
  period: PickerPeriod
  category: CategoryType
  couponName: string
  minAmount: number | null

  discountAmount: number | null

  maxDiscountAmount: number | null
  discountPercent: number | null

  targetType: CouponTargetType
  targetUser: sampleUserType[]
  targetExcels: DoubleDFile[]
  // total 이 없으면 null로 해야함
  targetCodeCount: {
    used: number | null | undefined
    total: number
  } | null
}

export const COUPON_PUBLISH_INITIAL_VALUE: CouponMatchInput = {
  startAt: null,
  endAt: null,
  name: '',
  isPercent: false,
  issueMethod: 'search',
  categoryName: '',
  target: 'specify',
  userList: [],
  maxAmount: null,
  minAmount: null,
  amount: null,
  code: null,
  issuedCount: null,
  usedCount: null,
}

export interface CouponPublishInput {
  couponId?: string // 화면 깜빡임 방지 플래그 용
  startAt: Dayjs | null
  endAt: Dayjs | null
  name: string
  isPercent: boolean
  amount: number | null
  minAmount: number | null
  maxAmount: number | null
  categoryName: string
  target: CouponTarget
  code: string | null
  issuedCount: number | null
  usedCount: number | null
  issueMethod: IssueMethod
  targetUser: LiveSearchUserInfo[]
  excelFileName: string | null
}
export interface CouponMatchInput {
  couponId?: string // 화면 깜빡임 방지 플래그 용
  usedCount: number | null
  startAt: Dayjs | null
  endAt: Dayjs | null
  name: string
  isPercent: boolean
  amount: number | null
  minAmount: number | null
  maxAmount: number | null
  categoryName: string
  target: CouponTarget
  code: string | null
  issuedCount: number | null
  userList: TargetUser[]
  issueMethod: IssueMethod
}
// API
export interface APICreateNewCoupon {
  startAt: string
  endAt: string
  name: string
  isPercent: boolean
  amount: number
  minAmount: number
  maxAmount?: number
  categoryName: string
  target: CouponTarget
  code: string | null
  issuedCount: number
  userList?: TargetUser[]
}

export type APIEditCoupon = Omit<
  APICreateNewCoupon,
  'isPercent' | 'excelFileName' | 'code' | 'target'
>
