import { Key_Of_ADMIN_TYPE } from 'types/api/samples/adminWeb'

// 각 부서 별 api 통신 값 매칭 '부서이름' : 'api통신value'
export const ADMIN_TYPE_DROPDOWN_LIST = {
  전체: '전체',
  고객지원팀: '고객지원팀',
  경영지원팀: '경영지원팀',
  자재지원팀: '자재지원팀',
} as const
export type AdminKeyType = KeyOf<typeof ADMIN_TYPE_DROPDOWN_LIST>
// 각 검색 키워드 별 api 통신 값 매칭 '검색 키워드' : 'api통신value'
export const ADMIN_QUERY_DROPDOWN_LIST = {
  전화번호: '전화번호',
  관리자명: '관리자명',
  '관리자 유형': '관리자 유형',
  담당업무: '담당업무',
} as const

export const isAdminType = (
  value: any
): value is typeof ADMIN_TYPE_DROPDOWN_LIST[AdminKeyType] => {
  return (
    value !== null &&
    Object.values(ADMIN_TYPE_DROPDOWN_LIST).some((item) => item === value)
  )
}

export type AdminFilterListType = {
  office: string
}
export const initialAdminFilterValues: AdminFilterListType = {
  office: '',
}

// ** Table 관련 type
export type AdminListRowType = {
  id: string
  office: string
  info: { name: string; phone: string }
  role: '일반 관리자' | '최고 관리자'
  task: string
  disabled: boolean
  pwdChangeRequest: boolean
  cursor: string
}

// **  API type
export interface APIAdminListData {
  id: string
  role: Key_Of_ADMIN_TYPE
  name: string
  phone: string
  office: string //소속
  position: string | null //직급
  task: string | null //담당업무
  createdAt: string
  deletedAt: string | null
  pwdRestRequestAt: string | null
}
