import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { DoubleDFile } from 'types/services.type'
const excel =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
export const FILE_TYPE = {
  excel:
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  image: 'image/x-png, image/png, image/gif, image/jpeg',
  doc: `${excel},.hwp, application/msword, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*`,
} as const
type FileType = typeof FILE_TYPE

export type FileTypeKeys = KeyOf<FileType>
export interface FileUploadBoxProps extends DivAttributes {
  _type: FileTypeKeys
  _fileList: DoubleDFile[]
  _emitValue: (value: DoubleDFile[]) => void
  _multiple?: boolean
  _btnText?: string
  _btnWidth?: number
  _placeholder?: string
  _css?: AdditionalCss
  _uploadState?: 'progress' | 'success' | 'error'
  // -1 : error, 100 : completed
  _progress?: number
}
