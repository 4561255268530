import { API_URL_KEYS } from 'API_URL'
import callAxios, { handleError } from './callAxios'

export const callFileUpload = async (
  file: File,
  type: API_URL_KEYS,
  url: string,
  optionValue?: { [key in string]: string | number | boolean }
) => {
  try {
    const buffer = await file.arrayBuffer()
    const fileName = file.name
    // const fileId = uuid()
    // const ext = file.name.split('.')[file.name.split('.').length - 1]

    const res = await callAxios(type).put<{
      data: { uploadUrl: string; cacheControl: number; fileName: string }
    }>(
      url,
      {
        fileName,
        ...optionValue,
      },
      {}
    )
    if (res.data.data.uploadUrl) {
      const uploadRes = await callAxios().put(res.data.data.uploadUrl, buffer, {
        headers: {
          skipToken: true,
          'Cache-Control': res.data.data.cacheControl ?? undefined,
        },
      })

      if (uploadRes.status === 200) {
        return `${res.data.data.fileName}`
      }
      return null
    }
    return null
  } catch (error) {
    handleError(error)
  }
}
