import { css } from '@emotion/react'
import Badge from 'components/Badge'
import { SERVICE_BADGE_COLOR } from 'components/Badge/Badge'
import ContentsBox from 'components/ContentsBox'
import DownloadSpreadSheet from 'components/DownloadSpreadSheet'
import Dropdown from 'components/Dropdown'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'

import { rebuildSpreadSheetDataForApi } from 'components/DownloadSpreadSheet/SelectFilterForSpreadSheet/SelectFilterForSpreadSheet'
import TableCell from 'components/TableComponent/TableCell'
import { TableRow } from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import useFetch from 'hooks/useFetch'
import useSearchFilter from 'hooks/useSearchFilter'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow } from 'themes/Shared.styles'
import {
  blobGetXlsx,
  cn,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
} from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import S from './UserList.styles'
import {
  ApiUserListItem,
  USER_STATE_DROPDOWN_LIST,
  USER_TYPE_DROPDOWN_LIST,
  UserFilterListType,
  UserListProps,
  initialUserFilterValues,
  isUserState,
  isUserType,
} from './UserList.types'

// EDIT:THIS
type ResponseKeyName = {
  id: string
  type: string
  name: string
  phone: string
  createdAt: string
}

// EDIT:THIS
const UserListTableConfigs: TableCellConfig<ResponseKeyName>[] = [
  {
    _type: 'dropdown',
    _id: 'type',
    _label: '회원 등급',
    _size: 106,
    _data: { ...USER_TYPE_DROPDOWN_LIST, 전체: '' },
    _value: '',
    _position: 'center',
  },
  {
    _type: 'default',
    _id: 'name',
    _size: 80,
    _label: '회원명',
  },
  {
    _type: 'default',
    _id: 'phone',
    _size: 'auto',
    _label: '전화 번호',
  },
  {
    _type: 'order',
    _id: 'createdAt',
    _size: 160,
    _label: '가입일',
    _order: 'INITIAL',
    _position: 'left',
  },
]
const USER_QUERY_DROPDOWN_LIST = {
  전화번호: 'phone',
  회원명: 'name',
} as const

// EDIT:THIS
const responseParser = (response: ApiUserListItem[]): ResponseKeyName[] => {
  const rebuild = response.map((item: ApiUserListItem, index: number) => {
    return {
      id: item.id,
      type: item.role,
      name: item.name,
      phone: item.phone,
      createdAt: item.createdAt,
    } as ResponseKeyName
  })
  return rebuild
}

const UserList = ({ _css, ...props }: UserListProps) => {
  const navigate = useNavigate()
  const topInfoRef = useRef() as MutableRefObject<HTMLDivElement>
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [tableHeaderOffset, set_tableHeaderOffset] = useState<number>(0)
  const [headerOffset, set_headerOffset] = useState<number>(0)

  const [stats] = useFetch<{ total: number; U1: number; U2: number }>(
    'user',
    `/users/stats`
  )
  useEffect(() => {
    if (topInfoRef.current && headerRef.current) {
      const headerSize =
        topInfoRef.current.getBoundingClientRect().height +
        headerRef.current.getBoundingClientRect().height
      set_headerOffset(topInfoRef.current.getBoundingClientRect().height)
      set_tableHeaderOffset(headerSize)
    }
  }, [])

  const [selectedId, set_selectedId] = useState<string>('')

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: initialUserFilterValues,
      queryList: USER_QUERY_DROPDOWN_LIST,
    },
    (obj, queryName) => {
      const { searchType, searchValue, userType, userStatus } = obj
      return {
        userType: isUserType(userType) ? userType : 'all',
        userStatus: isUserState(userStatus) ? userStatus : 'normal',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    (obj) => {
      const { queryName = 'search', terms = '', ...rest } = obj

      return removeUndefined(
        {
          ...rest,
          ...parserForSearchWithTermsAndQueryName(terms, queryName),
        },
        'all'
      )
    }
  )

  const apiURI = useMemo(() => {
    // EDIT:THIS
    return `/users/list?take=10${
      window.location.search ? '&' + searchParams.toString() : ''
    }`
  }, [searchParams])

  return (
    <ContentsBox _type="relative" _css={css``}>
      {/* ContentsBox에서 스타일 상속 받음 */}
      <S.Wrap _css={_css} {...props}>
        {/* EDIT:THIS */}
        <TopInfo ref={topInfoRef}>
          <TopInfoRow
            _label={`전체`}
            _contents={`${thousand(stats?.total)}명`}
          />
          <TopInfoRow
            _label={`일반 회원`}
            _contents={`${thousand(stats?.U1)}명`}
          />
          <TopInfoRow
            _label={`정기 회원`}
            _contents={`${thousand(stats?.U2)}명`}
          />
        </TopInfo>
        {/* EDIT:THIS */}
        <TableTitle
          ref={headerRef}
          _css={css`
            top: ${headerOffset}px;
          `}
          _left={`회원 리스트`}
          _right={
            <FlexRow gap={16}>
              <DownloadSpreadSheet
                _noPeriod
                _dropdownFilters={[
                  {
                    label: '필터',
                    value: '전체',
                    key: 'type',
                    list: {
                      전체: '전체',
                      '정기 회원': '정기 회원',
                      '일반 회원': '일반 회원',
                    },
                  },
                  {
                    label: '회원 상태',
                    value: '정상',
                    key: 'status',
                    list: {
                      정상: '정상',
                      휴면: '휴면',
                      탈퇴: '탈퇴',
                    },
                  },
                ]}
                _tableConfig={[
                  '회원등급',
                  '가입일',
                  '회원명',
                  '전화번호',
                  '회원상태',
                ]}
                _emitValue={async (value) => {
                  const rebuild = rebuildSpreadSheetDataForApi(value)
                  blobGetXlsx(
                    'user',
                    `/users/excel${rebuild.queryString}`,
                    '회원_목록'
                  )
                }}
              />
            </FlexRow>
          }
        />
        {/* EDIT:THIS */}
        <FilterPart<UserFilterListType>
          _value={initialValues}
          _placeholder="주문번호 또는 주문자명, 상품명 검색"
          _queryNameList={USER_QUERY_DROPDOWN_LIST}
          _queryNameFormat={{ phone: 'phone' }}
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _FilterContents={({ _value, _emitValue, ...props }) => {
            return (
              <FlexRow gap={24} width="auto">
                <Dropdown.Underline
                  _css={css`
                    width: 120px;
                  `}
                  _value={_value?.userType ?? 'all'}
                  _list={USER_TYPE_DROPDOWN_LIST}
                  _emitValue={(value) => {
                    if (_emitValue && _value)
                      _emitValue({
                        ..._value,
                        userType: isUserType(value) ? value : 'all',
                      })
                  }}
                />
                <Dropdown.Underline
                  _css={css`
                    width: 120px;
                  `}
                  _value={_value?.userStatus ?? null}
                  _list={USER_STATE_DROPDOWN_LIST}
                  _emitValue={(value) => {
                    if (_emitValue && _value)
                      _emitValue({
                        ..._value,
                        userStatus: isUserState(value) ? value : 'normal',
                      })
                  }}
                />
              </FlexRow>
            )
          }}
        />
        {/* EDIT:THIS */}
        <RoundFillBox>
          <TableComponent<ResponseKeyName>
            _type="user"
            _url={apiURI}
            _topOffset={tableHeaderOffset}
            _config={UserListTableConfigs}
            _parser={(response: DoubleDListWithPaging<ApiUserListItem>) => {
              const rebuild = responseParser(response.list)
              return {
                _list: rebuild,
                _cursor: disposeCursor(response.paging.cursor, 'cursor'),
              }
            }}
            _EmptyContent={`회원이 없습니다`}
            _ListContent={({ _row, _index }) => {
              const rowId = _row.id ? String(_row.id) : String(_index)
              return (
                <TableRow
                  className={cn({
                    active: selectedId === rowId,
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    set_selectedId(rowId)
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation()
                    // EDIT:THIS !
                    navigate(`/member/user/${rowId}`)
                  }}
                >
                  {UserListTableConfigs.map((item, columnIndex) => {
                    // EDIT:THIS
                    const _id = item._id as keyof ResponseKeyName
                    const _value = _row[_id]
                    return (
                      <TableCell
                        key={'body' + _index + columnIndex}
                        _position={item._position}
                        _size={item._size}
                      >
                        {_id === 'createdAt' ? (
                          <div>
                            {dayjs(_value).format('YYYY-MM-DD(dd) HH:mm')}
                          </div>
                        ) : _id === 'type' ? (
                          <>
                            {_value === 'REGULAR' ? (
                              <Badge {...SERVICE_BADGE_COLOR['정기회원']}>
                                정기회원
                              </Badge>
                            ) : (
                              <Badge {...SERVICE_BADGE_COLOR['일반회원']}>
                                일반회원
                              </Badge>
                            )}
                          </>
                        ) : _id === 'phone' ? (
                          <div>{parseToFormattedStr(_value, 'phone')}</div>
                        ) : (
                          <div>{_value}</div>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrap>
    </ContentsBox>
  )
}
export default UserList
