import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const EditHouseholdPopupStyle = {
  Wrap: styled.div`
    padding: 32px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 40px;
    ${addCssProps}
  `,
  RoomUnitToggleButton: styled.div`
    display: flex;
    user-select: none;
    align-items: center;
    padding: 4px 8px;
    gap: 4px;
    ${typography['Body/Small/Bold']}
    background-color: ${colors['Grayscale/Background Dark']};
    color: ${colors['Grayscale/Gray Light']};
    border-radius: 8px;
    cursor: pointer;
    div.active {
      color: ${colors['Grayscale/Black light']};
    }
  `,
}

export default EditHouseholdPopupStyle
