import {
  ImagesGlobalLogo,
  ImagesSideBarPassword,
  ImagesSideBarSignOut,
} from 'assets'
import classNames from 'classnames'
import {
  useAppDispatch,
  useAppSelector,
  useShallowSelector,
} from 'hooks/reduxHooks'
import PasswordChangeModal from 'layout/SideBar/PasswordChangeModal'
import { debounce } from 'lodash-es'
import {
  Fragment,
  MutableRefObject,
  RefAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Link,
  LinkProps,
  matchPath,
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom'
import { selectAuth, setAuth } from 'store/authSlice'
import { setConfig } from 'store/configSlice'
import { selectRefresh } from 'store/refreshSlice'
import { DivAttributes } from 'themes/styles.type'
import callAxios from 'utils/callAxios'
import { cn } from 'utils/helpers'
import S from './SideBar.styles'
import {
  BothIconType,
  NavList,
  NavListType,
  SideBarProps,
  chevronIcon,
  isNoChildNav,
} from './SideBar.types'

const CustomLink = ({
  children,
  className,
  to,
  parent,
  onClick,
  ...props
}: LinkProps & RefAttributes<HTMLAnchorElement> & { parent?: string }) => {
  const dispatch = useAppDispatch()
  let resolved = useResolvedPath(parent ?? to)
  let match = useMatch({
    path: resolved.pathname,
    end: resolved.pathname === '/' ? true : false,
  })
  let matchTrue = useMatch({
    path: resolved.pathname,
    end: true,
  })
  const navigate = useNavigate()
  const debounceRef = useRef(
    debounce(() => {
      if (window.location.search) {
        navigate(window.location.pathname, { replace: true })
        return
      }
      dispatch(setConfig({ refresh: true }))
    }, 300)
  )

  return (
    <Link
      className={classNames({ active: match }, className)}
      to={to}
      onClick={(e) => {
        if (onClick) onClick(e)
        if (matchTrue) {
          e.stopPropagation()
          e.preventDefault()
          debounceRef.current()
          window.scrollTo(0, 0)
        }
      }}
      {...props}
    >
      {children}
    </Link>
  )
}
interface BothIconProps {
  to: string
  icon: BothIconType
  activeParent?: string
}
const BothIcon = ({
  to,
  icon,
  activeParent,
  className,
  ...props
}: BothIconProps &
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >) => {
  const location = useLocation()
  return (
    <>
      {matchPath({ path: to, end: to === '/' }, location.pathname) ? (
        <img
          src={icon.active}
          className={cn({ open: activeParent }, className)}
          alt={'active'}
        />
      ) : (
        <img
          src={icon.default}
          className={cn({ open: activeParent }, className)}
          alt={''}
        />
      )}
    </>
  )
}
interface NavParentProps {
  parent: string
  children?: React.ReactNode
  activeParent?: string
}
const NavParent = ({
  parent,
  children,
  className,
  activeParent,
  ...props
}: NavParentProps & DivAttributes) => {
  const location = useLocation()
  return (
    <div
      className={classNames(
        {
          active: matchPath(
            { path: parent, end: false },
            activeParent ?? location.pathname
          ),
        },
        'nav-parent',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
const setToLocalStorage = (name: string, item: any) => {
  localStorage.setItem(name, JSON.stringify(item))
}
const getFromLocalStorage = <T,>(name: string, item: T) => {
  const itemString = localStorage.getItem(name)
  if (itemString) {
    return JSON.parse(itemString) as T
  } else {
    setToLocalStorage(name, item)
    return item
  }
}
const rebuildWorksCount = (
  value: {
    total: number
    orderDone: number
    ready: number
    ing: number
    cancel: number
    finished: number
  },
  estimates: number
) => {
  return {
    '/work/estimates': estimates,
    '/work/ordered': value.orderDone,
    '/work/waiting': value.ready,
    '/work/ongoing': value.ing,
    '/work/completed': value.finished,
    '/work/cancelled': value.cancel,
  }
}
const checkArray = [
  '/work/estimates',
  '/work/ordered',
  '/work/waiting',
  '/work/ongoing',
  '/work/completed',
  '/work/cancelled',
] as const
const checkInCountArray = (
  value: string
): value is typeof checkArray[number] => {
  return (
    value !== undefined &&
    checkArray.includes(value as typeof checkArray[number])
  )
}
const SideBar = ({ _css, ...props }: SideBarProps) => {
  const dispatch = useAppDispatch()
  const userAuth = useShallowSelector(selectAuth)
  const [navItemList, set_navItemList] = useState<NavListType>([])
  const [opened, set_opened] = useState<string[] | null>(null)
  const [isModal, set_isModal] = useState<boolean>(false)
  const fireOnceRef = useRef(false) as MutableRefObject<boolean>
  const refreshCount = useAppSelector(selectRefresh)
  const fetchCount = useCallback(async (list: NavListType) => {
    const estimatesQueueCountResponse = await callAxios('order').get<
      DoubleDResponse<number>
    >(`/estimates/new-count`)
    const workStats = await callAxios('task').get<
      DoubleDResponse<{
        total: number
        orderDone: number
        ready: number
        ing: number
        cancel: number
        finished: number
      }>
    >(`/order/stats`)
    if (
      workStats.data.data &&
      typeof estimatesQueueCountResponse.data.data === 'number'
    ) {
      const rebuild = rebuildWorksCount(
        workStats.data.data,
        estimatesQueueCountResponse.data.data
      )
      const withCount = list.filter((item) => {
        if (!isNoChildNav(item)) {
          item.children.map((child) => {
            if (checkInCountArray(child.to) && rebuild[child.to]) {
              child.count = rebuild[child.to]
            }
            return child
          })
        }
        return item
      })
      set_navItemList(withCount)
    }
  }, [])
  useEffect(() => {
    if (NavList) {
      fetchCount(NavList)
    }
  }, [fetchCount, refreshCount])
  useEffect(() => {
    if (navItemList.length > 0 && !fireOnceRef.current) {
      fireOnceRef.current = true
      const openedListFromDisk = getFromLocalStorage(
        'openedList',
        [] as string[]
      )
      const childItems = document.querySelectorAll('.child-nav')
      childItems.forEach((el) => {
        const target = el as HTMLElement
        const bound = target.getBoundingClientRect()
        const targetParent = target.getAttribute('data-parent') as string
        target.setAttribute('data-height', String(bound.height))
        if (!openedListFromDisk.includes(targetParent)) {
          if (bound.height)
            target.setAttribute('data-height', String(bound.height))
          target.style.height = '0px'
        } else {
          let nextHeight = bound.height === 0 ? 'auto' : bound.height + 'px'
          target.style.height = nextHeight
        }
      })
      set_opened(openedListFromDisk)
    }
  }, [navItemList])
  const toggleOpenCategories = (v: string, target: HTMLDivElement) => {
    if (!opened) return
    const childNav = target.parentElement?.querySelector(
      '.child-nav'
    ) as HTMLDivElement
    let targetHeight = childNav?.getAttribute('data-height')
    let currentHeight = childNav?.getBoundingClientRect()
    if (!targetHeight) return
    if (!childNav) return
    let openedList = opened.includes(v)
      ? opened.filter((i) => i !== v)
      : [...opened, v]
    if (opened.includes(v)) {
      if (currentHeight && childNav)
        childNav.setAttribute('data-height', String(currentHeight.height))
      childNav.style.height = 0 + 'px'
    } else {
      let nextHeight =
        Number(targetHeight) === 0 || !targetHeight
          ? 'auto'
          : targetHeight + 'px'
      childNav.style.height = nextHeight
    }
    set_opened(openedList)
    setToLocalStorage('openedList', openedList)
  }
  const hasInList = (v: string) => {
    if (!opened) return undefined
    return opened.includes(v) ? v : undefined
  }
  const handleLogout = () => {
    localStorage.clear()
    dispatch(setAuth({ status: 'unauthorized', token: null }))
  }
  return (
    <>
      <PasswordChangeModal
        _isModal={isModal}
        _closeModal={() => {
          set_isModal(false)
        }}
      />
      <S.Wrap _css={_css} {...props}>
        <div className="logo-part">
          <CustomLink
            to={'/'}
            className="logo"
            onClick={() => {
              fetchCount(NavList)
            }}
          >
            <img src={ImagesGlobalLogo} alt="logo" />
          </CustomLink>
        </div>
        <S.NavMenu id="nav-menu" className={cn({ visible: opened })}>
          {navItemList.map((item) => {
            return (
              <Fragment key={'parent' + item.label}>
                {isNoChildNav(item) ? (
                  <CustomLink
                    className={cn({
                      home: item.to === '/',
                      'no-child': item.to !== '/',
                    })}
                    to={item.to}
                    onClick={() => {
                      fetchCount(NavList)
                    }}
                  >
                    <div className="left">
                      <BothIcon
                        to={item.to === '/' ? '/' : item.to}
                        icon={item.icon}
                      />
                      {item.label}
                    </div>
                  </CustomLink>
                ) : (
                  <S.NavGroup>
                    <NavParent
                      className="nav-parent"
                      parent={item.parent}
                      onClick={(e) => {
                        toggleOpenCategories(item.parent, e.currentTarget)
                      }}
                    >
                      <div className="left">
                        <BothIcon to={item.parent} icon={item.icon} />
                        {item.label}
                      </div>
                      <div className="right">
                        <BothIcon
                          to={item.parent}
                          icon={chevronIcon}
                          className="chevron"
                          activeParent={hasInList(item.parent)}
                        />
                      </div>
                    </NavParent>
                    <S.ChildList
                      data-parent={item.parent}
                      className={cn(
                        { open: hasInList(item.parent) },
                        'child-nav'
                      )}
                    >
                      {item.children.map((child) => {
                        return (
                          <CustomLink
                            key={child.to}
                            to={child.to}
                            className="child"
                            onClick={() => {
                              fetchCount(NavList)
                            }}
                          >
                            <div className="left">
                              <S.ChildMark className="mark" />
                              <div>{child.label}</div>
                            </div>
                            <div className="right">
                              {child.count !== 0 && (
                                <S.ChildCount>{child.count}</S.ChildCount>
                              )}
                            </div>
                          </CustomLink>
                        )
                      })}
                    </S.ChildList>
                  </S.NavGroup>
                )}
              </Fragment>
            )
          })}
        </S.NavMenu>
        <S.Bottom>
          <div className="name">{userAuth.userInfo?.name}</div>
          <div className="duty">
            <div>{userAuth.userInfo?.office}</div>
            {userAuth.userInfo?.position && (
              <>
                <div>|</div>
                <div>{userAuth.userInfo?.position}</div>
              </>
            )}
          </div>
          <div className="buttons">
            <S.MainButton
              onClick={() => {
                set_isModal(true)
              }}
            >
              <img src={ImagesSideBarPassword} alt="비밀변호 변경" />
              비밀번호 변경
            </S.MainButton>
            <S.MainButton onClick={handleLogout}>
              <img src={ImagesSideBarSignOut} alt="로그아웃" />
              로그아웃
            </S.MainButton>
          </div>
        </S.Bottom>
      </S.Wrap>
    </>
  )
}
export default SideBar
