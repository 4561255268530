import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const DraggablePopupStyle = {
  Container: styled.div`
    position: fixed;
    z-index: 9998;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  Wrap: styled.div`
    max-height: calc(100% - 64px);
    position: fixed;
    z-index: 9998;
    min-width: 650px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 10px 40px 0px #6363634d;
    .drag-modal-close {
      cursor: pointer;
      user-select: none;
    }
    .drag-modal-header {
      border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
      border-radius: 20px 20px 0 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      height: 100px;
      flex-shrink: 0;
      padding: 0 32px;
      ${typography['Display/1 Bold']}
      color: ${colors['Grayscale/Black light']};
      .drag-modal-header-title {
        cursor: grab;
        flex: 1;
      }
    }
    .drag-modal-contents {
      position: relative;
      width: 100%;
      overflow-y: auto;
      overscroll-behavior-y: contain;
      margin: 100px auto 120px;
      max-height: calc(100vh - 100px - 120px - 64px);
      &.no-bottom {
        max-height: calc(100vh - 100px - 64px);
        margin: 100px auto 0;
      }
    }
    .drag-modal-bottom {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 120px;
      min-height: 120px;
      flex-shrink: 0;
      padding: 40px 32px 32px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      & > button {
        padding: 12px 0;
        &:first-of-type {
          width: 148px;
        }
        &:last-of-type {
          width: 200px;
        }
      }
    }
    ${addCssProps}
  `,
}

export default DraggablePopupStyle
