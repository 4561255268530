import styled from '@emotion/styled/macro'
import { ImagesMainSendMessage } from 'assets'
import { colors } from 'themes/styles'

const MessagePopUpStyle = {
  MessageBtn: styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 100px;
    bottom: 76px;
    z-index: 1000;
  `,
  BtnWrapper: styled.div`
    position: relative;
    width: 64px;
    height: 64px;
    filter: drop-shadow(1px 4px 12px rgba(97, 94, 122, 0.19));
    cursor: pointer;

    &:hover {
      path {
        fill: ${colors['Primary/Default']};
      }
      circle {
        fill: ${colors['White/White off']};
      }
    }
  `,
  BubbleWrapper: styled.div`
    position: absolute;
    width: 180px;
    height: 72px;
    bottom: calc(100% + 10px);
    left: calc(-90px + 32px);
    background: no-repeat center/contain url(${ImagesMainSendMessage});
  `,
  ContentWrapper: styled.div`
    .hr {
      background-color: ${colors['Grayscale/Gray Lighter']};
      height: 1px;
      width: 100%;
    }
    .padding-wrapper {
      padding: 32px 32px 0px;
    }
    .checkbox-wrapper {
      & > .flex-row:nth-of-type(1) {
        margin-bottom: 12px;
      }
      & > .flex-row {
        display: flex;
        gap: 10px;
        & > div {
          width: 189px;
          padding: 4px 0px;
        }
      }
    }
    .textarea {
      height: 180px;
    }
  `,
}

export default MessagePopUpStyle
