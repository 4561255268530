import { css } from '@emotion/react'
import { colors } from 'themes/styles'

export const d = 'ss'

const NoticePublishStyles = {
  QuillEditorCss: css`
    & {
      flex: 1;
      margin-top: 24px;
    }
    &.super-ql {
      & {
        border: 1px solid ${colors['Grayscale/Gray Lighter']};
        padding: 12px;
        border-radius: 16px;
      }
      .ql-toolbar.ql-snow {
        margin: 0;
        border: none;
        border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
        border-radius: 0;
      }
      .ql-container.ql-snow {
        border: none;
        border-radius: 0;
      }
    }
  `,
}

export default NoticePublishStyles
