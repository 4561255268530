import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  parseObjectToQueryString,
  parseQueryStringToObject,
} from 'utils/parsers'

export type TermsAndQueryNameType = { terms: string; queryName: string }
/**
 * defaultValue: {
 *  filterList: T
 *  queryList?: DropdownListType
 * }
 *
 * queriesToObject: query object -> filterList 상태값으로 파싱하세요.
 *
 * objectToQuery: filterList => query object로 파싱하세요. 주소 search query로 갑니다.
 */
const useSearchFilter = <T extends object>(
  defaultValue: {
    filterList: T
    queryList?: DropdownListType
  },
  queriesToObject: (
    obj: QueryObject,
    queryName: string | null
  ) => T & { terms: string; queryName?: string },
  objectToQuery: (obj: T & TermsAndQueryNameType) => QueryObject
) => {
  const navigate = useNavigate()
  const [searchParams, set_searchParams] = useSearchParams()
  const [initialValues, set_initialValues] = useState<
    T & TermsAndQueryNameType
  >({ ...defaultValue.filterList, terms: '', queryName: '' })

  useEffect(() => {
    const obj = parseQueryStringToObject(searchParams.toString())
    let queryName: string | null = null
    if (defaultValue.queryList) {
      const QueryNames = Object.values(defaultValue.queryList)
      queryName =
        Object.keys(obj).filter((item) => QueryNames.includes(item))[0] ?? null
    }
    const initValues: T & { terms: string; queryName?: string } =
      queriesToObject(obj, queryName)
    set_initialValues({ ...initValues, queryName: initValues.queryName ?? '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const handleSetLocation = (
    value: T & TermsAndQueryNameType,
    addHistory?: boolean
  ) => {
    const parsed = objectToQuery(value)

    if (addHistory) {
      set_searchParams(parseObjectToQueryString(parsed, true))
    } else {
      navigate(
        window.location.pathname + `?${parseObjectToQueryString(parsed, true)}`,
        { replace: true }
      )
    }
  }
  return { searchParams, initialValues, handleSetLocation }
}
export default useSearchFilter
