import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import { TaskAdditionalCost } from '../ExtraFeesInService/ExtraFeesInService.types'

export const EXTRA_FEE_INITIAL_VALUE: TaskAdditionalCost = {
  taskName: '',
  price: null,
  taskPrice: null,
  note: '',
  messageToUser: '',
}

export interface ExtraFeeProps extends DivAttributes {
  _css?: AdditionalCss
  _open: boolean
  _onClose: () => void
  _emitValue: (value: TaskAdditionalCost, index?: number) => void
  _noUserRequest?: boolean
  _data?: {
    index: number
    data: TaskAdditionalCost
    readonly?: boolean
  }
}
