import { css } from '@emotion/react'
import { PrimaryOutlineButton } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import RadioButton from 'components/RadioButton'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineFormatInput, {
  getFormats,
} from 'components/UnderlineInput/UnderlineFormatInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import AlertRow from 'pages/Work/components/AlertRow'
import { useEffect, useState } from 'react'
import {
  passwordInputRegex,
  passwordRegex,
  usernameRegex,
} from 'templates/Authorization/Authorization.helpers'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { typography } from 'themes/styles'
import { PWD_NOT_CHANGED } from 'types/share'
import { ModifyTechsProfilesProps } from './ModifyTechsProfiles.types'

const ModifyTechsProfiles = ({
  _id,
  _css,
  inputs,
  handleInput,
  handleValues,
  fetchInit,
  ...props
}: ModifyTechsProfilesProps) => {
  const [isHidePwdInput, set_isHidePwdInput] = useState<boolean>(false)
  // _id 존재 시 (수정화면) 비밀번호 input 숨김 처리
  useEffect(() => {
    if (!!_id) {
      set_isHidePwdInput(true)
    }
  }, [_id])

  // 비밀번호 형식 체크
  useEffect(() => {
    if (inputs.password === PWD_NOT_CHANGED) return
    if (inputs.password.length >= 8) {
      if (passwordRegex.test(inputs.password)) {
        handleInput('passwordError', '')
      } else {
        handleInput('passwordError', '조합된 비밀번호를 입력해 주세요.')
      }
    } else {
      handleInput('passwordError', '8자리 이상 입력해주세요.')
    }
  }, [inputs.password, handleInput])
  return (
    <ContentBoxWrap>
      <BoxLayout
        _title={'기본 정보'}
        _titleSuffix={
          <>
            <CheckBoxRound
              _mini
              _active={inputs.measureAble}
              onClick={() => {
                handleInput('measureAble', (prev) => !prev.measureAble)
              }}
            >
              실측 가능
            </CheckBoxRound>
          </>
        }
      >
        <ContentRadiusBox gap={12}>
          <BoxRowComponent _label={`이름 *`}>
            <UnderlineInput
              value={inputs.name}
              handleValue={(value) => {
                handleInput('name', value.trim())
              }}
              placeholder="내용 입력"
              maxLength={20}
            />
          </BoxRowComponent>
          <BoxRowComponent _label={`아이디 *`}>
            <UnderlineInput
              value={inputs.userId}
              maxLength={20}
              handleValue={(value) => {
                if (value) {
                  if (value.length >= 6) {
                    if (usernameRegex.test(value)) {
                      handleInput('usernameError', '')
                    } else {
                      handleInput(
                        'usernameError',
                        '형식에 맞게 다시 입력해 주세요.'
                      )
                    }
                  } else {
                    handleInput('usernameError', '6자리 이상 입력해주세요.')
                  }
                } else {
                  handleInput('usernameError', '')
                }
                handleInput('userId', value)
              }}
              placeholder="6~20자의 영문 소문자, 숫자"
              error={inputs.usernameError}
            />
          </BoxRowComponent>
          <BoxRowComponent _label={`비밀번호 *`}>
            {isHidePwdInput ? (
              <FlexRow gap={0}>
                <PrimaryOutlineButton
                  _css={css`
                    padding: 2px 12px;
                    border-radius: 8px;
                  `}
                  onClick={() => {
                    handleInput('password', '')
                    set_isHidePwdInput(false)
                  }}
                >
                  비밀번호 변경
                </PrimaryOutlineButton>
              </FlexRow>
            ) : (
              <FlexRow gap={12}>
                <UnderlineInput
                  _css={css`
                    width: 100%;
                  `}
                  value={inputs.password}
                  handleValue={(value) => {
                    if (value && !passwordInputRegex.test(value)) return
                    handleInput('password', value)
                  }}
                  error={inputs.passwordError}
                  placeholder="8자 이상, 영문 + 숫자 + 특수문자 조합"
                />
                {!!_id && (
                  <TXT
                    _textStyle="Body/Small/Bold"
                    _color="Primary/Default"
                    onClick={() => {
                      handleInput('password', PWD_NOT_CHANGED)
                      handleInput('passwordError', '')
                      set_isHidePwdInput(true)
                    }}
                    _css={css`
                      flex-shrink: 0;
                      cursor: pointer;
                    `}
                  >
                    취소
                  </TXT>
                )}
              </FlexRow>
            )}
          </BoxRowComponent>
          <BoxRowComponent _label={`전화번호 *`}>
            <UnderlineFormatInput
              format={getFormats('phone', inputs.phone)}
              value={inputs.phone?.replace(/-/g, '') ?? ''}
              handleValue={(value) => handleInput('phone', value)}
              placeholder="내용 입력"
            />
          </BoxRowComponent>
          <BoxRowComponent _label={`주민 번호 *`}>
            <UnderlineFormatInput
              format={getFormats('residentNumber')}
              value={inputs.residentNumber?.replace(/-/g, '') ?? ''}
              handleValue={(value) => handleInput('residentNumber', value)}
              placeholder="내용 입력"
            />
          </BoxRowComponent>
          <BoxRowComponent _label={'계좌번호 *'}>
            <UnderlineInput
              placeholder="(예) 국민 1234567890"
              value={inputs.accountNumber}
              handleValue={(value) => handleInput('accountNumber', value)}
            />
          </BoxRowComponent>
        </ContentRadiusBox>
      </BoxLayout>
      <BoxLayout _title={'급여 정보'}>
        <ContentRadiusBox gap={12}>
          <BoxRowComponent _label={'소속 *'}>
            <RadioButton
              _mini
              _active={inputs.badges.techsType === '수수다'}
              onClick={() => {
                handleInput('badges', (prev) => ({
                  ...prev.badges,
                  techsType: '수수다',
                }))
              }}
            >
              수수다
            </RadioButton>
            <RadioButton
              _mini
              _active={inputs.badges.techsType === '프리랜서'}
              onClick={() => {
                handleValues((prev) => ({
                  ...prev,
                  badges: { ...prev.badges, techsType: '프리랜서' },
                  salary: null,
                  settlementRatio: null,
                }))
              }}
            >
              프리랜서
            </RadioButton>
          </BoxRowComponent>
          {inputs.badges.techsType === '수수다' && (
            <>
              <BoxRowComponent _label={'기본 급여 *'}>
                <UnderlineNumberInput
                  placeholder="숫자만 입력"
                  value={inputs.salary}
                  handleValue={(value) => handleInput('salary', value)}
                  suffix={'원'}
                />
              </BoxRowComponent>
              <BoxRowComponent _label={'정산 비율 *'}>
                <UnderlineNumberInput
                  placeholder="숫자만 입력"
                  value={inputs.settlementRatio}
                  handleValue={(value) => handleInput('settlementRatio', value)}
                  suffix={'%'}
                />
              </BoxRowComponent>
            </>
          )}
          {inputs.badges.techsType === '프리랜서' && (
            <AlertRow
              _css={css`
                align-items: flex-start;
                .bold {
                  ${typography['Body/Caption/Bold']}
                }
              `}
            >
              <div>
                프리랜서 정산 항목은 엑셀표로 일괄 업로드해주세요.
                <br />
                {`(메뉴 서비스 > 숙련공 > 상단 `}
                <span className="bold">보존 공임 일괄 조정</span>
                {` 버튼 클릭 > 해당되는 월 파일 업로드)`}
              </div>
            </AlertRow>
          )}
        </ContentRadiusBox>
      </BoxLayout>
    </ContentBoxWrap>
  )
}
export default ModifyTechsProfiles
