import { AdditionalCss, DivAttributes } from 'themes/styles.type'

export interface EditTechsProps extends DivAttributes {
  _css?: AdditionalCss
}

// * API
export interface APICreateTechs {
  name: string
  userName: string
  pwd: string
  phone: string
  personalNo: string
  accountInfo: string
  measuring: boolean
  type: typeof TECHS_TYPE[keyof typeof TECHS_TYPE]
  basicWages?: number
  settlementRatio?: number
  dispatchableZone: number[]
  availableSkill?: string[]
}

export const TECHS_TYPE = {
  수수다: 'susuda',
  프리랜서: 'freelance',
} as const
