import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const PopupStyle = {
  Wrap: styled.div`
    padding: 0;
    ${addCssProps}
  `,
  ModalPosition: styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  `,
  Contents: styled.div`
    padding: 0;
  `,
  Bottom: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 40px 32px 32px;
    & > button {
      flex: 1;
      padding: 12px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
  Title: styled.div`
    ${typography['Headline/Bold']}
    color: ${colors['Grayscale/Black']};
    padding: 0 32px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
  `,
  Body: styled.div`
    ${typography['Body/Small/Regular']}
    color: ${colors['Grayscale/Gray Default']};
    text-align: center;
    white-space: pre-line;
  `,
}
export default PopupStyle
