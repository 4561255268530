import { css } from '@emotion/react'
import { ImagesDetailsRestore } from 'assets'
import Badge from 'components/Badge'
import { BADGE_COLOR } from 'components/Badge/Badge'
import { GrayScaleOutline } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound'
import dayjs from 'dayjs'
import { OrderItemType, OrderSelectIdsType } from 'pages/Work/Work.types'
import { useEffect, useMemo, useState } from 'react'
import { cn, thousand } from 'utils/helpers'
import S from './OrderItem.styles'
import { OrderItemProps } from './OrderItem.types'
const ParseDate = ({ label, date }: { label: string; date: string }) => {
  return (
    <div className="order-date">
      <span className="bold">{label}</span>
      <div className="order-date">
        {date ? dayjs(date).format('YYYY-MM-DD') : '결제 전입니다.'}
      </div>
    </div>
  )
}
export const orderItemDisabled = (item: OrderItemType) => {
  return !!item.cancelledAt || !!item.serviceDoneAt || !item.paidAt
}
const OrderItem = ({
  _css,
  _item,
  className,
  _checkAble: checkAble,
  _forPayment,
  _selected,
  _noCancellation,
  onClick,
  onCheck,
  ...props
}: OrderItemProps) => {
  const [selected, set_selected] = useState<OrderSelectIdsType>({
    selectIds: [],
    rejectIds: [],
    deleteIds: [],
  })
  useEffect(() => {
    if (_selected) set_selected(_selected)
  }, [_selected])
  const disabled = useMemo(() => {
    return _forPayment
      ? (_noCancellation && checkAble) || (checkAble && !!_item.cancelledAt)
      : checkAble &&
          (_item.status === '시공완료' || _item.status === '시공취소')
  }, [_item, checkAble, _forPayment, _noCancellation])
  const checked = useMemo(() => {
    return (
      checkAble && _item.taskId && selected.selectIds.includes(_item.taskId)
    )
  }, [checkAble, selected, _item])
  const rejected = useMemo(() => {
    return (
      checkAble && _item.taskId && selected.rejectIds.includes(_item.taskId)
    )
  }, [checkAble, selected, _item])

  return (
    <>
      <S.Wrap
        className={cn(
          {
            cancel: _item.cancelledAt,
            checkAble,
            rejected,
            selected: checked,
            disabled,
            clickAble: !!onClick,
          },
          className
        )}
        onClick={(e) => {
          if (onClick) onClick(e)
        }}
        _css={_css}
        {...props}
      >
        <S.OrderGroup>
          <S.Top>
            <>
              {_noCancellation ? (
                <div className="badge">
                  <Badge {...BADGE_COLOR['AccentFill']}>취소불가</Badge>
                </div>
              ) : _item.cancelledAt ? (
                <div className="badge">
                  <Badge {...BADGE_COLOR['Gray']}>취소완료</Badge>
                </div>
              ) : _item.cancelRequest ? (
                <div className="badge">
                  <Badge {...BADGE_COLOR['Danger']}>취소요청</Badge>
                </div>
              ) : (
                <></>
              )}
              {_item.cancelledAt ? (
                <ParseDate label="취소일" date={_item.cancelledAt} />
              ) : _item.serviceDoneAt ? (
                <ParseDate label="시공 완료일" date={_item.serviceDoneAt} />
              ) : _item.serviceAt ? (
                <ParseDate label="시공 시작일" date={_item.serviceAt} />
              ) : _item.paidAt ? (
                <ParseDate label="결제일" date={_item.paidAt} />
              ) : (
                <ParseDate label="결제일" date={''} />
              )}
            </>
          </S.Top>
          <S.Details>
            <S.Description>
              <S.Title>
                {_item.name}
                {_item.discount && (
                  <S.Discount>
                    <div>{_item.discount.type}</div>
                    <div>- {thousand(_item.discount.amount)}원</div>
                  </S.Discount>
                )}
              </S.Title>
              {typeof _item.amount === 'number' && (
                <S.Sub>
                  <S.Price>{thousand(_item.amount)}원</S.Price>
                </S.Sub>
              )}
            </S.Description>
          </S.Details>
        </S.OrderGroup>
        <S.RightWrap className={cn({ visible: checkAble, hide: !checkAble })}>
          {rejected ? (
            <>
              <GrayScaleOutline
                _icon
                _css={css`
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                `}
                onClick={() => {
                  if (!disabled && onCheck) onCheck('rejectIds')
                }}
              >
                <img src={ImagesDetailsRestore} alt="restore" />
              </GrayScaleOutline>
            </>
          ) : (
            <>
              {_forPayment && _item.cancelRequest && (
                <div
                  onClick={() => {
                    if (!disabled && onCheck) onCheck('rejectIds')
                  }}
                >
                  거절
                </div>
              )}
              <CheckBoxRound
                _active={!!checked}
                _disabled={disabled}
                onClick={() => {
                  if (!disabled && onCheck) onCheck('selectIds')
                }}
              />
            </>
          )}
        </S.RightWrap>
      </S.Wrap>
    </>
  )
}
export default OrderItem
