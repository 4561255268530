import { css } from '@emotion/react'
import { GrayScaleText, PrimaryButton } from 'components/Buttons'
import UnderlineInput from 'components/UnderlineInput'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useMatchInput from 'hooks/useMatchInput'
import { useEffect, useMemo, useState } from 'react'
import { cleanSign, selectSign } from 'store/signSlice'
import SA from 'templates/Authorization/Authorization.styles'
import {
  decryptString,
  passwordInputRegex,
  passwordRegex,
} from '../Authorization.helpers'
import PasswordCheck from '../LoginComponent/PasswordCheck'
import LogoPart from '../LogoPart'
import S from './FindUserComponent.styles'
import { FindUserComponentProps, FindValue } from './FindUserComponent.types'

const initialValue: FindValue = {
  username: '',
  password: '',
  imp_uid: '',
}
const FindUserComponent = ({ _css, ...props }: FindUserComponentProps) => {
  const { imp_uid, username } = useAppSelector(selectSign)
  const dispatch = useAppDispatch()
  const { inputs, handleInput } = useMatchInput<FindValue>(initialValue)

  const [passwordError, set_passwordError] = useState<string>('')
  const [passwordType, set_passwordType] = useState<'password' | 'text'>(
    'password'
  )

  useEffect(() => {
    const disposeUid = (_e: string) => {
      const decrypted = decryptString(_e)
      if (decrypted) {
        handleInput('imp_uid', decrypted)
        handleInput('username', username)
      } else {
        window.location.href = '/'
      }
    }
    if (imp_uid) {
      disposeUid(imp_uid)
    } else {
      window.location.href = '/'
    }
  }, [handleInput, imp_uid, username])

  const canNotSubmit = useMemo(() => {
    return !passwordRegex.test(inputs.password)
  }, [inputs.password])
  const togglePasswordType = () => {
    set_passwordType((type) => (type === 'password' ? 'text' : 'password'))
  }
  const handleSubmit = () => {
    // TODO :: api
    window.location.reload()
  }
  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <LogoPart />
        <SA.Center>
          <SA.Header>계정찾기</SA.Header>
          <S.Contents>
            <S.CurrentId>
              <span>아이디</span>
              <span className="id">{inputs.username}</span>
            </S.CurrentId>
            <UnderlineInput
              _css={css`
                margin-top: 24px;
              `}
              type={passwordType}
              label="비밀번호"
              value={inputs.password}
              handleValue={(value) => {
                if (value) {
                  if (!passwordInputRegex.test(value)) return
                  if (value.length >= 8) {
                    if (passwordRegex.test(value)) {
                      set_passwordError('')
                    } else {
                      set_passwordError('조합된 비밀번호를 입력해 주세요.')
                    }
                  } else {
                    set_passwordError('8자리 이상 입력해주세요.')
                  }
                } else {
                  set_passwordError('')
                }
                handleInput('password', value)
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit()
                }
              }}
              error={passwordError}
              guide={'8자 이상, 영문 + 숫자 + 특수문자 조합'}
              _el={
                <PasswordCheck
                  _active={passwordType === 'text'}
                  _css={css`
                    margin-top: 8px;
                    justify-content: flex-end;
                    position: absolute;
                    bottom: -6px;
                    right: 0;
                  `}
                  onClick={togglePasswordType}
                />
              }
            />
          </S.Contents>
          <SA.Bottom>
            <S.MiniButton>
              <GrayScaleText
                _mini
                _css={css`
                  padding: 5px 12px;
                `}
                onClick={() => {
                  dispatch(cleanSign())
                }}
              >
                비밀번호가 생각나셨나요?
              </GrayScaleText>
            </S.MiniButton>
            <PrimaryButton
              _css={css`
                padding: 10px 20px;
              `}
              disabled={canNotSubmit}
              onClick={handleSubmit}
            >
              완료
            </PrimaryButton>
          </SA.Bottom>
        </SA.Center>
        <SA.Dim />
      </S.Wrap>
    </>
  )
}
export default FindUserComponent
