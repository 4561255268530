import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'

const OrderIconStyle = {
  OrderImage: styled.img`
    width: 16px;
    height: 16px;
    ${addCssProps}
  `,
}

export default OrderIconStyle
