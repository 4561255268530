import { NoticeValueType } from 'pages/Support/Notice/NoticeList/NoticeList.types'

export const NULL_OF_QUILL = '<p><br></p>'
export type NoticeOption = '중요공지' | '외부링크' | undefined
export interface noticePublishValueProps {
  noticeId?: string
  target: NoticeValueType
  notifyTitle: string | null
  notifyContent: string | null
  option?: NoticeOption
  title: string
  content: string | null
  link: string | null
}
export const NOTICE_PUBLISH_INITIAL_VALUE: noticePublishValueProps = {
  target: 'all',
  title: '',
  content: null,
  link: null,
  option: '중요공지',
  notifyContent: null,
  notifyTitle: null,
}
// API
export interface APINoticePublishData {
  targetUserType: NoticeValueType
  title: string
  link: string | null
  content: string | null
  isImportant: boolean
  notiTitle: string | null
  notiContent: string | null
}
