import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import {
  OptionAndPrice,
  PriceTagHeaderType,
} from 'pages/Services/Setting/ServiceManagement/ServiceManagement.types'
import {
  AddressInfoValuesType,
  CustomerInfoValuesType,
  OrderItemType,
} from 'pages/Work/Work.types'
import { TaskAdditionalCost } from 'pages/Work/components/ExtraFeesInService/ExtraFeesInService.types'
import { AdditionalCss, DivAttributes } from 'themes/styles.type'
import {
  COMMUNICATION_METHODS_DROPDOWN_LIST,
  DoubleDFile,
  FREE_PARKING_DROPDOWN_LIST,
  HAS_PASSWORD_DROPDOWN_LIST,
  LIVE_IN_DROPDOWN_LIST,
} from 'types/services.type'

export interface AddEstimatesProps extends DivAttributes {
  _css?: AdditionalCss
}

export const serviceRadio = ['청소/도배/타일/바닥', '기타 서비스'] as const

export type CategoryDropdownType = {
  list: DropdownListType
  isFirst?: boolean
  isCategory?: boolean
  isEstimatesItem?: number[]
}
export type OptionNames = 'h1' | 'h2' | 'h3' | 'h4'
type DropdownValueOf<T> = T[keyof T]
export type ServiceItemInEstimatesType = {
  serviceCategoryId: number | null
  serviceName: string
  serviceCategoryName: string
  data?: OrderItemType
  serviceRequests: string
  priceTagId: number | null
}
export type EstimatesDetailsValuesDefaultType = CustomerInfoValuesType &
  AddressInfoValuesType & {
    extraFees: TaskAdditionalCost[]
    zoneGroupId: number | null
    // 주문 금액
    orderDate: string
    dispatchFee: number | null
    serviceFee: number | null
    serviceMaterialPrice: number | null
    liftingPrice: number | null
    totalPrice: number | null

    // 결제 정보
    paymentInfo?: {
      paidAt: string
      paidPersonName: string
    }
    buyerName: string
    userId?: string

    // 수금 정보
    prePaidFee: number | null
    prePaidReason: string

    serviceItem: ServiceItemInEstimatesType

    desiredDate: string
    scheduledDate: string

    requests: string
    requestImages: DoubleDFile[]
  }
export type EstimatesDetailsValuesForUserType =
  EstimatesDetailsValuesDefaultType & {
    serviceState: '작성대기' | '전송완료'
    paymentInfoResult: {
      totalPrice: number
      taskPrice: number
      serviceMaterialPrice: number
      liftingPrice: number
      additionalCost: {
        messageToUser: string
        note: string
        price: number
        taskName: string
      }[]
    } | null
  }
export type EstimatesDetailsValuesType = EstimatesDetailsValuesDefaultType & {
  freeParking: DropdownValueOf<typeof FREE_PARKING_DROPDOWN_LIST> | null
  parkingMethod: string
  liveIn: DropdownValueOf<typeof LIVE_IN_DROPDOWN_LIST> | null
  hasPassword: DropdownValueOf<typeof HAS_PASSWORD_DROPDOWN_LIST> | null
  frontPwd: string
  doorPwd: string
  communicationMethod: DropdownValueOf<
    typeof COMMUNICATION_METHODS_DROPDOWN_LIST
  > | null
}

export const ADD_ESTIMATES_INITIAL_VALUE: EstimatesDetailsValuesType = {
  zoneGroupId: null,
  name: '',
  phone: '',
  customerType: null,
  funnels: [],
  extraFees: [],

  // 주문 금액
  orderDate: '',
  buyerName: '',
  dispatchFee: null,
  serviceFee: null,
  serviceMaterialPrice: null,
  liftingPrice: null,
  totalPrice: null,

  // 수금 정보
  prePaidFee: null,
  prePaidReason: '',

  bcode: null,
  address: '',
  addressOld: '',
  addressDetails: '',
  residentType: null,
  buildingSize: null,
  floors: null,
  elevator: null,

  serviceItem: {
    serviceCategoryName: '',
    serviceCategoryId: null,
    serviceName: '',
    serviceRequests: '',
    priceTagId: null,
  },

  desiredDate: '',
  scheduledDate: '',

  freeParking: null,
  parkingMethod: '',
  liveIn: null,
  hasPassword: null,
  frontPwd: '',
  doorPwd: '',
  communicationMethod: null,

  requests: '',
  requestImages: [],
}
export type MaterialPriceType = {
  id: number
  name: string
  price: number
  image: string
  imageUrl: string
  count: number | null
  maxQuantity: number
}
export type ApiPriceOptions = {
  id: number
  priceTagHeader: PriceTagHeaderType[]
  serviceOption: OptionAndPrice[]
  material: MaterialPriceType[]
  quantity: {
    name: string
  } | null
}
