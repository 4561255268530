import styled from '@emotion/styled/macro'
import { notFoundMiniTable } from 'themes/Shared.styles'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const SettlementForPartnerDetailsStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  CalendarHeader: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 20px;
    h1 {
      ${typography['Headline/Bold']}
      color: ${colors['Grayscale/Black']};
      white-space: nowrap;
    }
    img {
      width: 14px;
      height: 14px;
    }
  `,
  MiniTableWrap: styled.div`
    border: 1px solid #dadfe8;
    border-radius: 16px;
    width: 100%;
    overflow-y: auto;
  `,
  MiniTable: styled.table`
    width: 100%;
    border-collapse: collapse;
    thead {
      border-radius: 16px 16px 0px 0px;
      background-color: ${colors['Grayscale/Background Dark']};
    }
    th {
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Dark']};
      text-align: left;
      & > div {
        padding: 8px 12px;
      }
    }
    tbody {
      tr.selected {
        background-color: ${colors['Primary/Lighter']};
      }
      tr:hover {
        td {
          cursor: pointer;
          background-color: ${colors['Grayscale/Background Light']};
        }
      }
    }
    td {
      text-align: left;
      ${typography['Body/Small/Regular']}
      color: ${colors['Grayscale/Black light']};
      border-top: 1px solid ${colors['Grayscale/Gray Lighter']};
      & > div {
        padding: 12px;
      }
    }
    th,
    td {
      &:first-of-type {
        & > div {
          margin-left: 8px;
        }
      }
      &:last-of-type {
        & > div {
          margin-right: 8px;
        }
      }
    }
    tr {
      &:last-of-type {
        td {
          border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
        }
      }
    }
    ${notFoundMiniTable}
    ${addCssProps}
  `,
}

export default SettlementForPartnerDetailsStyle
