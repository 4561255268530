import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const ManagerPartStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  WorkerName: styled.div`
    padding: 4px 12px;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    ${typography['Body/Caption/Regular']}
    color: ${colors['Grayscale/Gray Dark']};
    box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Default']};
  `,
}

export default ManagerPartStyle
