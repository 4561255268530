import { MutableRefObject, useRef } from 'react'
import S from './TechsNameInDayPart.styles'
import { TechsNameInDayPartProps } from './TechsNameInDayPart.types'

const TechsNameInDayPart = <T extends { name: string }>({
  _css,
  _techs,
  ...props
}: TechsNameInDayPartProps<T>) => {
  const targetRef = useRef() as MutableRefObject<HTMLDivElement>

  return (
    <div className="techs">
      <S.Techs ref={targetRef} _css={_css} {...props}>
        {_techs
          .map((tech) => tech.name)
          .slice(0, 4)
          .join(',')}
      </S.Techs>
      {_techs.length > 4 && (
        <S.MoreCount>+{_techs.slice(4, _techs.length).length}</S.MoreCount>
      )}
    </div>
  )
}
export default TechsNameInDayPart
