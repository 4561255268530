import { BADGE_COLOR } from 'components/Badge/Badge'

export const NOTICE_TYPE_DROPDOWN_LIST = {
  전체: 'all',
  일반회원: 'normal',
  정기회원: 'regular',
} as const
export type NoticeKeyType = KeyOf<typeof NOTICE_TYPE_DROPDOWN_LIST>
export type NoticeValueType = ValueOf<typeof NOTICE_TYPE_DROPDOWN_LIST>

export const NOTICE_BADGE_COLOR_SET = {
  전체: BADGE_COLOR['PrimaryLight'],
  일반회원: BADGE_COLOR['AccentLight'],
  정기회원: BADGE_COLOR['SuccessLight'],
} as const

export const NOTICE_QUERY_DROPDOWN_LIST = {
  제목: 'title',
  작성자명: 'issuer',
} as const

export const isNoticeType = (
  value: any
): value is typeof NOTICE_TYPE_DROPDOWN_LIST[NoticeKeyType] => {
  return (
    value !== null &&
    Object.values(NOTICE_TYPE_DROPDOWN_LIST).some((item) => item === value)
  )
}

export type NoticeFilterListType = {
  targetUserType: typeof NOTICE_TYPE_DROPDOWN_LIST[NoticeKeyType] | ''
}
export const initialNoticeFilterValues: NoticeFilterListType = {
  targetUserType: '',
}
export const NOTICE_FILTER_DROPDOWN_LIST = {
  전체: 'all',
  '회원 지정': 'user-select',
  '코드 발급': 'code-issue',
} as const

// API

export interface APINoticeListRow {
  id: number
  targetUserType: 'all' | 'normal' | 'regular'
  title: string
  createdAt: string
  issuer: string
  link: string | null
  isImportant: boolean
}
