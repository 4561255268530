import styled from '@emotion/styled/macro'
import { notFoundMiniTable } from 'themes/Shared.styles'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const SettlementServiceHistoryTableStyle = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    ${addCssProps}
  `,
  Counts: styled.div`
    ${typography['Body/Caption/Regular']}
    color: ${colors['Grayscale/Gray Dark']};
    .bold {
      ${typography['Body/Caption/Bold']}
    }
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
  `,
  Divider: styled.div`
    height: 10px;
    width: 1px;
    background-color: ${colors['Grayscale/Gray Lighter']};
  `,
  MiniTableWrap: styled.div`
    border: 1px solid #dadfe8;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: auto;
  `,
  MiniTable: styled.table`
    width: 100%;
    border-collapse: collapse;
    thead {
      border-radius: 16px 16px 0px 0px;
      background-color: ${colors['Grayscale/Background Dark']};
    }
    th {
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Dark']};
      text-align: left;
      & > div {
        padding: 8px 12px;
      }
    }
    tbody {
      tr:hover {
        td {
          cursor: pointer;
          background-color: ${colors['Grayscale/Background Light']};
        }
      }
    }
    td {
      text-align: left;
      ${typography['Body/Small/Regular']}
      color: ${colors['Grayscale/Black light']};
      border-top: 1px solid ${colors['Grayscale/Gray Lighter']};
      & > div {
        padding: 12px;
      }
    }
    th,
    td {
      &:first-of-type {
        & > div {
          margin-left: 8px;
        }
      }
      &:last-of-type {
        & > div {
          margin-right: 8px;
        }
        /* text-align: right; */
      }
    }
    tbody {
      tr {
        &:last-of-type {
          td {
            border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
          }
        }
      }
    }
    ${notFoundMiniTable}
  `,
}

export default SettlementServiceHistoryTableStyle
