import { ImagesGlobalSafeGuard } from 'assets'
import crypto from 'crypto-js'
import { useAppDispatch } from 'hooks/reduxHooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { setStep, setUid, setUsername } from 'store/signSlice'
import SA from '../Authorization.styles'
import LogoPart from '../LogoPart'
import S from './Certification.styles'
import { CertificationProps } from './Certification.types'
import Iamport from './iamport-types/Iamport'

const isIamPort = (value: any): value is Iamport => {
  return value !== undefined && typeof value.init === 'function'
}
type FakeStatus = 'exist' | 'none'
type FakeUserInfo = {
  username: string
}
interface FakeResponse {
  status: FakeStatus
  info?: FakeUserInfo | null
}
const fakeCheck = (uid: string): Promise<FakeResponse> => {
  // TODO :: remove
  return new Promise((res) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let exist: FakeResponse = {
      status: 'exist',
      info: {
        username: 'doubled9',
      },
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let none: FakeResponse = {
      status: 'none',
      info: null,
    }
    setTimeout(() => {
      res(none)
    }, 1000)
  })
}
let test = true
const Certification = ({ _css, ...props }: CertificationProps) => {
  const [searchParams, set_searchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const checkUserType = async (uid: string) => {
      // TODO :: api
      return await fakeCheck(uid)
    }
    const passInit = async () => {
      // TODO :: remove test production
      if (test) {
        const res = await checkUserType(`imp_test`)
        if (res.status === 'none') {
          const uid = crypto.AES.encrypt(
            `imp_test`,
            process.env.REACT_APP_SALT ?? 'doubled9'
          ).toString()
          dispatch(setUid(uid))
          dispatch(setStep('join'))
        } else if (res.status === 'exist' && res.info) {
          const uid = crypto.AES.encrypt(
            `imp_test`,
            process.env.REACT_APP_SALT ?? 'doubled9'
          ).toString()
          dispatch(setUsername(res.info.username))
          dispatch(setUid(uid))
          dispatch(setStep('find'))
        }
        return
      }
      const { IMP } = window
      const iamportCode = `imp19707140`
      if (isIamPort(IMP)) {
        IMP.init(iamportCode)
        IMP.certification({ popup: false }, async (response) => {
          if (response.success) {
            const res = await checkUserType(response.imp_uid)
            if (res.status === 'none') {
              const uid = crypto.AES.encrypt(
                response.imp_uid,
                process.env.REACT_APP_SALT ?? 'doubled9'
              ).toString()
              dispatch(setUid(uid))
            } else if (res.status === 'exist' && res.info) {
              dispatch(setUsername(res.info.username))
            } else {
              window.location.hash = '/'
            }
          } else {
            window.location.hash = '/'
          }
        })
      } else {
        window.location.hash = '/'
        return
      }
    }
    passInit()
  }, [dispatch, searchParams, set_searchParams])

  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <LogoPart />
        <S.PendingPart>
          <img src={ImagesGlobalSafeGuard} alt="Safe Guard" />
          <div>
            <h2>
              안전한 서비스 이용을 위해
              <br />
              본인인증을 진행합니다.
            </h2>
            <p>잠시만 기다려주세요.</p>
          </div>
        </S.PendingPart>
        <SA.Dim />
      </S.Wrap>
    </>
  )
}
export default Certification
