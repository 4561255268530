import DatePicker from 'components/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import S from './PeriodPicker.styles'
import { PeriodPickerProps } from './PeriodPicker.types'

const PeriodPicker = ({
  _css,
  _divider,
  _period,
  _emitValue,
  _placeholder,
  ...props
}: PeriodPickerProps) => {
  const handlePeriod = (target: 'start' | 'end', value: Dayjs) => {
    let nextPeriod = { ..._period, [target]: value }
    _emitValue(nextPeriod)
  }
  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <DatePicker
          className="start-date"
          _value={
            _period.start && dayjs.isDayjs(_period.start) ? _period.start : null
          }
          _emitValue={(value) => {
            handlePeriod('start', value.startOf('day'))
          }}
          _comparisonDate={{
            type: 'before',
            date: _period.end,
          }}
          placeholder={
            _placeholder
              ? _placeholder.left
              : dayjs().format('YYYY년 MM월 DD일')
          }
        />
        {_divider}
        <DatePicker
          className="end-date"
          _value={
            _period.end && dayjs.isDayjs(_period.end) ? _period.end : null
          }
          _emitValue={(value) => {
            handlePeriod('end', value.endOf('day'))
          }}
          _comparisonDate={{
            type: 'after',
            date: _period.start,
          }}
          placeholder={
            _placeholder
              ? _placeholder.right
              : dayjs().format('YYYY년 MM월 DD일')
          }
        />
      </S.Wrap>
    </>
  )
}
export default PeriodPicker
