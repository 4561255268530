import { useAppDispatch, useShallowSelector } from 'hooks/reduxHooks'
import { useCallback, useEffect } from 'react'
import {
  GalleryStatesType,
  cleanGallery,
  getCurrentGallery,
  setGallery,
  updateCurrentGalleryAndClean,
  updatedGallery,
} from 'store/gallerySlice'

export const useGallery = (
  callback?: ((updatedItems: GalleryStatesType) => void) | boolean
) => {
  const currentGallery = useShallowSelector(getCurrentGallery)
  const resultGallery = useShallowSelector(updatedGallery)
  const dispatch = useAppDispatch()

  const memorizedCallback = useCallback(
    async (updatedItems: GalleryStatesType) => {
      if (typeof callback === 'function') {
        callback(updatedItems)
        dispatch(cleanGallery())
      } else if (typeof callback === 'boolean' && callback) {
        dispatch(updateCurrentGalleryAndClean(updatedItems))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  useEffect(() => {
    if (resultGallery) {
      memorizedCallback(resultGallery)
    }
  }, [resultGallery, memorizedCallback])

  const openGallery = useCallback(
    (imageData: GalleryStatesType) => {
      dispatch(setGallery(imageData))
    },
    [dispatch]
  )

  return {
    currentGallery,
    resultGallery,
    openGallery,
    dispatch,
  }
}
