import { css } from '@emotion/react'
import {
  ImagesMiniCalendarChevronLeft,
  ImagesMiniCalendarChevronRight,
} from 'assets'
import classNames from 'classnames'
import { GrayScaleOutline } from 'components/Buttons'
import dayjs, { Dayjs } from 'dayjs'
import useFetch from 'hooks/useFetch'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { FlexRow } from 'themes/Shared.styles'
import generateCalendar, { GeneratedType } from 'utils/getCalendar'
import { cn } from 'utils/helpers'
import { TechsType } from '../WorkerAssignmentTab/AssignTeamPopup/AssignTeamPopup.types'
import S from './CalendarInPopup.styles'
import { CalendarInPopupProps } from './CalendarInPopup.types'
import SchedulesInPopup from './SchedulesInPopup'
import TechsNameInDayPart from './TechsNameInDayPart'

const CalendarInPopup = ({
  _css,
  _team,
  _DayContents: DayContents,
  ...props
}: CalendarInPopupProps) => {
  const [currentCalendar, set_currentCalendar] = useState<GeneratedType[][]>()
  const [currentMonth, set_currentMonth] = useState<Dayjs>(dayjs())
  const [openSelectedValue, set_openSelectedValue] = useState<{
    selected: Dayjs
    top: number
    left: number
    type: TechsType
  } | null>(null)

  const [holidays] = useFetch<{ date: string; holiday: string }[]>(
    'order',
    `/calendar/holiday?yyyymm=${currentMonth.format('YYYYMM')}`
  )

  const [response] = useFetch<{ day: string; nameList: string[] }[]>(
    'task',
    `/schedule/month/worker?yyyymm=${currentMonth.format(
      'YYYYMM'
    )}&team=${_team}`,
    {
      disabled: !currentMonth || !_team,
    }
  )

  useEffect(() => {
    const calendar = generateCalendar(currentMonth, openSelectedValue?.selected)
    set_currentCalendar(calendar)
  }, [currentMonth, openSelectedValue])

  const calendarRef = useRef() as MutableRefObject<HTMLDivElement>

  return (
    <>
      <SchedulesInPopup
        _value={openSelectedValue}
        _onClose={() => set_openSelectedValue(null)}
      />
      <S.CalendarWrap
        className="popup-calendar"
        _css={_css}
        ref={calendarRef}
        {...props}
      >
        <S.CalendarHeader>
          <FlexRow gap={8}>
            <h1>{currentMonth.format('YYYY년 MM월')}</h1>
            <FlexRow gap={6}>
              <GrayScaleOutline
                _css={css`
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                `}
                onClick={() => {
                  set_currentMonth((prev) => prev.subtract(1, 'month'))
                }}
              >
                <img src={ImagesMiniCalendarChevronLeft} alt="prev" />
              </GrayScaleOutline>
              <GrayScaleOutline
                _css={css`
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                `}
                onClick={() => {
                  set_currentMonth((prev) => prev.add(1, 'month'))
                }}
              >
                <img src={ImagesMiniCalendarChevronRight} alt="next" />
              </GrayScaleOutline>
            </FlexRow>
          </FlexRow>
          <div></div>
        </S.CalendarHeader>
        <S.CalendarDays className={cn('calendar-body')}>
          <S.WeeksRow className="week">
            <S.Weeks>일</S.Weeks>
            <S.Weeks>월</S.Weeks>
            <S.Weeks>화</S.Weeks>
            <S.Weeks>수</S.Weeks>
            <S.Weeks>목</S.Weeks>
            <S.Weeks>금</S.Weeks>
            <S.Weeks>토</S.Weeks>
          </S.WeeksRow>
          {currentCalendar &&
            currentCalendar.map((week, index) => (
              <S.CalendarRow key={'week' + index}>
                {week.map((day, _index) => {
                  const {
                    isGrayed,
                    isSelected,
                    isToday,
                    endBetween,
                    startBetween,
                  } = day
                  const dayOff = holidays?.find(
                    (item) => item.date === day.current.format('YYYY-MM-DD')
                  )
                  const techs = response?.find(
                    (item) => item.day === day.current.format('D')
                  )
                  return (
                    <S.DayColumn
                      key={'day' + _index}
                      className={classNames(
                        {
                          isGrayed,
                          isSelected,
                          isToday,
                          endBetween,
                          startBetween,
                        },
                        'day-column'
                      )}
                      onClick={() => {
                        if (!_team) return
                        if (calendarRef.current) {
                          const rect =
                            calendarRef.current.getBoundingClientRect()
                          set_openSelectedValue({
                            selected: day.current,
                            top: (rect.height - 470) / 2,
                            left: (rect.width - 264) / 2,
                            type: _team,
                          })
                        }
                      }}
                    >
                      <S.DayWrap className="day-wrap">
                        <S.DayHeader>
                          <S.DayPart
                            className={cn({ holiday: !!dayOff }, 'day-part')}
                            data-date={day.current.format('YYYY-MM-DD')}
                          >
                            {day.current.format('D')}
                          </S.DayPart>
                          {dayOff && <S.Holidays>{dayOff.holiday}</S.Holidays>}
                        </S.DayHeader>
                        {techs && techs.nameList?.length > 0 ? (
                          <TechsNameInDayPart
                            _techs={techs.nameList.map((item) => ({
                              name: item,
                            }))}
                          />
                        ) : (
                          <></>
                        )}
                        {DayContents ? <DayContents day={day} /> : <></>}
                      </S.DayWrap>
                    </S.DayColumn>
                  )
                })}
              </S.CalendarRow>
            ))}
        </S.CalendarDays>
      </S.CalendarWrap>
    </>
  )
}
export default CalendarInPopup
