import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const HolidaysManagementStyle = {
  Wrap: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    & > div:last-of-type {
      flex: 1;
      overflow-y: auto;
    }
    ${addCssProps}
  `,
  CalendarHeader: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 20px;
    h1 {
      ${typography['Headline/Bold']}
      color: ${colors['Grayscale/Black']};
      white-space: nowrap;
    }
    img {
      width: 14px;
      height: 14px;
    }
  `,
  MiniTableWrap: styled.div`
    border: 1px solid #dadfe8;
    border-radius: 16px;
    width: 100%;
    overflow: hidden;
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        border-radius: 16px 16px 0px 0px;
        background-color: ${colors['Grayscale/Background Dark']};
      }
      th {
        ${typography['Body/Small/Bold']}
        color: ${colors['Grayscale/Gray Dark']};
        text-align: left;
        border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
        & > div {
          padding: 0 12px;
          display: flex;
          align-items: center;
          height: 48px;
        }
      }
      tbody {
        tr.selected {
          background-color: ${colors['Primary/Lighter']};
        }
        td.selected {
          background-color: ${colors['Primary/Lighter']};
        }
        tr:hover {
          td {
            cursor: pointer;
            background-color: ${colors['Grayscale/Background Light']};
          }
        }
      }
      td {
        text-align: left;
        ${typography['Body/Small/Regular']}
        color: ${colors['Grayscale/Black light']};
        border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
        & > div {
          height: 64px;
          display: flex;
          align-items: center;
          padding: 0 12px;
        }
      }
      th {
        &:first-of-type {
          width: 104px;
          max-width: 104px;
          & > div {
            margin-left: 52px;
            width: 104px;
            max-width: 104px;
          }
        }
        &:last-of-type {
          & > div {
            margin-right: 8px;
          }
          /* text-align: right; */
        }
      }
      td {
        &:first-of-type {
          width: 182px;
          max-width: 182px;
          & > div {
            margin-left: 8px;
            width: 182px;
            max-width: 182px;
          }
        }
        &:last-of-type {
          & > div {
            margin-right: 8px;
          }
          /* text-align: right; */
        }
      }
    }
  `,
}

export default HolidaysManagementStyle
