import {
  APIGetTechsVacation,
  HolidaysManagementProps,
  TechsVacation,
} from './HolidaysManagement.types'

import { css } from '@emotion/react'
import {
  ImagesMiniCalendarChevronLeft,
  ImagesMiniCalendarChevronRight,
} from 'assets'
import { GrayScaleOutline, PrimaryOutlineButton } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound'
import NotFoundItem from 'components/NotFoundItem'
import dayjs, { Dayjs } from 'dayjs'
import useFetch from 'hooks/useFetch'
import { uniqBy } from 'lodash-es'
import { useEffect, useState } from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import { FlexRow } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import { cn } from 'utils/helpers'
import AddHoliday from './AddHoliday'
import S from './HolidaysManagement.styles'

const TH = ({ children, ...props }: { children?: React.ReactNode }) => (
  <th>
    <div>{children}</div>
  </th>
)
interface DefaultTD
  extends React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  > {
  children?: React.ReactNode
}
const TD = ({ children, ...props }: DefaultTD) => (
  <td {...props}>
    <div>{children ? children : '-'}</div>
  </td>
)
const HolidaysManagement = ({
  _css,
  _technicianId,
  _disabled,
  ...props
}: HolidaysManagementProps) => {
  const [currentYearMonth, set_currentYearMonth] = useState<Dayjs>(dayjs())
  const [holidayList, set_holidayList] = useState<TechsVacation[]>([])
  const [selectedHolidays, set_selectedHolidays] = useState<TechsVacation[]>([])
  const [openAddHoliday, set_openAddHoliday] = useState<boolean>(false)
  // 휴무일 fetch
  const [response] = useFetch<APIGetTechsVacation>(
    'user',
    `/technician/${_technicianId}/vacation/list?yyyymm=${currentYearMonth.format(
      'YYYYMM'
    )}`,
    {
      disabled: !_technicianId || currentYearMonth.format() === 'Invalid Date',
    }
  )
  // 휴무일 토글 Handler
  const toggleHolidays = (techsVacation: TechsVacation) => {
    if (
      selectedHolidays.some((item) => item.yyyymmdd === techsVacation.yyyymmdd)
    ) {
      set_selectedHolidays((prev) =>
        prev.filter((item) => item.yyyymmdd !== techsVacation.yyyymmdd)
      )
    } else {
      set_selectedHolidays((prev) => [...prev, techsVacation])
    }
  }
  // api로 부터 받은 휴무일 데이터 set
  useEffect(() => {
    if (response) {
      const parseData: TechsVacation[] = response.map((item) => ({
        yyyymmdd: item.yyyymmdd,
        reason: item.reason ?? undefined,
        deleted: false,
      }))
      set_holidayList(parseData)
    }
  }, [response])

  return (
    <>
      {openAddHoliday && (
        <AddHoliday
          _onClose={() => {
            set_openAddHoliday(false)
          }}
          _emitValue={(day, reason) => {
            const newHoliday = {
              yyyymmdd: Number(day.format('YYYYMMDD')),
              reason: reason,
              deleted: false,
            }
            const isCurrentMonth = day.isSame(currentYearMonth, 'M')
            // 프론트 화면에 추가하기 위함
            if (isCurrentMonth) {
              const newHolidayList = [...holidayList, newHoliday]
              const newHolidayNoDuplication = uniqBy(newHolidayList, 'yyyymmdd')
              set_holidayList(newHolidayNoDuplication)
            }
            updateVacation(_technicianId, [newHoliday])
            set_openAddHoliday(false)
          }}
        />
      )}
      <S.Wrap>
        <S.CalendarHeader>
          <FlexRow gap={8}>
            <h1>{currentYearMonth.format('YYYY년 MM월')}</h1>
            <FlexRow gap={6}>
              <GrayScaleOutline
                _css={css`
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                `}
                onClick={() => {
                  set_currentYearMonth((prev) => prev.subtract(1, 'month'))
                  set_selectedHolidays([])
                }}
              >
                <img src={ImagesMiniCalendarChevronLeft} alt="prev" />
              </GrayScaleOutline>
              <GrayScaleOutline
                _css={css`
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                `}
                onClick={() => {
                  set_currentYearMonth((prev) => prev.add(1, 'month'))
                  set_selectedHolidays([])
                }}
              >
                <img src={ImagesMiniCalendarChevronRight} alt="next" />
              </GrayScaleOutline>
            </FlexRow>
          </FlexRow>
          <FlexRow gap={12} justifyContent="flex-end">
            <PrimaryOutlineButton
              disabled={_disabled}
              _mini
              _css={css`
                padding: 5px 12px;
                white-space: nowrap;
              `}
              onClick={() => {
                set_openAddHoliday(true)
              }}
            >
              추가
            </PrimaryOutlineButton>
            <GrayScaleOutline
              _mini
              _css={css`
                padding: 5px 12px;
                white-space: nowrap;
              `}
              disabled={selectedHolidays.length === 0 || _disabled}
              onClick={() => {
                const deletedDates = selectedHolidays.map((item) => ({
                  ...item,
                  deleted: true,
                }))
                const newHolidayList = holidayList.filter(
                  (item) =>
                    !deletedDates.some(
                      (deletedDate) => deletedDate.yyyymmdd === item.yyyymmdd
                    )
                )
                set_holidayList(newHolidayList)
                updateVacation(_technicianId, deletedDates)
                set_selectedHolidays([])
              }}
            >
              삭제
            </GrayScaleOutline>
          </FlexRow>
        </S.CalendarHeader>
        <S.MiniTableWrap>
          {holidayList.length === 0 ? (
            <NotFoundItem
              _css={css`
                height: 100%;
                border: none;
                box-shadow: none;
                & > div {
                  box-shadow: none;
                  height: 100%;
                  border: none;
                }
              `}
            >
              등록된 휴무일이 없습니다.
            </NotFoundItem>
          ) : (
            <TableVirtuoso
              fixedHeaderContent={() => {
                return (
                  <tr>
                    <TH>날짜</TH>
                    <TH>사유</TH>
                  </tr>
                )
              }}
              style={{ height: '100%', width: '100%' }}
              totalCount={holidayList.length}
              itemContent={(index) => {
                const row = holidayList[index]
                const selectedClassName = {
                  className: cn({
                    selected: selectedHolidays.includes(row),
                  }),
                }
                return (
                  <>
                    <TD
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleHolidays(row)
                      }}
                      {...selectedClassName}
                    >
                      <CheckBoxRound
                        _css={css`
                          width: 100%;
                        `}
                        _mini
                        _active={selectedHolidays.includes(row)}
                        onClick={(e) => {
                          e.stopPropagation()
                          toggleHolidays(row)
                        }}
                      >
                        {row.yyyymmdd}
                      </CheckBoxRound>
                    </TD>
                    <TD
                      {...selectedClassName}
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleHolidays(row)
                      }}
                    >
                      {row.reason}
                    </TD>
                  </>
                )
              }}
            />
          )}
        </S.MiniTableWrap>
      </S.Wrap>
    </>
  )
}

const updateVacation = async (
  id: string | undefined,
  newHolidayList: TechsVacation[]
) => {
  if (!id) return
  try {
    await callAxios('user').put(`/technician/${id}/vacation`, {
      list: newHolidayList,
    })
  } catch (e) {
    handleError(e)
  }
}

export default HolidaysManagement
