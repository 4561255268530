import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const CancelWorkStyle = {
  Wrap: styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    ${addCssProps}
  `,
  AmountWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    ${typography['Body/Caption/Regular']}
    color: ${colors['Grayscale/Gray Dark']};
    span {
      ${typography['Body/Caption/Bold']}
    }
  `,
  AmountDivider: styled.div`
    width: 1px;
    height: 10px;
    background-color: ${colors['Grayscale/Gray Lighter']};
  `,
}

export default CancelWorkStyle
