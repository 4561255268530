import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import DraggablePopup from 'components/DraggablePopup'
import PopupInputLayout from 'components/PopupInputLayout'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useMatchInput from 'hooks/useMatchInput'
import { useEffect } from 'react'
import { FlexRow } from 'themes/Shared.styles'
import S from './MaterialFee.styles'
import { EXTRA_FEE_INITIAL_VALUE, MaterialFeeProps } from './MaterialFee.types'

const MaterialFee = ({
  _css,
  _open,
  _id,
  _data,
  _onClose,
  _emitValue,
  ...props
}: MaterialFeeProps) => {
  const { inputs, handleInput, handleValues } = useMatchInput(
    EXTRA_FEE_INITIAL_VALUE
  )
  const trueWhenEmpty = useEmptyCheck(inputs, 'id')
  useEffect(() => {
    if (_data) handleValues(_data)
  }, [_data, handleValues])
  return (
    <>
      <DraggablePopup
        _title={'자재 정보 입력'}
        _open={true}
        _onClose={_onClose}
        _bottom={
          <>
            <GrayScaleFill onClick={_onClose}>취소</GrayScaleFill>
            <PrimaryButton
              disabled={trueWhenEmpty}
              onClick={() => {
                _emitValue({ ...inputs })
              }}
            >
              확인
            </PrimaryButton>
          </>
        }
      >
        <S.Wrap _css={_css} {...props}>
          <PopupInputLayout _title="자재 내역" _required={true} _gap={4}>
            <UnderlineInput
              value={inputs.name}
              placeholder="자재 이름 입력"
              handleValue={(value) => handleInput('name', value)}
            />
          </PopupInputLayout>
          <FlexRow gap={24}>
            <PopupInputLayout _title="개수" _required={true} _gap={4}>
              <UnderlineNumberInput
                value={inputs.count ? Number(inputs.count) : null}
                suffix={'개'}
                placeholder="개수 입력"
                handleValue={(value) => handleInput('count', value ?? 1)}
              />
            </PopupInputLayout>
          </FlexRow>
        </S.Wrap>
      </DraggablePopup>
    </>
  )
}
export default MaterialFee
