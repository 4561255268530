import { css } from '@emotion/react'
import { GrayScaleOutline, PrimaryButton } from 'components/Buttons'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import { CancelServiceButtonsProps } from './CancelServiceButtons.types'

const CancelServiceButtons = ({
  _css,
  _selected: selected,
  _handleSelected: handleSelected,
  _onClose,
  ...props
}: CancelServiceButtonsProps) => {
  const { Layout: L } = S
  return (
    <L.RightBottom>
      <GrayScaleOutline
        _css={css`
          width: 160px;
          height: 48px;
        `}
        onClick={() => {
          _onClose()
        }}
      >
        취소
      </GrayScaleOutline>
      <PrimaryButton
        _css={css`
          flex: 1;
          height: 48px;
        `}
        disabled={
          selected.selectIds.length < 1 && selected.rejectIds.length < 1
        }
        onClick={() => {
          handleSelected({
            ...selected,
            deleteIds: [...selected.selectIds, ...selected.rejectIds],
          })
        }}
      >
        확인
      </PrimaryButton>
    </L.RightBottom>
  )
}
export default CancelServiceButtons
