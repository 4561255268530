import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { PrimaryOutlineButton } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound/CheckBoxRound'
import RadioButton from 'components/RadioButton'
import Textarea from 'components/Textarea'
import UnderlineInput from 'components/UnderlineInput'
import UnderlineFormatInput, {
  getFormats,
} from 'components/UnderlineInput/UnderlineFormatInput'
import { MatchInputProps } from 'hooks/useMatchInput'
import { ContentPart } from 'pages/Main/components/ContentPart/ContentPart'
import { APIAdminCreateData } from 'pages/System/Admin/AdminPopUp/AdminPopUp.types'
import { PopUpContentPart } from 'pages/System/Admin/AdminPopUp/ContentInput/ContentInput'
import { useEffect, useState } from 'react'
import { FlexColumn, FlexRow, TXT } from 'themes/Shared.styles'
import { ADMIN_TYPE } from 'types/api/samples/adminWeb'
import { PWD_NOT_CHANGED } from 'types/share'
import { onInputNumber } from 'utils/helpers'
import S from './AdminPopUp.styles'

const PopUpContentInput = styled(UnderlineInput)`
  & > input {
    height: 44px;
  }
`

interface AddAdminFormProps extends MatchInputProps<APIAdminCreateData> {
  _openAuthoritySet: () => void
  _isEdit: boolean
}

const AddAdminForm = ({
  _openAuthoritySet,
  inputs,
  handleInput,
  _isEdit,
  ...props
}: AddAdminFormProps) => {
  const [isHidePwdInput, set_isHidePwdInput] = useState<boolean>(false)

  // _id 존재 시 (수정화면) 비밀번호 input 숨김 처리
  useEffect(() => {
    if (_isEdit) {
      set_isHidePwdInput(true)
    }
  }, [_isEdit])
  return (
    <S.ContentWrapper>
      <FlexColumn gap={32} className="padding-wrapper">
        <FlexRow
          gap={24}
          _css={css`
            width: 100%;
          `}
        >
          <PopUpContentPart _title="이름" _required>
            <PopUpContentInput
              placeholder="내용 입력"
              value={inputs.name}
              handleValue={(value) => {
                handleInput('name', value)
              }}
            />
          </PopUpContentPart>
          <PopUpContentPart _title="휴대폰 번호" _required>
            <UnderlineFormatInput
              format={getFormats('phone', inputs.phone)}
              placeholder="내용 입력"
              value={inputs.phone}
              handleValue={(value) => {
                const numberVal = onInputNumber(value, inputs.phone)
                handleInput('phone', numberVal)
              }}
            />
          </PopUpContentPart>
        </FlexRow>
        <FlexRow
          gap={24}
          _css={css`
            width: 100%;
          `}
        >
          <PopUpContentPart _title="아이디" _required>
            <PopUpContentInput
              placeholder="내용 입력"
              value={inputs.userName}
              handleValue={(value) => {
                handleInput('userName', value)
              }}
            />
          </PopUpContentPart>
          <PopUpContentPart _title="비밀번호" _required>
            {isHidePwdInput ? (
              <FlexRow
                gap={0}
                _css={css`
                  height: 45px;
                `}
              >
                <PrimaryOutlineButton
                  _css={css`
                    padding: 2px 12px;
                    border-radius: 8px;
                  `}
                  onClick={() => {
                    handleInput('pwd', '')
                    set_isHidePwdInput(false)
                  }}
                >
                  비밀번호 변경
                </PrimaryOutlineButton>
              </FlexRow>
            ) : (
              <FlexRow gap={12}>
                <PopUpContentInput
                  placeholder="8자 이상, 영문 + 숫자 + 특수문자 조합"
                  value={inputs.pwd}
                  _css={css`
                    width: 100%;
                  `}
                  handleValue={(value) => {
                    handleInput('pwd', value)
                  }}
                />
                {_isEdit && (
                  <TXT
                    _textStyle="Body/Small/Bold"
                    _color="Primary/Default"
                    onClick={() => {
                      handleInput('pwd', PWD_NOT_CHANGED)
                      set_isHidePwdInput(true)
                    }}
                    _css={css`
                      flex-shrink: 0;
                      cursor: pointer;
                    `}
                  >
                    취소
                  </TXT>
                )}
              </FlexRow>
            )}
          </PopUpContentPart>
        </FlexRow>

        <PopUpContentPart _title={'권한'} _required>
          <FlexColumn gap={19}>
            <FlexRow
              gap={10}
              _css={css`
                padding-top: 8px;
              `}
            >
              <RadioButton
                _mini
                _active={ADMIN_TYPE[inputs.role] === '최고 관리자'}
                onClick={() => {
                  handleInput('role', 'MASTER')
                }}
                _css={css`
                  width: 100%;
                `}
              >
                최고 관리자
              </RadioButton>
              <RadioButton
                _mini
                _active={ADMIN_TYPE[inputs.role] === '일반 관리자'}
                onClick={() => {
                  handleInput('role', 'ADMIN')
                }}
                _css={css`
                  width: 100%;
                `}
              >
                일반 관리자
              </RadioButton>
            </FlexRow>
            <PrimaryOutlineButton
              _css={css`
                width: 100%;
                border-radius: 8px;
                padding: 2px 12px;
              `}
              onClick={() => {
                _openAuthoritySet()
              }}
            >
              진입 권한 설정
            </PrimaryOutlineButton>
          </FlexColumn>
        </PopUpContentPart>

        <FlexRow
          gap={24}
          _css={css`
            width: 100%;
          `}
        >
          <PopUpContentPart _title="소속" _required>
            <PopUpContentInput
              placeholder="내용 입력"
              value={inputs.office}
              handleValue={(value) => {
                handleInput('office', value)
              }}
            />
          </PopUpContentPart>
          <PopUpContentPart _title="직급">
            <PopUpContentInput
              placeholder="내용 입력"
              value={inputs.position ?? ''}
              handleValue={(value) => {
                handleInput('position', value)
              }}
            />
          </PopUpContentPart>
        </FlexRow>

        <ContentPart
          _css={css`
            .title {
              margin-bottom: 4px;
            }
            .content {
              border: none;
            }
            .title {
              margin-bottom: 12px;
            }
            textarea {
              height: 100px;
            }
          `}
          _title={
            <FlexRow gap={0} justifyContent={'space-between'}>
              <TXT _textStyle="Body/Small/Bold" _color="Grayscale/Gray Default">
                {'담당업무'}
              </TXT>
              <CheckBoxRound
                _mini
                _css={css`
                  padding: 0px;
                `}
                _active={inputs.isMaterial}
                onClick={() => {
                  handleInput('isMaterial', (prev) => !prev.isMaterial)
                }}
              >
                자재 담당
              </CheckBoxRound>
            </FlexRow>
          }
        >
          <Textarea
            className="textarea"
            placeholder="메세지를 입력하세요"
            defaultValue={inputs.task ?? ''}
            onChange={(e) => {
              handleInput('task', e.target.value)
            }}
          />
        </ContentPart>
      </FlexColumn>
    </S.ContentWrapper>
  )
}
export default AddAdminForm
