import { css } from '@emotion/react'
import { IconsClip, IconsWhiteCancelCircle } from 'assets'
import Badge from 'components/Badge'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import DetailsDefault, { ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DC from 'components/DetailsComponents/DetailsComponents.styles'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import { APIGetEventDetailsData } from 'pages/Support/Event/EventDetails/EventDetails.types'
import { EVENT_BADGE_COLOR } from 'pages/Support/Event/EventList/EventList.types'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
  SvgWrap,
  TXT,
} from 'themes/Shared.styles'
import { colors, typography } from 'themes/styles'
import callAxios, { handleError } from 'utils/callAxios'
import { HandleEllipsisToolTip, isBetweenPeriod } from 'utils/helpers'
import { addHttpToLinkStr, extractFileNameFromUrl } from 'utils/parsers'
import S from './EventDetails.styles'

interface EventDetailsProps {
  _id: string
}

const EventDetails = ({ _id, ...props }: EventDetailsProps) => {
  const { Layout: L } = DC
  const [response, error] = useFetch<APIGetEventDetailsData>(
    'user',
    `/board/event/${_id}`,
    {
      disabled: !_id,
    }
  )
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [detailValues, set_detailValues] = useState<APIGetEventDetailsData>()
  const [imgSrc, set_imgSrc] = useState<string | null>(null)
  const [modalState, set_modalState] = useState<
    'eventEnd' | 'popUp' | 'thumbnail' | null
  >(null)
  const [periodState, set_periodState] = useState<
    keyof typeof EVENT_BADGE_COLOR | null
  >(null)

  // api : 이벤트 종료
  const handleDelete = async (id: string) => {
    try {
      await callAxios('user').patch(`/board/event/${id}`)
      set_modalState(null)
      dispatch(setToast({ message: '종료되었습니다.', type: 'error' }))
      navigate('/customer/event')
    } catch (e) {
      handleError(e)
    }
  }

  // 초기값 설정
  useEffect(() => {
    if (error) return
    if (response) {
      set_detailValues(response)
      set_periodState(
        isBetweenPeriod(dayjs(), response.startAt, response.endAt)
      )
    }
  }, [response, error])
  if (!detailValues) return <></>
  return (
    <>
      <Modal
        open={!!modalState}
        onClose={() => set_modalState(null)}
        _css={
          modalState === 'popUp' || modalState === 'thumbnail'
            ? css`
                .modal-wrap {
                  background: none;
                }
              `
            : ''
        }
      >
        {modalState === 'eventEnd' && (
          <MiniPopup
            _title="이벤트 종료"
            _buttonHandler={
              <>
                <GrayScaleFill onClick={() => set_modalState(null)}>
                  아니요
                </GrayScaleFill>
                <PrimaryButton
                  onClick={() => {
                    handleDelete(_id)
                  }}
                >
                  종료하기
                </PrimaryButton>
              </>
            }
          >
            {`해당 이벤트를 종료 하시겠습니까?`}
          </MiniPopup>
        )}
        {(modalState === 'popUp' || modalState === 'thumbnail') && (
          <S.ImagePopup>
            <SvgWrap
              onClick={() => set_modalState(null)}
              _css={css`
                cursor: pointer;
                position: absolute;
                top: -38px;
                right: -38px;
              `}
            >
              <IconsWhiteCancelCircle />
            </SvgWrap>
            <FlexColumn gap={16}>
              <S.ImageBox>
                <img src={imgSrc ?? ''} alt="banner" />
              </S.ImageBox>
            </FlexColumn>
            <TXT
              _css={css`
                position: absolute;
                bottom: -28px;
                left: 50%;
                transform: translateX(-50%);
                ${typography['Body/Large/Bold']};
                color: ${colors['White/White off']};
              `}
            >
              {modalState === 'popUp' ? '팝업 배너' : '썸네일 배너'}
            </TXT>
          </S.ImagePopup>
        )}
      </Modal>
      <DetailsDefault
        _title={'이벤트 상세'}
        _topLeftControls={
          <L.ButtonRow>
            {!detailValues?.finishAt && (
              <GrayScaleFill
                padding={'5px 12px'}
                _mini
                onClick={() => {
                  set_modalState('eventEnd')
                }}
              >
                이벤트 종료
              </GrayScaleFill>
            )}
            <ToList _to="/customer/event" />
          </L.ButtonRow>
        }
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout _title={'설정'}>
              <ContentRadiusBox>
                <BoxRowComponent
                  _label={'알림 제목'}
                  _contents={<div>{detailValues?.notiTitle}</div>}
                />
                <BoxRowComponent
                  _label={'알림 내용'}
                  _css={css`
                    margin-top: 12px;
                    align-items: flex-start;
                  `}
                  _contents={<div>{detailValues?.notiContent}</div>}
                />
                <BoxRowComponent
                  _label={'기간'}
                  _contents={
                    <TXT _color="Primary/Default">
                      {dayjs(detailValues?.startAt).format('YYYY-MM-DD(ddd)') +
                        ' ~ ' +
                        dayjs(detailValues?.endAt).format('YYYY-MM-DD(ddd)')}
                    </TXT>
                  }
                />
                <BoxRowComponent
                  _label={'사진'}
                  _css={css`
                    margin-top: 12px;
                    align-items: flex-start;
                  `}
                  _contents={
                    <FlexColumn gap={12} alignItems="flex-end">
                      <FlexRow
                        gap={8}
                        onMouseEnter={(e) =>
                          HandleEllipsisToolTip('mouseEnter', e)
                        }
                        _css={css`
                          width: fit-content;
                          max-width: fit-content;
                        `}
                      >
                        <TXT
                          _color="Primary/Default"
                          _css={css`
                            cursor: pointer;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                          `}
                          onClick={() => {
                            if (!!detailValues?.popupBanner) {
                              set_imgSrc(detailValues.popupBanner)
                              set_modalState('popUp')
                            }
                          }}
                        >
                          {detailValues
                            ? extractFileNameFromUrl(detailValues.popupBanner)
                            : ''}
                        </TXT>
                        <Badge
                          _css={css`
                            flex-shrink: 0;
                            ${typography['Body/Caption/Regular']};
                            border: 1px solid
                              ${colors['Grayscale/Gray Default']};
                          `}
                        >
                          팝업 배너
                        </Badge>
                      </FlexRow>
                      <FlexRow
                        gap={8}
                        _css={css`
                          width: fit-content;
                        `}
                      >
                        <TXT
                          _color="Primary/Default"
                          onClick={() => {
                            if (!!detailValues?.thumbnailBanner) {
                              set_imgSrc(detailValues.thumbnailBanner)
                              set_modalState('thumbnail')
                            }
                          }}
                          _css={css`
                            cursor: pointer;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                          `}
                        >
                          {detailValues
                            ? extractFileNameFromUrl(
                                detailValues.thumbnailBanner
                              )
                            : ''}
                        </TXT>
                        <Badge
                          _css={css`
                            flex-shrink: 0;
                            ${typography['Body/Caption/Regular']};
                            border: 1px solid
                              ${colors['Grayscale/Gray Default']};
                          `}
                        >
                          썸네일 배너
                        </Badge>
                      </FlexRow>
                    </FlexColumn>
                  }
                />
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _topRightHeader={
          <S.RightTopHeaderWrap>
            <FlexRow gap={8}>
              <Badge
                _css={css`
                  width: 52px;
                `}
                {...(periodState && EVENT_BADGE_COLOR[periodState])}
              >
                {periodState}
              </Badge>
            </FlexRow>
            <div className="header-title">{detailValues?.title}</div>
            <FlexRow gap={8} className="header-info-wrap">
              <div>{detailValues?.createdAt}</div>
              <div className="vertical-bar" />
              <div>{detailValues?.issuer}</div>
            </FlexRow>
          </S.RightTopHeaderWrap>
        }
        _rightContents={
          <FlexRow gap={20} justifyContent="center">
            <IconsClip />
            <S.LinkText
              href={addHttpToLinkStr(detailValues?.link)}
              target="_blank"
            >
              {detailValues?.link}
            </S.LinkText>
          </FlexRow>
        }
        _bottomRightControls={
          <S.RightBottom>
            <GrayScaleOutline
              disabled={!!detailValues?.finishAt}
              _css={css`
                width: 100%;
                height: 48px;
              `}
              onClick={() => navigate(`/customer/event/publish/${_id}`)}
            >
              수정
            </GrayScaleOutline>
          </S.RightBottom>
        }
      ></DetailsDefault>
    </>
  )
}
export default EventDetails
