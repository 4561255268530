export const SERVICE_KIND_TABS = [
  '메인 서비스 설정',
  '기타서비스 설정',
] as const
export const SERVICE_CUSTOMER_TYPE = ['일반', '정기'] as const

type ServiceTypeNamesType =
  | 'paint'
  | 'clean'
  | 'tile'
  | 'paint+clean'
  | 'floor'
  | 'etc'
export const parseForMainService = (value: string): ServiceTypeNamesType => {
  if (value.includes('도배') && value.includes('청소')) return 'paint+clean'
  if (value.includes('도배')) return 'paint'
  if (value.includes('청소')) return 'clean'
  if (value.includes('타일')) return 'tile'
  if (value.includes('바닥')) return 'floor'
  return 'etc'
}

export const serviceTypeList: Record<ServiceTypeNamesType, string> = {
  paint: '도배',
  clean: '청소',
  tile: '타일',
  'paint+clean': '청소+도배',
  floor: '바닥',
  etc: '기타 서비스',
}

export const defaultServiceList = [
  { id: 1, name: '청소', type: 'clean' },
  { id: 2, name: '도배', type: 'paint' },
  { id: 3, name: '타일', type: 'tile' },
  { id: 4, name: '바닥', type: 'floor' },
]
export const mainServiceList = {
  일반: defaultServiceList,
  정기: [
    ...defaultServiceList,
    { id: 5, name: '청소+도배', type: 'paint+clean' },
  ],
} as {
  [key in typeof SERVICE_CUSTOMER_TYPE[number]]: {
    id: number
    name: string
    type: ServiceTypeNamesType
  }[]
}
export type EtcServiceItemType = {
  id: number
  name: string
  icon: string
  data?: any
  inactive?: boolean
}
export type EtcServiceListType = {
  [key in typeof SERVICE_CUSTOMER_TYPE[number]]: EtcServiceItemType[]
}
export type ServiceTargetItemValuesType = {
  kind: typeof SERVICE_KIND_TABS[number]
  type: typeof SERVICE_CUSTOMER_TYPE[number]
  serviceId?: number
  serviceName: string
  serviceType: ServiceTypeNamesType
}
export type SelectedServiceItemType = {
  groupItem: ApiZoneGroupItem | null
  serviceItem: ServiceTargetItemValuesType | null
}
export type ValuesForServiceManagementType = {
  groupItem: ApiZoneGroupItem
  serviceItem: ServiceTargetItemValuesType
}
export type ServiceCategoryChainType = {
  id: number
  categoryChain: string[]
}

export type ApiZoneGroupItem = {
  id: number
  name: string
  createdAt: string
  updatedAt: string
  isEnable: boolean
  pivotZone: PivotZone[]
}

export type ApiCategoryList = {
  id: number
  name: string
  iconUrl: string
  isActive: boolean
  isMain: boolean
  isRegular: boolean
  createdAt: string
}
