import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '.'

interface ErrorMessageType {
  title: string | null
  errorMessage: string | null
}

interface ErrorState {
  message: ErrorMessageType | null
}

const initialState: ErrorState = {
  message: null,
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action: { payload: ErrorMessageType | string }) => {
      if (typeof action.payload === 'string') {
        state.message = {
          title: '알림',
          errorMessage: action.payload,
        }
      } else {
        state.message = action.payload
      }
    },
    cleanError: (state) => {
      state.message = null
    },
  },
})
export const { setError, cleanError } = errorSlice.actions

export const selectError = (state: RootState) => state.error.message

export default errorSlice.reducer
