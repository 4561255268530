import styled from '@emotion/styled/macro'
import Gallery from 'components/Gallery'
import ToastMessage from 'components/ToastMessage'
import UnAuthorizedPage from 'components/UnAuthorizedPage/UnAuthorizedPage'
import { useShallowSelector } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import usePageAuthority from 'hooks/usePageAuthorities'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { selectAuth, setUserInfo, UserInfoType } from 'store/authSlice'
import { restoreRefresh, selectRefresh } from 'store/configSlice'
import { selectGallery } from 'store/gallerySlice'
import { PageAuthority } from 'types/api/samples/adminWeb'
import LoadingPage from './LoadingPage'
import SideBar from './SideBar'
export const Contents = styled.div`
  margin-left: 322px;
  width: calc(100% - 322px);
  position: relative;
  min-height: 100%;
  @media print {
    width: 100%;
    margin-left: 0px;
  }
`
const Layout = () => {
  const refresh = useShallowSelector(selectRefresh)
  const gallery = useShallowSelector(selectGallery)
  const dispatch = useDispatch()
  useEffect(() => {
    if (refresh) dispatch(restoreRefresh())
  }, [refresh, dispatch])
  const userAuth = useShallowSelector(selectAuth)
  const [adminInfo] = useFetch<UserInfoType>('user', '/admin/me', {
    disabled: userAuth.status !== 'authorized',
  })
  const [abilities] = useFetch<PageAuthority[]>(
    'user',
    `/admin/${adminInfo?.id}/ability`,
    {
      disabled: !adminInfo?.id || userAuth.status !== 'authorized',
    }
  )
  useEffect(() => {
    if (!userAuth.userInfo && adminInfo && abilities) {
      dispatch(setUserInfo({ ...adminInfo, authority: abilities }))
    }
  }, [adminInfo, abilities, dispatch, userAuth.userInfo])

  const hasAuthForPage = usePageAuthority(
    ...(userAuth.userInfo?.authority ?? [])
  )
  if (!hasAuthForPage && !abilities) return <LoadingPage />
  return (
    <>
      {gallery && <Gallery _item={gallery} />}
      <ToastMessage />
      <SideBar />
      <Contents id="site-contents">
        {hasAuthForPage ? !refresh && <Outlet /> : <UnAuthorizedPage />}
      </Contents>
    </>
  )
}
export default Layout
