import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const OtherServiceItem = styled.div`
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
  flex-basis: calc(50% - 16px);
  transition: 0.15s;
`
const OtherServicesStyle = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;
    width: 100%;
    & > div {
      width: 100%;
    }
    ${addCssProps}
  `,
  OtherServiceRow: styled.div`
    display: flex;
    align-items: center;
    gap: 12px 24px;
    flex-flow: row wrap;
    flex-wrap: wrap;
    width: 100%;
    flex: 1;
    &.active {
      ${OtherServiceItem} {
        box-shadow: inset 0px 0px 0px 2px ${colors['Primary/Default']};
        background-color: ${colors['Primary/Lighter']};
      }
    }
  `,
  OtherServiceItem,
  SelectAbleContainer: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 24px;
  `,
  Title: styled.div`
    ${typography['Title/Bold']}
    color: ${colors['Grayscale/Black light']};
  `,
  Sub: styled.div`
    ${typography['Body/Caption/Bold']}
    color: ${colors['Grayscale/Gray Light']};
  `,
}

export default OtherServicesStyle
