import { css } from '@emotion/react'
import ContentsBox from 'components/ContentsBox'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import PeriodDropdownFilter from 'components/TableComponent/PeriodDropdownFilter'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  isTimeFilter,
} from 'components/TableComponent/PeriodDropdownFilter/PeriodDropdownFilter.types'
import TableCell from 'components/TableComponent/TableCell'
import T from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TwoInfoOneCell, {
  isNameAndPhone,
  nameAndPhoneProps,
} from 'components/TableComponent/TwoInfoOneCell/TwoInfoOneCell'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import { useRefresh } from 'hooks/useFetch'
import useSearchFilter from 'hooks/useSearchFilter'
import {
  APILogListData,
  LOG_QUERY_DROPDOWN_LIST,
} from 'pages/System/Log/LogList/LogList.type'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import {
  parserForPeriodQuery,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  valueCheck,
} from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import S from './LogList.styles'

// ** Table 관련 type
type LogListItemType = {
  id: string
  team: string
  nameAndPhone: nameAndPhoneProps
  adminId: string
  orderNumber: string
  title: string
  workHistory: string
  workDate: string
}
const LogListTableConfigs: TableCellConfig<LogListItemType>[] = [
  { _type: 'default', _id: 'team', _label: '소속', _size: 110 },
  { _type: 'default', _id: 'nameAndPhone', _label: '관리자 이름', _size: 120 },
  { _type: 'default', _id: 'adminId', _label: '관리자 아이디', _size: 150 },
  { _type: 'default', _id: 'orderNumber', _label: '주문번호', _size: 250 },
  { _type: 'default', _id: 'title', _label: '서비스명/제목', _size: 236 },
  { _type: 'default', _id: 'workHistory', _label: '활동 내역', _size: 140 },
  {
    _type: 'order',
    _id: 'workDate',
    _label: '활동일시',
    _size: 160,
    _order: 'ASC',
  },
]

// ** Parser : API response data -> table row data
const parserForLogList = (response: DoubleDListWithPaging<APILogListData>) => {
  const rebuild = response.list.map((row: APILogListData, index: number) => {
    return {
      id: String(row.id),
      team: valueCheck(row.office),
      nameAndPhone: {
        name: valueCheck(row.name),
        phone: valueCheck(row.phone, (value) =>
          parseToFormattedStr(value, 'phone')
        ),
      },
      adminId: valueCheck(row.userName),
      orderNumber: valueCheck(row.orderNumber),
      title: valueCheck(row.actionDetail),
      workHistory: valueCheck(row.actionName),
      workDate: valueCheck(row.createdAt, (value) =>
        dayjs(value).format('YYYY-MM-DD(dd) HH:mm')
      ),
    } as LogListItemType
  })
  return {
    _list: rebuild,
    _cursor: disposeCursor(response.paging.cursor),
  }
}
interface LogListProps {}
const LogList = (props: LogListProps) => {
  const topInfoRef = useRef() as MutableRefObject<HTMLDivElement>
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [tableHeaderOffset, set_tableHeaderOffset] = useState<number>(0)
  const [headerOffset, set_headerOffset] = useState<number>(0)
  const [refresh] = useRefresh()
  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: PERIOD_DROPDOWN_INITIAL_VALUE,
      queryList: LOG_QUERY_DROPDOWN_LIST,
    },
    (
      {
        startAt,
        endAt,
        month,
        year,
        periodType,
        searchType,
        searchValue,
        ...rest
      },
      queryName
    ) => {
      return {
        period: {
          start: startAt ? dayjs(startAt, 'YYYY-MM-DD') : null,
          end: endAt ? dayjs(endAt, 'YYYY-MM-DD') : null,
        },
        monthValue: month ?? '',
        yearValue: year ?? '',
        periodType: isTimeFilter(periodType) ? periodType : 'all',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    ({ queryName = 'search', terms = '', ...rest }) => {
      return removeUndefined({
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        ...parserForPeriodQuery(rest),
      })
    }
  )
  // 위치 고정 용
  useEffect(() => {
    if (topInfoRef.current && headerRef.current) {
      const headerSize =
        topInfoRef.current.getBoundingClientRect().height +
        headerRef.current.getBoundingClientRect().height
      set_headerOffset(topInfoRef.current.getBoundingClientRect().height)
      set_tableHeaderOffset(headerSize)
    }
  }, [])

  // query -> api url
  const apiURI = useMemo(() => {
    if (refresh) return ''
    const uri = `/admin-log?take=10${
      searchParams.toString() ? '&' + searchParams.toString() : ''
    }`
    return decodeURI(uri)
  }, [searchParams, refresh])
  return (
    <ContentsBox _type="relative" _css={css``}>
      <S.Wrapper>
        {/* 위치 고정 용 */}
        <TopInfo
          ref={topInfoRef}
          _css={css`
            & > div {
              background-color: #f6f6f6;
            }
          `}
        >
          <TopInfoRow
            _label={``}
            _contents={``}
            _css={css`
              visibility: hidden;
            `}
          />
        </TopInfo>
        <TableTitle
          ref={headerRef}
          _css={css`
            top: ${headerOffset}px;
          `}
          _left={'작업로그'}
        />
        <FilterPart<PeriodDropdownValuesType>
          _value={initialValues}
          _placeholder="주문번호 또는 제목 검색"
          _queryNameList={LOG_QUERY_DROPDOWN_LIST}
          _queryNameFormat={{ phone: 'phone' }}
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _FilterContents={({ _value, ...props }) => {
            return <PeriodDropdownFilter _value={_value} {...props} />
          }}
        />
        <RoundFillBox>
          <TableComponent<LogListItemType>
            _type="user"
            _topOffset={tableHeaderOffset}
            _config={LogListTableConfigs}
            _url={apiURI}
            _parser={parserForLogList}
            _ListContent={({ _row, _index }) => {
              // const rowId = _row.id ? String(_row.id) : String(_index)
              return (
                <T.Row>
                  {LogListTableConfigs.map((item, columnIndex) => {
                    // 각 column의 속성
                    const _id = item._id
                    // 해당 속성의 값
                    const _value = _row[item._id] as any
                    return (
                      <TableCell
                        key={'body' + _index + columnIndex}
                        _size={item._size}
                        _position="left"
                      >
                        {_id === 'nameAndPhone' && isNameAndPhone(_value) ? (
                          <TwoInfoOneCell
                            _topData={_value.name}
                            _bottomData={_value.phone}
                          />
                        ) : (
                          <div>{_value ? _value : '-'}</div>
                        )}
                      </TableCell>
                    )
                  })}
                </T.Row>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrapper>
    </ContentsBox>
  )
}

export default LogList
