import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { css } from '@emotion/react'
import { useDispatch } from 'react-redux'

import { IconsClip } from 'assets'
import { PrimaryButton, PrimaryOutlineButton } from 'components/Buttons'
import CheckBoxRound from 'components/CheckBoxRound'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DetailsDefault from 'components/DetailsComponents/DetailsComponents'
import DC from 'components/DetailsComponents/DetailsComponents.styles'
import Dropdown from 'components/Dropdown'
import QuillEditorComponent from 'components/QuillEditorComponent'
import Textarea from 'components/Textarea'
import UnderlineInput from 'components/UnderlineInput'
import { useControlQuillUrls } from 'hooks/useControlQuillUrls'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import { APINoticeDetailData } from 'pages/Support/Notice/NoticeDetails/NoticeDetails.types'
import { NOTICE_TYPE_DROPDOWN_LIST } from 'pages/Support/Notice/NoticeList/NoticeList.types'
import {
  APINoticePublishData,
  NOTICE_PUBLISH_INITIAL_VALUE,
  NULL_OF_QUILL,
  NoticeOption,
  noticePublishValueProps,
} from 'pages/Support/Notice/NoticePublish/NoticePublish.types'
import { setToast } from 'store/toastSlice'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import S from './NoticePublish.styles'

interface NoticePublishProps {}
const NoticePublish = (props: NoticePublishProps) => {
  const { id: noticeId } = useParams<'id'>()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [optionalAttr, set_optionalAttr] = useState<
    Array<keyof noticePublishValueProps>
  >(['notifyContent', 'notifyTitle', 'option', 'link', 'content', 'noticeId'])

  const { inputs, handleInput, fetchInit, isMatched } =
    useMatchInput<noticePublishValueProps>(NOTICE_PUBLISH_INITIAL_VALUE)
  const trueWhenEmpty = useEmptyCheck(inputs, ...optionalAttr)
  const [response, error] = useFetch<APINoticeDetailData>(
    'user',
    `/board/notice/${noticeId}`,
    {
      disabled: !noticeId,
    }
  )
  const { handleDeleteSharedImages } = useControlQuillUrls(inputs.content ?? '')
  // * 공지사항 글쓰기
  const publishNotice = useCallback(
    async (body: APINoticePublishData) => {
      try {
        await handleDeleteSharedImages()
        await callAxios('user').post('/board/notice', body)
        dispatch(
          setToast({
            message: '공지사항이 등록되었습니다.',
            type: 'success',
          })
        )
        navigate('/customer/notice')
      } catch (error) {
        handleError(error)
      }
    },
    [dispatch, navigate, handleDeleteSharedImages]
  )
  // * 공지사항 수정
  const editNotice = useCallback(
    async (body: APINoticePublishData) => {
      if (!noticeId) return
      try {
        await handleDeleteSharedImages()
        await callAxios('user').put(`/board/notice/${noticeId}`, body)
        dispatch(
          setToast({
            message: '공지사항이 수정되었습니다.',
            type: 'success',
          })
        )
        navigate(`/customer/notice/${noticeId}`)
      } catch (error) {
        handleError(error)
      }
    },
    [dispatch, navigate, noticeId, handleDeleteSharedImages]
  )

  // 공지사항 수정 시,  초기 데이터 세팅
  useEffect(() => {
    if (error) return
    if (response) {
      const parsedData = parseAPINoticeDetailDataToInput(response)
      fetchInit({ ...parsedData, noticeId })
    }
  }, [response, error, fetchInit, handleInput, noticeId])
  // * 공통
  // 필수 input 값 filled 체크
  useEffect(() => {
    const externalLinkOptional = (
      prev: (keyof noticePublishValueProps)[]
    ): (keyof noticePublishValueProps)[] => {
      return [...prev.filter((item) => item !== 'link'), 'content']
    }
    const normalOptional = (
      prev: (keyof noticePublishValueProps)[]
    ): (keyof noticePublishValueProps)[] => {
      return [...prev.filter((item) => item !== 'content'), 'link']
    }

    set_optionalAttr((prev) => {
      if (inputs.option === '외부링크') return externalLinkOptional(prev)
      return normalOptional(prev)
    })
  }, [inputs.option])

  if (noticeId && !inputs.noticeId) return <></>
  return (
    <>
      <DetailsDefault
        _css={css`
          .radius-box-gap {
            gap: 12px;
          }
        `}
        _title={'공지사항 글쓰기'}
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout _title={'설정'}>
              <ContentRadiusBox>
                <BoxRowComponent _label="공지 대상 *">
                  <Dropdown.Underline
                    _value={inputs.target}
                    _list={NOTICE_TYPE_DROPDOWN_LIST}
                    _emitValue={(value) => {
                      handleInput('target', value)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'알림 제목'}>
                  {!noticeId ? (
                    <UnderlineInput
                      readOnly={!!noticeId}
                      maxLength={40}
                      placeholder="제목 입력(40자 이내)"
                      value={inputs.notifyTitle ?? undefined}
                      handleValue={(value) => {
                        handleInput('notifyTitle', value)
                      }}
                    />
                  ) : (
                    <TXT
                      _css={css`
                        text-align: left !important;
                      `}
                    >
                      {inputs.notifyTitle}
                    </TXT>
                  )}
                </BoxRowComponent>
                <BoxRowComponent
                  _label={'알림 내용'}
                  _css={css`
                    margin-top: 12px;
                    align-items: flex-start;
                  `}
                >
                  <Textarea
                    readOnly={!!noticeId}
                    _css={css`
                      border: none;
                      border-radius: 0;
                      padding: 0px;
                      height: 120px;
                    `}
                    maxLength={140}
                    value={inputs.notifyContent ?? undefined}
                    onChange={(e) => {
                      handleInput('notifyContent', e.target.value)
                    }}
                    placeholder={!noticeId ? `내용 입력(140자 이내)` : ''}
                  />
                </BoxRowComponent>
                <BoxRowComponent _label={'옵션'}>
                  <CheckBoxRound
                    _css={css`
                      text-align: left !important;
                    `}
                    _active={inputs.option === '중요공지'}
                    onClick={() => {
                      const value =
                        inputs.option === '중요공지' ? undefined : '중요공지'
                      handleInput('option', value)
                    }}
                  >
                    중요 공지
                  </CheckBoxRound>
                  <CheckBoxRound
                    _css={css`
                      text-align: left !important;
                    `}
                    _active={inputs.option === '외부링크'}
                    onClick={() => {
                      const value =
                        inputs.option === '외부링크' ? undefined : '외부링크'
                      handleInput('option', value)
                    }}
                  >
                    외부 링크
                  </CheckBoxRound>
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
            {inputs.option === '외부링크' && (
              <BoxLayout _title="외부링크 *">
                <ContentRadiusBox>
                  <FlexRow gap={12}>
                    <IconsClip />
                    <UnderlineInput
                      _css={css`
                        width: 100%;
                      `}
                      placeholder="외부 링크 입력"
                      value={inputs.link ?? undefined}
                      handleValue={(value) => handleInput('link', value)}
                    />
                  </FlexRow>
                </ContentRadiusBox>
              </BoxLayout>
            )}
          </ContentBoxWrap>
        }
        _rightContents={
          <BoxLayout
            _title="제목 *"
            _css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <ContentRadiusBox>
              <UnderlineInput
                placeholder="제목 입력"
                value={inputs.title}
                handleValue={(value) => handleInput('title', value)}
              />
            </ContentRadiusBox>
            {inputs.option === '외부링크' ? (
              <ContentRadiusBox
                _css={css`
                  margin-top: 24px;
                  flex: 1;
                `}
              />
            ) : (
              <QuillEditorComponent
                _css={S.QuillEditorCss}
                _quillHTML={inputs.content ?? undefined}
                _emitValue={(value, _d, _s, editor) => {
                  const quillValue = value === NULL_OF_QUILL ? null : value
                  handleInput('content', quillValue)
                }}
              />
            )}
          </BoxLayout>
        }
        _bottomRightControls={
          <DC.Layout.RightBottom>
            <PrimaryOutlineButton
              _css={css`
                width: 160px;
                height: 48px;
              `}
              // disabled={selectedIds.length < 1}
              onClick={() => {
                const link = noticeId
                  ? `/customer/notice/${noticeId}`
                  : '/customer/notice'
                navigate(link)
              }}
            >
              취소
            </PrimaryOutlineButton>

            <PrimaryButton
              disabled={noticeId ? trueWhenEmpty || isMatched : trueWhenEmpty}
              _css={css`
                flex: 1;
                height: 48px;
              `}
              onClick={() => {
                const parsedData = parseInputToAPINoticePublishData(inputs)
                noticeId ? editNotice(parsedData) : publishNotice(parsedData)
              }}
            >
              완료
            </PrimaryButton>
          </DC.Layout.RightBottom>
        }
      ></DetailsDefault>
    </>
  )
}

// filter useless attr before sending to API
const filterUselessAttr = (inputs: noticePublishValueProps) => {
  let filteredInputs = { ...inputs }
  if (inputs.option === '외부링크') {
    // 외부 링크일 경우 컨텐츠 비우기
    filteredInputs = {
      ...filteredInputs,
      content: null,
    }
  } else {
    // 외부 링크가 아닐 경우 링크 비우기
    filteredInputs = {
      ...filteredInputs,
      link: null,
    }
  }

  return filteredInputs
}
// matchInput -> APINoticeDetailData
const parseInputToAPINoticePublishData = (inputs: noticePublishValueProps) => {
  const filteredInput = filterUselessAttr(inputs)
  const parsedData: APINoticePublishData = {
    targetUserType: filteredInput.target,
    title: filteredInput.title,
    isImportant: filteredInput.option === '중요공지',
    link: filteredInput.link,
    content: filteredInput.content,
    notiTitle: filteredInput.notifyTitle,
    notiContent: filteredInput.notifyContent,
  }
  return parsedData
}
// APINoticeDetailData -> matchInput data
const parseAPINoticeDetailDataToInput = (data: APINoticeDetailData) => {
  const parsedData: noticePublishValueProps = {
    target: data.targetUserType,
    title: data.title,
    option: getNoticeState(data.isImportant, !!data.link),
    link: data.link,
    content: data.content,
    notifyTitle: data.notiTitle,
    notifyContent: data.notiContent,
  }
  return parsedData
}

export const getNoticeState = (
  isImportant: boolean,
  isLink: boolean
): NoticeOption => {
  return isImportant ? '중요공지' : isLink ? '외부링크' : undefined
}

export default NoticePublish
