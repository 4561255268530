import { MutableRefObject, useRef, useState } from 'react'
import { DoubleDFile } from 'types/services.type'
import { getFileToBlob, uuid } from 'utils/helpers'
import { callFileUpload } from 'utils/services'
import S from './PhotoUploadBox.styles'
import { PhotoUploadBoxProps } from './PhotoUploadBox.types'

const PhotoUploadBox = ({
  _css,
  _type,
  _url,
  _optionValue,
  _emitValue,
  multiple = true,
  ...props
}: PhotoUploadBoxProps) => {
  const [oneFiles, set_oneFiles] = useState<string>('')
  const uuidRef = useRef(uuid()) as MutableRefObject<string>

  const handleUpload = async (...fileList: File[]) => {
    const uploadedList = (
      await Promise.all(
        fileList
          .map(async (file) => {
            const fileName = await callFileUpload(
              file,
              _type,
              _url,
              _optionValue
            )
            const blob = await getFileToBlob(file)
            if (fileName) {
              return {
                id: fileName ?? '',
                name: fileName ?? '',
                url: blob,
              }
            } else {
              return null
            }
          })
          .filter((ii) => !!ii)
      )
    ).filter((ii) => !!ii) as DoubleDFile[]
    if (uploadedList.length > 0) {
      _emitValue(uploadedList)
    }
  }
  return (
    <>
      <S.Label htmlFor={'photo' + uuidRef.current}>
        <S.Upload>사진 업로드</S.Upload>
        <input
          id={'photo' + uuidRef.current}
          type="file"
          hidden
          multiple={multiple}
          value={oneFiles}
          accept={'image/x-png, image/png, image/gif, image/jpeg'}
          onChange={async (e) => {
            set_oneFiles(e.target.value)
            const getFiles = e.target.files as FileList
            if (getFiles) {
              await handleUpload(...getFiles)
              setTimeout(() => {
                set_oneFiles('')
              }, 0)
            }
          }}
        />
      </S.Label>
    </>
  )
}
export default PhotoUploadBox
