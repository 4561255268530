import CellDropdown from './CellDropdown'
import ServiceDropdown from './ServiceDropdown'
import UnderlineDropdown from './UnderlineDropdown'

const Dropdown = {
  Underline: UnderlineDropdown,
  Cell: CellDropdown,
  Service: ServiceDropdown,
}
export default Dropdown
