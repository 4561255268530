import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'

const MaterialFeeStyle = {
  Wrap: styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    ${addCssProps}
  `,
}

export default MaterialFeeStyle
