import { ImagesGlobalLogo } from 'assets'
import S from './LogoPart.styles'
import { LogoPartProps } from './LogoPart.types'

const LogoPart = ({ _css, ...props }: LogoPartProps) => {
  return (
    <>
      <S.Wrap
        onClick={() => {
          window.location.reload()
        }}
        _css={_css}
        {...props}
      >
        <div>
          <img src={ImagesGlobalLogo} alt="Logo" width={100} />
        </div>
      </S.Wrap>
    </>
  )
}
export default LogoPart
