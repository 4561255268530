import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
import { SettingStyles } from '../Setting.styles'

const SelectServiceStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  GroupButton: SettingStyles.GroupButton,
  ServiceButton: styled(SettingStyles.GroupButton)`
    display: flex;
    align-items: center;
    gap: 4px;
    width: calc(25% - (36px / 4));
    ${typography['Body/Large/Bold']}

    &.etc {
      padding: 18px 16px;
      width: calc((100% / 3) - (24px / 3));
      &:not(.add) > div:last-of-type {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.add {
        box-shadow: none;
        padding: 16px 14px;
        border: 2px dashed #dadfe8;
        justify-content: center;
        text-align: center;
        gap: 0px;
        color: ${colors['Grayscale/Gray Default']};
      }
    }
    img {
      width: 24px;
      height: 24px;
    }
    .img {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
    }
  `,
  DisabledBadge: styled.div`
    position: absolute;
    top: 1px;
    right: 13px;
    background: #dadfe8;
    border-radius: 0px 0px 4px 4px;
    padding: 1px 4px;
    ${typography['Menu/Bold']}
    color: ${colors['Grayscale/Gray Default']};
  `,
}

export default SelectServiceStyle
