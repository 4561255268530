import { BADGE_COLOR } from 'components/Badge/Badge'

export const POINT_STATE_DROPDOWN_LIST = {
  전체: '',
  '포인트 발행': '포인트 발행',
  '포인트 사용': '포인트 사용',
  기간만료: '기간만료',
  '포인트 적립': '포인트 적립',
} as const
export type PointStateKeyType = KeyOf<typeof POINT_STATE_DROPDOWN_LIST>

export const POINT_STATE_BADGE_COLOR_SET = {
  '포인트 발행': BADGE_COLOR.PrimaryLight,
  '포인트 사용': BADGE_COLOR.PrimaryFill,
  기간만료: BADGE_COLOR.Gray,
  '포인트 적립': BADGE_COLOR.Danger,
} as const

export const POINT_QUERY_DROPDOWN_LIST = {
  회원명: 'name',
  내용: 'note',
  발급자명: 'issuer',
} as const
export const POINT_TYPE_DROPDOWN_LIST = {
  전체: 'all',
  '회원 지정': '1',
  '코드 발급': '2',
} as const
export type PointKeyType = KeyOf<typeof POINT_TYPE_DROPDOWN_LIST>
export const isPointType = (
  value: any
): value is typeof POINT_TYPE_DROPDOWN_LIST[PointKeyType] => {
  return (
    value !== null &&
    Object.values(POINT_TYPE_DROPDOWN_LIST).some((item) => item === value)
  )
}

export type PointFilterListType = {
  types: typeof POINT_TYPE_DROPDOWN_LIST[PointKeyType]
}
export const initialPointFilterValues: PointFilterListType = {
  types: 'all',
}

export type PointListItemType = {
  id: number
  pointState: PointStateKeyType
  createdAt: string
  content: string
  member: string
  issuer: string
  pointAmount: number
}

// ** API
export interface APIPointListData {
  id: number
  type: keyof typeof POINT_STATE
  createdAt: string
  note: string
  name: string
  issuerName: string
  amount: number
}

export const POINT_STATE = {
  charge: '포인트 충전',
  issue: '포인트 발행',
  save: '포인트 적립',
  use: '포인트 사용',
  destroy: '기간만료',
} as const
