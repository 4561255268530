import { GetFormatsType } from 'components/UnderlineInput/UnderlineFormatInput'

export const parseObjectToQueryString = (
  obj: QueryObject,
  withoutQuestion?: boolean
) => {
  let query = (Object.keys(obj) as Array<keyof typeof obj>)
    .map((keyName) => (obj[keyName] ? `${keyName}=${obj[keyName]}` : ''))
    .filter((ii) => !!ii)
    .join('&')
  return query ? `${withoutQuestion ? '' : '?'}${query}` : ''
}
export const removeInitialOrder = <T extends QueryObject>(
  value: T
): QueryObject => {
  value = (Object.keys(value) as Array<keyof T>).reduce((prev, curr) => {
    if (value[curr] === 'INITIAL') return prev
    prev[curr] = value[curr]
    return prev
  }, {} as T)
  return value
}
export const parseQueryStringToObject = <T extends QueryObject>(
  value: string
) => {
  value = decodeURIComponent(value.replace(/\+/g, ' '))
  if (value.slice(0, 1) === '?') {
    value = value.slice(1, value.length)
  }

  let paramsArray = value.split('&').filter((ii) => !!ii)
  let params = paramsArray.reduce((prev, curr) => {
    let paramsSplit = curr.split('=')
    if (paramsSplit.length === 2) {
      const keyName = paramsSplit[0] as keyof T
      const property = decodeURIComponent(paramsSplit[1]) as T[keyof T]
      prev[keyName] = property
    }
    return prev
  }, {} as T)
  return params
}

export const attachHttpToStr = (value: string) => {
  return /(http(s)?:\/\/)/.test(value) ? value : 'https://' + value
}

/**
 * 문자열 -> 지정된 형식의 문자열로 parse
 * @param value
 * @param option
 */
export const parseToFormattedStr = (value: string, option: GetFormatsType) => {
  if (!value) return value
  if (typeof value !== 'string') {
    value = String(value)
  }
  if (option === 'phone') {
    return value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
  }
  if (option === 'residentNumber') {
    return value.replace(/^(\d{6})(\d{7})$/, '$1-$2')
  }
  return value
}

/**
 * http://192.168.1.77:9000/file/파일이름.jpeg 과 같은 형태의 문자열에서 파일이름.jpeg만 추출
 */
export const extractFileNameFromUrl = (url: string) => {
  const splittedUrl = url.split('/')
  return splittedUrl[splittedUrl.length - 1]
}
/**
 * Adds "http://" to a URL if it doesn't already start with "http://" or "https://".
 * @param url The URL to modify.
 * @returns The modified URL with "http://" added if necessary.
 */
export const addHttpToLinkStr = (url: string): string => {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url
  }
  return url
}
