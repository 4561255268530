import styled from '@emotion/styled/macro'

const AdminDetailsStyle = {
  RightBottomBtnWrapper: styled.div`
    width: 100%;
    height: 96px;
    padding: 24px 32px;
  `,
}

export default AdminDetailsStyle
