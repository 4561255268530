import { IconsClip } from 'assets'
import { Link } from 'react-router-dom'
import { SvgWrap } from 'themes/Shared.styles'
import { attachHttpToStr } from 'utils/parsers'

export interface LinkClipProps {
  _to: string
}

const LinkClip = ({ _to, ...props }: LinkClipProps) => {
  return (
    <Link to={attachHttpToStr(_to)} target={'_blank'}>
      <SvgWrap _size={24} _svgSize={{ width: 16, height: 16 }}>
        <IconsClip />
      </SvgWrap>
    </Link>
  )
}
export default LinkClip
