import { css } from '@emotion/react'
import { RoundBox } from 'components/ContentsBox/ContentsBox.styles'
import { Dayjs } from 'dayjs'
import useFetch from 'hooks/useFetch'
import { useEffect, useMemo, useState } from 'react'
import { cn } from 'utils/helpers'
import { DotColorsKeyType } from '../../CalendarInReservation/CalendarInReservation.types'
import { TechsType } from '../../WorkerAssignmentTab/AssignTeamPopup/AssignTeamPopup.types'
import S from './SchedulesInPopup.styles'
import { SchedulesInPopupProps } from './SchedulesInPopup.types'

type SelectedSchedules = {
  type: DotColorsKeyType
  title: string
  workId: number | string
  techs: string[]
}
type ScheduleGroupByTime = {
  time: {
    start: string
    end: string
  }
  data: SelectedSchedules[]
}
type ApiSchedulesADay = {
  startTime: string
  endTime: string // HHmm
  schedules: {
    taskId: string
    type: TechsType
    categoryName: string
    nameList: string[]
  }[]
}
const SchedulesInPopup = ({
  _css,
  _value,
  _onClose,
  ...props
}: SchedulesInPopupProps) => {
  const [selected, set_selected] = useState<Dayjs | null>(null)
  useEffect(() => {
    if (_value) set_selected(_value.selected)
  }, [_value])
  const [response] = useFetch<ApiSchedulesADay[]>(
    'task',
    `/schedule/day?yyyymmdd=${selected?.format('YYYYMMDD')}&team=${
      _value?.type
    }`,
    {
      disabled: !selected || !_value?.type,
    }
  )
  const currentSchedules = useMemo(() => {
    if (!response) return [] as ScheduleGroupByTime[]
    // return [] as ScheduleGroupByTime[]
    return response.map((item) => ({
      time: {
        start: item.startTime.slice(0, 2) + ':' + item.startTime.slice(2, 4),
        end: item.endTime.slice(0, 2) + ':' + item.endTime.slice(2, 4),
      },
      data: item.schedules.map((data) => ({
        type: data.type,
        title: data.categoryName,
        workId: data.taskId,
        techs: data.nameList,
      })),
    })) as ScheduleGroupByTime[]
  }, [response])

  if (!_value) return <></>
  return (
    <>
      <S.Wrap
        _css={css`
          margin-top: ${_value.top}px;
          margin-left: ${_value.left}px;
        `}
      >
        <RoundBox
          _css={css`
            width: 100%;
            height: 100%;
            flex-shrink: 0;
            position: relative;
          `}
        >
          <S.RightHeader>오늘 다른 일정</S.RightHeader>
          <S.SchedulesOuter
            className={cn({ none: currentSchedules.length < 1 })}
          >
            <S.SchedulesWrap>
              {currentSchedules.length > 0 ? (
                <>
                  {currentSchedules.map((schedule, index) => {
                    return (
                      <S.SchedulesGroup key={'schedule' + index}>
                        <S.AllDayWrap>
                          <S.Line />
                          <div>
                            {schedule.time.start} ~ {schedule.time.end}
                          </div>
                          <S.Line />
                        </S.AllDayWrap>
                        {schedule.data.map((work, workIndex) => {
                          return (
                            <S.ScheduleItem
                              key={'work' + workIndex}
                              _bgColor={work.type}
                              onClick={() => {}}
                            >
                              <div className="title">{work.title}</div>
                              {work.techs.length > 0 && (
                                <div className="techs">
                                  {work.techs.map((techName, techsIndex) => {
                                    return (
                                      <div key={'techName' + techsIndex}>
                                        {techName}
                                      </div>
                                    )
                                  })}
                                </div>
                              )}
                            </S.ScheduleItem>
                          )
                        })}
                      </S.SchedulesGroup>
                    )
                  })}
                </>
              ) : (
                <S.None>오늘 일정이 없습니다.</S.None>
              )}
            </S.SchedulesWrap>
          </S.SchedulesOuter>
        </RoundBox>
      </S.Wrap>
      <S.Background onClick={_onClose} />
    </>
  )
}
export default SchedulesInPopup
