import { API_URL_KEYS } from 'API_URL'
import { AxiosRequestConfig } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import callAxios, {
  DoubleDError,
  extractData,
  handleError,
} from 'utils/callAxios'
export type FetchConfig = {
  disabled?: boolean
  configs?: AxiosRequestConfig
}

export const useRefresh = (): [boolean, () => void] => {
  const [refresh, set_refresh] = useState<boolean>(false)
  useEffect(() => {
    if (refresh) set_refresh(false)
  }, [refresh])
  const callRefresh = useCallback(() => {
    set_refresh(true)
  }, [])
  return [refresh, callRefresh]
}
const useFetch = <T,>(
  type: API_URL_KEYS,
  url: string,
  options?: FetchConfig
): [T | null, DoubleDError | null, () => void] => {
  const [refresh, callRefresh] = useRefresh()
  const [response, set_response] = useState<T | null>(null)
  const [apiError, set_apiError] = useState<DoubleDError | null>(null)
  const resetValues = useCallback(() => {
    set_response(null)
    set_apiError(null)
  }, [])
  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const fetchData = async (_url: string, _options?: FetchConfig) => {
      try {
        if (
          _options?.disabled ||
          _url.includes('=undefined') ||
          _url.includes('/undefined')
        ) {
          resetValues()
          return
        }
        const configs = _options?.configs ?? {}
        const res = await callAxios(type).get<T>(_url, { ...configs, signal })
        set_response(extractData(res))
      } catch (error: any) {
        set_apiError(error.response ?? error.data ?? {})
        handleError(error)
      }
    }
    if (url && !refresh) {
      fetchData(url, options)
    } else {
      resetValues()
    }
    return () => {
      resetValues()
      // fetch 도중에 언마운트 될  시 abort
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, type, options?.disabled, resetValues, refresh])

  return [response, apiError, callRefresh]
}
export default useFetch
