import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'

const SelectTeamPopupStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  TableWrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
  `,
  TechsWrap: styled.div`
    display: flex;
    align-items: center;
    overflow-x: auto;
    gap: 16px;
    flex: 1;
  `,
}

export default SelectTeamPopupStyle
