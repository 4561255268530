import { css } from '@emotion/react'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import DetailsDefault, { ToList } from 'components/DetailsComponents'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import { useAppDispatch } from 'hooks/reduxHooks'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import { PWD_NOT_CHANGED } from 'types/share'
import callAxios, { handleError } from 'utils/callAxios'
import {
  APIgetTechsDetail,
  TECHS_PROFILE_INITIAL_VALUE,
  TechsProfileValuesType,
} from '../TechsDetails/TechsDetails.types'
import { APICreateTechs, EditTechsProps, TECHS_TYPE } from './EditTechs.types'
import ModifyTechsProfiles from './ModifyTechsProfiles'
import ModifyTechsSpecs from './ModifyTechsSpecs'

const EditTechs = ({ _css, ...props }: EditTechsProps) => {
  const { Layout: L } = S
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const techsData = useMatchInput(TECHS_PROFILE_INITIAL_VALUE)
  const { inputs, fetchInit, isMatched } = techsData
  const [optionalAttr, set_optionalAttr] =
    useState<Array<keyof TechsProfileValuesType>>(INIT_OPTIONAL_ATTR)
  const trueWhenEmpty = useEmptyCheck(techsData.inputs, ...optionalAttr)
  const [response] = useFetch<APIgetTechsDetail>('user', `/technician/${id}`, {
    disabled: !id,
  })
  const [disableUserPopup, set_disableUserPopup] = useState<boolean>(false)

  const handleEditMode = () => {
    navigate(`/system/techs/${id ?? ''}`)
  }

  // 저장 버튼 클릭 시 핸들러
  const onSubmitHandler = () => {
    const { usernameError, passwordError } = inputs
    if (usernameError || passwordError) {
      return dispatch(
        setToast([
          usernameError
            ? `아이디: ${usernameError}`
            : `비밀번호: ${passwordError}` ?? '',
          'error',
        ])
      )
    }
    const parsedData = parseTechsDataToAPIReqData(inputs)
    // api request
    id ? editTechs(parsedData) : createTechs(parsedData)
  }
  // 숙련공 생성 api 요청
  const createTechs = async (value: APICreateTechs) => {
    try {
      await callAxios('user').post('/technician', value)
      dispatch(setToast(['저장되었습니다.', 'success']))
      navigate(`/system/techs`)
    } catch (e) {
      handleError(e)
    }
  }
  // 숙련공 수정 api 요청
  const editTechs = async (value: APICreateTechs) => {
    const { pwd, ...rest } = value
    const valueAfterCheckPwd =
      value.pwd === PWD_NOT_CHANGED ? { ...rest } : { ...value }
    try {
      await callAxios('user').patch(`/technician/${id}`, valueAfterCheckPwd)
      dispatch(setToast(['수정되었습니다.', 'success']))
      navigate(`/system/techs/${id}`)
    } catch (e) {
      handleError(e)
    }
  }
  // 숙련공 비활성화 처리
  const handleUserDisable = async () => {
    techsData.handleInput('badges', (prev) => ({
      ...prev.badges,
      inActive: true,
    }))
    set_disableUserPopup(false)
    dispatch(setToast(['숙련공 계정이 비활성화 되었습니다.', 'error']))
  }

  // 소속 변경 시, 필수 값 옵션 변경
  useEffect(() => {
    if (inputs.badges.techsType === '수수다') {
      set_optionalAttr((prev) =>
        prev.filter((item) => item !== 'settlementRatio' && item !== 'salary')
      )
    }
    if (inputs.badges.techsType === '프리랜서') {
      set_optionalAttr((prev) => [...prev, 'salary', 'settlementRatio'])
    }
  }, [inputs.badges])
  // 수정 페이지일 때 response 데이터로 useMatchInput 초기화
  useEffect(() => {
    if (response) {
      const parsedResponse = parseAPIResDataToTechsData(response)
      fetchInit(parsedResponse)
    }
  }, [response, fetchInit])

  return (
    <>
      <Modal
        open={disableUserPopup}
        onClose={() => set_disableUserPopup(false)}
      >
        <MiniPopup
          _title="숙련공 계정 비활성화"
          _buttonHandler={
            <>
              <GrayScaleFill onClick={() => set_disableUserPopup(false)}>
                아니오
              </GrayScaleFill>
              <PrimaryButton onClick={handleUserDisable}>
                비활성화하기
              </PrimaryButton>
            </>
          }
        >
          비활성화 하게 되면 해당 계정은 탈퇴 처리되며,
          <br />
          숙련공앱에 로그인 하실 수 없습니다.
        </MiniPopup>
      </Modal>
      <DetailsDefault
        _title={id ? '숙련공 수정' : '숙련공 등록'}
        _topLeftControls={
          id ? (
            <L.ButtonRow>
              {!techsData.inputs.badges.inActive && (
                <GrayScaleFill
                  padding={'5px 12px'}
                  _mini
                  onClick={() => {
                    set_disableUserPopup(true)
                  }}
                >
                  계정 비활성화
                </GrayScaleFill>
              )}
              <ToList _to="/system/techs" />
            </L.ButtonRow>
          ) : (
            <></>
          )
        }
        _leftContents={<ModifyTechsProfiles {...techsData} _id={id} />}
        _rightContents={<ModifyTechsSpecs {...techsData} />}
        _bottomRightControls={
          <L.RightBottom>
            <GrayScaleOutline
              _css={css`
                width: 160px;
                padding: 12px 0;
              `}
              onClick={handleEditMode}
            >
              취소
            </GrayScaleOutline>
            <PrimaryButton
              _css={css`
                flex: 1;
                padding: 12px 0;
              `}
              disabled={!id ? trueWhenEmpty : isMatched || trueWhenEmpty}
              onClick={onSubmitHandler}
            >
              저장
            </PrimaryButton>
          </L.RightBottom>
        }
      />
    </>
  )
}

// techsData(프론트) -> API 요청 데이터(서버)
const parseTechsDataToAPIReqData = (value: TechsProfileValuesType) => {
  const reqData: APICreateTechs = {
    name: value.name,
    userName: value.userId,
    pwd: value.password,
    phone: value.phone,
    personalNo: value.residentNumber,
    accountInfo: value.accountNumber,
    measuring: !!value.measureAble,
    type: TECHS_TYPE[value.badges.techsType],
    basicWages: value.salary ?? undefined,
    settlementRatio: value.settlementRatio ?? undefined,
    dispatchableZone: value.dispatchAbleAreas.map((item) => item.id),
    availableSkill: value.skills,
  }
  return reqData
}
// API 응답 데이터(서버) -> techsData(프론트)
export const parseAPIResDataToTechsData = (value: APIgetTechsDetail) => {
  const resData: TechsProfileValuesType = {
    name: value.name,
    userId: value.userName,
    profileImage: value.profileImageUrl,
    phone: value.phone,
    measureAble: value.measuring,
    badges: {
      techsType: value.isFreelancer ? '프리랜서' : '수수다',

      inActive: !!value.disabledAt,
    },
    password: PWD_NOT_CHANGED,
    createdAt: value.createdAt,
    residentNumber: value.personalNo,
    accountNumber: value.accountInfo,
    salary: value.basicWages,
    settlementRatio: value.settlementRatio,
    dispatchAbleAreas: value.dispatchableZone,
    skills: value.availableSkill,
    serviceCount: value.totalTaskCount,
    recentServiceDate: value.lastTaskDate ?? '',
  }
  return resData
}

const INIT_OPTIONAL_ATTR: Array<keyof TechsProfileValuesType> = [
  'badges',
  'createdAt',
  'passwordError',
  'profileImage',
  'recentServiceDate',
  'serviceCount',
  'skills',
  'usernameError',
]
export default EditTechs
