import { cn, HandleEllipsisToolTip } from 'utils/helpers'
import T from '../TableComponent.styles'
import { TableCellProps } from './TableCell.types'

const TableCell = ({
  _css,
  _size = 'auto',
  _position,
  className,
  ...props
}: TableCellProps) => {
  return (
    <T.BodyCell
      _size={_size}
      _position={_position}
      className={cn({ auto: _size === 'auto' }, className)}
      onMouseEnter={(e) => HandleEllipsisToolTip('mouseEnter', e)}
      onMouseLeave={(e) => HandleEllipsisToolTip('mouseLeave', e)}
      _css={_css}
      {...props}
    ></T.BodyCell>
  )
}
export default TableCell
