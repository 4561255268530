import EventDetails from 'pages/Support/Event/EventDetails/EventDetails'
import EventList from 'pages/Support/Event/EventList/EventList'
import { useParams } from 'react-router-dom'

export interface PointProps {}

const Event = (props: PointProps) => {
  const { id } = useParams<'id'>()

  return (
    <>
      {id && <EventDetails _id={id} />}
      {!id && <EventList />}
    </>
  )
}
export default Event
