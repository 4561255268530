import SettlementForPartnerDetails from 'pages/Settlement/SettlementForPartnerDetails'
import SettlementList from 'pages/Settlement/SettlementList'
import { useParams } from 'react-router-dom'

interface SettlementForPartnerHistoryProps {}

const SettlementForPartnerHistory = (
  props: SettlementForPartnerHistoryProps
) => {
  const { id } = useParams<'id'>()

  return (
    <>
      <>{!id && <SettlementList _type="history" _target="partner" />}</>
      <>{id && <SettlementForPartnerDetails _type="history" _id={id} />}</>
    </>
  )
}
export default SettlementForPartnerHistory
