import { css } from '@emotion/react'
import Badge from 'components/Badge'
import { BADGE_COLOR } from 'components/Badge/Badge'
import { PrimaryButton } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import Dropdown from 'components/Dropdown'
import LinkClip from 'components/LinkClip/LinkClip'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import TableCell from 'components/TableComponent/TableCell'
import T from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import dayjs from 'dayjs'
import useSearchFilter from 'hooks/useSearchFilter'
import {
  APINoticeListRow,
  NOTICE_BADGE_COLOR_SET,
  NOTICE_QUERY_DROPDOWN_LIST,
  NOTICE_TYPE_DROPDOWN_LIST,
  NoticeFilterListType,
  NoticeKeyType,
  initialNoticeFilterValues,
  isNoticeType,
} from 'pages/Support/Notice/NoticeList/NoticeList.types'

import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow, TXT } from 'themes/Shared.styles'
import {
  cn,
  isObjectKeyType,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
} from 'utils/helpers'

import useFetch from 'hooks/useFetch'
import S from './NoticeList.styles'
export interface NoticeListProps {}

type NoticeListItemType = {
  id: string
  category: NoticeKeyType
  title: string
  createdAt: string
  author: string
  link: string
  important?: boolean
}

const NoticeListTableConfigs: TableCellConfig<NoticeListItemType>[] = [
  {
    _type: 'default',
    _id: 'category',
    _label: '분류',
    _position: 'center',
    _size: 106,
  },
  {
    _type: 'default',
    _id: 'title',
    _label: '제목',

    _size: 712,
  },
  {
    _type: 'default',
    _id: 'createdAt',
    _label: '일시',
    _position: 'left',
    _size: 180,
  },
  {
    _type: 'default',
    _id: 'author',
    _label: '작성자명',
    _size: 84,
  },
  {
    _type: 'default',
    _id: 'link',
    _label: '외부링크',
    _position: 'center',
    _size: 84,
  },
]

export const NoticeList = (props: NoticeListProps) => {
  const [selectedId, set_selectedId] = useState<string>('')
  const [totalNoticeCount, set_totalNoticeCount] = useState<number>()
  const navigate = useNavigate()

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: initialNoticeFilterValues,
      queryList: NOTICE_QUERY_DROPDOWN_LIST,
    },
    ({ searchType, searchValue, targetUserType, ...reset }) => {
      return {
        targetUserType: isNoticeType(targetUserType) ? targetUserType : 'all',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    ({ queryName, terms = '', targetUserType, ...rest }) => {
      return removeUndefined({
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        targetUserType: targetUserType === '' ? '' : targetUserType,
        ...rest,
      })
    }
  )
  // api 데이터 parser
  const parserForNoticeListTable = (
    response: DoubleDListWithPaging<APINoticeListRow>
  ) => {
    const rebuild = response.list.map(
      (row: APINoticeListRow, index: number) => {
        return {
          id: row.id.toString(),
          category:
            row.targetUserType === 'all'
              ? '전체'
              : row.targetUserType === 'normal'
              ? '일반회원'
              : '정기회원',
          title: row.title,
          createdAt: dayjs(row.createdAt).format('YYYY-MM-DD(ddd) HH:mm'),
          author: row.issuer,
          link: row.link,
          important: row.isImportant,
        } as NoticeListItemType
      }
    )
    set_totalNoticeCount(response.paging.totalRow)
    return {
      _list: rebuild,
      _cursor: disposeCursor(response.paging.cursor),
    }
  }

  const apiURI = useMemo(() => {
    const uri = `/board/notice/list?take=10${
      window.location.search ? '&' + searchParams.toString() : ''
    }`

    return decodeURI(uri)
  }, [searchParams])

  const [stats] = useFetch<{
    total: number
    all: number
    U1: number
    U2: number
  }>('user', `/board/notice/stats`)
  return (
    <ContentsBox _type="relative">
      <S.Wrapper>
        {/* TopInfo */}
        <TopInfo>
          <TopInfoRow
            _label={'전체'}
            _contents={`${thousand(stats?.total)}건`}
          />
          <TopInfoRow
            _label={'일반회원'}
            _contents={`${thousand(stats?.U1)}건`}
          />
          <TopInfoRow
            _label={'정기회원'}
            _contents={`${thousand(stats?.U2)}건`}
          />
        </TopInfo>
        {/* TableTitle */}
        <TableTitle
          _left={
            <FlexRow gap={0}>
              <h1>공지사항</h1>
              <TXT _color="Primary/Default">
                <h1> {totalNoticeCount}</h1>
              </TXT>
            </FlexRow>
          }
          _right={
            <PrimaryButton
              onClick={() => navigate('/customer/notice/publish')}
              _css={css`
                width: 160px;
                height: 40px;
              `}
            >
              글쓰기
            </PrimaryButton>
          }
        />
        {/* Filter */}
        <FilterPart<NoticeFilterListType>
          _value={initialValues}
          _placeholder="쿠폰 제목 검색"
          _queryNameList={NOTICE_QUERY_DROPDOWN_LIST}
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _FilterContents={({ _value, _emitValue, ...props }) => (
            <Dropdown.Underline
              _css={css`
                width: 120px;
              `}
              _value={_value?.targetUserType ? _value?.targetUserType : 'all'}
              _list={NOTICE_TYPE_DROPDOWN_LIST}
              _emitValue={(value) => {
                if (_emitValue && _value)
                  _emitValue({
                    ..._value,
                    targetUserType: isNoticeType(value) ? value : '',
                  })
              }}
            />
          )}
        />
        {/* Table */}
        <RoundFillBox>
          <TableComponent<NoticeListItemType>
            _config={NoticeListTableConfigs}
            _type="user"
            _url={apiURI}
            _parser={parserForNoticeListTable}
            _ListContent={({ _row, _index }) => {
              const rowId = _row.id ? String(_row.id) : String(_index)
              return (
                <T.Row
                  className={cn({
                    active: selectedId === rowId,
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    set_selectedId(rowId)
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation()
                    navigate(`/customer/notice/${rowId}`)
                  }}
                >
                  {NoticeListTableConfigs.map((item, columnIndex) => {
                    const _id = item._id
                    const _value = _row[item._id] as any

                    return (
                      <TableCell
                        key={'body' + _index + columnIndex}
                        _size={item._size}
                        _position={item._position}
                      >
                        {_id === 'category' ? (
                          <Badge
                            _css={css`
                              width: 52px;
                              height: 24px;
                            `}
                            {...(isObjectKeyType(
                              NOTICE_BADGE_COLOR_SET,
                              _value
                            ) && NOTICE_BADGE_COLOR_SET[_value])}
                          >
                            {_value}
                          </Badge>
                        ) : _id === 'title' ? (
                          <FlexRow gap={8}>
                            {_row.important && (
                              <Badge
                                _css={css`
                                  width: 33px;
                                  height: 24px;
                                `}
                                {...BADGE_COLOR['Danger']}
                              >
                                중요
                              </Badge>
                            )}
                            <div>{_value}</div>
                          </FlexRow>
                        ) : _id === 'link' ? (
                          _value ? (
                            <FlexRow gap={0} justifyContent="center">
                              <LinkClip _to={_value} />
                            </FlexRow>
                          ) : (
                            <></>
                          )
                        ) : (
                          <div>{_value}</div>
                        )}
                      </TableCell>
                    )
                  })}
                </T.Row>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrapper>
    </ContentsBox>
  )
}
