export const LOG_QUERY_DROPDOWN_LIST = {
  전화번호: 'phone',
  관리자명: 'name',
  '관리자 아이디': 'userName',
  주문번호: 'orderNumber',
  서비스명: 'actionDetail',
} as const

// ** API type
export interface APILogListData {
  id: number
  office: string
  name: string
  phone: string
  userName: string
  orderNumber: string | null
  actionDetail: string
  actionName: string
  createdAt: string
}
