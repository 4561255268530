import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const NotFoundItemStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${addCssProps}
  `,
  Box: styled.div`
    box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
    border-radius: 16px;
    padding: 34px 16px;
    height: 120px;
    text-align: center;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    gap: 8px;
    background-color: ${colors['White/White off']};
    ${typography['Body/Caption/Bold']}
    color: ${colors['Grayscale/Gray Default']};
    & > div {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 6px;
      img {
        width: 40px;
        height: 40px;
        margin: 0 auto;
      }
    }
  `,
}

export default NotFoundItemStyle
