import { ImagesDetailsAlert } from 'assets'
import S from './AlertRow.styles'
import { AlertRowProps } from './AlertRow.types'

const AlertRow = ({ _css, children, ...props }: AlertRowProps) => {
  return (
    <>
      <S.Wrap _css={_css} {...props}>
        <img src={ImagesDetailsAlert} alt="alert" />
        {children}
      </S.Wrap>
    </>
  )
}
export default AlertRow
