import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const AlertRowStyle = {
  Wrap: styled.div`
    padding: 10px 12px;
    border-radius: 12px;
    background-color: ${colors['Grayscale/Background Light']};
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    ${typography['Body/Caption/Regular']}
    color: ${colors['Grayscale/Gray Dark']};
    &.flex-start {
      align-items: flex-start;
    }
    .bold {
      ${typography['Body/Caption/Bold']}
    }
    ${addCssProps}
  `,
}

export default AlertRowStyle
