import SettlementForPartnerDetails from 'pages/Settlement/SettlementForPartnerDetails'
import SettlementList from 'pages/Settlement/SettlementList'
import { useParams } from 'react-router-dom'

interface SettlementForPartnerQueueProps {}

const SettlementForPartnerQueue = (props: SettlementForPartnerQueueProps) => {
  const { id } = useParams<'id'>()
  return (
    <>
      <>{!id && <SettlementList _type="queue" _target="partner" />}</>
      <>{id && <SettlementForPartnerDetails _type="queue" _id={id} />}</>
    </>
  )
}
export default SettlementForPartnerQueue
