import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'
export const LiveSearchDefaultDiv = styled.div`
  padding: 8px 32px;
  .highlight {
    color: ${colors['Primary/Default']};
  }
  &:hover {
    background-color: ${colors['Grayscale/Background Light']};
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: ${colors['Grayscale/Gray Lighter']};
  }
  &.disabled .highlight {
    color: ${colors['Grayscale/Gray Lighter']};
  }
  ${addCssProps}
`
const LiveSearchInputStyle = {
  Wrap: styled.div`
    position: relative;
    text-align: left;
    ${addCssProps}
  `,
  List: styled.div`
    position: absolute;
    width: 100%;
    text-align: left;
    z-index: 9;
    padding-bottom: 32px;
    ${typography['Body/Caption/Regular']}
    color: ${colors['Grayscale/Gray Dark']};
    .list-inner {
      width: 100%;
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      gap: 0px;
      background-color: #fff;
      box-shadow: 0px 4px 12px 0px #9a9a9a26;
      overflow-y: auto;
      overscroll-behavior-y: contain;
    }
    .not-found {
      ${typography['Body/Caption/Regular']}
      color: ${colors['Grayscale/Gray Dark']};
      background-color: #fff;
      box-shadow: 0px 4px 12px 0px #9a9a9a26;
      padding: 24px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  `,
}

export default LiveSearchInputStyle
