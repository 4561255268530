import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'

const EditBuildingPopupStyle = {
  Wrap: styled.div`
    padding: 32px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;
    ${addCssProps}
  `,
}

export default EditBuildingPopupStyle
