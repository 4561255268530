import { css, SerializedStyles } from '@emotion/react'
import { ContentPart } from 'pages/Main/components/ContentPart/ContentPart'
import React from 'react'
import { FlexRow, TXT } from 'themes/Shared.styles'

export interface PopUpContentPartProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  _title: string
  _required?: boolean
  _css?: SerializedStyles
}

export const PopUpContentPart = ({
  children,
  _css,
  _title,
  _required,
  ...props
}: PopUpContentPartProps) => {
  return (
    <ContentPart
      _css={css`
        .title {
          margin-bottom: 4px;
        }
        .content {
          border: none;
        }
        ${_css}
      `}
      _title={
        <FlexRow gap={0}>
          <TXT _textStyle="Body/Small/Bold" _color="Grayscale/Gray Default">
            {_title}
          </TXT>
          {_required && (
            <TXT _textStyle="Body/Small/Bold" _color={'Accent/Dark'}>
              {' *'}
            </TXT>
          )}
        </FlexRow>
      }
    >
      {children}
    </ContentPart>
  )
}
