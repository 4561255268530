import { css } from '@emotion/react'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import DetailsDefault, { ToList } from 'components/DetailsComponents'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import {
  APIgetTechsDetail,
  TECHS_PROFILE_INITIAL_VALUE,
  TechsDetailsProps,
  parserForProfileValues,
} from './TechsDetails.types'

import Chips from 'components/Chips'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import RowTab from 'components/RowTab'
import { useAppDispatch } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import LoadingPage from 'layout/LoadingPage'
import { parseAPIResDataToTechsData } from 'pages/System/Techs/EditTechs/EditTechs'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import { ContentBoxWrap, ContentRadiusBox, Flex } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import { isObjectValueType } from 'utils/helpers'
import HolidaysManagement from './HolidaysManagement'
import TechsDetailsReadOnly from './TechsDetailsReadOnly'

const tabs = ['가능 지역 · 업무', '휴무일 관리'] as const
const TechsDetails = ({ _css, _id, ...props }: TechsDetailsProps) => {
  const { Layout: L } = S
  const dispatch = useAppDispatch()
  const [searchParams, set_searchParams] = useSearchParams()
  const [currentTab, set_currentTab] = useState<typeof tabs[number]>()
  const [response, isError] = useFetch<APIgetTechsDetail>('user', `/technician/${_id}`, {
    disabled: !_id,
  })
  useEffect(() => {
    const currentTabQuery = searchParams.get('tabs')
    if (isObjectValueType(tabs, currentTabQuery)) {
      set_currentTab(currentTabQuery)
    } else {
      set_currentTab(tabs[0])
    }
  }, [searchParams])
  const techsData = useMatchInput(TECHS_PROFILE_INITIAL_VALUE)
  const { inputs, fetchInit, handleValues } = techsData
  const navigate = useNavigate()
  useEffect(() => {
    if (_id) {
      handleValues(parserForProfileValues('test'))
    }
  }, [_id, handleValues])

  const handleEditMode = () => {
    navigate(`/system/techs/edit/${_id}`)
  }
  const [disableUserPopup, set_disableUserPopup] = useState<boolean>(false)

  // 계정 비활성화 api 호출
  const handleUserDisable = async () => {
    if (!_id) return
    try {
      await callAxios('user').patch(`/technician/${_id}/disable`)
      techsData.handleInput('badges', (prev) => ({
        ...prev.badges,
        inActive: true,
      }))
      set_disableUserPopup(false)
      dispatch(setToast(['숙련공 계정이 비활성화 되었습니다.', 'error']))
    } catch (e) {
      handleError(e)
    }
  }

  // API로 받은 데이터 파싱하여 set
  useEffect(() => {
    if (response) {
      const parsedResponse = parseAPIResDataToTechsData(response)
      fetchInit(parsedResponse)
    }
  }, [response, fetchInit])
  if (!response) return <LoadingPage />

  if (isError) return <Navigate to={`/404?from=${window.location.pathname}`} />
  return (
    <>
      <Modal
        open={disableUserPopup}
        onClose={() => set_disableUserPopup(false)}
      >
        <MiniPopup
          _title="숙련공 계정 비활성화"
          _buttonHandler={
            <>
              <GrayScaleFill onClick={() => set_disableUserPopup(false)}>
                아니오
              </GrayScaleFill>
              <PrimaryButton onClick={handleUserDisable}>
                비활성화하기
              </PrimaryButton>
            </>
          }
        >
          비활성화 하게 되면 해당 계정은 탈퇴 처리되며,
          <br />
          숙련공앱에 로그인 하실 수 없습니다.
        </MiniPopup>
      </Modal>
      <DetailsDefault
        _title={'숙련공 상세'}
        _topLeftControls={
          <L.ButtonRow>
            {!techsData.inputs.badges.inActive && (
              <GrayScaleFill
                padding={'5px 12px'}
                _mini
                onClick={() => {
                  set_disableUserPopup(true)
                }}
              >
                계정 비활성화
              </GrayScaleFill>
            )}
            <ToList _to="/system/techs" />
          </L.ButtonRow>
        }
        _leftContents={<TechsDetailsReadOnly {...techsData} />}
        _topRightHeader={
          <RowTab
            _tabs={tabs}
            _tab={currentTab}
            _emitValue={(value) => {
              searchParams.set('tabs', value)
              set_searchParams(searchParams)
            }}
            _css={css`
              padding: 24px 32px 0;
            `}
          />
        }
        _rightContents={
          <>
            {currentTab === '가능 지역 · 업무' && (
              <ContentBoxWrap>
                <BoxLayout _title={'파견 가능 지역'}>
                  <ContentRadiusBox
                    _css={css`
                      min-height: 202px;
                      position: relative;
                    `}
                  >
                    {inputs.dispatchAbleAreas.length === 0 ? (
                      <Flex
                        alignItems={'center'}
                        justifyContent={'center'}
                        _textStyle="Body/Caption/Bold"
                        _color="Grayscale/Gray Light"
                        _css={css`
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          left: 0;
                          top: 0;
                        `}
                      >
                        파견 가능 지역을 추가하세요.
                      </Flex>
                    ) : (
                      <Flex gap={`16px 12px`} flexWrap="wrap">
                        {inputs.dispatchAbleAreas.map((item, index) => {
                          return (
                            <Chips
                              _type={'border'}
                              _color={'grayscale'}
                              key={item.name + index}
                            >
                              {item.name}
                            </Chips>
                          )
                        })}
                      </Flex>
                    )}
                  </ContentRadiusBox>
                </BoxLayout>
                <BoxLayout _title={'시공 가능 업무'}>
                  <ContentRadiusBox
                    _css={css`
                      min-height: 202px;
                      position: relative;
                    `}
                  >
                    {inputs.skills.length === 0 ? (
                      <Flex
                        alignItems={'center'}
                        justifyContent={'center'}
                        _textStyle="Body/Caption/Bold"
                        _color="Grayscale/Gray Light"
                        _css={css`
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          left: 0;
                          top: 0;
                        `}
                      >
                        시공 가능 업무를 추가하세요.
                      </Flex>
                    ) : (
                      <Flex gap={`16px 12px`} flexWrap="wrap">
                        {inputs.skills.map((skillName, skillIndex) => {
                          return (
                            <Chips
                              _type={'border'}
                              _color={'grayscale'}
                              key={skillName + skillIndex}
                            >
                              {skillName}
                            </Chips>
                          )
                        })}
                      </Flex>
                    )}
                  </ContentRadiusBox>
                </BoxLayout>
              </ContentBoxWrap>
            )}
            {currentTab === '휴무일 관리' && (
              <ContentBoxWrap
                className="holiday"
                _css={css`
                  &.holiday {
                    overflow-y: hidden;
                    height: 100%;
                  }
                  &.holiday > div:not(.draggable) {
                    overflow-y: hidden;
                    height: 100%;
                  }
                `}
              >
                <HolidaysManagement
                  _disabled={!!response?.disabledAt}
                  _technicianId={response?.userTechnicianId}
                />
              </ContentBoxWrap>
            )}
          </>
        }
        _bottomRightControls={
          <L.RightBottom>
            {techsData.inputs.badges.inActive ? (
              <GrayScaleOutline
                _css={css`
                  width: 100%;
                  padding: 12px 0;
                `}
                disabled={true}
              >
                비활성화 계정입니다.
              </GrayScaleOutline>
            ) : (
              <>
                <GrayScaleOutline
                  _css={css`
                    width: 100%;
                    padding: 12px 0;
                  `}
                  onClick={handleEditMode}
                >
                  수정
                </GrayScaleOutline>
              </>
            )}
          </L.RightBottom>
        }
      />
    </>
  )
}
export default TechsDetails
