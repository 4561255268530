import crypto from 'crypto-js'

/**
 *
 */
export const deleteIamportElements = () => {
  let IamportDialog = document.querySelector('.imp-dialog') as HTMLDivElement
  if (IamportDialog) {
    IamportDialog.remove()
  }
}
/**
 *
 *
 * @param {string} _e
 * @return {*} {null | string}
 */
export const decryptString = (_e: string) => {
  if (!_e) return null
  const bytes = crypto.AES.decrypt(_e, process.env.REACT_APP_SALT ?? 'doubled9')
  const decrypted = bytes.toString(crypto.enc.Utf8)
  if (decrypted) {
    return decrypted
  } else {
    return null
  }
}
export const usernameRegex = /^[a-z\d]{6,20}$/
export const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+=<>,./?;:'"|~`{}[\]\\-])[A-Za-z\d!@#$%^&*()_+=<>,./?;:'"|~`{}[\]\\-]{8,}$/

export const usernameInputRegex = /^[a-z\d]*$/
export const passwordInputRegex =
  /^[A-Za-z\d!@#$%^&*()_+=<>,./?;:‘“|~`{}[\]\\-]*$/
