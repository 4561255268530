import { css } from '@emotion/react'
import { IconsCrown, IconsUnlock } from 'assets'
import Badge from 'components/Badge'
import { PrimaryButton } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import Dropdown from 'components/Dropdown'
import { disposeCursor } from 'components/ListByCursor/ListByCursor.helpers'
import RoundFillBox from 'components/RoundFillBox'
import TableComponent from 'components/TableComponent'
import FilterPart from 'components/TableComponent/FilterPart'
import TableCell from 'components/TableComponent/TableCell'
import T from 'components/TableComponent/TableComponent.styles'
import { TableCellConfig } from 'components/TableComponent/TableComponent.types'
import TableTitle from 'components/TableComponent/TableTitle'
import TwoInfoOneCell from 'components/TableComponent/TwoInfoOneCell/TwoInfoOneCell'
import TopInfo from 'components/TopInfo'
import TopInfoRow from 'components/TopInfo/TopInfoRow'
import useFetch, { useRefresh } from 'hooks/useFetch'
import useSearchFilter from 'hooks/useSearchFilter'
import {
  ADMIN_QUERY_DROPDOWN_LIST,
  APIAdminListData,
  AdminFilterListType,
  AdminListRowType,
} from 'pages/System/Admin/AdminList/AdminList.types'
import { AdminPopUp } from 'pages/System/Admin/AdminPopUp/AdminPopUp'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow } from 'themes/Shared.styles'
import { ADMIN_TYPE } from 'types/api/samples/adminWeb'
import {
  cn,
  parserForSearchWithTermsAndQueryName,
  parserForSearchWithTypeAndValue,
  removeUndefined,
  thousand,
} from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import S from './AdminList.styles'

const AdminListTableConfigs: TableCellConfig<AdminListRowType>[] = [
  { _type: 'default', _id: 'office', _label: '소속', _size: 110 },
  { _type: 'default', _id: 'info', _label: '관리자명/휴대폰 번호', _size: 280 },
  { _type: 'default', _id: 'role', _label: '관리자 유형', _size: 200 },
  { _type: 'default', _id: 'task', _label: '담당업무', _size: 576 },
]

// ** Parser : API response data -> table row data
const parserForAdminListTable = (
  response: DoubleDListWithPaging<APIAdminListData>
) => {
  const rebuild = response.list.map((row: APIAdminListData, index: number) => {
    const { id, role, office, name, phone, task, deletedAt, pwdRestRequestAt } =
      row
    return {
      id: id,
      office: office,
      info: { name: name, phone: parseToFormattedStr(phone, 'phone') },
      role: ADMIN_TYPE[role],
      task: task ?? '',
      disabled: !!deletedAt,
      pwdChangeRequest: !!pwdRestRequestAt,
    } as AdminListRowType
  })
  return {
    _list: rebuild,
    _cursor: disposeCursor(response.paging.cursor),
  }
}

interface AdminListProps {}
export const AdminList = (props: AdminListProps) => {
  const [tableHeaderOffset, set_tableHeaderOffset] = useState<number>(0)
  const [headerOffset, set_headerOffset] = useState<number>(0)
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>
  const topInfoRef = useRef() as MutableRefObject<HTMLDivElement>
  const [selectedId, set_selectedId] = useState<string>('')
  const [isPopUpOpen, set_isPopUpOpen] = useState<boolean>(false)

  const [stats] = useFetch<{ name: string; count: number }[]>(
    'user',
    `/admin/stats`
  )
  const adminDropdownList = useMemo(() => {
    if (stats) {
      return {
        ...{ 전체: '전체' },
        ...stats
          .filter((item) => item.name !== '전체')
          .reduce((prev, curr) => {
            prev[curr.name] = curr.name
            return prev
          }, {} as { [key in string]: string }),
      }
    }
    return { 전체: '전체' }
  }, [stats])

  const [refresh, callRefresh] = useRefresh()
  const navigate = useNavigate()

  const { searchParams, initialValues, handleSetLocation } = useSearchFilter(
    {
      filterList: { office: '' },
      queryList: ADMIN_QUERY_DROPDOWN_LIST,
    },
    ({ searchType, searchValue, office, ...rest }) => {
      return {
        office: office ? String(office) : '전체',
        ...parserForSearchWithTypeAndValue(searchType, searchValue),
      }
    },
    ({ queryName, terms = '', office, ...rest }) => {
      return removeUndefined({
        office: office === '전체' ? '' : office,
        ...parserForSearchWithTermsAndQueryName(terms, queryName),
        ...rest,
      })
    }
  )
  // query -> 백엔드 api url
  const apiURI = useMemo(() => {
    if (refresh) return ''
    const uri = `/admin/list?take=10${
      searchParams.toString() ? '&' + searchParams.toString() : ''
    }`
    return decodeURI(uri)
  }, [searchParams, refresh])

  useEffect(() => {
    if (topInfoRef.current && headerRef.current) {
      const headerSize =
        topInfoRef.current.getBoundingClientRect().height +
        headerRef.current.getBoundingClientRect().height
      set_headerOffset(topInfoRef.current.getBoundingClientRect().height)
      set_tableHeaderOffset(headerSize)
    }
  }, [])

  return (
    <ContentsBox _type="relative" _css={css``}>
      <S.Wrapper>
        <AdminPopUp
          _toastMsg="관리자가 등록되었습니다."
          _title="관리자 추가"
          _isOpen={isPopUpOpen}
          _handleIsOpen={(state) => set_isPopUpOpen(state)}
          _reload={callRefresh}
        />
        {/* 상단 Info */}
        <TopInfo ref={topInfoRef}>
          {stats
            ?.filter((item) => item.name === '전체')
            .map((item) => {
              return (
                <TopInfoRow
                  key={item.name}
                  _label={item.name}
                  _contents={`${thousand(item.count)}명`}
                />
              )
            })}
          {stats
            ?.filter((item) => item.name !== '전체')
            .map((item) => {
              return (
                <TopInfoRow
                  key={item.name}
                  _label={item.name}
                  _contents={`${thousand(item.count)}명`}
                />
              )
            })}
        </TopInfo>
        {/* 상단 타이틀 */}
        <TableTitle
          ref={headerRef}
          _css={css`
            top: ${headerOffset}px;
          `}
          _left={'관리자 리스트'}
          _right={
            <PrimaryButton
              onClick={() => set_isPopUpOpen(true)}
              _css={css`
                width: 160px;
                height: 40px;
              `}
            >
              관리자 추가
            </PrimaryButton>
          }
        />
        <FilterPart<AdminFilterListType>
          _value={initialValues}
          _placeholder="주문번호 또는 주문자명, 상품명 검색"
          _queryNameList={ADMIN_QUERY_DROPDOWN_LIST}
          _queryNameFormat={{ 전화번호: 'phone' }}
          _emitValue={(value) => {
            handleSetLocation(value)
          }}
          _FilterContents={({ _value, _emitValue, ...props }) => (
            <Dropdown.Underline
              _css={css`
                min-width: 120px;
              `}
              _autoWidth
              _value={_value?.office ? String(_value.office) : '전체'}
              _list={adminDropdownList}
              _emitValue={(value) => {
                if (_emitValue && _value)
                  _emitValue({
                    ..._value,
                    office: value ? String(value) : '',
                  })
              }}
            />
          )}
        />
        {/* 테이블 리스트 */}
        <RoundFillBox>
          <TableComponent<AdminListRowType>
            _type="user"
            _url={apiURI}
            _topOffset={tableHeaderOffset}
            _config={AdminListTableConfigs}
            _parser={parserForAdminListTable}
            _ListContent={({ _row, _index }) => {
              // _row : api -> parser 거쳐서  넘어온 값들
              const rowId = _row.id ? String(_row.id) : String(_index)
              return (
                <T.Row
                  className={cn({
                    active: selectedId === rowId,
                    disabled: _row['disabled'],
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    set_selectedId(rowId)
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation()
                    navigate(`/system/admin/${rowId}`)
                  }}
                >
                  {AdminListTableConfigs.map((item, columnIndex) => {
                    const columnKey = item._id
                    const columnValue = _row[item._id]
                    return (
                      <TableCell
                        key={'TableCell' + _row['id'] + columnKey}
                        _size={item._size}
                        _position="left"
                      >
                        {typeof columnValue === 'object' ? (
                          <TwoInfoOneCell
                            _icon={
                              _row['pwdChangeRequest'] ? <IconsUnlock /> : <></>
                            }
                            _topData={columnValue.name}
                            _bottomData={columnValue.phone}
                          />
                        ) : columnKey === 'office' ? (
                          <>
                            {_row['disabled'] ? (
                              <Badge
                                _textColor="Grayscale/Gray Default"
                                _bgColor="Grayscale/Gray Lighter"
                              >
                                비활성화
                              </Badge>
                            ) : (
                              <div>{columnValue}</div>
                            )}
                          </>
                        ) : columnKey === 'role' ? (
                          <FlexRow gap={4}>
                            {columnValue === '최고 관리자' && <IconsCrown />}
                            <div>{columnValue}</div>
                          </FlexRow>
                        ) : typeof columnValue === 'string' ? (
                          <div>{columnValue}</div>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    )
                  })}
                </T.Row>
              )
            }}
          />
        </RoundFillBox>
      </S.Wrapper>
    </ContentsBox>
  )
}

export default AdminList
