import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const FunnelStyle = {
  Wrap: styled.div`
    ${addCssProps}
  `,
  Header: styled.div`
    padding: 0 32px;
    height: 75px;
    border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      ${typography['Display/1 Bold']}
      color: ${colors['Grayscale/Black light']};
    }
    button {
      width: 160px;
      height: 40px;
    }
  `,
  Content: styled.div`
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .box-row {
      width: 100%;
      height: 480px;
      & > div {
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: 100%;
        width: 100%;
        flex: 1;
        padding: 16px;
        border: 1px solid ${colors['Grayscale/Gray Lighter']};
        border-radius: 16px;
        &:last-of-type {
          justify-content: space-between;
        }
      }
    }
  `,
  ClassifyHeader: styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    h2 {
      ${typography['Menu/Bold']}
      color: ${colors['Grayscale/Gray Default']};
    }
    .count {
      ${typography['Body/Caption/Bold']}
      color: ${colors['Primary/Default']};
    }
  `,
  BoxLeftHeader: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 84px;
      height: 40px;
    }
  `,
  BoxLeftContent: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    flex: 1;
    gap: 12px;
    padding: 12px 0;
    & > button {
      text-align: left;
      &:focus {
        outline: none;
      }
      ${typography['Title/Bold']}
      color: ${colors['Grayscale/Black light']};
      padding: 16px;
      border-radius: 16px;
      box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
      &.active {
        background-color: ${colors['Primary/Lighter']};
        box-shadow: inset 0px 0px 0px 2px ${colors['Primary/Default']};
      }
      &:hover {
        background-color: ${colors['Grayscale/Background Dark']};
      }
      &:disabled {
        cursor: default;
        * {
          color: ${colors['Grayscale/Gray Lighter']};
        }
      }
    }
  `,
  BoxRightButtons: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    & > button {
      &:first-of-type {
        width: 160px;
        height: 48px;
      }
      &:last-of-type {
        flex: 1 0;
        height: 48px;
      }
    }
  `,
  NotFountList: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${typography['Body/Large/Bold']}
    color: ${colors['Grayscale/Gray Light']};
  `,
}

export default FunnelStyle
