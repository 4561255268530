import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'

const PeriodDataBoxStyle = {
  Wrapper: styled.div`
    flex: 1 1 160px;
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  Label: styled.div`
    height: 46px;
    padding: 13px;
    ${typography['Body/Small/Bold']};
    color: ${colors['Grayscale/Gray Default']};
  `,
  GrayRoundWrapper: styled.div`
    & {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      padding: 12px 24px;
      width: 100%;
      height: 52px;
      ${typography['Title/Bold']};
      color: ${colors['Primary/Default']};
      background-color: ${colors['Grayscale/Background Light']};
      border-radius: 12px;
    }
    span {
      ${typography['Body/Caption/Bold']};
      color: ${colors['Grayscale/Gray Default']};
    }
  `,
}

export default PeriodDataBoxStyle
