import styled from '@emotion/styled/macro'
import { buttonAssets } from 'components/Buttons/Buttons.styles'
import Textarea from 'components/Textarea'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

export const BoxTextarea = styled(Textarea)`
  border: none;
  min-height: 120px;
  padding: 13px 0px;
`
const AddEstimatesStyle = {
  Label: styled.label`
    input {
      display: none;
      visibility: hidden;
    }
    ${addCssProps}
  `,
  Upload: styled.div`
    border-radius: 50px;
    width: 86px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${buttonAssets({
      _mini: true,
      disabledType: 'fill',
      borderColor: 'Primary/Default',
      backgroundColor: 'White/White off',
      hoverBackgroundColor: 'Grayscale/Background Dark',
      textColor: 'Primary/Default',
    })}
  `,
  Files: styled.div`
    max-width: calc(100% - 110px);
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    gap: 12px;
    ${addCssProps}
  `,
  File: styled.div`
    position: relative;
    ${typography['Body/Large/Underline bold']}
    color: ${colors['Primary/Default']};
    border-bottom: 1px solid ${colors['Primary/Default']};
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
}

export default AddEstimatesStyle
