import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import { ContentRadiusBox } from 'themes/Shared.styles'
import { thousand } from 'utils/helpers'
import { CouponCodeCountProps } from './CouponCodeCount.types'
const CouponCodeCount = ({
  _css,
  inputs,
  handleInput,
  ...props
}: CouponCodeCountProps) => {
  return (
    <BoxLayout _title={'발급 개수'}>
      <ContentRadiusBox>
        <BoxRowComponent
          _label={'사용 / 발급'}
          _contents={`${inputs.usedCount ? thousand(inputs.usedCount) : 0} / ${
            inputs.issuedCount ? thousand(inputs.issuedCount) : 0
          } 개`}
        />
      </ContentRadiusBox>
    </BoxLayout>
  )
}
export default CouponCodeCount
