import { createSlice } from '@reduxjs/toolkit'
import { PageAuthority } from 'types/api/samples/adminWeb'
import { RootState } from '.'

export type TokenType = {
  accessToken: string
  accessTokenExpiresIn: string
  refreshToken: string
  refreshTokenExpiresIn: string
}

export type AuthStateType = 'authorized' | 'unauthorized' | 'idle'
export type UserInfoType = {
  id: string
  name: string
  office: string
  position: string | null
  role: string
  authority: PageAuthority[]
}
export type UserRole = 'super-admin' | 'admin'
interface authState {
  status: AuthStateType
  token: TokenType | null
  imp_uid: string
  userInfo: UserInfoType | null
}
const initialState: authState = {
  status: 'idle',
  token: null,
  imp_uid: '',
  userInfo: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCertification: (state, action: { payload: string }) => {
      state.imp_uid = action.payload
    },
    setUserInfo: (state, action: { payload: UserInfoType }) => {
      if (state.status === 'authorized') {
        state.userInfo = action.payload
      }
    },
    cleanUserInfo: (state) => {
      state.userInfo = null
    },
    setAuth: (
      state,
      action: {
        payload: {
          status: AuthStateType
          token?: TokenType | null
          isSuperAdmin?: boolean
        }
      }
    ) => {
      state.status = action.payload.status
      if (action.payload.token !== undefined) {
        state.token = action.payload.token
      }
    },
    cleanAuth: (state) => {
      state.token = null
      state.status = 'idle'
      state.userInfo = null
    },
  },
})
export const { setAuth, cleanAuth, setCertification, setUserInfo } =
  authSlice.actions

export const selectAuth = (state: RootState) => state.auth
export const isSuperAdmin = (state: RootState) =>
  state.auth.userInfo?.role === 'MASTER'

export default authSlice.reducer
