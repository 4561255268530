import { css } from '@emotion/react'
import { PrimaryButton } from 'components/Buttons'
import DraggablePopup from 'components/DraggablePopup'
import Dropdown from 'components/Dropdown'
import { DropdownListType } from 'components/Dropdown/Dropdown.types'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import { isEqual } from 'lodash-es'
import { SettlementRatios } from 'pages/Work/Work.types'
import AlertRow from 'pages/Work/components/AlertRow'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setToast } from 'store/toastSlice'
import { FlexColumn, FlexRow } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import { thousand } from 'utils/helpers'
import S from './ModifySettlementRatio.styles'
import { ModifySettlementRatioProps } from './ModifySettlementRatio.types'

export const percents = Array.from(Array(101).keys()).map((i) => i)
export const percentList = percents.reduce((prev, curr) => {
  prev[curr + '%'] = curr === 0 ? '0' : curr
  return prev
}, {} as DropdownListType)
const ModifySettlementRatio = ({
  _css,
  _items,
  _onClose,
  _emitValue,
  ...props
}: ModifySettlementRatioProps) => {
  const dispatch = useDispatch()
  const [settlementRatios, set_settlementRatios] = useState<SettlementRatios[]>(
    []
  )
  const [existSettlementRatios, set_existSettlementRatios] = useState<
    SettlementRatios[]
  >([])
  const checkEqual = (before: SettlementRatios, next: SettlementRatios) => {
    return isEqual(before, next)
  }
  useEffect(() => {
    set_settlementRatios(_items)
    set_existSettlementRatios(_items)
  }, [_items])
  const totalAmount = useMemo(() => {
    const techsAmount = settlementRatios.reduce((prev, curr) => {
      prev += curr.amount ? Number(curr.amount) : 0
      return prev
    }, 0)
    const totalAmount = settlementRatios.reduce((prev, curr) => {
      prev += curr.originalTaskAmount ? Number(curr.originalTaskAmount) : 0
      return prev
    }, 0)
    return {
      amount: totalAmount,
      serviceAmount: techsAmount,
      susudaAmount: totalAmount - techsAmount,
    }
  }, [settlementRatios])
  const handleSubmit = async (item: SettlementRatios) => {
    try {
      await callAxios('task').patch(`/settlement/log/${item.id}`, {
        settlementRatio: item.ratio,
        settlementWages: item.amount,
      })
      dispatch(
        setToast({
          message: '수수료가 확정되었습니다.',
          type: 'success',
        })
      )
      set_existSettlementRatios(settlementRatios)
      _emitValue(settlementRatios)
    } catch (error) {
      handleError(error)
    }
  }
  return (
    <>
      <DraggablePopup
        _title={'숙련공 정산비율 수정'}
        _open={true}
        _onClose={_onClose}
        _css={css`
          width: 930px;
        `}
      >
        <FlexColumn
          gap={12}
          _css={css`
            padding: 32px;
          `}
        >
          <AlertRow className="flex-start">
            시공 시간은 숙련공이 앱에서 직접 버튼을 통해 측정된 시간이므로, 실제
            일한 시간과 차이가 날 수 있습니다.
            <br />
            정산 시 참고용으로만 활용하시기 바랍니다.
          </AlertRow>
          <FlexRow gap={8} width="auto">
            <FlexRow gap={4}>
              <S.Label>총 공임료</S.Label>
              <S.Price>{thousand(totalAmount.amount)}원</S.Price>
            </FlexRow>
            <S.Divide />
            <FlexRow gap={4}>
              <S.Label>숙련공 공임비</S.Label>
              <S.Price>{thousand(totalAmount.serviceAmount)}원</S.Price>
            </FlexRow>
            <S.Divide />
            <FlexRow gap={4}>
              <S.Label>수수다 매출액</S.Label>
              <S.Price>{thousand(totalAmount.susudaAmount)}원</S.Price>
            </FlexRow>
          </FlexRow>
          <S.SeparatedTable>
            <FlexRow gap={12}>
              <FlexRow
                gap={0}
                className="thead"
                justifyContent={'space-between'}
              >
                <div>이름</div>
                <div>소속</div>
                <div>시간</div>
                <div>휴무 여부</div>
                <div>정산 비율</div>
                <div>정산 금액</div>
              </FlexRow>
              <div style={{ width: '120px', minWidth: '120px', flexShrink: 0 }}>
                {' '}
              </div>
            </FlexRow>
            <FlexColumn gap={12} className="tbody">
              {settlementRatios.map((item, itemIndex) => {
                return (
                  <FlexRow gap={12}>
                    <FlexRow
                      className="tr"
                      gap={0}
                      key={'modify' + item.id}
                      justifyContent={'space-between'}
                    >
                      <div>{item.name}</div>
                      <div>{item.company}</div>
                      <div>{item.serviceTime}시간</div>
                      <div>{item.dutyState}</div>
                      <Dropdown.Underline
                        _list={percentList}
                        _value={item.ratio}
                        _emitValue={(value) => {
                          set_settlementRatios((prev) => {
                            const cloned = prev.map((curr) =>
                              curr.id === item.id
                                ? {
                                    ...curr,
                                    ratio: !isNaN(Number(value))
                                      ? Number(value)
                                      : 0,
                                    amount: !isNaN(Number(value))
                                      ? (Number(value) / 100) *
                                        item.originalTaskAmount
                                      : item.amount,
                                  }
                                : curr
                            )
                            return cloned
                          })
                        }}
                        _css={css`
                          width: 120px;
                          min-width: 120px;
                        `}
                      />
                      <div>
                        <UnderlineNumberInput
                          value={item.amount}
                          suffix="원"
                          _css={css`
                            input {
                              text-align: right;
                            }
                          `}
                          handleValue={(value) => {
                            set_settlementRatios((prev) => {
                              return prev.map((data) =>
                                data.id === item.id
                                  ? { ...data, amount: value }
                                  : data
                              )
                            })
                          }}
                        />
                      </div>
                    </FlexRow>
                    <div>
                      <PrimaryButton
                        _css={css`
                          width: 120px;
                          min-width: 120px;
                          padding: 12px 20px;
                        `}
                        onClick={() => {
                          handleSubmit(item)
                        }}
                        disabled={checkEqual(
                          item,
                          existSettlementRatios[itemIndex]
                        )}
                      >
                        완료
                      </PrimaryButton>
                    </div>
                  </FlexRow>
                )
              })}
            </FlexColumn>
          </S.SeparatedTable>
        </FlexColumn>
      </DraggablePopup>
    </>
  )
}
export default ModifySettlementRatio
