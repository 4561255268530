import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const SelectMaterialPopupStyle = {
  Wrap: styled.div`
    padding: 32px 12px 0px;
    height: 432px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;
    ${addCssProps}
  `,
  RowList: styled.div`
    padding: 0 12px 0px;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  MaterialRow: styled.div`
    width: 100%;
    box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
    border-radius: 16px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    background-color: transparent;
    &.dragging {
      opacity: 0.4;
      * {
        opacity: 0.4;
      }
    }
    .img {
      border-radius: 8px;
      width: 40px;
      height: 40px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .box {
      flex: 1 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16;
      .title {
        flex: 1;
        ${typography['Title/Bold']}
        color: ${colors['Grayscale/Black light']};
      }
      .option {
        ${typography['Body/Caption/Regular']}
        color: ${colors['Grayscale/Gray Default']};
      }
    }
    &:hover:not(.draggable) {
      background-color: ${colors['Grayscale/Background Dark']};
    }
    &.active {
      background-color: ${colors['Primary/Lighter']};
      box-shadow: inset 0px 0px 0px 2px ${colors['Primary/Default']};
    }
    &:disabled {
      cursor: default;
      * {
        color: ${colors['Grayscale/Gray Lighter']};
      }
    }
  `,
}

export default SelectMaterialPopupStyle
