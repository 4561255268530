import styled from '@emotion/styled/macro'
import { addCssProps } from 'themes/styles.helper'

const AddHolidayStyle = {
  Wrap: styled.div`
    padding: 32px;
    ${addCssProps}
  `,
}

export default AddHolidayStyle
