import { css } from '@emotion/react'
import { GrayScaleFill, PrimaryButton } from 'components/Buttons'
import DraggablePopup from 'components/DraggablePopup'
import { useAppDispatch, useShallowSelector } from 'hooks/reduxHooks'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import { APIAdminDetailData } from 'pages/System/Admin/AdminDetails/AdminDetails.types'
import AddAdminForm from 'pages/System/Admin/AdminPopUp/AddAdminForm'
import {
  ADMIN_INFO_INITIAL_VALUE,
  APIAdminCreateData,
  AuthSettingCategory,
  DetailAuthSettingPageData,
  SETTING_OPTIONS,
} from 'pages/System/Admin/AdminPopUp/AdminPopUp.types'
import AuthoritySetForm from 'pages/System/Admin/AdminPopUp/AuthoritySetForm'
import { useEffect, useState } from 'react'
import { store } from 'store'
import { selectAuth } from 'store/authSlice'
import { setError } from 'store/errorSlice'
import { setToast } from 'store/toastSlice'
import { AbilityItem, PageAuthority } from 'types/api/samples/adminWeb'
import { PWD_NOT_CHANGED } from 'types/share'
import callAxios, { handleError } from 'utils/callAxios'
import { checkPwdRegex } from 'utils/helpers'

// 진입 권한 설정 form data(프론트) -> AbilityItem[](백) 형태로 Parse
const parseAuthInputToAbility = (settings: DetailAuthSettingPageData) => {
  let result: AbilityItem[] = []
  for (const [, value] of Object.entries(settings)) {
    const valueWithoutAll = value.filter((item) => item.code !== 'all')
    const abilities = valueWithoutAll.map((item) => {
      return {
        page: item.code,
        permission: item.active,
      }
    }) as AbilityItem[]
    result = result.concat(abilities)
  }
  return result
}

//  PageAuthority[](백) -> 진입 권한 설정 form data(프론트) 형태로 parse
export const parseAbilityToAuthInput = (pageAuthority: PageAuthority[]) => {
  const initPageAuthority: DetailAuthSettingPageData = { ...SETTING_OPTIONS }
  for (const [key] of Object.entries(initPageAuthority)) {
    const typedKey = key as AuthSettingCategory

    initPageAuthority[typedKey] = initPageAuthority[typedKey].map((item) => {
      if (item.code === 'all') return { ...item, active: false }
      if (!pageAuthority.includes(item.code)) {
        return { ...item, active: false }
      }
      return { ...item }
    })
  }
  return initPageAuthority
}
export interface AdminPopUpProps {
  _isOpen: boolean
  _handleIsOpen: (state: boolean) => void
  _title: string
  _toastMsg: string
  _detailInfo?: APIAdminDetailData | null
  _reload: () => void
}
export const AdminPopUp = ({
  _toastMsg,
  _title,
  _handleIsOpen,
  _isOpen,
  _detailInfo,
  _reload,
  ...props
}: AdminPopUpProps) => {
  const formMatchInput = useMatchInput(ADMIN_INFO_INITIAL_VALUE)
  const authMatchInput = useMatchInput(SETTING_OPTIONS)
  const [isOpenAuthoritySet, set_isOpenAuthoritySet] = useState<boolean>(false)
  const emptyCheck = useEmptyCheck(formMatchInput.inputs, 'position', 'task')
  const dispatch = useAppDispatch()
  const [existAuthRes] = useFetch<PageAuthority[]>(
    'user',
    `/admin/${_detailInfo?.id}/ability`,
    {
      disabled: !_detailInfo,
    }
  )

  // 프론트 단에서 validation 체크 용 함수
  const handleFormValidation = (value: APIAdminCreateData) => {
    if (value.office && value.office.length > 20) {
      store.dispatch(setError('소속은 20자 이하입니다.'))
      return false
    }
    if (
      !checkPwdRegex(value.pwd, 'password') &&
      value.pwd !== PWD_NOT_CHANGED
    ) {
      store.dispatch(
        setError('비밀번호는 8자리 이상의 영문,숫자,특수문자 조합입니다.')
      )
      return false
    }
    if (!checkPwdRegex(value.phone, 'phone')) {
      store.dispatch(setError('휴대폰 번호를 확인하세요.'))
      return false
    }
    if (!checkPwdRegex(value.userName, 'id')) {
      store.dispatch(setError('아이디는 6~20자 영소문자, 숫자입니다.'))
      return false
    }
    return true
  }

  // 세부 권한 설정 선택 시 -> formMatchInput 업데이트
  const handleSetAuthSetting = () => {
    const ability = parseAuthInputToAbility(authMatchInput.inputs)
    formMatchInput.handleInput('ability', ability)
  }
  // api : create
  const handleCreateSubmit = async () => {
    if (handleFormValidation(formMatchInput.inputs)) {
      try {
        const res = await callAxios('user').post<DoubleDResponse<string>>(
          '/admin',
          formMatchInput.inputs
        )
        if (res.data.success) {
          dispatch(
            setToast({
              message: _toastMsg,
              type: 'success',
            })
          )
        }
        closePopUp()
      } catch (e) {
        handleError(e)
      }
    }
  }
  // api : edit
  const handleEditSubmit = async () => {
    if (handleFormValidation({ ...formMatchInput.inputs }) && _detailInfo) {
      const { pwd, ...rest } = formMatchInput.inputs
      const patchBody =
        formMatchInput.inputs.pwd === PWD_NOT_CHANGED
          ? rest
          : { ...rest, pwd: pwd }
      try {
        const res = await callAxios('user').patch(
          `admin/${_detailInfo.id}`,
          patchBody
        )
        if (res.data.success) {
          dispatch(
            setToast({
              message: _toastMsg,
              type: 'success',
            })
          )
        }
        closePopUp()
      } catch (e) {
        handleError(e)
      }
    }
  }

  // 팝업 닫기
  const closePopUp = () => {
    formMatchInput.handleValues(ADMIN_INFO_INITIAL_VALUE)
    _handleIsOpen(false)
  }

  // 수정화면 일때 유저 정보 세팅
  useEffect(() => {
    if (_isOpen && _detailInfo && existAuthRes) {
      const formFetchInput = formMatchInput.fetchInit
      const authFetchInput = authMatchInput.fetchInit
      const existAuthMatchInput = parseAbilityToAuthInput(existAuthRes)
      const existingFormMatchInput: APIAdminCreateData = {
        name: _detailInfo.name,
        phone: _detailInfo.phone,
        userName: _detailInfo.userName,
        pwd: PWD_NOT_CHANGED,
        role: _detailInfo.role,
        office: _detailInfo.office,
        ability: parseAuthInputToAbility(existAuthMatchInput),
        position: _detailInfo.position,
        task: _detailInfo.task,
        isMaterial: _detailInfo.isMaterial,
      }
      formFetchInput(existingFormMatchInput)
      authFetchInput(existAuthMatchInput)
    }
  }, [
    _detailInfo,
    existAuthRes,
    _isOpen,
    formMatchInput.fetchInit,
    authMatchInput.fetchInit,
  ])
  const userAuth = useShallowSelector(selectAuth)
  return (
    <>
      {!isOpenAuthoritySet ? (
        <DraggablePopup
          _title={_title}
          _open={_isOpen}
          _onClose={closePopUp}
          _css={css``}
          _bottom={
            <>
              <GrayScaleFill onClick={closePopUp}>취소</GrayScaleFill>
              <PrimaryButton
                disabled={_detailInfo ? formMatchInput.isMatched : emptyCheck}
                onClick={async () => {
                  _detailInfo
                    ? await handleEditSubmit()
                    : await handleCreateSubmit()
                  if (
                    userAuth?.userInfo &&
                    userAuth.userInfo.id === _detailInfo?.id
                  ) {
                    window.location.reload()
                  } else {
                    _reload()
                  }
                }}
              >
                저장
              </PrimaryButton>
            </>
          }
        >
          <AddAdminForm
            {...formMatchInput}
            _isEdit={!!_detailInfo}
            _openAuthoritySet={() => set_isOpenAuthoritySet(true)}
          />
        </DraggablePopup>
      ) : (
        <DraggablePopup
          _title={'세부 권한 설정'}
          _open={_isOpen}
          _onClose={() => set_isOpenAuthoritySet(false)}
          _css={css`
            width: 650px;
            button {
              box-shadow: none;
              border-radius: 50px;
              height: 44px;
            }
          `}
          _bottom={
            <>
              <GrayScaleFill
                onClick={() => {
                  authMatchInput.returnToOriginal()
                  set_isOpenAuthoritySet(false)
                }}
              >
                취소
              </GrayScaleFill>
              <PrimaryButton
                onClick={() => {
                  handleSetAuthSetting()
                  set_isOpenAuthoritySet(false)
                }}
              >
                선택
              </PrimaryButton>
            </>
          }
        >
          <AuthoritySetForm {...authMatchInput} />
        </DraggablePopup>
      )}
    </>
  )
}
