import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const Thumbnail = css`
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 12px;
  max-width: 100%;
  box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Light']};
  &.active {
    box-shadow: inset 0px 0px 0px 3px #fff;
  }
  position: relative;
  &:hover::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: 12px;
    background-color: ${colors['Grayscale/Black light']};
    opacity: 0.4;
  }
  &.add {
    box-shadow: none;
  }
`
const GalleryStyle = {
  Wrap: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background-color: ${colors['Grayscale/Black light']};
    display: flex;
    flex-direction: column;
    gap: 0px;
    color: #fff;
    ${addCssProps}
  `,
  Header: styled.div`
    height: 92px;
    width: 100%;
    padding: 0 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 24px;
      height: 24px;
    }
    gap: 8px;
    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      .title {
        ${typography['Display/3 Bold']}
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .count {
        margin-left: 12px;
        ${typography['Headline/Regular']}
        height: 42px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 2px;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
    }
  `,
  GalleryButton: styled.button`
    cursor: pointer;
    background-color: transparent;
    padding: 8px 26px;
    ${typography['Body/Large/Bold']}
    color: ${colors['White/White off']};
    border-radius: 12px;
    transition: 0.3s;
    &:hover {
      background-color: ${colors['White/White 10%']};
    }
    &:disabled {
      color: ${colors['Grayscale/Gray Dark']};
    }
  `,
  Contents: styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      height: 100%;
    }
    .left {
      width: 276px;
      flex-shrink: 0;
    }
    .center {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;
      max-width: calc(100% - 552px);
    }
    .right {
      width: 276px;
      flex-shrink: 0;
    }
  `,
  List: styled.div`
    position: absolute;
    top: 100px;
    left: 48px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    ${typography['Headline/Bold']}
    user-select: none;
    * {
      transition: 0.15s;
    }
  `,
  GroupItem: styled.div`
    color: ${colors['Grayscale/Gray Light']};
    &:hover,
    &.active {
      color: ${colors['White/White off']};
    }
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      min-width: 112px;
      &.active {
        color: ${colors['White/White off']};
      }
    }
    .click-able {
      cursor: pointer;
    }
  `,
  ChildItem: styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 12px;
    .child-box {
      display: flex;
      flex-direction: column;
      gap: 15px;
      &:hover,
      &.active {
        .child-title {
          color: ${colors['White/White off']};
        }
      }
    }
    .child-title {
      display: flex;
      align-items: center;
      gap: 12px;
      &:hover,
      &.active {
        color: ${colors['White/White off']};
      }
    }
    & > div {
      padding: 2px 0;
      ${typography['Body/Large/Bold']}
      color: ${colors['Grayscale/Gray Light']};
    }
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: ${colors['White/White off']};
    }
    .child-child {
      padding-left: 16px;
      &:hover,
      &.active {
        color: ${colors['White/White off']};
      }
    }
  `,
  ImageBox: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .prev {
      width: 144px;
      flex-shrink: 0;
    }
    .box {
      flex: 1;
      position: relative;
    }
    .no-image {
      background-color: ${colors['White/White 10%']};
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      ${typography['Body/Large/Bold']}
      color: ${colors['White/White off']};
    }
    .next {
      width: 144px;
      flex-shrink: 0;
    }
    .nav-box {
      cursor: pointer;
      &.none {
        opacity: 0.4;
      }
      width: 100%;
      height: 144px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
  Thumbnails: styled.div`
    flex-shrink: 0;
    width: 100%;
    height: 165px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors['Grayscale/Gray Lighter']};
      border-radius: 15px;
      border: 10px solid ${colors['Grayscale/Gray Lighter']};
      background-clip: padding-box;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${colors['Grayscale/Gray Lighter']};
      border-radius: 15px;
      border: 10px solid ${colors['Grayscale/Gray Lighter']};
      background-clip: padding-box;
    }
    margin-bottom: 16px;
    .thumbnail {
      ${Thumbnail}
    }
  `,
}

export default GalleryStyle
