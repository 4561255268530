import DetailsComponents, { ToList } from 'components/DetailsComponents'
import ST from './SettlementForPartnerDetails.styles'
import {
  ApiPartnerSettlementDetails,
  PARTNER_SETTLEMENT_INFO_INITIAL_VALUE,
  SettlementForPartnerDetailsProps,
} from './SettlementForPartnerDetails.types'

import { css } from '@emotion/react'
import { ImagesDetailsChevronRightBlack, ImagesDetailsProfile } from 'assets'
import Badge from 'components/Badge'
import { BADGE_COLOR } from 'components/Badge/Badge'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import OrderIcon from 'components/OrderIcon'
import { TD, TH } from 'components/TableComponent/TableComponent'
import { toggleOrder } from 'components/TableComponent/TableComponent.helpers'
import dayjs from 'dayjs'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import LoadingPage from 'layout/LoadingPage'
import { orderBy } from 'lodash-es'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import {
  ContentBoxWrap,
  ContentProfileBox,
  ContentRadiusBox,
  Divider,
  Flex,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { cn, thousand } from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import AddRecommenderPopup from '../components/AddRecommenderPopup'

const SettlementForPartnerDetails = ({
  _css,
  _type,
  _id,
  ...props
}: SettlementForPartnerDetailsProps) => {
  const navigate = useNavigate()
  const { Layout: L } = S
  const [response, isError] = useFetch<ApiPartnerSettlementDetails>(
    'task',
    `/settlement/partner/${_id}`,
    {
      disabled: !_id,
    }
  )
  const { inputs, fetchInit } = useMatchInput(
    PARTNER_SETTLEMENT_INFO_INITIAL_VALUE
  )
  const [referralOrderBy, set_referralOrderBy] = useState<
    'ASC' | 'DESC' | 'INITIAL'
  >('INITIAL')
  const [taskOrderBy, set_taskOrderBy] = useState<'ASC' | 'DESC' | 'INITIAL'>(
    'INITIAL'
  )
  useEffect(() => {
    if (response) fetchInit(response)
  }, [response, fetchInit])

  const [addRecommenderOpen, set_addRecommenderOpen] = useState<boolean>(false)

  if (!response) return <LoadingPage />

  if (isError) return <Navigate to={`/404?from=${window.location.pathname}`} />
  return (
    <>
      {addRecommenderOpen && _type === 'queue' && (
        <AddRecommenderPopup _onClose={() => set_addRecommenderOpen(false)} />
      )}
      <DetailsComponents
        _title={`파트너 정산 ${_type === 'history' ? '내역' : '대기'} 상세`}
        _topLeftControls={
          <L.ButtonRow>
            <ToList _to={`/settlement/partner/${_type}`} />
          </L.ButtonRow>
        }
        _leftContents={
          <ContentBoxWrap>
            <ContentProfileBox>
              <div className="wrap">
                <FlexColumn gap={12} alignItems="center">
                  <div
                    data-profile
                    style={{
                      backgroundImage: `url(${ImagesDetailsProfile})`,
                    }}
                  />
                  <div>
                    <div
                      className="name with-link"
                      onClick={() => {
                        navigate(`/member/partner/${inputs.partnerId}`)
                      }}
                    >
                      {inputs.partnerName}
                      <img src={ImagesDetailsChevronRightBlack} alt="right" />
                    </div>
                    <div className="phone">
                      {parseToFormattedStr(inputs.partnerPhone, 'phone')}
                    </div>
                    <FlexRow
                      gap={4}
                      className="badges"
                      justifyContent={'center'}
                    >
                      <Badge {...BADGE_COLOR.AccentLight}>파트너</Badge>
                    </FlexRow>
                  </div>
                </FlexColumn>
              </div>
            </ContentProfileBox>
            <Flex
              flexDirection="column"
              justifyContent={'center'}
              alignItems={'center'}
              _css={css`
                margin-top: 16px;
              `}
              gap={18}
            >
              {_type === 'queue' && (
                <>
                  <TXT _textStyle="Headline/Bold" _color="Grayscale/Black">
                    지급 정보
                  </TXT>
                </>
              )}
              {_type === 'history' && (
                <ST.CalendarHeader>
                  <FlexRow gap={12}>
                    {/* <GrayScaleOutline
                      _css={css`
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                      `}
                      onClick={() => {
                        set_currentMonth((prev) => prev.subtract(1, 'month'))
                      }}
                    >
                      <img src={ImagesDetailsChevronLeftSmall} alt="prev" />
                    </GrayScaleOutline> */}
                    <h1>{`${inputs.year}년 ${inputs.month}월`}</h1>
                    {/* <GrayScaleOutline
                      _css={css`
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                      `}
                      disabled={dayjs().diff(currentMonth, 'month') <= 0}
                      onClick={() => {
                        set_currentMonth((prev) => prev.add(1, 'month'))
                      }}
                    >
                      <img src={ImagesDetailsChevronRightSmall} alt="next" />
                    </GrayScaleOutline> */}
                  </FlexRow>
                </ST.CalendarHeader>
              )}
              <FlexColumn gap={12}>
                <ContentRadiusBox>
                  <BoxRowComponent _label={'계좌 정보'}>
                    {inputs.accountNo}
                  </BoxRowComponent>
                  <BoxRowComponent _label={'이메일'}>
                    {inputs.partnerEmail}
                  </BoxRowComponent>
                </ContentRadiusBox>
                <ContentRadiusBox>
                  <BoxRowComponent _label={'소개 수수료 한도'}>
                    {thousand(inputs.referralFee)}원
                  </BoxRowComponent>
                  <BoxRowComponent _label={'정산수수료'}>
                    {inputs.settlementFee}%
                  </BoxRowComponent>
                </ContentRadiusBox>
                <ContentRadiusBox
                  _css={css`
                    margin-top: 6px;
                  `}
                >
                  <BoxRowComponent _label={'소개 수수료'}>
                    {thousand(inputs.calcInfo.referralWages)}원
                  </BoxRowComponent>
                  <BoxRowComponent _label={'정기회원 이용 수수료'}>
                    {thousand(inputs.calcInfo.taskWages)}원
                  </BoxRowComponent>
                  <Divider
                    _css={css`
                      margin-bottom: 8px;
                    `}
                  />
                  <BoxRowComponent
                    _label={<S.TotalLabel>지급 총액</S.TotalLabel>}
                  >
                    <S.TotalPrice>
                      {thousand(inputs.calcInfo.totalWages)}원
                    </S.TotalPrice>
                  </BoxRowComponent>
                  {_type === 'history' && inputs.finishedAt && (
                    <Flex justifyContent={'right'}>
                      <Badge {...BADGE_COLOR['Gray']}>
                        {dayjs(inputs.finishedAt).format('YYYY-MM-DD')} 정산완료
                      </Badge>
                    </Flex>
                  )}
                </ContentRadiusBox>
              </FlexColumn>
            </Flex>
          </ContentBoxWrap>
        }
        _rightContents={
          <ContentBoxWrap
            _css={css`
              gap: 12px;
              & > div {
                flex: 1;
                max-height: calc(50% - 12px);
                min-height: calc(50% - 12px);
              }
            `}
          >
            <BoxLayout
              _title={'정기회원 소개 내역'}
              _css={css`
                display: flex;
                flex-direction: column;
                gap: 0px;
                & > div:last-of-type {
                  flex: 1;
                }
              `}
            >
              <ST.MiniTableWrap>
                <ST.MiniTable
                  className={cn(
                    {
                      'not-found': inputs.referralInfo.length === 0,
                    },
                    'house'
                  )}
                  _css={css`
                    th,
                    td {
                      &:first-of-type {
                        & > div {
                          padding-left: 20px;
                          margin-left: 0px;
                        }
                      }
                      &:last-of-type {
                        & > div {
                          margin-right: 8px;
                        }
                        /* text-align: right; */
                      }
                    }
                  `}
                >
                  <thead
                    style={{
                      position: 'sticky',
                      top: '0px',
                    }}
                  >
                    <tr>
                      <TH>회원명</TH>
                      <TH>전화번호</TH>
                      <TH>소개 수수료</TH>
                      <TH>
                        <FlexRow
                          gap={4}
                          justifyContent="flex-start"
                          onClick={() => {
                            set_referralOrderBy((prev) => toggleOrder(prev))
                          }}
                        >
                          등록일
                          <OrderIcon _order={referralOrderBy} />
                        </FlexRow>
                      </TH>
                    </tr>
                  </thead>
                  <tbody>
                    {inputs.referralInfo.length > 0 ? (
                      <>
                        {orderBy(
                          inputs.referralInfo,
                          (value) => value.createdAt,
                          referralOrderBy.toLowerCase() as 'asc' | 'desc'
                        ).map((item, itemIndex) => {
                          return (
                            <tr key={'recommender-list' + itemIndex}>
                              <TD>{item.userName}</TD>
                              <TD>
                                {parseToFormattedStr(item.userPhone, 'phone')}
                              </TD>
                              <TD>{thousand(item.referralWages)}원</TD>
                              <TD>
                                {item.createdAt
                                  ? dayjs(item.createdAt).format('YYYY-MM-DD')
                                  : '-'}
                              </TD>
                            </tr>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={4}>
                            <div>소개 내역이 없습니다</div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </ST.MiniTable>
              </ST.MiniTableWrap>
            </BoxLayout>
            <BoxLayout
              _title={'이용 내역'}
              _css={css`
                display: flex;
                flex-direction: column;
                gap: 0px;
                & > div:last-of-type {
                  flex: 1;
                }
              `}
            >
              <ST.MiniTableWrap>
                <ST.MiniTable
                  className={cn(
                    {
                      'not-found': inputs.taskInfo.length === 0,
                    },
                    'house'
                  )}
                >
                  <thead
                    style={{
                      position: 'sticky',
                      top: '0px',
                    }}
                  >
                    <tr>
                      <TH>건물명</TH>
                      <TH>서비스명</TH>
                      <TH>정산 금액</TH>
                      <TH>
                        <FlexRow
                          gap={4}
                          justifyContent="flex-start"
                          onClick={() => {
                            set_taskOrderBy((prev) => toggleOrder(prev))
                          }}
                        >
                          시공일
                          <OrderIcon _order={taskOrderBy} />
                        </FlexRow>
                      </TH>
                    </tr>
                  </thead>
                  <tbody>
                    {inputs.taskInfo.length > 0 ? (
                      <>
                        {orderBy(
                          inputs.taskInfo,
                          (value) => value.taskFinishedAt,
                          taskOrderBy.toLowerCase() as 'asc' | 'desc'
                        ).map((item, itemIndex) => {
                          return (
                            <tr key={'used-list' + itemIndex}>
                              <TD>{item.buildingName}</TD>
                              <TD>{item.serviceName}</TD>
                              <TD>{thousand(item.settlementWages)}원</TD>
                              <TD>
                                {item.taskFinishedAt
                                  ? dayjs(item.taskFinishedAt).format(
                                      'YYYY-MM-DD'
                                    )
                                  : '-'}
                              </TD>
                            </tr>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={4}>
                            <div>이용 내역이 없습니다</div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </ST.MiniTable>
              </ST.MiniTableWrap>
            </BoxLayout>
          </ContentBoxWrap>
        }
      />
    </>
  )
}
export default SettlementForPartnerDetails
