import { css } from '@emotion/react'
import { IconsClip, IconsNotiCircle } from 'assets'
import { PrimaryButton, PrimaryOutlineButton } from 'components/Buttons'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import DetailsDefault from 'components/DetailsComponents/DetailsComponents'
import CS from 'components/DetailsComponents/DetailsComponents.styles'
import FileUploadBox from 'components/FileUploadBox'
import PeriodPicker from 'components/PeriodPicker'
import Textarea from 'components/Textarea'
import UnderlineInput from 'components/UnderlineInput'
import dayjs from 'dayjs'
import useEmptyCheck from 'hooks/useEmptyCheck'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import { APIGetEventDetailsData } from 'pages/Support/Event/EventDetails/EventDetails.types'
import S from 'pages/Support/Event/EventPublish/EventPublish.styles'
import { APICreateEvent } from 'pages/Support/Event/EventPublish/EventPublish.types'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { DoubleDFile } from 'types/services.type'
import callAxios, { handleError } from 'utils/callAxios'
import { extractFileNameFromUrl } from 'utils/parsers'
import { callFileUpload } from 'utils/services'

export interface TestType {
  id: number
  code: string
  brand: string
  title: string
  article: string
  createdAt: number
  state: string
}

const EVENT_PUBLISH_INITIAL_VALUE: APICreateEvent = {
  startAt: '',
  endAt: '',
  popupBanner: '',
  thumbnailBanner: '',
  title: '',
  link: '',
}
interface EventPublishProps {}

const EventPublish = (props: EventPublishProps) => {
  const { id: userId } = useParams<'id'>()
  const { inputs, handleInput, fetchInit, isMatched } = useMatchInput(
    EVENT_PUBLISH_INITIAL_VALUE
  )
  const trueWhenEmpty = useEmptyCheck(inputs, 'notiTitle', 'notiContent')
  const [response, error] = useFetch<APIGetEventDetailsData>(
    'user',
    `/board/event/${userId}`,
    {
      disabled: !userId,
    }
  )
  // 프론트 화면에 파일 정보 보여주기 용 state
  const [bannerFile, set_bannerFile] = useState<DoubleDFile[]>()
  const [thumbnailFile, set_thumbnailFile] = useState<DoubleDFile[]>()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // api : 이벤트 생성
  const createNewEvent = async (body: APICreateEvent) => {
    try {
      await callAxios('user').post('/board/event', body)
      const message = '이벤트가 등록되었습니다.'
      dispatch(setToast({ message, type: 'success' }))
      navigate(`/customer/event`)
    } catch (e) {
      handleError(e)
    }
  }
  // api : 이벤트 수정
  const editEvent = async (body: APICreateEvent) => {
    if (!userId) return
    try {
      await callAxios('user').put(`/board/event/${userId}`, body)
      const message = '수정되었습니다.'
      dispatch(setToast({ message, type: 'success' }))
      navigate(`/customer/event/${userId}`)
    } catch (e) {
      handleError(e)
    }
  }
  // 이벤트 수정시 기존 정보 set
  useEffect(() => {
    if (error) return
    if (response) {
      const popupBannerName = extractFileNameFromUrl(response.popupBanner)
      const thumbnailBannerName = extractFileNameFromUrl(
        response.thumbnailBanner
      )
      let parsedInput: APICreateEvent = {
        startAt: response.startAt,
        endAt: response.endAt,
        popupBanner: popupBannerName,
        thumbnailBanner: thumbnailBannerName,
        title: response.title,
        link: response.link,
      }

      if (response.notiTitle) {
        parsedInput = {
          ...parsedInput,
          notiTitle: response.notiTitle,
        }
      }
      if (response.notiContent) {
        parsedInput = {
          ...parsedInput,
          notiContent: response.notiContent,
        }
      }
      set_bannerFile([
        {
          id: response.popupBanner,
          url: response.popupBanner,
          name: popupBannerName,
        },
      ])
      set_thumbnailFile([
        {
          id: response.thumbnailBanner,
          url: response.thumbnailBanner,
          name: thumbnailBannerName,
        },
      ])
      fetchInit(parsedInput)
    }
  }, [response, error, fetchInit])

  return (
    <>
      <DetailsDefault
        _css={css`
          .radius-box-gap {
            gap: 12px;
          }
          .left-box {
            overflow: visible;
            width: 597px;
          }
          #left-contents {
            overflow: visible;
            & > div {
              overflow: visible;
            }
          }
        `}
        _title={'이벤트 글쓰기'}
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout _title={'설정'}>
              <ContentRadiusBox>
                <BoxRowComponent _label={'알림 제목'}>
                  {!userId ? (
                    <UnderlineInput
                      readOnly={!!userId}
                      placeholder="제목 입력(40자 이내)"
                      value={inputs.notiTitle}
                      handleValue={(value) => handleInput('notiTitle', value)}
                    />
                  ) : (
                    <TXT
                      _css={css`
                        text-align: left !important;
                      `}
                    >
                      {inputs.notiTitle}
                    </TXT>
                  )}
                </BoxRowComponent>
                <BoxRowComponent
                  _label={'알림 내용'}
                  _css={css`
                    margin-top: 12px;
                    align-items: flex-start;
                  `}
                >
                  <Textarea
                    readOnly={!!userId}
                    _css={css`
                      border: none;
                      border-radius: 0;
                      padding: 0px;
                      height: 120px;
                    `}
                    maxLength={140}
                    value={inputs.notiContent}
                    onChange={(e) => {
                      handleInput('notiContent', e.target.value)
                    }}
                    placeholder={
                      !userId
                        ? `(광고)내용 입력(140자 이내)
[수신거부:마이페이지>알림설정]`
                        : ''
                    }
                  />
                </BoxRowComponent>
                <S.NotificationBox>
                  <FlexRow gap={4}>
                    <IconsNotiCircle />
                    <div>
                      정보통신망법 영리목적 광고성 정보 푸시내용 표기의무 안내
                    </div>
                  </FlexRow>
                  <ul>
                    <li>{`광고성 정보가 시작되는 부분에 “(광고)” 표시`}</li>
                    <li>{`전송자의 명칭, 연락처 표시(앱 명칭)`}</li>
                    <li>{`정보 마지막 부분에 수신 거부 방법 명시[수신거부 : 마이페이지>알림설정]`}</li>
                    <li>
                      {` 야간 광고 전송제한으로 오후 9시 ~ 오전 8시에 푸시 전송이
                      불가, 다음날 오전 10시에 일괄 전송`}
                    </li>
                  </ul>
                </S.NotificationBox>
                <BoxRowComponent
                  _css={css`
                    margin-top: 12px;
                  `}
                  _label={'기간*'}
                >
                  <PeriodPicker
                    _period={{
                      start: dayjs(inputs.startAt),
                      end: dayjs(inputs.endAt),
                    }}
                    _emitValue={(value) => {
                      const startAt = value.start?.isValid()
                        ? value.start?.startOf('day').toISOString()
                        : ''
                      const endAt = value.end?.isValid()
                        ? value.end?.endOf('day').toISOString()
                        : ''
                      startAt && handleInput('startAt', startAt)
                      endAt && handleInput('endAt', endAt)
                    }}
                  />
                </BoxRowComponent>
                <BoxRowComponent
                  _label={'사진 *'}
                  _css={css`
                    margin: 12px 0;
                    align-items: flex-start;
                  `}
                >
                  <FlexColumn gap={0}>
                    <FileUploadBox
                      _btnText="팝업 배너 업로드"
                      _btnWidth={122}
                      _placeholder="권장사이즈 1125*636px"
                      _type={'image'}
                      _fileList={bannerFile ?? []}
                      _emitValue={(value) => {
                        if (value.length > 0) {
                          value[0].file &&
                            callFileUpload(
                              value[0].file,
                              'user',
                              '/board/event/popup'
                            ).then((item) => {
                              set_bannerFile(value)
                              handleInput('popupBanner', item ?? '')
                            })
                        } else {
                          set_bannerFile([])
                          handleInput('popupBanner', '')
                        }
                      }}
                    />
                    <FileUploadBox
                      _btnText="썸네일 배너 업로드"
                      _btnWidth={122}
                      _placeholder="권장사이즈 981*288px"
                      _type={'image'}
                      _fileList={thumbnailFile ?? []}
                      _emitValue={(value) => {
                        if (value.length > 0) {
                          value[0].file &&
                            callFileUpload(
                              value[0].file,
                              'user',
                              '/board/event/thumbnail'
                            ).then((item) => {
                              set_thumbnailFile(value)
                              handleInput('thumbnailBanner', item ?? '')
                            })
                        } else {
                          set_thumbnailFile([])
                          handleInput('thumbnailBanner', '')
                        }
                      }}
                    />
                  </FlexColumn>
                </BoxRowComponent>
                <S.NotificationBox>
                  <FlexRow gap={4}>
                    <IconsNotiCircle />
                    <div>
                      낮은 해상도의 사진을 업로드한 경우, 화질이 깨져보일 수
                      있습니다.
                    </div>
                  </FlexRow>
                </S.NotificationBox>
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _rightContents={
          <ContentBoxWrap>
            <BoxLayout _title="제목 *">
              <ContentRadiusBox>
                <UnderlineInput
                  placeholder="제목 입력"
                  value={inputs.title}
                  handleValue={(value) => handleInput('title', value)}
                />
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout _title="외부링크 *">
              <ContentRadiusBox>
                <FlexRow gap={12}>
                  <IconsClip />
                  <UnderlineInput
                    _css={css`
                      width: 100%;
                    `}
                    placeholder="외부 링크 입력"
                    value={inputs.link}
                    handleValue={(value) => handleInput('link', value)}
                  />
                </FlexRow>
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _bottomRightControls={
          <CS.Layout.RightBottom>
            <PrimaryOutlineButton
              _css={css`
                width: 160px;
                height: 48px;
              `}
              // disabled={selectedIds.length < 1}
              onClick={() => {
                // 편집모드일 때
                if (userId) {
                  navigate(`/customer/event/${userId}`)
                  return
                }
                // 신규발급일 때
                navigate('/customer/event')
              }}
            >
              취소
            </PrimaryOutlineButton>

            <PrimaryButton
              disabled={!userId ? trueWhenEmpty : trueWhenEmpty || isMatched}
              _css={css`
                flex: 1;
                height: 48px;
              `}
              onClick={() => {
                // 수정모드일 경우 !!userId. 알림(푸시)보내기 api 호출 x
                userId ? editEvent(inputs) : createNewEvent(inputs)
              }}
            >
              완료
            </PrimaryButton>
          </CS.Layout.RightBottom>
        }
      ></DetailsDefault>
    </>
  )
}
export default EventPublish
