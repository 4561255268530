import Badge from 'components/Badge'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import { TD, TH } from 'components/TableComponent/TableComponent'
import dayjs from 'dayjs'
import { ApiSettlementList } from 'pages/Settlement/SettlementForTechsDetails/SettlementForTechsDetails.types'
import { JOB_TYPE_BADGE_COLOR } from 'pages/System/Techs/Techs.types'
import { DotColorsKeyType } from 'pages/Work/components/CalendarInReservation/CalendarInReservation.types'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexRow, TXT } from 'themes/Shared.styles'
import { cn, thousand } from 'utils/helpers'
import ST from './SettlementServiceHistoryTable.styles'
import { SettlementServiceHistoryTableProps } from './SettlementServiceHistoryTable.types'

export type SettlementsOfTechsByTimeType = {
  id: string
  fixedCommissionDate: string | null
  completeDate: string | null
  type: DotColorsKeyType
  title: string
  customerName: string
  settlementAmount: number | '해당없음'
}
export const settlementsOfTechsSamples: SettlementsOfTechsByTimeType[] = [
  {
    id: '1',
    settlementAmount: 5000,
    type: '시공',
    title: '도배 > 부분 도배',
    customerName: '김회원',
    fixedCommissionDate: '2022-10-02 18:30',
    completeDate: '2022-10-02 18:30',
  },
  {
    id: '2',
    settlementAmount: '해당없음',
    type: '실측',
    title: '도배 > 부분 도배',
    customerName: '김회원',
    fixedCommissionDate: null,
    completeDate: null,
  },
]

const SettlementServiceHistoryTable = ({
  _css,
  _type,
  _settlementList,
  _workInfo,
  ...props
}: SettlementServiceHistoryTableProps) => {
  const navigate = useNavigate()
  const [settlementList, set_settlementList] = useState<ApiSettlementList[]>([])
  useEffect(() => {
    set_settlementList(_settlementList)
  }, [_settlementList])
  return (
    <>
      <ST.Wrap>
        <S.Header className="box-header">
          <S.HeaderWrap>
            <S.Title>
              {_type === 'queue' ? '정산 대기 내역' : '시공 내역'}
            </S.Title>
            <FlexRow gap={8} width="auto">
              <ST.Counts>
                출동횟수{' '}
                <span className="bold">
                  {thousand(_workInfo.dispatchCount)}회
                </span>
              </ST.Counts>
              <ST.Divider />
              <ST.Counts>
                활동일수{' '}
                <span className="bold">
                  {thousand(_workInfo.activityDay)}일
                </span>
              </ST.Counts>
            </FlexRow>
          </S.HeaderWrap>
          <S.CheckAble className="checkEmpty" />
        </S.Header>
        <ST.MiniTableWrap>
          <ST.MiniTable
            className={cn({
              'not-found': settlementList.length === 0,
            })}
          >
            <thead>
              <tr>
                <TH>서비스명</TH>
                <TH>회원명</TH>
                <TH>정산 금액</TH>
                <TH>시공 완료일</TH>
              </tr>
            </thead>
            <tbody>
              {settlementList.length > 0 ? (
                <>
                  {settlementList.map((item, itemIndex) => {
                    return (
                      <tr
                        key={'list' + itemIndex}
                        onClick={() => {
                          navigate(
                            item.unpaid
                              ? `/unpaid/${item.taskId}`
                              : `/work/completed/${item.taskId}`
                          )
                        }}
                      >
                        <TD>
                          <FlexRow gap={4}>
                            <Badge
                              {...JOB_TYPE_BADGE_COLOR[
                                item.isMeasurement ? '실측' : '시공'
                              ]}
                            >
                              {item.isMeasurement ? '실측' : '시공'}
                            </Badge>
                            <div>{item.serviceName}</div>
                          </FlexRow>
                        </TD>
                        <TD>{item.customerName}</TD>
                        <TD>
                          {item.isMeasurement ? (
                            <TXT _color="Grayscale/Gray Light">해당없음</TXT>
                          ) : (
                            `${thousand(item.settlementWages)}원`
                          )}
                        </TD>
                        <TD>
                          {item.taskFinishedAt
                            ? dayjs(item.taskFinishedAt).format(
                                'YYYY-MM-DD HH:mm'
                              )
                            : '-'}
                        </TD>
                      </tr>
                    )
                  })}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan={4}>
                      <div>내역이 없습니다.</div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </ST.MiniTable>
        </ST.MiniTableWrap>
      </ST.Wrap>
    </>
  )
}
export default SettlementServiceHistoryTable
