import { GrayScaleOutline } from 'components/Buttons'
import ContentsBox from 'components/ContentsBox'
import useFetch from 'hooks/useFetch'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ContentRadiusBox, FlexColumn, FlexRow } from 'themes/Shared.styles'
import callAxios, { extractData, handleError } from 'utils/callAxios'
import { cn, updateAndDeleteBehind } from 'utils/helpers'
import { parseQueryStringToObject } from 'utils/parsers'
import S from './SelectCategory.styles'
import { ApiServiceCategory, SelectCategoryProps } from './SelectCategory.types'

type CategoryType = [
  ApiServiceCategory[],
  ApiServiceCategory[],
  ApiServiceCategory[],
  ApiServiceCategory[],
  ApiServiceCategory[]
]
const categoryBox = [[], [], [], [], []]
const SelectCategory = ({ _css, ...props }: SelectCategoryProps) => {
  const [currentValue, set_currentValue] = useState<{
    zoneGroupId: string
    type: string
    categoryId?: string
  } | null>(null)

  const [searchParams, set_searchParams] = useSearchParams()
  useEffect(() => {
    const { zoneGroupId, type, categoryId } = parseQueryStringToObject<{
      zoneGroupId: string
      type: string
      categoryId?: string
    }>(searchParams.toString())
    set_currentValue((prev) => {
      if (zoneGroupId && type) {
        return {
          zoneGroupId,
          type,
          categoryId,
        }
      }
      return prev
    })
  }, [searchParams])

  const [category, set_category] = useState<CategoryType>([[], [], [], [], []])
  const [selectedCategory, set_selectedCategory] = useState<
    ApiServiceCategory[]
  >([])
  const [response] = useFetch<ApiServiceCategory[]>(
    'order',
    `/subCategory/list?categoryId=${currentValue?.categoryId}`,
    {
      disabled: !currentValue?.categoryId,
    }
  )
  const fetchSubCategory = useCallback(
    async (item: ApiServiceCategory, index: number) => {
      try {
        const res = await callAxios('order').get(
          `/subCategory/list?subCategoryId=${item?.id}`
        )
        if (res) {
          const nextData = extractData<ApiServiceCategory[]>(res)
          set_category((prev) => {
            return updateAndDeleteBehind(prev, index, nextData) as CategoryType
          })
        }
      } catch (error) {
        handleError(error)
      }
    },
    []
  )

  useEffect(() => {
    if (response) {
      set_category([response, [], [], [], []])
    }
    return () => {
      set_category([[], [], [], [], []])
      set_selectedCategory([])
    }
  }, [response])

  const lastCategoryItem = useMemo(() => {
    const lastItem = selectedCategory[selectedCategory.length - 1]
    return lastItem ? lastItem : null
  }, [selectedCategory])

  return (
    <>
      <ContentsBox>
        <FlexColumn gap={0}>
          <S.Wrap _css={_css} {...props}>
            {categoryBox.map((_, kindIndex) => {
              const currentCategory = category[kindIndex]
              return (
                <ContentRadiusBox key={kindIndex}>
                  {currentCategory && currentCategory.length ? (
                    <>
                      <S.CategoryHeader>{kindIndex + 1}차분류</S.CategoryHeader>
                      <S.CategoryBody>
                        <S.CategoryWrap>
                          {currentCategory.map((item, categoryIndex) => {
                            return (
                              <S.GroupButton
                                disabled={item.isEstimates}
                                className={cn({
                                  active:
                                    selectedCategory?.[kindIndex]?.id ===
                                    item.id,
                                })}
                                onClick={async () => {
                                  set_selectedCategory((prev) => {
                                    if (item.id === prev[kindIndex]?.id)
                                      return prev
                                    return updateAndDeleteBehind(
                                      prev,
                                      kindIndex,
                                      item
                                    )
                                  })
                                  set_category((prev) => {
                                    return updateAndDeleteBehind(
                                      prev,
                                      kindIndex + 1,
                                      undefined
                                    ) as CategoryType
                                  })
                                  if (!item.isLast) {
                                    await fetchSubCategory(item, kindIndex + 1)
                                  }
                                }}
                                key={
                                  `${kindIndex}_` + categoryIndex + item.name
                                }
                              >
                                {item.name}
                              </S.GroupButton>
                            )
                          })}
                        </S.CategoryWrap>
                      </S.CategoryBody>
                    </>
                  ) : (
                    <S.NotFoundList>상세 분류가 없습니다.</S.NotFoundList>
                  )}
                </ContentRadiusBox>
              )
            })}
          </S.Wrap>
          <FlexRow gap={0} justifyContent="flex-end">
            <S.ButtonBox>
              <GrayScaleOutline
                onClick={() => {
                  if (!lastCategoryItem) return
                  searchParams.set('lastCategoryId', `${lastCategoryItem.id}`)
                  set_searchParams(searchParams.toString())
                }}
                disabled={!lastCategoryItem?.isLast}
              >
                조회
              </GrayScaleOutline>
            </S.ButtonBox>
          </FlexRow>
        </FlexColumn>
      </ContentsBox>
    </>
  )
}
export default SelectCategory
