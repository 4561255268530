import { css } from '@emotion/react'
import { ImagesInputRemove } from 'assets'
import { FILE_TYPE } from 'components/ApiFileUploadBox/ApiFileUploadBox.types'
import Badge, { BADGE_COLOR, SERVICE_BADGE_COLOR } from 'components/Badge/Badge'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
  PrimaryOutlineButton,
} from 'components/Buttons'
import DetailsDefault, { ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import Dropdown from 'components/Dropdown'
import { useGallery } from 'components/Gallery/Gallery.hooks'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import NotFoundItem from 'components/NotFoundItem'
import RadioButton from 'components/RadioButton'
import RowTab from 'components/RowTab'
import UnderlineNumberInput from 'components/UnderlineInput/UnderlineNumberInput'
import dayjs from 'dayjs'
import useMatchInput from 'hooks/useMatchInput'
import LoadingPage from 'layout/LoadingPage'
import AddressInfo from 'pages/Work/components/AddressInfo'
import CustomerInfo from 'pages/Work/components/CustomerInfo'
import ExtraFee from 'pages/Work/components/ExtraFee'
import { TaskAdditionalCost } from 'pages/Work/components/ExtraFeesInService/ExtraFeesInService.types'
import ExtraFeesInfo from 'pages/Work/components/ExtraFeesInfo'
import OrderItem from 'pages/Work/components/OrderItem'
import PhotoAndRequests from 'pages/Work/components/PhotoAndRequests'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import {
  ContentBoxWrap,
  ContentRadiusBox,
  ContentRow,
  Divider,
  Flex,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { colors, typography } from 'themes/styles'
import { DoubleDFile } from 'types/services.type'
import { blobNewWindow } from 'utils/DragAndZoom/utils'
import callAxios, { handleError } from 'utils/callAxios'
import {
  HandleEllipsisToolTip,
  getFileToBlob,
  handleNumber,
  objectKeys,
  thousand,
} from 'utils/helpers'
import { callFileUpload } from 'utils/services'
import A, { BoxTextarea } from '../AddEstimates/AddEstimates.styles'
import { serviceRadio } from '../AddEstimates/AddEstimates.types'
import SelectMaterialPopup from '../AddEstimates/SelectMaterialPopup'
import { useServiceControlOnEstimates } from '../Estimates.hooks'
import {
  ESTIMATES_INITIAL_VALUE,
  EstimatesDetailsProps,
} from './EstimatesDetails.types'
import { setRefresh } from 'store/refreshSlice'
const tabs = ['주문 정보', '결제 정보'] as const

// 변경 시 AddEstimates.tsx의 타입과 싱크를 맞출지를 고려할것.
const EstimatesDetails = ({ _css, _id, ...props }: EstimatesDetailsProps) => {
  const { Layout: L } = S
  const { currentGallery, dispatch } = useGallery()
  const navigate = useNavigate()
  const estimatesDetailsData = useMatchInput(ESTIMATES_INITIAL_VALUE)
  const { inputs, handleInput, handleValues } = estimatesDetailsData

  // 시공 정보 관련 항목
  const {
    errorZoneGroup,
    serviceRowList,
    responseData,
    currentServiceType,
    selectedServiceCategory,
    priceTableOptionValues,
    optionPriceValues,
    materialItemList,
    quantityTitle,
    serviceQuantity,
    selectedMaterial,
    lastSelectedServiceCategoryId,
    categoryDropdown,
    selectedIsEstimates,
    handleOptionPriceValue,
    handleServiceQuantity,
    handlePriceTableOptionValues,
    handleDropdown,
    handleServiceType,
    handleServiceRequest,
    handleSelectedMaterial,
    getCurrentServicePrice,
    priceOptionDropdowns,
    checkOptions,
  } = useServiceControlOnEstimates(estimatesDetailsData, Number(_id))

  const [openMaterial, set_openMaterial] = useState<boolean>(false)

  // 추가금
  const [extraFees, set_extraFees] = useState<TaskAdditionalCost[]>([])
  const handleExtraFees = (fee: TaskAdditionalCost[]) => {
    set_extraFees(fee)
  }

  // 결제 정보 - 주문 금액

  const totalAmount = useMemo(() => {
    let total = extraFees.reduce((prev, curr) => {
      prev += handleNumber(curr.price)
      return prev
    }, 0)
    if (inputs.serviceFee) {
      total += inputs.serviceFee
    }
    if (inputs.dispatchFee) {
      total += inputs.dispatchFee
    }
    if (inputs.serviceMaterialPrice) {
      total += inputs.serviceMaterialPrice
    }
    if (inputs.liftingPrice) {
      total += inputs.liftingPrice
    }
    return total
  }, [
    extraFees,
    inputs.serviceFee,
    inputs.dispatchFee,
    inputs.serviceMaterialPrice,
    inputs.liftingPrice,
  ])

  useEffect(() => {
    if (responseData) {
      handleValues(responseData)
      set_extraFees(responseData.extraFees)
    }
  }, [responseData, handleValues])

  // 주문 정보 | 결제 정보 탭
  const [currentTab, set_currentTab] =
    useState<typeof tabs[number]>('주문 정보')

  const [openModal, set_openModal] = useState<{
    title: string
    content: string
    txt?: [string, string]
    onSubmit?: () => void
  } | null>(null)

  const [openExtraFee, set_openExtraFee] = useState<{
    open: boolean
    data?: {
      index: number
      data: TaskAdditionalCost
    }
  }>({ open: false })
  const handleSubmit = async () => {
    if (selectedIsEstimates) {
      set_openModal({
        title: '시공 정보 변경',
        content: `시공 정보가 ‘문의'로 되어있습니다.
        서비스를 선택해야 견적서를 전송할 수 있습니다.`,
      })
      return
    }
    if (!_id) return
    try {
      const putData = {
        serviceProductId: inputs.serviceItem.serviceCategoryId,
        priceTagId: inputs.serviceItem.priceTagId,
        taskPrice: inputs.serviceFee,
        liftingPrice: inputs.liftingPrice,
        serviceMaterialPrice: inputs.serviceMaterialPrice,
        additionalCost: extraFees.map((item) => ({
          taskName: item.taskName,
          price: item.price,
          note: item.note,
          taskPrice: item.taskPrice ?? 0,
          messageToUser: item.messageToUser,
        })),
        serviceOption: objectKeys(optionPriceValues)
          .map((keyName) =>
            optionPriceValues[keyName] !== 0
              ? {
                  name: keyName,
                  price: optionPriceValues[keyName],
                }
              : null
          )
          .filter((ii) => ii !== null),
        serviceRequestsImage: inputs.requestImages.map((item) => item.name),
        serviceRequests: inputs.serviceItem.serviceRequests,
        etcRequests: inputs.requests,
        quantity: serviceQuantity ?? 1,
        ...(selectedMaterial && {
          material: {
            id: selectedMaterial.id,
            name: selectedMaterial.name,
            unitPrice: selectedMaterial.price,
            quantity: selectedMaterial.count ?? 1,
          },
        }),
      }
      await callAxios('order').put(`/estimates/${_id}`, putData)
      dispatch(setToast(['견적서를 발송했습니다.', 'success']))
      navigate('/work/estimates')
    } catch (error) {
      handleError(error)
    }
  }
  // 사진, 요청사항
  const [files, set_files] = useState<string>('')
  // 사진, 요청사항 이미지 업로드
  const handleUpload = async (...fileList: File[]) => {
    const uploadedList = (
      await Promise.all(
        fileList
          .map(async (file) => {
            const fileName = await callFileUpload(
              file,
              'order',
              '/estimates/file'
            )
            const blob = await getFileToBlob(file)
            if (fileName) {
              return {
                id: fileName ?? '',
                name: fileName ?? '',
                url: blob,
              }
            } else {
              return null
            }
          })
          .filter((ii) => !!ii)
      )
    ).filter((ii) => !!ii) as DoubleDFile[]
    if (uploadedList.length > 0) {
      handleInput('requestImages', (prev) => [
        ...prev.requestImages,
        ...uploadedList,
      ])
    }
  }
  const handleDeleteCurrentEstimates = useCallback(async () => {
    try {
      await callAxios('order').delete(`/estimates/${_id}/admin`)
      dispatch(
        setToast({ message: '견적서가 삭제되었습니다.', type: 'success' })
      )
      navigate(`/work/estimates`)
    } catch (error) {
      handleError(error)
    }
  }, [navigate, dispatch, _id])
  // 다음 상태로 변경
  const handleChangeStatus = useCallback(async () => {
    try {
      await callAxios('order').patch(`/payment/${_id}`)
      dispatch(setRefresh(dayjs().valueOf()))
      dispatch(setToast({ message: '상태가 변경되었습니다.', type: 'success' }))
      navigate('/work/estimates')
    } catch (error) {
      handleError(error)
    }
  }, [_id, dispatch, navigate])

  if (!responseData) return <LoadingPage />
  return (
    <>
      {errorZoneGroup && (
        <Modal open={errorZoneGroup} onClose={handleDeleteCurrentEstimates}>
          <MiniPopup
            _title="서비스 지역 조회 불가"
            _buttonHandler={handleDeleteCurrentEstimates}
          >
            해당 견적 요청서의
            <br />
            서비스 지역을 조회할 수 없습니다.
            <br />
            견적서를 삭제합니다.
          </MiniPopup>
        </Modal>
      )}
      {openMaterial && (
        <SelectMaterialPopup
          _onClose={() => set_openMaterial(false)}
          _data={materialItemList}
          _emitValue={(value) => handleSelectedMaterial(value)}
        />
      )}
      {openModal && (
        <Modal open={openModal !== null} onClose={() => set_openModal(null)}>
          <MiniPopup
            _title={openModal.title}
            _buttonHandler={
              openModal.onSubmit ? (
                <>
                  <GrayScaleFill
                    onClick={() => {
                      set_openModal(null)
                    }}
                  >
                    {openModal.txt?.[0] ?? '아니오'}
                  </GrayScaleFill>
                  <PrimaryButton onClick={openModal.onSubmit}>
                    {openModal.txt?.[1] ?? '삭제'}
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    set_openModal(null)
                  }}
                >
                  {openModal.txt?.[0] ?? '확인'}
                </PrimaryButton>
              )
            }
          >
            {openModal.content}
          </MiniPopup>
        </Modal>
      )}
      {openExtraFee.open && (
        <ExtraFee
          _data={openExtraFee.data}
          _open={openExtraFee.open}
          _onClose={() => set_openExtraFee((prev) => ({ open: false }))}
          _emitValue={(value) => {
            const prevExtra = [...extraFees]
            const nextExtra = openExtraFee.data
              ? prevExtra.map((item) =>
                  item.id === value.id ? { ...item, ...value } : item
                )
              : [...prevExtra, value]
            set_extraFees(nextExtra)
            set_openExtraFee((prev) => ({ open: false }))
          }}
        />
      )}
      <DetailsDefault
        _title={'견적 요청'}
        _topLeftControls={
          <L.ButtonRow>
            <GrayScaleOutline
              _mini
              _css={css`
                padding: 5px 12px;
              `}
              // disabled={cancelPaymentState}
              onClick={() => handleChangeStatus()}
            >
              다음 상태로 변경
            </GrayScaleOutline>
            <GrayScaleOutline
              _mini
              _css={css`
                padding: 5px 12px;
              `}
              // disabled={cancelPaymentState}
              onClick={() => handleDeleteCurrentEstimates()}
            >
              삭제
            </GrayScaleOutline>
            <ToList _to="/work/estimates" />
          </L.ButtonRow>
        }
        _leftTab={
          <RowTab
            _tabs={tabs}
            _tab={currentTab}
            _emitValue={(value) => {
              set_currentTab(value)
            }}
          />
        }
        _leftContents={
          <ContentBoxWrap>
            {currentTab === '주문 정보' && (
              <>
                <BoxLayout
                  _title={`주문 정보`}
                  _titleSuffix={
                    <>
                      {inputs.serviceState !== null && (
                        <Badge {...SERVICE_BADGE_COLOR[inputs.serviceState]}>
                          {inputs.serviceState}
                        </Badge>
                      )}
                    </>
                  }
                >
                  <ContentRadiusBox>
                    <BoxRowComponent
                      _label={`주문 일시`}
                      _contents={
                        inputs.orderDate
                          ? dayjs(inputs.orderDate).format('YYYY-MM-DD HH:mm')
                          : '-'
                      }
                    />
                  </ContentRadiusBox>
                </BoxLayout>
                <BoxLayout _title={'회원 정보'}>
                  <CustomerInfo inputs={inputs} />
                </BoxLayout>
                <BoxLayout _title={'주문 서비스'}>
                  {inputs.serviceItem.serviceCategoryId ? (
                    <OrderItem
                      _item={{
                        id: inputs.serviceItem.serviceCategoryId,
                        name: inputs.serviceItem.serviceName,
                      }}
                    />
                  ) : (
                    <NotFoundItem _useImage={true}>
                      선택한 서비스가 없습니다.
                    </NotFoundItem>
                  )}
                </BoxLayout>
              </>
            )}
            {currentTab === '결제 정보' && inputs.serviceState === '작성대기' && (
              <>
                <div>
                  <BoxLayout _title={'결제 정보'}>
                    <FlexColumn gap={12}>
                      <ContentRadiusBox>
                        <BoxRowComponent _label={'양중비'}>
                          <UnderlineNumberInput
                            suffix="원"
                            placeholder="내용 입력"
                            value={inputs.liftingPrice}
                            handleValue={(value) =>
                              handleInput('liftingPrice', value)
                            }
                          />
                        </BoxRowComponent>
                      </ContentRadiusBox>
                      {extraFees.map((item, itemIndex) => {
                        return (
                          <ContentRadiusBox key={'extra' + itemIndex}>
                            <BoxRowComponent _label={item.taskName}>
                              {thousand(item.price ?? 0)}원
                            </BoxRowComponent>
                          </ContentRadiusBox>
                        )
                      })}
                      <ContentRadiusBox>
                        <BoxRowComponent _label={'공임비'}>
                          <UnderlineNumberInput
                            suffix="원"
                            placeholder="내용 입력"
                            value={inputs.serviceFee}
                            handleValue={(value) =>
                              handleInput('serviceFee', value)
                            }
                          />
                        </BoxRowComponent>
                      </ContentRadiusBox>
                      <ContentRadiusBox>
                        <BoxRowComponent _label={'재료비'}>
                          <UnderlineNumberInput
                            suffix="원"
                            placeholder="내용 입력"
                            value={inputs.serviceMaterialPrice}
                            handleValue={(value) =>
                              handleInput('serviceMaterialPrice', value)
                            }
                          />
                        </BoxRowComponent>
                      </ContentRadiusBox>
                      <ContentRadiusBox>
                        <ContentRow>
                          <S.TotalLabel>주문 금액</S.TotalLabel>
                          <S.TotalPrice>{thousand(totalAmount)}원</S.TotalPrice>
                        </ContentRow>
                      </ContentRadiusBox>
                    </FlexColumn>
                  </BoxLayout>
                </div>
                <ExtraFeesInfo
                  extraFees={extraFees}
                  handleExtraFees={handleExtraFees}
                  handleModal={(value) => set_openModal(value)}
                  openExtraFeeModal={(value) => {
                    set_openExtraFee(value)
                  }}
                />
              </>
            )}
            {currentTab === '결제 정보' &&
              inputs.serviceState === '전송완료' &&
              inputs.paymentInfoResult && (
                <>
                  <div>
                    <BoxLayout _title={'결제 정보'}>
                      <FlexColumn gap={12}>
                        <ContentRadiusBox>
                          <BoxRowComponent _label={'공임비'}>
                            {thousand(inputs.paymentInfoResult!.taskPrice ?? 0)}
                            원
                          </BoxRowComponent>
                          <BoxRowComponent _label={'재료비'}>
                            {thousand(
                              inputs.paymentInfoResult!.serviceMaterialPrice ??
                                0
                            )}
                            원
                          </BoxRowComponent>
                          <BoxRowComponent _label={'양중비'}>
                            {thousand(
                              inputs.paymentInfoResult!.liftingPrice ?? 0
                            )}
                            원
                          </BoxRowComponent>
                          {extraFees.map((item, itemIndex) => {
                            return (
                              <BoxRowComponent
                                key={'extra_price' + itemIndex}
                                _label={item.taskName}
                              >
                                {thousand(item.price ?? 0)}원
                              </BoxRowComponent>
                            )
                          })}
                          <Divider />
                          <ContentRow>
                            <S.TotalLabel>주문 금액</S.TotalLabel>
                            <S.TotalPrice>
                              {thousand(inputs.paymentInfoResult!.totalPrice)}원
                            </S.TotalPrice>
                          </ContentRow>

                          <FlexRow gap={4} justifyContent={'space-between'}>
                            <S.PointBadgeBox>
                              <Badge {...BADGE_COLOR['PrimaryLight']}>
                                계산식
                              </Badge>
                              <TXT
                                _color="Primary/Default"
                                _textStyle="Body/Caption/Regular"
                              >{`{(상품금액 - 쿠폰,포인트 할인) * 1.1(VAT)} + 출장비`}</TXT>
                            </S.PointBadgeBox>
                            <S.PointBadgeBox></S.PointBadgeBox>
                          </FlexRow>
                        </ContentRadiusBox>
                      </FlexColumn>
                    </BoxLayout>
                  </div>
                  <ExtraFeesInfo
                    extraFees={extraFees}
                    _readOnly={true}
                    handleExtraFees={handleExtraFees}
                    handleModal={(value) => set_openModal(value)}
                    openExtraFeeModal={(value) => {
                      set_openExtraFee(value)
                    }}
                  />
                </>
              )}
          </ContentBoxWrap>
        }
        _rightContents={
          <ContentBoxWrap>
            <BoxLayout _title={'주소지 정보'}>
              <AddressInfo inputs={inputs} />
            </BoxLayout>
            {inputs.serviceState === '전송완료' && (
              <>
                <BoxLayout _title={`시공 정보 (${serviceRowList.title})`}>
                  <ContentRadiusBox>
                    {serviceRowList.list.map((row, rowIndex) => {
                      return (
                        <BoxRowComponent
                          key={`row` + rowIndex}
                          _label={row.name}
                        >
                          {row.value}
                        </BoxRowComponent>
                      )
                    })}
                  </ContentRadiusBox>
                  <ContentRadiusBox className="question">
                    <BoxRowComponent
                      _label={`시공 요청 사항`}
                      _contents={serviceRowList.requests}
                    />
                  </ContentRadiusBox>
                </BoxLayout>
              </>
            )}
            {inputs.serviceState === '작성대기' && (
              <BoxLayout _title={'시공 정보 추가'}>
                {inputs.zoneGroupId && inputs.customerType ? (
                  <ContentRadiusBox gap={12}>
                    <>
                      <BoxRowComponent _label={'서비스 유형'}>
                        {serviceRadio.map((serviceType) => {
                          return (
                            <RadioButton
                              key={serviceType}
                              _mini
                              _active={currentServiceType === serviceType}
                              onClick={() => {
                                handleServiceType(serviceType)
                              }}
                            >
                              {serviceType}
                            </RadioButton>
                          )
                        })}
                      </BoxRowComponent>
                      {categoryDropdown.map((dropdown, dropdownIndex) => {
                        return (
                          <BoxRowComponent
                            key={'service-dropdown-' + dropdownIndex}
                            _label={dropdownIndex === 0 ? '서비스 이름*' : ''}
                          >
                            <Dropdown.Underline
                              _list={dropdown.list}
                              _value={selectedServiceCategory[dropdownIndex]}
                              _emitValue={(value) => {
                                handleDropdown(value, dropdownIndex)
                              }}
                            />
                          </BoxRowComponent>
                        )
                      })}
                      {priceOptionDropdowns && (
                        <>
                          {objectKeys(priceOptionDropdowns.priceTagHeader)
                            .length > 0 && (
                            <>
                              {objectKeys(
                                priceOptionDropdowns.priceTagHeader
                              ).map((keyName, keyIndex) => {
                                const tagName =
                                  priceOptionDropdowns.priceTagHeader[keyName]
                                    .priceTagColumn
                                return (
                                  <BoxRowComponent
                                    _label={keyName}
                                    key={keyName + keyIndex}
                                  >
                                    <Dropdown.Underline
                                      _list={
                                        priceOptionDropdowns.priceTagHeader[
                                          keyName
                                        ].option
                                      }
                                      _placeholder={'선택'}
                                      _value={priceTableOptionValues[tagName]}
                                      _emitValue={(value) => {
                                        handlePriceTableOptionValues(
                                          tagName,
                                          value
                                        )
                                      }}
                                    />
                                  </BoxRowComponent>
                                )
                              })}
                            </>
                          )}
                          {objectKeys(priceOptionDropdowns.serviceOptions)
                            .length > 0 && (
                            <>
                              {objectKeys(
                                priceOptionDropdowns.serviceOptions
                              ).map((keyName, keyIndex) => {
                                return (
                                  <BoxRowComponent
                                    _label={keyName}
                                    key={keyName + keyIndex}
                                  >
                                    <Dropdown.Underline
                                      _list={
                                        priceOptionDropdowns.serviceOptions[
                                          keyName
                                        ].option
                                      }
                                      _placeholder={'선택'}
                                      _value={
                                        optionPriceValues[keyName] ?? null
                                      }
                                      _emitValue={(value) => {
                                        handleOptionPriceValue(keyName, value)
                                      }}
                                    />
                                  </BoxRowComponent>
                                )
                              })}
                            </>
                          )}
                        </>
                      )}
                      {/* 방개수 필요한 경우 */}
                      {quantityTitle && (
                        <BoxRowComponent _label={quantityTitle.name}>
                          <UnderlineNumberInput
                            value={serviceQuantity}
                            handleValue={handleServiceQuantity}
                          />
                        </BoxRowComponent>
                      )}
                      {/* 자재 필요한 경우 */}
                      {materialItemList.length > 0 && (
                        <>
                          <BoxRowComponent
                            _label={'자재'}
                            _contents={
                              <>
                                {!selectedMaterial ? (
                                  <PrimaryOutlineButton
                                    _css={css`
                                      padding: 5px 0;
                                      width: 100%;
                                    `}
                                    onClick={() => {
                                      set_openMaterial(true)
                                    }}
                                  >
                                    입력
                                  </PrimaryOutlineButton>
                                ) : (
                                  <>
                                    <Flex
                                      justifyContent={'space-between'}
                                      _css={css`
                                        div {
                                          text-align: left;
                                        }
                                      `}
                                    >
                                      <div>
                                        <div>{selectedMaterial.name}</div>
                                        <div>
                                          {thousand(
                                            selectedMaterial.count ?? 0
                                          )}
                                          개
                                        </div>
                                      </div>
                                      <GrayScaleFill
                                        _mini
                                        padding={`5px 0`}
                                        width={97}
                                        onClick={() => {
                                          handleSelectedMaterial(null)
                                        }}
                                      >
                                        삭제
                                      </GrayScaleFill>
                                    </Flex>
                                  </>
                                )}
                              </>
                            }
                          />
                        </>
                      )}
                      {!checkOptions && lastSelectedServiceCategoryId && (
                        <>
                          <BoxRowComponent
                            _label={'시공 요청 사항'}
                            className="flex-start"
                          >
                            <BoxTextarea
                              placeholder="내용 입력"
                              value={inputs.serviceItem.serviceRequests ?? ''}
                              onChange={(e) => {
                                handleServiceRequest(e.target.value)
                              }}
                            />
                          </BoxRowComponent>
                        </>
                      )}
                      <BoxRowComponent _label={''}>
                        {!inputs.serviceItem.serviceCategoryId ? (
                          <>
                            <PrimaryButton
                              disabled={
                                !lastSelectedServiceCategoryId || checkOptions
                              }
                              padding={'5px 0px;'}
                              width={'100%'}
                              onClick={async () => {
                                await getCurrentServicePrice()
                              }}
                            >
                              추가
                            </PrimaryButton>
                          </>
                        ) : (
                          <>
                            <GrayScaleOutline
                              padding={'5px 0px;'}
                              width={'100%'}
                              disabled={
                                !lastSelectedServiceCategoryId || checkOptions
                              }
                              onClick={async () => {
                                if (selectedIsEstimates) {
                                  set_openModal({
                                    title: '시공 정보 변경',
                                    content: `시공 정보가 ‘문의'로 되어있습니다.
                                    서비스를 선택해야 견적서를 전송할 수 있습니다.`,
                                  })
                                  return
                                }
                                set_openModal({
                                  title: '시공 정보 변경',
                                  content: '변경하시면 가격도 함께 변경됩니다.',
                                  txt: ['취소', '변경'],
                                  onSubmit: async () => {
                                    await getCurrentServicePrice()
                                    set_openModal(null)
                                  },
                                })
                              }}
                            >
                              수정
                            </GrayScaleOutline>
                          </>
                        )}
                      </BoxRowComponent>
                    </>
                  </ContentRadiusBox>
                ) : (
                  <>
                    <NotFoundItem
                      _css={css`
                        div.not-found {
                          color: ${colors['Grayscale/Gray Light']};
                          ${typography['Body/Caption/Bold']}
                        }
                      `}
                    >
                      회원정보 및 주소지 정보 먼저 선택해주세요.
                    </NotFoundItem>
                  </>
                )}
              </BoxLayout>
            )}
            <BoxLayout _title={'사진, 요청 사항'}>
              {responseData?.serviceState === '전송완료' ? (
                <PhotoAndRequests
                  _readOnly={true}
                  _gallery={{
                    gallery: { ...currentGallery, target: ['요청 사진'] },
                    count: currentGallery.data['요청 사진']?.length ?? 0,
                  }}
                  _emitValue={(value) => {
                    handleInput(
                      'requestImages',
                      value.map((item) => ({
                        id: item.id,
                        name: item.name,
                        url: item.url,
                      }))
                    )
                  }}
                  _requests={inputs.requests}
                />
              ) : (
                <ContentRadiusBox>
                  <BoxRowComponent
                    _label={'사진'}
                    _css={css`
                      align-items: flex-start;
                      & > label {
                        padding: 12px 0;
                      }
                    `}
                  >
                    <FlexRow gap={24} alignItems="flex-start">
                      <A.Label htmlFor={'request-images'}>
                        <A.Upload style={{ marginTop: '7px' }}>
                          사진 업로드
                        </A.Upload>
                        <input
                          id={'request-images'}
                          type="file"
                          hidden
                          multiple={true}
                          value={files}
                          accept={FILE_TYPE['image']}
                          onChange={async (e) => {
                            set_files(e.target.value)
                            const getFiles = e.target.files as FileList
                            if (getFiles) {
                              await handleUpload(...getFiles)
                              setTimeout(() => {
                                set_files('')
                              }, 0)
                            }
                          }}
                        />
                      </A.Label>
                      {inputs.requestImages.length > 0 ? (
                        <A.Files
                          _css={css`
                            max-width: calc(100% - 84px - 24px);
                          `}
                        >
                          {inputs.requestImages.map((imageUrl) => {
                            return (
                              <FlexRow
                                _css={css`
                                  position: relative;
                                  width: 100%;
                                `}
                                key={imageUrl.name}
                                gap={10}
                                alignItems="center"
                                onMouseEnter={(e) =>
                                  HandleEllipsisToolTip('mouseEnter', e)
                                }
                                onMouseLeave={(e) =>
                                  HandleEllipsisToolTip('mouseLeave', e)
                                }
                              >
                                <A.File>
                                  <Link
                                    to={imageUrl.url}
                                    download={imageUrl.name}
                                    target="_blank"
                                    {...(!imageUrl.url.includes('http') && {
                                      onClick: (e) => {
                                        e.preventDefault()
                                        blobNewWindow(imageUrl.url)
                                      },
                                    })}
                                  >
                                    {imageUrl.name}
                                  </Link>
                                </A.File>
                                <img
                                  src={ImagesInputRemove}
                                  alt="text remove"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    handleInput('requestImages', (prev) =>
                                      prev.requestImages.filter(
                                        (galleryUrl) => {
                                          return galleryUrl !== imageUrl
                                        }
                                      )
                                    )
                                  }}
                                />
                              </FlexRow>
                            )
                          })}
                        </A.Files>
                      ) : (
                        <TXT
                          _textStyle="Body/Large/Bold"
                          _color="Grayscale/Gray Light"
                          _css={css`
                            padding: 10px 0;
                          `}
                        >
                          {`등록된 사진 없음`}
                        </TXT>
                      )}
                    </FlexRow>
                  </BoxRowComponent>
                  {inputs.serviceState !== '전송완료' && (
                    <BoxRowComponent
                      _label={'기타 요청 사항'}
                      className="flex-start"
                    >
                      <BoxTextarea
                        value={inputs.requests}
                        onChange={(e) => {
                          handleInput('requests', e.target.value)
                        }}
                        placeholder="입력"
                      />
                    </BoxRowComponent>
                  )}
                </ContentRadiusBox>
              )}
            </BoxLayout>
          </ContentBoxWrap>
        }
        _bottomRightControls={
          inputs.serviceState === '작성대기' ? (
            <L.RightBottom>
              <PrimaryButton
                _css={css`
                  flex: 1;
                `}
                height={48}
                padding={`12px 0`}
                onClick={() => {
                  handleSubmit()
                }}
              >
                견적서 작성
              </PrimaryButton>
            </L.RightBottom>
          ) : undefined
        }
      />
    </>
  )
}
export default EstimatesDetails
