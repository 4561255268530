import { useSearchParams } from 'react-router-dom'
import SelectCategory from './SelectCategory'
import SelectService from './SelectService'
import ServiceManagement from './ServiceManagement'

const Setting = () => {
  const [searchParams, set_searchParams] = useSearchParams()
  return (
    <>
      {!searchParams.get('zoneGroupId') && (
        <SelectService
          _emitValue={(value) => {
            searchParams.set('zoneGroupId', `${value.groupItem.id}`)
            searchParams.set(
              'type',
              `${value.serviceItem.serviceType === 'etc' ? 'sub' : 'main'}`
            )
            if (value.serviceItem.serviceId) {
              if (value.serviceItem.serviceType === 'etc') {
                searchParams.set(
                  'lastCategoryId',
                  `${value.serviceItem.serviceId}`
                )
              }
              searchParams.set('categoryId', `${value.serviceItem.serviceId}`)
            } else {
              if (value.serviceItem.serviceType === 'etc') {
                searchParams.set('sub', `new`)
              }
              searchParams.delete('lastCategoryId')
              searchParams.delete('categoryId')
            }
            set_searchParams(searchParams.toString())
          }}
        />
      )}
      {searchParams.get('zoneGroupId') &&
        searchParams.get('type') === 'main' &&
        !searchParams.get('lastCategoryId') && <SelectCategory />}
      {(searchParams.get('lastCategoryId') ||
        searchParams.get('sub') === 'new') &&
        searchParams.get('zoneGroupId') && <ServiceManagement />}
    </>
  )
}
export default Setting
