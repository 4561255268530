import styled from '@emotion/styled/macro'
import { colors, typography } from 'themes/styles'
import { addCssProps } from 'themes/styles.helper'

const RowTabStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    &.tab-line {
      justify-content: space-between;
    }
    &.tab-badge {
      gap: 8px;
    }
    &.flex-end {
      justify-content: flex-end;
      flex: 1;
    }
    & > div.tab-line {
      border-bottom: 1px solid ${colors['Grayscale/Gray Lighter']};
    }
    ${addCssProps}
  `,
  Tab: styled.div`
    cursor: pointer;
    user-select: none;
    &.tab-line {
      flex: 1;
      text-align: center;
      ${typography['Body/Small/Bold']};
      color: ${colors['Grayscale/Gray Default']};
      border-bottom: 2px solid transparent;
      padding: 4px 0 12px;
      &.selected {
        border-bottom: 2px solid ${colors['Primary/Default']};
        color: ${colors['Grayscale/Black']};
      }
    }
    &.tab-badge {
      padding: 8px 16px;
      ${typography['Body/Small/Bold']}
      color: ${colors['Grayscale/Gray Default']};
      box-shadow: inset 0px 0px 0px 1px ${colors['Grayscale/Gray Lighter']};
      border-radius: 16px;
      &.selected {
        box-shadow: none;
        color: #ffffff;
        background-color: ${colors['Primary/Default']};
      }
    }
  `,
}

export default RowTabStyle
