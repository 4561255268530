import { MutableRefObject, useEffect, useRef, useState } from 'react'
import S from './RoundFillBox.styles'
import { RoundFillBoxProps } from './RoundFillBox.types'

const RoundFillBox = ({ _css, _vh, ...props }: RoundFillBoxProps) => {
  const boxRef = useRef() as MutableRefObject<HTMLDivElement>
  const [boxOffsetTop, set_boxOffsetTop] = useState<number>(0)
  useEffect(() => {
    if (boxRef.current) {
      const bounding = boxRef.current.getBoundingClientRect()
      set_boxOffsetTop(bounding.top)
    }
  }, [])
  return (
    <>
      <S.Wrap
        ref={boxRef}
        _offsetTop={boxOffsetTop}
        _css={_css}
        _vh={_vh}
        {...props}
      >
        {props.children}
      </S.Wrap>
    </>
  )
}
export default RoundFillBox
