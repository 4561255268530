import {
  ImagesDropdownCellChevronDown,
  ImagesDropdownCellChevronUp,
} from 'assets'
import useOutsideEvent from 'hooks/useOutsideEvent'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { cn, getObjectLabel, uuid } from 'utils/helpers'
import { dropdownListOnkeyDown } from '../Dropdown.helpers'
import { DropdownListType } from '../Dropdown.types'
import CellDropdownStyle from './CellDropdown.styles'
import { CellDropdownProps } from './CellDropdown.types'

const CellDropdown = <T extends DropdownListType>({
  _css,
  _value,
  _list,
  _emitValue,
  className,
  _position,
  _placeholder = '선택',
  _type = 'th',
  _disabled,
  ...props
}: CellDropdownProps<T>) => {
  const S =
    _type === 'td'
      ? CellDropdownStyle.ServiceDropdownStyle
      : CellDropdownStyle.TableDropdownStyle
  const listRef = useRef() as MutableRefObject<HTMLDivElement>
  const wrapRef = useRef() as MutableRefObject<HTMLDivElement>

  const [label, set_label] = useState<Extract<keyof T, string | number> | null>(
    null
  )
  const [open, set_open] = useState<T>()
  useEffect(() => {
    if (_value) {
      set_label(getObjectLabel(_list, _value))
    } else {
      set_label(null)
    }
  }, [_list, _value])
  const uuidRef = useRef('cell_' + uuid()) as MutableRefObject<string>

  useOutsideEvent(`#${uuidRef.current}`, () => {
    set_open(undefined)
  })
  return (
    <>
      <S.Wrap
        ref={wrapRef}
        id={uuidRef.current}
        _css={_css}
        className={cn({ active: open, disabled: _disabled }, className)}
        {...props}
      >
        <S.Label
          onClick={(e) => {
            set_open((prev) => (prev ? undefined : _list))
          }}
          _position={_position}
          className={cn(
            {
              placeholder: !label,
              active: open,
              // selected: !!label,
            },
            'label-box'
          )}
        >
          <label>{label ? label : _placeholder}</label>
          <img
            src={ImagesDropdownCellChevronDown}
            alt="closed"
            className={cn({ hide: open })}
          />
          <img
            src={ImagesDropdownCellChevronUp}
            alt="opened"
            className={cn({ hide: !open })}
          />
        </S.Label>
        {open && (
          <S.List>
            <div ref={listRef}>
              {(
                Object.keys(open) as Array<Extract<keyof T, string | number>>
              ).map((keyName) => {
                return (
                  <S.Item
                    tabIndex={0}
                    key={keyName}
                    className="dropdown-item"
                    data-active={
                      _value === open[keyName] ? 'active' : 'inactive'
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      if (_emitValue) _emitValue(open[keyName])
                      set_open(undefined)
                      if (wrapRef.current) {
                        wrapRef.current.focus()
                      }
                    }}
                    onKeyDown={(e) => {
                      dropdownListOnkeyDown(e, listRef.current, (e) => {
                        if (e.code === 'Enter' && open) {
                          if (_emitValue) _emitValue(open[keyName])
                          set_open(undefined)
                          if (wrapRef.current) {
                            wrapRef.current.focus()
                          }
                        }
                      })
                    }}
                  >
                    {keyName}
                  </S.Item>
                )
              })}
            </div>
          </S.List>
        )}
      </S.Wrap>
    </>
  )
}
export default CellDropdown
