import { css } from '@emotion/react'
import DatePicker from 'components/DatePicker'
import Dropdown from 'components/Dropdown'
import useMatchInput from 'hooks/useMatchInput'
import { clone } from 'lodash'
import { useEffect } from 'react'
import { FilterContentsProps } from '../FilterPart/FilterPart.types'
import S from './PeriodDropdownFilter.styles'
import {
  PERIOD_DROPDOWN_INITIAL_VALUE,
  PeriodDropdownValuesType,
  PeriodType,
  TIME_FILTER_DROPDOWN,
  TIME_FILTER_DROPDOWN_WITHOUT_ALL,
  TimeFilterValueType,
  monthOptions,
  yearOption,
} from './PeriodDropdownFilter.types'

const isWithoutAll = (
  value: string,
  without?: boolean
): value is 'year' | 'month' | 'period' => {
  return !!without
}

const PeriodDropdownFilter = ({
  _value,
  _removeAll,
  _css,
  _emitValue,
  ...props
}: FilterContentsProps<PeriodDropdownValuesType>) => {
  const { inputs, handleValues } = useMatchInput(
    _removeAll
      ? { ...PERIOD_DROPDOWN_INITIAL_VALUE, periodType: '' }
      : PERIOD_DROPDOWN_INITIAL_VALUE
  )

  useEffect(() => {
    let initValue = _value ?? PERIOD_DROPDOWN_INITIAL_VALUE
    initValue = {
      ...initValue,
      yearValue: Number(initValue.yearValue),
      monthValue: Number(initValue.monthValue),
    }
    handleValues(_removeAll ? { ...initValue, periodType: '' } : initValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value, handleValues])

  const handleOnlyFilter = (
    target: keyof PeriodDropdownValuesType,
    value: PeriodDropdownValuesType[keyof PeriodDropdownValuesType]
  ) => {
    const cloned = {
      ...clone(PERIOD_DROPDOWN_INITIAL_VALUE),
      periodType: inputs.periodType as TimeFilterValueType,
    }
    if (target === 'period') {
      const period = value as PeriodType
      handleValues({ ...cloned, period })
      if (
        period.start &&
        period.start.isValid() &&
        period.end &&
        period.end.isValid()
      ) {
        if (_emitValue) _emitValue({ ...cloned, period })
      }
    } else {
      handleValues({ ...cloned, [target]: value })
      if (_emitValue) _emitValue({ ...cloned, [target]: value })
    }
  }
  return (
    <S.FilterWrap _css={_css}>
      {isWithoutAll(inputs.periodType, _removeAll) ? (
        <Dropdown.Underline
          _css={css`
            width: 100px;
            min-width: 100px;
          `}
          _list={TIME_FILTER_DROPDOWN_WITHOUT_ALL}
          _value={inputs.periodType}
          _emitValue={(value) => {
            let nextValue = {
              ...clone(PERIOD_DROPDOWN_INITIAL_VALUE),
              periodType: value,
            }
            handleValues(nextValue)
          }}
        />
      ) : (
        <Dropdown.Underline
          _css={css`
            width: 100px;
            min-width: 100px;
          `}
          _list={TIME_FILTER_DROPDOWN}
          _value={inputs.periodType as TimeFilterValueType}
          _emitValue={(value) => {
            let nextValue = {
              ...clone(PERIOD_DROPDOWN_INITIAL_VALUE),
              periodType: value,
            }
            if ((value as any) === 'all') {
              if (_emitValue) _emitValue(nextValue)
            }
            handleValues(nextValue)
          }}
        />
      )}
      {inputs.periodType === 'month' && (
        <Dropdown.Underline
          _list={monthOptions}
          _value={inputs.monthValue}
          _emitValue={(value) => {
            handleOnlyFilter('monthValue', value)
          }}
          _css={css`
            width: 100px;
            min-width: 100px;
          `}
        />
      )}
      {inputs.periodType === 'year' && (
        <Dropdown.Underline
          _list={yearOption}
          _value={inputs.yearValue}
          _emitValue={(value) => {
            handleOnlyFilter('yearValue', value)
          }}
          _css={css`
            width: 100px;
            min-width: 100px;
          `}
        />
      )}
      {inputs.periodType === 'period' && (
        <S.PeriodWrap>
          <DatePicker
            _value={inputs.period.start}
            _emitValue={(value) => {
              handleOnlyFilter('period', {
                ...inputs.period,
                start: value,
              })
            }}
            _comparisonDate={{
              type: 'after',
              date: inputs.period.end,
            }}
            _css={css`
              width: 172px;
              min-width: 172px;
            `}
          />
          ~
          <DatePicker
            _value={inputs.period.end}
            _emitValue={(value) => {
              handleOnlyFilter('period', { ...inputs.period, end: value })
            }}
            _comparisonDate={{
              type: 'before',
              date: inputs.period.start,
            }}
            _css={css`
              width: 172px;
              min-width: 172px;
            `}
          />
        </S.PeriodWrap>
      )}
    </S.FilterWrap>
  )
}
export default PeriodDropdownFilter
