import { css } from '@emotion/react'
import { ImagesDetailsProfile, ImagesServiceChevronRight } from 'assets'
import Badge from 'components/Badge'
import { BADGE_COLOR } from 'components/Badge/Badge'
import { GrayScaleOutline } from 'components/Buttons'
import DetailsComponents, { ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import useFetch from 'hooks/useFetch'
import useMatchInput from 'hooks/useMatchInput'
import LoadingPage from 'layout/LoadingPage'
import { useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import {
  ContentBoxWrap,
  ContentProfileBox,
  ContentRadiusBox,
  FlexColumn,
  FlexRow,
  TXT,
} from 'themes/Shared.styles'
import { colors } from 'themes/styles'
import { thousand } from 'utils/helpers'
import { parseToFormattedStr } from 'utils/parsers'
import { ApiPartnerDetails, PARTNER_INFO_INITIAL_VALUE } from '../Partner.types'
import { PartnerDetailsProps } from './PartnerDetails.types'

const PartnerDetails = ({ _css, _id, ...props }: PartnerDetailsProps) => {
  const { Layout: L } = S
  const navigate = useNavigate()
  const { inputs, fetchInit } = useMatchInput(PARTNER_INFO_INITIAL_VALUE)
  const [response, isError] = useFetch<ApiPartnerDetails>(
    'user',
    `/partner/${_id}`,
    {
      disabled: !_id,
    }
  )
  useEffect(() => {
    if (response) {
      fetchInit({
        ...PARTNER_INFO_INITIAL_VALUE,
        id: response.id,
        name: response.name,
        phone: response.phone,
        gender: response.gender,
        residentNumber: response.personalNo,
        accountNumber: response.accountNo,
        email: response.email,
        chargeLimit: response.referralFee,
        settlementCharge: response.settlementFee,
        contractFiles:
          response.contracts?.map((item) => ({
            id: item.id,
            name: item.fileName,
            url: item.contractsUrl,
          })) ?? [],
        remarks: response.note,

        buildingInfo: true,
      })
    }
  }, [fetchInit, response])
  if (!response) return <LoadingPage />
  if (isError) return <Navigate to={`/404?from=${window.location.pathname}`} />
  return (
    <>
      <DetailsComponents
        _title={'파트너 상세'}
        _topLeftControls={
          <L.ButtonRow>
            <ToList _to="/member/partner" />
          </L.ButtonRow>
        }
        _leftContents={
          <>
            <ContentBoxWrap>
              <ContentProfileBox>
                <div className="wrap">
                  <FlexColumn gap={12} alignItems="center">
                    <div
                      data-profile
                      style={{
                        backgroundImage: `url(${ImagesDetailsProfile})`,
                      }}
                    />
                    <div>
                      <div className="name">{inputs.name}</div>
                      <div className="phone">
                        {parseToFormattedStr(inputs.phone, 'phone')}
                      </div>
                      <FlexRow
                        gap={4}
                        className="badges"
                        justifyContent={'center'}
                      >
                        <Badge {...BADGE_COLOR.AccentLight}>파트너</Badge>
                      </FlexRow>
                    </div>
                  </FlexColumn>
                </div>
              </ContentProfileBox>
              <BoxLayout
                _title={'기본 정보'}
                _css={css`
                  div > label {
                    min-width: 100px;
                  }
                `}
              >
                <FlexColumn gap={12}>
                  <ContentRadiusBox>
                    <BoxRowComponent
                      _label={'주민등록번호'}
                      _contents={parseToFormattedStr(
                        inputs.residentNumber,
                        'residentNumber'
                      )}
                    />
                    <BoxRowComponent
                      _label={'계좌번호'}
                      _contents={inputs.accountNumber}
                    />
                    <BoxRowComponent
                      _label={'이메일'}
                      _contents={inputs.email}
                    />
                  </ContentRadiusBox>

                  <ContentRadiusBox>
                    <BoxRowComponent
                      _label={'소개 수수료 한도'}
                      _contents={`${thousand(inputs.chargeLimit ?? 0)}원`}
                    />
                    <BoxRowComponent
                      _label={'정산수수료'}
                      _contents={`${thousand(inputs.settlementCharge ?? 0)}%`}
                    />
                  </ContentRadiusBox>
                </FlexColumn>
              </BoxLayout>
            </ContentBoxWrap>
          </>
        }
        _rightContents={
          <ContentBoxWrap>
            <BoxLayout _title={'건물정보'}>
              <ContentRadiusBox>
                <BoxRowComponent _label={'건물'}>
                  <FlexRow
                    gap={8}
                    justifyContent="flex-end"
                    onClick={() => {
                      navigate(
                        `/member/building?searchType=회원명&searchValue=${inputs.name}`
                      )
                    }}
                  >
                    <TXT _textStyle="Body/Large/Bold" _color="Primary/Default">
                      정보 보기
                    </TXT>
                    <img src={ImagesServiceChevronRight} alt="right" />
                  </FlexRow>
                </BoxRowComponent>
              </ContentRadiusBox>
            </BoxLayout>
            <BoxLayout _title={'기타정보'}>
              <ContentRadiusBox>
                <BoxRowComponent
                  _label={'계약서'}
                  _css={css`
                    align-items: flex-start;
                    & > label {
                      padding-top: 10px;
                    }
                  `}
                >
                  <FlexColumn
                    gap={12}
                    alignItems="flex-end"
                    _css={css`
                      padding-top: 7px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      & > div {
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                      & > div a {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: ${colors['Primary/Default']};
                        text-decoration: underline;
                        white-space: nowrap;
                      }
                    `}
                  >
                    {inputs.contractFiles.map((item, itemIndex) => {
                      return (
                        <div key={item.id + 'contract' + itemIndex}>
                          <Link to={item.url}>{item.name}</Link>
                        </div>
                      )
                    })}
                  </FlexColumn>
                </BoxRowComponent>
              </ContentRadiusBox>
              <ContentRadiusBox
                className="question"
                _css={css`
                  margin-top: 12px !important;
                `}
              >
                <BoxRowComponent _label={'비고'} _contents={inputs.remarks} />
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _bottomRightControls={
          <>
            <L.RightBottom>
              <GrayScaleOutline
                width={'100%'}
                height={48}
                padding={`12px 0`}
                onClick={() => {
                  navigate(`/member/partner/edit/${_id}`)
                }}
              >
                수정
              </GrayScaleOutline>
            </L.RightBottom>
          </>
        }
      />
    </>
  )
}
export default PartnerDetails
