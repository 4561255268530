import AuthGuard from 'layout/AuthGuard'
import Layout from 'layout/Layout'
import ReDirect from 'layout/Redirect'
import Main from 'pages/Main'

import {
  createBrowserRouter,
  IndexRouteObject,
  RouterProvider,
} from 'react-router-dom'
import { authorizedPages, ExtraRouteObject } from './AuthorizedPages'
import NotFoundPage from './NotFoundPage'
import ServerErrorPage from './ServerErrorPage'
import Test from './Test'

type RouteObject = IndexRouteObject | ExtraRouteObject
export const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Main />,
      },
      ...authorizedPages,
    ],
  },
  {
    path: '/redirect',
    element: <ReDirect />,
  },
  {
    path: '/test',
    element: <Test />,
  },
  {
    path: '/404',
    element: <NotFoundPage />,
  },
  {
    path: '/500',
    element: <ServerErrorPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
const RouteComponent = () => {
  const router = createBrowserRouter(routes)
  return <RouterProvider router={router} />
}
export default RouteComponent
