import { css } from '@emotion/react'
import {
  GrayScaleFill,
  GrayScaleOutline,
  PrimaryButton,
} from 'components/Buttons'
import Chips from 'components/Chips'
import DetailsComponents, { ToList } from 'components/DetailsComponents'
import BoxLayout from 'components/DetailsComponents/BoxLayout'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import S from 'components/DetailsComponents/DetailsComponents.styles'
import MiniPopup from 'components/MiniPopup'
import Modal from 'components/Modal'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import useFetch from 'hooks/useFetch'
import LoadingPage from 'layout/LoadingPage'
import AlertRow from 'pages/Work/components/AlertRow'
import { useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { setToast } from 'store/toastSlice'
import { ContentBoxWrap, ContentRadiusBox, Flex } from 'themes/Shared.styles'
import callAxios, { handleError } from 'utils/callAxios'
import { addLocalGroup } from '../components/AddServiceArea/AddServiceArea.helpers'
import EditServiceArea from '../components/EditServiceArea/EditServiceArea'
import { ApiZoneGroupDetails, AreaDetailsProps } from './AreaDetails.types'

const AreaDetails = ({ _css, ...props }: AreaDetailsProps) => {
  const { Layout: L } = S
  const dispatch = useAppDispatch()
  const { id } = useParams<'id'>()
  const [openAddServiceArea, set_openAddServiceArea] = useState<boolean>(false)
  const [openDeletePopup, set_openDeletePopup] = useState<boolean>(false)
  const navigate = useNavigate()
  const [response, isError, callRefresh] = useFetch<ApiZoneGroupDetails>(
    `user`,
    `/zone-group/${id}/detail`,
    {
      disabled: !id,
    }
  )
  const handleDelete = async () => {
    try {
      await callAxios('user').delete(`/zone-group/${id}`)
      dispatch(setToast(['서비스 지역이 삭제 되었습니다.', 'error']))
      set_openDeletePopup(false)
      navigate(`/services/area`)
    } catch (error) {
      handleError(error)
    }
  }
  if (!response) return <LoadingPage />
  if (isError) return <Navigate to={`/404?from=${window.location.pathname}`} />
  return (
    <>
      <Modal
        open={openDeletePopup}
        onClose={() => {
          set_openDeletePopup(false)
        }}
      >
        <MiniPopup
          _title="서비스 지역 삭제"
          _buttonHandler={
            <>
              <GrayScaleFill onClick={() => set_openDeletePopup(false)}>
                취소
              </GrayScaleFill>
              <PrimaryButton onClick={handleDelete}>삭제하기</PrimaryButton>
            </>
          }
        >
          해당 서비스 지역 그룹을 삭제하시면
          <br />
          이 그룹에 포함된 서비스 가격도 함께 삭제되며,
          <br />
          삭제된 지역에서는 수수다앱 서비스를
          <br />
          이용할 수 없습니다.
        </MiniPopup>
      </Modal>
      {openAddServiceArea && !isNaN(Number(id)) && (
        <EditServiceArea
          _data={{
            id: Number(id),
            groupName: response.name,
          }}
          _emitValue={async (value, id) => {
            await addLocalGroup(value, id)
            callRefresh()
          }}
          _open={openAddServiceArea}
          _onClose={() => {
            set_openAddServiceArea(false)
          }}
        />
      )}
      <DetailsComponents
        _title={'서비스 지역 상세'}
        _topLeftControls={
          <L.ButtonRow>
            <GrayScaleFill
              _mini
              padding={'5px 12px'}
              onClick={() => set_openDeletePopup(true)}
            >
              삭제
            </GrayScaleFill>
            <ToList _to="/services/area" />
          </L.ButtonRow>
        }
        _leftContents={
          <ContentBoxWrap>
            <BoxLayout _title={'지역 그룹 정보'}>
              <ContentRadiusBox>
                <BoxRowComponent
                  _label={'그룹 이름'}
                  _contents={response.name}
                />
                <BoxRowComponent
                  _label={'최근 수정'}
                  _contents={
                    response.updatedAt
                      ? dayjs(response.updatedAt).format('YYYY-MM-DD HH:mm')
                      : '-'
                  }
                />
              </ContentRadiusBox>
            </BoxLayout>
            <AlertRow className="flex-start">
              <div>
                추가하신 지역의 상세 서비스 및 가격 관리는{' '}
                <span className="bold">{'[서비스 관리 > 서비스 설정]'}</span>{' '}
                메뉴에서 관리할 수 있습니다.
                <br />
                별도로 카테고리 설정을 하지 않으실 경우, 기본 서비스 가격으로
                책정됩니다.
              </div>
            </AlertRow>
          </ContentBoxWrap>
        }
        _rightContents={
          <ContentBoxWrap>
            <BoxLayout
              _title={'포함 지역(행정동)'}
              _css={css`
                height: 100%;
              `}
            >
              <ContentRadiusBox
                _css={css`
                  min-height: calc(100% - 52px);
                `}
              >
                <Flex gap={'16px 12px'} flexWrap="wrap">
                  {response.zone.map((item, index) => (
                    <Chips
                      _type="border"
                      key={'Chips' + index}
                    >{`${item.si} ${item.gu} ${item.dong}`}</Chips>
                  ))}
                </Flex>
              </ContentRadiusBox>
            </BoxLayout>
          </ContentBoxWrap>
        }
        _bottomRightControls={
          <L.RightBottom>
            <GrayScaleOutline
              width={'100%'}
              padding={'12px 0'}
              onClick={() => {
                set_openAddServiceArea(true)
              }}
            >
              수정
            </GrayScaleOutline>
          </L.RightBottom>
        }
      />
      {/* <S.Wrap _css={_css} {...props}></S.Wrap> */}
    </>
  )
}
export default AreaDetails
