import { css } from '@emotion/react'
import BoxRowComponent from 'components/DetailsComponents/BoxRowComponent'
import UploadImageRow from 'components/UploadImageRow'
import { GalleryStatesType } from 'store/gallerySlice'
import { ContentRadiusBox } from 'themes/Shared.styles'
import { DoubleDFile } from 'types/services.type'
interface PhotoAndRequestsProps {
  _gallery?: {
    gallery: GalleryStatesType
    count: number
  }
  _requests?: string | JSX.Element
  _disabledGallery?: boolean
  _readOnly?: boolean
  _emitValue?: (value: DoubleDFile[]) => void
}
const PhotoAndRequests = ({
  _gallery,
  _requests,
  _readOnly,
  _disabledGallery,
  _emitValue,
  ...props
}: PhotoAndRequestsProps) => {
  return (
    <>
      {_gallery && (
        <ContentRadiusBox
          _css={css`
            margin-top: 12px;
          `}
        >
          <UploadImageRow
            _readOnly={_readOnly}
            currentGallery={_gallery.gallery}
            parent={'요청 사진'}
            _type={'order'}
            _url={'/estimates/file'}
            _disabledGallery={_disabledGallery}
            _emitValue={(value) => {
              if (_emitValue) _emitValue(value)
            }}
          />
          {typeof _requests !== 'string' && _requests}
        </ContentRadiusBox>
      )}
      {typeof _requests === 'string' && (
        <ContentRadiusBox className="question">
          <BoxRowComponent _label={`기타 요청 사항`} _contents={_requests} />
        </ContentRadiusBox>
      )}
    </>
  )
}
export default PhotoAndRequests
